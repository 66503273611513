import utils from 'src/lib/api-utils'

/**
 * PUT stickyのアクションクリエーター
 * sticky.staffId === -1 でDockに戻せる
 * @param  {Sticky} sticky      [description]
 * @param  {string} accessToken [description]
 * @param  {object} env         env
 * @return {Promise}
 */
export const requestAddress = (
  prefName,
  cityName,
  address,
  accessToken,
  env,
) => {
  const endpoint = `${utils.createEndpoint(env, 'address')}`
  const body = JSON.stringify({ prefName, cityName, address })

  const request = fetch(endpoint, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body,
  })

  return request
}

export default requestAddress
