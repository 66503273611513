import { connect as _connect } from 'react-redux'

// アクション
import { actionCreators as $ } from 'src/reducers/wara'

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
export const mapStateToProps = state => {
  return {
    accessToken: state.login.authentication.accessToken,
    env: state.env,
    modal: state.wara.modal,
  }
}

/**
 * map dispatch to props
 * @param  {function} dispatch Dispatcher
 * @return {object}             mapped dispatcher as props
 */
export const mapDispatchToProps = (dispatch, ownProps) => {
  if (ownProps.update && ownProps.reset) {
    return {
      stageSticky: sticky => {
        ownProps.reset()
        ownProps.update(sticky)
        ownProps.callback && ownProps.callback()
      },
    }
  } else {
    // TODO: これは、廃止にしたい
    return {
      stageSticky: sticky => dispatch($.stageSticky(sticky)),
    }
  }
}

export const connect = Component =>
  _connect(mapStateToProps, mapDispatchToProps)(Component)
