import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import noop from 'src/lib/noop'
import { baseColor, gray } from 'src/colors'
import moment from 'moment'
import config from 'src/config'

const selectColor = props => (props.disabled ? gray : baseColor)
const selectBorder = props =>
  props.visible ? `1px solid ${props.disabled ? gray : baseColor}` : 'none'
const selectCursor = props => (props.disabled ? 'default' : 'pointer')

const SideButton = styled.button`
  background-color: transparent;
  border: ${selectBorder};
  border-radius: 4px;
  color: ${selectColor};
  width: 24px;
  height: 24px;
  font-size: 12px;
  outline: none;

  &:hover {
    cursor: ${selectCursor};
    transform: scale(1.0625);
  }
`

const Display = styled.button`
  background-color: transparent;
  color: ${selectColor};
  height: 24px;
  font-size: 12px;
  outline: none;
  width: 50px;
  padding-right: 1px;
  padding-left: 1px;
  border: none;

  &:hover {
    cursor: default;
  }
`

export const I = styled.i`
  color: ${props => (props.visible ? 'inherit' : 'transparent')};
`

export class SlideToggleButton extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    onChange: PropTypes.func,
    selectedIndex: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
      }),
    ).isRequired,
    disabled: PropTypes.bool,
    displayDate: PropTypes.string.isRequired,
    hasStartStickies: PropTypes.bool.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    onChange: noop,
    disabled: false,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return (
      this.props.selectedIndex !== nextProps.selectedIndex ||
      this.props.items !== nextProps.items ||
      this.props.disabled !== nextProps.disabled ||
      this.props.hasStartStickies !== nextProps.hasStartStickies ||
      this.props.displayDate !== nextProps.displayDate
    )
  }

  /**
   * ◀︎をクリックした時
   */
  onPrevClick = () => {
    this.props.onChange(false)
  }

  /**
   * ▶︎をクリックした時
   */
  onNextClick = () => {
    this.props.onChange(true)
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const {
      selectedIndex,
      items,
      disabled,
      displayDate,
      hasStartStickies,
    } = this.props
    const { label } = items[selectedIndex] || { label: '' }
    const prevVisible = selectedIndex !== config.ssworkState.workCancel
    const nextVisible = selectedIndex !== config.ssworkState.workEnd

    const isNextDisabled = () => {
      const today = moment(moment().format('YYYY-MM-DD'))
      // 稼働中 && (today - displayDate) >= 2 の時、hasStartStickiesに関係なく ▶︎ (稼働終了)が押せる
      if (
        selectedIndex === config.ssworkState.workStart &&
        today.diff(moment(displayDate), 'days') >= 2
      ) {
        return false
      }
      // それ以外はhasStartStickiesの時▶︎が押せない
      return hasStartStickies
    }

    const prevDisabled = hasStartStickies
    const nextDisabled = isNextDisabled()

    return (
      <div>
        <SideButton
          onClick={ this.onPrevClick }
          disabled={ disabled || prevDisabled }
          visible={ prevVisible }
        >
          <I
            className={ 'fa fa-caret-left' }
            disabled={ disabled }
            visible={ prevVisible }
          />
        </SideButton>
        <Display disabled={ disabled }>{label}</Display>
        <SideButton
          onClick={ this.onNextClick }
          disabled={ disabled || nextDisabled }
          visible={ nextVisible }
        >
          <I
            className={ 'fa fa-caret-right' }
            disabled={ disabled }
            visible={ nextVisible }
          />
        </SideButton>
      </div>
    )
  }
}

export default SlideToggleButton
