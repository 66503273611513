import React from 'react'
import PropTypes from 'prop-types'

/**
 * 付箋作成時のメッセージコンポーネント
 * @type {object}
 */
export class PutMessageResult extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    status: PropTypes.oneOf(['not_yet', 'request', 'success', 'failure'])
      .isRequired,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return this.props.status !== nextProps.status
  }

  /**
   * render
   * @return {ReactDOM} rendering ReactDOM
   */
  render() {
    const { status } = this.props

    let Message
    if (status === 'not_yet') {
      // 通信前のメッセージ
      Message = () => null
    } else if (status === 'success') {
      // 通信成功時のメッセージ
      Message = () => (
        <div className={ 'alert alert--normal' }>
          <div className={ 'alert-icon' }>
            <i className={ 'fa fa-4x fa-check-circle' } />
          </div>
          <p>{'更新成功'}</p>
        </div>
      )
    } else if (status === 'request') {
      // 通信中のメッセージ
      Message = () => (
        <div className={ 'alert alert--normal' }>
          <div className={ 'alert-icon' }>
            <i className={ 'fa fa-spinner fa-pulse fa-4x fa-fw' } />
          </div>
          <p>{'通信中'}</p>
        </div>
      )
    } else {
      // 通信失敗時のメッセージ
      Message = () => (
        <div className={ 'alert alert--error' }>
          <div className={ 'alert-icon' }>
            <i className={ 'fa fa-4x fa-times-circle' } />
          </div>
          <p>{'通信失敗'}</p>
        </div>
      )
    }

    return (
      <div className={ 'alert-wrap' }>
        <Message />
      </div>
    )
  }
}

export default PutMessageResult
