import React from 'react'
import PropTypes from 'prop-types'

// libs
import { toJPYenText } from 'src/lib/format'
import noop from 'src/lib/noop'
import { TextField } from '@mui/material'
import theme from 'src/theme.js'
import { ThemeProvider } from '@mui/material/styles'

export class Jpy extends React.Component {
  /**
   * validate jpy
   * @param  {any}     value given value
   * @return {boolean}       validation result
   */
  static isValid = value => {
    return (
      value === '' ||
      value === null ||
      /^[¥￥]?[０１２３４５６７８９0123456789,、，]+$/.test(value.toString())
    )
  }

  /**
   * validate jpy with minus sign when negative is specified
   * @param  {any}     value given value
   * @return {boolean}       validation result
   */
  static isValidNegative = value => {
    return (
      value === '' ||
      value === null ||
      /^[¥￥]?-?[０１２３４５６７８９0123456789,、，]+$/.test(value.toString())
    )
  }

  static normalize = value =>
    parseInt(
      (value || '0')
        .replace(/[０１２３４５６７８９]/g, s =>
          String.fromCharCode(s.charCodeAt(0) - 65248),
        )
        .replace(/[,、，]/g, '')
        .trim(),
      10,
    )

  /**
   * '', nullが入力された時、値をそのまま格納する
   */
  static normalizeNoZero = value => {
    if (value === null || value === '') {
      return value
    } else {
      return Jpy.normalize(value)
    }
  }

  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.number,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    isValid: PropTypes.bool,
    id: PropTypes.string,
    name: PropTypes.string,
    negative: PropTypes.bool, // trueの時負の金額を有効とする。デフォルトはfalse。
    readOnly: PropTypes.bool, // テキストを書き込み不可にする時true。デフォルトはfalse。
    blankNotZero: PropTypes.bool, // null, '' の時に 0 として扱わない
    style: PropTypes.object, // 追加のスタイル
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    className: '',
    value: 0,
    disabled: false,
    onChange: noop,
    isValid: true,
    id: 'jpyid',
    name: 'jpyname',
    negative: false,
    readOnly: false,
    blankNotZero: false,
    style: {},
  }

  state = { editing: false }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.value !== nextProps.value ||
      this.state.editing !== nextState.editing ||
      this.props.disabled !== nextProps.disabled ||
      this.props.readOnly !== nextProps.readOnly
    )
  }

  focus = () => this.setState({ editing: this.props.value })
  change = e => this.setState({ editing: e.target.value })
  blur = e => {
    const { value } = e.target
    if (this.isValidValue(value)) {
      this.props.onChange(
        this.props.blankNotZero
          ? Jpy.normalizeNoZero(value)
          : Jpy.normalize(value),
      )
    }
    this.setState({ editing: false })
  }

  isValidValue = value => {
    const { negative } = this.props
    if (!negative) {
      return Jpy.isValid(value)
    } else {
      return Jpy.isValidNegative(value)
    }
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { editing } = this.state
    const {
      value,
      disabled,
      isValid: externalValidity,
      readOnly,
      blankNotZero,
      style: propStyle,
    } = this.props

    // blankNotZero が設定されている時は null/'' の時のみ空白表示し、0は￥0表示。そうでない時は0も空白表示。
    const displayValue = blankNotZero
      ? editing === false
        ? value === null || value === ''
          ? ''
          : toJPYenText(value)
        : editing
      : editing === false
        ? value
          ? toJPYenText(value)
          : ''
        : editing
    const isValid = this.isValidValue(displayValue) && externalValidity

    const inputProps = {
      id: this.props.id,
      name: this.props.name,
      className: this.props.className,
      value: this.props.value,
      disabled: this.props.disabled,
      onChange: this.props.onChange,
    }

    return (
      <ThemeProvider theme={ theme }>
        <TextField
          color="primary"
          error={ !isValid }
          value={ displayValue }
          type={ 'text' }
          onFocus={ this.focus }
          onChange={ this.change }
          onBlur={ this.blur }
          disabled={ disabled }
          size="small"
          variant="outlined"
          InputProps={ {
            readOnly: readOnly,
          } }
          sx={ {
            ...propStyle,
            background: disabled ? '#fafafa' : '#fff',
            width: '90px',
            textAlign: 'right',
            '& .MuiOutlinedInput-root': {
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0, 0, 0, 0.23)',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'primary.main',
              },
            },
            '& .MuiInputBase-root': { height: '28px' },
            '& .MuiInputBase-input': { height: '20px', padding: '0 14px' },
          } }
        />
      </ThemeProvider>
    )
  }
}

export default Jpy
