import styled from 'styled-components'
import { baseColor } from 'src/colors'
import button from 'src/styled/button'

export const ButtonLink = styled.button`
  ${button};
  background-color: transparent;
  color: ${baseColor};
  font-size: 1.2em;
  text-decoration: underline;

  &::before {
    content: '←';
  }
`
