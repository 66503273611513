/**
 * マスタデータが金額の時trueを返す
 * 距離・区間を除く。
 * @param {*} master
 */
export const isPaymentMaster = () => true
//  export const isPaymentMaster = master => true
//  !(
//    master.inputType === config.houseLaboFeeInputType.distance ||
//    master.inputType === config.houseLaboFeeInputType.range ||
//    master.inputType === config.houseLaboFeeInputType.string
//  )

/**
 * 合計金額の計算
 * @param {*} stickyProps
 * @returns feeTotal: ①+②+③, allTotal: 消費税、有料道路を含む合計
 */
export const calcTotal = stickyProps => {
  const esMaster = stickyProps.esMaster || {}
  const esMasterFees = esMaster.esFees || []
  const es = stickyProps.es || {}
  const esFees = stickyProps.esFees || []
  const workFee = es.workFee || []
  const esPartsFees = stickyProps.esPartsFees || []
  const esMasterFees1 = esMasterFees[0] || []
  let feeTotal = 0
  // ① 出動費などの合計
  esMasterFees1.forEach(master => {
    const fee = esFees.find(f => f.feeId === master.feeId) || {}
    feeTotal += parseInt(fee.fee || '0', 10)
  })
  // ② 作業費の合計
  workFee.forEach(f => {
    feeTotal += f
  })
  // ③ 部品代の合計
  esPartsFees.forEach(fee => {
    feeTotal += fee.fee
  })
  // 有料道路代を含んだ合計→total = feeTotal + salesTax(feeTotal) + 有料道路代
  const allTotal = feeTotal + salesTax(feeTotal) + (es.highwayFee || 0)
  //
  return { feeTotal, allTotal }
}

// export const calcTotal = (esMaster, es, esFees, esPartsFees) => 0

/**
 * 消費税の計算
 * 税率、四捨五入の有無をここでまとめる。
 * Req1795: 消費税の計算を四捨五入に変更。
 * @param {*} value 金額
 */
export const salesTax = value => Math.round(value * 0.1)
