import { createStore, combineReducers, applyMiddleware } from 'redux'
import { routerReducer, routerMiddleware } from 'react-router-redux'
import { apiMiddleware } from 'redux-api-middleware'
import createHistory from 'history/createBrowserHistory'
import hookMiddleware, {
  registerPosthooks,
  registerPrehooks,
} from 'redux-hook-middleware'
import localStorageIOMiddleware from 'src/middlewares/local-storage-io'
import networkMonitorMiddleware from 'src/middlewares/network-monitor'
import prehooks from 'src/middlewares/hooks/prehooks'
import posthooks from 'src/middlewares/hooks/posthooks'

/**
 * Reducers
 */
import apiMetaReducer from 'src/reducers/api-meta'
import callReducer from 'src/reducers/call'
import waraReducer from 'src/reducers/wara'
import clickerReducer from 'src/reducers/clicker'
import dndReducer from 'src/reducers/dnd'
import loginReducer from 'src/reducers/login'
import lightboxReducer from 'src/reducers/lightbox'
import historiesReducer from 'src/reducers/histories'
import masterReducer from 'src/reducers/master'
import envReducer from 'src/reducers/env'
import marucReducer from 'src/reducers/maruc'
import metaReducer from 'src/reducers/meta'
import userPresetReducer from 'src/reducers/user-preset'
import networkStatusReducer from 'src/reducers/network-status'
import wrappedCalendarReducer from 'src/reducers/wrapped-calendar'
import sendMailReducer from 'src/reducers/send-mail'
import paymentsReducer from 'src/reducers/payments'
import displayDateReducer from 'src/reducers/display-date'
import batchReducer from 'src/reducers/batch'
import stagesReducer from 'src/reducers/stages'
import messengerReducer from 'src/reducers/messenger'
import complaintsReducer from 'src/reducers/complaints'
import marucRefundReducer from 'src/reducers/maruc-refund'

/**
 * register hook middleware functions at first
 * @type {object}
 */
registerPrehooks(prehooks)
registerPosthooks(posthooks)

/**
 * Browser history
 * @type {object}
 */
export const history = createHistory()

/**
 * middlewares
 * @type {Array<function>}
 */
const middlewares = [
  routerMiddleware(history),
  apiMiddleware,
  hookMiddleware,
  localStorageIOMiddleware,
  networkMonitorMiddleware,
]

/**
 * store
 * @type {object}
 */
const store = createStore(
  combineReducers({
    apiMeta: apiMetaReducer,
    call: callReducer,
    wara: waraReducer,
    clicker: clickerReducer,
    dnd: dndReducer,
    login: loginReducer,
    lightbox: lightboxReducer,
    histories: historiesReducer,
    master: masterReducer,
    env: envReducer,
    maruc: marucReducer,
    meta: metaReducer,
    userPreset: userPresetReducer,
    networkStatus: networkStatusReducer,
    wrappedCalendar: wrappedCalendarReducer,
    sendMail: sendMailReducer,
    displayDate: displayDateReducer,
    batch: batchReducer,
    stages: stagesReducer,
    payments: paymentsReducer,
    messenger: messengerReducer,
    complaints: complaintsReducer,
    marucRefund: marucRefundReducer,
    // given by React-router
    router: routerReducer,
  }),
  applyMiddleware(...middlewares),
)

/**
 * export
 */
export default store
