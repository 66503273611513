import {
  ACTION_TYPES as STICKY_DELETE_ACTION_TYPES,
  creator as _createAsyncDeleteStickyAction,
  partialReducer as partialDeleteStickyReducer,
} from './delete-sticky'

import {
  ACTION_TYPES as POST_EXCHANGE_STICKIES_ACTION_TYPES,
  creator as _createAsyncPostExchangeStickiesAction,
  partialReducer as partialExchangeStickyReducer,
} from './exchange-stickies'

import {
  ACTION_TYPES as ALL_STICKY_GET_ACTION_TYPES,
  creator as _createAsyncGetAllStickyAction,
  partialReducer as partialGetAllStickyReducer,
} from './get-all-sticky'

import {
  ACTION_TYPES as DOCK_STICKY_GET_ACTION_TYPES,
  creator as _createAsyncGetDockStickiesAction,
  partialReducer as partialGetDockStickyReducer,
} from './get-dock-sticky'

import {
  ACTION_TYPES as STAFF_GET_ACTION_TYPES,
  creator as _createAsyncGetStaffsAction,
  partialReducer as partialGetStaffsReducer,
} from './get-staffs'

import {
  ACTION_TYPES as STICKY_POST_ACTION_TYPES,
  creator as _createAsyncPostStickyAction,
  partialReducer as partialPostStickyReducer,
} from './post-sticky'

import {
  ACTION_TYPES as PUT_STAFF_DAILY_INFO_ACTION_TYPES,
  creator as _createAsyncPutStaffAction,
  partialReducer as partialPutStaffReducer,
} from './put-staff'

/**
 * action type name bulk export
 * @type {object}
 */
export const ACTION_TYPES = {
  // 付箋の新規作成
  ...STICKY_DELETE_ACTION_TYPES,
  ...POST_EXCHANGE_STICKIES_ACTION_TYPES,
  ...ALL_STICKY_GET_ACTION_TYPES,
  ...DOCK_STICKY_GET_ACTION_TYPES,
  ...STAFF_GET_ACTION_TYPES,
  ...STICKY_POST_ACTION_TYPES,
  ...PUT_STAFF_DAILY_INFO_ACTION_TYPES,
}

export const createAsyncDeleteStickyAction = _createAsyncDeleteStickyAction
export const createAsyncPostExchangeStickiesAction = _createAsyncPostExchangeStickiesAction
export const createAsyncGetAllStickyAction = _createAsyncGetAllStickyAction
export const createAsyncGetDockStickiesAction = _createAsyncGetDockStickiesAction
export const createAsyncGetStaffsAction = _createAsyncGetStaffsAction
export const createAsyncPostStickyAction = _createAsyncPostStickyAction
export const createAsyncPutStaffAction = _createAsyncPutStaffAction

const partialReducers = [
  partialDeleteStickyReducer,
  partialExchangeStickyReducer,
  partialGetAllStickyReducer,
  partialGetDockStickyReducer,
  partialGetStaffsReducer,
  partialPostStickyReducer,
  partialPutStaffReducer,
]

/**
 * reducer
 * @param  {object} state  stored state
 * @param  {object} action action
 * @return {function}      reducer
 */
export default (state, action) =>
  partialReducers.reduce(
    (prevState, reducer) => reducer(prevState, action),
    state,
  )
