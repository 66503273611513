import { css } from 'styled-components'
import { baseColor } from 'src/colors'
import { darken } from 'polished'

export const button = css`
  margin: 0.2em;
  padding: 0 5px;
  border: 0;
  border-radius: 3px;
  background-color: transparent;
  line-height: 2em;

  &:hover {
    cursor: pointer;
  }

  &[disabled] {
    cursor: default;
    opacity: 0.3;
  }
`

export const buttonLine = (color, disableDarken) => css`
  border: 1px solid ${color};
  color: ${color};

  &:hover:not([disabled]) {
    border: 1px solid ${disableDarken ? color : darken(0.1, color)};
    color: ${disableDarken ? color : darken(0.1, color)};
  }

  &:focus:not([disabled]) {
    border: 1px solid ${baseColor};
    outline: 0;
    box-shadow: 0 0 7px ${baseColor};
  }
`

export const buttonFill = color => css`
  background: ${color};
  color: #fff;

  &:hover:not([disabled]) {
    background: ${darken(0.1, color)};
  }

  &:focus:not([disabled]) {
    border: 1px solid ${color};
    outline: 0;
    box-shadow: 0 0 7px ${color};
    z-index: 3;
  }
`

export default button
