import React from 'react'
import connect from './connect'
import PropTypes from 'prop-types'
import linkHistoryHOC from './hocs/link-history'
import Autocomplete from 'react-autocomplete'

/**
 * 市町村を入力します
 * @type {ReactComponent}
 */
export class CompleteCityCode extends React.PureComponent {
  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    // cityCode が指定されているときは市町村名を表示する
    const {
      master: { allCities },
    } = props
    const selected = allCities.find(city => city.id === props.cityCode)
    const cityName = selected ? this.getItemValue(selected) : ''
    this.state = {
      isEditing: false,
      editingValue: cityName,
    }
  }

  /**
   * プロパティのqueryで渡される値がクリアされた時、
   * 項目が編集中でない時はテキストをクリアする。
   * @param {*} nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.state.isEditing && nextProps.value === '-1') {
      this.setState({ editingValue: '' })
    }
  }

  /**
   * 項目から表示する値を取得する
   */
  getItemValue = city => city.prefName + (city.name ? ` ${city.name}` : '')

  /**
   * 個々の項目を描画する
   */
  renderItem = (city, isHighlighted, style) => (
    <div
      key={ city.id }
      style={ {
        ...style,
        backgroundColor: isHighlighted ? 'lightgray' : 'white',
        padding: '3px',
      } }
    >
      {this.getItemValue(city)}
    </div>
  )

  /**
   * メニューの描画
   * @param {*} items
   * @param {*} value
   * @param {*} style
   */
  renderMenu(items, value, style) {
    return (
      <div style={ { ...style, ...this.menuStyle, zIndex: 10 } }>{items}</div>
    )
  }

  /**
   * 入力項目の描画
   */
  renderInput = props => (
    <input
      { ...props }
      onFocus={ e => {
        props.onFocus(e)
        this.onInputFocus()
      } }
      disabled={ this.props.disabled }
    />
  )

  /**
   * 項目を描画するかの判定
   */
  shouldItemRender = (city, inputValue) => {
    // 未入力の時は全部表示
    if (inputValue === '') {
      return true
    }
    const [prefname, name, value] = [
      city.prefName || '',
      city.name,
      inputValue,
    ].map(element => element.replace(/ /g, ''))
    return prefname.indexOf(value) === 0 || name.indexOf(value) === 0
  }

  /**
   * 入力にフォーカスが当たった時
   */
  onInputFocus = () =>
    this.setState({
      isEditing: true,
    })

  /**
   * 名前が変更された時
   */
  onChange = e => {
    this.state.isEditing && this.setState({ editingValue: e.target.value })
    // 名前を変更したところで一旦valueをクリアする。一覧から選択した時にvalueを設定する。
    this.props.onChange({ target: { value: {} } })
  }

  /**
   * 項目が選択された時
   */
  onSelect = (_0, city) => {
    this.setState({ isEditing: false, editingValue: this.getItemValue(city) })
    this.props.onChange({
      target: { value: { prefCode: city.prefCode, cityCode: city.id } },
    })
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { editingValue } = this.state
    const {
      label,
      master: { allCities },
    } = this.props
    const identifier = 'citylist-text-form-search'

    return (
      <div>
        <dl className={ 'label-inputs-wrap' }>
          <dt className={ 'input-headline' }>
            <label htmlFor={ identifier }>{label}</label>
          </dt>
          <dd className={ 'autocomplete-wrap' }>
            <Autocomplete
              getItemValue={ this.getItemValue }
              items={ allCities }
              renderItem={ this.renderItem }
              renderMenu={ this.renderMenu }
              renderInput={ this.renderInput }
              shouldItemRender={ this.shouldItemRender }
              onChange={ this.onChange }
              onSelect={ this.onSelect }
              value={ editingValue }
              inputProps={ { id: identifier, style: { fontSize: '1.0em' } } }
            />
          </dd>
        </dl>
      </div>
    )
  }
}

/**
 * propTypes
 * @type {object}
 */
CompleteCityCode.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  cityCode: PropTypes.string, // 市町村の初期値を設定する
  prefCode: PropTypes.string,
  // stateProps
  // 市町村一覧
  master: PropTypes.shape({
    allCities: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        prefCode: PropTypes.string.isRequired,
        prefName: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
}

/**
 * defaultProps
 * @type {object}
 */
CompleteCityCode.defaultProps = {
  label: '',
  disabled: false,
  small: false,
  cityCode: '',
  prefCode: '',
}

export default linkHistoryHOC(connect(CompleteCityCode), 'cityCode')
