import update from 'immutability-helper'

/**
 * タイプ定義
 * @type {string}
 */
const type = 'WARA.STAGE_STICKY_RAW'

/**
 * exportation
 */
export default {
  /**
   * action type
   * @type {string}
   */
  type,

  /**
   * action creator
   * @param  {object|function}  updator immutability-helperのupdaterをそのまま渡すか、updateを引数にコールする関数を渡します
   * @return {object} created action
   */
  creator: updator => {
    return {
      type,
      payload: { updator },
    }
  },

  /**
   * [handler description]
   * @param  {object}  state   State
   * @param  {object} action  Action
   * @return {object}          State
   */
  handler: (state, { payload }) => {
    const updator =
      typeof payload.updator === 'function'
        ? payload.updator(update)
        : payload.updator
    return update(state, { modal: { stage: updator } })
  },
}
