import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'

const Footer = styled.footer`
  width: 100%;
  margin: 30px auto 0;
  padding-top: 10px;
  border-top: 1px solid #ddd;
  text-align: center;
`

const FooterDl = styled.dl`
  display: inline-block;
  margin-right: 20px;
`

const FooterDt = styled.dt`
  display: inline-block;
  margin-right: 10px;
`

const FooterDd = styled.dd`
  display: inline-block;
  margin-right: 10px;
`

/**
 map state to props
 * @param  {object} state mapping state
 * @return {object}       mapped state as props
 */
const mapStateToProps = state => {
  return {
    meta: state.meta,
    apiMeta: state.apiMeta,
  }
}

/**
 * 共通フッターのコンポーネント
 * @type {ReactComponent}
 */
export class CommonFooter extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // stateProps
    meta: PropTypes.shape({
      version: PropTypes.string.isRequired,
    }).isRequired,
    apiMeta: PropTypes.shape({
      version: PropTypes.string.isRequired,
      expoVersion: PropTypes.string.isRequired,
      inventoryVersion: PropTypes.string.isRequired,
    }).isRequired,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return (
      this.props.apiMeta.version !== nextProps.apiMeta.version ||
      this.props.apiMeta.expoVersion !== nextProps.apiMeta.expoVersion ||
      this.props.apiMeta.inventoryVersion !== nextProps.apiMeta.inventoryVersion
    )
  }

  /**
   * render
   * @return {ReactDOM} dom
   */
  render() {
    const {
      meta: { version },
      apiMeta: { version: apiVersion, expoVersion, inventoryVersion },
    } = this.props

    return (
      <Footer>
        <FooterDl>
          <FooterDt>{'バージョン情報'}</FooterDt>
          <FooterDd>{`${version} - ${apiVersion} - ${expoVersion} - ${inventoryVersion}`}</FooterDd>
        </FooterDl>
      </Footer>
    )
  }
}

export default connect(mapStateToProps)(CommonFooter)
