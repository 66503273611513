import { ACTION_TYPES as USERPRESET_ACTION_TYPES } from 'src/reducers/user-preset'
import { createAsyncPostUserPresetAction } from 'src/reducers/user-preset'

/**
 * userPresetを全送りする
 * @param {object} store redux store
 * @param  {object} action redux action
 * @return {void}
 */
const serializeUserPreset = store => {
  const state = store.getState()
  const { userid, accessToken } = state.login.authentication
  const { env } = state
  const body = {
    regions: store.getState().userPreset.data.regions.map(region => ({
      ...region,
      listUserDisplayRegionSS: (region.listUserDisplayRegionSS || []).map(
        ss => ({
          ...ss,
          displayOrder: (region.ssOrder || []).indexOf(ss.ssId) + 1,
        }),
      ),
    })),
  }

  store.dispatch(
    createAsyncPostUserPresetAction(userid, body, accessToken, env),
  )
}

export default {
  /**
   * 選択地域更新 -> ユユーザープリセットシリアライズのAPIチェーン
   */
  [USERPRESET_ACTION_TYPES.SINGULATE_USERPRESET_SELECTED_REGION]: serializeUserPreset,

  /**
   * 選択地域更新 -> ユーザープリセットシリアライズのAPIチェーン
   */
  [USERPRESET_ACTION_TYPES.UPDATE_USERPRESET_REGION_DISPLAY]: serializeUserPreset,

  /**
   * スタッフの表示・非表示を切り替え -> ユーザープリセットシリアライズのAPIチェーン
   */
  [USERPRESET_ACTION_TYPES.TOGGLE_STAFF_DISPLAY]: serializeUserPreset,

  /**
   * ss並び替え -> ユーザープリセットシリアライズのAPIチェーン
   */
  [USERPRESET_ACTION_TYPES.UPDATE_USERPRESET_SS_ORDER]: serializeUserPreset,

  /**
   * ユーザープリセットのリセット -> シリアライズのAPIチェーン
   */
  [USERPRESET_ACTION_TYPES.RESET]: serializeUserPreset,

  //
  // (store, action) => {
  //   const { userid, accessToken } = store.getState().login.authentication
  //   const { regionId, ssOrder } = action.payload
  //   const body = {
  //     regions: [
  //       {
  //         id: regionId,
  //         ssOrder,
  //       },
  //     ],
  //   }
  //   // userPreset更新
  //   store.dispatch(createAsyncPostUserPresetAction(userid, body, accessToken))
  // },
}
