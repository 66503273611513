// action creators
import { actionCreators as $ } from 'src/reducers/wara'

// lib
import { findStickyFromWara } from 'src/lib/search'
import Sticky from 'src/lib/class-sticky'
import requestPutSticky from 'src/lib/sticky-api/put'
import requestCanUpdateSticky from 'src/lib/sticky-api/canupdate'

// config
import config from 'src/config'

/**
 * [description]
 * @param  {object} state state
 * @param  {{ regionIndex: number, listIndex: number, stickyIndex: number }} locationSrc
 * @param  {{ regionIndex: number, listIndex: number }} locationDest location object
 * @param  {function} dispatch dispather
 * @return {void}
 */
export default (state, locationSrc, locationDest, dispatch) => {
  const {
    regionIndex: regionIndexSrc,
    listIndex: listIndexSrc,
    stickyIndex: stickyIndexSrc,
  } = locationSrc

  const {
    regionIndex: regionIndexDest,
    listIndex: listIndexDest,
  } = locationDest
  const sticky =
    findStickyFromWara(state.wara.data, locationSrc) || new Sticky({})
  const { stickyTypeId, stickyStatusId } = sticky.json()

  // 分裂付箋はドックに入れさせない
  if (stickyStatusId === config.stickyStatus.split) {
    return
  }

  // 特殊付箋はドックに入れさせない
  if (
    stickyTypeId !== config.stickyType.normal &&
    (regionIndexDest === -1 || listIndexDest === -1)
  ) {
    return
  }

  const wara = state.wara.data
  const env = state.env
  const { accessToken } = state.login.authentication

  const isDocking = regionIndexDest === -1 || listIndexDest === -1

  // 移動元のリスト or dock
  const listSrc =
    regionIndexSrc === -1
      ? wara.dock
      : wara.regions[regionIndexSrc].lists[listIndexSrc]

  // 移動先のリスト or dock
  const listDest =
    regionIndexDest === -1
      ? wara.dock
      : wara.regions[regionIndexDest].lists[listIndexDest]

  // 付箋を取得
  const stickySrc = listSrc.stickies[stickyIndexSrc].json()
  const stickySrcId = stickySrc.id
  const stickySrcCompanyId = stickySrc.companyId
  const stickySrcRegionId = stickySrc.regionId
  const stickSrcUpdateAt = stickySrc.updateAt

  // スタッフID or 解除用id -1 を取得
  const staffId = regionIndexDest === -1 ? -1 : listDest.staffId
  const { displayDate } = state.displayDate

  // Req1386対応
  // SSのマスタデータを探す
  // listSrc/listDest は serviceStaffs APIで取得したSS情報で、dailyInfoを持っている。
  // allServiceStaffs APIでエリアと会社コードを返すようにしたので、staffIdをキーに検索し、
  // 該当SSのエリアと会社コードを参照できるようにする。
  // allServiceStaffsはエリアIDごとの配列になっているので、先にエリアIDで絞り込んで
  // find()することで少しはパフォーマンスが稼げるはず。
  const destStaff = (staffId => {
    if (staffId === -1) return {}
    const allServiceStaffs = state.master.data.allServiceStaffs
    return (
      (allServiceStaffs[listDest.regionId] || []).find(
        s => s.staffId === staffId,
      ) || {}
    )
  })(staffId)

  // わらにドロップするとき、付箋の会社コードとエリアが
  // ドロップ先SSの会社コードと付箋エリアとが違う時は警告を表示する
  if (
    regionIndexDest !== -1 &&
    (destStaff.companyId !== stickySrcCompanyId ||
      destStaff.stickyRegionId !== stickySrcRegionId)
  ) {
    if (!confirm('付箋を異なるエリアのSSに配置しますか？')) {
      return
    }
  }

  // 付箋が移動可能かチェックし、可能なら移動する。
  requestCanUpdateSticky(stickySrcId, stickSrcUpdateAt, accessToken, env)
    .then(() => {
      const newStickyDiff = new Sticky({
        id: stickySrcId,
        staffId,
        displayOrder: isDocking ? null : listDest.stickies.length,
        displayDate,
        updateAt: stickSrcUpdateAt,
      })
      // わらにドロップした時は確定作業日時を設定する
      if (regionIndexDest !== -1) {
        newStickyDiff._props.determinedDateTime = { date: displayDate }
      }

      requestPutSticky(newStickyDiff, accessToken, env)
        .then(resultSticky => {
          // 付箋をUI上で移動する
          dispatch($.assignSticky(locationSrc, locationDest, displayDate))
          // 移動後の付箋をレスポンスで上書きする
          dispatch($.updateStickyById(new Sticky(resultSticky)))
        })
        .catch(e => {
          // TODO: 付箋の配置に失敗した時。ここで元に戻す
          console.error(e)
          if (e === config.conflictError) {
            alert(config.conflictMessage)
          } else {
            alert(e)
          }
        })
    })
    .catch(e => {
      console.error(e)
      if (e === config.conflictError) {
        alert(config.conflictMessage)
      } else {
        alert(e)
      }
    })
}
