import React from 'react'
import PropTypes from 'prop-types'
import connect from './connect'
import Sticky from 'src/lib/class-sticky'

/**
 * 場所の変更
 * 私用付箋で用いられる
 * workPoint.freeDescribe としてシリアライズされる
 * @type {ReactComponent}
 */
export class Place extends React.Component {
  /**
   * Validation
   * @type {object}
   */
  static propTypes = {
    // ownProps
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
    disabled: PropTypes.bool.isRequired,
    // stateProps
    // dispatchProps
    updateStage: PropTypes.func.isRequired,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return (
      (this.props.sticky.json().workPoint || {}).freeDescribe !==
        (nextProps.sticky.json().workPoint || {}).freeDescribe ||
      this.props.disabled !== nextProps.disabled
    )
  }

  /**
   * 自由記述のchange イベントハンドラー
   * @param  {Event} e event
   * @return {void}
   */
  onChange = e => {
    const { updateStage } = this.props
    const diffProps = {
      workPoint: { freeDescribe: e.target.value },
    }
    updateStage(new Sticky(diffProps))
  }

  /**
   * render
   * @return {ReactDOM} dom
   */
  render() {
    const { sticky, route, disabled } = this.props
    const { workPoint } = sticky.json()

    // null避け
    const place = (workPoint && workPoint.freeDescribe) || ''

    const identifier = 'input-private-place' + route

    return (
      <dl className={ 'label-inputs-wrap' }>
        <dt>
          <label htmlFor={ identifier }>{'場所'}</label>
        </dt>

        <dd className={ 'input-wrap' }>
          <span className={ 'free-describe-wrap' }>
            <input
              className={ 'long' }
              placeholder={ '（場所）' }
              value={ place }
              id={ identifier }
              name={ identifier }
              type={ 'text' }
              onChange={ this.onChange }
              disabled={ disabled }
            />
          </span>
        </dd>
      </dl>
    )
  }
}

export default connect(Place)
