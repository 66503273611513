import utils from 'src/lib/api-utils'

export const requestPutRegionInfo = (regionId, notice, accessToken, env) => {
  const endpoint = `${utils.createEndpoint(env, 'regionInfo')}`

  const request = fetch(endpoint, {
    method: 'PUT',
    headers: { Authorization: `Bearer ${accessToken}` },
    body: JSON.stringify({ regionId, notice }),
  })
  return request
}

export default requestPutRegionInfo
