// react
import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { today } from 'src/lib/moment'

// components
import NormalForms from 'src/components/commons/form-groups/normal-forms'
import ObjectDumper from 'src/components/debuggers/object-dumper'
import ChangeLogs from 'src/components/commons/sticky-modal-content/partials/change-logs'
import ValidationErrorMessage from 'src/components/commons/validation-error-message'
import SearchButton from 'src/components/commons/search-button'

// lib
import Sticky from 'src/lib/class-sticky'
import button, { buttonFill } from 'src/styled/button'
import { baseColor, red } from 'src/colors'
import config from 'src/config'
import { isValidRefund } from 'src/lib/validate-maruc-refund.js'
import { contactNoMerge } from 'src/lib/format'

const Button = styled.button`
  ${button};
  ${props => (props.maruc ? buttonFill(red) : buttonFill(baseColor))};
  margin: 10px;
  padding: 5px 10px;
`

export const NavLink = styled(Link)`
  text-decoration: none;
  color: ${props => (props.disabled ? 'lightgray' : baseColor)};
  font-size: 1em;
  border: 1px solid ${props => (props.disabled ? 'lightgray' : baseColor)};
  padding: 5px 10px;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 4px;
  display: block;
  width: 120px;
  text-align: center;
`

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
const mapStateToProps = state => {
  return {
    isDebugMode: state.env.DEBUG,
    wara: state.wara.data,
    marucRefund: state.marucRefund,
  }
}

// eslint-disable-next-line react/require-optimization
export class ModalContent extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // onwnProps
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    onDecideClick: PropTypes.func.isRequired,
    onResumeClick: PropTypes.func.isRequired,
    closeMe: PropTypes.func.isRequired, // モーダルを閉じる
    // onReRequestClick: PropTypes.func.isRequired,
    onMarucClick: PropTypes.func.isRequired,
    onEditHistoryClick: PropTypes.func.isRequired,
    parentRoute: PropTypes.oneOf(['assign', 'call', 'history']).isRequired,
    displayRoute: PropTypes.oneOf(['assign', 'call', 'history', 'maruc']), // ヘッダを表示するための判定フラグ。デフォルトはnull=>history。
    createSticky: PropTypes.bool, // 新規注文のボタンを有効にする
    newSticky: PropTypes.bool, // 新規付箋の作成
    postSticky: PropTypes.func, // 新規付箋の作成処理
    showButtons: PropTypes.bool, // trueのとき: 付箋作成のボタンを表示する
    // linkToWara: PropTypes.bool, // わらのURL表示
    consultation: PropTypes.bool, // 相談室のときtrue
    marucRepeat: PropTypes.bool, // trueのときマルシーリピートがあるマルシーの交渉履歴を入力不可にする
    // stateProps
    isDebugMode: PropTypes.bool.isRequired,
    marucRefund: PropTypes.object.isRequired, // マルシー返金
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    displayRoute: null,
    linkToWara: false,
    linkMarucRepeat: -1,
    consultation: false,
    marucRepeat: false,
    createSticky: false,
    newSticky: false,
    postSticky: null,
    showButtons: true,
  }

  state = { diffSticky: new Sticky({}), buttonDisabled: false }

  componentDidUpdate(prevProps) {
    if (this.props.sticky !== prevProps.sticky) {
      this.setState({ diffSticky: new Sticky({}) })
    }
  }

  update = nextDiffSticky => {
    const { diffSticky: statediff } = this.state
    this.setState({ diffSticky: statediff.update(nextDiffSticky) })
  }

  renderDecideButton = () => (
    <Button onClick={ () => this.props.onDecideClick(this.props.sticky) }>
      <i className={ 'fa fa-plus' } />
      {' この履歴を元に新規注文入力を開始する'}
    </Button>
  )

  renderResumeButton = () => (
    <Button onClick={ () => this.props.onResumeClick(this.props.sticky) }>
      <i className={ 'fa fa-plus' } />
      {'注文を復活する'}
    </Button>
  )

  renderReRequestButton = () => null
  // renderReRequestButton = () => (
  //   <Button onClick={ () => this.props.onReRequestClick(this.props.sticky) }>
  //     <i className={ 'fa fa-plus' } />
  //     {' この履歴を元に再依頼を開始する'}
  //   </Button>
  // )

  renderMarucButton = () => (
    <Button maruc onClick={ () => this.props.onMarucClick(this.props.sticky) }>
      <i className={ 'fa fa-plus' } />
      {' この履歴を元にマルシーの新規注文入力を開始する'}
    </Button>
  )

  /**
   * 更新ボタンのハンドラ
   * 付箋の更新後、差分をクリアする。
   */
  updateHistory = () => {
    this.setState({ buttonDisabled: true })
    this.props.onEditHistoryClick(this.state.diffSticky)
    this.setState({ diffSticky: new Sticky({}), buttonDisabled: false })
  }

  /**
   * 新規付箋作成ボタンのハンドラ
   */
  postSticky = () => {
    this.setState({ buttonDisabled: true })
    this.props.postSticky(this.state.diffSticky)
    this.setState({ diffSticky: new Sticky({}), buttonDisabled: false })
  }

  /**
   * ボタン描画
   * @return {ReactComponent|null} この履歴を..ボタンのコンポーネント、または空
   */
  renderPostButtons = () => {
    const { sticky, createSticky, showButtons } = this.props
    const id = sticky.id
    const orderId = sticky._props.orderId || ''
    const companyId = sticky._props.companyId || config.company.ES
    // 変更後の付箋の内容をチェックする
    const diffSticky = sticky.update(this.state.diffSticky)
    const validation = diffSticky.isValid(Sticky.VALIDATION_PATTERN.BEFORE_POST)
    const messages = validation.messages
    return showButtons ? (
      <div>
        {messages.map(message => (
          <ValidationErrorMessage key={ message } message={ message } />
        ))}
        {createSticky && (
          <div>
            {this.renderDecideButton()}
            {(sticky.isCanceled || sticky.isSuspended) &&
              this.renderResumeButton()}
            {this.renderReRequestButton()}
            {sticky.isClaimerOrder || this.renderMarucButton()}
          </div>
        )}
        <div className={ 'modal-buttons-below' } style={ { marginLeft: '8px' } }>
          <ChangeLogs
            stickyId={ id }
            orderId={ orderId }
            route={ 'call' }
            companyId={ companyId }
          />
        </div>
      </div>
    ) : null
  }

  /**
   * 「わらへ移動」ボタンの描画
   */
  renderLinkWaraButton = sticky => {
    // 未アサインの時はボタンを無効にする。
    const noAssign = sticky._props.staffId === null
    const linkStyle = {}
    if (noAssign) {
      linkStyle.pointerEvents = 'none'
    }
    const displayDate = sticky._props.displayDate
    const waraURL = `/assign/wara/${(displayDate
      ? displayDate
      : today()
    ).replace(/-/g, '/')}?stickyId=${sticky.id}`
    return (
      <div>
        <NavLink to={ waraURL } disabled={ noAssign } style={ linkStyle }>
          {'わらへ移動'}
        </NavLink>
      </div>
    )
  }

  /**
   * 「マルシーリピート付箋へ移動する」ボタンの描画
   */
  renderLinkRepeatButton = (sticky, marucRepeatId) => {
    const displayDate = sticky._props.displayDate
    const waraURL = `/assign/wara/${(displayDate
      ? displayDate
      : today()
    ).replace(/-/g, '/')}?stickyId=${marucRepeatId}`
    return (
      <div>
        <NavLink to={ waraURL }>{'リピートを表示'}</NavLink>
      </div>
    )
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { diffSticky, buttonDisabled } = this.state
    const {
      // ownProps
      parentRoute,
      sticky: originalSticky,
      newSticky,
      closeMe,
      // stateProps
      isDebugMode,
      marucRefund,
      displayRoute,
      // linkToWara,
      consultation,
      marucRepeat,
    } = this.props

    const editSticky = originalSticky.update(diffSticky)
    const background = editSticky.stickyBackGroundColor()
    contactNoMerge(diffSticky, editSticky)
    const validation = editSticky.isValid(Sticky.VALIDATION_PATTERN.BEFORE_POST)
    const diffContacts = editSticky._props.contacts
    const isNa =
      this.props.sticky._props.finishStateId === config.finishState.na
    const hasResident =
      isNa || diffContacts?.[0]?.some(contact => contact.prefCode)
    const isValid = validation.result && hasResident
    const { validRefund: isMarucRefundValid } = isValidRefund(
      marucRefund,
      originalSticky.id,
    )
    const client = editSticky.findClient() || {}
    const phoneNumber = client.phoneNumber || ''

    return (
      <div style={ { padding: '20px', background } } id={ 'search-results-modal' }>
        {/*
          2019/12/18 暫定的に「わらへ移動」ボタンの表示をしないようにする。
          SSが無効な時に表示しないという案もあるが、この時点の相談結果では処理が重いこともあり、
          表示しないこととする。戻すことを考え、今はコメントとする。
          <div style={ buttonWrapStyle }>
            {linkToWara && this.renderLinkWaraButton(originalSticky)}
          </div>
        */}
        <NormalForms
          sticky={ originalSticky.update(diffSticky) }
          location={ { regionIndex: -1, listIndex: -1, stickyIndex: -1 } }
          route={ displayRoute || 'history' }
          parentRoute={ parentRoute }
          renderPostContent={ this.renderPostButtons }
          allowEdit // TODO: これは常時true
          update={ this.update } // NOTE: デフォルトのupdateSticky をオーバーライドする
          consultation={ consultation }
          marucRepeat={ marucRepeat }
          closeModal={ closeMe }
          newSticky={ newSticky }
        />
        <div
          style={ {
            position: 'fixed',
            top: '150px',
            right: '5%',
            zIndex: '15',
          } }
        >
          {newSticky && (
            <Button
              onClick={ this.postSticky }
              disabled={ !isValid || buttonDisabled }
            >
              <i className={ 'fa fa-pencil' } />
              {'保存する'}
            </Button>
          )}
          {!newSticky && (
            <Button
              onClick={ this.updateHistory }
              disabled={ !isValid || buttonDisabled || !isMarucRefundValid }
            >
              <i className={ 'fa fa-pencil' } />
              {'更新する'}
            </Button>
          )}
          <SearchButton
            linkTo={ `/call/search?phoneNumber=${phoneNumber}` }
            onClick={ closeMe }
          />
        </div>
        <div>
          {newSticky && (
            <Button
              onClick={ this.postSticky }
              disabled={ !isValid || buttonDisabled }
            >
              <i className={ 'fa fa-pencil' } />
              {'保存する'}
            </Button>
          )}
          {!newSticky && (
            <Button
              onClick={ this.updateHistory }
              disabled={ !isValid || buttonDisabled || !isMarucRefundValid }
            >
              <i className={ 'fa fa-pencil' } />
              {'更新する'}
            </Button>
          )}
        </div>
        {isDebugMode ? (
          <ObjectDumper
            prop={ diffSticky.json() }
            side={ 'right' }
            handleOffsetY={ 0 }
            title={ '差分' }
            defaultOpen={ false }
          />
        ) : null}
        {isDebugMode ? (
          <ObjectDumper
            prop={ editSticky.json() }
            side={ 'right' }
            handleOffsetY={ 100 }
            title={ 'オリジナル' }
            defaultOpen={ false }
          />
        ) : null}
      </div>
    )
  }
}

export default connect(mapStateToProps)(ModalContent)
