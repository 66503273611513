import React from 'react'
import PropTypes from 'prop-types'
import connect from '../../connect'
import styled from 'styled-components'

const Select = styled.select`
  min-width: 80px;
  padding: 0.25rem 0.5rem;
  border: 1px solid #9e9e9e;
  border-radius: 0.25rem;
`

/**
 * 交換の達人の入力タイプコンポーネント
 * @type {ReactComponent}
 */
export const ECFeeType = ({ value, onChange, route, disabled, master }) => {
  /**
   * ドロップダウンが変更された
   * @param {*} e
   */
  const onECFeeTypeChange = e => {
    const ecFeeTypeId = parseInt(e.target.value, 10)
    onChange(ecFeeTypeId)
  }
  const groupName = `ec-business-${route}`
  const identifier = `id-${groupName}`
  const showECFeeTypes = master.ecExtraFeeTypes

  return (
    <Select
      name={ identifier }
      id={ identifier }
      onChange={ onECFeeTypeChange }
      value={ value }
      disabled={ disabled }
    >
      {showECFeeTypes.map(item => (
        <option key={ item.id } value={ item.id }>
          {item.name}
        </option>
      ))}
    </Select>
  )
}

/**
 * propTypes
 * @type {object}
 */
ECFeeType.propTypes = {
  value: PropTypes.number.isRequired, // 表示する値
  onChange: PropTypes.func.isRequired, // 入力があった時のコールバック
  route: PropTypes.oneOf(['all']).isRequired,
  disabled: PropTypes.bool.isRequired,
  // stateprops
  master: PropTypes.object.isRequired,
}

export default connect(React.memo(ECFeeType))
