const defaultEnvs = {
  API_HTTPS: true,
  API_HOST: window.location.hostname,
  EXPO_API_HOST: window.location.hostname,
  COMMODITY_URL: '',
  COMMODITY_MASTER_URL: '',
  COMMODITY_KEIRI_URL: '',
  COMMODITY_ENGINEERING_URL: '',
  GENERAL_AFFAIRS_URL: '',
  DEBUG: true,
  API_PORT: void 0,
  NODE_ENV: 'develop',
}

/**
 * initialState
 * @type {object}
 */
export const initialEnvState = { ...defaultEnvs, ...__ENV__ }
// console.log(__ENV__)

export const UPDATE = 'ENV.UPDATE'

export const ACTION_TYPES = { UPDATE }

/**
 * Action creator
 * @type {object}
 */
export const create = {
  update: (key, value) => ({ type: UPDATE, payload: { key, value } }),
}

/**
 * env reducer
 * @param  {object} [state=initialEnvState] prev state
 * @param  {object} action                  action
 * @return {object}                         next state
 */
export const reducer = (state = initialEnvState, action) => {
  const { type } = action
  let payload
  switch (type) {
    case UPDATE:
      payload = action.payload || {}
      return { ...state, [payload.key]: payload.value }
    default:
      return state
  }
}

export default reducer
