import config from 'src/config'

const NORMAL = Symbol('STICKY_TYPE_NORMAL')
const SPECIAL = Symbol('STICKY_TYPE_SPECIAL')
const SPLIT = Symbol('STICKY_TYPE_SPLIT')
const INVALID = Symbol('STICKY_TYPE_INVALID')

export const stickyTypes = {
  NORMAL,
  SPECIAL,
  SPLIT,
  INVALID,
}

/**
 * [description]
 * @param  {object} locationDest  locationDest
 * @param  {object} item     React-DnD item object
 * @return {symbol}          stickyTypes
 */
export default (locationDest, item) => {
  const {
    regionIndex: regionIndexDest,
    listIndex: listIndexDest,
  } = locationDest

  if (item.stickyStatusId === config.stickyStatus.split) {
    // 分裂付箋の時
    const isDocking = regionIndexDest === -1 || listIndexDest === -1
    if (isDocking) {
      // dockにはdropさせない
      return INVALID
    } else {
      return SPLIT
    }

    // NOTE: 無理やり条件分岐させている
    // isSpecialSticky は DragSourceなコンポーネントのpropsとして定義している
    // ACCEPTABLE_TYPESをこのコールバックの内部で取得する方法がわからない
    // 正しいやり方はわからない
  } else if (item.isSpecialSticky) {
    const isDocking = regionIndexDest === -1 || listIndexDest === -1
    if (isDocking) {
      // dockにはドロップさせない
      return INVALID
    } else {
      return SPECIAL
    }
  } else {
    // 普通の付箋の時
    const {
      regionIndex: regionIndexSrc,
      listIndex: listIndexSrc,
    } = item.location

    if (
      // 同じリストにアサインしようとしたでしょう！
      (listIndexSrc === listIndexDest && regionIndexSrc === regionIndexDest) ||
      // dock -> dock
      ((regionIndexSrc === -1 || listIndexSrc === -1) &&
        (regionIndexDest === -1 || listIndexDest === -1))
    ) {
      return INVALID
    } else {
      return NORMAL
    }
  }
}
