import config from 'src/config'
import moment from 'moment'

/**
 * 注文が該当する条件の一覧
 */
export const conditionIds = sticky => {
  const suspendIds = sticky.suspendReason ? sticky.suspendReason.ids || [] : []
  const isClaim = sticky.orderTypeId === config.orderType.claimer
  const meyasuTime = moment(sticky.meyasuTime)
  const cids = []
  let status = -1 // 最初に確定した状態
  let complete = false // 作業完了
  // 担当者未連絡、アサイン実行中、作業完了、リフォーム完了、中断注文のみ、アフター、失注のうち
  // どれか一つが表示されるようにする。
  //-----
  // 失注
  if (
    sticky.finishStateId === config.finishState.cancel ||
    sticky.finishStateId === config.finishState.na
  ) {
    if (status === -1) {
      status = 8
    }
  }
  // 作業完了
  if (sticky.finishStateId === config.finishState.complete) {
    complete = true
    if (status === -1) {
      status = 5
    }
  }
  // リフォーム完了
  if (sticky.finishStateId === config.finishState.reformComplete) {
    complete = true
    if (status === -1) {
      status = 6
    }
  }
  // 保留
  if (suspendIds.indexOf(config.suspendReason.suspend1) >= 0) {
    if (status === -1) {
      status = 13
    }
  }
  // アフター
  if (
    (sticky.afterDateTime && sticky.afterDateTime.date) ||
    suspendIds.includes(101)
  ) {
    if (status === -1) {
      status = 11
    }
  }
  // SS割当あり、meyasuTimeが有効の時「アサイン実行中」、それ以外は「担当者未連絡」
  if (sticky.staffId && meyasuTime.isValid()) {
    if (status === -1) {
      status = 2
    }
  } else {
    if (status === -1) {
      status = 1
    }
  }
  cids.push(status)
  // 要サンキューコール
  if (sticky.thankYouCall && !sticky.thankYouCall.done) {
    // 「要サンキューコール」は作業完了(マルシー除く)の時のみ表示
    if (complete && !isClaim) {
      cids.push(9)
    }
  }
  return cids
}
