import React from 'react'
import PropTypes from 'prop-types'
import createClassNames from 'classnames'

const DeleteButton = props => {
  const buttonClassNames = createClassNames({
    button: true,
    'button-open': true,
    'label-inputs-wrap-button': true,
    'button-disabled': false,
  })
  return (
    <button
      className={ buttonClassNames }
      style={ { border: 0, fontSize: '20px' } }
      onClick={ props.onClick }
      disabled={ props.disabled }
    >
      <i className={ 'fa fa-trash-o' } />
    </button>
  )
}

/**
 * propTypes
 */
DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired, // ボタンが押された時のハンドラ
  disabled: PropTypes.bool,
}
DeleteButton.defaultProps = {
  disabled: false,
}

export default DeleteButton
