import {
  createAsyncGetAllStickyAction,
  ACTION_TYPES,
} from 'src/reducers/wara/async'

export default {
  /**
   * ある日について、SSリストの取得 -> 全てのstickiesを取得
   * @param  {Store}  store  redux store
   * @param  {object} action redux action
   * @return {void}
   */
  [ACTION_TYPES.STAFF_GET_SUCCESS]: (store, action) => {
    const state = store.getState()
    const {
      login: {
        authentication: { accessToken },
      },
      env,
    } = state
    const { datestring } = action.meta

    store.dispatch(createAsyncGetAllStickyAction(datestring, accessToken, env))
    store.dispatch(
      createAsyncGetAllStickyAction(datestring, accessToken, env, true),
    )
  },
}
