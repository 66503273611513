import switz from 'switz'
import update from 'immutability-helper'

export const NETWORK_STATUSES = {
  NOT_YET: Symbol('network not yet'),
  TRYING: Symbol('network trying'),
  SUCCESS: Symbol('network successed'),
  FAILURE: Symbol('network failed'),
}

export const TARGETS = [
  'login',
  'deleteSticky',
  'postSticky',
  'putSticky',
  'getDockSticky',
  'getSticky',
  'getStickySearch',
  'getStaffs',
  'getMaster',
  'getUserPreset',
  'postUserPreset',
  'exchangeStickies',
  'putStaffDaily',
  'sendMail',
  'getHistories',
]

/**
 * initial state
 * [target]: status
 * @type {object}
 */
export const initialNetworkStatusState = TARGETS.reduce((prev, target) => {
  prev[target] = NETWORK_STATUSES.NOT_YET
  return prev
}, {})

/**
 * Action Types
 * @type {string}
 */
export const REQUEST = 'NETWORK_STATUS.REQUEST'
export const SUCCESS = 'NETWORK_STATUS.SUCCESS'
export const FAILURE = 'NETWORK_STATUS.FAILURE'
export const RESET = 'NETWORK_STATUS.RESET'

/**
 * action bulk exports
 * @type {object}
 */
export const ACTION_TYPES = {
  REQUEST,
  SUCCESS,
  FAILURE,
  RESET,
}

/**
 * networkStatus reducer
 * @param  {object} [state=initialNetworkStatusState] previous state
 * @param  {object} action                           dispatched action
 * @return {object}                                   next state
 */
export default (state = initialNetworkStatusState, action) => {
  const { type, payload } = action
  const target = payload ? payload.target : void 0

  if (!target) {
    return state
  }

  return switz(type, s =>
    s
      // reducer for Login
      .case(REQUEST, () =>
        update(state, { [target]: { $set: NETWORK_STATUSES.TRYING } }),
      )
      .case(SUCCESS, () =>
        update(state, { [target]: { $set: NETWORK_STATUSES.SUCCESS } }),
      )
      .case(FAILURE, () =>
        update(state, { [target]: { $set: NETWORK_STATUSES.FAILURE } }),
      )
      .case(RESET, () =>
        update(state, { [target]: { $set: NETWORK_STATUSES.NOT_YET } }),
      )
      .default(() => state),
  )
}
