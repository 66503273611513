import update from 'immutability-helper'

/**
 * タイプ定義
 * @type {string}
 */
const type = 'WARA.DELETE_STICKY'

/**
 * exportation
 */
export default {
  /**
   * action type
   * @type {string}
   */
  type,

  /**
   * action creator
   * @param  {{regionIndex: number, listIndex: number, stickyIndex: number}} regionIndex region index
   * @param  {bool} onlyInUI trueの時、リクエストを出さずにUI上からのみ消す(別の日に移動させたケース)
   * @return {object} Action
   */
  creator: (location, onlyInUI = false) => {
    return {
      type,
      payload: { location },
      meta: { onlyInUI },
    }
  },

  /**
   * action handler
   * @param  {object}  state   Immutableなstateです。このオブジェクトを変更すべきではありません。
   * @param  {object} action  関数に与える引数です。
   * @return {object}          適切にUpdateされた新しいStateを返却します。
   */
  handler: (state, action) => {
    const payload = action ? action.payload : {}
    const location = payload ? payload.location : {}
    const { regionIndex, listIndex, stickyIndex } = location

    if (regionIndex === -1 || listIndex === -1) {
      // NOTE: 消せる付箋は複製付箋で、それは未配置ではないはず
      // デバッグのために、未配置付箋の削除は実装してある
      return update(state, {
        data: {
          dock: {
            stickies: { $splice: [[stickyIndex, 1]] },
          },
        },
      })
    } else {
      return update(state, {
        data: {
          regions: {
            [regionIndex]: {
              lists: {
                [listIndex]: {
                  stickies: { $splice: [[stickyIndex, 1]] },
                },
              },
            },
          },
        },
      })
    }
  },
}
