import moment from 'moment'
const CRLF = '\r\n'

const transformRecord = record => {
  const displayTime = record.updateAt || record.createAt
  const date = displayTime ? moment(displayTime).format('YYYY/MM/DD') : '(不明)'
  const time = displayTime ? moment(displayTime).format('HH:mm') : '(不明)'
  return `${date} ${time} ${record.displayName} ${record.freeDescribe}`
}

export default records => records.map(x => transformRecord(x)).join(CRLF)
