/**
 * ライブラリ
 */
import React from 'react'
import PropTypes from 'prop-types'
import Sticky from 'src/lib/class-sticky'
import connect from './connect'
import { today } from 'src/lib/moment'

/**
 * コンポーネント
 */
import Search from './partials/search'
import InputDummy from './partials/input-dummy'
import Modal from 'react-modal'
import PostMessageResult from './partials/post-message-result'
import PutMessageResult from './partials/put-message-result'
import NormalForms from 'src/components/commons/form-groups/normal-forms'
import ObjectDumper from 'src/components/debuggers/object-dumper'
import ValidationErrorMessage from 'src/components/commons/validation-error-message'
import PostModeButtons from './partials/post-mode-buttons'
import PutModeButtons from './partials/put-mode-buttons'

// lib
import requestPostSticky from 'src/lib/sticky-api/post'
import requestPutSticky from 'src/lib/sticky-api/put'
import requestPutComplaint from 'src/lib/maruc-api/put'
import config from 'src/config'
import {
  setStickyDisplay,
  setFinishStatus,
  contactNoMerge,
} from 'src/lib/format'

/**
 * 初期ステート
 * @type {Object}
 */
const initContacts = [
  [
    {
      id: null,
      legalTypeId: 1,
      isClient: true,
      displayOrder: 1,
    },
  ],
]
const initialState = {
  continueMode: false, // コンティニューモード: 一旦ポストして、そのままPUTできる状態
  originalSticky: new Sticky({}),
  // originalSticky: new Sticky({
  //   determinedDateTime: { date: new Date().toISOString().split('T')[0] }, finishStateId: 6, reformUserId: 'ope17',
  // }),
  diffSticky: new Sticky({
    orderTypeId: 1,
    companyId: 1,
    contacts: initContacts,
    // determinedDateTime: { date: new Date().toISOString().split('T')[0] }, finishStateId: 6, reformUserId: 'ope17',
  }),
  postStatus: 'not_yet', // POSTのステータス
  status: 'not_yet', // PUTのステータス
  timerId: false,
}

/**
 * コール課ページのエントリポイント
 *
 * @type {object}
 */
export class CallEntry extends React.PureComponent {
  /**
   * PropTypes
   * @type {object}
   */
  static propTypes = {
    search: PropTypes.bool, // 検索画面を開く
    phoneNumber: PropTypes.string, // search=trueの時電話番号を渡す
    // stateProps
    modal: PropTypes.shape({ isOpen: PropTypes.bool }).isRequired,
    isDebugMode: PropTypes.bool.isRequired,
    accessToken: PropTypes.string.isRequired,
    env: PropTypes.object.isRequired,
    complaint: PropTypes.any.isRequired,
    messenger: PropTypes.object.isRequired,
    // dispatchProps
    registerMessengers: PropTypes.func.isRequired,
    unregisterMessengers: PropTypes.func.isRequired,
    postSticky: PropTypes.func.isRequired,
    postStatus: PropTypes.symbol.isRequired,
    resetPostStatus: PropTypes.func.isRequired,
    updateComplaint: PropTypes.func.isRequired,
    resetComplaint: PropTypes.func.isRequired,
  }

  static defaultProps = {
    search: false,
    phoneNumber: '',

  }

  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)

    this.state = { ...initialState }
    // 対応希望日時1　に当日の日付を設定する。
    const wishDateTimes = []
    wishDateTimes.push({
      date: today(),
      displayOrder: 0,
    })
    this.state.diffSticky._props.wishDateTimes = wishDateTimes

    // マルシー入力内容を削除
    this.props.resetComplaint('call-screen')

    // 深いコンポーネントにsetStateを渡す
    this.props.registerMessengers({ update: this.update, reset: this.reset })

    // 付箋作成ネットワークの正否を一応リセット
    // TODO: これは消えようとしている
    this.props.resetPostStatus()
  }

  componentDidUpdate() {
    if (!this.props.messenger.update || !this.props.messenger.reset) {
      // ストアの設定が消えることがあるので？、付箋が更新されるたびに無条件でストアにupdateとresetを設定する。
      console.log('registerMessengers')
      this.props.registerMessengers({ update: this.update, reset: this.reset })
    }
  }

  /**
   * componentWillUnmount
   * @return {void}
   */
  componentWillUnmount() {
    // // わたしたsetStateを消す
    // this.props.unregisterMessengers()
    clearTimeout(this.state.timerId)
  }

  reset = () => {
    this.setState({ ...initialState })
    this.props.resetComplaint('call-screen')
  }

  tryRemoveEntries = () => {
    const MSG1 = 'この入力内容を削除します。本当によろしいですか？'
    const MSG2 =
      '再度確認します。この入力内容を削除します。本当によろしいですか？'
    if (window.confirm(MSG1) && window.confirm(MSG2)) {
      this.reset()
    }
  }

  update = diffSticky => {
    const { diffSticky: statediff } = this.state
    if (diffSticky._props.noMerge) {
      // 今ある差分とマージしない
      delete diffSticky._props.noMerge
      this.setState({ diffSticky })
    } else {
      this.setState({ diffSticky: statediff.update(diffSticky) })
    }
  }

  /**
   * コンティニューモードを継続する
   * @param  {object} stickyProps [description]
   * @return {void}
   */
  carryOver = stickyProps => {
    this.setState({
      continueMode: true,
      originalSticky: new Sticky(stickyProps),
      diffSticky: new Sticky({}),
    })
    const { complaint, accessToken, env } = this.props
    if (complaint) {
      requestPutComplaint(
        {
          sticky: { id: stickyProps.id },
          complaint: { ...complaint, stickyId: stickyProps.id },
        },
        accessToken,
        env,
      )
        .then(res => {
          if (res.ok) {
            return res.json()
          } else {
            console.error(res)
            throw new Error('サーバーエラー')
          }
        })
        .then(({ complaint }) => this.props.updateComplaint(complaint))
    }
  }

  /**
   * 「案件を登録」ボタンのハンドラ
   */
  postSticky = () => {
    const {
      accessToken,
      env,
      master: { validStaffs },
    } = this.props
    const postSticky = new Sticky(this.state.diffSticky.json())
    this.updatePostStatus('request')
    // 付箋の表示状態を設定する
    setStickyDisplay(postSticky, validStaffs)
    // 終了状態を修正する
    setFinishStatus(postSticky)
    return requestPostSticky(postSticky, accessToken, env)
      .then(result => {
        this.carryOver(result)
        this.updatePostStatus('success')
      })
      .catch(() => this.updatePostStatus('failure'))
  }

  /**
   * 付箋登録のネットワークステータスのハンドリング
   * 成功・失敗は時間を置いてリセットする
   * @param  {string} status ネットワーク状況
   * @return {void}
   */
  updatePostStatus = postStatus => {
    this.setState({ postStatus: postStatus })
    if (postStatus === 'success' || postStatus === 'failure') {
      this.setState({
        timerId: setTimeout(
          () => this.updatePostStatus('not_yet'),
          config.messageResultDelay,
        ),
      })
    }
  }

  /**
   * 更新内容をサーバーにリクエストする
   * @return {Promise} request
   */
  putSticky = () => {
    const { originalSticky, diffSticky } = this.state
    const {
      accessToken,
      env,
      master: { validStaffs },
    } = this.props

    this.updateStatus('request')
    // 付箋の表示状態を設定する
    setStickyDisplay(diffSticky, validStaffs, originalSticky)

    const sticky = diffSticky.update({
      id: originalSticky.id,
      updateAt: originalSticky._props.updateAt,
    })
    return requestPutSticky(sticky, accessToken, env)
      .then(result => {
        this.carryOver(result)
        this.updateStatus('success')
      })
      .catch(() => this.updateStatus('failure'))
  }

  /**
   * 付箋更新のネットワークステータスのハンドリング
   * 成功・失敗は時間を置いてリセットする
   * @param  {string} status ネットワーク状況
   * @return {void}
   */
  updateStatus = status => {
    this.setState({ status: status })
    if (status === 'success' || status === 'failure') {
      this.setState({
        timerId: setTimeout(
          () => this.updateStatus('not_yet'),
          config.messageResultDelay,
        ),
      })
    }
  }

  /**
   * render
   * @return {ReactDOM} rendering ReactDOM
   */
  render() {
    const {
      continueMode,
      originalSticky,
      diffSticky,
      status,
      postStatus,
    } = this.state
    const {
      search,
      phoneNumber,
      // stateProps
      modal,
      isDebugMode,
      // デバッグ用
    } = this.props

    const sticky = originalSticky.update(diffSticky)
    contactNoMerge(diffSticky, sticky)

    const hasModalContent = modal.content && typeof modal.content === 'function'

    const disablePost = postStatus !== 'not_yet'
    const disablePut = status !== 'not_yet'

    // 必要な情報が入力されていない
    const { result: isValid, messages } = sticky.isValid(
      Sticky.VALIDATION_PATTERN.BEFORE_POST,
    )

    // 「案件を登録する」ボタンをフォームの上下に表示する。
    const buttons = prebutton => {
      const divClass = prebutton ? 'buttons-wrap-pre' : 'buttons-wrap'
      return continueMode ? (
        <PutModeButtons
          sticky={ sticky }
          putDisabled={ disablePut || !isValid }
          resetDisabled={ false }
          onPutClick={ this.putSticky }
          onResetClick={ this.tryRemoveEntries }
          divClassName={ divClass }
        />
      ) : (
        <PostModeButtons
          sticky={ sticky }
          postDisabled={ disablePost || !isValid }
          deleteDisabled={ disablePost }
          onPostClick={ this.postSticky }
          onDeleteClick={ this.tryRemoveEntries }
          divClassName={ divClass }
        />
      )
    }

    return (
      <div>
        <Search
          update={ this.update }
          reset={ this.reset }
          search={ search }
          phoneNumber={ phoneNumber }
        />

        <div className={ 'flex' }>
          <div>
            <NormalForms
              sticky={ sticky }
              update={ this.update }
              route={ 'call' }
              location={ { regionIndex: -1, listIndex: -1, stickyIndex: -1 } }
              renderPreContent={ () => {
                return buttons(true)
              } }
            />
            {messages.map(message => (
              <ValidationErrorMessage key={ message } message={ message } />
            ))}
            {buttons(false)}
          </div>
        </div>

        <InputDummy update={ this.update } />

        {isDebugMode && (
          <ObjectDumper
            prop={ sticky.json() }
            title={ 'コール課付箋入力値デバッグ' }
            handleOffsetY={ -50 }
            side={ 'right' }
            defaultOpen={ false }
          />
        )}

        <PostMessageResult status={ postStatus } />
        <PutMessageResult status={ status } />

        <Modal
          contentLabel={ 'Call Modal Editor' }
          isOpen={ modal.isOpen }
          shouldCloseOnOverlayClick={ false }
          ariaHideApp={ false }
        >
          {hasModalContent ? <modal.content { ...modal.props } /> : null}
        </Modal>
      </div>
    )
  }
}

export default connect(CallEntry)
