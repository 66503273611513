import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { red, baseColor, white, blue } from 'src/colors'

export const Badge = styled.div`
  position: absolute;
  top: ${props => props.offsetY - 6}px;
  right: ${props => props.offsetX - 6}px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${props => props.color};
  color: ${white};
  font-size: 1rem;
  font-weight: bold;
  display: block;
  line-height: 24px;
  text-align: center;
`

/**
 * マルシー付箋に表示するバッジ
 * @param {object} props props
 */
export const MarucBadge = props => (
  <Badge color={ red } offsetX={ props.offsetX || 0 } offsetY={ props.offsetY || 0 }>
    {'C'}
  </Badge>
)

MarucBadge.propTypes = {
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
}

MarucBadge.defaultProps = {
  offsetX: 0,
  offsetY: 0,
}

/**
 * 再依頼付箋に表示するバッジ
 * @param {object} props props
 */
export const ReRequestBadge = props => (
  <Badge
    color={ baseColor }
    offsetX={ 25 + (props.offsetX || 0) }
    offsetY={ props.offsetY || 0 }
  >
    {'再'}
  </Badge>
)

ReRequestBadge.propTypes = {
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
}

ReRequestBadge.defaultProps = {
  offsetX: 0,
  offsetY: 0,
}

/**
 * 「入電あるまで手配不要」に表示するバッジ
 * @param {object} props props
 */
export const PhoneCallBadge = props => (
  <Badge color={ blue } offsetX={ props.offsetX || 0 } offsetY={ props.offsetY || 0 }>
    <i className={ 'fa fa-phone' } />
  </Badge>
)

PhoneCallBadge.propTypes = {
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
}

PhoneCallBadge.defaultProps = {
  offsetX: 0,
  offsetY: 0,
}

/**
 * 「リピ表示」に表示するバッジ
 * @param {object} props props
 */
export const ShowRepeatBadge = props => (
  <Badge
    color={ baseColor }
    offsetX={ props.offsetX || 0 }
    offsetY={ props.offsetY || 0 }
  >
    {'ﾘﾋﾟ'}
    {/* <i className={ 'fa fa-repeat' } /> */}
  </Badge>
)

ShowRepeatBadge.propTypes = {
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
}

ShowRepeatBadge.defaultProps = {
  offsetX: 0,
  offsetY: 0,
}
