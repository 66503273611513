// React
import React from 'react'
import PropTypes from 'prop-types'

// components
import Jpy from 'src/components/commons/inputs/jpy'
import { Box, Typography } from '@mui/material'

/**
 * 現金（内金）
 * @type {ReactComponent}
 */
export const CashValue = ({ value, route, update, disabled }) => {
  const identifier = `input-value-value-${route}`

  return (
    <Box
      sx={ {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, max-content)',
        columnGap: '1.5rem',
        alignItems: 'center',
      } }
    >
      <Typography
        htmlFor={ identifier }
        variant="subtitle1"
        sx={ { fontWeight: 'bold' } }
      >
        {'現金（内金）'}
      </Typography>
      <Jpy
        disabled={ disabled || route !== 'assign' }
        value={ value === '' ? null : value }
        onChange={ update }
        id={ identifier }
        name={ identifier }
        blankNotZero
      />
    </Box>
  )
}

/**
 * Validation
 * @type {object}
 */
CashValue.propTypes = {
  // ownProps
  value: PropTypes.number,
  route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
  update: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

/**
 * Defaults
 * @type {object}
 */
CashValue.defaultProps = {
  value: 0,
}

export default React.memo(CashValue)
