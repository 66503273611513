import { css } from 'styled-components'
import { baseColor, bgGray, red, borderDarkGray, textColorA } from 'src/colors'

/**
 * input[text], select, textarea に適用するCSS
 * @type {string}
 */
export const form = css`
  height: 24px;
  padding: 2px 7px;
  transition: all 0.3s;
  border: 1px solid ${borderDarkGray};
  border-radius: 3px;
  outline: none;
  color: ${textColorA};
  font-size: 100%;
  line-height: 18px;

  &:focus {
    border: 1px solid ${baseColor};
    outline: 0;
    box-shadow: 0 0 7px ${baseColor};
    z-index: 5;
  }

  &.input-invalid {
    border: 1px solid ${red};
    box-shadow: 0 0 7px ${red};
  }

  &[disabled] {
    background: ${bgGray};
    color: ${borderDarkGray};

    :-moz-placeholder {
      color: ${borderDarkGray};
    }
  }
`

export default form
