import React from 'react'
import PropTypes from 'prop-types'
import connect from './props'
import { MarucBadge, ReRequestBadge } from 'src/components/commons/badges'

// libs
import { toJPYenText, hlWorkTypeLabel } from 'src/lib/format.js'
import { master as searchMaster } from 'src/lib/search'
import buildClassName from 'classnames'
import Sticky from 'src/lib/class-sticky'
import { CompanyName } from './styled'
import { getFullAddress } from 'src/lib/search'

// config
import config from 'src/config'

/**
 * 検索結果として得られた案件付箋を表示するコンポーネント
 * @param {object} props
 * @return {Component} the component
 */
export class StickyAbbr extends React.PureComponent {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    onSelect: PropTypes.func.isRequired,
    index: PropTypes.number,
    // StateProps
    masterWorkPoints: PropTypes.array,
    masterWorkTypes: PropTypes.array,
    masterCompanies: PropTypes.array,
    cityCodes: PropTypes.object,
    accessToken: PropTypes.string.isRequired,
    isDebugMode: PropTypes.bool.isRequired,
    staffs: PropTypes.arrayOf(
      PropTypes.shape({
        staffId: PropTypes.number.isRequired,
        staffName: PropTypes.string.isRequired,
      }),
    ).isRequired,
    // dispatchProps
    getCityCodes: PropTypes.func.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    index: -1,
    masterWorkPoints: [],
    masterWorkTypes: [],
    masterPrefCodes: [],
    masterCompanies: [],
    cityCodes: {},
  }

  /**
   * constructor
   * @param  {object} props props
   * @return {void}
   */
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { prefCode } = this.props.sticky.findClient() || {}
    if (
      prefCode &&
      (!this.props.cityCodes[prefCode] ||
        this.props.cityCodes[prefCode].length === 0)
    ) {
      // prefCodeをセットしたタイミングでcityCode一覧を取得する
      const accessToken = this.props.accessToken
      this.props.getCityCodes(prefCode, accessToken)
    }
  }

  /**
   * クリックして過去履歴を洗濯した時のハンドラ
   * @return {void}
   */
  onClickToSelect = e => {
    e.stopPropagation()
    this.props.onSelect(this.props.sticky)
  }

  /**
   * エンター押下で過去履歴を洗濯した時のハンドラ
   * @return {void}
   */
  onEnterToSelect = e =>
    e.keyCode === 13 && this.props.onSelect(this.props.sticky)

  /**
   * [prepare description]
   * @param  {object} props レンダリングするためのオリジナルのProps
   * @return {object}       レンダリングに必要なProps
   */
  prepareRender = () => {
    const {
      // ownProps
      sticky,
      index,
      // stateProps
      masterWorkPoints,
      masterWorkTypes,
      masterCompanies,
      staffs,
    } = this.props

    const {
      id,
      companyId,
      determinedDateTime,
      workPoint,
      workType,
      negotiationRecords: nullaleNegotiationRecord,
      finishStateId,
      suspendReason,
      orderTypeId,
      cash,
      account,
      afterDateTime,
      stickyStatusId,
      staffId,
      houseLabo,
      parentStatus,
    } = sticky.json()

    const client = sticky.findClient() || {}
    const workPointIds = (workPoint || {}).ids || []
    const workTypeIds = (workType || {}).ids || []
    const negotiationRecords = nullaleNegotiationRecord || []
    // input -> masterとIds
    // output -> masterのvalue
    const workPointNames = workPointIds
      .map(id => {
        return (masterWorkPoints.find(wk => wk.id === id) || {}).name || ''
      })
      .join(',')

    const workTypeNames = workTypeIds
      .map(id => {
        return (masterWorkTypes.find(wk => wk.id === id) || {}).name || ''
      })
      .join(',')

    // input -> masterと都道府県コード
    const { prefName, cityName, address, buildingName } = client
    const fullAddress = getFullAddress(
      prefName,
      cityName,
      address,
      buildingName,
    )

    const isDeny = negotiationRecords.some(
      x => x.handOverStatuses === config.handOverStatuses.reject,
    )

    const isCanceled = finishStateId === config.finishState.cancel
    const parentIsCanceled = (parentStatus || '') === 'cancel'

    const isNa = finishStateId === config.finishState.na

    const isReformCompleted =
      finishStateId === config.finishState.reformComplete

    const isSuspended =
      suspendReason && suspendReason.ids
        ? suspendReason.ids.includes(config.suspendReason.suspend1)
        : false
    const parentIsSuspended = (parentStatus || '') === 'suspend'

    const isMaruc = orderTypeId === config.orderType.claimer
    const isReRequest = !!sticky.getUserValue('reRequestOf')

    const company = searchMaster(masterCompanies, companyId)
    const companyName = (company || {}).name || ''

    const isWorking = stickyStatusId === config.stickyStatus.working

    const isAdjusiting =
      !!JSON.parse(sticky.getUserValue('adjusting') || null) || false

    const hasUndecidedAfter = ((suspendReason || {}).ids || []).includes(
      config.suspendReason.afterUndecided,
    )

    const hasAfter = !!(afterDateTime && afterDateTime.date)

    const isFinished = finishStateId === config.finishState.complete

    const classNames = buildClassName({
      'sticky-abbr': true,
      'is-adjusting': isAdjusiting,
      'is-canceled': isCanceled,
      'is-na': isNa,
      'is-reform-completed': isReformCompleted,
      'is-suspended': isSuspended,
      'is-working': isWorking && !hasAfter,
      'has-undecided-after': hasUndecidedAfter,
      'has-after': hasAfter,
      'is-finished': isFinished,
    })

    const staffName =
      staffId === null
        ? '(未アサイン)'
        : (staffs.find(staff => staff.staffId === staffId) || {}).staffName ||
          '(不明)'

    // ハウスラボ二人工付箋の種類
    const twoPersonWorkTypeId =
      (houseLabo || {}).twoPersonWorkTypeId ||
      config.houseLaboTwoWorkType.normal
    const hlWorkType = hlWorkTypeLabel(twoPersonWorkTypeId)

    return {
      id,
      classNames,
      client,
      determinedDate: (determinedDateTime || {}).date || '',
      cashValue: (cash || {}).value || '',
      accountValue: (account || {}).value || '',
      companyName,
      address: fullAddress,
      workPointNames,
      workTypeNames,
      isMaruc,
      isReRequest,
      isDeny,
      isCanceled,
      parentIsCanceled,
      isSuspended,
      parentIsSuspended,
      companyId,
      index,
      staffName,
      hlWorkType,
    }
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const {
      id,
      classNames,
      client,
      address,
      determinedDate,
      cashValue,
      accountValue,
      companyName,
      workPointNames,
      workTypeNames,
      isMaruc,
      isReRequest,
      isDeny,
      // isCanceled,
      parentIsCanceled,
      // isSuspended,
      parentIsSuspended,
      companyId,
      index,
      staffName,
      hlWorkType,
    } = this.prepareRender()

    const { isDebugMode } = this.props

    return (
      <div
        className={ classNames }
        onClick={ this.onClickToSelect }
        onKeyDown={ this.onEnterToSelect }
        tabIndex={ 0 }
        role={ 'navigation' }
        style={ { position: 'relative' } }
      >
        <div className={ 'flex-space' }>
          <p className={ 'sticky-abbr-name' }>
            {client.name ? client.name + ' 様' : '( 名前未入力) '}
          </p>
          <p>
            {isDeny ? (
              <span className={ 'sticky-abbr-tag-red' }>{'今後注文不可'}</span>
            ) : null}
            {parentIsCanceled ? (
              <span className={ 'sticky-abbr-tag-gray' }>{'キャンセル復活'}</span>
            ) : null}
            {parentIsSuspended ? (
              <span className={ 'sticky-abbr-tag-gray' }>{'保留復活'}</span>
            ) : null}
            {hlWorkType.display ? (
              <span className={ 'sticky-abbr-tag-gray' }>{hlWorkType.label}</span>
            ) : null}
          </p>
        </div>
        <p className={ 'sticky-abbr-name' }>{`スタッフ: ${staffName}`}</p>
        <p className={ 'sticky-abbr-address' }>{address}</p>
        <p className={ 'sticky-abbr-phone' }>{client.phoneNumber}</p>
        <p className={ 'sticky-abbr-time' }>{determinedDate}</p>
        <p className={ 'sticky-abbr-content' }>
          {workPointNames +
            ' ' +
            workTypeNames +
            ' (現金: ' +
            toJPYenText(cashValue) +
            '/売掛金:' +
            toJPYenText(accountValue) +
            ')'}
        </p>
        {companyName ? (
          <CompanyName companyId={ companyId } companyName={ companyName } />
        ) : null}

        {isMaruc && <MarucBadge offsetX={ 10 } offsetY={ 10 } />}
        {isReRequest && <ReRequestBadge offsetX={ 10 } offsetY={ 10 } />}

        {isDebugMode && (
          <button
            onClick={ () =>
              console.log({
                prefCode: client.prefCode,
                cityCode: client.cityCode,
                cityCodes: this.props.cityCodes,
              })
            }
          >
            {'デバッグ'}
          </button>
        )}

        {isDebugMode && <span>{`${index}番目 stickyId: ${id}`}</span>}
      </div>
    )
  }
}

/**
 * connected as default
 * @type {Function}
 */
export default connect(StickyAbbr)
