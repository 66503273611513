// react
import React from 'react'
import PropTypes from 'prop-types'

// components
import Modal from 'react-modal'
import ChangeLogsView from './change-logs-view'

// lib
import config from 'src/config'
import ClassSticky from 'src/lib/class-sticky'

export default class ChangeLogs extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    stickyId: PropTypes.number,
    orderId: PropTypes.string,
    companyId: PropTypes.number,
    // route: PropTypes.string.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    stickyId: -1,
    orderId: '',
    companyId: config.company.ES,
  }

  state = { isChangeLogsOpen: false }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps, nextState) {
    return this.state.isChangeLogsOpen !== nextState.isChangeLogsOpen
  }

  onOpen = () => this.setState({ isChangeLogsOpen: true })
  onClose = () => this.setState({ isChangeLogsOpen: false })

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { isChangeLogsOpen } = this.state
    const { stickyId, orderId, companyId } = this.props
    const disabled = !orderId
    const backGroundColor = ClassSticky.backGroundColor(companyId)

    return (
      <div className={ 'change-logs-wrap' }>
        <button
          className={ 'button button-view-change-logs' }
          onClick={ this.onOpen }
          disabled={ disabled }
        >
          <i className={ 'fa fa-history' } />
          {' 変更履歴確認'}
        </button>
        <div>
          <Modal
            contentLabel={ 'change-logs' }
            isOpen={ isChangeLogsOpen }
            ariaHideApp={ false }
            style={ { content: { background: backGroundColor } } }
          >
            <ChangeLogsView
              stickyId={ stickyId }
              orderId={ orderId }
              evenBackGroundColor={ backGroundColor }
              onCloseClick={ this.onClose }
            />
          </Modal>
        </div>
      </div>
    )
  }
}
