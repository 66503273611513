import Sticky from 'src/lib/class-sticky'
import config from 'src/config'

/**
 * コール課での流し込み実行前に付箋を変形する
 * @param  {Sticky} sticky 付箋インスタンス
 * @param  {object} option オプション
 * @return {Sticky}        変形後の付箋インスタンス
 */
const transformBeforeGenerateReRequest = sticky => {
  const originalId = sticky.id
  // 注文タイプの再設定
  // 注文タイプ　|新規　リピ　マルシー
  // ----------+------------------
  // 再依頼　　　|新規　リピ　リピ
  if (sticky.isClaimerOrder) {
    // マルシーの時リピート
    sticky._props.orderTypeId = config.orderType.repeat
  }
  // 付箋の支払情報をクリアする。
  sticky._props.contacts &&
    sticky._props.contacts.forEach(idcontact => {
      idcontact.forEach(contact => {
        contact.id = null
        contact.stickyId = null
        contact.paymentInfo = null
      })
    })
  // 付箋から不要な情報を削除して返す
  return sticky
    .remove({
      id: true,
      // 流し込みに当たって、いらないデータを消しておく
      negotiationRecords: true,
      // アサイン課の領分
      account: true,
      afterDateTime: true,
      cancelReasonId: true,
      cash: true,
      childId: true,
      completedAt: true,
      createAt: true,
      createUserId: true,
      creditCardId: true,
      creditPaymentMethodId: true,
      displayOrder: true,
      displayDate: true,
      estimateNumber: true,
      estimatePayment: true,
      finishState: true,
      finishStateId: true,
      receivedAt: true,
      reRequest: true,
      reRequestTime: true,
      reRequestUser: true,
      stickyStatusId: true, // これは消しても次のステップで0になる
      suspendReason: true,
      thankYouCall: true,
      updateAt: true,
      updateUserId: true,
      workContent: true,
    })
    .update(new Sticky({ reRequest: sticky.json().orderId }), { shallow: true })
    .setUserValue('reRequestOf', originalId)
}

export default transformBeforeGenerateReRequest
