import config from 'src/config'
import { obj2arr, obj2arrDeep } from './obj2arr'

/**
 * 付箋プロパティをマージする、カスタムマージャー
 * 配列やオブジェクトを掘ってマージするもの
 * @param  {StickyUpdator|Sticky} sticky アップデート元の値
 * @param  {StickyUpdator} updator       nullでスキップするアップデート値
 * @return {Sticky}                      アップデート後の値
 */
export default (sticky, updator) => {
  // --------------- 下処理ここから------------------------------------
  // updatorのnullやundefinedがマージされないように消す

  // そのままリテラル値なパラメータの消毒
  config.constants.stickyProps.shallowLiteral.forEach(propName => {
    if (updator[propName] === null || updator[propName] === void 0) {
      delete updator[propName]
    }
  })

  // 1層入れ子のオブジェクト型のパラメータの消毒
  config.constants.stickyProps.shallowObject.forEach(propName => {
    if (typeof updator[propName] !== 'object') {
      delete updator[propName]
    }
  })

  // オブジェクト型の配列のパラメータの消毒
  // それぞれの値についてマージが必要になる
  config.constants.stickyProps.arrayedObject.forEach(propName => {
    if (
      !Array.isArray(updator[propName]) &&
      typeof updator[propName] !== 'object'
    ) {
      delete updator[propName]
    }
  })

  // 2重入れ子のパラメータの消毒
  config.constants.stickyProps.arrayedArrayedLiteral.forEach(propName => {
    if (Array.isArray(updator[propName])) {
      updator[propName].forEach((tabContacts, tabIndex) => {
        if (Array.isArray(tabContacts)) {
          tabContacts.forEach((contact, contactsIndex) => {
            if (typeof contact !== 'object') {
              updator[propName][tabIndex][contactsIndex] = null
            }
          })
        } else {
          updator.contacts[tabIndex] = null
        }
      })
    } else if (typeof updator[propName] === 'object') {
      // あとでフィルタするよ
    } else {
      delete updator.contacts
    }
  })

  // --------------- 下処理ここまで ------------------------------------

  const result = {
    // shallowなやつを全部マージするよ
    ...sticky,
    ...updator,

    // オブジェクト1層入れ子なやつをディープマージ
    ...config.constants.stickyProps.shallowObject.reduce((prev, propName) => {
      if (updator[propName] === void 0 || updator[propName] === null) {
        return prev
      } else {
        prev[propName] = { ...sticky[propName], ...updator[propName] }
        return prev
      }
    }, {}),

    // オブジェクトの配列の奴については、index値、idをそのままにそれぞれをマージ
    ...config.constants.stickyProps.arrayedObject.reduce((prev, propName) => {
      const updateOf = sticky[propName] || []
      let updateBy
      if (Array.isArray(updator[propName])) {
        updateBy = updator[propName] || []
      } else if (typeof updator[propName] === 'object') {
        updateBy = obj2arr(updator[propName])
      } else {
        updateBy = []
      }

      const maxLength = Math.max(updateOf.length, updateBy.length)

      const result = []
      for (let i = 0; i < maxLength; i++) {
        result.push(
          updateBy[i]
            ? {
              ...updateOf[i],
              ...updateBy[i],
              // id: (updateOf[i] && updateOf[i].id) || updateBy,
            }
            : {
              ...updateOf[i],
            },
        )
      }
      prev[propName] = result
      return prev
    }, {}),

    // 最後にcontactsをマージ
    // メモ： 汎用的な書き方にはなっているが、contactsに特化している。
    ...config.constants.stickyProps.arrayedArrayedLiteral.reduce(
      (prev, propName) => {
        const updateOf = sticky[propName] || []

        // updatorがオブジェクトの添字で指定された場合は変換する
        let updateBy
        if (Array.isArray(updator[propName])) {
          updateBy = updator[propName]
        } else if (typeof updator[propName] === 'object') {
          updateBy = obj2arrDeep(updator[propName])
        } else {
          updateBy = []
        }

        const maxLength1 = Math.max(updateOf.length, updateBy.length)

        const result1 = []
        for (let i = 0; i < maxLength1; i++) {
          const updateOfOf = updateOf[i] || [] // 更新前のcontacts[tab]
          const updateByBy = updateBy[i] || [] // 更新するcontacts[tab]
          const maxLength2 = Math.max(updateOfOf.length, updateByBy.length)

          const result2 = []
          for (let j = 0; j < maxLength2; j++) {
            // 基本はマージ
            // of: [{1}, {2}]  by: [{1'}, {2'}] => result2: [{1+1'}, {2+2'}]
            // updateByBy[j] === -1 の時は削除,　空のときは元のデータ
            // of: [{1}, {2}, {3}]  by: [{1'}, -1, {}] => result2: [{1+1'}, {3}]
            // src/components/commons/custom-controls/contacts/delete-button.jsxでidなしのcontactsを削除するため
            // updateByBy[j].noMerge === true の時は元のデータとマージしない（順番の入れ替え）
            // of: [{1}, {2}, {3}] by: [{2', noMerge}, {1',noMerge}] => result2: [{2'}, {1'}, {3}]
            if (updateByBy[j] !== -1) {
              if (updateByBy[j]) {
                const displayOrder = j + 1
                //
                if (updateByBy[j].noMerge) {
                  // noMergeがある時は置き換え
                  const item = {
                    ...updateByBy[j],
                    displayOrder,
                  }
                  result2.push(item)
                } else if (updateByBy[j].moved) {
                  // movedがある時は他の情報を削除する。
                  const item = {
                    id: updateByBy[j].id,
                    moved: true,
                    displayOrder,
                  }
                  result2.push(item)
                } else {
                  // 元のデータとマージ
                  const id =
                    (updateOfOf[j] && updateOfOf[j].id) ||
                    (updateByBy[j] && updateByBy[j].id) ||
                    null
                  result2.push({
                    ...updateOfOf[j],
                    ...updateByBy[j],
                    id,
                    displayOrder,
                  })
                }
              } else {
                result2.push({ ...updateOfOf[j] })
              }
            }
          }

          result1.push(result2)
        }

        prev[propName] = result1
        return prev
      },
      {},
    ),
  }
  return result
}
