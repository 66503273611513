/**
 * バッチ処理実施reducer
 */

import { CALL_API } from 'redux-api-middleware'
import { NETWORKER } from 'src/middlewares/network-monitor'

import update from 'immutability-helper'
import switz from 'switz'
import config from 'src/config'
import { ACTION_TYPES as NETWORK_ACTION_TYPES } from 'src/reducers/network-status'

/**
 * initial state
 * @type {object}
 */
export const initialBatchState = {
  remoteStatus: {},
}

export const BATCH_POST_START_REQUEST = 'BATCH.BATCH_POST_START_REQUEST'
export const BATCH_POST_START_SUCCESS = 'BATCH.BATCH_POST_START_SUCCESS'
export const BATCH_POST_START_FAILURE = 'BATCH.BATCH_POST_START_FAILURE'
export const BATCH_GET_STATUS_REQUEST = 'BATCH.BATCH_GET_STATUS_REQUEST'
export const BATCH_GET_STATUS_SUCCESS = 'BATCH.BATCH_GET_STATUS_SUCCESS'
export const BATCH_GET_STATUS_FAILURE = 'BATCH.BATCH_GET_STATUS_FAILURE'

/**
 * action bulk exports
 * @type {object}
 */
export const ACTION_TYPES = {
  BATCH_POST_START_REQUEST,
  BATCH_POST_START_SUCCESS,
  BATCH_POST_START_FAILURE,
  BATCH_GET_STATUS_REQUEST,
  BATCH_GET_STATUS_SUCCESS,
  BATCH_GET_STATUS_FAILURE,
}

/**
 * postStartのネットワーク状態識別子を取得する
 * @param  {string} slug どのバッチ処理かを識別するslug
 * @return {string}      得られたネットワーク状態識別子
 */
export const getPostStartNetworkTarget = slug => `postStartBatch-${slug}`

/**
 * getStatusのネットワーク状態識別子を取得する
 * @param  {string} slug どのバッチ処理かを識別するslug
 * @return {string}      得られたネットワーク状態識別子
 */
export const getGetStatusNetworkTarget = slug => `getStatusBatch-${slug}`

/**
 * AsyncPostBatchActionを作成するアクションクリエーター
 * @param  {string} slug        batchのslug
 * @param  {string} url         url
 * @param  {string} accessToken access token
 * @return {object}             redux action
 */
export const createAsyncPostStartBatchAction = (
  slug,
  url,
  accessToken,
  env,
) => {
  /* eslint-disable no-console */
  !__TEST__ && console.log(`${env.API_HTTPS ? 'https' : 'http'}://${url}`)
  /* eslint-enable no-console */

  const networkerTarget = getPostStartNetworkTarget(slug)

  return {
    [CALL_API]: {
      endpoint: `${config.API.schema}://${url}`,
      method: 'POST',
      headers: { Authorization: `Bearer ${accessToken}` },
      types: [
        {
          type: BATCH_POST_START_REQUEST,
          meta: {
            [NETWORKER]: {
              target: networkerTarget,
              networkActionType: NETWORK_ACTION_TYPES.REQUEST,
            },
          },
        },
        {
          type: BATCH_POST_START_SUCCESS,
          meta: {
            slug,
            [NETWORKER]: {
              target: networkerTarget,
              networkActionType: NETWORK_ACTION_TYPES.SUCCESS,
            },
          },
          payload: (action, state, res) => res.json().then(payload => payload),
        },
        {
          type: BATCH_POST_START_FAILURE,
          meta: {
            slug,
            [NETWORKER]: {
              target: networkerTarget,
              networkActionType: NETWORK_ACTION_TYPES.FAILURE,
            },
          },
        },
      ],
    },
  }
}

/**
 * AsyncgetBatchStatusActionを作成するアクションクリエーター
 * @param  {string} slug        batchのslug
 * @param  {string} url         url
 * @param  {string} accessToken access token
 * @return {object}             redux action
 */
export const createAsyncGetBatchStatusAction = (slug, url, accessToken) => {
  /* eslint-disable no-console */
  !__TEST__ && console.log(`${config.API.schema}://${url}`)
  /* eslint-enable no-console */

  const networkerTarget = getGetStatusNetworkTarget(slug)

  return {
    [CALL_API]: {
      endpoint: `${config.API.schema}://${url}`,
      method: 'GET',
      headers: { Authorization: `Bearer ${accessToken}` },
      types: [
        {
          type: BATCH_GET_STATUS_REQUEST,
          meta: {
            [NETWORKER]: {
              target: networkerTarget,
              networkActionType: NETWORK_ACTION_TYPES.REQUEST,
            },
          },
        },
        {
          type: BATCH_GET_STATUS_SUCCESS,
          meta: {
            slug,
            [NETWORKER]: {
              target: networkerTarget,
              networkActionType: NETWORK_ACTION_TYPES.SUCCESS,
            },
          },
          payload: (action, state, res) => res.json().then(payload => payload),
        },
        {
          type: BATCH_GET_STATUS_FAILURE,
          meta: {
            slug,
            [NETWORKER]: {
              target: networkerTarget,
              networkActionType: NETWORK_ACTION_TYPES.FAILURE,
            },
          },
        },
      ],
    },
  }
}

/**
 * batch reducer
 * @param  {object} [state=initialBatchState] previous state
 * @param  {object} action                    dispatched action
 * @return {object}                            next state
 */
export default (state = initialBatchState, action) => {
  const { type, payload, meta } = action
  return switz(type, s =>
    s
      .case(BATCH_POST_START_REQUEST, () => state)
      .case(BATCH_POST_START_SUCCESS, () => {
        return update(state, {
          remoteStatus: {
            [meta.slug]: { $set: 'trying' },
          },
        })
      })
      .case(BATCH_POST_START_FAILURE, () => state)
      .case(BATCH_GET_STATUS_REQUEST, () => state)
      .case(BATCH_GET_STATUS_SUCCESS, () => {
        return update(state, {
          remoteStatus: {
            [meta.slug]: { $set: payload },
          },
        })
      })
      .case(BATCH_GET_STATUS_FAILURE, () => state)
      .default(() => state),
  )
}
