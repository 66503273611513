import styled from 'styled-components'

/**
 * トグルボタンの基底コンポーネント
 * @type {ReactComponent}
 */
export const ToggleButton = styled.button`
  position: fixed;
  display: block;
  top: calc(50% + ${props => props.offsetY + 'px'});
  width: 30px;
  height: 50px;
  transition-duration: 0.2s;
  border: none;
  z-index: 8888888;
  opacity: 0.6;
  background: gray;
  color: white;

  &:hover {
    cursor: pointer;
  }

  /* stylelint-disable declaration-block-semicolon-newline-after */
  ${props => props.side}: ${props => (props.isOpen ? '360px' : 0)};
  border-top-left-radius: ${props => (props.side === 'right' ? '25px' : 0)};
  border-bottom-left-radius: ${props => (props.side === 'right' ? '25px' : 0)};
  border-top-right-radius: ${props => (props.side === 'left' ? '25px' : 0)};
  border-bottom-right-radius: ${props => (props.side === 'left' ? '25px' : 0)};
`

/**
 * ダンプ内容表示エリアを包むコンポーネントの基底コンポーネント
 * @type {ReactComponent}
 */
export const Wrap = styled.div`
  position: fixed;
  display: block;
  top: 0;
  width: 300px;
  height: 100%;
  opacity: 0.6;
  background: gray;
  color: white;
  overflow: scroll;
  z-index: 8888888;
  margin: 0;
  padding: 30px;
  transition-duration: 0.2s;
  line-height: 1.2;
  ${props => props.side}: ${props => (props.isOpen ? 0 : '-360px')};
`

/**
 * オブジェクト改ざん用テキストエリアのコンポーネント
 * @type {ReactComponent}
 */
export const TextArea = styled.textarea`
  background: none;
  border: none;
  font-size: inherit;
  color: inherit;
  font-family: inherit;
  line-height: inherit;
  display: ${props => (props.show ? 'block' : 'none')};
  width: 100%;
  height: 100%;
`

/**
 * オブジェクト表示エリアのコンポーネント
 * @type {ReactComponent}
 */
export const Pre = styled.pre`
  display: ${props => (props.show ? 'block' : 'none')};
  cursor: ${props => (props.editable ? 'text' : 'default')};
`
