import React from 'react'
import PropTypes from 'prop-types'
import connect from 'src/components/commons/custom-controls/connect'

// component
import AddButton from '../house-labo/add-button'
import CalcButton from './calc-button' // TODO: カスタマイズ必要
import DeleteButton from '../house-labo/delete-button'
import WrappedCalendar from 'src/components/commons/wrapped-calendar'
import TimeInput from 'src/components/commons/custom-controls/assign/house-labo-client/time-input'
import ReactDataSheet from 'react-datasheet'
import HouseLaboWork from '../house-labo/house-labo-work'

// lib
import Sticky from 'src/lib/class-sticky'
import styled from 'styled-components'
import { borderDarkGray, lightGreen } from 'src/colors'
import { toJPYenText, yenToValue } from 'src/lib/format'
import { isPaymentMaster, salesTax, calcTotal } from './util'
// import config from 'src/config'
import 'react-datasheet/lib/react-datasheet.css'
import moment from 'moment'
import config from 'src/config'

export const Hr = styled.hr`
  height: 1px;
  border: 0;
  background-color: ${borderDarkGray};
`
// セルの値の型
const SVTypeText = 0 // 文字列
const SVTypePayment = 1 // 金額

/**
 * ホームサーブ（ES提携）の画面
 * TODO: 付箋でホームサーブのデータはsrc/config.js(573)のstickyProps.shallowObjectに登録することで、1レベルのマージが行われる。
 * @type {ReactComponent}
 */
/* eslint-disable react/jsx-no-bind */
export class HomeServe extends React.Component {
  /**
   * Validation
   * @type {object}
   */
  static propTypes = {
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    route: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    // Auto bind action creator
    updateStage: PropTypes.func.isRequired,
  }

  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    this.state = {
      showWorkTimes: false, // 作業時間の表示
    }
  }

  /**
   *
   */
  // componentDidMount() {
  //   // this.initialSelectState()
  // }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.sticky !== nextProps.sticky ||
      this.props.disabled !== nextProps.disabled ||
      this.state.showWorkTimes !== nextState.showWorkTimes
    )
  }

  /**
   * 主にテーブルで入力不可の状態をチェックするために使用
   */
  isInputDisabled = () => {
    return this.props.disabled
    // TODO: 要検討
    // const { sticky, disabled } = this.props
    // const stickyProps = sticky.json() || {}
    // const houseLabo = stickyProps.houseLabo || {}
    // const workReport = houseLabo.workReport || false // 報告書確認済み
    // return disabled || workReport
  }

  /**
   * 作業箇所の追加
   */
  addEsWorks = () => {
    const { sticky, updateStage } = this.props
    const stickyProps = sticky.json() || {}
    const esWorks = stickyProps.esWorks || []
    esWorks.push({
      id: null,
      condition: '',
      cause: '',
      result: '',
      remark: '',
    })
    const diffProps = { esWorks: [...esWorks] }
    updateStage(new Sticky(diffProps))
  }

  /**
   * 作業箇所の削除
   * @param {*} index
   */
  removeEsWorks = index => {
    const { sticky, updateStage } = this.props
    const stickyProps = sticky.json() || {}
    const esWorks = stickyProps.esWorks || []
    esWorks.splice(index, 1)
    const diffProps = { esWorks: [...esWorks] }
    updateStage(new Sticky(diffProps))
  }

  /**
   * 作業箇所の更新
   * @param {*} index
   * @param {*} key
   * @param {*} value
   */
  updateEsWorks = (index, key, value) => {
    const { sticky, updateStage } = this.props
    const stickyProps = sticky.json() || {}
    const esWorks = stickyProps.esWorks || []
    esWorks[index] = {
      ...esWorks[index],
      [key]: value,
    }
    const diffProps = { esWorks: [...esWorks] }
    updateStage(new Sticky(diffProps))
  }

  /**
   * 作業箇所のテキスト変更
   * @param {*} e
   * @param {*} index
   * @param {*} key
   */
  updateEsWorkText = (e, index, key) =>
    this.updateEsWorks(index, key, e.target.value)

  /**
   * 作業時間の更新
   * @param {*} index
   * @param {*} key
   * @param {*} value
   */
  updateEsWorkTimes = (index, key, value) => {
    const { sticky, updateStage } = this.props
    const stickyProps = sticky.json() || {}
    const esWorkTimes = stickyProps.esWorkTimes || []
    // 指定されたインデックスまでで値が未設定の時は空の値を設定する。
    // ex. 1と4だけに入力した場合も考慮。
    for (let i = 0; i <= index; i++) {
      if (!esWorkTimes[i]) {
        esWorkTimes[i] = {
          id: null,
          date: '',
          startAt: '',
          finishAt: '',
        }
      }
    }
    esWorkTimes[index] = {
      ...esWorkTimes[index],
      [key]: value,
    }
    const diffProps = { esWorkTimes: [...esWorkTimes] }
    updateStage(new Sticky(diffProps))
  }

  /**
   * マスタの金額データを更新する。feeIdがない時は追加する
   * @param {*} esFees
   * @param {*} feeId
   * @param {*} key
   * @param {*} value
   */
  updateInsertEsFees = (esFees, feeId, key, value) => {
    // 入力不可になった時は何もしない
    if (this.isInputDisabled()) {
      return
    }
    if (esFees.some(fee => fee.feeId === feeId)) {
      esFees.forEach(fee => (fee.feeId === feeId ? (fee[key] = value) : false))
    } else {
      esFees.push({ feeId, [key]: value })
    }
  }

  /**
   * マスタの金額データを更新する。feeIdがない時は追加する
   * @param {*} feeId
   * @param {*} key
   * @param {*} value
   */
  updateEsFees = (feeId, key, value) => {
    const { sticky, updateStage } = this.props
    const stickyProps = sticky.json() || {}
    const es = stickyProps.es || {}
    const esFees = stickyProps.esFees || []
    this.updateInsertEsFees(esFees, feeId, key, value)
    // 合計金額を再計算して保存する
    const { allTotal } = calcTotal({ ...stickyProps, esFees })
    const diffProps = {
      es: {
        ...es,
        totalFee: allTotal,
      },
      esFees: [...esFees],
    }
    updateStage(new Sticky(diffProps))
  }

  /**
   * 作業費を追加
   */
  addWorkFee = () => {
    const { sticky, updateStage } = this.props
    const stickyProps = sticky.json() || {}
    const es = stickyProps.es || {}
    const workFee = es.workFee || []
    workFee.push(0)
    const diffProps = {
      es: {
        ...es,
        workFee: workFee,
      },
    }
    updateStage(new Sticky(diffProps))
  }

  /**
   * 作業費を削除
   * @param {*} index
   */
  removeWorkFee = index => {
    const { sticky, updateStage } = this.props
    const stickyProps = sticky.json() || {}
    const es = stickyProps.es || {}
    const workFee = es.workFee || []
    workFee.splice(index, 1)
    // 合計金額を再計算する
    const { allTotal } = calcTotal({ ...stickyProps, es: { workFee: workFee } })
    const diffProps = {
      es: {
        ...es,
        workFee: workFee,
        totalFee: allTotal,
      },
    }
    updateStage(new Sticky(diffProps))
  }

  /**
   * 部品代を追加
   */
  addEsPartsFees = () => {
    const { sticky, updateStage } = this.props
    const stickyProps = sticky.json() || {}
    const esPartsFees = stickyProps.esPartsFees || []
    esPartsFees.push({
      id: null,
      title: '',
      fee: 0,
    })
    const diffProps = { esPartsFees: [...esPartsFees] }
    updateStage(new Sticky(diffProps))
  }

  /**
   * 部品代を削除
   * @param {*} index
   */
  removeEsPartsFees = index => {
    const { sticky, updateStage } = this.props
    const stickyProps = sticky.json() || {}
    const es = stickyProps.es || {}
    const esPartsFees = stickyProps.esPartsFees || []
    esPartsFees.splice(index, 1)
    // 合計金額を再計算する
    const { allTotal } = calcTotal({ ...stickyProps, esPartsFees })
    const diffProps = {
      es: {
        ...es,
        totalFee: allTotal,
      },
      esPartsFees: [...esPartsFees],
    }
    updateStage(new Sticky(diffProps))
  }

  /**
   * ①：出動費等が変更された
   */
  onGrid1CellsChanged = changes => {
    /**
     * テーブルのセルのタイプに応じて値を変換する
     * @param {*} cell
     * @param {*} value
     * @returns
     */
    const toValue = (cell, value) => {
      if (cell.svtype === SVTypeText) {
        return value
      } else {
        // 数値として処理できる時は数値化→文字列変換する。数値にならない時は '0'を設定する
        // 数字の後に文字列を入力できたので、数値だけを文字列変換して保存する。
        return isNaN(parseInt(value, 10)) ? '0' : parseInt(value, 10).toString()
      }
    }
    // 入力不可になった時は何もしない
    if (this.isInputDisabled()) {
      return
    }
    const { sticky, updateStage } = this.props
    const stickyProps = sticky.json() || {}
    const es = stickyProps.es || {}
    const esFees = stickyProps.esFees || []
    // 実質col === 1の時だけが対象。esFees[{feeId=cell.svfeeid}]の値を更新
    changes.forEach(({ col, cell, value }) => {
      if (col === 1) {
        const val = toValue(cell, value)
        this.updateInsertEsFees(esFees, cell.svfeeid, 'fee', val)
      }
    })
    // 合計金額を再計算して保存する
    const { allTotal } = calcTotal(stickyProps)
    const diffProps = {
      es: {
        ...es,
        id: es.id || null,
        totalFee: allTotal,
      },
      esFees: [...esFees],
    }
    updateStage(new Sticky(diffProps))
  }

  /**
   * ②：作業費が変更された
   */
  onGrid2CellsChanged = changes => {
    const toValue = value => {
      const val = parseInt(value, 10)
      return isNaN(val) ? 0 : val
    }
    // 入力不可になった時は何もしない
    if (this.isInputDisabled()) {
      return
    }
    const { sticky, updateStage } = this.props
    const stickyProps = sticky.json() || {}
    const es = stickyProps.es || {}
    const workFee = es.workFee || []

    // col === 1の時だけが対象。
    changes.forEach(({ col, row, value }) => {
      const index = row - 1
      if (col === 1) {
        const val = toValue(value)
        workFee[index] = val
      }
    })
    // 合計金額を再計算して保存する
    const { allTotal } = calcTotal(stickyProps)
    const diffProps = {
      es: {
        ...es,
        id: es.id || null,
        workFee,
        totalFee: allTotal,
      },
    }
    updateStage(new Sticky(diffProps))
  }

  /**
   * ③：部品代が変更された
   */
  onGrid3CellsChanged = changes => {
    const toValue = value => {
      const val = parseInt(value, 10)
      return isNaN(val) ? 0 : val
    }
    // 入力不可になった時は何もしない
    if (this.isInputDisabled()) {
      return
    }
    const { sticky, updateStage } = this.props
    const stickyProps = sticky.json() || {}
    const es = stickyProps.es || {}
    const esPartsFees = stickyProps.esPartsFees || []

    changes.forEach(({ col, row, value }) => {
      const index = row - 1
      if (col === 0) {
        esPartsFees[index] = {
          ...esPartsFees[index],
          title: value,
        }
      } else if (col === 1) {
        const val = toValue(value)
        esPartsFees[index] = {
          ...esPartsFees[index],
          fee: val,
        }
      }
    })
    // 合計金額を再計算して保存する
    const { allTotal } = calcTotal(stickyProps)
    const diffProps = {
      es: {
        ...es,
        id: es.id || null,
        totalFee: allTotal,
      },
      esPartsFees,
    }
    updateStage(new Sticky(diffProps))
  }

  /**
   * 有料道路代が変更された
   */
  onhighwayFeeCellsChanged = changes => {
    const toValue = value => {
      const val = parseInt(value, 10)
      return isNaN(val) ? 0 : val
    }
    // 入力不可になった時は何もしない
    if (this.isInputDisabled()) {
      return
    }
    const { sticky, updateStage } = this.props
    const stickyProps = sticky.json() || {}
    const es = stickyProps.es || {}
    changes.forEach(({ col, value }) => {
      if (col === 1) {
        const val = toValue(value)
        es.highwayFee = val
      }
    })
    // 合計金額を再計算して保存する
    const { allTotal } = calcTotal(stickyProps)
    const diffProps = {
      es: {
        ...es,
        id: es.id || null,
        highwayFee: es.highwayFee,
        totalFee: allTotal,
      },
    }
    updateStage(new Sticky(diffProps))
  }

  /**
   * 付箋のデータをグリッド用のデータに変換する
   * @param {*} esMaster ES提携クライアントマスタの金額データ：①のみで使用。
   * @param {*} esFees 付箋の金額情報　①②③でデータ形式は異なる。
   * @param {*} index 金額表のインデックス
   */
  makeHomeServeFeesGrid = (esMaster, esFees, index) => {
    // 標準属性
    // value: 表示する値
    // component: 表示するコンポーネント
    // readOnly: 編集不可の時はtrue
    // width: セルの幅
    // カスタム属性
    // svheader: 見出しの時true
    // svnoboder: 枠線を消すときtrue
    // svcomponent: valueではなくcomponentを表示する時true
    // svtype: セルのデータ型（数値・文字列など）
    // svfeeid: セルの項目ID
    // svtotalvalue: 合計金額の欄

    const inputDisabled = this.isInputDisabled()
    // ---------------------------------------------------------------
    // 金額の列を作成する
    const valueItem = (master, fee) => {
      const item = {
        svfeeid: master.feeId, // 項目ID
      }
      if (master.inputType === 11) {
        // 文字列
        item.value = fee.fee || ''
        item.svtype = SVTypeText
      } else {
        // 金額
        item.value = fee.fee || 0
        item.svtype = SVTypePayment
      }
      return item
    }

    // ---------------------------------------------------------------
    // 計算機ボタンの列を作成する
    const calcItem = (sticky, master) => {
      // 計算対象の項目の時のみボタンを表示する
      const calcInputTypes = [
        config.homeServeFeeInputType.dispatch,
        config.homeServeFeeInputType.holiday,
        config.homeServeFeeInputType.fardistance,
      ]
      if (calcInputTypes.includes(master.inputType)) {
        return {
          component: (
            <CalcButton
              inputType={ master.inputType }
              params={ master.params }
              sticky={ sticky }
              onDecide={ value => this.updateEsFees(master.feeId, 'fee', value) }
              disabled={ inputDisabled }
            />
          ),
          svcomponent: 'true',
        }
      } else {
        return { value: '', readOnly: true }
      }
    }

    // ---------------------------------------------------------------
    // 見出し
    const head = [
      [
        { value: '項目', readOnly: true, svheader: 'true', width: 280 },
        {
          value: '金額（税抜）',
          readOnly: true,
          svheader: 'true',
          width: 240,
        },
        { value: '', readOnly: true, svheader: 'true', width: 40 }, // 計算機ボタン
      ],
    ]

    // ---------------------------------------------------------------
    // 表示のためのデータを作成する
    const { sticky } = this.props
    // ---------------------------------------------------------------
    // 表ごとのデータの作成方法
    // ---------------------------------------------------------------
    // 表①：マスタと付き合わせた金額
    const grid1 = () => {
      let total = 0
      const grid = head.concat(
        esMaster.map(master => {
          const fee = esFees.find(fee => fee.feeId === master.feeId) || {}
          const row = []
          // 項目
          row.push({
            value: master.name,
            readOnly: true,
            svheader: 'true',
          })
          // 金額
          row.push(valueItem(master, fee))
          if (isPaymentMaster(master)) {
            total += parseInt(fee.fee || '0', 10)
          }
          // 計算機ボタン
          row.push(calcItem(sticky, master))
          return row
        }),
      )
      return { grid, total }
    }

    // ---------------------------------------------------------------
    // 表②：作業費: esFees は金額の配列
    const grid2 = () => {
      let total = 0
      const grid = head.concat(
        esFees.map((fee, i) => {
          const row = []
          // 項目
          row.push({
            value: '作業費', // 名前は固定
            readOnly: true,
            svheader: 'true',
          })
          // 金額
          row.push({
            value: fee,
            svtype: SVTypePayment,
          })
          // 合計
          total += fee
          // 削除ボタン
          row.push({
            component: (
              <DeleteButton
                onClick={ () => {
                  this.removeWorkFee(i)
                } }
              />
            ),
            svcomponent: 'true',
          })
          return row
        }),
      )
      return { grid, total }
    }

    // ---------------------------------------------------------------
    // 表③部品代等：「項目」は編集可能
    const grid3 = () => {
      let total = 0
      const grid = head.concat(
        esFees.map((fee, i) => {
          const row = []
          // 項目
          row.push({
            value: fee.title || '',
            readOnly: false, // 項目名を編集可能にする
            svheader: 'true',
          })
          // 金額
          row.push({
            value: fee.fee || 0,
            svtype: SVTypePayment,
          })
          // 合計
          total += fee.fee
          // 削除ボタン
          row.push({
            component: (
              <DeleteButton
                onClick={ () => {
                  this.removeEsPartsFees(i)
                } }
              />
            ),
            svcomponent: 'true',
          })
          return row
        }),
      )
      return { grid, total }
    }

    // ---------------------------------------------------------------
    // ヘッダと表本体を結合する
    const { grid, total } =
      index === 0 ? grid1() : index === 1 ? grid2() : index === 2 ? grid3() : []

    // 合計金額の列を追加する。高さ調整のため、最後の列にダミーのdivを追加。
    grid.push([
      {
        value: '上記項目合計金額',
        readOnly: true,
        svheader: 'true',
      },
      { value: toJPYenText(total), readOnly: true, svtotalvalue: 'true' },
      { value: '', readOnly: true, svnoboder: 'true' },
      {
        svcomponent: 'true',
        component: (
          <div
            style={ {
              height: '24px',
            } }
          >
            {''}
          </div>
        ),
        svnoboder: 'true',
      },
    ])
    return grid
  }

  /**
   * 値の表示方法をカスタマイズする
   * @param {*} cell
   */
  valueRenderer = cell => {
    if (cell.svtype === SVTypePayment) {
      // 金額の時は￥マークをつける
      return toJPYenText(parseInt(cell.value, 10))
    } else if (cell.svcomponent) {
      // コンポーネントを表示する
      return cell.component
    }
    return cell.value
  }

  /**
   * セルの値を編集する時はそのまま表示する
   * @param {*} cell
   */
  dataRenderer = cell => {
    return cell.value
  }

  /**
   * セルの属性を置き換えることでスタイルを上書きする。
   * @param {*} cell
   */
  attributesRenderer = cell => {
    // 共通プロパティ
    const commonStyle = {
      color: 'black',
      padding: '0.5rem 0 0.5rem 0',
      border: '#78909c 1px solid',
      verticalAlign: 'middle',
    }
    if (cell.svheader) {
      // 表のヘッダ
      return {
        ...cell,
        style: {
          ...commonStyle,
          background: 'linear-gradient(#eceff1, #cfd8dc)',
          fontWeight: 'bold',
          textAlign: 'center',
        },
      }
    } else if (cell.svtotalvalue) {
      // 追加金額の合計欄を特別扱いする
      const val = yenToValue(cell.value)
      const color = isNaN(val) || val >= 0 ? 'black' : 'red'
      return {
        ...cell,
        style: {
          ...commonStyle,
          color,
          background: 'white',
          textAlign: 'right',
          paddingRight: '6px',
        },
      }
    } else if (cell.svnoboder) {
      // 枠線を削除する
      return {
        ...cell,
        style: {
          ...commonStyle,
          border: 0,
          background: 'white',
        },
      }
    } else {
      // 値を編集する項目
      const color =
        cell.svtype === SVTypePayment && yenToValue(cell.value) < 0
          ? 'red'
          : 'black'
      // 入力不可の時背景色を変える
      const background = this.isInputDisabled() ? lightGreen : 'white'
      return {
        ...cell,
        style: {
          ...commonStyle,
          color,
          background,
          paddingRight: '6px',
        },
      }
    }
  }

  /**
   * render
   * @return {ReactDOM} rendered result
   */
  render() {
    const { sticky, route, disabled } = this.props

    const stickyProps = sticky.json() || {}
    // ES提携データ
    const es = stickyProps.es || {}
    // クライアントマスタ
    const esMaster = stickyProps.esMaster || {}
    const esMasterFees = esMaster.esFees || []
    // 作業状況
    const esWorks = stickyProps.esWorks || []
    // 作業時間
    const esWorkTimes = stickyProps.esWorkTimes || []
    // 出動日など
    const esFees = stickyProps.esFees || []
    // 作業費
    const workFee = es.workFee || []
    // 部品代
    const esPartsFees = stickyProps.esPartsFees || []

    const inputDisabled = disabled

    // ---------------------------------------------------------------
    // 作業箇所
    const esWorksList = (
      <div>
        <dl className={ 'label-inputs-wrap' }>
          <dt>
            <label style={ { whiteSpace: 'nowrap' } }>{'作業箇所'}</label>
          </dt>
          <dd>&nbsp;</dd>
        </dl>
        <div>
          {esWorks.length > 0
            ? esWorks.map((eswork, index) => (
              <HouseLaboWork
                key={ `eswork-${index}` }
                houseLaboWork={ eswork }
                index={ index }
                updateHouseLaboWorkText={ this.updateEsWorkText }
                removeHouseLaboWorks={ this.removeEsWorks }
                disabled={ inputDisabled }
                isHomeServe
              />
            ))
            : null}
        </div>
        <AddButton
          label={ '＋作業箇所を追加する' }
          onClick={ this.addEsWorks }
          disabled={ inputDisabled }
        />
      </div>
    )

    // ---------------------------------------------------------------
    // 作業時間
    const workTime = () => {
      const WorkTimeTitle = styled.div`
        padding: 0.5rem;
        margin-top: 0.7rem;
        margin-bottom: 0.5rem;
        max-width: 10rem;
        font-weight: bold;
        cursor: pointer;

        &:hover {
          background: lightgrey;
        }
      `

      // 作業時間と合計の分を表示
      const minute = (row, col, title, min) => (
        <dl
          className={ 'label-inputs-wrap' }
          style={ {
            gridRow: row,
            gridColumn: col,
            height: '24px',
          } }
        >
          <dt style={ { width: '3rem' } }>{title}</dt>
          <dd style={ { width: '8rem' } }>
            <p
              style={ { width: '6rem', textAlign: 'right', fontWeight: 'bold' } }
            >{`${min}分`}</p>
          </dd>
        </dl>
      )

      // 作業時間の表示
      if (this.state.showWorkTimes) {
        // 表示する行数を固定する。未設定の作業時間は空
        const dispWorkTimes = (() => {
          const WORKTIMES_MAX = 5
          const workTimes = [...esWorkTimes]
          while (workTimes.length < WORKTIMES_MAX) {
            workTimes.push({ id: null, date: '', startAt: '', finishAt: '' })
          }
          return workTimes
        })()
        let totalMin = 0
        return (
          <div>
            <WorkTimeTitle
              onClick={ () => this.setState({ showWorkTimes: false }) }
            >
              {'▼ 作業時間'}
            </WorkTimeTitle>
            <div
              style={ {
                display: 'grid',
                gridTemplateColumns: '27vw 15vw 15vw 10vw 6vw',
                marginLeft: '2rem',
              } }
            >
              {dispWorkTimes.map((wt, i) => {
                const min =
                  wt.date && wt.startAt && wt.finishAt
                    ? (moment(`${wt.date} ${wt.finishAt}`).unix() -
                        moment(`${wt.date} ${wt.startAt}`).unix()) /
                      60
                    : 0
                totalMin += min
                return (
                  <>
                    <dl
                      className={ 'label-inputs-wrap' }
                      style={ { gridRow: i + 1, gridColumn: 1 } }
                    >
                      <dt className={ 'primary-label input-headline' }>
                        <label htmlFor={ 'determined-date-time-date' }>
                          {`実際の時間${i + 1}`}
                        </label>
                      </dt>
                      <dd className={ 'calendar-wrap' }>
                        <WrappedCalendar
                          buttonId={ `sv-work-time-${route}-${i}` }
                          value={ wt.date || '' }
                          onSelect={ date =>
                            this.updateEsWorkTimes(i, 'date', date)
                          }
                          disabled={ disabled }
                        />
                      </dd>
                    </dl>
                    <dl
                      className={ 'label-inputs-wrap' }
                      style={ { gridRow: i + 1, gridColumn: 2 } }
                    >
                      <TimeInput
                        label={ '作業開始時刻' }
                        value={ wt.startAt || '' }
                        name={ `es-work-start-at-${i}` }
                        onChange={ time =>
                          this.updateEsWorkTimes(i, 'startAt', time)
                        }
                        route={ route }
                        disabled={ inputDisabled }
                      />
                    </dl>
                    <dl
                      className={ 'label-inputs-wrap' }
                      style={ { gridRow: i + 1, gridColumn: 3 } }
                    >
                      <TimeInput
                        label={ '作業終了時刻' }
                        value={ wt.finishAt || '' }
                        name={ `work-finish-at${i}` }
                        onChange={ time =>
                          this.updateEsWorkTimes(i, 'finishAt', time)
                        }
                        route={ route }
                        disabled={ inputDisabled }
                      />
                    </dl>
                    {/* 時間(分) */}
                    {minute(i + 1, 4, '', min)}
                  </>
                )
              })}
              {/* 合計時間(分) 一覧の分と同じカラムに表示する */}
              {minute(dispWorkTimes.length + 1, 4, '合計', totalMin)}
            </div>
          </div>
        )
      } else {
        // 作業時間を表示しないときはタイトルのみにする。
        return (
          <div>
            <WorkTimeTitle
              onClick={ () => this.setState({ showWorkTimes: true }) }
            >
              {'▶︎ 作業時間'}
            </WorkTimeTitle>
          </div>
        )
      }
    }

    // ---------------------------------------------------------------
    // 金額入力のための表①
    const esMaster1 = esMasterFees[0] || []
    const grid1 = this.makeHomeServeFeesGrid(esMaster1, esFees, 0)

    // ---------------------------------------------------------------
    // 金額入力のための表②
    const grid2 = this.makeHomeServeFeesGrid(null, workFee, 1)

    // ---------------------------------------------------------------
    // 金額入力のための表③
    const grid3 = this.makeHomeServeFeesGrid(null, esPartsFees, 2)

    // ---------------------------------------------------------------
    // 金額入力のための表スタイル
    const feeSheetStyle = {
      marginTop: '10px',
      marginBottom: '4px',
      marginLeft: '10px',
      marginRight: '10px',
    }
    // 有料道路代・合計金額の表示領域
    const totalSheetStyle = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginTop: '10px',
      marginBottom: '4px',
      marginLeft: '10px',
      marginRight: '10px',
    }

    // ---------------------------------------------------------------
    // 合計金額を表示するための表。
    const makeTotal = () => {
      const total = calcTotal(stickyProps)
      const tax = salesTax(total.feeTotal)
      const totalFee = total.feeTotal + tax
      // ---------------------------------------------------------------
      // カラムのスタイル
      const clientTotalTdStyle = {
        border: '1px solid gray',
        paddingTop: '4px',
        paddingBottom: '4px',
        paddingLeft: '6px',
        paddingRight: '6px',
      }
      const clientTotalTdTitleStyle = {
        ...clientTotalTdStyle,
        width: '150px',
        fontWeight: 'bold',
        // background: 'lightgrey',
        background: 'linear-gradient(#eceff1, #cfd8dc)',
        textAlign: 'center',
      }
      const clientTotalTdValueStyle = {
        ...clientTotalTdStyle,
        width: '120px',
        fontWeight: 'normal',
        background: 'white',
        textAlign: 'right',
      }
      // 有料道路代を表示するためのデータ
      const highwayFeeGrid = [
        [
          {
            value: '有料道路代(税込)',
            readOnly: true,
            width: 180,
            svheader: 'true',
          },
          {
            value: es.highwayFee || 0,
            width: 140,
            svtype: SVTypePayment,
          },
        ],
      ]
      // 合計金額を表示するためのデータ
      const allTotalGrid = [
        [
          {
            value: '合計金額',
            readOnly: true,
            width: 180,
            svheader: 'true',
          },
          {
            value: toJPYenText(total.allTotal), // 計算結果①+②+③ + 有料道路代
            readOnly: true,
            width: 140,
            svtotalvalue: 'true',
          },
        ],
      ]

      // ---------------------------------------------------------------
      // 合計金額表示
      const totalPayments = (
        <div
          style={ {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          } }
        >
          <div style={ feeSheetStyle }>
            <table>
              <thead>
                <tr>
                  <th style={ clientTotalTdTitleStyle }>{'項目'}</th>
                  <th style={ clientTotalTdTitleStyle }>{'金額(税抜)'}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={ clientTotalTdValueStyle }>{'小計'}</td>
                  <td style={ clientTotalTdValueStyle }>
                    {toJPYenText(total.feeTotal)}
                  </td>
                </tr>
                <tr>
                  <td style={ clientTotalTdValueStyle }>{'消費税(10%)'}</td>
                  <td style={ clientTotalTdValueStyle }>{toJPYenText(tax)}</td>
                </tr>
                <tr>
                  <td style={ clientTotalTdValueStyle }>{'合計金額'}</td>
                  <td style={ clientTotalTdValueStyle }>
                    {toJPYenText(totalFee)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={ totalSheetStyle }>
            <ReactDataSheet
              data={ highwayFeeGrid }
              valueRenderer={ this.valueRenderer }
              dataRenderer={ this.dataRenderer }
              onCellsChanged={ this.onhighwayFeeCellsChanged }
              attributesRenderer={ this.attributesRenderer }
            />
            <ReactDataSheet
              data={ allTotalGrid }
              valueRenderer={ this.valueRenderer }
              dataRenderer={ this.dataRenderer }
              onCellsChanged={ () => {} }
              attributesRenderer={ this.attributesRenderer }
            />
          </div>
        </div>
      )
      return totalPayments
    }

    // ---------------------------------------------------------------
    // 全体の描画
    return (
      <div>
        {esWorksList}
        {workTime()}
        <Hr />
        <div>
          <div style={ { display: 'flex' } }>
            <div style={ feeSheetStyle }>
              <ReactDataSheet
                data={ grid1 }
                valueRenderer={ this.valueRenderer }
                dataRenderer={ this.dataRenderer }
                onCellsChanged={ this.onGrid1CellsChanged }
                attributesRenderer={ this.attributesRenderer }
              />
            </div>
            <div style={ feeSheetStyle }>
              <ReactDataSheet
                data={ grid2 }
                valueRenderer={ this.valueRenderer }
                dataRenderer={ this.dataRenderer }
                onCellsChanged={ this.onGrid2CellsChanged }
                attributesRenderer={ this.attributesRenderer }
              />
              <AddButton
                disabled={ false }
                label={ '＋行を追加する' }
                onClick={ this.addWorkFee }
              />
            </div>
            <div style={ feeSheetStyle }>
              <ReactDataSheet
                data={ grid3 }
                valueRenderer={ this.valueRenderer }
                dataRenderer={ this.dataRenderer }
                onCellsChanged={ this.onGrid3CellsChanged }
                attributesRenderer={ this.attributesRenderer }
              />
              <AddButton
                disabled={ false }
                label={ '＋行を追加する' }
                onClick={ this.addEsPartsFees }
              />
            </div>
          </div>
        </div>
        {makeTotal()}
      </div>
    )
  }
}
/* eslint-enable react/jsx-no-bind */

export default connect(HomeServe)
