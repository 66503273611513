export const convertBeforePut = payments => {
  const payMap = {}
  payments.forEach(payment => {
    if (!payMap[payment.meta.id]) {
      payMap[payment.meta.id] = {
        stickyContactId: payment.meta.id,
        stickyId: payment.data.stickyId,
        payments: [],
        accounts: [],
      }
    }
    // payment.paymentTypeの値がプロパティ名として利用される
    payMap[payment.meta.id][payment.paymentType].push({
      ...payment.data,
      stickyId: payment.data.stickyId,
    })
  })
  const values = Object.keys(payMap).map(key => {
    return payMap[key]
  })
  // console.log( '!!!:', values )
  return values
}

export default convertBeforePut
