import React from 'react'
import PropTypes from 'prop-types'
import { FileSpec } from '../upload/styled'
import { makeCancelable, noop } from 'src/lib/cancelable-promise'

// 売掛金管理のExcelアップロードをリクエストし、アップロード状態を表示する
// APIの仕様が他のアップロードと変わったため、独立したコンポーネントにする。
export class ExcelUpload extends React.PureComponent {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    file: PropTypes.shape({
      errorMessage: PropTypes.string,
      data: PropTypes.any,
      name: PropTypes.string.isRequired,
    }).isRequired,
    upload: PropTypes.func.isRequired,
    fire: PropTypes.bool, // アップロードを発火させる
    dataFilter: PropTypes.func.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    fire: false,
  }

  state = {
    status: 'not_yet',
    onUnmount: noop,
    errorMessages: [],
  }

  /**
   * componentDidMount
   * @return {void}
   */
  componentDidMount() {
    this.props.fire && this.request()
  }

  /**
   * componentDidUpdate
   * 親からfire プロパティ経由でアップロードのスイッチを入れる
   * @param  {object} prevProps prev props
   * @param  {object} prevState prev state
   * @param  {object} snapshot  snapshot
   * @return {void}
   */
  componentDidUpdate(prevProps) {
    if (!prevProps.fire && this.props.fire) {
      this.request()
    }
  }

  /**
   * componentWillUnmount
   * @return {void}
   */
  componentWillUnmount() {
    this.state.onUnmount()
  }

  request = () => {
    const { file, upload, dataFilter } = this.props
    console.log(file.data)
    const { promise, cancel: onUnmount } = makeCancelable(
      upload(dataFilter(file.data)),
    )

    this.setState({ status: 'requesting', onUnmount })

    promise
      .then(res => {
        // 成功したときはres.ok=true、それ以外ではエラーメッセージが配列で返される。
        if (res.ok) {
          return 'OK'
        } else {
          return res.json()
        }
      })
      .then(res => {
        if (res === 'OK') {
          this.setState({ status: 'success' })
        } else {
          // エラーの時、resにメッセージの配列がセットされる。
          this.setState({ status: 'failure', errorMessages: res })
        }
      })
      .catch(err => {
        if (!err.isCanceled) {
          this.setState({ status: 'failure' })
          console.error(err)
        }
      })
  }

  renderMessage = () => {
    const { status, errorMessages } = this.state
    const {
      file: { errorMessage },
    } = this.props

    if (errorMessage) {
      return (
        <FileSpec style={ { flex: 1 } } failure>
          {errorMessage}
        </FileSpec>
      )
    } else {
      if (status === 'not_yet') {
        return <FileSpec>{''}</FileSpec>
      } else if (status === 'success') {
        return <FileSpec success>{'アップロード成功'}</FileSpec>
      } else if (status === 'requesting') {
        return <FileSpec>{'アップロード中...'}</FileSpec>
      } else if (status === 'failure') {
        const messages = errorMessages.map(msg => (
          <>
            {msg}
            <br />
          </>
        ))
        return (
          <>
            <FileSpec failure>{'サーバーエラー'}</FileSpec>
            <span>{messages}</span>
          </>
        )
      } else {
        return <FileSpec failure>{'不明なエラー'}</FileSpec>
      }
    }
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const {
      file: { name, errorMessage },
    } = this.props

    return (
      <>
        <FileSpec width={ 200 } cancel={ !!errorMessage }>
          {name}
        </FileSpec>
        {this.renderMessage()}
      </>
    )
  }
}

export default ExcelUpload
