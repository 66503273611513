import React from 'react'
import PropTypes from 'prop-types'
import connect from './connect'
import linkHistoryHOC from './hocs/link-history'
import WrappedCalendar from 'src/components/commons/wrapped-calendar'
import { today } from 'src/lib/moment'
import { Box } from '@mui/material'

export const dateHOC = label =>
  class Date extends React.Component {
    static propTypes = {
      // ownProps
      value: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
      disabled: PropTypes.bool,
      nolabel: PropTypes.bool,
      showPast: PropTypes.bool, // true の時は過去日付を選択可能、false(default)の時は未来日付を選択可能に見せる
      buttonId: PropTypes.string, // ボタンに振るID値
      inputId: PropTypes.string, // InputのID

      // よく分からないが、注文一覧のビューでは flex を取ってしまうと崩れるし、それ以外ではflexを取る必要がある
      isFlex: PropTypes.bool,
    }

    /**
     * defaultProps
     * @type {object}
     */
    static defaultProps = {
      isFlex: false,
      disabled: false,
      nolabel: false,
      showPast: false,
      buttonId: '',
      inputId: '',
    }

    /**
     * shouldComponentUpdate
     * @param  {object} nextProps next props
     * @param  {object} nextState next state
     * @return {boolean}          should component update
     */
    shouldComponentUpdate(nextProps) {
      return (
        this.props.value !== nextProps.value ||
        this.props.disabled !== nextProps.disabled
      )
    }

    onSelect = date => this.props.onChange({ target: { value: date } })

    /**
     * render
     * @return {ReactElement|null|false} render a React element.
     */
    render() {
      const {
        value,
        isFlex,
        disabled,
        nolabel,
        showPast,
        buttonId,
        inputId,
      } = this.props
      const displayValue = value === '-1' ? today() : value

      return (
        <Box sx={ { display: isFlex ? 'flex' : 'block' } }>
          {nolabel || <label style={ { fontWeight: 'bold' } }>{label}</label>}
          <Box className={ showPast ? 'calendar-wrap-past' : 'calendar-wrap' }>
            <WrappedCalendar
              value={ displayValue }
              onSelect={ this.onSelect }
              disabled={ disabled }
              buttonId={ buttonId }
              inputId={ inputId }
            />
          </Box>
        </Box>
      )
    }
  }

export const DateFrom = linkHistoryHOC(connect(dateHOC('開始日')), 'from')
export const DateTo = linkHistoryHOC(connect(dateHOC('終了日')), 'to')
export const DateStart = linkHistoryHOC(connect(dateHOC('開始日')), 'startDate')
export const DateEnd = linkHistoryHOC(connect(dateHOC('終了日')), 'endDate')
export const PaymentDate = linkHistoryHOC(
  connect(dateHOC('支払日')),
  'paymentDate',
)
