import React from 'react'
import PropTypes from 'prop-types'
import connect from './connect'
import linkHistoryHOC from './hocs/link-history'
import WrappedCalendarWeek from 'src/components/commons/wrapped-calendar-week'
import { today, thisWeek } from 'src/lib/moment'
import { Box, Button, IconButton, TextField, Typography } from '@mui/material'

// config
import config from 'src/config'

export const weekHOC = label =>
  class Week extends React.PureComponent {
    static propTypes = {
      value: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
      disabled: PropTypes.bool,
      nolabel: PropTypes.bool,
      nocalendar: PropTypes.bool,
      buttonId: PropTypes.string,
      inputId: PropTypes.string,
      // よく分からないが、注文一覧のビューでは flex を取ってしまうと崩れるし、それ以外ではflexを取る必要がある
      isFlex: PropTypes.bool,
    }

    /**
     * defaultProps
     * @type {object}
     */
    static defaultProps = {
      isFlex: false,
      disabled: false,
      nolabel: false,
      nocalendar: false,
      buttonId: 'week',
      inputId: 'weekInput',
    }

    onSelect = date => this.props.onChange({ target: { value: date } })

    /**
     * render
     * @return {ReactElement|null|false} render a React element.
     */
    render() {
      const {
        value,
        isFlex,
        disabled,
        nolabel,
        nocalendar,
        buttonId,
        inputId,
      } = this.props
      const displayValue =
        value === '-1' ? thisWeek(today(), config.startDayOfWeek) : value

      return (
        <Box sx={ { display: isFlex ? 'flex' : 'block' } }>
          {nolabel || <label style={ { fontWeight: 'bold' } }>{label}</label>}
          <WrappedCalendarWeek
            value={ displayValue }
            onSelect={ this.onSelect }
            disabled={ disabled }
            nocalendar={ nocalendar }
            buttonId={ buttonId }
            inputId={ inputId }
          />
        </Box>
      )
    }
  }

export const WeekFrom = linkHistoryHOC(connect(weekHOC('表示週')), 'startDate')
export const WeekUpload = linkHistoryHOC(
  connect(weekHOC('計上週')),
  'startDate',
)
// 精算処理のための表示用
export const WeekSettlement = linkHistoryHOC(
  connect(weekHOC('精算期間')),
  'startDate',
)
export const WeekStart = linkHistoryHOC(connect(weekHOC('開始週')), 'startDate')
export const WeekEnd = linkHistoryHOC(connect(weekHOC('終了週')), 'endDate')
