const preservableClear = () => {
  const preserved = Object.keys(localStorage)
    .filter(key => /^PRESERVE__/.test(key))
    .reduce((prev, key) => {
      prev[key] = localStorage.getItem(key)
      return prev
    }, {})

  localStorage.clear()

  Object.keys(preserved).forEach(key =>
    localStorage.setItem(key, preserved[key]),
  )
}

export const LSUtil = {
  preservableClear,
}
