import React from 'react'
import PropTypes from 'prop-types'
import connect from './connect'
import { NETWORK_STATUSES } from 'src/reducers/network-status'
import { isToday, isAfterToday } from 'src/lib/validate'

/**
 * アサイン可能かどうかのラジオボタンのコンポーネント
 * @type {function}
 */
export class Assignable extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    updateTheDailyInfo: PropTypes.func.isRequired,
    isAssignable: PropTypes.bool,
    displayDate: PropTypes.string.isRequired,
    // stateProps
    networkStatus: PropTypes.symbol.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    isAssignable: false,
  }

  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    this.state = { isNetworkTrying: false }
  }

  /**
   * UNSAFE_componentWillReceiveProps
   * @param  {object} nextProps React props.
   * @return {void}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.networkStatus === NETWORK_STATUSES.TRYING &&
      nextProps.networkStatus !== NETWORK_STATUSES.TRYING &&
      this.state.isNetworkTrying
    ) {
      this.setState({ isNetworkTrying: false })
    }
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.isAssignable !== nextProps.isAssignable ||
      this.state.isNetworkTrying !== nextState.isNetworkTrying ||
      this.props.displayDate !== nextProps.displayDate
    )
  }

  /**
   * トグルボタンを押した時のハンドラ
   * @return {void}
   */
  onToggleCkick = () => {
    const { displayDate } = this.props
    // 当日はAPIを呼び出して状態を保存するが、未来日付の時はストア上の状態のみを更新する。
    if (isToday(displayDate)) {
      this.setState({ isNetworkTrying: true })
      this.props.updateTheDailyInfo({ isAssignable: !this.props.isAssignable })
    } else {
      this.props.updateTheDailyInfo(
        { isAssignable: !this.props.isAssignable },
        false,
      )
    }
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { isNetworkTrying } = this.state

    const { isAssignable } = this.props

    // 当日以降の時はアサイン可能の変更ができる。
    const disabled = !isAfterToday(this.props.displayDate)

    return (
      <div className={ 'control-progress' }>
        <button
          className={ 'button button-change-progress' }
          onClick={ this.onToggleCkick }
          disabled={ isNetworkTrying || disabled }
        >
          <i aria-hidden={ 'true' } className={ 'fa fa-refresh' } />
        </button>
        <span>{`アサイン : ${isAssignable ? '可能' : '不可'}`}</span>
      </div>
    )
  }
}

export default connect(Assignable)
