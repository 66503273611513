// settlement
import React from 'react'
import PropTypes from 'prop-types'
import Authorize from 'src/components/commons/authorize'
import CommonHeader from 'src/components/layouts/common-header'
import CommonFooter from 'src/components/layouts/common-footer'
import HomeView from './home'
import DetailApp from './detail/app'
import ManageApp from './manage/app'
import config from 'src/config'

export const SettlementSubRouteView = props => {
  const {
    App,
    match: {
      params: { staffId, billingPeriod },
    },
    history: { goBack },
  } = props

  const [from, to] = billingPeriod.split(',')

  return (
    <div className={ 'callview__wrapper' }>
      <CommonHeader route={ 'accounting' } subRoute={ 'settlement' } />
      <Authorize roles={ config.roles.accounting }>
        <App
          staffId={ parseInt(staffId, 10) }
          from={ from }
          to={ to }
          goBack={ goBack }
        />
      </Authorize>
      <CommonFooter />
    </div>
  )
}

SettlementSubRouteView.propTypes = {
  App: PropTypes.func.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      staffId: PropTypes.string.isRequired,
      billingPeriod: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export const SettlementHomeView = HomeView
export const SettlementDetailView = props => (
  <SettlementSubRouteView App={ DetailApp } { ...props } />
)
export const SettlementManageView = props => (
  <SettlementSubRouteView App={ ManageApp } { ...props } />
)
