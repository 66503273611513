import { css } from 'styled-components'
import { baseColor, white, borderDarkGray } from 'src/colors'

export const checkbox = css`
  margin: 0;
  opacity: 0;
  transform: scale(0.9);
  z-index: 0;

  &:checked {
    + label::before {
      background: ${white};
    }

    + label::after {
      transform: rotate(-45deg) scale(1);
      opacity: 1;
    }
  }

  &:hover,
  &:focus {
    + label::before {
      border: 1px solid ${baseColor};
    }
  }

  &:focus {
    + label::before {
      box-shadow: 0 0 7px ${baseColor};
    }
  }

  &[disabled] {
    + label {
      opacity: 1;
    }

    + label::before {
      background: ${borderDarkGray};
    }
  }
`

export const checkboxLabel = (color = baseColor) => css`
  display: inline-block;
  position: relative;
  margin-right: 1rem;
  padding: 0.375rem 0 0.5rem 0.375rem;
  cursor: pointer;

  &:last-child {
    margin-right: 1rem;
  }

  &::before,
  &::after {
    position: absolute;
    transition: all 0.2s;
    content: '';
  }

  &::before {
    top: 50%;
    left: -15px;
    width: 14px;
    height: 14px;
    margin-top: -8px;
    border: 1px solid ${borderDarkGray};
    border-radius: 3px;
    background: ${white};
  }

  &::after {
    top: 50%;
    left: -12px;
    width: 8px;
    height: 4px;
    margin-top: -4px;
    transform: rotate(-45deg) scale(0.5);
    border-bottom: 2px solid ${color};
    border-left: 2px solid ${color};
    opacity: 0;
  }

  &:hover::before {
    background: ${white};
  }

  [disabled] + & {
    cursor: default;
  }
`
