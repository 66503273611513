import React from 'react'
import PropTypes from 'prop-types'
import connect from '../../../connect'
import { contacts as iterateContacts } from 'src/lib/iterator'
import Sticky from 'src/lib/class-sticky'
import findContact from '../../lib/find-contact'
import Radio from 'src/styled/form/radio'

/**
 * 依頼者かどうかを入力します
 * @type {ReactComponent}
 */
export class IsClient extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    tabIndex: PropTypes.number.isRequired,
    contactsIndex: PropTypes.number.isRequired,
    route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
    disabled: PropTypes.bool.isRequired,
    // stateProps
    master: PropTypes.shape({
      prefCodes: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string.isRequired,
          regionId: PropTypes.number.isRequired,
        }),
      ),
    }).isRequired,
    // dispatchProps
    updateStage: PropTypes.func.isRequired,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    const { sticky, tabIndex, contactsIndex } = this.props
    const thisContact = findContact(sticky, tabIndex, contactsIndex) || {}
    const nextContact =
      findContact(nextProps.sticky, tabIndex, contactsIndex) || {}
    const thisIsClient = thisContact.isClient || false
    const nextIsClient = nextContact.isClient || false
    return (
      nextProps.sticky.json().contacts !== this.props.sticky.json().contacts ||
      thisIsClient !== nextIsClient ||
      this.props.disabled !== nextProps.disabled
    )
  }

  /**
   * チェックボックス変更時のハンドラ
   * @param  {Event} e event
   * @return {void}
   */
  onChange = () => {
    const { sticky, tabIndex, contactsIndex, updateStage } = this.props
    const id = sticky.getContactId(tabIndex, contactsIndex)
    const nextContact = { id, isClient: true }

    const updator = {
      contacts: {
        [tabIndex]: {
          [contactsIndex]: nextContact,
        },
      },
    }

    const contacts = sticky.json().contacts
    //
    // 上記以外のラジオボタンをfalseにする
    iterateContacts(contacts, (tabIndex, contactsIndex) => {
      if (!updator.contacts[tabIndex]) {
        updator.contacts[tabIndex] = {}
      }

      if (!updator.contacts[tabIndex][contactsIndex]) {
        updator.contacts[tabIndex][contactsIndex] = {}
      }

      updator.contacts[tabIndex][contactsIndex].id = sticky.getContactId(
        tabIndex,
        contactsIndex,
      )
      updator.contacts[tabIndex][contactsIndex].isClient =
        tabIndex === this.props.tabIndex &&
        contactsIndex === this.props.contactsIndex
    })
    const diffProps = updator
    updateStage(new Sticky(diffProps))
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const {
      // ownProps
      sticky,
      tabIndex,
      contactsIndex,
      route,
      disabled,
    } = this.props

    const contact = findContact(sticky, tabIndex, contactsIndex) || {}
    const isClient = contact.isClient || false
    // console.log(`${tabIndex}-${contactsIndex}-${isClient}`)

    // ---------------------------------------------------------------
    // // NOTE: 依頼者かどうかのラジオボタングループは、tanIndex, contactsIndexを跨いで共通化したいが、
    // // そのままだと左右ボタンで移動した時にそれにつられてタブが開かない
    // // onCheckedの時にそのタブを開いたりしたい
    // // このことは未実装なので、radioGroupNameはname属性に適用していない
    // ---------------------------------------------------------------
    // ↑ contactsを一覧表示するようにしたため、name属性にradioGroupName を設定することで一つしか選択できないようにする。
    const radioGroupName = `contact-is-client-${route}`
    // const identifier = `${radioGroupName}-${tabIndex}-${contactsIndex}`

    return (
      <dl className={ 'label-inputs-wrap' }>
        <Radio
          checked={ isClient }
          name={ radioGroupName }
          onChange={ this.onChange }
          onClick={ this.onChange }
          disabled={ disabled }
          labelText={ '依頼者' }
        />
      </dl>
    )
  }
}

export default connect(IsClient)
