import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { toJPYenText } from 'src/lib/format'
import { Dl, Dt, Dd } from 'src/styled/list'
import moment from 'moment'
import { baseColor, borderDarkGray } from 'src/colors'
import config from 'src/config'

// const testItems = [
//   {
//     stickyId: 1,
//     orderId: 'W01-00000001',
//     regionId: 1,
//     stickyContactId: 123,
//     name: '顧客名',
//     phoneNumber: '09011111111',
//     address: '住所',
//     completedAt: '2018-08-27T09:00:00.00000Z',
//     paymentId: 1,
//     cashValue: 10000,
//     chargedValue: 10000,
//     creditCardId: -1,
//     paymentMethodId: 1,
//     paymentTimes: 5,
//     isConfirmed: true,
//     isSettled: false,
//   },
// ]

// テーブルのヘッダ
const headers = {
  completedAt: '日付',
  name: 'お客様名',
  phoneNumber: '電話番号',
  cashValue: '金額',
  creditCardId: '支払方法',
  paymentMethodId: '支払回数',
  paymentTimes: '分割回数',
  isConfirmed: '確定済み',
  isSettled: '精算済み',
}

// 項目の順番
const keyOrders = [
  'completedAt',
  'name',
  'phoneNumber',
  'cashValue',
  'creditCardId',
  'paymentMethodId',
  'paymentTimes',
  'isConfirmed',
  'isSettled',
]

// スタイル
const tableStyle = {
  width: '97%',
  marginLeft: '30px',
}

const theadStyle = {
  borderBottom: `1px solid ${borderDarkGray}`,
}

const thStyle = {
  color: `${baseColor}`,
  padding: '3px 0 2px',
  textAlign: 'left',
}

const trStyle = {
  borderBottom: `1px solid ${borderDarkGray}`,
}

const tdStyle = {
  padding: '3px 0 2px',
}

export class Payments extends React.Component {
  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
  }

  /**
   * @param {*} date 完了
   */
  completedAt(date) {
    return moment(date).format('MM/DD')
  }

  /**
   * カード
   * @param {*} creditCardId クレジットカードID
   */
  creditCardId(creditCardId) {
    const { master } = this.props
    const apicreditCards = master.creditCards
    if (Object.keys(apicreditCards).length === 0) {
      return '-'
    }
    if (creditCardId === config.paymentMethod.cash) {
      return '現金'
    }
    return creditCardId
      ? (apicreditCards.find(x => x.id === creditCardId) || { name: '不明' })
        .name
      : '現金'
  }

  /**
   * @param {*} paymentMethodId 支払方法
   */
  paymentMethodId(creditCardId, paymentMethodId) {
    const { master } = this.props
    if (
      !creditCardId ||
      creditCardId === config.paymentMethod.cash ||
      creditCardId === config.paymentMethod.check ||
      creditCardId === config.paymentMethod.homeServe
    ) {
      return '-'
    }
    return paymentMethodId
      ? (
        master.creditPaymentMethods.find(x => x.id === paymentMethodId) || {
          name: '(不明)',
        }
      ).name
      : '-'
  }

  /**
   * @param {*} times 支払回数
   */
  paymentTimes(creditCardId, times) {
    if (
      !creditCardId ||
      creditCardId === config.paymentMethod.cash ||
      creditCardId === config.paymentMethod.check ||
      creditCardId === config.paymentMethod.homeServe
    ) {
      return '-'
    }
    return isNaN(times) || times === null ? '-' : `${times}回`
  }

  /**
   * 確定済み・清算済み
   * @param {bool} value チェックの値
   */
  boolstr(value) {
    return value ? '✅' : '-'
  }

  render() {
    const { items } = this.props
    const sum = items.reduce((prev, item) => prev + item.chargedValue, 0)
    // console.log(items)

    // クレジットカードのAPI対応のため、コンポーネントをクラスで登録する方法に変更した。
    // その際、PluralTableがうまく使えなかったので、テーブルを直接描画するように変更した。
    // import { Table, TableHead, Th, Td } from '../../styled';
    // でインポートするコンポーネントを使うことができなかったので、HTML標準のコンポーネントに
    // 直接スタイルを指定する方式にした。もっとうまい方法が見つかれば修正したい。
    return [
      <Dl key={ 0 }>
        <Dt>{'現金払'}</Dt>
        <Dd>{toJPYenText(sum)}</Dd>
      </Dl>,
      <Dl key={ 1 }>
        <Dt />
        <Dd strech>
          {items.length > 0 && (
            <table style={ tableStyle }>
              <thead style={ theadStyle }>
                <tr>
                  {keyOrders.map(key => (
                    <th key={ key } style={ thStyle }>
                      {headers[key]}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {items.map(item => (
                  <tr
                    key={ `${item.stickyId}_${item.paymentId}` }
                    style={ trStyle }
                  >
                    <td style={ tdStyle }>
                      {this.completedAt(item['displayDate'])}
                    </td>
                    <td style={ tdStyle }>{item['name']}</td>
                    <td style={ tdStyle }>{item['phoneNumber']}</td>
                    <td style={ tdStyle }>{toJPYenText(item.chargedValue)}</td>
                    <td style={ tdStyle }>
                      {this.creditCardId(item['creditCardId'])}
                    </td>
                    <td style={ tdStyle }>
                      {this.paymentMethodId(
                        item['creditCardId'],
                        item['paymentMethodId'],
                      )}
                    </td>
                    <td style={ tdStyle }>
                      {this.paymentTimes(
                        item['creditCardId'],
                        item['paymentTimes'],
                      )}
                    </td>
                    <td style={ tdStyle }>{this.boolstr(item['isConfirmed'])}</td>
                    <td style={ tdStyle }>{this.boolstr(item['isSettled'])}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Dd>
      </Dl>,
    ]
  }
}

Payments.propTypes = {
  items: PropTypes.array.isRequired,
  master: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  master: {
    creditPaymentMethods: state.master.data.creditPaymentMethods || [],
    creditCards: state.master.data.creditCards || [],
  },
})

export default connect(mapStateToProps)(Payments)
