import { createInventoryEndpoint } from 'src/lib/api-utils'

const query = (body, accessToken, env) => {
  const url = createInventoryEndpoint(env, 'query')
  const request = fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body,
  })
  return request
}

export const uploadShipInventoryPDF = (stickyId, data, accessToken, env) => {
  const body = {
    query: `mutation uploadShipInventoryPdf($stickyId: Int!, $data: String!) {
      uploadShipInventoryPdf(stickyId: $stickyId, data: $data)
    }
  `,
    variables: {
      stickyId,
      data,
    },
  }
  return query(JSON.stringify(body), accessToken, env)
}

export const deleteShipInventoryPDF = (id, accessToken, env) => {
  const body = {
    query: `mutation deleteShipInventoryPdf($id: Int!) {
      deleteShipInventoryPdf(id: $id)
    }
  `,
    variables: {
      id,
    },
  }
  return query(JSON.stringify(body), accessToken, env)
}
