import React from 'react'
import PropTypes from 'prop-types'
import connect from '../../../connect'
import findContact from '../../lib/find-contact'
import Sticky from 'src/lib/class-sticky'
import Checkbox from 'src/styled/form/checkbox'

/**
 * 立会者かどうかを選択します
 * @type {ReactComponent}
 */
export class IsObserver extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    tabIndex: PropTypes.number.isRequired,
    contactsIndex: PropTypes.number.isRequired,
    disabled: PropTypes.bool.isRequired,
    // dispatchProps
    updateStage: PropTypes.func.isRequired,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.sticky.json().contacts !== this.props.sticky.json().contacts ||
      this.props.disabled !== nextProps.disabled
    )
  }

  /**
   * onChange handler
   * @param  {Event} e event
   * @return {void}
   */
  onChange = e => {
    const isObserver = e.target.checked
    const { sticky, tabIndex, contactsIndex, updateStage } = this.props
    const id = sticky.getContactId(tabIndex, contactsIndex)
    const existsClient = sticky.existsClient()
    const diffProps = {
      contacts: {
        [tabIndex]: {
          [contactsIndex]: existsClient
            ? { id, isObserver }
            : { id, isObserver, isClient: true },
        },
      },
    }
    updateStage(new Sticky(diffProps))
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const {
      // ownProps
      sticky,
      tabIndex,
      contactsIndex,
      disabled,
    } = this.props

    const contact = findContact(sticky, tabIndex, contactsIndex) || {}
    const isObserver = !!contact.isObserver
    // NOTE: 立会者かどうかのラジオボタングループは、tanIndex, contactsIndexを跨いで共通化したいが、
    // そのままだと左右ボタンで移動した時にそれにつられてタブが開かない
    // onCheckedの時にそのタブを開いたりしたい
    // このことは未実装なので、radioGroupNameはname属性に適用していない

    return (
      <dl className={ 'label-inputs-wrap' }>
        <Checkbox
          labelText={ '立会者' }
          checked={ isObserver }
          onChange={ this.onChange }
          disabled={ disabled }
        />
      </dl>
    )
  }
}

export default connect(IsObserver)
