import React from 'react'
import CommonHeader from 'src/components/layouts/common-header'
import DebugToggleButton from 'src/components/debuggers/debug-toggle'
import UseHTTPS from '../debuggers/use-https'
import InputApiHost from '../debuggers/input-api-host'
import StepNumber from '../debuggers/step-number'
import CommonFooter from 'src/components/layouts/common-footer'

/**
 * デバッグ画面のコンポーネントです。
 * @type {Component}
 */
export default class DebugView extends React.Component {
  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate() {
    return false
  }

  /**
   * render
   * @return {void}
   */
  render() {
    return (
      <div className={ 'homeview__wrapper' }>
        <CommonHeader route={ 'debug' } />

        <DebugToggleButton />
        <UseHTTPS />
        <InputApiHost />
        <StepNumber />

        <CommonFooter />
      </div>
    )
  }
}
