import React from 'react'
import PropTypes from 'prop-types'
import connect from '../../../connect'
import Sticky from 'src/lib/class-sticky'
import { changeContactArea } from 'src/lib/format.js'
import { baseColor, gray } from 'src/colors'

/**
 * 連絡先の順番を入れ替える
 * @type {ReactComponent}
 */
export class ContactOrder extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    tabIndex: PropTypes.number.isRequired,
    contactsIndex: PropTypes.number.isRequired,
    disabled: PropTypes.bool.isRequired,
    // stateProps
    master: PropTypes.object.isRequired,
    // dispatchProps
    updateStage: PropTypes.func.isRequired,
  }

  /**
   * 連絡先の順番を入れ替える
   * @param {*} up trueの時上、falseの時下に移動する。
   */
  changeOrder = up => {
    // contacts[tabIndex] の中で、contactIndexの要素を上/下に移動する
    const {
      // ownProps
      sticky,
      tabIndex,
      contactsIndex,
      updateStage,
      master,
    } = this.props
    const contacts = sticky.json().contacts || []
    const tabContacts = contacts[tabIndex] || []
    const nextIndex = up => {
      if (up) {
        // 自分より上で削除・移動されていない連絡先
        for (let i = contactsIndex - 1; i >= 0; i--) {
          const contact = tabContacts[i]
          if (!(contact.deleted || contact.moved)) {
            return i
          }
        }
      } else {
        // 自分より下で削除・移動されていない連絡先
        for (let i = contactsIndex + 1; i < tabContacts.length; i++) {
          const contact = tabContacts[i]
          if (!(contact.deleted || contact.moved)) {
            return i
          }
        }
      }
      return -1
    }
    // 移動先のインデックスと連絡先
    // 移動先が見つからない時は何もせず戻る（ないはずだが、エラー防止）
    const newIndex = nextIndex(up)
    if (newIndex === -1) {
      return
    }
    const newContact = tabContacts[newIndex]
    if (!newContact) {
      return
    }
    newContact.noMerge = true // 移動先は上書き
    // 移動する連絡先
    const contact = tabContacts.splice(contactsIndex, 1)[0]
    contact.noMerge = true // 移動元も上書き
    // 連絡先を挿入
    tabContacts.splice(newIndex, 0, contact)
    // 更新
    const diffProps = {
      contacts: {
        [tabIndex]: tabContacts,
      },
    }
    changeContactArea(sticky, master, diffProps)
    updateStage(new Sticky(diffProps))
  }

  upOrder = () => this.changeOrder(true)

  downOrder = () => this.changeOrder(false)

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const {
      // ownProps
      sticky,
      tabIndex,
      contactsIndex,
      disabled,
    } = this.props

    // const identifier = `contact-order-${tabIndex}-${contactsIndex}`
    const contacts = sticky.json().contacts || []
    const tabContacts = contacts[tabIndex] || []
    // 自分の上・下に削除・移動済しかない時は上下移動を無効にする
    let upDisabled = true
    let downDisabled = true
    for (let i = 0; i < contactsIndex; i++) {
      const contact = tabContacts[i]
      if (!(contact.deleted || contact.moved)) {
        upDisabled = false
        break
      }
    }
    for (let i = contactsIndex + 1; i < tabContacts.length; i++) {
      const contact = tabContacts[i]
      if (!(contact.deleted || contact.moved)) {
        downDisabled = false
        break
      }
    }

    const buttonStyle = {
      padding: '0 0.75rem 0.2rem 0.75rem',
      border: `1px solid ${baseColor}`,
      borderRadius: '4px',
      background: 'rgba(255, 255, 255, 0.1)',
      height: '1.2rem',
      fontSize: '1rem',
    }

    return (
      <div style={ { display: 'flex', flexDirection: 'column' } }>
        <button
          onClick={ this.upOrder }
          disabled={ disabled || upDisabled }
          style={ {
            ...buttonStyle,
            color: disabled || upDisabled ? gray : baseColor,
          } }
        >
          {'▲'}
        </button>
        <button
          onClick={ this.downOrder }
          disabled={ disabled || downDisabled }
          style={ {
            ...buttonStyle,
            color: disabled || downDisabled ? gray : baseColor,
          } }
        >
          {'▼'}
        </button>
      </div>
    )
  }
}

export default connect(ContactOrder)
