// @flow

interface List {
  staffId: number;
}

type RegionList = {
  list: List,
  listIndex: number,
}

type SSOrder = number[]

export const sortSS = (regionLists: RegionList[], ssOrder: SSOrder = []) => {
  const ssOrderMap = ssOrder.reduce((prev, x) => {
    prev[x] = ssOrder.indexOf(x)
    return prev
  }, {})
  // console.log(ssOrderMap)

  const result = [...regionLists]
  result.sort((listA, listB) => {
    const [weightA, weightB] = [listA.list, listB.list].map(x =>
      ssOrderMap[x.staffId] === void 0 ? Infinity : ssOrderMap[x.staffId],
    )
    return weightA - weightB
  })

  return result
}

export default sortSS
