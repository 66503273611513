import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import WrappedCalendar from 'src/components/commons/wrapped-calendar'
import moment from 'moment'
import { push } from 'react-router-redux'

/**
 * map dispatch to props
 * @param  {function} dispatch dispatcher
 * @return {object}            mapped dispatcher
 */
const mapDispatchToProps = dispatch => {
  return {
    /**
     * URL push
     * @param  {string} datestring YYYY-MM-DD
     * @return {void}
     */
    push: datestring =>
      dispatch(push(`/assign/wara/${datestring.split('-').join('/')}`)),
  }
}

export class Date extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    year: PropTypes.string.isRequired,
    month: PropTypes.string.isRequired,
    day: PropTypes.string.isRequired,
    // dispatchProps
    push: PropTypes.func.isRequired,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return (
      this.props.year !== nextProps.year ||
      this.props.month !== nextProps.month ||
      this.props.day !== nextProps.day
    )
  }

  /**
   * 日付クリックをWrappedCalendar要素に伝播する
   * @return {void}
   */
  propagateClick = () =>
    document.getElementById('wara-location-calendar-button').click()

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { year, month, day, push } = this.props

    const weekdayList = ['日', '月', '火', '水', '木', '金', '土']
    const weekdayIndex = moment([year, month - 1, day]).day()
    const weekday = weekdayList[weekdayIndex]

    return (
      <div>
        <div className={ 'side-date' }>
          <div className={ 'date' } onClick={ this.propagateClick }>
            <span className={ 'date-year year' }>{year}</span>
            <span className={ 'separator date-separator separator-slash' }>
              {'/'}
            </span>
            <span className={ 'date-month month' }>{month}</span>
            <span className={ 'separator date-separator separator-slash' }>
              {'/'}
            </span>
            <span className={ 'date-day day' }>{day}</span>
            <span className={ 'separator date-separator separator-brace-open' }>
              {'('}
            </span>
            <span className={ 'date-weekday weekday' }>{weekday}</span>
            <span className={ 'separator date-separator separator-brace-close' }>
              {')'}
            </span>
          </div>
        </div>

        <div className={ 'wara-location-calendar-wrap' }>
          <WrappedCalendar
            buttonId={ 'wara-location-calendar-button' }
            value={ `${year}-${month}-${day}` }
            onSelect={ push }
            displayDateLabel={ false }
          />
        </div>
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(Date)
