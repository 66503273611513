import React from 'react'
import PropTypes from 'prop-types'
import { Table } from './styled'

export default class TSVTable extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    tsv: PropTypes.string.isRequired,
    evenBackGroundColor: PropTypes.string, // 偶数行の背景色
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    evenBackGroundColor: '',
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return this.props.tsv !== nextProps.tsv
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { tsv, evenBackGroundColor } = this.props

    const [header, ...rows] = tsv.split('\n')

    return (
      <Table.Wrap>
        <Table.Table>
          <tbody>
            <Table.Tr>
              {header.length > 0 &&
                header
                  .split('\t')
                  .map((key, index) => (
                    <Table.Th key={ `${key}-${index}` }>{key}</Table.Th>
                  ))}
            </Table.Tr>
            {rows.map((row, i) =>
              // TODO: パフォーマンスに劣る
              row.length > 0 ? (
                <Table.Tr key={ i } even={ evenBackGroundColor }>
                  {row.split('\t').map((value, j) => (
                    // TODO: パフォーマンスに劣る
                    <Table.Td key={ j }>{value}</Table.Td>
                  ))}
                </Table.Tr>
              ) : null,
            )}
          </tbody>
        </Table.Table>
      </Table.Wrap>
    )
  }
}
