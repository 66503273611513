/**
 * initialState
 * APIバージョンとiPad APIバージョンを保持する
 * @type {object}
 */
const initialMetaState = { version: '', expoVersion: '', inventoryVersion: '' }

export const createActions = {
  // APIバージョン
  set: version => ({ type: 'API_META.SET', payload: { version } }),
  // iPad APIバージョン
  setExpo: version => ({ type: 'EXPO_API_META.SET', payload: { version } }),
  // 商材課　APIバージョン
  setInventory: version => ({
    type: 'INVENTORY_API_META.SET',
    payload: { version },
  }),
}

/**
 * [description]
 * @param  {object} [state=initialMetaState] [description]
 * @param  {object} action                   [description]
 * @return {object}                          [description]
 */
export const reducer = (state = initialMetaState, action) => {
  const { type, payload } = action

  switch (type) {
    case 'API_META.SET': {
      return { ...state, version: payload.version }
    }
    case 'EXPO_API_META.SET': {
      return { ...state, expoVersion: payload.version }
    }
    case 'INVENTORY_API_META.SET': {
      return { ...state, inventoryVersion: payload.version }
    }
    default:
      return state
  }
}

export default reducer
