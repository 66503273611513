/**
 * contactsオブジェクトからclientを探します
 * @param  {Array} contacts contacts
 * @param  {boolean} formatLocation locationオブジェクトの形で返すかどうか
 * @return {Contact}        Clientなコンタクトオブジェクト
 */
const findClient = (contacts, formatLocation) => {
  if (!Array.isArray(contacts)) {
    return false
  }
  const client = contacts[0][0]
  return formatLocation ? { tabIndex: 0, contactsIndex: 0 } : client
}
/*
const org_findClient = (contacts, formatLocation) => {
  if (!Array.isArray(contacts)) {
    return false
  }

  // clientを探す
  let tabIndex = void 0
  let contactsIndex = void 0
  for (let i = 0; i < contacts.length; i++) {
    const tabContacts = contacts[i]
    if (!Array.isArray(tabContacts)) {
      continue
    }

    for (let j = 0; j < tabContacts.length; j++) {
      const contact = contacts[i][j]
      if (contact && contact.isClient) {
        tabIndex = i
        contactsIndex = j
        break
      }
    }
    if (tabIndex !== void 0 && contactsIndex !== void 0) {
      break
    }
  }

  const client =
    tabIndex !== void 0 && contactsIndex !== void 0
      ? contacts[tabIndex][contactsIndex]
      : false
  return formatLocation ? { tabIndex, contactsIndex } : client
}
*/

export default findClient
