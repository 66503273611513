import config from 'src/config'
import { findListFromWara } from 'src/lib/search'
import { toJPYenText } from 'src/lib/format'
import { today } from 'src/lib/moment'
import findStickyById from '../../../../../../lib/find-sticky-by-id'

export default props => {
  const {
    // ownProps
    // location,
    sticky: originalSticky, // 編集中の付箋（詳細情報取得済み）
    // stateProps
    wara,
    staged,
    displayDate,
  } = props

  const { location } = findStickyById(wara, originalSticky.id)
  const { regionIndex, listIndex } = location

  const isDocked = !location || regionIndex === -1 || listIndex === -1

  // 元の付箋と同じリストに配置されている付箋の数を取得
  const stickyCount = isDocked
    ? // Dock内付箋
    wara.dock.stickies.length
    : // list内付箋
    wara.regions[regionIndex].lists[listIndex].stickies.length

  const mergedSticky = originalSticky.update(staged)
  const originalStickyProps = originalSticky.json()

  const sticky = staged.update({ id: originalStickyProps.id })
  const stickyProps = sticky.json()

  // マルシー情報：　storeから取得していたのを、付箋の詳細情報から取得するように変更。
  const storedComplaint = mergedSticky._props.marucComplaint

  const isDateMoved =
    regionIndex !== -1 &&
    listIndex !== -1 &&
    !!stickyProps.determinedDateTime &&
    stickyProps.determinedDateTime.date &&
    stickyProps.determinedDateTime.date !== displayDate

  // アフターが設定されていない
  const afterHasNotBeenCreated =
    !originalStickyProps.afterDateTime ||
    !originalStickyProps.afterDateTime.date

  // アフタになるべき条件が今セットされた
  const afterShouldBeCreated =
    stickyProps.afterDateTime && stickyProps.afterDateTime.date

  // まだマルシーリピートが作られていないか
  const marucRepeatHasNotBeenCreated = !mergedSticky.getUserValue(
    'hasMarucRepeat',
  )

  // マルシーリピートを作るべきかどうか
  // 「再修理」にチェックあり、かつ「マルシー完了」にチェックなし
  const marucRepeatShouldBeCreated =
    storedComplaint &&
    (storedComplaint.measures || []).includes(config.measures.payedRepaier) &&
    !(storedComplaint.complete || false)

  const refund = storedComplaint && storedComplaint.refund

  const isAfterCreation = !!(afterHasNotBeenCreated && afterShouldBeCreated)

  const isMarucRepeatCreation = !!(
    mergedSticky.isClaimerOrder &&
    marucRepeatHasNotBeenCreated &&
    marucRepeatShouldBeCreated
  )

  // 「完了」されたか
  const isFinished =
    (staged._props.finishStateId || -1) === config.finishState.complete

  // contactsのidを消して新たに作成する。contactsに変更があった場合は反映結果を使う。
  const newContacts = [...mergedSticky.json().contacts]
  for (let i = 0; i < newContacts.length; i++) {
    if (newContacts[i]) {
      const newTabContacts = [...newContacts[i]]
      for (let j = 0; j < newTabContacts.length; j++) {
        if (newTabContacts[j]) {
          newTabContacts[j] = { ...newTabContacts[j], id: null }
        }
      }
      newContacts[i] = newTabContacts
    }
  }

  // アフターの付箋を作成
  const afterSticky = originalSticky
    .update(
      {
        contacts: newContacts,
        determinedDateTime: {
          ...{ ...stickyProps }.afterDateTime,
          answerTimeId: null,
        },
        displayDate: { ...{ ...stickyProps }.afterDateTime }.date,
        parentId: stickyProps.id,
        displayOrder: stickyCount, // orderは最後にする
        afterDateTime: null,
        finishStateId: null,
        stickyStatusId: config.stickyStatus.notWorking,
        // アフタの重要申し送りにいつのアフタか記載する
        // 受注不可、受注注意のチェックボックスの状態を残す
        handOver: isAfterCreation
          ? {
            freeDescribe: [
              (stickyProps.handOver || {}).freeDescribe,
              displayDate.substr(5, 5) + 'のアフタ',
            ].join(' '),
            ids: [...((mergedSticky._props.handOver || {}).ids || [])],
          }
          : null,
        // 2019/03/20: 理由は不明だがアフタ付箋の中断理由を設定するようにした。idsに[-1]を指定したため、検索が期待通り動かなかったため修正する。
        // 2020/03/10: 中断理由の詳細は指定しないように変更(この時点では中断理由を削除しても問題ないが今後の変更を考え、陽に{}を設定しておく)
        suspendReason: {},
        showSchedule: true,
      },
      { shallow: true },
    )
    .remove({ id: true, cash: true, account: true })
    .setUserValue('afterOf', originalSticky.id) // 付箋間の関係を定義する
  // アフタ付箋の金額を削除
  afterSticky._props.contacts &&
    afterSticky._props.contacts.forEach(idcontact => {
      idcontact.forEach(contact => {
        contact.id = null
        contact.stickyId = null
        contact.paymentInfo = null
      })
    })

  // マルシーリピート付箋の支払情報をクリアする。
  const marucContacts = [...mergedSticky.json().contacts]
  for (let i = 0; i < marucContacts.length; i++) {
    if (marucContacts[i]) {
      const marucTabContacts = [...marucContacts[i]]
      for (let j = 0; j < marucTabContacts.length; j++) {
        if (marucTabContacts[j]) {
          marucTabContacts[j] = {
            ...marucTabContacts[j],
            id: null,
            paymentInfo: null,
          }
        }
      }
      marucContacts[i] = marucTabContacts
    }
  }

  const isHouseLabo = originalStickyProps.companyId === config.company.HL

  // マルシーリピートの付箋を作成
  const marucRepeatSticky = originalSticky
    .update(
      {
        stickyTypeId: config.stickyType.normal, // マルシーもnormalだが、明示的に設定する
        orderTypeId: config.orderType.repeat,
        contacts: marucContacts,
        determinedDateTime: {
          date: displayDate,
          answerTimeId: null,
        },
        displayDate,
        parentId: null, // リピート付箋では指定しないようにする
        displayOrder: stickyCount, // orderは最後にする
        afterDateTime: null,
        finishStateId: null,
        stickyStatusId: config.stickyStatus.notWorking,
        wishDateTimes: [{ date: today(), displayOrder: 0 }], // 当日の日付を設定
        // 重要申し送りにいつのマルシーリピートかを記載する
        handOver: isMarucRepeatCreation
          ? {
            freeDescribe: [
              (stickyProps.handOver || {}).freeDescribe,
              displayDate.substr(5, 5) +
                  `のマルシーリピ(有償修理${toJPYenText(refund)})`,
            ].join(' '),
          }
          : null,
        houseLabo: isHouseLabo
          ? { id: null, invoice: 0, extraFeeTax: 0 }
          : undefined,
      },
      { shallow: true },
    )
    .remove({ id: true, cash: true, account: true, orderId: true })
    .setUserValue('marucRepeatOf', originalSticky.id) // 付箋間の関係を定義する

  // アフターが他の日にあるかどうか
  const isAfterOnOtherDay = afterSticky.json().displayDate !== displayDate

  // マルシーリピートが他の日にあるかどうか
  const isMarucRepeatOnOtherDay =
    marucRepeatSticky.json().displayDate !== displayDate

  const { staffId, regionId } = location
    ? findListFromWara(wara, location)
    : { staffId: -1, regionId: -1 }

  // すでに集金付箋と支払情報の紐付けがされているかどうか
  // p2s: payment -> sticky
  // s2p: sticky -> payment
  // 双方向リストになっている
  const { p2s = {}, s2p = {} } =
    originalSticky.getUserValue('collectStickyMap') || {}

  return {
    // 判定条件
    isDateMoved,
    isAfterCreation, // TODO: この変は減らせる
    isMarucRepeatCreation, // TODO: この変は減らせる
    isAfterOnOtherDay,
    isMarucRepeatOnOtherDay,

    // リクエストに必要なSticky等の値
    originalSticky: mergedSticky,
    stagedSticky: sticky,
    afterSticky,
    marucRepeatSticky,
    newContacts,
    isFinished,

    // その他の雑多な値
    stickyCount, // そのオリジナルの付箋がアサインされているリストにいる付箋の数
    staffId,
    regionId,
    p2s,
    s2p,
  }
}
