import React from 'react'
import PropTypes from 'prop-types'
import connect from '../../connect'
import Checkbox from 'src/styled/form/checkbox'

/**
 * チェックボックス共通コンポーネント
 * @type {ReactComponent}
 */
export const CheckBox = ({ label, value, name, onChange, route, disabled }) => {
  const ddStyle = {
    flexGrow: 0,
    marginRight: '30px',
  }

  const groupName = `houselabo-${name}-${route}`
  const identifier = `id-${groupName}`

  return (
    <>
      <dt />
      <dd className={ 'input-wrap' } style={ ddStyle }>
        <Checkbox
          id={ identifier }
          checked={ value }
          onChange={ onChange }
          labelText={ label }
          disabled={ disabled }
          value={ value }
        />
      </dd>
    </>
  )
}

/**
 * propTypes
 * @type {object}
 */
CheckBox.propTypes = {
  label: PropTypes.string.isRequired, // 見出し
  value: PropTypes.bool.isRequired, // 表示する値
  name: PropTypes.string.isRequired, // 項目名
  onChange: PropTypes.func.isRequired, // 入力があった時のコールバック
  route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default connect(React.memo(CheckBox))
