import update from 'immutability-helper'
import Sticky from 'src/lib/class-sticky'

/**
 * タイプ定義
 * @type {string}
 */
const type = 'WARA.OPEN_MODAL'

/**
 * exportation
 */
export default {
  /**
   * action type
   * @type {string}
   */
  type,

  /**
   * action creator
   * prepare for <content { ...props } />
   * @param  {Component} content [description]
   * @param  {Sticky} sticky  [description]
   * @return {object}         [description]
   */
  creator: (content, props, sticky) => {
    return { type, payload: { content, props, sticky } }
  },

  /**
   * action handler
   * @param  {object} state   Immutableなstateです。このオブジェクトを変更すべきではありません。
   * @param  {object} action 関数に与える引数です。
   * @return {object}         適切にUpdateされた新しいStateを返却します。
   */
  handler: (state, action) => {
    const { content, props, sticky } = action.payload
    // content = null の時、モーダルで編集中の付箋と差分を置き換える。
    // props:
    //    sticky: 新しい付箋
    // stikcy: 差分
    if (content === null) {
      return update(state, {
        modal: {
          props: { sticky: { $set: props.sticky } },
          stage: { $set: sticky },
        },
      })
    }

    // 指定された付箋をモーダルで開く。
    return update(state, {
      modal: {
        isOpen: { $set: true },
        content: { $set: content },
        props: { $set: props },
        stage: {
          $set: new Sticky(sticky.castOff()),
        },
      },
    })
  },
}
