// react related
import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'

// components
import Jpy from 'src/components/commons/inputs/jpy'

export const AccountValue = ({ value, route, update, disabled, readOnly }) => {
  const identifier = `account-value-${route}`

  return (
    <Box
      sx={ {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, max-content)',
        alignItems: 'center',
        columnGap: '0.5rem',
      } }
    >
      <Typography
        htmlFor={ identifier }
        variant="subtitle1"
        sx={ { fontWeight: 'bold' } }
      >
        {'回収予定売掛金'}
      </Typography>
      <Jpy
        value={ value === '' ? null : value }
        disabled={ disabled || route !== 'assign' }
        onChange={ update }
        readOnly={ readOnly }
        id={ identifier }
        name={ identifier }
        blankNotZero
      />
    </Box>
  )
}

/**
 * propTypes
 * @type {object}
 */
AccountValue.propTypes = {
  // ownProps
  value: PropTypes.number.isRequired,
  route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
  update: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
}

export default React.memo(AccountValue)
