import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Dl, Dt, Dd } from 'src/styled/list'
import JpyBase from 'src/components/commons/inputs/jpy'
import form from 'src/styled/form'

import styled from 'styled-components'
import button, { buttonFill } from 'src/styled/button'
import { baseColor } from 'src/colors'
import putSettlement from 'src/lib/settlement-api/put-settlement'
import { makeCancelable, noop } from 'src/lib/cancelable-promise'
import { fluffyPromise as fluffy } from 'src/lib/api-utils'

const Button = styled.button`
  ${button};
  ${buttonFill(baseColor)};
  padding: 1px 10px;
`

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Jpy = styled(JpyBase)`
  ${form};
`

// {
//   "id": 1,
//   "billingStart": "2018-10-01",
//   "billingEnd": "2018-10-07",
//   "advanceValue": 10000
// }

export class Advance extends React.PureComponent {
  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    this.state = { value: false, status: 'not_yet', cancel: noop }
  }

  /**
   * componentWillUnmount
   * @return {void}
   */
  componentWillUnmount() {
    this.state.cancel()
  }

  onChange = value => this.setState({ value })
  onClick = () => {
    const { value: advanceValue } = this.state
    const { accessToken, env, query } = this.props

    const { promise, cancel } = makeCancelable(
      putSettlement({ ...query, advanceValue }, accessToken, env),
    )

    this.setState({ status: 'requesting', cancel })

    fluffy(promise)
      .then(() => this.setState({ status: 'success' }))
      .catch(err => {
        if (!err.isCanceled) {
          this.setState({ status: 'failure' })
        }
      })
  }
  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { value, status } = this.state
    const {
      advance: { advanceValue },
      isSettled,
    } = this.props

    const displayValue = (value === false ? advanceValue : value) || 0

    return (
      <Dl>
        <Dt>{'立替金'}</Dt>
        <Dd>
          <Wrap>
            <Jpy
              value={ displayValue }
              onChange={ this.onChange }
              disabled={ isSettled }
            />
            <Button onClick={ this.onClick } disabled={ isSettled }>
              {'登録'}
            </Button>
            {status === 'requesting' && <span>{'更新中'}</span>}
            {status === 'failure' && <span>{'サーバーエラー'}</span>}
          </Wrap>
        </Dd>
      </Dl>
    )
  }
}

Advance.propTypes = {
  // ownProps
  advance: PropTypes.object,
  query: PropTypes.shape({
    staffId: PropTypes.number.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }).isRequired,
  // stateProps
  accessToken: PropTypes.string.isRequired,
  env: PropTypes.object.isRequired,
  isSettled: PropTypes.bool.isRequired,
}

Advance.defaultProps = {
  advance: {},
}

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
const mapStateToProps = state => {
  return {
    accessToken: state.login.authentication.accessToken,
    env: state.env,
  }
}

export default connect(mapStateToProps)(Advance)
