/**
 * 内税額を計算
 * @param {*} value 金額
 */
export const salesTax = value => Math.floor((value / 1.1) * 0.1)

/**
 * 合計額を計算する。
 * @param {*} ecExtraFees
 */
export const calcTotal = ecExtraFees => {
  // sales: 売上, materialFees: 材料, expenses: 経費
  const sales = ecExtraFees.filter(extraFee => extraFee.ecFeeTypeId === 1)
  const materialFees = ecExtraFees.filter(
    extraFee => extraFee.ecFeeTypeId === 2,
  )
  const expenses = ecExtraFees.filter(extraFee => extraFee.ecFeeTypeId === 3)

  // 支払額は、(売上 - 材料 - 経費) * 25%（四捨五入）
  const salesTotal = sales.reduce((acc, extraFee) => acc + extraFee.fee, 0)
  const materialFeeTotal = materialFees.reduce(
    (acc, extraFee) => acc + extraFee.fee,
    0,
  )
  const expenseTotal = expenses.reduce((acc, extraFee) => acc + extraFee.fee, 0)
  const amountPaid = Math.round(
    (salesTotal - materialFeeTotal - expenseTotal) * 0.25,
  )

  // 消費税分は切り捨て
  const tax = Math.floor((amountPaid / 1.1) * 0.1)

  return { sales, materialFees, expenses, amountPaid, tax }
}
