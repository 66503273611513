import React from 'react'

const StickyHeader = () => (
  <tr>
    <th>{'受注会社名'}</th>
    <th>{'入力日'}</th>
    <th>{'対応希望日'}</th>
    <th>{'重要申送'}</th>
    <th>{'担当者'}</th>
    <th>{'作業箇所'}</th>
    <th>{'現況'}</th>
    <th>{'メモ'}</th>
    <th>{'作業内容'}</th>
    <th>{'現金（内金)'}</th>
    <th>{'売掛金'}</th>
    <th>{'売掛金回収方法'}</th>
    <th>{'回収予定日'}</th>
    <th>{'中断理由'}</th>
    <th>{'中断日'}</th>
    <th>{'中断期限'}</th>
    <th>{'アフター'}</th>
    <th>{'終了状態'}</th>
    <th>{'キャンセル理由'}</th>
    <th>{'終了日時'}</th>
    <th>{'見積もりNo.'}</th>
    <th>{'見積'}</th>
    <th>{'再依頼'}</th>
    <th>{'再依頼内容'}</th>
    <th>{'電話番号'}</th>
    <th>{'第2電話番号'}</th>
    <th>{'お客様名'}</th>
    <th>{'郵便番号'}</th>
    <th>{'都道府県'}</th>
    <th>{'住所(市区町村)'}</th>
    <th>{'住所(その他)'}</th>
    <th>{'建物情報'}</th>
    <th>{'顧客タイプ'}</th>
    <th>{'管理会社・オーナー関与'}</th>
    <th>{'ありの場合の連絡先'}</th>
    <th>{'判断'}</th>
    <th>{'判断者'}</th>
    <th>{'エリア'}</th>
    <th>{'受電'}</th>
    <th>{'サンキューコール'}</th>
    <th>{'内容'}</th>
    <th>{'更新者'}</th>
    <th>{'受注番号'}</th>
  </tr>
)

export default StickyHeader
