import React from 'react'
import PropTypes from 'prop-types'
import connect from './connect'
import linkHistoryHOC from './hocs/link-history'
import config from 'src/config'

const stickyConditions = config.stickyConditions.filter(
  condition => condition.search,
)
const conditions = [{ id: 10, name: '全て' }, ...stickyConditions]

export const Conditions = ({ value, onChange, disabled, isFlex }) => {
  return (
    <dl
      className={ 'label-inputs-wrap label-inputs-wrap-inline' }
      style={ { display: isFlex ? 'flex' : 'block' } }
    >
      <dt>{'検索条件'}</dt>
      <dd className={ 'input-wrap' }>
        <select
          value={ value }
          onChange={ onChange }
          disabled={ disabled }
          className={ 'short' }
        >
          {conditions.map(condition => (
            <option value={ condition.id } key={ condition.id }>
              {condition.name}
            </option>
          ))}
        </select>
      </dd>
    </dl>
  )
}

Conditions.propTypes = {
  // ownProps
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,

  // よく分からないが、注文一覧のビューでは flex を取ってしまうと崩れるし、それ以外ではflexを取る必要がある
  isFlex: PropTypes.bool,
}

/**
 * defaultProps
 * @type {object}
 */
Conditions.defaultProps = {
  isFlex: false,
  disabled: false,
}

export default linkHistoryHOC(connect(React.memo(Conditions)), 'conditionId')
