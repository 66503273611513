import config from 'src/config'

/**
 * 金額のIDに対応するマスタを検索する
 * @param {*} masterHouseLaboFees マスタデータ
 * @param {*} fee 金額データ
 */
export const hlMasterFromFeeId = (masterHouseLaboFees, feeId) => {
  const masterHouseLaboFees1 = masterHouseLaboFees[0] || []
  const masterHouseLaboFees2 = masterHouseLaboFees[1] || []
  const masterHouseLaboFeesG = masterHouseLaboFees[2] || []
  const master1 = masterHouseLaboFees1.find(master => master.feeId === feeId)
  const master2 = masterHouseLaboFees2.find(master => master.feeId === feeId)
  const masterG = masterHouseLaboFeesG.find(master => master.feeId === feeId)
  return master1 || master2 || masterG || {}
}

/**
 * 特定のinputTypeにマッチするマスタデータを取得する。
 * 紹介料など特定のinputTypeを検索するのが目的。
 * 同じinputTypeが複数ある時は最初の1つを返す（実際は使えない？）
 * 一致するinputTypeがないときは{}を返す。
 * @param {*} masterHouseLaboFees
 * @param {*} inputType
 */
export const hlMasterFromInputType = (masterHouseLaboFees, inputType) => {
  const masterHouseLaboFees1 = masterHouseLaboFees[0] || []
  const masterHouseLaboFees2 = masterHouseLaboFees[1] || []
  const masterHouseLaboFeesG = masterHouseLaboFees[2] || []
  const master1 = masterHouseLaboFees1.find(
    master => master.inputType === inputType,
  )
  const master2 = masterHouseLaboFees2.find(
    master => master.inputType === inputType,
  )
  const masterG = masterHouseLaboFeesG.find(
    master => master.inputType === inputType,
  )
  return master1 || master2 || masterG || {}
}

/**
 * マスタデータが金額の時trueを返す
 * 距離・区間を除く。
 * @param {*} master
 */
export const isPaymentMaster = master =>
  !(
    master.inputType === config.houseLaboFeeInputType.distance ||
    master.inputType === config.houseLaboFeeInputType.range ||
    master.inputType === config.houseLaboFeeInputType.string
  )

/**
 * マスターデータが紹介料の時trueを返す
 * @param {*} master
 */
export const isIntroduceMaster = master =>
  master.inputType === config.houseLaboFeeInputType.clientIntro ||
  master.inputType === config.houseLaboFeeInputType.sceneIntro

/**
 * 消費税の計算
 * 税率、四捨五入の有無をここでまとめる。
 * @param {*} value 金額
 */
export const salesTax = value => Math.floor(value * 0.1)

/**
 * クライアント精算額を計算する。
 * @param {*} houseLaboFees
 * @param {*} houseLaboExtraFees
 */
export const calcClientTotal = (
  masterHouseLaboFees,
  houseLabo,
  houseLaboFees,
  houseLaboExtraFees,
  // isGran, // グラン対応のため引数として渡すが使わないように変更
) => {
  let total = 0 // 総売上：紹介料（マイナス金額）を除いた合計
  let pureTotal = 0 // 純売上：紹介料を加えた合計
  let total_with_intro = 0 // 総売上＋出力指定のある紹介料 Req1416
  // ①②の選択された項目の金額合計
  houseLaboFees.forEach(fee => {
    const master = hlMasterFromFeeId(masterHouseLaboFees, fee.feeId)
    if (isPaymentMaster(master)) {
      // 集計対象の金額（距離や区間を除く）
      if (isIntroduceMaster(master)) {
        // 紹介料は「出力」指定なしでも合計する
        pureTotal += parseInt(fee.fee || '0', 10)
        // 出力指定がある時紹介料を合計する
        if (fee.isExport) {
          total_with_intro += parseInt(fee.fee || '0', 10)
        }
      } else if (fee.isExport) {
        // それ以外は「出力」指定ありのものを合計する
        total += parseInt(fee.fee || '0', 10)
        pureTotal += parseInt(fee.fee || '0', 10)
        total_with_intro += parseInt(fee.fee || '0', 10)
      }
    }
  })
  // マスターごとの合計金額
  // totalByMaster[i]: i列めのマスターごとの金額の合計
  const totalByMaster = new Array(masterHouseLaboFees.length).fill(0)
  for (let i = 0; i < masterHouseLaboFees.length; i++) {
    const masterFees = masterHouseLaboFees[i]
    let total = 0
    for (let j = 0; j < masterFees.length; j++) {
      const master = masterFees[j]
      if (isPaymentMaster(master)) {
        const fee = houseLaboFees.find(f => f.feeId === master.feeId)
        if (fee) {
          if (isIntroduceMaster(master)) {
            total += parseInt(fee.fee || '0', 10)
          } else if (fee.isExport) {
            total += parseInt(fee.fee || '0', 10)
          }
        }
      }
    }
    totalByMaster[i] = total
  }

  // ③の合計金額
  let extraFeeTotal = 0 // 税抜の合計
  houseLaboExtraFees.forEach(exfee => {
    if (exfee.isExport) {
      extraFeeTotal += exfee.unitFee * exfee.number
    }
  })
  // 消費税が設定されていない時は10％で計算する
  const extratotal =
    extraFeeTotal + (houseLabo.extraFeeTax || salesTax(extraFeeTotal))
  // ①②の合計+③の合計
  total += extratotal
  pureTotal += extratotal
  total_with_intro += extratotal
  //
  return { total, pureTotal, totalByMaster, extraFeeTotal, total_with_intro }
}

/**
 * 合計金額を計算する。
 * @param {*} houseLaboFees
 */
export const calcTotal = (
  contractAmount,
  masterHouseLaboFees,
  houseLabo,
  houseLaboFees,
  houseLaboExtraFees,
  isGran,
) => {
  // クライアント請求額
  const { total: clientTotal, pureTotal } = calcClientTotal(
    masterHouseLaboFees,
    houseLabo,
    houseLaboFees,
    houseLaboExtraFees,
    isGran,
  )
  // 総売上 = 契約金総額＋クライアント請求額
  let totalSales =
    (contractAmount ? contractAmount.total.totalContract : 0) + clientTotal

  // 準売上 = 総売上 - クライアント紹介料
  const netSales =
    (contractAmount ? contractAmount.total.totalContract : 0) + pureTotal

  return {
    totalSales,
    netSales,
  }
}

/**
 * 紹介料を計算する
 * @param {*} stickyProps 付箋のJSONデータ
 * @param {*} contractAmount 契約金額総額
 */
export const calcIntroduction = (stickyProps, contractAmount) => {
  // [{feeId, value}] を返す
  const houseLaboFees = stickyProps.houseLaboFees || []
  const houseLaboClientMaster = stickyProps.houseLaboClientMaster || {}
  const masterHouseLaboFees = houseLaboClientMaster.houseLaboFees || []
  const houseLaboExtraFees = stickyProps.houseLaboExtraFees || []

  // チェックを入れた①②の合計
  const totalHouseLaboFees = houseLaboFees => {
    let total = 0
    houseLaboFees.forEach(fee => {
      const master = hlMasterFromFeeId(fee.feeId)
      if (isPaymentMaster(master) && fee.isExport) {
        total += parseInt(fee.fee || '0', 10)
      }
    })
    return total
  }

  // チェックを入れた追加金額③の合計
  const totalHouseLaboExtraFees = houseLaboExtraFees => {
    let total = 0
    houseLaboExtraFees.forEach(exfee => {
      if (exfee.isExport) {
        total += exfee.unitFee * exfee.number
      }
    })
    total += salesTax(total)
    return total
  }

  // マスタの最初の金額？
  const firstHouseLaboExtraFees = masterHouseLaboFees => {
    return masterHouseLaboFees[0][0].params.price
  }

  // 基本[の合計]
  const totalHouseLaboBasicFees = houseLaboFees => {
    let total = 0
    houseLaboFees.forEach(fee => {
      const master = hlMasterFromFeeId(masterHouseLaboFees, fee.feeId)
      if (master && master.inputType) {
        if (master.inputType === config.houseLaboFeeInputType.basic) {
          // チェック関係なく合計する
          total += parseInt(fee.fee || 0, 10)
        }
      }
    })
    return total
  }

  // クライアント紹介料、現場紹介料： inputType -> masterを探す
  const inputTypes = [
    config.houseLaboFeeInputType.clientIntro,
    config.houseLaboFeeInputType.sceneIntro,
  ]
  const result = []
  inputTypes.forEach(inputType => {
    const master = hlMasterFromInputType(masterHouseLaboFees, inputType)
    if (master && master.params) {
      if (master.params.target === config.houseLaboFeeTarget.type1) {
        // 1: ③の最初の項目に率をかけたものがクライアント紹介料。
        const total = firstHouseLaboExtraFees(masterHouseLaboFees)
        const value = -Math.floor((total * (master.params.ratio || 100)) / 100)
        result.push({ feeId: master.feeId, value })
      } else if (master.params.target === config.houseLaboFeeTarget.type2) {
        // 2: 契約金総額に率をかけたものがクライアント紹介料
        const total = contractAmount ? contractAmount.total.totalContract : 0
        const value = -Math.floor((total * (master.params.ratio || 100)) / 100)
        result.push({ feeId: master.feeId, value })
      } else if (master.params.target === config.houseLaboFeeTarget.type3) {
        // 3: チェックを入れた①②③のすべての項目に率をかけたものがクライアント紹介料。
        //    契約金総額に率をかけたものが現場紹介料。
        if (master.inputType === config.houseLaboFeeInputType.clientIntro) {
          // クライアント紹介料： チェックを入れた①②③のすべての項目に率をかけたものがクライアント紹介料。
          const total =
            totalHouseLaboFees(houseLaboFees) +
            totalHouseLaboExtraFees(houseLaboExtraFees)
          const value = -Math.floor(
            (total * (master.params.ratio || 100)) / 100,
          )
          result.push({ feeId: master.feeId, value })
        } else if (
          master.inputType === config.houseLaboFeeInputType.sceneIntro
        ) {
          // 現場紹介料：2と同じ
          const total = contractAmount ? contractAmount.total.totalContract : 0
          const value = -Math.floor(
            (total * (master.params.ratio || 100)) / 100,
          )
          result.push({ feeId: master.feeId, value })
        }
      } else if (master.params.target === config.houseLaboFeeTarget.type4) {
        // 4: 基本料金に率をかけたものがクライアント紹介料。チェック有無関係なし。
        const total = totalHouseLaboBasicFees(houseLaboFees)
        const value = -Math.floor((total * (master.params.ratio || 100)) / 100)
        result.push({ feeId: master.feeId, value })
      }
    }
  })
  return result
}
