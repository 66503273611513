/**
 * provide range class
 * @type {Range}
 */
export default class Range {
  /**
   * constructor
   * @param  {number} init   initial number
   * @param  {number} finish terminal number
   * @return {object}        created loop
   */
  constructor(init, finish) {
    const result = []
    for (let i = init; i < finish + 1; i++) {
      result.push(i)
    }
    return result
  }
}

/**
 * {1:2, 2:3, 3:1}のように循環する対応をオブジェクトで表現します。
 * @param  {number} start  loop start
 * @param  {number} finish loop finish
 * @return {object}        Looping directed list in object
 */
export const createLoopMap = (start, finish) => {
  let result

  if (start < finish) {
    result = new Range(start, finish).map(i => ({
      key: i,
      value: i === finish ? start : i + 1,
    }))
  } else if (start === finish) {
    result = [{ key: start, value: start + 1 }]
  } else {
    result = new Range(finish, start).map(i => ({
      key: i,
      value: i === finish ? start : i - 1,
    }))
  }

  return result.reduce((prev, x) => {
    prev[x.key] = x.value
    return prev
  }, {})
}

/**
 * 等間隔で作成した時間の文字列の配列を返す
 * @param  {number} interval     minuteIntervals
 * @param  {number} start=0      start at
 * @param  {number} finish=60*24 finish at
 * @return {Array<string>}       array of timeString
 */
export const getIntervalTimeStrings = (
  interval,
  start = 0,
  finish = 60 * 24,
) => {
  const minutes = []
  for (let i = start; i < finish; i = i + interval) {
    minutes.push(i)
  }
  return minutes.map(x => {
    const hour = ('00' + Math.floor(x / 60)).slice(-2)
    const minute = ('00' + (x % 60)).slice(-2)
    return `${hour}:${minute}`
  })
}
