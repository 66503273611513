import React from 'react'
import PropTypes from 'prop-types'
import createClassNames from 'classnames'
import Modal from 'react-modal'
// import config from 'src/config'
import moment from 'moment'
// import { today } from 'src/lib/moment'

export class CalcButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      // inputValue: null, // 入力した値（文字列）※ 今の所使わないが、用意だけしておく。
      calculation: null, // 計算した結果（文字列で設定する）
      modalIsOpen: false, // 電卓の表示フラグ
    }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.inputType !== nextProps.inputType ||
      this.props.params !== nextProps.params ||
      // this.state.inputtValue !== nextState.inputValue ||
      this.state.calculation !== nextState.calculation ||
      this.state.modalIsOpen !== nextState.modalIsOpen
    )
  }

  /**
   * 電卓ダイアログを表示する。
   */
  openModal() {
    // 初期値を計算する
    const { initialValue } = this.initialValue()
    this.setState({ modalIsOpen: true, calculation: initialValue })
  }

  /**
   * 電卓ダイアログを閉じる
   */
  closeModal() {
    this.setState({ modalIsOpen: false })
  }

  // ACボタンを押したとき
  onAllClear = () => {
    this.setState({ calculation: 0 })
  }

  // OKボタンで表に計算結果を流し込む
  clickOkButton = () => {
    this.closeModal()
    this.props.onDecide(this.state.calculation)
  }

  /**
   * 日付が日曜、祝日かを判定する
   * @param {*} date
   */
  isHoliday = date => {
    // TODO: 祝日マスタを検索して祝日の判定をする。
    const day = moment(date)
    if (day.isValid()) {
      // 今の所日曜のチェックのみ。
      return day.weekday() === 0
    }
    return false
  }

  /**
   * ダイアログの初期値を計算する
   */
  initialValue = () => {
    // 日付チェックする時
    // const { inputType, params, sticky } = this.props
    // const stickyProps = sticky.json() || {}
    // const isHoliday = this.isHoliday(stickyProps.displayDate || today())
    const { inputType, params } = this.props
    const isHoliday = true // 今の所日曜・祝日の金額はいつも表示しておく。

    let initialValue = '0'
    if (inputType === 1) {
      // 出動料
      initialValue = params.price.toString()
    } else if (inputType === 2) {
      // 日・祝料金
      if (isHoliday) {
        initialValue = params.price.toString()
      }
    } else if (inputType === 3) {
      // 遠方費
      initialValue = params.price.toString()
    }
    return {
      initialValue,
      isHoliday,
    }
  }

  render() {
    const { inputType, params } = this.props
    const { calculation } = this.state

    const buttonClassNames = createClassNames({
      button: true,
      'button-open': true,
      'label-inputs-wrap-button': true,
      'button-disabled': false,
    })

    // 電卓のモーダル
    const modalStyle = {
      overlay: {
        backgroundColor: 'rgba(255, 255, 255, .1)',
      },
      content: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '208px',
        height: '294px',
        padding: '1.5rem',
        background: 'rgba(33, 33, 33, .9)',
        backdropFilter: 'blur(4px)',
        border: '1px solid #212121',
        borderRadius: '10px',
        boxShadow: 'rgba(0, 0, 0, 0.4) 0px 10px 10px',
      },
    }

    // 計算結果の表示部分
    const calculationStyle = {
      alignSelf: 'center',
      width: 'calc(208px - 0.75rem)',
      backgroundImage: 'linear-gradient(180deg, #fafafa, #eeeeee)',
      fontSize: '3rem',
      fontFamily: 'Orbitron, sans-serif',
      textAlign: 'right',
    }

    // 式のタイトル部分
    const formTitleStyle = {
      display: 'flex',
      justifyContent: 'flex-start',
      marginBottom: '0.75rem',
      marginRight: '0.75rem',
      color: '#ffffff',
      fontSize: '1.25rem',
    }
    // 式の数値部分
    const formulaStyle = {
      display: 'flex',
      justifyContent: 'flex-start',
      marginBottom: '0.75rem',
      marginRight: '0.75rem',
      fontSize: '2.125rem',
      fontFamily: 'Orbitron, sans-serif',
      color: '#ffffff',
    }
    // OKボタンなどを囲む
    const bottomButtons = {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '1.5rem',
    }
    // 電卓のボタン
    const calculatrButtonStyle = {
      width: '9rem',
      padding: '0.75rem',
      borderRadius: '0.75rem',
      fontSize: '1.25rem',
      fontFamily: 'Orbitron, sans-serif',
    }
    // 対象外メッセージ
    const excludedMessage = {
      fontSize: '1.5rem',
      color: '#ffffff',
    }
    /*
     ** 以下は計算に使う項目。
     */
    const { initialValue, isHoliday } = this.initialValue()

    // 電卓を最初に表示したとき表示する計算結果
    const firstCalculation = initialValue || ''

    /**
     * 料金タイプごとの電卓の中身
     */
    const contents = () => {
      if (inputType === 1) {
        // 出動料
        return (
          <>
            <div style={ { display: 'flex', alignItems: 'flex-end' } }>
              <div style={ { display: 'flex', flexDirection: 'column' } }>
                <span style={ formTitleStyle }>{'出動料'}</span>
                <span style={ formulaStyle }>
                  {params.price.toLocaleString()}
                </span>
              </div>
            </div>
          </>
        )
      } else if (inputType === 2) {
        // 日・祝料金
        return (
          <>
            <div style={ { display: 'flex', alignItems: 'flex-end' } }>
              <div style={ { display: 'flex', flexDirection: 'column' } }>
                <span style={ formTitleStyle }>{'日・祝料金'}</span>
                <span style={ formulaStyle }>
                  {isHoliday ? params.price.toLocaleString() : '0'}
                </span>
              </div>
            </div>
          </>
        )
      } else if (inputType === 3) {
        // 遠方費
        return (
          <>
            <div style={ { display: 'flex', alignItems: 'flex-end' } }>
              <div style={ { display: 'flex', flexDirection: 'column' } }>
                <span style={ formTitleStyle }>{'遠方費'}</span>
                <span style={ formulaStyle }>
                  {params.price.toLocaleString()}
                </span>
              </div>
            </div>
          </>
        )
      } else {
        return (
          <div style={ { display: 'flex', alignItems: 'flex-end' } }>
            <span style={ excludedMessage }>{'自動計算できません'}</span>
          </div>
        )
      }
    }

    return (
      <div
        style={ {
          display: 'inline',
          height: '20px',
          width: '20px',
        } }
      >
        <button
          className={ buttonClassNames }
          style={ { border: 0, fontSize: '16px' } }
          onClick={ this.openModal }
          disabled={ this.props.disabled }
        >
          <i className={ 'fa fa-calculator' } />
        </button>
        <Modal
          isOpen={ this.state.modalIsOpen }
          style={ modalStyle }
          ariaHideApp={ false }
        >
          <div
            style={ {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
            } }
          >
            <div>
              <input
                style={ calculationStyle }
                value={ calculation || firstCalculation.toLocaleString() }
                readOnly
              />
              <button
                style={ {
                  ...calculatrButtonStyle,
                  marginTop: '1.5rem',
                  background: 'linear-gradient(-180deg, #ff4081, #c51162)',
                } }
                onClick={ this.onAllClear }
              >
                {'AC'}
              </button>
            </div>
            {contents()}
            <div style={ bottomButtons }>
              <button onClick={ this.closeModal } style={ calculatrButtonStyle }>
                {'cancel'}
              </button>
              <button
                onClick={ this.clickOkButton }
                style={ {
                  ...calculatrButtonStyle,
                  background: 'linear-gradient(-180deg, #69f0ae, #00c853)',
                } }
              >
                {'OK'}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

/**
 * propTypes
 */
CalcButton.propTypes = {
  inputType: PropTypes.number.isRequired, // 項目の種類→ダイアログを切り替えるのに使用
  params: PropTypes.object.isRequired, // マスタから渡す計算に使用するための値
  // sticky: PropTypes.object.isRequired, // 付箋
  onDecide: PropTypes.func.isRequired, // ダイアログのボタンが押された時のハンドラ
  disabled: PropTypes.bool,
}
/**
 * defaultProps
 */
CalcButton.defaultProps = {
  contractAmount: null,
  disabled: false,
}
export default CalcButton
