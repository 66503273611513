import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NETWORK_STATUSES } from 'src/reducers/network-status'
import { ACTION_TYPES as USEPRESET_ACTION_TYPES } from 'src/reducers/user-preset'
import { isToday } from 'src/lib/validate'

/**
 map state to props
 * @param  {object} state state
 * @return {object}       props
 */
const mapStateToProps = state => {
  return {
    networkStatus: state.networkStatus.postUserPreset,
    displayDate: state.displayDate.displayDate,
  }
}

/**
 * map dispatch to props
 * @param  {function} dispatch dispatcher
 * @return {object}             props
 */
const mapDispatchToProps = dispatch => {
  return {
    /**
     * SS を非表示状態にする
     * @param  {number} regionId region id
     * @param  {number} ssId     ss id
     * @return {void}
     */
    hideSS: (regionId, ssId) =>
      dispatch({
        type: USEPRESET_ACTION_TYPES.TOGGLE_STAFF_DISPLAY,
        payload: { regionId, ssId, display: false },
      }),
  }
}

export class Hide extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    regionId: PropTypes.number.isRequired,
    staffId: PropTypes.number.isRequired,
    displayDate: PropTypes.string.isRequired,
    // stateProps
    networkStatus: PropTypes.symbol.isRequired,
    // dispatchProps
    hideSS: PropTypes.func.isRequired,
  }

  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    this.state = { isNetworkTrying: false }
  }

  /**
   * UNSAFE_componentWillReceiveProps
   * @param  {object} nextProps React props.
   * @return {void}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.networkStatus === NETWORK_STATUSES.TRYING &&
      nextProps.networkStatus !== NETWORK_STATUSES.TRYING &&
      this.state.isNetworkTrying
    ) {
      this.setState({ isNetworkTrying: false })
    }
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.isNetworkTrying !== nextState.isNetworkTrying ||
      this.props.displayDate !== nextProps.displayDate
    )
  }

  /**
   * 隠すボタンを押した時のハンドラ
   * @return {void}
   */
  onHideCkick = () => {
    const { hideSS, regionId, staffId } = this.props

    this.setState({ isNetworkTrying: true })
    hideSS(regionId, staffId)
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { isNetworkTrying } = this.state

    const disabled = !isToday(this.props.displayDate)

    return (
      <div className={ 'control-staff-display' }>
        <button
          className={ 'button button-change-staff-display' }
          onClick={ this.onHideCkick }
          disabled={ isNetworkTrying || disabled }
        >
          <i aria-hidden={ 'true' } className={ 'fa fa-refresh' } />
        </button>
        <span>{'非表示にする'}</span>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Hide)
