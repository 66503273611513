// react related
import React from 'react'
import PropTypes from 'prop-types'

// components
import Jpy from 'src/components/commons/inputs/jpy'

import { Box, Typography } from '@mui/material'

export const AccountCollectedValue = ({
  value,
  route,
  update,
  disabled,
  isValid,
}) => {
  const identifier = `account-collected-value-${route}`

  return (
    <Box
      sx={ {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, max-content)',
        alignItems: 'center',
        columnGap: '0.5rem',
      } }
    >
      <Typography
        htmlFor={ identifier }
        variant="subtitle1"
        sx={ { fontWeight: 'bold' } }
      >
        {'回収売掛金'}
      </Typography>
      <Jpy
        value={ value === '' ? null : value }
        disabled={ disabled || route !== 'assign' }
        onChange={ update }
        isValid={ isValid }
        blankNotZero
      />
    </Box>
  )
}

/**
 * propTypes
 * @type {object}
 */
AccountCollectedValue.propTypes = {
  // ownProps
  value: PropTypes.number.isRequired,
  route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
  update: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
}

export default React.memo(AccountCollectedValue)
