import utils from 'src/lib/api-utils'

export const get = (startDate, accessToken, env) => {
  const endpoint = utils.createEndpoint(env, 'refreshLiquidationWork')
  const url = endpoint + '/' + startDate

  const request = fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  })

  return request
}

export default get
