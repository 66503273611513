import React from 'react'
import PropTypes from 'prop-types'

/**
 * マルシー付箋の表面
 * @param {function} props props
 */
const Claimer = props => {
  const { contacts } = props

  const contact = contacts
    ? contacts[0]
      ? contacts[0][0]
        ? contacts[0][0]
        : {}
      : {}
    : {}

  return (
    <div className={ 'sticky-claimer-content' }>
      <div className={ 'sticky-element sticky-client-name' }>
        {contact.name || '(未入力)'}
      </div>
    </div>
  )
}

/**
 * propTypes
 * @type {object}
 */
Claimer.propTypes = {
  contacts: PropTypes.array,
}

/**
 * defaultProps
 * @type {object}
 */
Claimer.defaultProps = {
  contacts: [],
}

// export
export default Claimer
