import React, { Component } from 'react'
import PropTypes from 'prop-types'
import connect from './props'

// components
import DatePicker from 'react-ja-date-picker'

// libs
import config from 'src/config'
import noop from 'src/lib/noop'
import { today, thisWeek } from 'src/lib/moment'
import moment from 'moment'
import { styled } from '@mui/material/styles'
import { Box, Button, IconButton, TextField } from '@mui/material'
import {
  ArrowCircleLeft,
  ArrowCircleRight,
  CalendarMonth,
} from '@mui/icons-material'
import theme from 'src/theme.js'
import { ThemeProvider } from '@mui/material/styles'

const CalendarInputWrap = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  position: 'relative',

  '& .MuiTextField-root': {
    width: '11rem',
  },
}))

/**
 * 開閉ボタンつきカレンダーコンポーネント
 * @type {ReactComponent}
 */
export class WrappedCalendarWeek extends Component {
  /**
   * [isValid description]
   * @param {string} date date
   * @return {boolean} isValid
   */
  static isValid = date => {
    if (!date) {
      return true
    }

    const planeDateString = date.split('-').join('')
    if (planeDateString.length === 4) {
      const month = parseInt(planeDateString.substr(4, 2), 10)
      const day = parseInt(planeDateString.substr(6, 2), 10)
      if (month < 1 || month > 12 || day < 1 || day > 31) {
        return false
      }
      return true
    }

    if (planeDateString.length < 8) {
      return false
    }
    const year = parseInt(planeDateString.substr(0, 4), 10)
    const month = parseInt(planeDateString.substr(4, 2), 10)
    const day = parseInt(planeDateString.substr(6, 2), 10)
    if (year < 1900 || month < 1 || month > 12 || day < 1 || day > 31) {
      return false
    }
    return true
  }

  static normalize = date => {
    const planeDateString = date.split('-').join('')

    if (planeDateString.length === 4) {
      const year = moment(today()).year()
      const month = parseInt(planeDateString.substr(0, 2), 10)
      const day = parseInt(planeDateString.substr(2, 2), 10)
      const m = moment(`${year}-${month}-${day}`)
      return m.isValid()
        ? thisWeek(m.format('YYYY-MM-DD'), config.startDayOfWeek)
        : ''
    }

    const year = planeDateString.substr(0, 4)
    const month = planeDateString.substr(4, 2)
    const day = planeDateString.substr(6, 2)
    if (!year || !month || !day) {
      return ''
    }
    const m = moment(`${year}-${month}-${day}`)
    return m.isValid()
      ? thisWeek(m.format('YYYY-MM-DD'), config.startDayOfWeek)
      : ''
  }

  /**
   * PropTypes
   * @type {object}
   */
  static propTypes = {
    // storeから空いてるかどうかを取得
    // 他のカレンダーを閉じるのに使う
    isOpen: PropTypes.func.isRequired,
    buttonId: PropTypes.string, // ボタンに振るID値
    inputId: PropTypes.string, // INPUTのID
    value: PropTypes.string,
    onSelect: PropTypes.func,
    closeAll: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    unregister: PropTypes.func.isRequired,
    displayDateLabel: PropTypes.bool,
    disabled: PropTypes.bool,
    nocalendar: PropTypes.bool,
  }

  /**
   * DefaultProps
   * @type {object}
   */
  static defaultProps = {
    buttonId: '',
    inputId: '',
    value: '',
    onSelect: noop,
    displayDateLabel: true,
    disabled: false,
    nocalendar: false,
  }

  state = { onEditing: false }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.onEditing !== nextState.onEditing ||
      this.props.value !== nextProps.value ||
      this.props.disabled !== nextProps.disabled
    ) {
      return true
    }
    const isOpenNext = nextProps.isOpen(this.id)
    const isOpen = this.props.isOpen(this.id)
    return isOpenNext !== isOpen || nextProps.value !== this.props.value
  }

  /**
   * componentWillUnmount
   * @return {void}
   */
  componentWillUnmount() {
    // このコンポーネントの登録解除
    this.props.unregister(this.id)
  }

  // 一意なIDを生成
  id = Symbol('Wrapped Calendar Week ID')

  /**
   * [onFocus description]
   * @param  {Event} e evnet
   * @return {void}
   */
  onFocus = () => {
    this.setState({ onEditing: this.props.value })
  }

  /**
   * [onChange description]
   * @param  {Event} e evnet
   * @return {void}
   */
  onChange = e => {
    this.setState({ onEditing: e.target.value })
  }

  /**
   * [onBlur description]
   * @param  {Event} e evnet
   * @return {void}
   */
  onBlur = () => {
    const { onEditing } = this.state
    if (WrappedCalendarWeek.isValid(onEditing)) {
      this.toggleCalendar(false)
      this.props.onSelect(WrappedCalendarWeek.normalize(onEditing))
    }
    this.setState({ onEditing: false })
  }

  onLastWeekClick = () => {
    this.toggleCalendar(false)
    const lastWeek = moment(this.props.value)
      .add(-7, 'day')
      .format('YYYY-MM-DD')
    this.props.onSelect(lastWeek)
  }

  onNextWeekClick = () => {
    this.toggleCalendar(false)
    const nextWeek = moment(this.props.value)
      .add(7, 'day')
      .format('YYYY-MM-DD')
    this.props.onSelect(nextWeek)
  }

  onThisWeekClick = () => {
    this.toggleCalendar(false)
    this.props.onSelect(WrappedCalendarWeek.normalize(today()))
  }

  /**
   * toggleCalendar
   * @param  {boolean|any} open open or close as boolean. with <any>, toggle
   * @return {void}
   */
  toggleCalendar = open => {
    const isOpen = this.props.isOpen(this.id)
    const toggleValue = typeof open === 'boolean' ? open : !isOpen
    // 開ける時は、一旦全部閉める
    if (toggleValue) {
      this.props.closeAll()
    }
    this.props.toggle(this.id, toggleValue)
  }

  /**
   * カレンダーの日付選択時に発火
   * @param  {number} year  year
   * @param  {number} month month
   * @param  {number} day   day
   * @return {void}
   */
  onCalendarSelect = (year, month, day) => {
    this.toggleCalendar(false)

    this.props.onSelect(
      WrappedCalendarWeek.normalize(
        `${year}-${month.toString().padStart(2, '0')}-${day
          .toString()
          .padStart(2, '0')}`,
      ),
    )
  }

  /**
   * render
   * @return {ReactDOM} rendered React DOM
   */
  render() {
    const { onEditing } = this.state

    const {
      value,
      buttonId,
      inputId,
      displayDateLabel,
      disabled,
      isOpen: _isOpen,
      nocalendar,
    } = this.props

    const isOpen = _isOpen(this.id)

    const displayDate =
      onEditing === false ? WrappedCalendarWeek.normalize(value) : onEditing

    const inputStyle = {}
    if (!displayDateLabel) {
      inputStyle.display = 'none'
    }
    if (nocalendar) {
      inputStyle.marginRight = 0
    }

    return (
      <ThemeProvider theme={ theme }>
        <Box
          sx={ { display: 'flex', flexDirection: 'row', alignItems: 'center' } }
        >
          <Box
            sx={ { display: 'flex', flexDirection: 'row', alignItems: 'center' } }
          >
            <IconButton
              color="primary"
              onClick={ this.onLastWeekClick }
              disabled={ disabled }
            >
              <ArrowCircleLeft />
            </IconButton>

            <CalendarInputWrap>
              <TextField
                id={ inputId }
                name={ inputId }
                onFocus={ this.onFocus }
                onChange={ this.onChange }
                onBlur={ this.onBlur }
                type="text"
                value={ displayDate }
                disabled={ disabled }
                placeholder={ `例: ${today()}` }
                error={ !WrappedCalendarWeek.isValid(displayDate) }
                variant="outlined"
                size="small"
                sx={ {
                  background: '#fff',
                  display: displayDateLabel ? 'inline-flex' : 'none',
                  '& .MuiOutlinedInput-root': {
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(0, 0, 0, 0.23)',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'primary.main',
                    },
                  },
                  '& .MuiInputBase-root': { height: '28px' },
                  '& .MuiInputBase-input': {
                    height: '20px',
                    padding: '0 14px',
                  },
                } }
              />
              {!nocalendar && (
                <IconButton
                  color="primary"
                  disabled={ disabled }
                  id={ buttonId }
                  onClick={ this.toggleCalendar }
                  size="large"
                  sx={ {
                    display: !displayDateLabel && 'none',
                    position: 'absolute',
                    left: '8rem',
                  } }
                >
                  <CalendarMonth />
                </IconButton>
              )}
              {isOpen && (
                <div
                  style={ {
                    background: '#fff',
                    position: 'absolute',
                    top: displayDateLabel ? '2.5rem' : 0,
                    zIndex: 10000,
                  } }
                >
                  <DatePicker
                    date={ value }
                    type={ 'button' }
                    onSelect={ this.onCalendarSelect }
                  />
                </div>
              )}
            </CalendarInputWrap>

            <IconButton
              color="primary"
              onClick={ this.onNextWeekClick }
              disabled={ disabled }
            >
              <ArrowCircleRight />
            </IconButton>
          </Box>
          <Button
            onClick={ this.onThisWeekClick }
            disabled={ disabled }
            variant="outlined"
          >
            {'今週'}
          </Button>
        </Box>
      </ThemeProvider>
    )
  }
}

export default connect(WrappedCalendarWeek)
