import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import StickyContentButtons from '../buttons'
import switz from 'switz'
import config from 'src/config'
import createClassNames from 'classnames'
import Memo from './memo'
import Claimer from './claimer'
import Private from './private'
import Noop from './noop'
import Payment from './payment'
// import { findStickyFromWara } from 'src/lib/search'
import ClassSticky from 'src/lib/class-sticky'
import {
  MarucBadge,
  ReRequestBadge,
  PhoneCallBadge,
  ShowRepeatBadge,
} from 'src/components/commons/badges'

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
export const mapStateToProps = state => {
  return {
    wara: state.wara.data,
    masterStickyTypes: state.master.data.stickyTypes || [],
    accessToken: state.login.authentication.accessToken,
  }
}

export class SpecialStickySurface extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    className: PropTypes.string.isRequired,
    location: PropTypes.shape({
      regionIndex: PropTypes.number.isRequired,
      listIndex: PropTypes.number.isRequired,
      stickyIndex: PropTypes.number.isRequired,
    }).isRequired,
    sticky: PropTypes.instanceOf(ClassSticky).isRequired,
    getSSworkList: PropTypes.func.isRequired,
    workState: PropTypes.number.isRequired,
    // mappedProps
    wara: PropTypes.shape({
      regions: PropTypes.arrayOf(
        PropTypes.shape({
          lists: PropTypes.arrayOf(
            PropTypes.shape({
              stickies: PropTypes.arrayOf(PropTypes.instanceOf(ClassSticky)),
            }),
          ),
        }),
      ).isRequired,
    }).isRequired,
    masterStickyTypes: PropTypes.array.isRequired,
    setWaiting: PropTypes.func.isRequired,
    isMaruc: PropTypes.bool.isRequired,
    isReRequest: PropTypes.bool.isRequired,
    isPhoneCall: PropTypes.bool.isRequired,
    isShowSchedule: PropTypes.bool.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    className: '',
  }

  /**
   * Defaults
   * @type {object}
   */
  static defaultProps = {
    stickyTypeId: config.stickyType.normal,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate() {
    return true
  }

  render() {
    // console.log(this.props)

    const {
      location,
      // wara,
      sticky: propSticky,
      masterStickyTypes,
      setWaiting,
      isMaruc,
      isReRequest,
      isPhoneCall,
      isShowSchedule,
      getSSworkList,
      workState,
    } = this.props

    // const sticky = findStickyFromWara(wara, location) || new Sticky()
    const sticky = propSticky || new ClassSticky()
    const stickyProps = sticky.json()

    const { stickyTypeId } = stickyProps

    // ラベル作成
    //
    const labels = masterStickyTypes.filter(
      stickyType => stickyType.id === stickyTypeId,
    )

    // NOTE: マスタに id === 5 がいないので、適当にやってる。
    let label = labels.length > 0 ? labels[0].name : '(不明な付箋タイプ)'
    if (stickyTypeId === config.stickyType.noop) {
      label = ''
    } else if (stickyTypeId === config.stickyType.payment) {
      label = '集金'
    }

    const additionals = createClassNames({
      'is-noop-sticky': stickyTypeId === config.stickyType.noop,
      'is-payment-sticky': stickyTypeId === config.stickyType.payment,
    })

    // 付箋の表面記載事項を、付箋タイプに応じてかき分け
    const StickySurfaceContent = switz(stickyTypeId, s =>
      s
        .case(config.stickyType.memo, () => (
          <Memo workContent={ stickyProps.workContent } />
        ))
        .case(config.stickyType.claimer, () => (
          <Claimer contacts={ stickyProps.contacts } />
        ))
        .case(config.stickyType.private, () => (
          <Private
            workPoint={ stickyProps.workPoint }
            determinedDateTime={ stickyProps.determinedDateTime }
            workContent={ stickyProps.workContent }
          />
        ))
        .case(config.stickyType.noop, () => <Noop>{'（空白）'}</Noop>)
        .case(config.stickyType.payment, () => {
          const account = stickyProps.account || {}
          return <Payment sticky={ sticky } account={ account } />
        })
        .default(() => null),
    )

    const className =
      this.props.className +
      (stickyProps.finishStateId === config.finishState.complete
        ? ' sticky-body-finished'
        : '') +
      ' ' +
      additionals

    return (
      <div className={ className }>
        <div className={ 'sticky-body' }>
          <h4>{label}</h4>
          {StickySurfaceContent}
        </div>
        <StickyContentButtons
          location={ location }
          sticky={ sticky }
          setWaiting={ setWaiting }
          getSSworkList={ getSSworkList }
          workState={ workState }
        />
        {isPhoneCall && <PhoneCallBadge offsetX={ -4 } offsetY={ 7 } />}
        {isMaruc && <MarucBadge offsetX={ -4 } offsetY={ 7 } />}
        {isReRequest && <ReRequestBadge offsetX={ -4 } offsetY={ 7 } />}
        {isShowSchedule && <ShowRepeatBadge offsetX={ -4 } offsetY={ 7 } />}
      </div>
    )
  }
}

export default connect(mapStateToProps)(SpecialStickySurface)
