import React from 'react'
import PropTypes from 'prop-types'
import { Box, TextField, Typography } from '@mui/material'
import theme from 'src/theme.js'
import { ThemeProvider } from '@mui/material/styles'

export const Input = ({
  value,
  label,
  disabled,
  name,
  update,
  placeholder,
  wide,
}) => {
  const onChange = e => {
    const value = e.target.value

    if (name === 'freeword') {
      const isFreewordSpecialized = value !== ''
      update({
        [name]: value,
        freewordSearch: isFreewordSpecialized,
        detailedSearch: !isFreewordSpecialized,
      })
    } else {
      update({ [name]: value })
    }
  }

  const renderInput = () => {
    const props = {
      id: `detailed-search-${name}-input`,
      name: `detailed-search-${name}-input`,
      type: 'text',
      onChange: onChange,
      disabled: disabled,
      value,
      placeholder,
      style: wide ? { width: '90%' } : {},
    }

    return (
      <TextField
        { ...props }
        size="small"
        sx={ {
          width: wide ? '90%' : '50%',
          background: '#fff',
          '& .MuiOutlinedInput-root': {
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'primary.main',
            },
          },
          '& .MuiInputBase-root': { height: '28px' },
          '& .MuiInputBase-input': { height: '20px', padding: '0 14px' },
        } }
      />
    )
  }

  const renderLabel = () => {
    return <label htmlFor={ `detailed-search-${name}-input` }>{label}</label>
  }

  return (
    <ThemeProvider theme={ theme }>
      <Box
        sx={ {
          display: 'grid',
          gridTemplateColumns: '5.5rem 1fr',
          alignItems: 'center',
        } }
      >
        {[
          <Typography key={ 1 } variant="subtitle1" sx={ { fontWeight: 'bold' } }>
            {renderLabel()}
          </Typography>,
          <dd key={ 2 }>{renderInput()}</dd>,
        ]}
      </Box>
    </ThemeProvider>
  )
}

/**
 * propTypes
 * @type {object}
 */
Input.propTypes = {
  // ownProps
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  update: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  wide: PropTypes.bool,
}

/**
 * defaultProps
 * @type {object}
 */
Input.defaultProps = {
  disabled: false,
  placeholder: '',
  wide: false,
}

export default React.memo(Input)
