// partial hooks
import stickyDeleteHook from './partials/delete-sticky'
import stickyInsertHook from './partials/insert-sticky'
import serviceStaffHook from './partials/service-staff'

/**
 * redux hook middleware に与える prehooks 関数オブジェクトです
 * @type {Object}
 */
export default {
  ...stickyDeleteHook,
  ...stickyInsertHook,
  ...serviceStaffHook,
}
