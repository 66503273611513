import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { baseColor, red, lightGreen, darkBaseColor } from 'src/colors'

// components
import { ContentWrap, Header, FormsWrap } from '../../../styled'
import Index from './partials/index'
import { WeekSettlement } from 'src/components/commons/history-linked-forms/week'
import Reload from 'src/components/commons/history-linked-forms/reload'
import Limit from 'src/components/commons/history-linked-forms/limit'
import Button from 'src/styled/button/ghost'
import ValidationErrorMessage from 'src/components/commons/validation-error-message'
import FileUploader from 'src/components/commons/file-uploader'

// libs
import { qsjoin } from 'src/lib/format'
import getSettlementStaffIds from 'src/lib/settlement-api/get-staffs'
import putSmileData from 'src/lib/smile-data-api/put'
import putPipeMaterial from 'src/lib/pipe-material-api/put'
import updateRequestHandler from '../../../update-request-handler'
import { makeCancelable, noop } from 'src/lib/cancelable-promise'
import { fluffyPromise as fluffy } from 'src/lib/api-utils'
import moment from 'moment'
import putSettlement from 'src/lib/settlement-api/put-settlement'
import settlementAll from 'src/lib/settlement-api/settlement-all'
import refreshLiquidationWork from 'src/lib/liquidation-work-api/refresh-liquidation-work'
import { today, thisWeek } from 'src/lib/moment'
import config from 'src/config'

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin: 50px auto 0;
  padding: 0 30px;
`

const FormItem = styled.span`
  &:not(:first-child) {
    margin-left: 0.5em;
  }
`

/**
 * 一括更新成功時のメッセージ
 */
const BatchSuccessMessage = styled.p`
  display: block;
  height: 30px;
  margin-bottom: 2px;
  padding: 0 20px;
  border: 1px solid ${darkBaseColor};
  border-radius: 5px;
  background: ${lightGreen};
  color: ${darkBaseColor};
  line-height: 30px;

  &::before {
    font-family: FontAwesome;
    content: '\f00c';
  }
`

// MIMEタイプのチェックをしないようにしたが、一覧は残しておく。
// const availableMIMETypes = [
//   'text/csv',
//   'text/plain',
//   'text/x-csv',
//   'application/csv',
//   'application/x-csv',
//   'application/vnd.ms-excel',
//   'text/tab-separated-values',
//   'text/comma-separated-values',
//   'text/x-comma-separated-values',
// ]

/**
 * 経理課精算アプリ
 * @type {Object}
 */
export class SettlementHomeApp extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    //  ownProps
    query: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
    // statePtops
    accessToken: PropTypes.string.isRequired,
    env: PropTypes.object.isRequired,
    staffs: PropTypes.arrayOf(
      PropTypes.shape({
        staffId: PropTypes.number.isRequired,
        staffVerboseName: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }

  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    props.replace('?' + qsjoin(props.query))
    const noQuery = Object.keys(props.query).length === 0
    this.state = {
      status: noQuery ? 'not_yet' : 'requesting', // getのネットワークステータス
      batchStatus: 'not_yet', // 一括更新のステータス
      staffIds: [],
      isUploaderOpen: false,
      timerId: false,
      onUnmount: noop,
      refreshStatus: 'not_yet',
      smileWeek: thisWeek(today(), config.startDayOfWeek),
    }
  }

  /**
   * componentDidMount
   * @return {void}
   */
  componentDidMount() {
    this.state.status === 'requesting' && this.request()
  }

  /**
   * componentDidUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {void}
   */
  componentDidUpdate = nextProps =>
    updateRequestHandler(this.props.query, nextProps.query, this.request)

  /**
   * componentWillUnmount
   * @return {void}
   */
  componentWillUnmount() {
    this.state.onUnmount()
    clearTimeout(this.state.timerId)
  }

  request = () => {
    const { accessToken, env, query } = this.props

    const params = {
      from: query.startDate,
      to: moment(query.startDate, 'YYYY-MM-DD')
        .add(7, 'day')
        .format('YYYY-MM-DD'),
    }
    const { promise, cancel } = makeCancelable(
      getSettlementStaffIds(params, accessToken, env),
    )
    this.setState({
      status: 'requesting',
      onUnmount: cancel,
      refreshStatus: 'not_yet',
    })

    fluffy(promise)
      .then(res => {
        if (res.ok) {
          return res.json()
        } else {
          throw new Error(res)
        }
      })
      .then(data => (data || {}).staffs || [])
      .then(staffs => {
        // APIでstaffNameが返されるが、使用せずにマスタからSS名(長い名前)を取得する。
        const staffIds = staffs.map(staff => {
          const staffVerboseName =
            (this.props.staffs.find(x => x.staffId === staff.staffId) || {})
              .staffVerboseName || ''
          return { ...staff, staffVerboseName }
        })
        this.setState({ status: 'success', staffIds })
        this.resetStatus()
      })
      .catch(error => {
        if (!error.isCanceled) {
          console.error(error)
          this.setState({ status: 'failure', staffIds: [] })
          this.resetStatus()
        }
      })
  }

  resetStatus = () =>
    this.setState({
      timerId: setTimeout(() => this.setState({ status: 'not_yet' }), 2000),
    })

  // SMILEデータアップロード画面を開く
  openSmileDataUploader = () =>
    this.setState({
      isUploaderOpen: 'smile',
      refreshStatus: 'not_yet',
      smileWeek: thisWeek(today(), config.startDayOfWeek),
    })

  // 管財商材アップロード画面を開く
  openPipeMaterialUploader = () =>
    this.setState({ isUploaderOpen: 'pipe', refreshStatus: 'not_yet' })

  // アップロード画面を閉じる
  closeUploader = () => this.setState({ isUploaderOpen: false })

  // SMILEアップロードのPromiseを作成して返す
  uploadSmileData = csvText => {
    const { smileWeek } = this.state
    return putSmileData(
      csvText,
      smileWeek,
      this.props.accessToken,
      this.props.env,
    )
  }

  uploadPipeMaterial = csvText =>
    putPipeMaterial(csvText, this.props.accessToken, this.props.env)

  // 清算の行を確定する処理
  onConfirm = (e, staffId) => {
    const settled = e.target.checked
    const { accessToken, env, query } = this.props
    const from = query.startDate
    const to = moment(query.startDate, 'YYYY-MM-DD')
      .add(7, 'day')
      .format('YYYY-MM-DD')
    const { promise } = makeCancelable(
      putSettlement({ from, to, staffId, settled }, accessToken, env),
    )
    fluffy(promise)
      .then(res => {
        if (res.ok) {
          return res.json()
        } else {
          console.error(res)
          throw new Error('ネットワークエラー')
        }
      })
      .then(() => {
        const { staffIds } = this.state
        const staffs = staffIds.map(staff =>
          staff.staffId === staffId ? { ...staff, isSettled: settled } : staff,
        )
        this.setState({ status: 'success', staffIds: staffs })
      })
      .catch(() => this.setState({ status: 'failure' }))
  }

  /**
   * 一括確定・確定解除共通処理
   */
  batchSettlement = settled => {
    // 変更する状態と逆のstaffId一覧を取得
    const { staffIds } = this.state
    // 一括更新のAPIを呼び出す
    const { accessToken, env, query } = this.props
    const from = query.startDate
    const to = moment(query.startDate, 'YYYY-MM-DD')
      .add(7, 'day')
      .format('YYYY-MM-DD')
    this.setState({ batchStatus: 'requesting' })
    const { promise } = makeCancelable(
      settlementAll({ from, to, settled }, accessToken, env),
    )
    fluffy(promise)
      .then(() => {
        // 変更対象になったSSの確定状態を更新する
        const staffs = staffIds.map(staff =>
          staff.hasSettlement ? { ...staff, isSettled: settled } : staff,
        )
        this.setState({ batchStatus: 'success', staffIds: staffs })
        this.resetBatchStatus()
      })
      .catch(err => {
        console.error(err)
        this.setState({ batchStatus: 'failure' })
        this.resetBatchStatus()
      })
  }

  /**
   * 全てを確定する
   */
  setAllSettlement = () => {
    if (confirm('未確定精算の全てを確定します。よろしいですか？')) {
      this.batchSettlement(true)
    }
  }

  /**
   * 全てを確定解除する
   */
  resetAllSettlement = () => {
    if (confirm('確定済み精算の全てを確定解除します。よろしいですか？')) {
      this.batchSettlement(false)
    }
  }

  /**
   * 一括登録結果をリセットしメッセージを消去する
   */
  resetBatchStatus = () =>
    this.setState({
      timerId: setTimeout(
        () => this.setState({ batchStatus: 'not_yet' }),
        config.messageResultDelay,
      ),
    })

  // 週の工務課精算データを更新した後取得します。
  refreshLiquidition = () => {
    const {
      accessToken,
      env,
      query: { startDate },
    } = this.props
    refreshLiquidationWork(startDate, accessToken, env)
      .then(res => {
        if (res.ok) {
          this.setState({ refreshStatus: 'success' })
        } else {
          throw 'GETエラー'
        }
      })
      .catch(() => {
        this.setState({ refreshStatus: 'failure' })
      })
  }

  changeSmileWeek = week => {
    this.setState({ smileWeek: week })
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const {
      status,
      staffIds,
      isUploaderOpen,
      refreshStatus,
      batchStatus,
    } = this.state

    const selectedProps = {
      value: null,
      maxPage: 0,
      onChange: this.props.onChange,
      query: this.props.query,
      replace: this.props.replace,
    }
    const disabled = status === 'requesting' || batchStatus === 'requesting'

    // 一人でも精算済のSSがある時はアップロード等のボタンを無効にする。
    const isSettled = (staffIds || []).reduce(
      (prev, staff) => prev || !!staff.isSettled,
      false,
    )

    return (
      <Container>
        <Header title={ '精算処理' } />
        <FormsWrap alignLeft>
          <FormItem style={ { marginLeft: '100px' } }>
            <WeekSettlement
              { ...selectedProps }
              disabled={ disabled }
              label={ '精算期間' }
            />
          </FormItem>
          <FormItem>
            <Reload
              { ...selectedProps }
              onClick={ this.request }
              disabled={ disabled }
              oneline
            />
          </FormItem>
        </FormsWrap>

        <FormsWrap alignLeft>
          <div style={ { marginLeft: '100px' } }>
            <Button onClick={ this.openSmileDataUploader } disabled={ isSettled }>
              {'SMILEデータアップロード'}
            </Button>
            <Button
              onClick={ this.openPipeMaterialUploader }
              disabled={ isSettled }
            >
              {'管材商材データアップロード'}
            </Button>
            <Button onClick={ this.refreshLiquidition } disabled={ isSettled }>
              {'外注費データ取得'}
            </Button>
          </div>
        </FormsWrap>

        <ContentWrap>
          {status === 'failure' && (
            <ValidationErrorMessage message={ 'サーバーエラー' } />
          )}
          {batchStatus === 'success' && (
            <BatchSuccessMessage>
              {'一括確定・解除が成功しました'}
            </BatchSuccessMessage>
          )}
          {batchStatus === 'failure' && (
            <ValidationErrorMessage
              message={ '一括確定・解除でエラーが発生しました' }
            />
          )}
          <SuccessMessage refreshStatus={ refreshStatus } />
          <FailureMessage refreshStatus={ refreshStatus } />
          <Index
            staffIds={ staffIds }
            from={ this.props.query.startDate }
            to={ moment(this.props.query.startDate, 'YYYY-MM-DD')
              .add(7, 'day')
              .format('YYYY-MM-DD') }
            disabled={ disabled }
            onConfirm={ this.onConfirm }
            setAllSettlement={ this.setAllSettlement }
            resetAllSettlement={ this.resetAllSettlement }
          />
          {status === 'requesting' && (
            <i className={ 'fa fa-spinner fa-pulse fa-fw' } />
          )}
        </ContentWrap>

        {isUploaderOpen === 'smile' && (
          <FileUploader
            title={ '経理データCSVアップロード' }
            upload={ this.uploadSmileData }
            closeMe={ this.closeUploader }
            readerType={ 'text' }
            encode={ 'shift-jis' }
            dispWeek
            changeWeek={ this.changeSmileWeek }
          />
        )}
        {isUploaderOpen === 'pipe' && (
          <FileUploader
            title={ '管材商材CSVアップロード' }
            upload={ this.uploadPipeMaterial }
            closeMe={ this.closeUploader }
            readerType={ 'text' }
            encode={ 'shift-jis' }
          />
        )}

        <Limit { ...selectedProps } />
      </Container>
    )
  }
}

// 成功のメッセージ
const SuccessMessage = props => {
  const { refreshStatus } = props

  return (
    <SnackBar isVisible={ refreshStatus === 'success' } success>
      {'成功しました。'}
    </SnackBar>
  )
}
SuccessMessage.propTypes = {
  refreshStatus: PropTypes.oneOf([
    'success',
    'failure',
    'not_yet',
    'requesting',
  ]).isRequired,
}

// 失敗のメッセージ
const FailureMessage = props => {
  const { refreshStatus } = props

  return (
    <SnackBar isVisible={ refreshStatus === 'failure' } failure>
      {'エラーが発生しました。'}
    </SnackBar>
  )
}
FailureMessage.propTypes = {
  refreshStatus: PropTypes.oneOf([
    'success',
    'failure',
    'not_yet',
    'requesting',
  ]).isRequired,
}

export const SnackBar = styled.p`
  position: fixed;
  bottom: 32px;
  right: ${props => (props.isVisible ? 5 : -400)}px;
  color: ${props => (props.success ? baseColor : props.failure ? red : 'black')};
  background-color: rgba(255, 255, 255, 0.6);
  padding: 0.5em;
  border-radius: 4px;
  font-weight: bold;
  font-size: 1.2em;
  transition-duration: 0.3s;
`

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
export const mapStateToProps = state => {
  return {
    accessToken: state.login.authentication.accessToken,
    env: state.env,
    staffs: state.master.data.staffs,
  }
}

export default connect(mapStateToProps)(SettlementHomeApp)
