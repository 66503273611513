export const filterAll = () => true

/**
 * contact idsを抽出する
 * @param  {object[][]} contacts       [description]
 * @param  {function} filterFunction [description]
 * @return {number[]}                [description]
 */
export const pickStickyContactIds = (
  contacts,
  filterFunction = filterAll,
  options = {},
) => {
  return contacts
    .reduce((prev, contact) => [...prev, ...(contact || [])], []) // flatten
    .filter(x => !!x) // remove null
    .filter(filterFunction)
    .map(x => {
      if (options.pickDeleted && x.deleted) {
        return -1
      } else {
        return x.id
      }
    })
}

export default pickStickyContactIds
