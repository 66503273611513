import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { ACTION_TYPES as LOGIN_ACTION_TYPES } from 'src/reducers/login'
import { LogoutButton } from './styled'

/**
 * map dispatch to props
 * @param  {function} dispatch dispatcher
 * @return {object}               mapped dispatcher as props
 */
const mapDispatchToProps = dispatch => {
  return {
    /**
     * ログアウトする
     * @return {void}
     */
    logout: () => dispatch({ type: LOGIN_ACTION_TYPES.DO_LOGOUT }),
  }
}

/**
 * ログアウトボタンを表示するコンポーネント
 * @param {object} props props
 * @return {ReactComponent}
 */
export const Logout = props => (
  <LogoutButton
    type={ 'button' }
    onClick={ props.logout }
    style={ {
      display: 'grid',
      gridTemplateRows: 'repeat(2, max-content)',
      color: '#ffffff',
    } }
  >
    <i className={ 'fa fa-sign-out' } />
    <span style={ { fontSize: '0.75rem' } }>{' ログアウト'}</span>
  </LogoutButton>
)
/**
 * propTypes
 * @type {object}
 */
Logout.propTypes = {
  // dispatchProps
  logout: PropTypes.func.isRequired,
}

export default connect(void 0, mapDispatchToProps)(Logout)
