import PropTypes from 'prop-types'
import React from 'react'
import ApiInitializer from './api-initializer'
import connect from './props'

/**
 * マスタを取得する。
 * TODO: 他のマスタもここに入れたい。
 * 現状では、取得するのはサービススタッフ一覧のみ
 * アクセストークン取得のタイミングと要検討
 */
export class MasterController extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // stateProps
    accessToken: PropTypes.string.isRequired,
  }

  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    this.state = { ...props }
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return this.props.accessToken !== nextProps.accessToken
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    return this.props.accessToken ? <ApiInitializer /> : false
  }
}

export default connect(MasterController)
