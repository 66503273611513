import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { bgPink, red, lightGreen, darkBaseColor } from 'src/colors'

const P = styled.p`
  display: block;
  height: 30px;
  margin-bottom: 2px;
  padding: 0 20px;
  border: 1px solid $red;
  border-radius: 5px;
  background: ${props => props.background};
  color: ${props => props.color};
  line-height: 30px;

  &::before {
    font-family: FontAwesome;
    content: ${props => props.icon};
  }
`

export const StatusMessage = props => {
  const icon = props.status === 'failure' ? '\'\f071\'' : '\'\f00c\''
  return props.status === 'display_success' ? (
    <P background={ lightGreen } color={ darkBaseColor } icon={ icon }>
      {props.message}
    </P>
  ) : props.status === 'failure' ? (
    <P background={ bgPink } color={ red } icon={ icon }>
      {props.message}
    </P>
  ) : null
}

StatusMessage.propTypes = {
  message: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
}

export default StatusMessage
