export const findStickyById = (wara, stickyId) => {
  for (const regionIndex in wara.regions) {
    const region = wara.regions[regionIndex]
    for (const listIndex in region.lists) {
      const list = region.lists[listIndex]
      for (const stickyIndex in list.stickies) {
        const sticky = list.stickies[stickyIndex]
        if (sticky.id === stickyId) {
          return {
            location: {
              regionIndex: parseInt(regionIndex, 10),
              listIndex: parseInt(listIndex, 10),
              stickyIndex: parseInt(stickyIndex, 10),
            },
            sticky,
          }
        }
      }
    }
  }

  for (const stickyIndex in wara.dock.stickies) {
    const sticky = wara.dock.stickies[stickyIndex]
    if (stickyId === sticky.id) {
      return {
        location: {
          regionIndex: -1,
          listIndex: -1,
          stickyIndex: parseInt(stickyIndex, 10),
        },
        sticky,
      }
    }
  }

  return { location: false }
}

export default findStickyById
