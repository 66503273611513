/**
 * ライブラリ
 */
import React from 'react'
import PropTypes from 'prop-types'
import TabButton from './tab-button'

/**
 * タブを表現するためのコンポーネント
 * NOTE:keyが重複するので1つの画面で1つしか使えない
 * @type {ReactComponent}
 */
export default class Tab extends React.Component {
  /**
   * default
   * @type {object}
   */
  static propTypes = {
    // Possible React children
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    initialIndex: PropTypes.number,
  }

  /**
   * default
   * @type {object}
   */
  static defaultProps = {
    children: [],
    initialIndex: 0,
  }

  /**
   * constructor
   * @param  {object} props props
   * @return {void}
   */
  constructor(props) {
    super(props)
    this.state = {
      showingIndex:
        props.initialIndex === -1
          ? Tab.defaultProps.initialIndex
          : props.initialIndex,
    }
  }

  /**
   * check if the given tab is visible
   * @param  {number}  index examining index
   * @return {boolean}       isVisible
   */
  isVisible = index => index === this.state.showingIndex

  /**
   * タブ選択時に発火するハンドラを作成します
   * @param  {number} index クリックしたタブのindex
   * @return {function}     タブの耳クリックのイベントハンドラ
   */
  createTabSelectHandler = index => () => this.setState({ showingIndex: index })

  /**
   * [onKeyDownAtTabHead description]
   * @param  {Event} e event
   * @return {void}
   */
  onKeyDownAtTabHead = e => {
    if (e.key === 'ArrowRight') {
      const nextHeadId = this.nextHeadId
      nextHeadId && document.getElementById(nextHeadId).focus()
    } else if (e.key === 'ArrowLeft') {
      const prevHeadId = this.prevHeadId
      prevHeadId && document.getElementById(prevHeadId).focus()
    }
  }

  get activeHeadIndex() {
    const value = parseInt(document.activeElement.dataset.index, 10)
    return isNaN(value) ? false : value
  }

  get nextHeadId() {
    const length = Array.isArray(this.props.children)
      ? this.props.children.length
      : 1
    return this.activeHeadIndex < length - 1
      ? `tab-header-${this.activeHeadIndex + 1}`
      : false
  }

  get prevHeadId() {
    return this.activeHeadIndex === 0
      ? false
      : `tab-header-${this.activeHeadIndex - 1}`
  }

  /**
   * render
   * @return {ReactDOM} rendered result
   */
  render() {
    // 子要素が1個の時はchildrenはarray-likeでない
    const children = (Array.isArray(this.props.children)
      ? this.props.children
      : [this.props.children]
    )
      .filter(child => !!child.props['data-tab-header'])
      .map(child => ({
        component: child,
        tabHeader: child.props['data-tab-header'].head,
        tabCount: child.props['data-tab-header'].length,
      }))

    return (
      <div className={ 'tab-wrap' }>
        <div className={ 'tab-button-wrap' }>
          {children.map(({ tabHeader, tabCount }, index) => {
            const activityClassName = this.isVisible(index)
              ? 'tab-head-button-active'
              : 'tab-head-button-inactive'
            return (
              <TabButton
                key={ tabHeader }
                dataIndex={ index }
                tabHeader={ tabHeader }
                tabCount={ tabCount }
                activityClassName={ activityClassName }
                onClick={ this.createTabSelectHandler }
                onKeyDown={ this.onKeyDownAtTabHead }
              />
            )
          })}
        </div>

        <div className={ 'tab-content-wrap' }>
          {children.map(({ component, tabHeader }, index) => (
            <div
              key={ tabHeader }
              className={ `tab-content ${
                this.isVisible(index) ? 'tab-visible' : 'tab-hidden'
              }` }
            >
              {component}
            </div>
          ))}
        </div>
      </div>
    )
  }
}
