import React, { Component } from 'react'
import PropTypes from 'prop-types'
import connect from './props'

// components
import DatePicker from 'react-ja-date-picker'

// libs
import noop from 'src/lib/noop'
import { today } from 'src/lib/moment'
import moment from 'moment'
import { Button, IconButton, TextField } from '@mui/material'
import theme from 'src/theme.js'
import { ThemeProvider } from '@mui/material/styles'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { styled } from '@mui/material/styles'

const CalendarInputWrap = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  position: 'relative',

  '& .MuiTextField-root': {
    width: '11rem',
  },
}))

/**
 * 開閉ボタンつきカレンダーコンポーネント
 * 詳細検索専用にカスタマイズ
 * @type {ReactComponent}
 */
export class WrappedCalendar extends Component {
  /**
   * [isValid description]
   * @param {string} date date
   * @return {boolean} isValid
   */
  static isValid = date => {
    if (!date) {
      return true
    }

    const planeDateString = date.split('-').join('')
    if (planeDateString.length === 4) {
      const month = parseInt(planeDateString.substr(4, 2), 10)
      const day = parseInt(planeDateString.substr(6, 2), 10)
      if (month < 1 || month > 12 || day < 1 || day > 31) {
        return false
      }
      return true
    }

    if (planeDateString.length < 8) {
      return false
    }
    const year = parseInt(planeDateString.substr(0, 4), 10)
    const month = parseInt(planeDateString.substr(4, 2), 10)
    const day = parseInt(planeDateString.substr(6, 2), 10)
    if (year < 1900 || month < 1 || month > 12 || day < 1 || day > 31) {
      return false
    }
    return true
  }

  static normalize = date => {
    const planeDateString = date.split('-').join('')

    if (planeDateString.length === 4) {
      const year = moment(today()).year()
      const month = parseInt(planeDateString.substr(0, 2), 10)
      const day = parseInt(planeDateString.substr(2, 2), 10)
      const m = moment(`${year}-${month}-${day}`)
      return m.isValid() ? m.format('YYYY-MM-DD') : ''
    }

    const year = planeDateString.substr(0, 4)
    const month = planeDateString.substr(4, 2)
    const day = planeDateString.substr(6, 2)
    if (!year || !month || !day) {
      return ''
    }
    const m = moment(`${year}-${month}-${day}`)
    return m.isValid() ? m.format('YYYY-MM-DD') : ''
  }

  /**
   * PropTypes
   * @type {object}
   */
  static propTypes = {
    // storeから空いてるかどうかを取得
    // 他のカレンダーを閉じるのに使う
    isOpen: PropTypes.func.isRequired,
    buttonId: PropTypes.string, // ボタンに振るID値
    value: PropTypes.string,
    onSelect: PropTypes.func,
    closeAll: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    unregister: PropTypes.func.isRequired,
    displayDateLabel: PropTypes.bool,
    disabled: PropTypes.bool,
  }

  /**
   * DefaultProps
   * @type {object}
   */
  static defaultProps = {
    buttonId: '',
    value: '',
    onSelect: noop,
    displayDateLabel: true,
    disabled: false,
  }

  state = { onEditing: false }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.onEditing !== nextState.onEditing ||
      this.props.value !== nextProps.value ||
      this.props.disabled !== nextProps.disabled
    ) {
      return true
    }
    const isOpenNext = nextProps.isOpen(this.id)
    const isOpen = this.props.isOpen(this.id)
    return isOpenNext !== isOpen || nextProps.value !== this.props.value
  }

  /**
   * componentWillUnmount
   * @return {void}
   */
  componentWillUnmount() {
    // このコンポーネントの登録解除
    this.props.unregister(this.id)
  }

  // 一意なIDを生成
  id = Symbol('Wrapped Calendar ID')

  /**
   * [onFocus description]
   * @param  {Event} e evnet
   * @return {void}
   */
  onFocus = () => {
    this.setState({ onEditing: this.props.value })
  }

  /**
   * [onChange description]
   * @param  {Event} e evnet
   * @return {void}
   */
  onChange = e => {
    this.setState({ onEditing: e.target.value })
  }

  /**
   * [onBlur description]
   * @param  {Event} e evnet
   * @return {void}
   */
  onBlur = () => {
    const { onEditing } = this.state
    if (WrappedCalendar.isValid(onEditing)) {
      this.toggleCalendar(false)
      this.props.onSelect(WrappedCalendar.normalize(onEditing))
    }
    this.setState({ onEditing: false })
  }

  onTodayClick = () => {
    this.toggleCalendar(false)
    this.props.onSelect(WrappedCalendar.normalize(today()))
  }

  /**
   * toggleCalendar
   * @param  {boolean|any} open open or close as boolean. with <any>, toggle
   * @return {void}
   */
  toggleCalendar = open => {
    const isOpen = this.props.isOpen(this.id)
    const toggleValue = typeof open === 'boolean' ? open : !isOpen
    // 開ける時は、一旦全部閉める
    if (toggleValue) {
      this.props.closeAll()
    }
    this.props.toggle(this.id, toggleValue)
  }

  /**
   * カレンダーの日付選択時に発火
   * @param  {number} year  year
   * @param  {number} month month
   * @param  {number} day   day
   * @return {void}
   */
  onCalendarSelect = (year, month, day) => {
    this.toggleCalendar(false)

    this.props.onSelect(
      WrappedCalendar.normalize(
        `${year}-${month.toString().padStart(2, '0')}-${day
          .toString()
          .padStart(2, '0')}`,
      ),
    )
  }

  /**
   * render
   * @return {ReactDOM} rendered React DOM
   */
  render() {
    const { onEditing } = this.state

    const {
      value,
      buttonId,
      displayDateLabel,
      disabled,
      isOpen: _isOpen,
    } = this.props

    const isOpen = _isOpen(this.id)

    const displayDate =
      onEditing === false ? WrappedCalendar.normalize(value) : onEditing

    return (
      <ThemeProvider theme={ theme }>
        <CalendarInputWrap>
          <TextField
            onFocus={ this.onFocus }
            onChange={ this.onChange }
            onBlur={ this.onBlur }
            type="text"
            value={ displayDate }
            disabled={ disabled }
            placeholder={ `例: ${today()}` }
            error={ !WrappedCalendar.isValid(displayDate) }
            variant="outlined"
            size="small"
            sx={ {
              background: '#fff',
              display: displayDateLabel ? 'inline-flex' : 'none',
              '& .MuiOutlinedInput-root': {
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'primary.main',
                },
              },
              '& .MuiInputBase-root': { height: '28px' },
              '& .MuiInputBase-input': { height: '20px', padding: '0 14px' },
            } }
          />
          <IconButton
            color="primary"
            id={ buttonId }
            onClick={ this.toggleCalendar }
            disabled={ disabled }
            size="large"
            sx={ {
              position: 'absolute',
              left: '8rem',
              display: displayDateLabel ? 'inline-flex' : 'none',
            } }
          >
            <CalendarMonthIcon />
          </IconButton>
          <Button
            color="primary"
            onClick={ this.onTodayClick }
            disabled={ disabled }
            size="small"
            sx={ {
              minWidth: '3rem',
              padding: '2px 8px',
            } }
            variant="outlined"
          >
            {'今日'}
          </Button>
          {isOpen && (
            <div
              style={ {
                background: '#fff',
                position: 'absolute',
                top: displayDateLabel ? '2.5rem' : 0,
                zIndex: 10000,
              } }
            >
              <DatePicker
                date={ value }
                type={ 'button' }
                onSelect={ this.onCalendarSelect }
              />
            </div>
          )}
        </CalendarInputWrap>
      </ThemeProvider>
    )
  }
}

export default connect(WrappedCalendar)
