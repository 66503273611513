/**
 * Reactライブラリ
 */
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

/**
 * コンポーネント
 */
import ClaimerForms from 'src/components/commons/form-groups/claimer-forms'
import MemoForms from 'src/components/commons/form-groups/memo-forms'
import NormalForms from 'src/components/commons/form-groups/normal-forms'
import PaymentForms from 'src/components/commons/form-groups/payment-forms'
import PrivateForms from 'src/components/commons/form-groups/private-forms'
import button, { buttonFill } from 'src/styled/button'
import ObjectDumper from '../../debuggers/object-dumper'
import StickyModalContentButtons from './partials/buttons'
import PaymentStickyModalContentButtons from './partials/payment-buttons'

/**
 * アクションクリエーター
 */
import { actionCreators as $ } from 'src/reducers/wara'

/**
 * ライブラリ
 */
import { baseColor, red } from 'src/colors'
import config from 'src/config'
import Sticky from 'src/lib/class-sticky'
import { contactNoMerge, getRegionId, setMarucStaff } from 'src/lib/format'
import switz from 'switz'

/**
 map state to props
 * @param  {object} state state
 * @return {object}      mapped states
 */
export const mapStateToProps = state => {
  return {
    staged: state.wara.modal.stage,
    isDebugMode: state.env.DEBUG,
    master: state.master.data,
  }
}

/**
 * map dispatch to props
 * @param  {function} dispatch dispatcher
 * @return {object}            mapped auto bind action creators
 */
export const mapDispatchToProps = dispatch => {
  return {
    /**
     * モーダルを閉じる
     * @return {void}
     */
    closeModal: () => dispatch($.closeModal()),

    /**
     * 編集中の付箋をstickyで置き換える。
     * locationは必要かも。
     * @param  {object} sticky  新しい編集用の付箋
     * @param  {object} staging 差分データ
     * @return {void}            [description]
     */
    updateEditSticky: (sticky, staging) => {
      dispatch($.openModal(null, { location: null, sticky }, staging))
    },
  }
}

const Button = styled.button`
  ${button};
  ${props => (props.maruc ? buttonFill(red) : buttonFill(baseColor))};
  margin: 10px;
  padding: 5px 10px;
`

/**
 * 付箋編集モーダルの中身
 * @type {ReactComponent}
 */
// eslint-disable-next-line react/require-optimization
export class StickyModalContent extends React.Component {
  static propTypes = {
    // ownProps
    type: PropTypes.oneOf([
      'normal',
      'claimer',
      'private',
      'memo',
      'noop',
      'payment',
    ]),
    location: PropTypes.shape({
      regionIndex: PropTypes.number.isRequired,
      listIndex: PropTypes.number.isRequired,
      stickyIndex: PropTypes.number.isRequired,
    }).isRequired,
    // stateProps
    staged: PropTypes.instanceOf(Sticky).isRequired,
    isDebugMode: PropTypes.bool,
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    master: PropTypes.object.isRequired,
    // dispatchProps
    closeModal: PropTypes.func.isRequired,
    updateEditSticky: PropTypes.func.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    type: 'normal',
    commitTarget: false,
    isDebugMode: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      newSticky: false,
      timerId: false,
      putStickyStatus: 'not_yet',
    }
  }

  /**
   * componentWillUnmount
   * @return {void}
   */
  componentWillUnmount() {
    clearTimeout(this.state.timerId)
  }

  /**
   * state.newSticky を変更するためのメソッド
   */
  setNewSticky = newSticky => {
    this.setState({ newSticky })
  }

  /**
   * 付箋の登録結果をセットする
   */
  setPutStickyStatus = status => {
    this.setState({ putStickyStatus: status })
  }

  /**
   * 付箋の登録結果をリセットする
   */
  resetPutStickyStatus = () =>
    this.setState({
      timerId: setTimeout(
        () => this.setState({ putStickyStatus: 'not_yet' }),
        config.messageResultDelay,
      ),
    })

  /**
   * 新規作成ボタンを押した時のハンドラ
   * @return {void}
   */
  onDecideClick = () => {
    const { sticky, master } = this.props
    // 都道府県に合わせた新エリアを設定する。
    const { prefCode } = sticky.findClient()
    const regionId = getRegionId(prefCode, master.prefCodes)
    const nextSticky = sticky.transform({
      pattern: Sticky.TRANSFORM_PATTERN.BEFORE_GENERATE_REPEAT,
      repeatProps: { regionId },
    })
    nextSticky._props.id = null
    this.props.updateEditSticky(new Sticky(nextSticky.json()), new Sticky({}))
    this.setState({ newSticky: true })
  }

  /**
   * 復活ボタンを押した時のハンドラ
   * @return {void}
   */
  onResumeClick = () => {
    const { sticky } = this.props
    const nextSticky = sticky.transform({
      pattern: Sticky.TRANSFORM_PATTERN.BEFORE_GENERATE_RESUME,
      repeatProps: {},
    })
    nextSticky._props.id = null
    this.setState({ newSticky: true })
    this.props.updateEditSticky(new Sticky(nextSticky.json()), new Sticky({}))
  }

  /**
   * 再依頼ボタンを押したときのハンドラ
   */
  onReRequestClick = () => {
    const { sticky } = this.props
    const nextSticky = sticky.transform({
      pattern: Sticky.TRANSFORM_PATTERN.BEFORE_GENERATE_REREQUEST,
    })
    nextSticky._props.id = null
    this.setState({ newSticky: true })
    this.props.updateEditSticky(new Sticky(nextSticky.json()), new Sticky({}))
  }

  /**
   * マルシーボタンを押したときのハンドラ
   */
  onMarucClick = () => {
    const {
      sticky,
      master: { validStaffs },
    } = this.props
    const nextSticky = sticky.transform({
      pattern: Sticky.TRANSFORM_PATTERN.BEFORE_GENERATE_MARUC,
    })
    nextSticky._props.id = null
    setMarucStaff(nextSticky, validStaffs)
    this.setState({ newSticky: true })
    this.props.updateEditSticky(new Sticky(nextSticky.json()), new Sticky({}))
  }

  /**
   * ボタン描画
   * @return {ReactComponent|null} この履歴を..ボタンのコンポーネント、または空
   */
  renderPostButtons = () => {
    const { sticky } = this.props
    // 変更後の付箋の内容をチェックする
    return (
      <div>
        {/* <Button onClick={ this.onDecideClick }>
          <i className={ 'fa fa-plus' } />
          {' この履歴を元に新規注文入力を開始する'}
        </Button> */}
        {/* {(sticky.isCanceled || sticky.isSuspended) && (
          <Button onClick={ this.onResumeClick }>
            <i className={ 'fa fa-plus' } />
            {'注文を復活する'}
          </Button>
        )} */}
        {/*
        <Button onClick={ this.onReRequestClick }>
          <i className={ 'fa fa-plus' } />
          {' この履歴を元に再依頼を開始する'}
        </Button>
        */}
        {/* {sticky.isClaimerOrder || (
          <Button maruc onClick={ this.onMarucClick }>
            <i className={ 'fa fa-plus' } />
            {' この履歴を元にマルシーの新規注文入力を開始する'}
          </Button>
        )} */}
      </div>
    )
  }

  render() {
    const {
      // ownProps
      location,
      type,
      sticky,
      master,
      // stateProps
      isDebugMode,
      // dispatchProps
      closeModal,
    } = this.props
    const { newSticky, putStickyStatus } = this.state

    const Forms = switz(type, s =>
      s
        .case('normal', () => NormalForms)
        .case('claimer', () => ClaimerForms)
        .case('private', () => PrivateForms)
        .case('memo', () => MemoForms)
        .case('noop', () => () => null)
        .case('payment', () => PaymentForms)
        .default(() => NormalForms),
    )

    const staged = this.props.staged || {}
    let edittingSticky = null
    if (sticky) {
      edittingSticky = sticky.merge(staged)
    } else {
      edittingSticky = staged
    }
    contactNoMerge(staged, edittingSticky)

    const background =
      edittingSticky._props.stickyTypeId === config.stickyType.normal
        ? edittingSticky.stickyBackGroundColor()
        : 'white'

    return (
      <div
        className={ 'sticky-modal' }
        id={ sticky.id }
        style={ { padding: '20px', background } }
      >
        <Forms
          sticky={ edittingSticky }
          route={ 'assign' }
          location={ location }
          renderPostContent={ this.renderPostButtons }
          closeModal={ closeModal }
          newSticky={ newSticky }
          master={ master }
        />
        {isDebugMode && (
          <ObjectDumper
            prop={ edittingSticky.json() }
            side={ 'left' }
            title={ 'アサイン課付箋入力値デバッグ' }
            defaultOpen={ false }
          />
        )}
        {isDebugMode && (
          <ObjectDumper
            prop={ staged.json() }
            side={ 'right' }
            title={ '累積差分のデバッグ' }
            defaultOpen={ false }
          />
        )}
        <div
          style={ {
            position: 'fixed',
            top: '150px',
            right: '5%',
            zIndex: '15',
          } }
        >
          {type === 'payment' ? (
            <PaymentStickyModalContentButtons sticky={ edittingSticky } />
          ) : (
            <StickyModalContentButtons
              sticky={ sticky }
              location={ location }
              newSticky={ newSticky }
              setNewSticky={ this.setNewSticky }
              noHistory
              noMessage
              setPutStickyStatus={ this.setPutStickyStatus }
              resetPutStickyStatus={ this.resetPutStickyStatus }
            />
          )}
        </div>
        {type === 'payment' ? (
          <PaymentStickyModalContentButtons sticky={ edittingSticky } />
        ) : (
          <StickyModalContentButtons
            sticky={ sticky }
            location={ location }
            newSticky={ newSticky }
            setNewSticky={ this.setNewSticky }
            setPutStickyStatus={ this.setPutStickyStatus }
            resetPutStickyStatus={ this.resetPutStickyStatus }
          />
        )}
        <button
          className={ 'button button-fixed close-mark' }
          onClick={ closeModal }
        />
        {/* state.putStickyStatus によってポップアップを表示 */}
        {switz(putStickyStatus, t =>
          t
            .case('request', () => (
              <div className={ 'alert alert--normal' } style={ { height: 'auto' } }>
                <div className={ 'alert-icon' }>
                  <i className="fa fa-spinner fa-pulse fa-2x fa-fw" />
                </div>
              </div>
            ))
            .case('success', () => (
              <div className={ 'alert alert--normal' } style={ { height: 'auto' } }>
                <div className={ 'alert-icon' }>
                  <i className="fa fa-2x fa-check-circle" />
                  {'更新に成功しました'}
                </div>
              </div>
            ))
            .case('failure', () => (
              <div className={ 'alert alert--error' } style={ { height: 'auto' } }>
                <div className={ 'alert-icon' }>
                  <i className="fa fa-2x fa-times-circle" />
                  {'更新に失敗しました'}
                </div>
              </div>
            ))
            .default(() => null),
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StickyModalContent)
