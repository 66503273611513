export const obj2arr = _obj => {
  const obj = _obj || {}

  return Object.keys(obj)
    .map(key => parseInt(key, 10))
    .filter(num => !isNaN(num))
    .reduce((prev, key1) => {
      prev[key1] = obj[key1]
      return prev
    }, [])
}

export const obj2arrDeep = _obj => {
  const obj = _obj || {}

  return Object.keys(obj)
    .map(key => parseInt(key, 10))
    .filter(num => !isNaN(num))
    .reduce((prev, key1) => {
      const innerObj = obj[key1]
      prev[key1] = Object.keys(innerObj)
        .map(key => parseInt(key, 10))
        .filter(num => !isNaN(num))
        .reduce((prev, key2) => {
          prev[key2] = obj[key1][key2]
          return prev
        }, [])
      return prev
    }, [])
}
