import PropTypes from 'prop-types'
import React from 'react'
import { baseColor } from 'src/colors'
import Authorize from 'src/components/commons/authorize'
import LoginForm from 'src/components/commons/login-form'
import OrderListLink from 'src/components/commons/order-list-link'
import CommonFooter from 'src/components/layouts/common-footer'
import CommonHeader from 'src/components/layouts/common-header'
import config from 'src/config'
import { Container, Mark, NavLabel, NavLink, NavigationItems } from './styled'

const navProps = [
  {
    role: config.roles.call,
    roleor: false,
    to: '/call',
    icon: 'fa-volume-control-phone',
    label: '案件登録',
  },
  {
    role: config.roles.assign,
    roleor: false,
    to: '/assign',
    icon: 'fa-user-plus',
    label: '案件ダッシュボード',
  },
  {
    role: config.roles.accounting,
    roleor: false,
    to: '/accounting',
    icon: 'fa-yen',
    label: '経理課業務',
  },
  {
    role: config.roles.commodity,
    roleor: false,
    to: '/commodity',
    icon: 'fa-truck',
    label: '商材管理課業務 ',
  },
]

// props.url で与えられたイメージを表示する
const Image = props => (
  <div
    className={ 'mark' }
    style={ {
      background: baseColor,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100px',
      borderRadius: '8px 8px 0 0',
    } }
  >
    <img src={ props.url } width="64px" height="64px" />
  </div>
)
Image.propTypes = {
  url: PropTypes.string.isRequired,
}

export const NavListItem = props => (
  <li>
    <Authorize roles={ props.role } roleOr={ props.roleor }>
      <NavLink to={ props.to }>
        {props.icon ? (
          <Mark className={ 'mark' }>
            <i className={ `fa ${props.icon} fa-3x` } />
          </Mark>
        ) : (
          <Image url={ props.url } />
        )}
        <NavLabel>{props.label}</NavLabel>
      </NavLink>
    </Authorize>
  </li>
)

NavListItem.propTypes = {
  role: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  roleor: PropTypes.bool.isRequired,
  to: PropTypes.string.isRequired,
  icon: PropTypes.string, // アイコン名
  url: PropTypes.any, // イメージのURL: iconの指定がない時に使う
  label: PropTypes.string.isRequired,
}
NavListItem.defaultProps = {
  icon: null,
  url: null,
}

/**
 * ホーム画面のコンポーネントです。
 * @type {Component}
 */
export const HomeView = () => {
  return (
    <div className={ 'homeview__wrapper' }>
      <CommonHeader route={ 'home' } />

      <div className={ 'main-navigation' }>
        <LoginForm />
        {/* ログインしていない時は消える */}

        <Container>
          <div>
            <OrderListLink />
          </div>

          <NavigationItems>
            {navProps.map(navProp => (
              <NavListItem key={ navProp.to } { ...navProp } />
            ))}
          </NavigationItems>
        </Container>
      </div>

      <CommonFooter />
    </div>
  )
}

export default HomeView
