import utils from 'src/lib/api-utils'

export const put = (csvText, accessToken, env) => {
  const url = utils.createEndpoint(env, 'uploadPipeMaterial')

  const request = fetch(url, {
    method: 'PUT',
    headers: { Authorization: `Bearer ${accessToken}` },
    body: JSON.stringify({
      pipeMaterialFile: btoa(unescape(encodeURIComponent(csvText))),
    }),
  })

  return request
}

export default put
