import { connect } from 'react-redux'

export const mapStateToProps = state => ({
  staffs: state.master.data.staffs,
  validStaffs: state.master.data.validStaffs,
  master: state.master.data,
})

/**
 * map state to props
 * @param  {object} stateProps    state props
 * @param  {object} dispatchProps dispatch props
 * @param  {object} ownProps      own props
 * @return {object}               props
 */
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...ownProps,
    ...stateProps,
    // NOTE: dispatch は生の <select /> に渡すと警告が出るので消す
    dispatch: void 0,
  }
}

export default Component =>
  connect(mapStateToProps, void 0, mergeProps)(Component)
