import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import CommonHeader from 'src/components/layouts/common-header'
import CommonFooter from 'src/components/layouts/common-footer'

/**
 * Webpack Style Load
 */
import 'src/styles/components/HomeView.scss'

/**
 map state to props
 * @param  {object} state state
 * @return {object}      mapped state as props
 */
const mapStateToProps = state => ({
  networkStatus: state.networkStatus,
})

/**
 * ホーム画面のコンポーネントです。
 * @type {Component}
 */
export class HomeView extends React.Component {
  /**
   * type check
   * @type {object}
   */
  static propTypes = {
    networkStatus: PropTypes.object.isRequired,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return this.props.networkStatus !== nextProps.networkStatus
  }

  /**
   * render
   * @return {void}
   */
  render() {
    const { networkStatus } = this.props

    return (
      <div className={ 'homeview__wrapper' }>
        <CommonHeader route={ 'network-status' } />

        <h1 className={ 'title' }>{'ネットワークステータス'}</h1>
        <div className={ 'box' }>
          <table>
            <tr>
              <th>{'通信'}</th>
              <th>{'状態'}</th>
            </tr>
            {Object.keys(networkStatus).map(key => {
              const item = networkStatus[key].toString()
              return (
                <tr key={ key }>
                  <td>{key}</td>
                  <td>{item}</td>
                </tr>
              )
            })}
          </table>
        </div>

        <CommonFooter />
      </div>
    )
  }
}

export default connect(mapStateToProps)(HomeView)
