import _noop from 'src/lib/noop'

export const noop = _noop

export const makeCancelable = promise => {
  let hasCanceled = false

  return {
    promise: new Promise((resolve, reject) =>
      promise.then(result =>
        hasCanceled ? reject({ isCanceled: true }) : resolve(result),
      ),
    ),
    cancel() {
      hasCanceled = true
    },
  }
}
