import styled from 'styled-components'
import { baseColor, borderGray, textColorB } from 'src/colors'

export const DetailedSeatchNavItemUL = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #e8e9ec;
  border-radius: 0 0 3px 3px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0 10px 12px;
`

const INFIXED = `
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const FIXED = `
  position: absolute;
  top: 714px;
  left: 134px;
  width: 380px;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #e8e9ec;
  border-radius: 0 0 3px 3px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0 10px 12px;
`

/* stylelint-disable */
export const NavItemUL = styled.ul`
  ${props => (props.fixed ? FIXED : INFIXED)};
  margin: 5px;
`
/* stylelint-enable */

export const NavButton = styled.button`
  border: 1px solid ${borderGray};
  border-radius: 4px;
  min-width: 24px;
  color: ${textColorB};
  line-height: 20px;
  text-align: center;

  &:hover {
    border: 1px solid ${baseColor};
    color: ${baseColor};
  }

  &.active {
    border: 1px solid ${baseColor};
    color: ${baseColor};

    &:hover {
      border: 1px solid ${baseColor};
      color: ${baseColor};
      cursor: default;
    }
  }
`
