import { css } from 'styled-components'
import { baseColor, white, borderDarkGray } from 'src/colors'

export const radio = css`
  margin: 0;
  opacity: 0;
  transform: scale(0.9);
  z-index: 0;

  &:checked {
    + label::before {
      background: ${white};
    }

    + label::after {
      transform: scale(1);
      opacity: 1;
    }
  }

  &:hover,
  &:focus {
    border-color: transparent;
    outline-style: none;
    box-shadow: none;

    + label::before {
      border: 1px solid ${baseColor};
    }
  }

  &:focus {
    + label::before {
      box-shadow: 0 0 7px ${baseColor};
    }
  }

  &[disabled] {
    + label {
      &:hover {
        cursor: default;
      }
    }

    + label::before {
      background: ${borderDarkGray};
    }

    + label::after {
      &:hover {
        cursor: default;
      }
    }
  }
`

export const radioLabel = css`
  display: inline-block;
  position: relative;
  margin-right: 1rem;
  padding: 0.5rem 0.5rem 0.5rem;
  cursor: pointer;

  &:last-child {
    margin-right: 1rem;
  }

  &::before,
  &::after {
    position: absolute;
    border-radius: 100%;
    content: '';
  }

  &::before {
    top: 0.25rem;
    left: -1.5rem;
    width: 1rem;
    height: 1rem;
    border: 1px solid ${borderDarkGray};
    background: ${white};
  }

  &::after {
    top: 0.5625rem;
    left: -1.13125rem;
    width: 0.5rem;
    height: 0.5rem;
    transform: scale(2);
    background: ${baseColor};
    opacity: 0;
  }

  &:hover::before {
    background: ${white};
  }

  [disabled] + & {
    cursor: default;
  }
`
