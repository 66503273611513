import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Typography } from '@mui/material'

const MessageText = styled.span`
  @media (max-width: 1024px) {
    display: none;
  }
`

/**
 map state to props
 * @param  {object} state mapping state
 * @return {object}       mapped state as props
 */
const mapStateToProps = state => {
  return {
    offlineMode: state.login.offlineMode,
    displayName: state.login.authentication.displayName,
  }
}

/**
 * ユーザー名を表示するコンポーネント
 * @param {object} props props
 * @return {ReactComponent}
 */
export class Welcome extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // stateProps
    displayName: PropTypes.string,
    offlineMode: PropTypes.bool.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    displayName: '',
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate() {
    // logoutした時は消えちゃうので更新しない
    return false
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { displayName, offlineMode } = this.props

    if (offlineMode) {
      return (
        <MessageText>
          <Typography>
            <i className={ 'fa fa-plane' } />
            {' オフラインモード'}
          </Typography>
        </MessageText>
      )
    } else {
      const message =
        displayName === '' ? 'ようこそ' : `ようこそ、${displayName}さん`

      return (
        <MessageText>
          <Typography>{message}</Typography>
        </MessageText>
      )
    }
  }
}

export default connect(mapStateToProps)(Welcome)
