import update from 'immutability-helper'

/**
 * タイプ定義
 * @type {string}
 */
const type = 'WARA.ADD_STICKY'

/**
 * exportation
 */
export default {
  /**
   * action type
   * @type {string}
   */
  type,

  /**
   * action creator
   * @param  {object} location [description]
   * @param  {Sticky} sticky    [description]
   * @return {object}          [description]
   */
  creator: (location, sticky) => {
    return {
      type,
      payload: {
        location,
        sticky,
      },
    }
  },

  /**
   * action handler
   * @param  {object}  state   Immutableなstateです。このオブジェクトを変更すべきではありません。
   * @param  {object} action  関数に与える引数です。
   * @return {object}          適切にUpdateされた新しいStateを返却します。
   */
  handler: (state, action) => {
    const {
      location: { regionIndex, listIndex },
      sticky,
    } = action.payload

    return update(state, {
      data: {
        regions: {
          [regionIndex]: {
            lists: {
              [listIndex]: {
                stickies: {
                  $push: [sticky],
                },
              },
            },
          },
        },
      },
    })
  },
}
