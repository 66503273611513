/**
 *  コール課用のreducer
 */

import update from 'immutability-helper'
import switz from 'switz'

/**
 * initial state
 * @type {object}
 */
export const initialCallState = {
  staffs: [],
}

const SET_STAFFS = 'CALL.SET_STAFFS'

export const createActions = {
  set: staffs => ({ type: SET_STAFFS, payload: { staffs } }),
}

export const reducer = (state = initialCallState, action) => {
  const { type, payload } = action

  return switz(type, s =>
    s
      .case(SET_STAFFS, () => {
        // APIでは退職者を含む全スタッフを取得し、IDの重複を除去して有効なスタッフをフィルタする。
        const noDupStaffs = payload.staffs.reduce(
          (prev, current) =>
            prev.staffId !== current.staffId
              ? { staffId: current.staffId, staffs: [...prev.staffs, current] }
              : prev,
          { staffId: null, staffs: [] },
        )
        const validStaffs = noDupStaffs.staffs.filter(staff => staff.isValid)
        return update(state, {
          staffs: { $set: noDupStaffs.staffs },
          validStaffs: { $set: validStaffs },
        })
      })
      .default(() => state),
  )
}

export default reducer
