/**
 * 集金付箋のフォーム
 * @file
 */

import React from 'react'
import PropTypes from 'prop-types'
import Sticky from 'src/lib/class-sticky'
import { connect } from 'react-redux'
import { renderNull } from 'src/lib/noop'

import OrderId from '../custom-controls/order-id'
import Contacts from '../custom-controls/contacts'
import FinishState from '../custom-controls/assign/finish-state'
import Memo from '../custom-controls/memo'
import { KPhotoButton, CloseButton } from './styled'

import noop from 'src/lib/noop'
import config from 'src/config'
import { actionCreators as createLightboxActions } from 'src/reducers/lightbox'

/**
 * 再利用性の低いコンポーネントたち
 */
import PaymentsWrap from '../custom-controls/assign/payments-wrap'

/**
 * 売掛金回収付箋の付箋入力フォームを描画します
 * @class {ReactComponent}
 */
export class PaymentsForms extends React.Component {
  /**
   * PropTypes 型定義
   * @type {object}
   */
  static propTypes = {
    // ownProps
    location: PropTypes.shape({
      regionIndex: PropTypes.number.isRequired,
      listIndex: PropTypes.number.isRequired,
      stickyIndex: PropTypes.number.isRequired,
    }).isRequired,
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    // route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
    update: PropTypes.func,
    route: PropTypes.string.isRequired,
    // stateProps
    accessToken: PropTypes.string.isRequired,
    env: PropTypes.object.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    isAfterPreview: false,
    allowEdit: true,
    update: void 0,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    renderPreContent: renderNull,
    renderPostContent: renderNull,
  }

  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    this.state = {
      showQuotation: false,
      showContract: false,
    }
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.sticky !== nextProps.sticky ||
      this.props.update !== nextProps.update ||
      this.state.showQuotation !== nextState.showQuotation ||
      this.state.showContract !== nextState.showContract
    )
  }

  /**
   * 見積書PDFの一覧表示
   */
  openQuotationLightbox = () => {
    const { sticky, openLightbox } = this.props
    const { orderId } = sticky.json()
    this.setState({
      showQuotation: false,
    })
    openLightbox(sticky.id, orderId, 'QPDF')
  }

  /**
   * 保留時角印付きの見積書PDFの一覧表示
   */
  openKQuotationLightbox = () => {
    const { sticky, openLightbox } = this.props
    const { orderId } = sticky.json()
    this.setState({
      showQuotation: false,
    })
    openLightbox(sticky.id, orderId, 'QKPDF')
  }

  /**
   * 請求書PDFの一覧表示
   */
  openContractLightbox = () => {
    const { sticky, openLightbox } = this.props
    const { orderId } = sticky.json()
    this.setState({
      showContract: false,
    })
    openLightbox(sticky.id, orderId, 'CPDF')
  }

  /**
   * 保留時角印付きの請求書PDFの一覧表示
   */
  openKContractLightbox = () => {
    const { sticky, openLightbox } = this.props
    const { orderId } = sticky.json()
    this.setState({
      showContract: false,
    })
    openLightbox(sticky.id, orderId, 'CKPDF')
  }

  /**
   * 工事完了書PDFの一覧表示
   */
  openWorkCompleteLightbox = () => {
    const { sticky, openLightbox } = this.props
    const { orderId } = sticky.json()
    openLightbox(sticky.id, orderId, 'WPDF')
  }

  /**
   * 領収書PDFの一覧表示
   */
  openReceiptLightbox = () => {
    const { sticky, openLightbox } = this.props
    const { orderId } = sticky.json()
    openLightbox(sticky.id, orderId, 'RPDF')
  }

  /**
   * 見積書表示の処理切り替え
   */
  openQuotation = () => {
    const isAccounting = this.props.roles.includes(config.roles.accounting)
    if (isAccounting) {
      this.setState({ showQuotation: true })
    } else {
      this.openQuotationLightbox()
    }
  }

  closeQuotation = () => {
    this.setState({ showQuotation: false })
  }

  /**
   * 契約書表示の処理切り替え
   */
  openContract = () => {
    const isAccounting = this.props.roles.includes(config.roles.accounting)
    if (isAccounting) {
      this.setState({ showContract: true })
    } else {
      this.openContractLightbox()
    }
  }

  closeContract = () => {
    this.setState({ showContract: false })
  }

  pdfButtons = () => {
    const { showQuotation, showContract } = this.state
    const pdfButtonView = {
      position: 'fixed',
      right: '50px',
      bottom: '100px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    }
    const pdfButtonPanel = {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      position: 'relative',
      width: '240px',
    }
    const buttonView = {
      position: 'absolute',
      bottom: 0,
      right: 0,
      zIndex: 1000,
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '8px',
      boxShadow: 'rbga(0, 0, 0, 0.4) 0px 10px 10px',
      backdropFilter: 'blur(4px)',
      borderRadius: '14px',
    }
    const buttonHeader = {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    }
    const buttonTitle = {
      flex: 1,
      background: 'lightgrey',
      padding: '4px',
      marginBottom: '4px',
      textAlign: 'center',
    }
    const buttonListWrap = {
      width: '220px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    }
    const buttonList = {
      width: '150px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
    }

    return (
      <div style={ pdfButtonView }>
        <div style={ pdfButtonPanel }>
          {showQuotation && (
            <div style={ buttonView }>
              <div style={ buttonHeader }>
                <p style={ buttonTitle }>{'見積書'}</p>
                <CloseButton onClick={ this.closeQuotation }>
                  <i className={ 'fa fa-times' } />
                </CloseButton>
              </div>
              <div style={ buttonListWrap }>
                <div style={ buttonList }>
                  <KPhotoButton onClick={ this.openKQuotationLightbox }>
                    <i className={ 'fa fa-file-pdf-o' } />
                    <p style={ { fontSize: '1.2rem', marginTop: '-0.4rem' } }>
                      {'印有'}
                    </p>
                  </KPhotoButton>
                  <KPhotoButton onClick={ this.openQuotationLightbox }>
                    <i className={ 'fa fa-file-pdf-o' } />
                    <p style={ { fontSize: '1.2rem', marginTop: '-0.4rem' } }>
                      {'印無'}
                    </p>
                  </KPhotoButton>
                </div>
              </div>
            </div>
          )}
          {showContract && (
            <div style={ buttonView }>
              <div style={ buttonHeader }>
                <p style={ buttonTitle }>{'契約書'}</p>
                <CloseButton onClick={ this.closeContract }>
                  <i className={ 'fa fa-times' } />
                </CloseButton>
              </div>
              <div style={ buttonListWrap }>
                <div style={ buttonList }>
                  <KPhotoButton onClick={ this.openKContractLightbox }>
                    <i className={ 'fa fa-file-pdf-o' } />
                    <p style={ { fontSize: '1.2rem', marginTop: '-0.4rem' } }>
                      {'印有'}
                    </p>
                  </KPhotoButton>
                  <KPhotoButton onClick={ this.openContractLightbox }>
                    <i className={ 'fa fa-file-pdf-o' } />
                    <p style={ { fontSize: '1.2rem', marginTop: '-0.4rem' } }>
                      {'印無'}
                    </p>
                  </KPhotoButton>
                </div>
              </div>
            </div>
          )}
          <KPhotoButton onClick={ this.openQuotation }>
            <i className={ 'fa fa-file-pdf-o' } />
            <p style={ { fontSize: '0.75rem' } }>{'見積'}</p>
          </KPhotoButton>
          <KPhotoButton onClick={ this.openContract }>
            <i className={ 'fa fa-file-pdf-o' } />
            <p style={ { fontSize: '0.75rem' } }>{'契約'}</p>
          </KPhotoButton>
          <KPhotoButton onClick={ this.openWorkCompleteLightbox }>
            <i className={ 'fa fa-file-pdf-o' } />
            <p style={ { fontSize: '0.75rem' } }>{'確認'}</p>
          </KPhotoButton>
          <KPhotoButton onClick={ this.openReceiptLightbox }>
            <i className={ 'fa fa-file-pdf-o' } />
            <p style={ { fontSize: '0.75rem' } }>{'領収'}</p>
          </KPhotoButton>
        </div>
      </div>
    )
  }

  /**
   * render
   * @return {ReactDOM} rendered React DOM
   */
  render() {
    const {
      // ownProps
      sticky,
      location,
      // location,
      update,
      route,
    } = this.props

    return (
      <div onClick={ e => e.stopPropagation() }>
        <div className={ 'new-order-input' }>
          <h2 className={ 'form-title' }>{'支払情報'}</h2>

          <OrderId sticky={ sticky } />
          {/* 注文ID */}

          <Contacts
            { ...{
              sticky,
              route,
              disabled: true,
              searchDisabled: true,
              update: noop,
            } }
          />

          {sticky && (
            <div className={ 'assign-input' }>
              <PaymentsWrap
                sticky={ sticky }
                route={ route }
                disabled={ false }
                newContactExists={ false }
                isCollectSticky
                collectStickyProps={ {
                  id: sticky.id,
                } }
              />
              <Memo sticky={ sticky } route={ route } disabled={ false } />
            </div>
          )}

          <FinishState
            { ...{
              sticky,
              route,
              location,
              disabled: false,
            } }
            update={ update }
          />
          {/* 終了状態 */}
          {route !== 'call' && this.pdfButtons()}
        </div>
      </div>
    )
  }
}

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
const mapStateToProps = state => {
  return {
    accessToken: state.login.authentication.accessToken,
    env: state.env,
    roles: state.login.authentication.roles,
  }
}

/**
 * map dispatch to props
 * @param  {function} dispatch dispatcher
 * @param  {object}   ownProps own props
 * @return {object}            dispatch props
 */
const mapDispatchToProps = dispatch => {
  return {
    openLightbox: (stickyId, orderId, apitype) =>
      dispatch(createLightboxActions.open(stickyId, orderId, 'RIGHT', apitype)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsForms)
