import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import noop from 'src/lib/noop'
import { red } from 'src/colors'
import negotiationRecords2CopyText from 'src/lib/negotiation-records-to-copy-text'
import { Button } from '@mui/material'
import { ContentCopy } from '@mui/icons-material'
import theme from 'src/theme.js'
import { ThemeProvider } from '@mui/material/styles'

const Wrap = styled.span`
  margin-left: 5px;
`

const Info = styled.span`
  color: ${red};
  margin-left: 5px;
  font-weight: normal;
`

export class CopyToClipboardButton extends React.PureComponent {
  state = { displayCopied: false }

  /**
   * componentWillUnmount
   * @return {void}
   */
  componentWillUnmount() {
    clearTimeout(this.state.timerId)
  }

  get id() {
    const { negotiationRecords: records } = this.props
    const negotiationId = records.length === 1 ? records[0].id : 'all'
    return `copy-to-clipboard-${this.props.stickyId}-${negotiationId}`
  }

  handleCopy = () => {
    document.getElementById(this.id).select()
    document.execCommand('Copy')
    this.setState({
      displayCopied: true,
      timerId: setTimeout(() => this.setState({ displayCopied: false }), 2000),
    })
  }
  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { displayCopied } = this.state
    const { negotiationRecords: records, label } = this.props

    return (
      <ThemeProvider theme={ theme }>
        <Wrap>
          <textarea
            id={ this.id }
            value={ negotiationRecords2CopyText(records) }
            onChange={ noop }
            style={ { position: 'fixed', left: -99999, top: -99999 } }
          />
          <Button
            color="primary"
            onClick={ this.handleCopy }
            size="small"
            startIcon={ <ContentCopy /> }
            sx={ { color: '#fff' } }
            variant="contained"
          >
            {label}
          </Button>
          {displayCopied && <Info>{'コピーされました'}</Info>}
        </Wrap>
      </ThemeProvider>
    )
  }
}

/**
 * propTypes
 * @type {object}
 */
CopyToClipboardButton.propTypes = {
  stickyId: PropTypes.number.isRequired,
  negotiationRecords: PropTypes.array.isRequired,
  label: PropTypes.string,
}

CopyToClipboardButton.defaultProps = {
  label: 'コピー',
}

export default CopyToClipboardButton
