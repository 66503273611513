import React from 'react'
import styled from 'styled-components'
import { gray } from 'src/colors'

const CloseButtonStyle = styled.button`
  background: transparent;
  border: none;
  position: fixed;
  right: 50px;
  top: 50px;
  font-size: 30px;
  transition-duration: 0.2s;
  color: ${gray};

  &:hover {
    font-size: 35px;
    cursor: pointer;
  }
`

export const CloseButton = props => (
  <CloseButtonStyle { ...props }>
    <i className={ 'fa fa-times' } />
  </CloseButtonStyle>
)

export default CloseButton
