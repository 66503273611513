import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import config from 'src/config'
import CopyToClipboardButton from './copy-to-clipboard-button'
import { Time, Paragraph } from './styled'
import { connect } from 'react-redux'
import { Box, Divider, Paper, TextField } from '@mui/material'
import theme from 'src/theme.js'
import { ThemeProvider } from '@mui/material/styles'

export class Histories extends React.Component {
  /**
   * 交渉履歴をソートするための比較関数。
   * idがあるときはidの降順、ないときは日付の降順
   * 一方のみにidがあるときは、ないほうのidをNumber.MAX_SAFE_INTEGERにし、
   * ない方（追加された）が上に来るようにする。
   */
  static comparator = (a, b) => {
    const ida = a.record.id || Number.MAX_SAFE_INTEGER
    const idb = b.record.id || Number.MAX_SAFE_INTEGER
    if (ida === idb) {
      return moment(b.record.createAt).unix() - moment(a.record.createAt).unix()
    } else {
      return idb - ida
    }
  }

  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    stickyId: PropTypes.number.isRequired,
    stickyOrderTypeId: PropTypes.number.isRequired,
    onLastRecordChange: PropTypes.func.isRequired,
    negotiationRecords: PropTypes.array.isRequired,
    route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
    // stateProps
    master: PropTypes.shape({
      handOver: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        }),
      ),
    }).isRequired,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return this.props.negotiationRecords !== nextProps.negotiationRecords
  }

  marucType = typeId => {
    const {
      stickyOrderTypeId,
      master: { handOver },
    } = this.props
    if (stickyOrderTypeId !== config.orderType.claimer) {
      return ''
    }
    let name = ''
    if (typeId) {
      const handover = handOver.find(ho => ho.id === typeId)
      name = handover ? handover.name : ''
    }
    return `マルシー種別: ${name}`
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const {
      negotiationRecords,
      route,
      onLastRecordChange,
      stickyId,
    } = this.props
    const displayNegotiationRecords = [
      ...(negotiationRecords.map((record, index) => ({ record, index })) || []),
    ]
    displayNegotiationRecords.sort(Histories.comparator)

    const [mutable, ...immutables] = displayNegotiationRecords

    const { createAt } = (mutable || {}).record || {}

    const mutableMarucTypeId =
      ((mutable || {}).record || {}).handOverStatuses === -1
        ? void 0
        : ((mutable || {}).record || {}).handOverStatuses

    const mutableDisplayName = mutable => {
      const record = (mutable || {}).record || {}
      return (
        record.displayName ||
        (record.updateUserId === 'sync' ? '連携プログラム' : '(名前なし)')
      )
    }

    return (
      <ThemeProvider theme={ theme }>
        <dl
          id={ `input-negotiation-record-${route}` }
          className={ 'negotiation-record' }
        >
          {displayNegotiationRecords.length > 0 && <hr />}
          {displayNegotiationRecords.length > 0 && (
            <h3 data-test={ 'negotiation-records-header' }>
              {'交渉履歴'}
              <CopyToClipboardButton
                stickyId={ stickyId }
                negotiationRecords={ displayNegotiationRecords.map(
                  x => x.record,
                ) }
                label={ '全ての交渉履歴をクリップボードにコピー' }
              />
            </h3>
          )}
          <dd className={ 'input-wrap' }>
            {!!mutable && (
              <Paper
                sx={ {
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, max-content) 1fr',
                  alignItems: 'center',
                  columnGap: '0.5rem',
                  background: 'rgba(255, 255, 255, .1)',
                  padding: '0.5rem',
                } }
              >
                <Box
                  sx={ {
                    display: 'grid',
                    gridTemplateRows: 'repeat(3, max-content)',
                    rowGap: '0.5rem',
                  } }
                >
                  <Time value={ createAt } />
                  <p>{'記録者: ' + mutableDisplayName(mutable)}</p>
                  {this.marucType(mutableMarucTypeId)}
                  <CopyToClipboardButton
                    stickyId={ stickyId }
                    negotiationRecords={ [mutable.record] }
                  />
                </Box>
                <Divider orientation="vertical" flexItem />
                <TextField
                  onChange={ e =>
                    onLastRecordChange(
                      e.target.value,
                      mutable.index,
                      mutable.record.id,
                    )
                  }
                  fullWidth
                  minRows={ 3 }
                  multiline
                  size="small"
                  sx={ {
                    background: '#fff',
                    '& .MuiOutlinedInput-root': {
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'primary.main',
                      },
                    },
                  } }
                  value={ mutable.record.freeDescribe || '' }
                />
              </Paper>
            )}
            {(immutables || []).length > 0
              ? (immutables || [])
                .filter(x => !x.deleted)
                .map((immutable, index) => {
                  const { createAt } = immutable.record

                  const immutableMarucHandOverStatusId =
                      immutable.record.handOverStatuses === -1
                        ? void 0
                        : immutable.record.handOverStatuses
                  const displayName =
                      immutable.record.displayName ||
                      (immutable.record.updateUserId === 'sync'
                        ? '連携プログラム'
                        : '(名前なし)')

                  return (
                    <Paper
                      // TODO: パフォーマンスに劣る
                      key={ index }
                      sx={ {
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, max-content) 1fr',
                        alignItems: 'center',
                        columnGap: '0.5rem',
                        background: 'rgba(255, 255, 255, .1)',
                        padding: '0.5rem',
                      } }
                    >
                      <Box
                        htmlFor={ `input-negotiation-record-form-${index}` }
                        sx={ {
                          display: 'grid',
                          gridTemplateRows: 'repeat(3, max-content)',
                          rowGap: '0.5rem',
                        } }
                      >
                        <Time value={ createAt } />
                        <p>{'記録者: ' + displayName}</p>
                        {this.marucType(immutableMarucHandOverStatusId)}
                        <CopyToClipboardButton
                          stickyId={ stickyId }
                          negotiationRecords={ [immutable.record] }
                        />
                      </Box>
                      <Divider orientation="vertical" flexItem />
                      <Box>
                        {(immutable.record.freeDescribe || '')
                          .split('\n')
                          .map((paragraph, index) => (
                            // TODO: パフォーマンスに劣る
                            <Paragraph key={ index }>{paragraph}</Paragraph>
                          ))}
                      </Box>
                    </Paper>
                  )
                })
              : null}
          </dd>
        </dl>
      </ThemeProvider>
    )
  }
}

const mapStateToProps = state => {
  return {
    master: state.master.data,
  }
}

export default connect(mapStateToProps, null)(Histories)
