import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Icons from './icons'
import moment from 'moment'
import Sticky from 'src/lib/class-sticky'
import config from 'src/config'
import { toJPYenText } from 'src/lib/format.js'

// 新規としてカウントしないアイコン
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOn } from '@fortawesome/free-solid-svg-icons'

const mapStateToProps = state => {
  return {
    answerTimes: state.master.data.answerTimes,
    cityCodes: state.master.cityCodes,
  }
}

export class UnFinishedStickyContent extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    // isSplitSource: PropTypes.bool.isRequired,
    // stateProps
    answerTimes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
    cityCodes: PropTypes.object.isRequired,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate() {
    return true
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const {
      // ownProps
      sticky,
      // isSplitSource,
      // stateProps
      answerTimes,
      cityCodes,
    } = this.props

    // 付箋オブジェクトを取得
    const {
      receivedAt,
      handOver,
      buildingType,
      customerTypeId,
      workType,
      workPoint,
      thankYouCall,
      suspendReason,
      finishStateId,
      cancelReasonId,
      cash,
      account,
      afterDateTime,
      determinedDateTime,
      wishDateTimes,
      companyId,
      houseLaboClientMaster,
      houseLaboClient,
      houseLabo,
      esMaster,
      es,
      ignoreCount,
    } = sticky.json()

    const isHouseLabo = companyId === config.company.HL
    const isHomeServe = companyId === config.company.SV

    const hasAfter = !!(afterDateTime && afterDateTime.date)

    // 依頼者コンタクトオブジェクトを取得
    const client = sticky.findClient() || {}

    // 依頼者の市区町村名を取得
    const filtered = (cityCodes[client.prefCode] || []).filter(
      x => x.code === client.cityCode,
    )[0]
    const cityName = filtered && filtered.cityName ? filtered.cityName : ''

    const clientName = client.name ? client.name : '(名前未入力)'

    const respective = client.name ? ' 様' : ''

    const displayClientName = cityName + ' ' + clientName + respective

    const handOverText = (handOver || {}).freeDescribe || ''

    // 受付日時表示を作成
    const receivedAtElem = () => {
      const receivedAtMoment = moment(receivedAt)
      const receivedAtDateStr = receivedAtMoment.isValid()
        ? receivedAtMoment.format('MM-DD')
        : ''
      const receivedAtTimeStr = receivedAtMoment.isValid()
        ? receivedAtMoment.format('HH:mm')
        : ''
      return (
        <span>
          <span>{'受'}</span>
          <span style={ { marginLeft: '2px' } }>{receivedAtDateStr}</span>
          <span style={ { marginLeft: '2px' } }>{receivedAtTimeStr}</span>
        </span>
      )
    }

    // 対応希望日時1
    const wishDateTime = () => {
      // 最初の対応希望日時
      const wishDateTime1 = (wishDateTimes || []).reduce(
        (prev, next) => (prev.displayOrder > next.displayOrder ? next : prev),
        { displayOrder: 999, date: '' },
      )
      // 日時をフォーマット。終了厳守のときは時刻のみ赤文字で表示する。
      const wishDate = moment(wishDateTime1.date)
      const wishDateStr = wishDate.isValid() ? wishDate.format('MM-DD') : ''
      const wishStart = wishDateTime1.startAt || ''
      const wishFinsh = wishDateTime1.finishAt || ''
      const style = wishDateTime1.strict ? { color: 'red' } : {}
      return (
        <span>
          <span>{'希'}</span>
          <span style={ { marginLeft: '2px' } }>{wishDateStr}</span>
          <span
            style={ { ...style, marginLeft: '2px' } }
          >{`${wishStart}〜${wishFinsh}`}</span>
        </span>
      )
    }

    // 確定作業日時
    const determinedDateTimeElem = () => {
      const determinedDate = moment((determinedDateTime || {}).date)
      const determinedDateStr = determinedDate.isValid()
        ? determinedDate.format('MM-DD')
        : ''
      const determiendStartAt = (determinedDateTime || {}).startAt || ''
      const determiendFinishAt = (determinedDateTime || {}).finishAt || ''
      const answerTimeId = (determinedDateTime || {}).answerTimeId
      const strict = !!(determinedDateTime || {}).strict
      const style = strict ? { color: 'red' } : {}
      let answerString = ''
      if (answerTimeId && answerTimeId > 0) {
        answerString =
          (answerTimes.find(x => x.id === answerTimeId) || {}).name || ''
      }
      return (
        <div className={ 'sticky-element' }>
          <span>{determinedDateStr}</span>
          <span
            style={ { ...style, marginLeft: '2px' } }
          >{`${determiendStartAt}〜${determiendFinishAt}`}</span>
          <span style={ { marginLeft: '2px' } }>{answerString}</span>
        </div>
      )
    }

    // ハウスラボクライアント名
    const houseLaboClientName = () => {
      if (isHouseLabo) {
        const clientName = (houseLaboClientMaster || {}).shortName || ''
        return <div className={ 'sticky-element' }>{clientName}</div>
      } else {
        return null
      }
    }

    // ハウスラボ受付番号
    const houseLaboReceiptNumber = () => {
      if (isHouseLabo) {
        const receiptNumber = (houseLaboClient || {}).receiptNumber || ''
        return <div className={ 'sticky-element' }>{receiptNumber}</div>
      } else {
        return null
      }
    }

    // ハウスラボクライアント請求
    const houseLaboClientTotal = () => {
      if (isHouseLabo) {
        const clientTotal = (houseLabo || {}).invoice || 0
        return (
          <div className={ 'sticky-element' }>{`ク請求 ${toJPYenText(
            clientTotal,
          )}`}</div>
        )
      } else {
        return null
      }
    }

    // ES提携クライアント名
    const homeServeClientName = () => {
      if (isHomeServe) {
        const clientName = (esMaster || {}).shortName || ''
        return <div className={ 'sticky-element' }>{clientName}</div>
      } else {
        return null
      }
    }

    // ES提携受付番号
    const homeServeReceiptNumber = () => {
      if (isHomeServe) {
        const receiptNumber = (es || {}).receiptNumber || ''
        return <div className={ 'sticky-element' }>{receiptNumber}</div>
      } else {
        return null
      }
    }

    const result = []

    if (
      ((suspendReason || {}).ids || []).includes(config.suspendReason.suspend1)
    ) {
      result.push('保留')
    }

    if (
      ((suspendReason || {}).ids || []).includes(
        config.suspendReason.afterUndecided,
      )
    ) {
      result.push('アフター未定')
    }
    if (hasAfter) {
      result.push('アフター')
    }

    if (finishStateId === config.finishState.cancel) {
      if (
        cancelReasonId === config.cancelReason.CNCL1 ||
        cancelReasonId === config.cancelReason.CNCL2
      ) {
        result.push('CNCL')
      } else {
        result.push('IC')
      }
    }

    if ((cash || {}).value) {
      result.push(`${toJPYenText((cash || {}).value || 0)}(現)`)
    }

    if ((account || {}).value) {
      result.push(`${toJPYenText((account || {}).value || 0)}(掛)`)
    }

    const thankYouCallDone = (thankYouCall || {}).done ? '済' : '未'

    // const splitMode = (sticky.getUserValue('SplitMode') || 'false') === 'true'

    return (
      <div className={ 'sticky-body sticky-body-unfinished' }>
        <div
          style={ {
            display: 'flex',
            justifyContent: 'space-between',
            paddingRight: 12,
          } }
        >
          <div className={ 'sticky-element sticky-received-at' }>
            {receivedAtElem()}
          </div>
          {ignoreCount && (
            <FontAwesomeIcon icon={ faToggleOn } color={ '#03c5ab' } size="1x" />
          )}
        </div>
        <div className={ 'sticky-element sticky-received-at' }>
          {wishDateTime()}
        </div>
        <div className={ 'sticky-element sticky-address sticky-place' }>
          {displayClientName}
        </div>
        {houseLaboClientName()}
        {homeServeClientName()}
        <div className={ 'sticky-element sticky-memo' }>{handOverText}</div>
        {houseLaboReceiptNumber()}
        {homeServeReceiptNumber()}
        {determinedDateTimeElem()}

        <Icons
          buildingTypeId={
            buildingType && buildingType.ids ? buildingType.ids[0] : -1
          }
          customerTypeId={ customerTypeId }
          workTypeIds={ workType && workType.ids ? workType.ids : [] }
          workPointIds={ workPoint && workPoint.ids ? workPoint.ids : [] }
        />

        {houseLaboClientTotal()}
        {result.length > 0 ? (
          <div className={ 'sticky-element' }>{'結果 ' + result.join(' ')}</div>
        ) : null}
        <div className={ 'sticky-element sticky-thank-you-call' }>
          {thankYouCallDone}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps)(UnFinishedStickyContent)
