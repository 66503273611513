import React from 'react'
import PropTypes from 'prop-types'

import { qsjoin } from 'src/lib/format'

/**
 * [linkHistoryHOC description]
 * @param  {ReactComonent} Form [description]
 * @param  {string} key         [description]
 * @return {ReactComonent}      [description]
 */
export const linkHistoryHOC = (Form, key, defaultValue = '-1') =>
  class LinkedForm extends React.Component {
    /**
     * propTypes
     * @type {object}
     */
    static propTypes = {
      query: PropTypes.object.isRequired,
      replace: PropTypes.func.isRequired,
      onChange: PropTypes.func.isRequired,
      maxPage: PropTypes.number.isRequired,
      disabled: PropTypes.bool,
    }

    static defaultProps = {
      disabled: false,
    }

    /**
     * shouldComponentUpdate
     * @param  {object} nextProps next props
     * @param  {object} nextState next state
     * @return {boolean}          should component update
     */
    shouldComponentUpdate(nextProps) {
      return (
        this.props.query[key] !== nextProps.query[key] ||
        this.props.maxPage !== nextProps.maxPage ||
        this.props.disabled !== nextProps.disabled
      )
    }

    onChange = e => {
      const value = e.target.value
      const nextQuery = { [key]: value === '-1' ? void 0 : value }
      this.props.replace('?' + qsjoin({ ...this.props.query, ...nextQuery }))
      this.props.onChange(nextQuery)
    }

    /**
     * render
     * @return {ReactElement|null|false} render a React element.
     */
    render() {
      const { query } = this.props
      return (
        <Form
          { ...this.props }
          onChange={ this.onChange }
          value={ query[key] || defaultValue }
          query={ void 0 }
          replace={ void 0 }
        />
      )
    }
  }

export default linkHistoryHOC
