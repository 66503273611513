import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import config from 'src/config'
import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material'
import theme from 'src/theme.js'
import { ThemeProvider } from '@mui/material/styles'

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
const mapStateToProps = state => ({
  creditPaymentMethods: state.master.data.creditPaymentMethods,
})

/**
 * 支払い方法コンポーネント
 * @type {ReactComponent}
 */
export class PaymentMethod extends React.PureComponent {
  /**
   * Validation
   * @type {object}
   */
  static propTypes = {
    // ownProps
    value: PropTypes.number.isRequired,
    route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
    update: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    // stateProps
    creditPaymentMethods: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }

  /**
   * onChangeハンドラ
   * @param  {Event} e event
   * @return {void}
   */
  onChange = e => {
    const val = parseInt(e.target.value, 10)
    const paymentMethodId = isNaN(val) ? -1 : val
    this.props.update(paymentMethodId)
  }

  /**
   * render
   * @return {ReactDOM} rendered result
   */
  render() {
    const { value, route, disabled: forceDisabled } = this.props

    const disabled = forceDisabled || route !== 'assign'

    const displayCreditPaymentMethods = [
      { id: -1, name: '(未選択)' },
      ...(this.props.creditPaymentMethods || []),
    ].filter(x => x.id !== config.paymentMethod.bonus)

    return (
      <ThemeProvider theme={ theme }>
        <Box
          sx={ {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, max-content)',
            columnGap: '1.5rem',
            alignItems: 'center',
          } }
        >
          <Typography
            htmlFor={ `cash-select-payment-method-id-${route}` }
            variant="subtitle1"
            sx={ { fontWeight: 'bold' } }
          >
            {'支払回数'}
          </Typography>
          <FormControl
            color="primary"
            fullWidth
            disabled={ disabled }
            size="small"
            sx={ {
              background: '#fff',
              '.MuiOutlinedInput-root': {
                height: '28px',
                alignItems: 'center',
                '.MuiSelect-select': {
                  padding: '0px 14px',
                },
              },
            } }
          >
            <Select
              value={ value }
              id={ `cash-select-payment-method-id-${route}` }
              MenuProps={ { style: { zIndex: 10000 } } } // TODO: 付箋詳細や詳細検索のモーダルで表示するにはこの数値が必要
              name={ `cash-select-payment-method-id-${route}` }
              onChange={ this.onChange }
            >
              {displayCreditPaymentMethods.map(option => (
                <MenuItem key={ option.id } value={ option.id }>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </ThemeProvider>
    )
  }
}

export default connect(mapStateToProps)(PaymentMethod)
