import update from 'immutability-helper'

const initialState = {}

const ADD = 'COMPLAINTS.ADD'
const REMOVE = 'COMPLAINTS.REMOVE'
const UPDATE = 'COMPLAINTS.UPDATE'

export const createActions = {
  add: (stickyId, complaint) => ({
    type: ADD,
    payload: { stickyId, complaint },
  }),
  remove: stickyId => ({
    type: REMOVE,
    payload: { stickyId },
  }),
  update: (stickyId, complaint) => ({
    type: UPDATE,
    payload: { stickyId, complaint },
  }),
}

export const reducer = (state = initialState, action) => {
  const { type } = action

  if (type === ADD) {
    const { stickyId, complaint } = action.payload
    return update(state, {
      [stickyId]: { $set: complaint },
    })
  } else if (type === REMOVE) {
    const { stickyId } = action.payload
    const nextState = { ...state }
    delete nextState[stickyId]
    return nextState
  } else if (type === UPDATE) {
    const { stickyId, complaint } = action.payload
    return update(state, {
      [stickyId]: { $set: { ...state[stickyId], ...complaint } },
    })
  } else {
    return state
  }
}

export default reducer
