import utils from 'src/lib/api-utils'

/**
 * マルシー返金
 * @param {*} stickyId
 * @param {*} accessToken
 * @param {*} env
 */
export const put = (body, accessToken, env) => {
  const endpoint = utils.createEndpoint(env, 'refund')
  return fetch(endpoint, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })
}

export default put
