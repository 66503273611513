import switz from 'switz'
import update from 'immutability-helper'

/**
 * initial state
 * @type {object}
 */
export const initialDnDState = {
  sticky: {
    regionIndex: -1,
    listIndex: -1,
    stickyIndex: -1,
    status: '',
  },
}

/**
 * Action Types
 * @type {string}
 */
export const UPDATE_STICKY = 'DND.UPDATE_STICKY'
export const CLEAR_STICKY = 'DND.CLEAR_STICKY'

/**
 * action bulk exports
 * @type {object}
 */
export const ACTION_TYPES = {
  UPDATE_STICKY,
  CLEAR_STICKY,
}

/**
 * clicker reducer
 * @param  {object} [state=initialDnDState] previous state
 * @param  {object} action                 dispatched action
 * @return {object}                         next state
 */
export default (state = initialDnDState, action) => {
  const { type, payload } = action

  return switz(type, s =>
    s
      .case(UPDATE_STICKY, () => {
        return update(state, {
          sticky: {
            regionIndex: { $set: payload.regionIndex },
            listIndex: { $set: payload.listIndex },
            stickyIndex: { $set: payload.stickyIndex },
            status: { $set: payload.status },
          },
        })
      })
      .case(CLEAR_STICKY, () => {
        return update(state, {
          sticky: {
            regionIndex: { $set: initialDnDState.sticky.regionIndex },
            listIndex: { $set: initialDnDState.sticky.listIndex },
            stickyIndex: { $set: initialDnDState.sticky.stickyIndex },
            status: { $set: initialDnDState.sticky.status },
          },
        })
      })
      .default(() => state),
  )
}
