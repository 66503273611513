import utils from 'src/lib/api-utils'
import { CALL_API } from 'redux-api-middleware'
import { NETWORKER } from 'src/middlewares/network-monitor'

import update from 'immutability-helper'
import switz from 'switz'
import config from 'src/config'
import { ACTION_TYPES as NETWORK_ACTION_TYPES } from 'src/reducers/network-status'
import Sticky from 'src/lib/class-sticky'

export const GET_DOCK_REQUEST = 'GET_DOCK_REQUEST'
export const GET_DOCK_SUCCESS = 'GET_DOCK_SUCCESS'
export const GET_DOCK_FAILURE = 'GET_DOCK_FAILURE'

/**
 * action type name bulk export
 * @type {object}
 */
export const ACTION_TYPES = {
  GET_DOCK_REQUEST,
  GET_DOCK_SUCCESS,
  GET_DOCK_FAILURE,
}

/**
 * [creator description]
 * @param  {number} year        [description]
 * @param  {number} month       [description]
 * @param  {number} day         [description]
 * @param  {string} accessToken [description]
 * @return {object} redux-api-middleware に渡す非同期処理情報を内包したアクション
 */
export const creator = (year, month, day, accessToken, env) => {
  const base = `${utils.createEndpoint(env, 'dockStickies')}`
  const endpoint = `${base}?displayDate=${year}-${month}-${day}`

  return {
    [CALL_API]: {
      endpoint,
      method: 'GET',
      headers: { Authorization: `Bearer ${accessToken}` },
      types: [
        {
          type: GET_DOCK_REQUEST,
          meta: {
            [NETWORKER]: {
              target: 'getDockSticky',
              networkActionType: NETWORK_ACTION_TYPES.REQUEST,
            },
          },
        },
        {
          type: GET_DOCK_SUCCESS,
          meta: {
            year,
            month,
            day,
            [NETWORKER]: {
              target: 'getDockSticky',
              networkActionType: NETWORK_ACTION_TYPES.SUCCESS,
            },
          },
          payload: (action, state, res) =>
            res.json().then(payload => payload || []),
        },
        {
          type: GET_DOCK_FAILURE,
          meta: {
            year,
            month,
            day,
            [NETWORKER]: {
              target: 'getDockSticky',
              networkActionType: NETWORK_ACTION_TYPES.FAILURE,
            },
          },
          payload: (action, state, res) => res.json().then(payload => payload),
        },
      ],
    },
  }
}

/**
 * [AsyncWaraReducers description]
 * @param  {object} state   state
 * @param  {object} action action
 * @return {object}         reducer
 */
export const partialReducer = (state, action) => {
  const { type, payload } = action

  return switz(type, s =>
    s
      .case(GET_DOCK_REQUEST, () => state)
      .case(GET_DOCK_SUCCESS, () => {
        const multipleStickyProps = (payload || []).filter(
          sticky =>
            sticky &&
            ((sticky.stickyTypeId === config.stickyType.normal && // 特殊付箋が未配置として出現するのはおかしいので削る
              sticky.stickyStatusId !== config.stickyStatus.split) || // 分裂付箋が未配置として出現するのはおかしいので削る
              sticky.stickyTypeId === config.stickyType.payment), // 集金付箋は通す
        )
        const stickies = multipleStickyProps.map(
          stickyProps => new Sticky(stickyProps),
        )
        return update(state, {
          data: {
            dock: {
              stickies: { $set: stickies },
            },
          },
        })
      })
      .case(GET_DOCK_FAILURE, () => state)
      .default(() => state),
  )
}
