import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import noop from 'src/lib/noop'
import styled from 'styled-components'
// import utils from 'src/lib/api-utils'
// import config from 'src/config'
// import { actionCreators as $ } from 'src/reducers/wara'
import ValidationErrorMessage from 'src/components/commons/validation-error-message'
import { isToday } from 'src/lib/validate'
import { toJPYenText } from 'src/lib/format'
import Time from './time-and-work-start/partials/time'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`

export const mapStateToProps = state => {
  return {
    isDebugMode: state.env.DEBUG,
    env: state.env,
    accessToken: state.login.authentication.accessToken,
    displayDate: state.displayDate.displayDate,
  }
}

const Input = styled.input`
  border: 1px solid #ceced3;
  border-radius: 3px;
  width: 100%;
  text-align: center;
`

/**
 * map dispatch to props
 * @param  {function} dispatch dispatcher
 * @param  {object}   ownProps own props
 * @return {object}            dispatch props
 */
// const mapDispatchToProps = dispatch => {
//   return {
//     updateStaffFreeDescribe: (location, freeDescribe) =>
//       dispatch($.updateStaffFreeDescribe(location, freeDescribe)),
//   }
// }

/**
 * 付箋のドラッグアンドドロップのターゲットを提供します。
 * @type {Compnent}
 */
export class SSName extends React.PureComponent {
  /**
   * Validation
   * @type {object}
   */
  static propTypes = {
    // location: PropTypes.shape({
    //   regionIndex: PropTypes.number.isRequired,
    //   listIndex: PropTypes.number.isRequired,
    // }).isRequired,
    staffId: PropTypes.number.isRequired,
    className: PropTypes.string,
    name: PropTypes.string,
    freeDescribe: PropTypes.string,
    updateTheDailyInfo: PropTypes.func.isRequired,
    displayDate: PropTypes.string.isRequired,
    ssWeeklySales: PropTypes.object.isRequired, // SSの週次売上
    ssWorkStatus: PropTypes.object.isRequired, // SSの稼働状況
    date: PropTypes.string.isRequired, // (現在)日付
    lastFinishedTime: PropTypes.string.isRequired, // 旧APIによる終了時刻
    startTime: PropTypes.string.isRequired, // 旧APIによる開始時刻
    // stateProps
    isDebugMode: PropTypes.bool,
    canDrop: PropTypes.bool,
    connectDropTarget: PropTypes.func,
    isDragging: PropTypes.bool,
    isOver: PropTypes.bool,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    isDebugMode: false,
    canDrop: false,
    className: '',
    connectDropTarget: noop,
    name: '',
    freeDescribe: '',
    isDragging: false,
    isOver: false,
  }

  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    this.state = { editing: false }
  }

  static lsprefix = 'SS_CITY_NAME_STORE_FOR_'

  onFocus = () =>
    this.setState({
      editing: this.props.freeDescribe,
      error: false,
    })
  onChange = e => this.setState({ editing: e.target.value })

  onBlur = e => {
    const { updateTheDailyInfo, freeDescribe: oldFreeDescribe } = this.props
    const freeDescribe = e.target.value || ''

    if (oldFreeDescribe === freeDescribe) {
      return
    }

    updateTheDailyInfo({ freeDescribe }, { cityName: freeDescribe })
  }

  /**
   * 終了時刻が変更された
   * @param {*} e
   */
  onLastFinishTimeBlur = e => {
    this.props.updateTheDailyInfo({
      date: this.props.date,
      lastFinishedTime: typeof e === 'object' ? e.target.value : e,
    })
  }

  /**
   * 開始時刻が変更された
   * @param {*} e
   */
  onStartTimeBlur = e => {
    this.props.updateTheDailyInfo({
      date: this.props.date,
      startTime: typeof e === 'object' ? e.target.value : e,
    })
  }

  /**
   * render
   * @return {ReactDOM} rendered result
   */
  render() {
    const {
      className,
      name,
      freeDescribe,
      staffId,
      isDebugMode,
      ssWeeklySales,
      ssWorkStatus,
      lastFinishedTime,
      startTime,
    } = this.props
    const { editing, error } = this.state

    // React-DnD module
    const { connectDropTarget } = this.props
    // React-DnD result
    const { isDragging, canDrop, isOver } = this.props

    const nativeClasses = classNames({
      sticky: false,
      'drop-target': true,
      'sticky-empty': false,
      dragging: isDragging,
      droppable: canDrop,
      hover: isOver,
    })

    const disabled = !isToday(this.props.displayDate)

    // SSの週次売上
    const paymentTotal = ssWeeklySales.paymentTotal
      ? toJPYenText(ssWeeklySales.paymentTotal)
      : '-'
    const accountTotal = ssWeeklySales.accountTotal
      ? toJPYenText(ssWeeklySales.accountTotal)
      : '-'
    const total = ssWeeklySales.total ? toJPYenText(ssWeeklySales.total) : '-'
    const rentalRatio = ssWeeklySales.rentalRatio
      ? ssWeeklySales.rentalRatio.toFixed(1)
      : '-'
    const visitCount = ssWeeklySales.visitCount ? ssWeeklySales.visitCount : '-'

    // 週次売上の行：金額を右寄せにする
    const sales = (label, value) => {
      return (
        <tr>
          <td>{label}</td>
          <td style={ { paddingLeft: '4px', textAlign: 'right' } }>{value}</td>
        </tr>
      )
    }

    return connectDropTarget(
      <Container className={ className + ' ' + nativeClasses }>
        <p>{name}</p>
        {isDebugMode ? <p>{'デバッグ: ' + staffId}</p> : null}
        <Input
          value={ editing === false ? freeDescribe : editing }
          onFocus={ this.onFocus }
          onChange={ this.onChange }
          onBlur={ this.onBlur }
          type={ 'text' }
          placeholder={ '(市区町村)' }
          disabled={ disabled }
          style={ disabled ? { background: 'lightgrey' } : {} }
        />
        <table style={ { marginTop: '10px', fontSize: '90%' } }>
          <tbody>
            {sales('現: ', paymentTotal)}
            {sales('掛: ', accountTotal)}
            {sales('計: ', total)}
            {sales('件: ', visitCount)}
            {sales('賃: ', rentalRatio)}
          </tbody>
        </table>
        <div
          style={ {
            width: '130px',
            display: 'flex',
            justifyContent: 'space-between',
          } }
        >
          <Time
            value={ ssWorkStatus.lastFinishedTime || lastFinishedTime || '' }
            label={ '前' }
            disabled={ disabled }
            update={ this.onLastFinishTimeBlur }
            id={ `prev-day-${staffId}` }
            nextId={ `next-day-${staffId}` }
          />
          <Time
            value={ ssWorkStatus.startTime || startTime || '' }
            label={ '開' }
            disabled={ disabled }
            update={ this.onStartTimeBlur }
            id={ `next-day-${staffId}` }
          />
        </div>
        {error ? <ValidationErrorMessage message={ 'エラー' } /> : null}
      </Container>,
    )
  }
}

export default connect(
  mapStateToProps,
  // mapDispatchToProps
)(SSName)
