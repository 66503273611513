import { DropTarget as DnDDropTarget } from 'react-dnd'

const cardTarget = {
  hover: (dropTargetProps, monitor, component) => {
    if (!component) {
      return null
    }
    const dragSourceProps = monitor.getItem()
    const { index: dragIndex } = dragSourceProps
    const { index: hoverIndex } = dropTargetProps

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return
    }
  },

  drop: (dropTargetProps, monitor, component) => {
    if (!component) {
      return null
    }
    const dragSourceProps = monitor.getItem()
    const { index: dragIndex } = dragSourceProps
    const { index: dropIndex } = dropTargetProps

    // Don't replace items with themselves
    if (dragIndex === dropIndex) {
      return
    }
    dropTargetProps.onDrop(dragIndex, dropIndex)
    // console.log({ dragSourceProps, dropTargetProps })
  },
}

export const DropTarget = Component =>
  DnDDropTarget('name-card', cardTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  }))(Component)
