import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { lightGreen, lightBlue } from 'src/colors'
import config from 'src/config'

const detectColor = props =>
  props.companyId === config.company.HL
    ? lightBlue
    : props.companyId === config.company.ES
      ? lightGreen
      : 'white'

export const CompanyNameWrap = styled.p`
  background-color: ${detectColor};
`

export const I = styled.i`
  color: #a0a7a5;
  margin-right: 0.5em;
`

export const CompanyName = props => (
  <CompanyNameWrap { ...props }>
    <I className={ 'fa fa-building' } />
    {props.companyName}
  </CompanyNameWrap>
)

CompanyName.propTypes = {
  companyName: PropTypes.string.isRequired,
  companyId: PropTypes.number.isRequired,
}
