import utils from 'src/lib/api-utils'
import Sticky from 'src/lib/class-sticky'
import config from 'src/config'

/**
 * PUT stickyのアクションクリエーター
 * sticky.staffId === -1 でDockに戻せる
 * @param  {Sticky} sticky      [description]
 * @param  {string} accessToken [description]
 * @param  {object} env         env
 * @return {Promise}
 */
export const requestPutSticky = (sticky, accessToken, env) => {
  const endpoint = `${utils.createEndpoint(env, 'sticky')}`

  const puttingSticky = sticky.transform({
    pattern: Sticky.TRANSFORM_PATTERN.BEFORE_PUT,
    sticky, // TODO: メソッドチェーンなのに、引数の処理がおかしいのでダメダメ
  })

  const body = puttingSticky.toJSONString()

  return fetch(endpoint, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body,
  }).then(res => {
    if (res.ok) {
      return res.json()
    } else if (res.status === config.conflictStatus) {
      throw config.conflictError
    } else {
      throw 'PUTエラー'
    }
  })
}

export default requestPutSticky
