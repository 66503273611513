import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toJPYenText } from 'src/lib/format'
import { Dl, Dd } from 'src/styled/list'
import { PluralTable } from '../../styled'
import moment from 'moment'
import { today } from 'src/lib/moment'
import config from 'src/config'
import styled from 'styled-components'

export const Dt = styled.dt`
  font-weight: bold;
  margin: 0.5em;
`

/**
 * 返金日を MM/DD にフォーマットする
 * @param {*} date
 */
const dateFormat = date =>
  date ? `${date.substr(5, 2)}/${date.substr(8, 2)}` : ''

/**
 * 金額が負の時は赤字で表示する
 * @param {*} value
 */
const toJPYenTextMinus = value =>
  value >= 0 ? (
    toJPYenText(value)
  ) : (
    <span style={ { color: 'red' } }>{toJPYenText(value)}</span>
  )

// 返金
const refundTableSpec = {
  // id: 19,
  // refundValue: 10000,
  // refundHandlingCharge: 210,
  // refundMethodId: 2,
  // paymentMethodId: null,
  // paymentTimes: null,
  // isConfirmed: true,
  // refundDate: '2020-08-19',
  // refundStaffId: -1,
  // updateUserId: 'ope1',
  // updateAt: '2020-08-19T16:39:47.169+09:00',
  // chargedValue: 10210,
  // --- 以下は表示時に作成
  // contactName: '名前',
  // phoneNumber: '090-1234-5678,
  // altStaffName: '代理返金者'
  // refundMethod: '現金'
  // paymentMethod: '-'
  // paymentTimesStr: '-'
  // isConfirmedStr: '-'

  headers: {
    refundDate: '返金日',
    contactName: 'お客様名',
    phoneNumber: '電話番号',
    refundValue: '返金額',
    chargedValue: '明細計上額',
    refundHandlingCharge: '手数料',
    refundMethod: '返金方法',
    paymentMethod: '支払い回数',
    paymentTimesStr: '分割回数',
    altStaffName: '代理返金者',
    isConfirmedStr: '精算済み',
  },
  keyOrders: [
    'refundDate',
    'contactName',
    'phoneNumber',
    'refundValue',
    'chargedValue',
    'refundHandlingCharge',
    'refundMethod',
    'paymentMethod',
    'paymentTimesStr',
    'altStaffName',
    'isConfirmedStr',
  ],
  transform: {
    refundDate: dateFormat,
    refundValue: toJPYenTextMinus,
    chargedValue: toJPYenTextMinus,
    refundHandlingCharge: toJPYenText,
  },
}

/**
 * mapStateToProps
 * @param {*} state
 */
const mapStateToProps = state => {
  return {
    master: state.master.data,
  }
}

/**
 * 現金・売掛金の返金
 */
class Refunds extends React.PureComponent {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    refund: PropTypes.object.isRequired,
    refundMethods: PropTypes.array.isRequired, // 支払い方法マスタ
    // stateProps
    master: PropTypes.object.isRequired,
  }

  /**
   * constructor
   * @param {*} props
   */
  constructor(props) {
    super(props)
  }

  /**
   * 代理返金者
   */
  altStaffName = staffId => {
    const { staffs } = this.props.master
    return staffId && staffId !== -1
      ? (staffs.find(x => x.staffId === staffId) || {}).staffVerboseName ||
          `スタッフ名不明 (id${staffId})`
      : ''
  }

  /**
   * 返金方法
   * @param {*} refundMethodId
   */
  refundMethod = refundMethodId => {
    const { refundMethods } = this.props
    return refundMethodId
      ? (refundMethods.find(method => method.id === refundMethodId) || {}).name
      : '不明'
  }

  /**
   * 返金の支払い方法
   * @param {*} refundMethodId
   * @param {*} paymentMethodId
   * @param {*} paymentTimes
   */
  refundCreditCardPayments = (
    refundMethodId,
    paymentMethodId,
    paymentTimes,
  ) => {
    if (!refundMethodId || refundMethodId < 10 || refundMethodId === 999) {
      return { paymentMethod: '-', paymentTimesStr: '-' }
    }
    const method =
      (
        this.props.master.creditPaymentMethods.find(method => {
          return method.id === paymentMethodId
        }) || {}
      ).name || '(未選択)'
    const times =
      (paymentMethodId || -1) === config.paymentMethodId.installments
        ? `(${paymentTimes || ''}回)`
        : ''
    return { paymentMethod: method, paymentTimesStr: times }
  }

  /**
   * 現金の返金情報
   * @param {*} payments
   */
  payments = payments => {
    return payments.flatMap(payment => {
      const refundOrigin = {
        contactName: payment.origin.contactName,
        phoneNumber: payment.origin.phoneNumber,
      }
      return this.paymentRefund(refundOrigin, payment.refunds)
    })
  }

  /**
   * 現金返金額
   * @param {*} origin
   * @param {*} refunds
   */
  paymentRefund = (origin, refunds) => this.refunds(origin, refunds)

  /**
   * 売掛金の返金情報
   * @param {*} payments
   */
  accounts = accounts => {
    return accounts.flatMap(account => {
      let items = this.accountHistory(account.histories)
      if (account.remain !== null) {
        const remain = this.accountRemain(account)
        items = items.concat(remain)
      }
      return items
    })
  }

  /**
   * 履歴
   */
  accountHistory = histories => {
    return histories.flatMap(history => {
      const accountOrigin = {
        contactName: history.history.name,
        phoneNumber: history.history.phoneNumber,
      }
      return this.historyRefunds(accountOrigin, history.refunds)
    })
  }

  /**
   * 売掛金返金額
   * @param {*} origin
   * @param {*} refunds
   */
  historyRefunds = (origin, refunds) => this.refunds(origin, refunds)

  /**
   * 売掛金減額をrefundと同じ形式のデータとする
   * 複数レコード返すことがあるので結果を配列にする。
   * @param {*} account
   */
  accountRemain = account => {
    const value =
      account.remain.beforeRefund - (account.remain.afterRefund || 0)
    const origin = account.origin
    const histories = account.histories || []
    const remain = account.remain
    // 残金が0の時は未返金とみなし、売掛金減額として登録しない。
    if (value === 0) {
      return []
    }
    // カード情報の表示条件
    const requireRefundMethod =
      origin.accountCollectionMethodId === config.howToRetrieve.creditCard &&
      histories.length === 0 &&
      remain.afterRefund !== 0
    // 残金の支払い情報
    const refundMethod = requireRefundMethod
      ? `売掛金減額(${this.refundMethod(remain.refundMethodId)})`
      : '売掛金減額'
    const refundCreditCardPayments = requireRefundMethod
      ? this.refundCreditCardPayments(
        remain.refundMethodId,
        remain.paymentMethodId,
        remain.paymentTimes,
      )
      : {
        paymentMethod: '-',
        paymentTimesStr: '-',
      }
    // 切り直しの有無で判定
    if ((remain.reCutValue || 0) === 0) {
      // 切り直しなし
      return [
        {
          refundId: remain.id,
          refundMethodId: config.refundMethodId.accountRemain, // 売掛金返金を表すIDとして設定
          refundDate: remain.refundDate,
          refundValue: value,
          chargedValue: remain.chargedAfterValue,
          refundHandlingCharge: null,
          contactName: origin.contactName,
          phoneNumber: origin.phoneNumber,
          altStaffName: '-',
          refundMethod,
          ...refundCreditCardPayments,
          isConfirmedStr: remain.isConfirmed || false ? '✅' : '-',
        },
      ]
    }
    // 切り直しあり：　切り直しの支払い情報
    const recutMethod = requireRefundMethod
      ? `切り直し(${this.refundMethod(remain.refundMethodId)})`
      : '切り直し'
    return [
      {
        refundId: remain.id,
        refundMethodId: config.refundMethodId.none, // 集計対象外IDとして設定
        refundDate: remain.refundDate,
        refundValue: remain.beforeRefund,
        chargedValue: remain.chargedAfterValue,
        refundHandlingCharge: null,
        contactName: origin.contactName,
        phoneNumber: origin.phoneNumber,
        altStaffName: '-',
        refundMethod,
        ...refundCreditCardPayments,
        isConfirmedStr: remain.isConfirmed || false ? '✅' : '-',
      },
      {
        refundId: -remain.id,
        refundMethodId: config.refundMethodId.accountRecut, // 売掛金切り直しを表すIDとして設定
        refundDate: remain.refundDate,
        refundValue: remain.reCutValue,
        chargedValue: remain.ChargedReCutValue,
        beforeRefund: remain.beforeRefund, // 小計の計算のため、元の金額を設定
        chargedAfterValue: remain.chargedAfterValue, // 同上
        refundHandlingCharge: null,
        contactName: origin.contactName,
        phoneNumber: origin.phoneNumber,
        altStaffName: '-',
        refundMethod: recutMethod,
        ...refundCreditCardPayments,
        isConfirmedStr: remain.isConfirmed || false ? '✅' : '-',
      },
    ]
  }

  /**
   * 返金額（現金・売掛金共通）
   * @param {*} origin
   * @param {*} refunds
   */
  refunds = (origin, refunds) => {
    const items = (refunds || []).map(refund => {
      const refundId = refund.id
      const altStaffName = this.altStaffName(refund.refundStaffId)
      const refundMethod = this.refundMethod(refund.refundMethodId)
      const refundCreditCardPayments = this.refundCreditCardPayments(
        refund.refundMethodId,
        refund.paymentMethodId,
        refund.paymentTimes,
      )
      const isConfirmedStr = refund.isConfirmed || false ? '✅' : '-'
      // refundに追加情報をマージした結果を表示データとして使用する。
      return {
        ...refund,
        ...origin,
        refundId,
        altStaffName,
        refundMethod,
        ...refundCreditCardPayments,
        isConfirmedStr,
      }
    })
    return items
  }

  /**
   * 小計を作成する
   * @param {*} items
   */
  subtotal = items => {
    let total = [
      [0, 0],
      [0, 0],
      [0, 0],
    ] // 種類ごとの合計
    items.forEach(item => {
      let row = 0 // 小計の種類を表すインデックス
      if (
        item.refundMethodId === config.refundMethodId.accountRemain ||
        item.refundMethodId === config.refundMethodId.accountRecut
      ) {
        row = 2
      } else if (
        item.refundMethodId === config.refundMethodId.furikomi ||
        item.refundMethodId === config.refundMethodId.kakitome
      ) {
        row = 1
      }
      if (item.refundMethodId === config.refundMethodId.none) {
        // 集計対象外の項目は集計しない（切り直しの元金額など）
      } else if (item.refundMethodId === config.refundMethodId.accountRecut) {
        // 切り直しの時は元の金額＋切り直しの金額を加える。
        total[row][0] += item.beforeRefund + item.refundValue
        total[row][1] += item.chargedAfterValue + item.chargedValue
      } else {
        total[row][0] += item.refundValue
        total[row][1] += item.chargedValue
      }
    })
    const titleTdStyle = {
      padding: '2px 10px',
      fontWeight: 'bold',
    }
    const valueTdStyle = {
      padding: '2px 10px',
      textAlign: 'right',
    }
    return (
      <table>
        <tbody>
          <tr>
            <td style={ titleTdStyle }>{'返金小計'}</td>
            <td style={ titleTdStyle }>{'現金'}</td>
            <td style={ valueTdStyle }>{toJPYenText(total[0][0])}</td>
            <td style={ valueTdStyle }>{toJPYenText(total[0][1])}</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td style={ titleTdStyle }>{'返金依頼書'}</td>
            <td style={ valueTdStyle }>{toJPYenText(total[1][0])}</td>
            <td style={ valueTdStyle }>{toJPYenText(total[1][1])}</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td style={ titleTdStyle }>{'売掛金減額'}</td>
            <td style={ valueTdStyle }>{toJPYenText(total[2][0])}</td>
            <td style={ valueTdStyle }>{toJPYenText(total[2][1])}</td>
          </tr>
        </tbody>
      </table>
    )
  }

  /**
   * 返金を返金日でソートする。
   * @param {*} a
   * @param {*} b
   */
  sortByRefundDate = (a, b) => {
    const thisday = today() // 返金日が未指定の時は本日とする。
    const adate = moment(a.refundDate || thisday).unix()
    const bdate = moment(b.refundDate || thisday).unix()
    return adate - bdate
  }

  /**
   * render()
   */
  render() {
    const { refund } = this.props
    const payments = refund.payments || []
    const accounts = refund.accounts || []

    // 現金と売掛金の返金をひとまとめにする
    let items = []
    items = items.concat(this.payments(payments))
    items = items.concat(this.accounts(accounts))
    // 返金日でソート
    items.sort(this.sortByRefundDate)

    const subtotalStyle = {
      paddingLeft: '120px',
    }
    return (
      <>
        <Dl key={ 0 }>
          <Dt>{'返金'}</Dt>
          <Dd />
        </Dl>
        {items.length === 0 ? null : (
          <Dl key={ 1 }>
            <Dt />
            <Dd style={ { width: '100%' } }>
              <PluralTable
                items={ items }
                tableSpec={ refundTableSpec }
                keyName={ 'refundId' }
              />
            </Dd>
          </Dl>
        )}
        {items.length === 0 ? null : (
          <Dl key={ 2 }>
            <Dt />
            <Dd style={ { width: '100%' } }>
              <div style={ subtotalStyle }>{this.subtotal(items)}</div>
            </Dd>
          </Dl>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps)(Refunds)
