import { connect } from 'react-redux'
import StickyEmptyDropTarget from './drop-target'

// action creators
import { actionCreators as $ } from 'src/reducers/wara'
import { DO_SPLIT } from 'src/reducers/clicker'

// components
import StickyEmpty from './'

/**
 map state to props
 * @param  {object} state event object
 * @return {object}       [description]
 */
const mapStateToProps = state => {
  return {
    wara: state.wara.data,
    networkStatuses: state.networkStatus,
    displayDate: state.displayDate.displayDate,
    accessToken: state.login.authentication.accessToken,
    env: state.env,
    // clicker
    splitSourceLocation: state.clicker.splitter.location,
  }
}

/**
 * map dispatch to props
 * @param  {function} dispatch dispatcher
 * @return {object}            mapped auto bind action creators
 */
const mapDispatchToProps = dispatch => {
  return {
    updateSticky: (sticky, location) =>
      dispatch($.updateSticky(sticky, location)),
    /**
     * UIでの付箋の追加を発火する
     * @param {Sticky} sticky [description]
     * @param {{regionIndex:number, listIndex: number}} location    [description]
     * @return {void}
     */
    addSplitSticky: (location, sticky) =>
      dispatch($.addSticky(location, sticky)),

    // 分裂付箋を分裂モードの間だけ保持しておく
    addToSplitList: location =>
      dispatch({ type: DO_SPLIT, payload: { location } }),
  }
}

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StickyEmpty)
const DropTargetComponent = StickyEmptyDropTarget(ConnectedComponent)

export default DropTargetComponent
