import React from 'react'
import styled from 'styled-components'
import { baseColor } from 'src/colors'

export const ApplyButtonStyle = styled.button`
  background: ${baseColor};
  color: #fff;
  min-width: 120px;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

export const ApplyButton = props => (
  <ApplyButtonStyle { ...props }>{'決定'}</ApplyButtonStyle>
)

export default ApplyButton
