/**
 * 未認証時に子要素隠蔽するためのコンポーネントです
 * セキュアな情報については、このコンポーネントで隠蔽するだけではいけません。
 * このコンポーネントで隠蔽された要素については容易に表示することが可能です。
 * 必ずAPI経由の通信を暗号化してください。
 * @file {Component}
 */

/**
 * ライブラリ
 */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NETWORK_STATUSES } from 'src/reducers/network-status'

/**
 * map state to props
 * @param  {object} state state
 * @param  {object} props given props
 * @return {object}       mapped state as props
 */
const mapStateToProps = (state, props) => {
  // also check if logged in
  const isLoggedIn = state.networkStatus.login === NETWORK_STATUSES.SUCCESS
  let isPermitted = false

  try {
    // accept roles and role props
    const roles = props.roles

    // ユーザが持つ権限のリスト
    const userRoles = new Set(state.login.authentication.roles)

    if (props.condition !== void 0) {
      // 条件判定のための関数で判定する。
      isPermitted = props.condition(userRoles)
    } else if (!roles) {
      isPermitted = true
    } else {
      // 表示に必要な権限リスト
      const propRoles = new Set(typeof roles === 'string' ? [roles] : roles)

      if (props.roleOr) {
        // 必要な権限のうちどれか一つあればいい
        for (const role of userRoles) {
          if (propRoles.has(role)) {
            isPermitted = true
            break
          }
        }
      } else {
        // ユーザが持つ権限が必要な権限に全て含まれるか
        const intersection = new Set()
        for (const role of userRoles) {
          if (propRoles.has(role)) {
            intersection.add(role)
          }
        }
        // ユーザに必要な権限が全てある時表示可能
        isPermitted = intersection.size === propRoles.size
      }
    }
    return {
      visible: isLoggedIn && isPermitted,
    }
  } catch (e) {
    return {
      visible: false,
    }
  }
}

/**
 * Authorizeなものを表示するコンポーネント
 * @type {ReactComponent}
 */
export const Authorize = props => {
  return props.visible ? (
    <span className={ 'is-visible' }>{props.children}</span>
  ) : (
    <span className={ 'is-not-visible' } />
  )
}

/**
 * propTypes
 * @type {object}
 */
Authorize.propTypes = {
  visible: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

/**
 * defaultProps
 * @type {object}
 */
Authorize.defaultProps = {
  children: null,
}

export default connect(mapStateToProps)(Authorize)
