import React from 'react'
import PropTypes from 'prop-types'
import { baseColor } from 'src/colors'

const AddButton = props => {
  const color = props.disabled ? 'gray' : baseColor
  const addButtonStyle = {
    width: '160px',
    height: '15px',
    marginTop: '10px',
    textAlign: 'center',
    borderRadius: '3px',
    backgroundColor: 'transparent',
    border: `1px solid ${color}`,
    padding: '4px 2px',
    color: `${color}`,
    fontWeight: 'bold',
    cursor: 'pointer',
  }
  return (
    <div
      style={ addButtonStyle }
      onClick={ props.disabled ? () => {} : props.onClick }
    >
      {props.label}
    </div>
  )
}

/**
 * propTypes
 */
AddButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired, // ボタンが押された時のハンドラ
}

export default AddButton
