import config from 'src/config'
import moment from 'moment'
import { today } from 'src/lib/moment'

/**
 * 交渉履歴がある時、最新の交渉履歴のみを残す。
 * @param {*} sticky
 */
const restFirstNegotiationRecord = sticky => {
  let lastRecord = null
  let lastUpdate = 0
  const negotiationRecords = sticky._props.negotiationRecords || []
  negotiationRecords.forEach(negotiation => {
    const updateAt = moment(negotiation.updateAt).unix()
    if (updateAt >= lastUpdate) {
      lastUpdate = updateAt
      lastRecord = negotiation
    }
  })
  if (lastRecord !== null) {
    sticky._props.negotiationRecords = [{ ...lastRecord, id: null }]
  }
}

/**
 * ハウスラボ付箋特有の処理をまとめる
 * @param {*} sticky
 */
const hlSticky = sticky => {
  const houseLaboClientMaster = sticky._props.houseLaboClientMaster || {}
  const masterHouseLaboFees = houseLaboClientMaster.houseLaboFees || []
  const masterHouseLaboFeesG = masterHouseLaboFees[2] || []
  const houseLabo = {}
  houseLabo['id'] = null
  houseLabo['invoice'] = 0
  houseLabo['extraFeeTax'] = 0
  const isGran = masterHouseLaboFeesG.length > 0 // TODO: 増えるようならHouseLaboのutilsにまとめる。
  if (isGran) {
    // グランの時、金額の区間以外にチェックをつける。
    sticky._props.houseLabo = {}
    sticky._props.houseLaboWorks = []
    sticky._props.houseLaboExtraFees = []
    const houseLaboFees = []
    masterHouseLaboFees.forEach(masters => {
      masters.forEach(master => {
        if (master.inputType !== 3) {
          houseLaboFees.push({
            feeId: master.feeId,
            isExport: true,
          })
        }
      })
    })
    sticky._props.houseLaboFees = houseLaboFees
    sticky._props.houseLabo = houseLabo
  } else {
    // それ以外のハウスラボの付箋の場合、金額情報その他を削除する。
    sticky._props.houseLabo = houseLabo
    sticky._props.houseLaboWorks = []
    sticky._props.houseLaboFees = []
    sticky._props.houseLaboExtraFees = []
  }
}

/**
 * コール課で新規注文・復活の流し込み実行前に付箋を変形する
 * @param  {Sticky} sticky 付箋インスタンス
 * @param  {object} option オプション
 * @param  {bool} resume 復活フラグ(trueの時復活)
 * @return {Sticky}        変形後の付箋インスタンス
 */
const transformBeforeGenerateRepeat = (
  sticky,
  { regionId } = {},
  resume = false,
) => {
  const { _props, id } = sticky
  // 変換元でリピートのエリアを検索し、エリアを置き換える。
  // 元で指定されない時はエリアは置き換えない。
  if (regionId) {
    _props.regionId = regionId
  }
  const isHouseLabo = _props.companyId === config.company.HL
  const isHomeServe = _props.companyId === config.company.SV
  // 全ての場合にcontactsの中のIDと支払情報をクリアする。removeでは削除できない。
  _props?.contacts.forEach(idContact => {
    idContact.forEach(contact => {
      contact.id = null
      contact.stickyId = null
      contact.paymentInfo = null
    })
  })
  // ハウスラボの時のみの処理を行う。
  isHouseLabo && hlSticky(sticky)
  // ES提携（ホームサーブ）の場合、金額情報その他を削除する。
  if (isHomeServe) {
    Object.assign(_props, {
      es: {},
      esFees: [],
      esPartsFees: [],
      esWorks: [],
      esWorkTimes: [],
    })
  }
  // CNCL復活または保留復活のときshowSchedule(appの当日予定に表示)はtrue　req2490
  const isCNCL = [
    config.cancelReason.CNCL1,
    config.cancelReason.CNCL2,
  ].includes(_props.cancelReasonId)
  _props.showSchedule =
    (_props.cancelReasonId === null && resume) || (resume && isCNCL)
  // キャンセル復活でも保留復活でもないとき
  if (!(sticky.isCanceled || sticky.isSuspended)) {
    // parent_id は nullにする
    Object.assign(_props, { parentId: null, orderId: null })
  } else if (resume) {
    // キャンセル復活または保留復活の際にparentIdを設定、orderIdを引き継ぐ
    Object.assign(_props, {
      parentId: id,
      userField: JSON.stringify({ resume: id }),
    })
  } else {
    Object.assign(_props, { parentId: null, orderId: null })
  }
  // 新規注文でリピになる時は交渉履歴の最初のレコードだけを残す
  if (!resume) {
    if (
      !(sticky.isCanceled && _props.orderTypeId === config.orderType.new) ||
      _props.cancelReasonId === config.cancelReason.CNCL1 ||
      _props.cancelReasonId === config.cancelReason.CNCL2
    ) {
      restFirstNegotiationRecord(sticky)
    }

    // 注文タイプの再設定
    // 付箋状態　　|キャンセル　　　　　　　　|キャンセルでない
    // 注文タイプ　|新規　リピ　マルシー　　　|新規　リピ　マルシー
    // ----------+----------------------+-----------------
    // 新規注文　　|新規　リピ　[リピ]　　　　|[リピ]　リピ　[リピ]
    // 復活　　　　|新規　リピ　マルシー　　　|ー　　ー　　ー
    // 新規作成の場合は上記の表からAPI呼び出しに変更。API呼び出しのため、注文タイプ「未定」を設定する。
    // 復活の時は元の付箋の注文タイプを変更しない。
    Object.assign(_props, { orderTypeId: config.orderType.undefined })
    // 復活のとき作業箇所・現況、再依頼の内容を引き継ぐ。
    Object.assign(_props, {
      workType: undefined,
      workPoint: undefined,
      // Req1357: キャンセル復活・保留復活で再依頼を引き継ぐ
      reRequest: undefined, // 再依頼
      reRequestUser: undefined, // 更新者
      reRequestTime: undefined, // 更新日時
      adNumber: undefined, // 広告番号
      mediumId: undefined, // 受電
    })
  }
  // Req963: 保留復活の時は以下の項目を引き継ぐ。
  // ※条件を整理したいが、五月雨式に変更が出てきたため、全然整理できない。
  if (!(resume && sticky.isSuspended)) {
    Object.assign(_props, {
      estimateNumber: undefined, // 見積もりNo
      estimatePayment: undefined, // 見積もり金額
      workContent: undefined, // 作業内容
    })
  }
  Object.assign(_props, { wishDateTimes: [{ date: today(), displayOrder: 0 }] })
  // 受注不可、受注注意のチェックボックスの状態を残す
  const handOverIds = [...((_props.handOver || {}).ids || [])]
  Object.assign(_props, { handOver: { ids: handOverIds } })
  // 付箋から不要な情報を削除して返す
  return sticky.remove({
    // 流し込みに当たって、いらないデータを消しておく
    determinedDateTime: true,
    // アサイン課の領分
    account: true,
    afterDateTime: true,
    cancelReasonId: true,
    cash: true,
    childId: true,
    completedAt: true,
    createAt: true,
    createUserId: true,
    creditCardId: true,
    creditPaymentMethodId: true,
    displayOrder: true,
    displayDate: true,
    finishState: true,
    finishStateId: true,
    freeDialId: true,
    receivedAt: true,
    staffId: !(resume && (sticky.isCanceled || sticky.isSuspended)),
    stickyStatusId: true, // これは消しても次のステップで0になる
    suspendReason: true,
    thankYouCall: true,
    updateAt: true,
    updateUserId: true,
    userField: !(
      (_props.cancelReasonId === null && resume) ||
      (resume && isCNCL)
    ),
    beforeWorkReport: true,
    makerMaintenance: true,
    twoPersonParentId: true,
  })
}

export default transformBeforeGenerateRepeat
