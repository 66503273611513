import utils from 'src/lib/api-utils'

/**
 * 指定期間のスタッフ全員の精算確定
 * @param {*} param0
 * @param {*} accessToken
 * @param {*} env
 */
export const settlementAll = ({ from, to, settled }, accessToken, env) => {
  const endpoint = `${utils.createEndpoint(env, 'settlementAll')}`

  const request = fetch(endpoint, {
    method: 'PUT',
    headers: { Authorization: `Bearer ${accessToken}` },
    body: JSON.stringify({
      billingStart: from,
      billingEnd: to,
      settled,
    }),
  })
  return request
}

export default settlementAll
