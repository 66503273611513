/**
 * React related
 */
import React from 'react'
import { Provider } from 'react-redux'

/**
 * Components
 */
import { Route, Router } from 'react-router-dom'
import LoadingScreen from 'src/components/commons/loading-screen'
import HomeView from './routes/home-view'
// import StaticsView       from './routes/statics-view'
import Lightbox from 'src/components/commons/lightbox'
import APIDebugger from 'src/components/debuggers/api'
import StateInspector from 'src/components/debuggers/state-inspector'
import CommodityView from 'src/components/routes/commodity-view.jsx'
import EngineeringView from 'src/components/routes/engineering-view.jsx'
import MasterController from 'src/components/system/master-controller'
import RewardView from './divisions/accounting/reward'
import {
  SettlementDetailView,
  SettlementHomeView,
  SettlementManageView,
} from './divisions/accounting/settlement'
import AccountingView from './routes/accounting-view'
import CallView from './routes/call-view'
import ConfigView from './routes/config-view'
import DebugView from './routes/debug-view'
import NetworkStatusView from './routes/network-status-view'

import store, { history } from '../store'
import Wara from './divisions/assign/wara'
import AssignView from './routes/assign-view'
import OrderListView from './routes/order-list-view'
// devel
import { hot } from 'react-hot-loader'

/**
 * Webpack Style Load
 */
import { DragDropContext } from 'react-dnd'
import getBackendContext from 'src/lib/get-backend-context'
import 'src/styles/components/HomeView.scss'

const enableDebugModeInitialy = __ENV__.DEBUG

const routes = [
  { path: '/', component: HomeView, exact: true },
  // { path: '/statics',  component: StaticsView, exact: true },
  { path: '/order-list', component: OrderListView, exact: true },
  // { path: '/visit-rate', component: VisitRateView, exact: true },
  // { path: '/work-master', component: WorkMasterView, exact: true },
  // { path: '/qrstaffs', component: QrStaffsView, exact: true },
  { path: '/call', component: CallView, exact: true },
  { path: '/call/:search', component: CallView, exact: true },
  { path: '/assign', component: Wara, exact: true },
  // { path: '/manage', component: ManageView, exact: true },
  { path: '/assign/wara/:year/:month/:day', component: Wara, exact: false },
  // { path: '/consultation', component: ConsultationView, exact: true },
  // {
  //   path: '/consultation/maruc-list',
  //   component: MarucListHomeView,
  //   exact: true,
  // },
  // {
  //   path: '/consultation/maruc-list/:stickyId',
  //   component: MarucListDetailView,
  //   exact: true,
  // },
  // {
  //   path: '/consultation/maruc-stat',
  //   component: MarucStatView,
  //   exact: true,
  // },
  // {
  //   path: '/consultation/paper-stat',
  //   component: PaperStatView,
  //   exact: true,
  // },
  // {
  //   path: '/consultation/sales-adujstment-registration',
  //   component: SalesAdjustmentRegistrationHomeView,
  //   exact: true,
  // },
  // {
  //   path: '/consultation/sales-adujstment-registration/:stickyId/:staffId',
  //   component: SalesAdjustmentRegistrationDetailView,
  //   exact: true,
  // },
  // {
  //   path: '/consultation/sales-adjustment-approvement',
  //   component: SalesAdjustmentApprovementView,
  //   exact: true,
  // },
  { path: '/accounting', component: AccountingView, exact: true },
  // { path: '/accounting/check', component: CheckView, exact: true },
  // {
  //   path: '/accounting/receivables',
  //   component: AccountsReceivableHomeView,
  //   exact: true,
  // },
  // {
  //   path: '/accounting/receivables/:accountId',
  //   component: AccountsReceivableDetailView,
  //   exact: true,
  // },
  // {
  //   path: '/accounting/invoice',
  //   component: AccountsInvoiceView,
  //   exact: true,
  // },
  // {
  //   path: '/accounting/ss-self-payment',
  //   component: SSSelfPaymentView,
  //   exact: true,
  // },
  {
    path: '/accounting/settlement',
    component: SettlementHomeView,
    exact: true,
  },
  {
    path: '/accounting/settlement/:staffId/:billingPeriod',
    component: SettlementDetailView,
    exact: true,
  },
  {
    path: '/accounting/settlement/manage/:staffId/:billingPeriod',
    component: SettlementManageView,
    exact: true,
  },
  // { path: '/accounting/card', component: CardView, exact: true },
  // { path: '/accounting/ranking', component: RankingView, exact: true },
  // { path: '/accounting/payment-csv', component: PaymentCSVView, exact: true },
  {
    path: '/accounting/reward',
    component: RewardView,
    exact: true,
  },
  // {
  //   path: '/accounting/attendance',
  //   component: AttendanceView,
  //   exact: true,
  // },
  { path: '/networkstatus', component: NetworkStatusView, exact: true },
  { path: '/config', component: ConfigView, exact: true },
  enableDebugModeInitialy && {
    path: '/api',
    component: APIDebugger,
    exact: true,
  },
  { path: '/aiot-debug', component: DebugView, exact: true },
  // { path: '/houselabo', component: HouseLaboView, exact: true },
  // { path: '/houselabo/invoice', component: HouseLaboInvoiceView, exact: true },
  // {
  //   path: '/houselabo/client-master',
  //   component: HouseLaboClientMasterView,
  //   exact: true,
  // },
  // {
  //   path: '/houselabo/reward',
  //   component: HouseLaboRewardView,
  //   exact: true,
  // },
  // {
  //   path: '/houselabo/receivables',
  //   component: HouselaboReceivableHomeView,
  //   exact: true,
  // },
  // {
  //   path: '/houselabo/receivables/:stickyId',
  //   component: HouselaboReceivableDetailView,
  //   exact: true,
  // },
  {
    path: '/commodity',
    component: CommodityView,
    exact: true,
  },
  {
    path: '/engineering',
    component: EngineeringView,
    exact: true,
  },
  // {
  //   path: '/general-affairs',
  //   component: GeneralAffairsView,
  //   exact: true,
  // },
  // { path: '/ec', component: ECView, exact: true },
  // { path: '/ec-business/check', component: ECCheckView, exact: true },
  // { path: '/ec-business/reward', component: ECRewardView, exact: true },
  // { path: '/ec-business/order-list', component: ECOrderListView, exact: true },
].filter(x => !!x)

/**
 * アプリケーションにredux storeをProvideするコンポーネントです。
 * 以下の機能を持ちます
 * - Reduxストアとコンポーネントの接続
 * - ルーティングの提供
 * - 開発環境で HMR を提供
 * @return {ReactComponent} React component
 */
const AppContainer = () => {
  return (
    <Provider store={ store }>
      <LoadingScreen>
        <MasterController />
        <Router history={ history }>
          <div>
            {routes.map(route => (
              <Route key={ route.path } { ...route } />
            ))}
          </div>
        </Router>
        <Lightbox />
        <StateInspector />
      </LoadingScreen>
    </Provider>
  )
}

/**
 * export
 */
const hotAppContainer = hot(module)(AppContainer)
// Drag&Dropのバックエンド
const backend = getBackendContext(navigator.userAgent)
// コンテキストとする
export default DragDropContext(backend)(hotAppContainer)
