import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import config from 'src/config'
import Sticky from 'src/lib/class-sticky'
import { baseColor } from 'src/colors'

// actionCreator
import { actionCreators as $ } from 'src/reducers/wara'
import { createAsyncPostStickyAction } from 'src/reducers/wara/async'

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Button = styled.button`
  background: transparent;
  border: 1px solid ${baseColor};
  color: baseColor;
  border-radius: 4px;
  margin: 1em;
  height: 20px;

  &:hover {
    cursor: pointer;
  }
`

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
const mapStateToProps = state => {
  return {
    __accessToken: state.login.authentication.accessToken,
    __env: state.env,
    displayDate: state.displayDate.displayDate,
  }
}

/**
 * map dispatch to props
 * @param  {function} dispatch dispatcher
 * @param  {object}   ownProps own props
 * @return {object}            dispatch props
 */
const mapDispatchToProps = dispatch => {
  return {
    __addSticky: (accessToken, env) => (sticky, locationDest) => {
      dispatch($.addSticky(locationDest, sticky))

      dispatch(
        createAsyncPostStickyAction(
          sticky, // 特殊付箋のbody
          accessToken,
          {
            // locationSrc           : false, // 特殊付箋を作成するときは、Srcは存在しないので不要。フックは呼ぶ必要はない
            locationDest,
            postStickyRequestType: config.postStickyRequestTypes.SPECIAL,
          },
          env,
        ),
      )
    },
  }
}

/**
 * map state to props
 * @param  {object} stateProps    state props
 * @param  {object} dispatchProps dispatch props
 * @param  {object} ownProps      own props
 * @return {object}               props
 */
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { __accessToken: accessToken, __env: env } = stateProps
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    // merge
    addSticky: dispatchProps.__addSticky(accessToken, env),
    // private
    __accessToken: void 0,
    __env: void 0,
  }
}

export class AddNoopButton extends React.PureComponent {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    locationDest: PropTypes.shape({
      regionIndex: PropTypes.number.isRequired,
      listIndex: PropTypes.number.isRequired,
      stickyIndex: PropTypes.number.isRequired, // stickies.length
    }).isRequired,
    staffId: PropTypes.number.isRequired,
    regionId: PropTypes.number.isRequired,
    // stateProps
    displayDate: PropTypes.string.isRequired,
    // dispatchProps
    addSticky: PropTypes.func.isRequired,
  }

  onClick = () => {
    const {
      locationDest,
      staffId,
      regionId,
      // stateProps
      displayDate,
    } = this.props

    const sticky = new Sticky({}).update({
      companyId: config.company.ES,
      contacts: [
        [
          {
            isClient: true,
          },
        ],
      ],
      wishDateTimes: [{ date: displayDate, displayOrder: 0 }],
      mediumId: 999,
      orderTypeId: 0,
      stickyTypeId: 5, // type of noop sticky
      regionId,
      staffId,
      displayDate,
      displayOrder: locationDest.stickyIndex,
    })

    this.props.addSticky(sticky, locationDest)
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    return (
      <Flex>
        <Button { ...this.props } onClick={ this.onClick }>
          {'+'}
        </Button>
      </Flex>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(AddNoopButton)
