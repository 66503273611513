import React from 'react'
import PropTypes from 'prop-types'
import noop from 'src/lib/noop'
import TimeAutocomplete from './time-autocomplete'
import { Box, Typography } from '@mui/material'

/**
 * 2つの時間選択コンボボックスを包んだもの
 * Time型の値の正規化を行い、DateTime型にする。
 * 採用するdateは、propsで与えられていればそれを、与えられていない時は今日の日付を使う
 * startAt、finishAtの値はDateTime型で、
 * dateが与えられている時は
 * イベントハンドラにおいて日付部分のみ与えられたDate型に上書きされる。
 * 日付の境界は27:00である
 * prevId と nextId はコンボボックスをタブ選択するための仕組みで、
 * 前後のタブオーダーにしたいコンポーネントのidを渡してほしい
 * @class {ReactComponent}
 */
export default class TimeRange extends React.Component {
  /**
   * PropTypes
   * @type {object}
   */
  static propTypes = {
    startAt: PropTypes.string,
    finishAt: PropTypes.string,
    onStartAtChange: PropTypes.func,
    onFinishAtChange: PropTypes.func,
    startAtId: PropTypes.string.isRequired,
    finishAtId: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    prevId: PropTypes.string.isRequired,
    nextId: PropTypes.string.isRequired,
    freeInput: PropTypes.bool, // 自由に時刻を入力する
  }

  /**
   * DefaultProps
   * @type {object}
   */
  static defaultProps = {
    date: '',
    startAt: '',
    finishAt: '',
    onStartAtChange: noop,
    onFinishAtChange: noop,
    disabled: false,
    freeInput: false,
  }

  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    this._onStartAtValidationSuccess = this._onStartAtValidationSuccess.bind(
      this,
    )
    this._onFinishAtValidationSuccess = this._onFinishAtValidationSuccess.bind(
      this,
    )
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return (
      this.props.startAt !== nextProps.startAt ||
      this.props.finishAt !== nextProps.finishAt ||
      this.props.disabled !== nextProps.disabled
    )
  }

  /**
   * startAtプロパティのバリデーションが成功した時にコールバックを呼ぶ
   * @param  {string} timestr HH:mm
   * @return {void}
   */
  _onStartAtValidationSuccess(timestr) {
    this.props.onStartAtChange(timestr)
  }

  /**
   * finishAtプロパティのバリデーションが成功した時にコールバックを呼ぶ
   * @param  {string} timestr HH:mm
   * @return {void}
   */
  _onFinishAtValidationSuccess(timestr) {
    this.props.onFinishAtChange(timestr)
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const {
      startAt,
      finishAt,
      startAtId,
      finishAtId,
      disabled,
      prevId,
      nextId,
      freeInput,
    } = this.props

    return (
      <Box
        sx={ {
          display: 'grid',
          gridTemplateColumns: 'repeat(3, max-content)',
          alignItems: 'center',
          columnGap: '0.5rem',
        } }
      >
        <TimeAutocomplete
          inputId={ startAtId }
          value={ startAt } // 'HH:mm'
          onValidateSuccess={ this._onStartAtValidationSuccess }
          disabled={ disabled }
          prevId={ prevId }
          nextId={ finishAtId }
          freeInput={ freeInput }
        />
        <Typography>{'〜'}</Typography>
        <TimeAutocomplete
          inputId={ finishAtId }
          value={ finishAt } // 'HH:mm'
          onValidateSuccess={ this._onFinishAtValidationSuccess }
          disabled={ disabled }
          prevId={ startAtId }
          nextId={ nextId }
          freeInput={ freeInput }
        />
      </Box>
    )
  }
}
