import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { master as searchMaster } from 'src/lib/search'
import { sortSS } from 'src/lib/sort-ss'
import styled from 'styled-components'
import requestPutRegionInfo from '../../../../lib/region-Info-api/put'
import ListIcon from '../list-icon/container'
import List from '../list/container'
import SortButton from './sort-button'
import { NETWORK_STATUSES } from 'src/reducers/network-status'

// 表示切り替えのボタン
const ToggleButton = styled.button`
  border: 1px solid white;
  border-radius: 4px;
  background-color: transparent;
  color: white;
`

const Region = ({
  // ownProps
  openSortListModal,
  location: { regionIndex },
  display,
  showAllStickies,
  toggleSticky,
  staffWeeklySales,
  worklist,
  getSSworkList,
  staffInfoList,
  putStaffInfo,
  regionInfo,
  // stateProps
  id,
  lists,
  masterRegions,
  userPresetRegions,
  accessToken,
  env,
  networkStatus,
  dock,
  displayDate,
}) => {
  const foundRegion = searchMaster(masterRegions, id)
  const regionName = foundRegion ? foundRegion.name : '(地域名不明)'

  // 未配置付箋の一覧が読み込まれたらtrue
  const dockStickiesLoaded =
    networkStatus.getDockSticky === NETWORK_STATUSES.SUCCESS
  const stickies = dock.stickies || []

  const _messaged = [] // 目安お伝え済み
  let hourlyCount = {
    9: 0,
    10: 0,
    11: 0,
    12: 0,
    13: 0,
    14: 0,
    15: 0,
    16: 0,
    17: 0,
    18: 0,
    19: 0,
    20: 0,
    21: 0,
    22: 0,
    23: 0,
    24: 0,
  }
  if (dockStickiesLoaded) {
    stickies.forEach((sticky, stickyIndex) => {
      // 確定作業日時あり
      const isMessaged =
        sticky.json().determinedDateTime &&
        sticky.json().determinedDateTime.date === displayDate &&
        sticky.json().determinedDateTime.startAt &&
        sticky.json().regionId === id
      if (isMessaged) {
        // 確定作業日時あり=目安お伝え済みのうち、開始時刻のあるもの
        _messaged.push({ sticky, stickyIndex })
      }
    })
    _messaged.forEach(item => {
      const hour = parseInt(
        item.sticky._props.determinedDateTime.startAt.split(':')[0],
        10,
      )
      if (hour < 9) return
      else if (hour >= 24) hourlyCount['24'] += 1
      // 24時以降
      else hourlyCount[hour.toString()] += 1
    })
  }

  // 最初にlistIndexの値を固定しておく
  // 後でソートして壊れてしまわないように
  let regionLists = lists.map((list, index) => ({
    list,
    listIndex: index,
  }))

  // ここは、ssOrdersプロパティが設定されていたら、その順にソートするロジック
  const filtered = userPresetRegions.filter(region => region.id === id)
  const theOrderExists = filtered.length > 0 && !!filtered[0].ssOrder

  if (theOrderExists) {
    const theOrder = filtered.length > 0 ? filtered[0].ssOrder : []
    regionLists = sortSS(regionLists, theOrder)
  }

  const style = display ? {} : { display: 'none' }
  const regionHeaderWrap = {
    display: 'grid',
    gridTemplate:
      'repeat(2, max-content) / 7rem 9.75rem calc(100vw - 17rem - 384px)', // 左のボタンまで17remと右の未配置付箋2列分（360px）と余白24pxをマイナスしてます
    gridTemplateAreas: '"region-name staff-button text-area" "time time time"',
    rowGap: '0.5rem',
    alignItems: 'center',
  }

  const regionHeader = {
    gridArea: 'region-name',
    fontSize: '1rem',
    fontWeight: 'bold',
  }
  const regionNameButtonsStyle = {
    gridArea: 'staff-button',
  }

  const textAreaStyle = {
    gridArea: 'text-area',
    resize: 'vertical',
    minHeight: '4rem',
    marginLeft: '1.5rem',
    padding: '0.5rem',
    borderColor: '#1b95bf',
    borderRadius: '0.5rem',
  }

  const timeAreaStyle = {
    gridArea: 'time',
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: '1rem',
    fontSize: '0.875rem',
  }

  const [notice, setNotice] = useState(null) // エリア別の特記事項の情報
  const [enters, setEnters] = useState(false) // 文字を入力したかの判定。未入力でカーソルを外した時PUTしないようにするため
  const [textHeight, setTextHeight] = useState(null) // "\n"の個数

  // 初回やリロードなどでwara/indexで取得のregionInfoが変わった時再描画します
  useEffect(() => {
    setNotice(regionInfo)
  }, [regionInfo])

  const onTextAreaChange = e => {
    e.target.value && setEnters(true)
    setNotice(e.target.value)
    setTextHeight(getTextHeight(e.target.value))
  }

  // テキストエリアの"\n"の数の計算
  const getTextHeight = (value = '') => {
    if (!value) {
      return null
    } else {
      let rowNum = value.split('\n').length
      return rowNum
    }
  }

  // 編集してテキストエリアからフォーカスを外したときPUT
  const submitText = () => {
    enters && requestPutRegionInfo(id, notice, accessToken, env)
    setEnters(false) // 送信後falseに戻す
  }

  return (
    <div className={ 'region-wrap' } style={ style }>
      <h2 className={ `wara-display-region-${id}` } style={ regionHeaderWrap }>
        <p style={ regionHeader }>{regionName}</p>
        <div style={ regionNameButtonsStyle }>
          <SortButton onClick={ openSortListModal }>{'並び替え'}</SortButton>
          <ToggleButton onClick={ toggleSticky }>
            {showAllStickies ? '完了を非表示' : '全てを表示'}
          </ToggleButton>
        </div>
        <textarea
          style={ textAreaStyle }
          rows={ textHeight ? textHeight : getTextHeight(notice?.[id]?.notice) }
          value={ notice?.[id]?.notice }
          onChange={ onTextAreaChange }
          onBlur={ submitText }
          placeholder={ '（特記事項なし）' }
        />
        <ul style={ timeAreaStyle }>
          {Object.entries(hourlyCount).map(([hour, count], index) => (
            <li
              key={ index }
              style={ {
                color:
                  count > 0
                    ? 'rgba(255, 255, 255, 1)'
                    : 'rgba(255, 255, 255, 0.8)',
                borderBottom: count > 0 && '1px solid #ffffff',
              } }
            >
              {`${hour === '24' ? '24時〜' : hour + '時'}`}
              <span style={ { marginInlineStart: '0.25rem' } }>{`${count}`}</span>
              <span style={ { fontSize: '0.75rem' } }>{'件'}</span>
            </li>
          ))}
        </ul>
      </h2>
      <ul className={ 'list-icons' }>
        {'非表示SS：'}
        {// リスト要素を列挙します
          regionLists.map(({ list, listIndex }) => {
            const identifier = `region-${id}-list-icon-${list.staffId}-${listIndex}`
            const ssDisplay =
            ((filtered[0] && filtered[0].listUserDisplayRegionSS) || [])
              // NOTE: listUserDisplayRegionSS では staffId ではなく ssId が使われているので注意
              .filter(x => x.ssId === list.staffId)[0] || {}

            const display = ssDisplay.display === false ? false : true

            return (
              <ListIcon
                key={ identifier }
                location={ { regionIndex, listIndex } }
                display={ !!list && !display }
              />
            )
          })}
      </ul>
      <ul className={ 'lists' }>
        {// リスト要素を列挙します
          regionLists.map(({ list, listIndex }) => {
            const identifier = `region-${id}-list-${list.staffId}-${listIndex}`
            const ssDisplay =
            ((filtered[0] && filtered[0].listUserDisplayRegionSS) || [])
              // NOTE: listUserDisplayRegionSS では staffId ではなく ssId が使われているので注意
              .filter(x => x.ssId === list.staffId)[0] || {}
            // SSの週次売上
            const ssWeeklySales =
            (staffWeeklySales.staffWeeklySales || {})[list.staffId] || {}
            // SSの稼働状況
            const ssWorkStatus = worklist[list.staffId] || {}
            // SSの情報
            const staffInfo = staffInfoList[list.staffId] || {}

            const display = ssDisplay.display === false ? false : true
            return (
              <span
                className={ display ? 'list-wrap-show' : 'list-wrap-hide' }
                // style={ { display: display ? 'block' : 'none' } }
                key={ identifier }
              >
                <List
                  location={ { regionIndex, listIndex } }
                  regionId={ id } // React-DnD用
                  showAllStickies={ showAllStickies }
                  ssWeeklySales={ ssWeeklySales }
                  ssWorkStatus={ ssWorkStatus }
                  getSSworkList={ getSSworkList }
                  staffInfo={ staffInfo }
                  putStaffInfo={ putStaffInfo }
                />
              </span>
            )
          })}
      </ul>
    </div>
  )
}

/**
 * propTypes
 * @type {object}
 */
Region.propTypes = {
  // ownProps
  openSortListModal: PropTypes.func.isRequired,
  location: PropTypes.shape({
    regionIndex: PropTypes.number.isRequired,
  }).isRequired,
  display: PropTypes.bool.isRequired,
  showAllStickies: PropTypes.bool.isRequired,
  toggleSticky: PropTypes.func.isRequired,
  staffWeeklySales: PropTypes.object.isRequired, // SSの週次売上
  worklist: PropTypes.object.isRequired, // SSの稼働状況
  getSSworkList: PropTypes.func.isRequired, // SSの稼働状況更新
  staffInfoList: PropTypes.object.isRequired, // SSの情報
  putStaffInfo: PropTypes.func.isRequired, // SSの情報更新
  regionInfo: PropTypes.object.isRequired, // エリアごとの特記事項
  // stateProps
  id: PropTypes.number.isRequired,
  lists: PropTypes.arrayOf(PropTypes.object),
  masterRegions: PropTypes.arrayOf(PropTypes.object).isRequired,
  userPresetRegions: PropTypes.array.isRequired,
  accessToken: PropTypes.string.isRequired,
  env: PropTypes.object.isRequired,
  networkStatus: PropTypes.object.isRequired,
  dock: PropTypes.object,
  displayDate: PropTypes.object.isRequired,
}

/**
 * defaultProps
 * @type {object}
 */
Region.defaultProps = {
  lists: [],
  masterRegions: [],
  userPresetRegions: [],
  dock: {},
}

const mapStateToProps = state => {
  return {
    staffs: state.master.data.staffs,
    accessToken: state.login.authentication.accessToken,
    env: state.env,
    networkStatus: state.networkStatus,
    dock: state.wara.data.dock,
    displayDate: state.displayDate.displayDate,
  }
}

export default connect(mapStateToProps)(React.memo(Region))
