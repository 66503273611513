import React from 'react'
import PropTypes from 'prop-types'
import connect from 'src/components/commons/custom-controls/connect'

// component
import AddButton from './add-button'
import ReactDataSheet from 'react-datasheet'
import DeleteButton from './delete-button'
import ECFeeType from './fee-type'

// lib
import Sticky from 'src/lib/class-sticky'
import { toJPYenText, yenToValue } from 'src/lib/format'
import { salesTax, calcTotal } from './util'
import 'react-datasheet/lib/react-datasheet.css'
import styled from 'styled-components'

// セルの値の型。1が飛んでいるのはハウスラボに揃えているため
const textType = 0 // 文字
const paymentType = 2 // 金額

const ExpenseTotalTable = styled.table`
  margin: 1.5rem 0;
  table-layout: fixed;
`
const AmountPaidTD = styled.td`
  border: 1px solid #212121;
  padding: 0.5rem;
  width: 12rem;
`

/**
 * 交換の達人の金額
 * @type {ReactComponent}
 */
export const ECBusiness = ({ sticky, master, route, updateStage }) => {
  /**
   * 料金を入力する行を追加
   */
  const addECExtraFees = () => {
    const { ecExtraFees = [] } = sticky.json() || {}
    ecExtraFees.push({
      id: null,
      name: '',
      fee: 0,
      remark: '',
      ecFeeTypeId: master.ecExtraFeeTypes[0].id,
      isConfirmed: false,
    })
    const diffProps = { ecExtraFees: [...ecExtraFees] }
    updateStage(new Sticky(diffProps))
  }

  const updateExtraFeeTypes = (index, key, value) => {
    const { ecExtraFees = [] } = sticky.json() || {}
    ecExtraFees[index] = {
      ...ecExtraFees[index],
      [key]: value,
    }
    const diffProps = { ecExtraFees: [...ecExtraFees] }
    updateStage(new Sticky(diffProps))
  }

  /**
   * 費用の削除
   * @param {*} index
   */
  const removeECExtraFees = index => {
    const { ecExtraFees = [] } = sticky.json() || {}
    ecExtraFees.splice(index, 1)
    const diffProps = { ecExtraFees: [...ecExtraFees] }
    updateStage(new Sticky(diffProps))
  }

  /**
   * 付箋のデータをテーブル表示用のデータに変換する
   * @param {*} ecExtraFees 費用情報
   */
  const makeECExtraFeesGrid = ecExtraFees => {
    // 標準属性
    // value: 表示する値
    // component: 表示するコンポーネント
    // readOnly: 編集不可の時はtrue
    // width: セルの幅
    // カスタム属性
    // header: 見出しの時true
    // noBorder: 枠線を消すときtrue
    // feeComponent: valueではなくcomponentを表示する時true
    // type: セルのデータ型（数値・文字列など）
    // feeIndex: 金額のインデックス
    // totalValue: 合計金額の欄
    // extraFeeTax: 消費税

    /**
     * その他費用の列を作成し、合計金額を計算する。
     * @param {*} exfee その他費用
     * @param {*} index 費用のインデックス
     */
    const makeExtraFeeRow = (extraFee, index) => {
      const { name, fee = 0, ecFeeTypeId, remark, isConfirmed } = extraFee
      return [
        { feeIndex: index, value: name, type: textType, readOnly: isConfirmed }, // 交換の達人の作業・商材名
        {
          feeIndex: index,
          value: fee,
          type: paymentType,
          readOnly: isConfirmed,
        }, // 価格（税込）
        {
          feeComponent: 'true',
          component: (
            <ECFeeType
              value={ ecFeeTypeId || -1 }
              onChange={ value =>
                updateExtraFeeTypes(index, 'ecFeeTypeId', value)
              }
              route={ route }
              disabled={ isConfirmed }
            />
          ),
        },
        {
          feeIndex: index,
          value: remark,
          type: textType,
          readOnly: isConfirmed,
        }, // 備考
        {
          feeComponent: 'true',
          component: (
            <DeleteButton
              onClick={ () => removeECExtraFees(index) }
              disabled={ isConfirmed }
            />
          ),
        },
      ]
    }
    // ---------------------------------------------------------------
    // 見出し
    const makeHead = () => {
      return [
        [
          { value: '交換の達人', readOnly: true, header: 'true', width: 280 },
          { value: '金額（税込）', readOnly: true, header: 'true', width: 200 },
          { value: '種類', readOnly: true, header: 'true', width: 128 },
          { value: '備考', readOnly: true, header: 'true', width: 280 },
          { value: '', readOnly: true, header: 'true', width: 36 },
        ],
      ]
    }
    const head = makeHead()

    // ---------------------------------------------------------------
    // 表示のためのデータを作成する
    const extraFeeRows = ecExtraFees.map(makeExtraFeeRow)

    return [...head, ...extraFeeRows]
  }

  /**
   * 値の表示方法をカスタマイズする
   * @param {*} cell
   */
  const valueRenderer = cell => {
    if (cell.type === paymentType) {
      // 金額の時は￥マークをつける
      return toJPYenText(cell.value)
    } else if (cell.feeComponent) {
      // コンポーネントを表示する
      return cell.component
    }
    return cell.value
  }

  /**
   * セルの値を編集する時はそのまま表示する
   * @param {*} cell
   */
  const dataRenderer = cell => {
    return cell.value
  }

  /**
   * セルの属性を置き換えることでスタイルを上書きする。
   * @param {*} cell
   */
  const attributesRenderer = cell => {
    // 共通プロパティ
    const commonStyle = {
      color: 'black',
      padding: '0.5rem 0 0.5rem 0',
      border: '#78909c 1px solid',
      verticalAlign: 'middle',
    }
    if (cell.header) {
      // 表のヘッダ
      return {
        ...cell,
        style: {
          ...commonStyle,
          background: 'linear-gradient(#eceff1, #cfd8dc)',
          fontWeight: 'bold',
        },
      }
    } else if (cell.totalValue) {
      // 追加金額の合計欄を特別扱いする
      const val = yenToValue(cell.value)
      const color = isNaN(val) || val >= 0 ? 'black' : 'red'
      return {
        ...cell,
        style: {
          ...commonStyle,
          color,
          background: 'white',
          textAlign: 'right',
          paddingRight: '6px',
        },
      }
    } else if (cell.noBorder) {
      // 枠線を削除する
      return {
        ...cell,
        style: {
          ...commonStyle,
          border: 0,
          background: 'transparent',
        },
      }
    } else {
      // 値を編集する項目
      const color =
        cell.type === paymentType && yenToValue(cell.value) < 0
          ? 'red'
          : 'black'
      const textAlignRight = cell.type === paymentType ? 'right' : 'center'
      // 入力不可の時背景色を変える
      return {
        ...cell,
        style: {
          ...commonStyle,
          color,
          textAlign: textAlignRight,
          background: '#ffffff',
          paddingRight: '1rem',
        },
      }
    }
  }

  /**
   * セルの値が変更された時付箋に反映する
   * @param {*} changes
   */
  const onCellsChanged = changes => {
    const { ecExtraFees = [] } = sticky.json() || {}
    // セルのタイプに応じて値を変換する
    const toValue = (cell, value) => {
      // 数値として処理できる時は数値化する。数値にならない時は 0を設定する
      if (cell.type === textType) {
        return value
      } else if (isNaN(parseInt(value, 10))) {
        return 0
      } else {
        return parseInt(value, 10)
      }
    }
    // 列と項目名の対応。ない項目はここでは更新されない。
    const keys = ['name', 'fee', 'ecFeeTypeId', 'remark', null]
    changes.forEach(({ col, cell, value }) => {
      const val = toValue(cell, value)
      const index = cell.feeIndex
      const key = keys[col] || '-'
      if (cell.extraFeeTax && col === 4) {
        // これ以外は変更されない
        const extax = val
      } else if (key !== '-') {
        ecExtraFees[index] = { ...ecExtraFees[index], [key]: val }
        if (key === 'name' || key === 'remark') {
          // 名前または備考が変更された時、消費税は今の設定を引き継ぐ
          const extax = 0
        }
      }
    })
    // 消費税と合計金額を更新する
    const diffProps = { ecExtraFees: [...ecExtraFees] }
    updateStage(new Sticky(diffProps))
  }
  // ---------------------------------------------------------------
  // 「交換の達人」の金額を入力する表
  const { ecExtraFees = [] } = sticky.json() || {}
  const grid = makeECExtraFeesGrid(ecExtraFees)

  // ---------------------------------------------------------------
  // 追加金額入力のための表スタイル
  const extraFeeSheetStyle = {
    marginTop: '1.5rem',
    marginBottom: '1.5rem',
  }

  const { amountPaid, tax } = calcTotal(ecExtraFees)

  // ---------------------------------------------------------------
  // 全体の描画
  return (
    <div>
      <div style={ extraFeeSheetStyle }>
        <ReactDataSheet
          data={ grid }
          valueRenderer={ valueRenderer }
          dataRenderer={ dataRenderer }
          onCellsChanged={ onCellsChanged }
          attributesRenderer={ attributesRenderer }
        />
        <div
          style={ {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          } }
        >
          <AddButton
            label={ '＋ 行を追加する' }
            onClick={ addECExtraFees }
            disabled={ ecExtraFees.some(extraFee => extraFee.isConfirmed) }
          />
          <div>
            <ExpenseTotalTable>
              <tr>
                <AmountPaidTD>{'支払額(税込)'}</AmountPaidTD>
                <AmountPaidTD style={ { textAlign: 'right' } }>
                  {toJPYenText(amountPaid)}
                </AmountPaidTD>
              </tr>
              <tr>
                <AmountPaidTD>{'消費税分(10%)'}</AmountPaidTD>
                <AmountPaidTD style={ { textAlign: 'right' } }>
                  {toJPYenText(tax)}
                </AmountPaidTD>
              </tr>
            </ExpenseTotalTable>
          </div>
        </div>
      </div>
    </div>
  )
}

/**
 * Validation
 * @type {object}
 */
ECBusiness.propTypes = {
  sticky: PropTypes.instanceOf(Sticky).isRequired,
  route: PropTypes.string.isRequired,
  // state props
  master: PropTypes.object.isRequired,
  // Auto bind action creator
  updateStage: PropTypes.func.isRequired,
}

export default connect(ECBusiness)
