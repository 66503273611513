import INSERT_STICKY_ACTION from 'src/reducers/wara/actions/insert-sticky'
import { createAsyncPostExchangeStickiesAction } from 'src/reducers/wara/async'

/**
 * 付箋の入れ替え -> 入れ替えのシリアライズのチェーン
 * prehookで実行することで、UI変更がdispatchされる前のstateを利用する
 * @param  {object} store  redux store
 * @param  {object} action redux action
 * @return {void}
 */
export const preInsertStickyHook = (store, action) => {
  const {
    targetRegionIndex,
    targetListIndex,
    stickyIndexSrc,
    stickyIndexDest,
  } = action.payload

  // state取得
  const {
    wara: { data: wara },
    login: {
      authentication: { accessToken },
    },
    env,
  } = store.getState()

  // 現行の並び順を取得
  const stickyIdSeq = wara.regions[targetRegionIndex].lists[
    targetListIndex
  ].stickies.map(sticky => sticky.id)

  const moveItem = (arr, from, to) => {
    const result = [...arr]
    const [movedItem] = result.splice(from, 1)
    result.splice(to, 0, movedItem)
    return result
  }

  const reorderedStickyIds = moveItem(
    stickyIdSeq,
    stickyIndexSrc,
    stickyIndexDest > stickyIndexSrc ? stickyIndexDest - 1 : stickyIndexDest,
  )

  // リクエスト
  const body = { stickyOrders: [reorderedStickyIds] }
  store.dispatch(createAsyncPostExchangeStickiesAction(body, accessToken, env))
}

export default {
  [INSERT_STICKY_ACTION.type]: preInsertStickyHook,
}
