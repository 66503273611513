import PropTypes from 'prop-types'
import React from 'react'
import config from 'src/config'
import Checkbox from 'src/styled/form/checkbox'
import styled from 'styled-components'
import { SelectButton } from '../styled'
import connect from './props'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
`

/**
 * 配置済み付箋置き場の地域をフィルタするコントローラー
 * @type {function}
 */
export class SelectRegionsForAssociates extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // stateProps
    masterRegions: PropTypes.array,
    displayingRegions: PropTypes.arrayOf(PropTypes.number.isRequired),
    isDebugMode: PropTypes.bool.isRequired,
    // dispathProps
    makeRegionVisible: PropTypes.func.isRequired,
    resetUserPreset: PropTypes.func.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    masterRegions: [],
    displayingRegions: [],
  }

  constructor(props) {
    super(props)
    this.state = {
      showNormal: true, // それぞれのエリアを表示するフラグ
      showHl: false,
      showQi: false,
    }
  }

  /**
   * on select handler
   * @param  {Event} e event
   * @return {void}
   */
  onRegionSelect = e => {
    const val = parseInt(e.target.value, 10)
    const id = isNaN(val) ? -1 : val
    this.props.makeRegionVisible(id, e.target.checked)
  }

  /**
   * エリアを全て選択する
   * @param {*} area 選択するエリアIDの配列または'ALL'
   */
  selectArea = area => {
    this.props.makeRegionVisible(area, true)
  }

  /**
   * エリアを全て選択解除する
   * @param {*} area 選択解除するエリアIDの配列または'ALL'
   */
  deselectArea = area => {
    this.props.makeRegionVisible(area, false)
  }

  /**
   * 通常のエリア表示の切替
   * @param {*} show
   */
  showNormal = show => {
    this.setState({ showNormal: show })
  }

  /**
   * HLエリアの表示切替
   * @param {*} show
   */
  showHlRegion = show => {
    this.setState({ showHl: show })
  }

  /**
   * QIエリアの表示切替
   * @param {*} show
   */
  showQiRegion = show => {
    this.setState({ showQi: show })
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const {
      // stateProps
      masterRegions,
      displayingRegions,
      isDebugMode,
      // dispatchProps
      resetUserPreset,
    } = this.props
    const { showNormal, showHl, showQi } = this.state
    // isValidのエリアのみを表示する
    const displayRegions = masterRegions.filter(region => region.isValid)

    // 種類ごとにエリアを分類する。エリアの種類が増えるごとに表示フラグと分類を手作業で追加する。
    const normalRegions = displayRegions.filter(
      region => region.companyId === config.company.ES,
    )
    const hlRegions = displayRegions.filter(
      region => region.companyId === config.company.HL,
    )
    const qiRegions = displayRegions.filter(
      region => region.companyId === config.company.QI,
    )

    /**
     * エリアのブロックを描画
     * @param {*} show 表示フラグ
     * @param {*} regions 表示するエリアのリスト
     * @param {*} label エリア名
     * @param {*} showRegion 表示切り替えのメソッド
     */
    const regionsBlock = (show, regions, label, showRegion) => {
      const regionStyle = {
        marginBottom: '0.5rem',
      }
      const labelDivStyle = {
        display: 'flex',
        aliginItems: 'center',
      }
      const labelStyle = {
        background: 'lightgray',
        borderRadius: '0.2rem',
        marginRight: '2rem',
        padding: '0.3rem',
        cursor: 'pointer',
      }
      const buttonStyle = {
        marginRight: '1rem',
      }
      if (show) {
        // 全選択・全解除するエリアのID一覧
        const regionIds = regions.map(region => region.id)
        return (
          <div style={ regionStyle }>
            <div style={ labelDivStyle }>
              <span
                style={ labelStyle }
                // eslint-disable-next-line react/jsx-no-bind
                onClick={ () => {
                  showRegion(false)
                } }
              >
                {'▼'}
                {label}
              </span>
              <SelectButton
                style={ buttonStyle }
                // eslint-disable-next-line react/jsx-no-bind
                onClick={ () => this.selectArea(regionIds) }
              >
                {'全選択'}
              </SelectButton>
              <SelectButton
                style={ buttonStyle }
                // eslint-disable-next-line react/jsx-no-bind
                onClick={ () => this.deselectArea(regionIds) }
              >
                {'全解除'}
              </SelectButton>
            </div>
            <div style={ { display: 'flex', flexWrap: 'wrap' } }>
              {regions.map(region => (
                <div key={ region.id }>
                  <Checkbox
                    labelText={ region.name }
                    value={ region.id }
                    name={ 'select-region-associated' }
                    checked={ displayingRegions.includes(region.id) }
                    onChange={ this.onRegionSelect }
                  />
                </div>
              ))}
            </div>
          </div>
        )
      } else {
        return (
          <div style={ regionStyle }>
            <div style={ labelDivStyle }>
              <span
                style={ labelStyle }
                // eslint-disable-next-line react/jsx-no-bind
                onClick={ () => {
                  showRegion(true)
                } }
              >
                {'▶︎'}
                {label}
              </span>
            </div>
          </div>
        )
      }
    }

    return (
      <>
        {/*
        <div>
          <Button onClick={ () => this.selectArea('ALL') }>{'全選択'}</Button>
          <Button onClick={ () => this.deselectArea('ALL') }>{'全解除'}</Button>
        </div>
        */}
        <Container>
          <div>
            {regionsBlock(showNormal, normalRegions, 'エリア', this.showNormal)}
            {/* {regionsBlock(showHl, hlRegions, 'HLエリア', this.showHlRegion)}
            {regionsBlock(showQi, qiRegions, 'Qエリア', this.showQiRegion)} */}
          </div>
          {isDebugMode && (
            <button onClick={ resetUserPreset }>
              {'ユーザープリセットのリセット: デバッグ用'}
            </button>
          )}
        </Container>
      </>
    )
  }
}

export default connect(SelectRegionsForAssociates)
