import React from 'react'
import PropTypes from 'prop-types'
import Authorize from 'src/components/commons/authorize'
import CommonHeader from 'src/components/layouts/common-header'
import CommonFooter from 'src/components/layouts/common-footer'
import CallEntry from 'src/components/divisions/call/entry'
import { qsparse } from 'src/lib/format'

import config from 'src/config'

/**
 * 統計コンポーネント
 * @type {ReactComponent}
 */
export class CallView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate() {
    return false
  }

  /**
   * render
   * @return {ReactDOM} dom
   */
  render() {
    const { match, location } = this.props
    const search = !!match.params.search
    // クエリパラメータで電話番号を渡す
    const query = qsparse(location.search) || {}
    const phoneNumber = query.phoneNumber || ''

    return (
      <div className={ 'callview__wrapper' }>
        <CommonHeader route={ 'call' } />

        <Authorize roles={ config.roles.call }>
          <div className={ 'box' }>
            <CallEntry search={ search } phoneNumber={ phoneNumber } />
          </div>
        </Authorize>

        <CommonFooter />
      </div>
    )
  }
}

export default CallView
