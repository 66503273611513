import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import logo from 'src/styles/assets/logo-es_02.png'

const QuickSearchNotFound = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`

const Image = styled.div`
  width: 80px;
  height: 42px;
  margin-right: 10px;
  background-image: url(${logo});
  background-size: cover;
`

const Message = styled.p`
  line-height: 1.4em;
`

/**
 * 検索結果が見つからないよコンポーネント
 * @return {ReactComponent} component
 */
export const SearchNotFound = props => {
  const { isFailed } = props

  const Content = () =>
    isFailed ? (
      '不明なエラーです。'
    ) : (
      <span>
        {'見つかりませんでした'}
        <br />
        {'検索条件を変更して、再度お試しください'}
      </span>
    )

  return (
    <QuickSearchNotFound>
      <Image />
      <Message>
        <Content />
      </Message>
    </QuickSearchNotFound>
  )
}

SearchNotFound.propTypes = {
  isFailed: PropTypes.bool.isRequired,
}

export default SearchNotFound
