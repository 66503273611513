import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { createAsyncLoginAction } from 'src/reducers/login'
import { ACTION_TYPES as LOGIN_ACTION_TYPES } from 'src/reducers/login'
import { NETWORK_STATUSES } from 'src/reducers/network-status'

import { FormLine, Label, Input } from './styled'

/**
 map state to props
 * @param  {object} state mapping state
 * @return {object}       mapped state as props
 */
export const mapStateToProps = state => {
  return {
    userid: state.login.authentication.userid,
    password: state.login.authentication.password,
    loginStatus: state.networkStatus.login,
    __env: state.env,
  }
}

/**
 * map dispatch to props
 * @param  {function} dispatch dispatcher
 * @return {object}               mapped dispatcher as props
 */
export const mapDispatchToProps = dispatch => {
  return {
    /**
     * ログインする
     * @param  {string} userid   user id
     * @param  {string} password password
     * @return {void}
     */
    __login: env => (userid, password) =>
      dispatch(createAsyncLoginAction(userid, password, env)),

    /**
     * ユーザーIDをアップデートする
     * @param {string} userid user id
     * @return {void}
     */
    setUserid: userid =>
      dispatch({ type: LOGIN_ACTION_TYPES.SET_USERNAME, payload: { userid } }),
  }
}

/**
 * map state to props
 * @param  {object} stateProps    state props
 * @param  {object} dispatchProps dispatch props
 * @param  {object} ownProps      own props
 * @return {object}               props
 */
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    // merge
    login: dispatchProps.__login(stateProps.__env),
    // make private
    __env: void 0,
    __login: void 0,
  }
}

/**
 * ユーザーIdの入力を行うコンポーネント
 * @param {object} props props
 * @return {ReactComponent}
 */
export class UserId extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // stateProps
    userid: PropTypes.string,
    password: PropTypes.string,
    loginStatus: PropTypes.oneOf(Object.values(NETWORK_STATUSES)),
    // DispatchProps
    login: PropTypes.func.isRequired,
    setUserid: PropTypes.func.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    userid: '',
    password: '',
    loginStatus: NETWORK_STATUSES.NOT_YET,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return (
      this.props.userid !== nextProps.userid ||
      this.props.loginStatus !== nextProps.loginStatus
    )
  }

  /**
   * ユーザーIDフォームの変更ハンドラ
   * @param  {Event} e event
   * @return {void}
   */
  onUseridChange = e => this.props.setUserid(e.target.value)

  /**
   * エンターキーを押してログインをするためのKeyDownハンドラ
   * @param  {Event} e event
   * @return {void}
   */
  onEnterKeyDownToLogin = e =>
    this.props.userid !== '' &&
    this.props.password !== '' &&
    e.keyCode === 13 &&
    this.props.login(this.props.userid, this.props.password)

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { userid, loginStatus } = this.props

    const disabled = loginStatus === NETWORK_STATUSES.TRYING

    return (
      <FormLine>
        <Label htmlFor={ 'userid' }>{'ユーザーID'}</Label>
        <Input
          autoComplete={ 'off' }
          value={ userid }
          id={ 'userid' }
          name={ 'userid' }
          type={ 'text' }
          onChange={ this.onUseridChange }
          onKeyDown={ this.onEnterKeyDownToLogin }
          disabled={ disabled }
        />
      </FormLine>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(UserId)
