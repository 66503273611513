import utils from 'src/lib/api-utils'

export const get = (stickyId, accessToken, env) => {
  const base = utils.createEndpoint(env, 'facility')
  const endpoint = `${base}/${stickyId}`

  return fetch(endpoint, {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  })
}

export default get
