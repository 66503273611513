/**
 * 付箋データ入力フォームの並び順などを定義する
 * @file
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Sticky from 'src/lib/class-sticky'

// コンポーネント
import NegotiationRecords from '../custom-controls/negotiation-records'
import FinishState from '../custom-controls/assign/finish-state'
import IsAlternative from '../custom-controls/is-alternative'
import Contacts from '../custom-controls/contacts'

// libs
import { findListFromWara } from 'src/lib/search'

/**
 map state to props
 * @param  {object} state state
 * @return {object}      mapped states
 */
export const mapStateToProps = state => {
  return {
    wara: state.wara.data,
  }
}

/**
 * 付箋入力フォームを描画します
 * @class {ReactComponent}
 */
export class ClaimerForms extends React.Component {
  /**
   * PropTypes 型定義
   * @type {object}
   */
  static propTypes = {
    // ownProps
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    route: PropTypes.oneOf(['call', 'assign']).isRequired,
    location: PropTypes.shape({
      regionIndex: PropTypes.number.isRequired,
      listIndex: PropTypes.number.isRequired,
    }).isRequired,
    // stateProps
    wara: PropTypes.shape({
      regions: PropTypes.arrayOf(
        PropTypes.shape({
          lists: PropTypes.arrayOf(
            PropTypes.shape({
              stickies: PropTypes.arrayOf(PropTypes.instanceOf(Sticky)),
            }),
          ),
        }),
      ).isRequired,
    }).isRequired,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return this.props.sticky !== nextProps.sticky
  }

  /**
   * render
   * @return {ReactDOM} rendered React DOM
   */
  render() {
    const {
      // ownProps
      location,
      sticky,
      route,
      // stateProps
      wara,
    } = this.props

    const stickyProps = sticky.json()

    const negotiationRecords = stickyProps.negotiationRecords || []
    const contacts = stickyProps.contacts || []
    const handOver = stickyProps.handOver || {}
    const regionId = stickyProps.regionId || -1

    const isSpecial = true

    const { staffName } = findListFromWara(wara, location)

    return (
      <div onClick={ e => e.stopPropagation() }>
        <div className={ 'new-order-input' }>
          <h2 className={ 'form-title' }>{'©︎付箋入力'}</h2>

          <IsAlternative
            { ...{
              staffName, // マルシー付箋はこの中でスタッフ名を表示
              sticky,
              route,
              disabled: false,
            } }
          />
          {/* マルシー代行 */}

          <h3 className={ 'form-subtitle' }>{'電話番号登録'}</h3>

          <Contacts
            { ...{
              contacts,
              tabIndex: 0,
              'data-tab-header': { head: 'マルシー', length: 1 },
              isClaimer: true,
              regionId,
              route,
              sticky,
              disabled: false,
            } }
          />

          <NegotiationRecords
            { ...{
              handOver,
              negotiationRecords,
              contacts,
              route,
              sticky,
              disabled: false,
            } }
          />
          {/* 交渉履歴 */}

          <FinishState
            { ...{ sticky, isSpecial, route, location, disabled: false } }
          />
          {/* 終了状態 */}

          {/* <ServiceStaff /> */}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps)(ClaimerForms)
