import React from 'react'
import connect from '../connect'
import PropTypes from 'prop-types'
import Sticky from 'src/lib/class-sticky'

export class DeleteButton extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    disabled: PropTypes.bool.isRequired,
    tabIndex: PropTypes.number.isRequired,
    contactsIndex: PropTypes.number.isRequired,
    // dispatchProps
    updateStage: PropTypes.func.isRequired,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return this.props.disabled !== nextProps.disabled
  }

  onClick = () => {
    const {
      // ownProps
      sticky,
      tabIndex,
      contactsIndex,
      // dispatchProps
      updateStage,
    } = this.props

    if (!window.confirm('この連絡先を削除します。本当によろしいですか？')) {
      return
    }
    const id = sticky.json().contacts[tabIndex][contactsIndex].id

    if (id === null || id === void 0) {
      // idが存在しない連絡先を削除しようとしている時
      // このブロックが呼ばれるのは、コール課から操作した時と、アサイン課で新規に連絡先を作ってそれを削除しようとした時。
      const tabContacts = [...((sticky.json().contacts || [])[tabIndex] || [])]
      // 削除対象のcontactsを-1にする。
      // src/lib/class-sticky/lib/sticky-merge.js:112 あたりで削除する。
      tabContacts[contactsIndex] = -1
      const diffProps = {
        contacts: {
          [tabIndex]: tabContacts,
        },
      }
      updateStage(new Sticky(diffProps, { shallow: true }))
    } else {
      // idが存在する連絡先を削除しようとしている時
      // このブロックはアサイン課から呼ばれることになる
      const diffProps = {
        contacts: {
          [tabIndex]: {
            [contactsIndex]: { id, deleted: true },
          },
        },
      }
      updateStage(new Sticky(diffProps))
    }
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { disabled } = this.props

    return (
      <button
        className={ 'button button-icon-delete-contact' }
        style={ { position: 'relative' } }
        onClick={ this.onClick }
        // 連絡先を消すことは少ないだろう
        // tabOrderは振らない
        // マウスでクリックして欲しい
        tabIndex={ -1 }
        disabled={ disabled }
      />
    )
  }
}

export default connect(DeleteButton)
