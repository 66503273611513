import { createInventoryEndpoint } from 'src/lib/api-utils'

/**
 * GraphQueryによる見積書・請求書PDFデータの取得
 * @returns
 */
const query = (body, accessToken, env) => {
  const url = createInventoryEndpoint(env, 'query')
  const request = fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body,
  })
  return request
}

/**
 * 見積書PDF
 * @param {*} id
 * @param {*} accessToken
 * @param {*} env
 * @returns
 */
export const getQuotation = (id, accessToken, env) => {
  const body = {
    query: `query getQuotation($id: Int!) {
      getQuotation(id: $id) {
        quotation {
            id
            stickyId
            orderId
            staffId
            quotationNumber
            issuedDate
            limitDate
            s3Filename
            uploadAt
        }
        downloadTimes
        printTimes
        data
        stampedData
      }
    }
  `,
    variables: {
      id,
    },
  }
  return query(JSON.stringify(body), accessToken, env)
}

/**
 * 請求書PDF
 * @param {*} id
 * @param {*} accessToken
 * @param {*} env
 * @returns
 */
export const getContract = (id, accessToken, env) => {
  const body = {
    query: `query getContract($id: Int!) {
      getContract(id: $id) {
        contract {
            id
            quotationId
            stickyId
            orderId
            staffId
            contractNumber
            issuedDate
            s3Filename
            uploadAt
        }
        downloadTimes
        printTimes
        data
        stampedData
      }
    }
  `,
    variables: {
      id,
    },
  }
  return query(JSON.stringify(body), accessToken, env)
}

/**
 * 工事完了確認書PDF
 * @param {*} id
 * @param {*} accessToken
 * @param {*} env
 * @returns
 */
export const getWorkComplete = (id, accessToken, env) => {
  const body = {
    query: `query getWorkComplete($id: Int!) {
      getWorkComplete(id: $id) {
        workComplete {
          id
          quotationId
          stickyId
          orderId
          staffId
          workCompleteNumber
          workCompleteDate
          s3Filename
          uploadAt
        }
        downloadTimes
        printTimes
        data
      }
    }
  `,
    variables: {
      id,
    },
  }
  return query(JSON.stringify(body), accessToken, env)
}

/**
 * 領収書PDF
 * @param {*} id
 * @param {*} accessToken
 * @param {*} env
 * @returns
 */
export const getReceipt = (id, accessToken, env) => {
  const body = {
    query: `query getReceipt($id: Int!) {
      getReceipt(id: $id) {
        receipt {
          id
          quotationId
          stickyId
          orderId
          staffId
          receiptNumber
          documentStatus
          issuedDate
          s3Filename
          uploadAt
        }
        downloadTimes
        printTimes
        data
      }
    }
  `,
    variables: {
      id,
    },
  }
  return query(JSON.stringify(body), accessToken, env)
}

/**
 * 出庫同意書PDF
 * @param {*} id
 * @param {*} accessToken
 * @param {*} env
 * @returns
 */
export const getShipInventory = (id, accessToken, env) => {
  const body = {
    query: `query getShipInventory($id: Int!) {
      getShipInventory(id: $id) {
        shipInventory {
          id
          stickyId
          orderId
          toStaffId
          s3Filename
          createAt
          createUserId
        }
        data
      }
    }
  `,
    variables: {
      id,
    },
  }
  return query(JSON.stringify(body), accessToken, env)
}

/**
 * 作業完了確認書PDF
 * @param {*} id
 * @param {*} accessToken
 * @param {*} env
 * @returns
 */
export const getHLWorkComplete = (id, accessToken, env) => {
  const body = {
    query: `query getHLWorkComplete($id: Int!) {
      getHLWorkComplete(id: $id) {
        hlWorkComplete {
          id
          stickyId
          orderId
          staffId
          s3Filename
          uploadAt
        }
        downloadTimes
        printTimes
        data
      }
    }
  `,
    variables: {
      id,
    },
  }
  return query(JSON.stringify(body), accessToken, env)
}

/**
 * 「交換の達人」工事完了確認書PDF
 * @param {*} id
 * @param {*} accessToken
 * @param {*} env
 * @returns
 */
export const getECWorkComplete = (id, accessToken, env) => {
  const body = {
    query: `query getECWorkComplete($id: Int!) {
      getECWorkComplete(id: $id) {
        ecWorkComplete {
          id
          stickyId
          orderId
          staffId
          ecWorkCompleteNumber
          documentStatus
          issuedDate
          s3Filename
          uploadAt
        }
        downloadTimes
        printTimes
        data
      }
    }
  `,
    variables: {
      id,
    },
  }
  return query(JSON.stringify(body), accessToken, env)
}

/**
 * 「交換の達人」工事完了確認書PDF
 * @param {*} id
 * @param {*} accessToken
 * @param {*} env
 * @returns
 */
export const getESignAgreement = (id, accessToken, env) => {
  const body = {
    query: `query getESignAgreement($id: Int!) {
      getESignAgreement(id: $id) {
        eSignAgreement {
          id
          stickyId
          orderId
          staffId
          eSignAgreementNumber
          quotationNumber
          documentStatus
          issuedDate
          s3Filename
          uploadAt
        }
        printTimes
        data
      }
    }
  `,
    variables: {
      id,
    },
  }
  return query(JSON.stringify(body), accessToken, env)
}
