export default {
  /**
   * ユーザー定義のHTTPヘッダの値です
   * @type {object}
   */
  userDefinedHTTPHeaderNames: {
    /**
     * APIアクセスのページングのクエリを考慮しなかった場合の、検索結果の総数。
     * UIでのページングの実装に使われる
     * @type {string}
     */
    stickySearchResultFullcount: 'X-Fullcount',
  },

  /**
   * 1ページに含まれる検索結果の数です
   * @type {number}
   */
  initialSearchLimit: 20,

  /**
   * ページングの時に表示するページの数です。
   * この値を7とすると、
   * 例えば現在2ページにいる時は、"最初,前へ,1,2,3,4,5,6,7...次へ,最後"のように表示されます
   * また、現在5ページにいる時は、"最初,前へ,...2,3,4,5,6,7,8...次へ,最後"のように表示されます
   * 偶数の値を指定した時は切り捨てられます
   * @type {number}
   */
  displayPageMax: 7,

  /**
   * クレジットカード支払い回数のマスタ
   * @type {Array<number>}
   */
  paymentTimesMaster: [3, 5, 6, 10, 12, 15, 18, 20, 24],

  /**
   * テスト用のメールアドレス
   * @type {string}
   */
  fallbackTestEmail: 'info+es-demo@ai-ot.com',

  /**
   * メッセージを消すまでのミリ秒数
   * @type {number}
   */
  messageResultDelay: 2000,

  /**
   * 案件入力フォームに関する定数
   * @type {object}
   */
  stickyModalContent: {
    // 電話番号入力欄のMax
    contactsMaxNum: 4,
    // 交渉履歴入力欄のMax
    recordsInfoMax: 5,
  },

  /**
   * サーバーと通信するときプロパティをnullに戻すためのフラグ
   * @type {number}
   */
  DELETE_ME: -1,

  /**
   * StickyStatusの逆引き
   * @type {object}
   */
  stickyStatus: {
    deleted: -1,
    notWorking: 0,
    split: 1, // 仮アサイン状態
    working: 10, // 着手中
    finishWorking: 11, // 着手完了
    liquidated: 20,
  },

  // 建物情報の逆引き
  buildingType: {
    other: 99,
  },

  customerType: {
    own: 4,
  },

  // 受付会社のリスト：アサイン課未配置付箋のチェックボックスで使用。
  // companyで定義したidと名前の対応、companyのキー
  // 修正した時はcompaniesも修正すること。
  companies: [
    { id: 1, name: 'イースマイル', key: 'ES' },
    { id: 2, name: 'ハウスラボ', key: 'HL' },
    { id: 3, name: 'キレイエ', key: 'QI' },
    { id: 4, name: 'ES提携', key: 'SV' },
    { id: 5, name: '交換の達人', key: 'EC' },
  ],
  // 受付会社
  company: {
    ES: 1,
    HL: 2,
    QI: 3,
    SV: 4,
    EC: 5,
  },

  personalTypeCode: {
    M1: 'M1',
  },

  // 申し送り事項IDの逆引き
  handOverStatuses: {
    // 今後受入不可のID
    reject: 1,
    other: 6,
    // マルシー
    claimContent: 11,
    measure: 12,
    result: 13,
  },

  // マスタが無いようだ
  marucHandOverStatus: [
    { id: 11, name: 'クレーム内容' },
    { id: 12, name: '対策' },
    { id: 13, name: '結果' },
  ],

  // ss勤務状態の逆引き
  workKind: {
    dayShift: 1, // 日勤
    lateShift: 2, // 遅番
    dayOff: 3, // 休日
    paidLeave: 4, // 有休
    workingHoliday: 5, // 休出
    absence: 6, // 欠勤
    halfDayOff: 7, // 半休
    specialLeave: 8, // 特休
  },

  // 終了状態逆引き
  finishState: {
    complete: 1,
    reformComplete: 6,
    cancel: 7,
    na: 99,
  },

  answerTime: {
    messaged: 4,
    messagedAtNight: 5,
  },

  // 付箋タイプの逆引き
  stickyType: {
    normal: 1,
    claimer: 2,
    private: 3,
    memo: 4,
    noop: 5, // ただの隙間を埋めるタイプの付箋
    payment: 6, // 集金付箋
  },

  // 作業箇所の逆引き
  workPoint: {
    other: 5,
  },

  // 現況の逆引き
  workType: {
    other: 4,
  },

  // クレジットカードの逆引き
  creditCard: {
    cash: -1,
  },

  // 集金方法逆引き
  // receivableCollection の別名
  howToRetrieve: {
    collect: 2, // 集金
    creditCard: 6,
  },

  // 注文タイプの逆引き
  orderType: {
    new: 1,
    repeat: 2,
    claimer: 3,
    undefined: -1, // 未定
  },

  // 支払い種別の逆引き
  paymentMethod: {
    cash: -1,
    check: 999, // 小切手
    split: 2, // 分割払い
    bonus: 4, // ボーナス払い
    homeServe: 9, // ホームサーブ
  },

  // 支払方法
  paymentMethodId: {
    onetime: 1, // 1回払い
    towtimes: 5, // 2回払い
    bonus: 4, // ボーナス
    revolving: 3, // リボ
    installments: 2, // 分割
  },

  // 中断理由の逆引き

  suspendReason: {
    suspend1: 1,
    afterUndecided: 101,
  },

  // 受電媒体の埋め込み
  media: {
    other: 999,
  },

  // キャンセル理由の逆引き
  cancelReason: {
    ICA: 1,
    ICB: 2,
    CNCL1: 201,
    CNCL2: 202,
  },

  // マルシーステータスの逆びき

  measures: {
    payedRepaier: 2,
  },

  // 調整理由の逆引き
  adjustmentTypes: {
    salesTransfer: 1,
  },

  adjustmentReasonTypes: {
    marucDaikou: 1,
  },

  // API access
  API: {
    version: { dir: 'stickyBoard/V0/revision' },
    login: { dir: 'V0/login' },
    zip: { dir: 'stickyBoard/V0/zip' },
    address: { dir: 'stickyBoard/V0/address' }, // 住所検索
    master: { dir: 'stickyBoard/V0' },
    userPreset: { dir: 'stickyBoard/V0/userPreset' }, // ユーザープリセットシリアライズ
    staffs: { dir: 'stickyBoard/V0/serviceStaffs' }, // サービススタッフ一覧取得
    allstaffs: { dir: 'stickyBoard/V0/allServiceStaffs' }, // サービススタッフ一覧取得(退職者も含む)　※使わないはずだが、残しておく。
    allServiceStaffs: { dir: 'stickyBoard/V0/allServiceStaffs' }, // サービススタッフ一覧取得(退職者も含む)
    staff: { dir: 'stickyBoard/V0/serviceStaff' }, // サービススタッフPUT
    sticky: { dir: 'stickyBoard/V0/sticky' }, // 新規付箋作成
    stickies: { dir: 'stickyBoard/V0/stickies' },
    dockStickies: { dir: 'stickyBoard/V0/stickies' }, // 未配置付箋取得
    exchangeStickies: { dir: 'stickyBoard/V0/exchangeSticky' }, // 付箋の入れ替え
    search: { dir: 'stickyBoard/V0/stickies' }, // 標準検索
    sendMail: { dir: 'stickyBoard/V0/sendmail' }, // メール送信
    histories: { dir: 'stickyBoard/V0/histories.tsv' }, // 変更履歴取得
    payment: { dir: 'stickyBoard/V0/payment' },
    payments: { dir: 'stickyBoard/V0/payments' },
    unconfirmedContacts: { dir: 'stickyBoard/V0/unconfirmedContacts' },
    confirmContacts: { dir: 'stickyBoard/V0/confirmContacts' },
    cancelConfirmContacts: { dir: 'stickyBoard/V0/cancelConfirmContacts' }, // 確定済みをキャンセル
    confirmRefundContacts: { dir: 'stickyBoard/V0/confirmRefundContacts' }, // 返金を確定する
    cancelConfirmRefundContacts: {
      dir: 'stickyBoard/V0/cancelConfirmRefundContacts',
    }, // 確定した返金をキャンセルする
    totalSales: { dir: 'stickyBoard/V0/totalSales' }, // 売り上げ合計
    mailLists: { dir: 'stickyBoard/V0/mailLists' }, // メール送信履歴
    notifyStart: { dir: 'stickyBoard/V0/notifyStart' }, // 稼働開始のプッシュ通知を発火
    notifyWorkStart: { dir: 'stickyBoard/V0/notifyWorkStart' }, // 着手開始のプッシュ通知を発火
    notifyWorkEnd: { dir: 'stickyBoard/V0/notifyWorkEnd' }, // 着手終了のプッシュ通知を発火
    notifyWorkCancel: { dir: 'stickyBoard/V0/notifyWorkCancel' }, // 着手キャンセルのプッシュ通知を発火
    notifyEnd: { dir: 'stickyBoard/V0/notifyEnd' }, // 稼働開始のプッシュ通知を発火
    staffExpense: { dir: 'stickyBoard/V0/staffExpense' }, // SS自己負担
    facility: { dir: 'stickyBoard/V0/facility' }, // 設備情報
    family: { dir: 'stickyBoard/V0/family' }, // 家族構成
    // マルシー
    maruc: { dir: 'stickyBoard/V0/maruc' },
    marucStatistics: { dir: 'stickyBoard/V0/marucStatistics' }, // マルシーの集計
    refund: { dir: 'stickyBoard/V0/refund' }, // マルシー返金
    refundMethods: { dir: 'stickyBoard/V0/refundMethods' }, // マルシー返金方法
    // 注文一覧
    orderList: { dir: 'stickyBoard/V0/orderList' },
    downloadOrderList: { dir: 'stickyBoard/V0/downloadOrderList' },
    // 精算処理
    settlementStaffs: { dir: 'stickyBoard/V0/settlementStaffs' },
    settlement: { dir: 'stickyBoard/V0/settlement' }, // スタッフ精算金額
    settlementAll: { dir: 'stickyBoard/V0/settlementAll' }, // 指定期間のスタッフ全員の精算確定
    documents: { dir: 'stickyBoard/V0/documents' }, // 伝票処理
    documentTypes: { dir: 'stickyBoard/V0/documentTypes' }, // 伝票処理
    documentBundle: { dir: 'stickyBoard/V0/documentBundle' }, // 伝票処理
    // 売掛金回収履歴
    accountHistory: { dir: 'stickyBoard/V0/accountHistory' },
    incompleteAccount: { dir: 'stickyBoard/V0/incompleteAccount' },
    completeAccount: { dir: 'stickyBoard/V0/completeAccount' },
    downloadAccountHistory: { dir: 'stickyBoard/V0/downloadAccountHistory' },
    uploadAccountHistory: { dir: 'stickyBoard/V0/uploadAccountHistory' },
    // 管材
    uploadPipeMaterial: { dir: 'stickyBoard/V0/uploadPipeMaterial' },
    pipeMaterial: { dir: 'stickyBoard/V0/pipeMaterial' },
    // スマイルデータ
    uploadSmileData: { dir: 'stickyBoard/V0/uploadSmileData' },
    smileData: { dir: 'stickyBoard/V0/smileData' },
    // 入金表
    downloadPayingSheet: { dir: 'stickyBoard/V0/downloadPayingSheet' },
    // カード管理表
    downloadCardManageSheet: { dir: 'stickyBoard/V0/downloadCardManageSheet' },
    // SSリアルタイム売上ランキングデータダウンロード
    downloadRanking: { dir: 'stickyBoard/V0/downloadRanking' },
    // 報酬明細
    // NOTE: API がスペルミスしている。NG: reword OK: reward
    downloadReward: { dir: 'stickyBoard/V0/downloadReword' },
    // 金額確認で contact を扱うやつ
    // https://bitbucket.org/ai-ot/es-login-jwt/src/step3_master/doc/contactAPI.apib
    contact: { dir: 'stickyBoard/V0/contact' }, // 通常のメモを更新
    contactRefund: { dir: 'stickyBoard/V0/contactRefund' }, // 返金のメモを更新
    // 売上調整登録
    adjustmentCandidates: { dir: 'stickyBoard/V0/adjustmentCandidates' },
    adjustmentCandidate: { dir: 'stickyBoard/V0/adjustmentCandidate' },
    // 売上調整承認
    adjustments: { dir: 'stickyBoard/V0/adjustments' },
    updateAdjustments: { dir: 'stickyBoard/V0/updateAdjustments' },
    // クレジットカード
    creditCards: { dir: 'stickyBoard/V0/creditCards' },
    // 工務課
    refreshLiquidationWork: { dir: 'stickyBoard/V0/refreshLiquidationWork' },
    // 契約金額の総額
    contractAmount: { dir: 'stickyBoard/V0/contractAmount' },
    // 勤怠表示のダウンロード
    downloadAttendance: { dir: 'stickyBoard/V0/downloadStaffAbsenceSheet' },
    // 売掛け金回収履歴のキャンセル
    cancelAccountHistory: { dir: 'stickyBoard/V0/cancelAccountHistory' },
    // 訪問率表
    getVisitorStats: { dir: 'stickyBoard/V0/getVisitorStats' },
    // スタッフQRコード一覧
    qrStaffs: { dir: 'stickyBoard/V0/qrStaffs' },
    // スタッフQRコード
    qrStaffCode: { dir: 'stickyBoard/V0/qrCode' },
    // あんしんサポートマスタ
    warrantyCategoryPeriod: { dir: 'stickyBoard/V0/warrantyCategoryPeriod' },
    // 売掛金履歴用の売掛金回収方法マスタ
    historyReceivableCollection: {
      dir: 'stickyBoard/V0/historyReceivableCollection',
    },
    // 全ユーザの取得
    allUsers: { dir: 'stickyBoard/V0/allUsers' },
    // 市町村一覧の取得
    allCities: { dir: 'stickyBoard/V0/allCities' },
    regionInfo: { dir: 'stickyBoard/V0/regionInfo' }, // アサイン課画面のエリアごとの情報
    // 更新履歴
    stickyHistories: { dir: 'stickyBoard/V0/stickyHistories.tsv' },
    contactHistories: { dir: 'stickyBoard/V0/contactHistories.tsv' },
    wishDateTimeHistories: { dir: 'stickyBoard/V0/wishDateTimeHistories.tsv' },
    negotiationHistories: { dir: 'stickyBoard/V0/negotiationHistories.tsv' },
    paymentHistories: { dir: 'stickyBoard/V0/paymentHistories.tsv' },
    accountHistories: { dir: 'stickyBoard/V0/accountHistories.tsv' },
    stickyHouseLaboHistories: {
      dir: 'stickyBoard/V0/stickyHouseLaboHistories.tsv',
    },
    stickyHouseLaboClientHistories: {
      dir: 'stickyBoard/V0/stickyHouseLaboClientHistories.tsv',
    },
    stickyHouseLaboWorkHistories: {
      dir: 'stickyBoard/V0/stickyHouseLaboWorkHistories.tsv',
    },
    stickyHouseLaboFeeHistories: {
      dir: 'stickyBoard/V0/stickyHouseLaboFeeHistories.tsv',
    },
    stickyHouseLaboExtraFeeHistories: {
      dir: 'stickyBoard/V0/stickyHouseLaboExtraFeeHistories.tsv',
    },
    // 交換の達人
    ecFeeList: { dir: 'stickyBoard/V0/ecFeeList' },
    ecOrderList: { dir: 'stickyBoard/V0/ecOrderList' },
    downloadECOrderList: { dir: 'stickyBoard/V0/downloadECOrderList' },
    confirmECFees: { dir: 'stickyBoard/V0/confirmECFees' },
    cancelECFees: { dir: 'stickyBoard/V0/cancelECFees' },
    downloadECReward: { dir: 'stickyBoard/V0/downloadECReward' },

    // 付箋の更新可否判定
    canUpdateSticky: { dir: 'stickyBoard/V0/canUpdateSticky' },
    // 請求書領収書 API
    invoiceAndReceipt: { dir: 'stickyBoard/V0/invoiceAndReceipt' },
    // 顧客の過去実績 API
    contactOrderType: { dir: 'stickyBoard/V0/contactOrderType' },
    // SSの週次売上 API
    staffWeeklySales: { dir: 'stickyBoard/V0/staffWeeklySales' },
    // SSの情報 API
    staffInfo: { dir: 'stickyBoard/V0/staffInfo' },
    // ハウスラボクライアント検索
    houseLaboClient: { dir: 'stickyBoard/V0/houseLaboClient' },
    // 二人工判別の選択肢
    twoPersonWorkType: { dir: 'stickyBoard/V0/twoPersonWorkType' },
    // ハウスラボ追加作業種別
    additionalWorkType: { dir: 'stickyBoard/V0/additionalWorkType' },
    // ハウスラボ 請求書　クライアント一覧取得
    settlementHLClients: { dir: 'stickyBoard/V0/settlementHLClients' },
    // ハウスラボ 請求書　ダウンロード
    downloadHLInvoice: { dir: 'stickyBoard/V0/downloadHLInvoice' },
    // ハウスラボ 請求書　CSV ダウンロード
    downloadHLInvoiceCsv: { dir: 'stickyBoard/V0/downloadHLInvoiceCsv' },
    // ハウスラボ クライアントマスターExcelダウンロード
    downloadHLClientMaster: { dir: 'stickyBoard/V0/downloadHLClientMaster' },
    // ハウスラボ クライアントマスターExcelアップロード
    uploadHLClientMaster: { dir: 'stickyBoard/V0/uploadHLClientMaster' },
    // ハウスラボ完了報告書Excelをダウンロード
    downloadHLWorkReport: { dir: 'stickyBoard/V0/downloadHLWorkReport' },
    // ハウスラボ給与明細Excelをダウンロード
    downloadHouseLaboReword: { dir: 'stickyBoard/V0/downloadHouseLaboReword' },
    // ハウスラボ　未回収ハウスラボクライアント請求一覧取得
    incompleteHLInvoice: { dir: 'stickyBoard/V0/incompleteHLInvoice' },
    // ハウスラボ　回収済ハウスラボクライアント請求一覧取得
    completeHLInvoice: { dir: 'stickyBoard/V0/completeHLInvoice' },
    // ハウスラボクライアント請求回収履歴詳細を取得
    hlInvoiceHistory: { dir: 'stickyBoard/V0/hlInvoiceHistory' },
    // 指定したハウスラボクライアント請求回収履歴を取消
    cancelHLInvoiceHistory: { dir: 'stickyBoard/V0/cancelHLInvoiceHistory' },
    // 未回収ハウスラボクライアント請求のExcelをダウンロード
    downloadHLInvoiceHistory: {
      dir: 'stickyBoard/V0/downloadHLInvoiceHistory',
    },
    // 未回収ハウスラボクライアント請求のExcelをアップロード
    uploadHLInvoiceHistory: { dir: 'stickyBoard/V0/uploadHLInvoiceHistory' },
    // リピートシール、ビフォー、アフターのチェックの集計を取得
    papersStatistics: { dir: 'stickyBoard/V0/papersStatistics' },
    // ホームサーブ（ES提携)クライアントを検索
    esClient: { dir: 'stickyBoard/V0/esClient' },
  },
  IMAGE_API: {
    download: { dir: 'api/download' },
    image: { dir: 'api/image' },
    list: { dir: 'api/list' },
    upload: { dir: 'api/upload' },
    delete: { dir: 'api/delete' },
  },
  EXPO_API: {
    notify: { dir: 'api/notify' }, // 稼働・着手の通知
    list: { dir: 'api/list' }, // 画像の一覧
    upload: { dir: 'api/upload' }, // 画像アップロード
    download: { dir: 'api/download' }, //　画像ダウンロード
    delete: { dir: 'api/delete' }, //　画像削除
    workList: { dir: 'api/workList' }, //　SS稼働状況
    sendMessage: { dir: 'api/sendMessage' }, // メッセージ送信
    messageList: { dir: 'api/messageList' }, // メッセージの一覧
    messageListAll: { dir: 'api/messageListAll' }, // 全メッセージ
    version: { dir: 'api/version' }, // iPad APIバージョン
    // ハウスラボのクライアント向けメールを送信
    sendHLClientMail: { dir: 'api/sendHLClientMail' },
    // ハウスラボのクライアント向けメールの一覧
    sendHLClientMailList: { dir: 'api/sendHLClientMailList' },
  },
  INVENTORY_API: {
    version: { dir: 'api/version' }, // APIバージョン
    query: { dir: 'api/query' }, // GraphQueryのエンドポイント
  },

  // 定数
  constants: {
    LINEBREAK: '\r\n',
    keys: {
      ACCESS_TOKEN_KEY: 'es-stickyboard-access_token',
      USERID_KEY: 'es-stickyboard-userid',
      USERNAME: 'es-stickyboard-userid',
      PASSWORD: 'es-stickyboard-password',
      LS_MASTER_PREFIX: 'es_stickyboard_master',
      LS_CITYCODES_PREFIX: 'es-stickyboard_citycodes',
      VERSION_INFO_KEY: 'PRESERVE__es-stickyboard_previous_version',
    },
    master: {
      keys: [
        'answerTimes',
        'buildingTypes',
        'buildYearsRanks',
        'cancelReasons',
        'cancelReasons2',
        'cancelReasons3',
        'naReasons',
        'companies',
        'creditCards',
        'creditPaymentMethods',
        'customerTypes',
        'freeDial',
        'finishStates',
        'handOver',
        'legalTypes',
        'media',
        'orderRanks',
        'orderTypes',
        'personalTypes',
        'prefCodes',
        'priorityLabels',
        'receivableCollection',
        'regions',
        'stickyTypes',
        'suspendReasons',
        'workKinds',
        'workPoints',
        'workTypes',
        'historyReceivableCollection',
        // 家族構成
        'familyRelationship',
        'ageGroup',
        // 設備
        'facilityType',
        'facilityAge',
        // マルシー（相談室）
        'marucStatus',
        'measures',
        'paperType',
        'adjustmentTypes',
        'adjustmentReasonTypes',
        // 全ユーザ
        'allUsers',
        // 市町村のリスト
        'allCities',
        // マルシー交渉履歴種別
        'marucHandOver',
        // スタッフ一覧
        'allServiceStaffs',
        // マルシー返金方法
        'refundMethods',
        // あんしんサポートマスタ
        'warrantyCategoryPeriod',
        // 二人工判別の選択肢
        'twoPersonWorkType',
        // ハウスラボ追加作業種別
        'additionalWorkType',
        // ハウスラボ締日マスタ
        'settlementDayType',
        // ハウスラボ請求書タイプマスタ
        'hlExportType',
        // ハウスラボ単位タイプマスタ
        'hlUnitType',
        // 交換の達人の金額タイプ
        'ecExtraFeeTypes',
      ],
    },

    // 以下は、lib/sticky-mergeで使われている
    stickyProps: {
      shallowLiteral: [
        'adNumber',
        'buildYearsRankId',
        'cancelReasonId',
        'childId',
        'companyId',
        'completedAt',
        'confirmed',
        'creditCardId',
        'creditPaymentMethodId',
        'customerTypeId',
        'displayDate',
        'displayOrder',
        'documentCompanyId',
        'estimateNumber',
        'estimatePayment',
        'finishStateId',
        // 'id', idはダメです
        'maruCId',
        'mediumId',
        'orderId',
        'orderTypeId',
        'parentId',
        'payment',
        'personalTypeCode',
        'priorityLabelId',
        'receivedAt',
        'regionId',
        'reRequest',
        'staffId',
        'stickyStatusId',
        'stickyTypeId',
        'updateAt',
        'updateUserId',
        'workContent',
      ],
      shallowObject: [
        'determinedDateTime',
        'afterDateTime',
        'buildingType',
        'handOver',
        'suspendReason',
        'thankYouCall',
        'workPoint',
        'workType',
        'marucComplaint',
        'houseLaboClient',
        'houseLabo',
      ],
      arrayedObject: [
        'negotiationRecords',
        'visitedHistories',
        'wishDateTimes',
      ],
      arrayedArrayedLiteral: ['contacts'],
    },
    contactTabHeaders: [
      '訪問先',
      'オーナー',
      '第三者（個人）',
      '第三者（法人）',
    ],
  },

  // 付箋作成の場合分け用enum
  postStickyRequestTypes: {
    CALL: Symbol('POST_STICKY_REQUEST_TYPE.CALL'),
    SPLIT: Symbol('POST_STICKY_REQUEST_TYPE.SPLIT'),
    SPECIAL: Symbol('POST_STICKY_REQUEST_TYPE.SPECIAL'),
    AFTER: Symbol('POST_STICKY_REQUEST_TYPE.AFTER'),
  },

  // googleMapを別ウィンドウで開くときの大きさ
  googleMapWindow: {
    width: 800,
    height: 600,
  },
  // 権限
  roles: {
    superuser: 'superUser',
    orderList: 'call',
    call: 'call',
    assign: 'assign',
    accounting: 'keiri',
    consultation: 'maruc',
    houselabo: 'houselabo',
    hlsolution: 'hlsolution',
    commodity: 'commodity', // 商材管理課
    engineering: 'koumu', // 工務課
    leader: 'leader',
    salesadjustment: ['maruc', 'leader'],
    qrstaff: ['qr', 'maintainer'],
    any: ['call', 'assign'],
    all: '',
  },
  // 付箋の状態
  // search: 注文一覧の検索条件に表示する
  // display: 注文一覧、過去履歴の一覧に表示する
  // dispName: 注文一覧の凡例に表示する時の名前（nameより優先して変えたい時のみ使う）
  // ecOrder: 交換の達人用の注文一覧で条件に使用するもの
  stickyConditions: [
    {
      id: 3,
      search: true,
      display: false,
      name: '当日注文のみ',
      color: 'rgb(199,138,253)',
      ecOrder: true,
    },
    {
      id: 1,
      search: true,
      display: true,
      name: '担当者未連絡',
      color: 'rgb(245, 201, 204)',
      ecOrder: true,
    },
    {
      id: 2,
      search: true,
      display: true,
      name: 'アサイン実行中',
      color: 'rgb(248,205,70)',
      ecOrder: true,
    },
    {
      id: 5,
      search: true,
      display: true,
      name: '作業完了',
      color: 'gray',
      ecOrder: true,
    },
    {
      id: 30,
      search: true,
      display: false,
      name: 'IC',
      color: 'white',
      ecOrder: false,
    },
    {
      id: 31,
      search: true,
      display: false,
      name: 'ICJ',
      color: 'white',
      ecOrder: true,
    },
    {
      id: 19,
      search: true,
      display: false,
      name: 'CNCL',
      color: 'white',
      ecOrder: true,
    },
    {
      id: 8,
      search: true,
      display: true,
      name: '失注',
      color: 'white',
      ecOrder: true,
    },
    {
      id: 32,
      search: true,
      display: false,
      name: '受注対象外(N/A)',
      color: 'white',
      ecOrder: true,
    },
    {
      id: 6,
      search: true,
      display: true,
      name: 'リフォーム引継',
      color: 'rgb(118,213,114)',
      ecOrder: true,
    },
    {
      id: 7,
      search: true,
      display: false,
      name: '中断注文のみ',
      color: 'rgb(151, 57, 153)',
      ecOrder: true,
    },
    {
      id: 12,
      search: true,
      display: false,
      name: 'アフター未定',
      color: 'rgb(151, 57, 153)',
      ecOrder: true,
    },
    {
      id: 13,
      search: true,
      display: true,
      name: '保留のみ',
      dispName: '保留',
      color: 'rgb(246, 163, 244)',
      ecOrder: true,
    },
    {
      id: 28,
      search: true,
      display: false,
      name: '保留（復活なし）',
      color: 'white',
      ecOrder: true,
    },
    {
      id: 14,
      search: true,
      display: false,
      name: '行動管理',
      color: 'white',
      ecOrder: true,
    },
    {
      id: 9,
      search: true,
      display: true,
      name: '要サンキューコール',
      color: 'rgb(118,198,245)',
      ecOrder: true,
    },
    {
      id: 15,
      search: true,
      display: false,
      name: '作業前報告未実施',
      color: 'rgb(255,0,0)',
      ecOrder: true,
    },
    {
      id: 16,
      search: true,
      display: false,
      name: 'メーカーメンテナンス',
      color: 'white',
      ecOrder: true,
    },
    {
      id: 20,
      search: true,
      display: false,
      name: '完了報告書作成状態：未',
      color: 'white',
    },
    {
      id: 21,
      search: true,
      display: false,
      name: '完了報告書作成状態：済',
      color: 'white',
    },
    {
      id: 22,
      search: true,
      display: false,
      name: '完了報告書送付状態：未',
      color: 'white',
    },
    {
      id: 23,
      search: true,
      display: false,
      name: '完了報告書送付状態：済',
      color: 'white',
    },
    {
      id: 24,
      search: true,
      display: false,
      name: '個別請求書発送要',
      color: 'white',
    },
    {
      id: 26,
      search: true,
      display: false,
      name: '個別請求書未発送',
      color: 'white',
    },
    {
      id: 25,
      search: true,
      display: false,
      name: '請求状態未確認',
      color: 'white',
    },
    {
      id: 29,
      search: true,
      display: false,
      name: '旧IC',
      color: 'white',
      ecOrder: false,
    },
    {
      id: 11,
      search: false,
      display: true,
      name: 'アフター',
      color: 'rgb(151, 57, 153)',
      ecOrder: true,
    },
    { id: 17, search: false, display: false, name: 'ICA', color: 'white' },
    { id: 18, search: false, display: false, name: 'ICB', color: 'white' },
    {
      id: 27,
      search: false,
      display: false,
      name: '二次対応予定調整中',
      color: 'white',
    },
    {
      id: 33,
      search: true,
      display: false,
      name: '全データ',
      color: 'white',
      ecOrder: true,
    },
  ],

  // 詳細検索　フリーワードの文字数
  freewordCountFulfiled: 1,

  accountDisplayLimit: 100,

  // 週の始まりの曜日 Sun=0, Mon=1 ...
  startDayOfWeek: 1,

  // マルシー状態の逆引き
  marucStatus: {
    active: 2,
  },

  // SSの稼働状態
  ssworkState: {
    workStart: 1, // 稼働開始
    workEnd: 2, // 稼働終了
    workCancel: 3, // 稼働キャンセル
  },

  // 付箋の更新時、ロック制御するための共通設定
  conflictStatus: 409, // 不整合があった時のHTTPステータス
  conflictError: 'Conflict', // 不整合があった時に throw されるエラー
  conflictMessage:
    '付箋が他で更新されました。付箋画面を閉じた後、画面をリロードして再度編集してください。',

  // 過去日を設定する時の警告メッセージ
  beforeDayWaring: '過去日付を設定してもいいですか。',

  // 返金方法：特殊な扱いをするものだけ定義しておく。
  refundMethodId: {
    none: -999, // 集計対象外
    accountRemain: -1, // 売掛金返金
    accountRecut: -2, // 売掛金切り直し
    furikomi: 2, // 振込
    kakitome: 3, // 現金書留
  },

  // ハウスラボ金額項目の入力タイプ
  houseLaboFeeInputType: {
    payment: 1, // 金額
    distance: 2, // 距離
    range: 3, // 区間
    dispatch: 4, // 出動
    basic: 5, // 基本
    trip: 6, // 出張費
    fardistance: 7, // 遠方費
    clientIntro: 8, // クライアント紹介料,
    sceneIntro: 9, // 現場紹介料
    dispatchCancel: 10, // 出動後キャンセル
    string: 11, // 文字列
    granBasic: 12, // グラン向けの基本
    granMulti: 13, // グラン向けの複数個所加算
  },

  // ハウスラボ　クライアント紹介料・現場紹介料の対象区分
  houseLaboFeeTarget: {
    type1: 1,
    type2: 2,
    type3: 3,
    type4: 4,
  },

  // 二人工付箋の種類
  houseLaboTwoWorkType: {
    tukan: 2,
    normal: 4,
    camera: 5,
    other: 99,
  },

  // 請求書タイプ
  houseLaboExportType: {
    excel: 1,
    csv: 2,
    none: 99,
  },

  // ES提携金額項目の入力タイプ
  homeServeFeeInputType: {
    dispatch: 1, // 出動費
    holiday: 2, // 日・祝料金
    fardistance: 3, // 遠方費
    payment: 4, // ただの金額
  },
}

export const maruCStatusIdToStyleProps = {
  3: {
    // name: '担当者未連絡',
    color: 'rgb(248,205,70)',
    borderColor: 'gray',
  },
  4: {
    // name: '対応日未定',
    color: 'rgb(236,77,61)',
    borderColor: 'gray',
  },
  5: {
    // name: '対応方法未定',
    color: 'rgb(255,255,255)',
    borderColor: 'gray',
  },
  6: {
    // name: '対応方法未定(当日)',
    color: 'rgb(255, 156, 0)',
    borderColor: 'gray',
  },
  7: {
    // name: '対応予定日超過',
    color: 'rgb(116, 0, 217)',
    borderColor: 'gray',
  },
  8: {
    // name: 対応方法決定
    color: 'green',
    borderColor: 'gray',
  },
  9: {
    // name: '対応中',
    color: 'rgb(255,255,255)',
    borderColor: 'gray',
  },
  10: {
    // name: '対応完了',
    color: 'rgb(96, 187, 255)',
    borderColor: 'gray',
  },
  11: {
    // name: '訪問希望日未定',
    color: 'magenta',
    borderColor: 'gray',
  },
}

export const maxFileSize = {
  size: 15 * 1024 * 1024,
  name: '15MB',
}

export const mimes = {
  excel: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  image: ['image/jpeg'],
  pdf: ['application/pdf'],
  csv: ['text/csv'],
}

export const freewordTypes = [
  {
    id: 'isNegotiationRecord',
    name: '交渉履歴',
  },
  {
    id: 'isWorkContent',
    name: '依頼内容',
  },
]
