import React from 'react'
import PropTypes from 'prop-types'
import connect from './props'

// library
import requestPutSticky from 'src/lib/sticky-api/put'
import requestPutPayment from 'src/lib/payments-api/put'
import requestDeleteSticky from 'src/lib/sticky-api/delete'
import requestCanUpdateSticky from 'src/lib/sticky-api/canupdate'
import Sticky from 'src/lib/class-sticky'
import requestGetSticky from 'src/lib/sticky-api/get'
import requestPostSticky from 'src/lib/sticky-api/post'
import config from 'src/config'
import { today } from 'src/lib/moment'
import { isValidAccount } from 'src/lib/validate'
import findStickyById from '../../../../../lib/find-sticky-by-id'

/**
 * 付箋編集画面のボタンを包むやつ
 * @type {fuction}
 */
export class PaymentStickyModalContentButtons extends React.Component {
  /**
   * Validation
   * @type {object}
   */
  static propTypes = {
    // ownProps
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    // stateProps
    wara: PropTypes.object.isRequired,
    addSticky: PropTypes.func.isRequired,
    updateSticky: PropTypes.func.isRequired,
    staged: PropTypes.instanceOf(Sticky).isRequired,
    accessToken: PropTypes.string.isRequired,
    env: PropTypes.object.isRequired,
    storedPayments: PropTypes.any.isRequired,
    // dispatchprops
    closeModal: PropTypes.func.isRequired,
    deleteStickyById: PropTypes.func.isRequired,
    updateEditSticky: PropTypes.func.isRequired,
    storePayments: PropTypes.func.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    isDebugMode: false,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate() {
    return true
  }

  /**
   * Update button click handler
   * @return {void}
   */
  onEditHistoryClick = async () => {
    const {
      // ownProps
      sticky,
      // stateProps
      wara,
      addSticky,
      updateSticky,
      staged,
      accessToken,
      env,
      storedPayments,
      // dispatchProps
      closeModal,
      deleteStickyById,
      updateEditSticky,
      storePayments,
    } = this.props
    const { location } = findStickyById(wara, sticky.id) // find target sticky's location

    try {
      await requestCanUpdateSticky(
        sticky.id,
        sticky._props.updateAt,
        accessToken,
        env,
      )
    } catch (e) {
      console.error(e)
      if (e === config.conflictError) {
        alert(config.conflictMessage)
      } else {
        alert(e)
      }
      return
    }

    //----------------------------------------------------------------
    // 集金付箋の金額を更新する
    //----------------------------------------------------------------
    // 全ての支払情報
    const allPayments = (storedPayments[sticky.id] || [])
      .filter(p => p)
      // stickyIdをくっつける。
      .map(payment => ({
        ...payment,
        data: {
          ...payment.data,
          stickyId: sticky.id,
        },
      }))

    let putPayments
    if (allPayments.length > 0) {
      try {
        putPayments = await requestPutPayment(allPayments, accessToken, env)
      } catch (e) {
        console.error(e)
        if (e === config.conflictError) {
          alert(config.conflictMessage)
        } else {
          alert('支払情報の更新に失敗しました')
        }
        return
      }
    }

    let nextSticky = staged.update({
      id: sticky.id,
      updateAt: sticky._props.updateAt,
    })
    let nextStickyProps = null

    try {
      nextStickyProps = await requestPutSticky(nextSticky, accessToken, env)
      updateSticky(
        new Sticky(nextStickyProps, { normalize: false }).update({
          finishStateId:
            nextStickyProps.finishStateId === null
              ? -1
              : nextStickyProps.finishStateId,
        }),
        location,
      )
    } catch (e) {
      console.error(e)
      if (e === config.conflictError) {
        alert(config.conflictMessage)
      } else {
        alert('完了状態の更新に失敗しました')
      }
      return
    }

    //----------------------------------------------------------------
    // 次回の回収方法が「集金」で残金があるとき、子集金付箋を作成する
    //----------------------------------------------------------------
    let orgsticky
    try {
      orgsticky = await requestGetSticky(sticky.id, accessToken, env)
    } catch (e) {
      console.error(e)
      return
    }
    // 複数の売掛金にも対応できる書き方だが、基本は売掛け金は一つだけ。
    // 次回の回収方法が「集金」で、残金があるとき
    const collectPayments = putPayments.filter(
      payment =>
        payment.data.accountCollectionMethodId ===
          config.howToRetrieve.collect &&
        payment.data.accountValue - (payment.data.accountCollectedValue || 0) >
          0,
    )
    let paymentDisplay = (orgsticky._props.displayOrder || 0) + 1
    let collectStickiesResults
    const removingCollectStickyIds = []
    // 新規に作成した集金付箋の一覧
    const payments = []
    try {
      collectStickiesResults = await Promise.all(
        collectPayments.map(payment => {
          // accountsの回収方法を「入金」、入金日をクリア、contactsの金額に残金を設定する。
          orgsticky._props.contacts.forEach(contactn => {
            contactn.forEach(contact => {
              contact.paymentInfo.stickyContactId = null
              contact.paymentInfo.stickyId = null
              contact.paymentInfo.payment = []
              contact.paymentInfo.accounts.forEach(account => {
                account.accountCollectionMethodId = config.howToRetrieve.collect
                account.accountDate = null
                account.accountValue =
                  payment.data.accountValue - payment.data.accountCollectedValue
                account.accountCollectedValue = null
              })
            })
          })
          const collectProps = {
            parentId: sticky.id,
            parentAccountId: payment.data.id,
            userField: JSON.stringify({
              collectStickyOf: sticky.id,
              collectPaymentOf: payment.data.id,
            }),
            stickyTypeId: config.stickyType.payment,
            isAfter: 'false',
            stickyStatusId: 0,
            regionId: orgsticky._props.regionId,
            staffId: orgsticky._props.staffId,
            displayDate: payment.data.accountDate,
            displayOrder: paymentDisplay++, // orderは最後にする
            contacts: orgsticky._props.contacts,
            companyId: orgsticky._props.companyId, // 親の会社名を引き継ぐ
            // 集金付箋の重要申し送りにいつの集金か記載する
            handOver: {
              freeDescribe:
                sticky._props.displayDate.substring(5, 7) +
                '月' +
                sticky._props.displayDate.substring(8, 10) +
                '日分集金',
            },
          }
          console.log(collectProps)
          return requestPostSticky(
            new Sticky(collectProps, { normalize: false }),
            accessToken,
            env,
          )
        }),
      )
      // リクエスト後にビューに集金付箋を配置
      const displayDate = orgsticky._props.displayDate
      collectStickiesResults.forEach((sticky, i) => {
        const stickObj = new Sticky(sticky, { normalize: false })
        payments.push(stickObj.id)
        if (
          // 現在の日付に表示する場合
          stickObj.json().displayDate === displayDate
        ) {
          addSticky(
            {
              ...location,
              stickyIndex: location.stickyIndex + i + 1,
            },
            stickObj,
          )
        } else if (
          displayDate === today() && // TODO これおかしい気がする。today は関連しないと思う
          stickObj.json().displayDate !== displayDate
        ) {
          // 日付を当日から変更
          removingCollectStickyIds.push(stickObj.id)
        }
      })
    } catch (e) {
      console.error(e)
      alert('集金付箋の作成に失敗しました')
      return
    }
    // 親付箋に集金付箋の一覧を登録する。
    // 親付箋の更新日時：orgsticky=集金付箋を登録する前の日時、nextStickyProps:マルシーリピートを完了した日時
    // マルシーリピートを完了し、かつ売掛金を登録した時のみnextStickyPropsが使われるはず。
    const updateAt =
      nextStickyProps === null
        ? orgsticky._props.updateAt
        : nextStickyProps.updateAt
    if (payments.length > 0) {
      const diffSticky = new Sticky({
        id: orgsticky.id,
        updateAt,
      })
      const ps = diffSticky.setUserValue('payments', payments)
      try {
        nextStickyProps = await requestPutSticky(ps, accessToken, env)
      } catch (e) {
        console.error(e)
        if (e === config.conflictError) {
          alert(config.conflictMessage)
        } else {
          alert('付箋の更新に失敗しました')
        }
        return
      }
    }
    // 上で更新した親付箋で編集中の付箋を置き換える。同時に差分をクリアする。
    const diffUpdateDateProps = {
      id: orgsticky.id,
    }
    updateEditSticky(
      new Sticky(nextStickyProps === null ? orgsticky.json() : nextStickyProps),
      new Sticky(diffUpdateDateProps),
    )
    // 金額の差分をクリアする
    storePayments(sticky.id, [])

    // 前回作成された集金付箋を削除する。
    putPayments.forEach(payment => {
      if (
        payment.paymentType === 'accounts' &&
        (payment.data.childStickyId || -1) !== -1
      ) {
        const paymentStickyId = payment.data.childStickyId
        deleteStickyById(paymentStickyId)
        requestDeleteSticky(paymentStickyId, accessToken, env)
          .then(() => {})
          .catch(err => {
            console.log(err)
          })
      }
    })

    removingCollectStickyIds.forEach(removingCollectStickyId =>
      deleteStickyById(removingCollectStickyId),
    )

    // モーダルを閉じる
    closeModal()
  }

  /**
   * 集金付箋の売掛金情報にエラーがないかチェックする
   */
  validAccount = () => {
    const { sticky, storedPayments } = this.props
    let valid = true
    const allPayments = (storedPayments[sticky.id] || []).filter(p => p)
    if (allPayments.length > 0) {
      // 更新したpaymentsの内容をチェックする
      // 実際はaccountが1つしかないはずだが、複数になった時も対応できるようにしておく。
      for (let i = 0; i < allPayments.length; i++) {
        const payment = allPayments[i]
        if (payment.paymentType === 'accounts') {
          const { result } = isValidAccount(payment, true)
          if (!result) {
            valid = false
            break
          }
        }
      }
    } else {
      // 元付箋のcontactsの内容をチェックする
      const contacts = sticky._props.contacts || []
      for (let i = 0; i < contacts.length; i++) {
        const tabcontacts = contacts[i] || []
        for (let j = 0; j < tabcontacts.length; j++) {
          const contact = tabcontacts[j] || {}
          const paymentInfo = contact.paymentInfo || {}
          const accounts = paymentInfo.accounts || []
          for (let k = 0; k < accounts.length; k++) {
            const account = accounts[k]
            const { result } = isValidAccount({ data: account }, true)
            if (!result) {
              valid = false
              break
            }
          }
          if (!valid) {
            break
          }
        }
        if (!valid) {
          break
        }
      }
    }
    return valid
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const isValid = this.validAccount()
    return (
      <div className={ 'modal-buttons-below' }>
        <button
          className={ 'button button-close' }
          onClick={ this.onEditHistoryClick }
          disabled={ !isValid }
        >
          {'変更して閉じる'}
        </button>
      </div>
    )
  }
}

export default connect(PaymentStickyModalContentButtons)
