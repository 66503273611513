import React from 'react'
import PropTypes from 'prop-types'
import connect from '../../../../connect'
import Sticky from 'src/lib/class-sticky'
import findContact from '../../../lib/find-contact'
import config from 'src/config'
import { contactInputStyle } from 'src/lib/format'

// components
import Search from './partials/search'

/**
 * 電話番号を入力します
 * @type {ReactComponent}
 */
export class PhoneNumber extends React.Component {
  /**
   * validation
   * @param  {string}  value validating phone number
   * @return {boolean}       result
   */
  static isValid = value => value === false || /^([0-9-])*$/.test(value)

  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    tabIndex: PropTypes.number.isRequired,
    contactsIndex: PropTypes.number.isRequired,
    route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
    disabled: PropTypes.bool.isRequired,
    searchDisabled: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
    tabOrder: PropTypes.number,
    isDelete: PropTypes.bool,
    // dispatchProps
    updateStage: PropTypes.func.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    closeModal: () => {},
    tabOrder: 0,
    isDelete: false,
  }

  state = {
    onEditing: false,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.sticky.json().contacts !== nextProps.sticky.json().contacts ||
      nextState.onEditing !== this.state.onEditing ||
      this.props.disabled !== nextProps.disabled ||
      this.props.readOnly !== nextProps.readOnly
    )
  }

  /**
   * フォーカスした時のハンドラ
   * 編集開始フラグを立てる
   * @return {vold}
   */
  onFocus = () => {
    const { sticky, tabIndex, contactsIndex } = this.props
    const { phoneNumber = '' } =
      findContact(sticky, tabIndex, contactsIndex) || {}
    this.setState({ onEditing: phoneNumber })
  }

  /**
   * onChange handler
   * @param  {Event} e onChange event
   * @return {void}
   */
  onChange = e => {
    const phoneNumber = e.target.value
    const canonicalPhoneNumber = this.normalize(phoneNumber)
    const { sticky, tabIndex, contactsIndex, updateStage } = this.props

    const id = sticky.getContactId(tabIndex, contactsIndex)
    const existsClient = sticky.existsClient()

    const diffProps = {
      contacts: {
        [tabIndex]: {
          [contactsIndex]: existsClient
            ? { id, phoneNumber, canonicalPhoneNumber }
            : { id, phoneNumber, canonicalPhoneNumber, isClient: true },
        },
      },
    }
    updateStage(new Sticky(diffProps), { normalize: false })
    this.setState({ onEditing: e.target.value })
  }

  /**
   * onChange handler
   * @param  {Event} e onChange event
   * @return {void}
   */
  onBlur = () => {
    // リセット
    this.setState({ onEditing: false })
  }

  /**
   * normalization
   * ハイフンを除外する
   * @param  {string} phoneNo [description]
   * @return {string}         [description]
   */
  normalize = phoneNo => phoneNo.split('-').join('')

  /**
   * [groupName description]
   * @return {string} [description]
   */
  get groupName() {
    return `contact-phone-number-${this.props.tabIndex}-${this.props.contactsIndex}-${this.props.route}`
  }

  renderInput = displayPhoneNumber => {
    const { route, disabled, readOnly, tabOrder, isDelete } = this.props
    const identifier = 'id-' + this.groupName
    return (
      <input
        className={
          (route === 'history' ? '' : 'shorter') +
          (PhoneNumber.isValid(displayPhoneNumber)
            ? 'input-valid'
            : 'input-invalid')
        }
        value={ displayPhoneNumber }
        id={ identifier }
        name={ this.groupName }
        type={ 'text' }
        onFocus={ this.onFocus }
        onChange={ this.onChange }
        onBlur={ this.onBlur }
        disabled={ disabled }
        readOnly={ readOnly }
        style={ contactInputStyle({ disabled, readOnly, isDelete }) }
        tabIndex={ tabOrder }
      />
    )
  }

  renderButton = (displayPhoneNumber, disabled) => {
    const { route, searchDisabled, tabOrder } = this.props

    return route === 'history' ? null : (
      <Search
        route={ route }
        disabled={ disabled || searchDisabled }
        phoneNo={ displayPhoneNumber }
        tabOrder={ tabOrder + 1 }
      />
    )
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { onEditing } = this.state
    const { sticky, tabIndex, contactsIndex, route } = this.props

    const { phoneNumber } = findContact(sticky, tabIndex, contactsIndex) || {}

    const identifier = 'id-' + this.groupName

    const displayPhoneNumber =
      onEditing === false ? phoneNumber || '' : onEditing

    const disabledSearchButton = !phoneNumber && !onEditing
    const label =
      sticky._props.companyId === config.company.ES
        ? '電話番号'
        : '現地電話番号'

    return (
      <dl
        className={ 'label-inputs-wrap' }
        style={ {
          display: 'grid',
          gridTemplateColumns: '5.5rem 1fr',
          columnGap: '0.5rem',
        } }
      >
        <dt>
          <label htmlFor={ identifier }>{'第1電話番号'}</label>
        </dt>

        <dd className={ 'input-wrap' }>
          {route === 'history' ? (
            this.renderInput(displayPhoneNumber)
          ) : (
            <div className={ 'flex' }>
              {this.renderInput(displayPhoneNumber)}
              {this.renderButton(displayPhoneNumber, disabledSearchButton)}
            </div>
          )}
        </dd>
      </dl>
    )
  }
}

export default connect(PhoneNumber)
