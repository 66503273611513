/**
 * metaプロパティ直下に
 * [NETWORKER]: { target<string>, networkActionType<symbol> }
 * というキーバリューを保持させることで、netrork-monitor ミドルウェアが発火します
 * @type {symbol}
 */
export const NETWORKER = Symbol('is networker')

/**
 * ネットワーク状態の変更通知をdispatchから受け取り、これをstoreに反映するアクションをディスパッチします
 * @param  {object} store redux store
 * @return {function} callback typed (next: function) => (action: object) => void
 */
export const networkMonitor = store => next => action => {
  next(action)
  if (action.meta && action.meta[NETWORKER]) {
    const { target, networkActionType } = action.meta[NETWORKER]
    // ネットワーク状態を更新する
    if (networkActionType) {
      store.dispatch({ type: networkActionType, payload: { target } })
    }
  }
}

export default networkMonitor
