import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import connect from './connect'
import Sticky from 'src/lib/class-sticky'
import styled from 'styled-components'
import { baseColor, darkBaseColor } from 'src/colors'
import { toJPYenText } from 'src/lib/format'
import getPayments from 'src/lib/payments-api/get'

import config from 'src/config'
import { borderDarkGray } from 'src/colors'
import moment from 'moment'
import { getFullAddress } from 'src/lib/search'

const { stickyConditions } = config

export const ColorMark = styled.div`
  &::before {
    content: '';
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: ${props => props.color};
    filter: drop-shadow(0 0 2px #616161);
    margin: 10px;
  }
`

const SectionsWrap = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${props => props.backgroundColor};
`

const Section = styled.div`
  width: 20%;
  padding: 2px;
  border-left: 1px solid ${borderDarkGray};

  & p {
    margin-top: 0.2em;
    margin-bottom: 0.2em;
    margin-left: 0.2em;
    margin-right: 0.05em;
  }
`

const Header = styled.span`
  display: inline-block;
  color: ${baseColor};
  padding-right: 10px;
  margin-bottom: ${props => props.marginBottom || 0};
`
// メモを開くボタン
const DetailButton = styled.button`
  border-radius: 3px;
  background-color: transparent;
  border: 1px solid ${baseColor};
  padding: 4px 5px 3px;
  margin: 0;
  color: ${baseColor};
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: ${darkBaseColor};
    border: 1px solid ${darkBaseColor};
  }
`

// eslint-disable-next-line react/require-optimization
export const StickyContent = ({
  sticky,
  master,
  conditionIds,
  openModal,
  accessToken,
  env,
  staffs,
}) => {
  const [accounts, setAccounts] = useState(0)
  const [payments, setPayments] = useState(0) // 現金の支払い
  const [hsPayments, setHsPayments] = useState(0) // ES提携（ホームサーブでの支払い）

  useEffect(() => {
    // 付箋自体が変わった、または同じ付箋が更新された時は金額を再取得する
    getStickyPayments()
  }, [sticky])

  /**
   * 付箋の金額を取得する
   */
  const getStickyPayments = () => {
    // 完了の付箋以外では金額情報を取得しないようにする（2019/11の対応）。
    if (sticky._props.finishStateId === config.finishState.complete) {
      // 付箋の支払情報を取得し、金額情報を更新する。
      getPayments(sticky.stickyContactIds, accessToken, env)
        .then(allPayments => {
          let totalAccounts = 0
          let totalPayments = 0
          let totalHsPayments = 0
          allPayments.forEach(payment => {
            if (payment.paymentType === 'accounts') {
              totalAccounts += payment.data.accountValue
              // totalDirectIncome += payment.data.accountValue
            } else if (payment.paymentType === 'payments') {
              if (payment.data.creditCardId === 9) {
                // creditCardId: 9 = ホームサーブがHsPaymentsに入ります
                totalHsPayments += payment.data.cashValue
              } else {
                totalPayments += payment.data.cashValue
              }
            }
          })
          setAccounts(totalAccounts)
          setPayments(totalPayments)
          setHsPayments(totalHsPayments)
        })
        .catch(err => {
          console.error(err)
        })
    }
  }

  /**
   * 日時のフォーマット共通
   * @param {*} date
   */
  const dateFormat = date => {
    const m = moment(date)
    const dateString = m.isValid() ? m.format('YYYY-MM-DD HH:mm:ss') : ''
    return dateString
  }

  const {
    id: stickyId,
    mediumId,
    receivedAt,
    completedAt,
    determinedDateTime,
    regionId,
    workType,
    workPoint,
    staffId: stickyStaffId,
    orderTypeId,
    handOver,
    companyId,
    finishStateId,
    cancelReasonId,
    houseLaboClientMaster,
    houseLaboClient,
    houseLabo,
    buildingType,
    customerTypeId,
  } = sticky.json()

  const isHouseLabo = companyId === config.company.HL
  const isHS = companyId === 4 // ES提携ならtrue

  const client = sticky.findClient() || {}
  const {
    prefName,
    cityName,
    address,
    buildingName,
    name,
    phoneNumber,
  } = client

  const mediumName =
    (master.media.find(({ id }) => id === mediumId) || {}).name || ''

  const regionName =
    (master.regions.find(({ id }) => id === regionId) || {}).name || ''

  const fullAddress = getFullAddress(prefName, cityName, address, buildingName)

  /**
   * 条件一覧を色の一覧に変換
   */
  const colors = conditionIds.map(cid => {
    return (
      stickyConditions.find(condition => condition.id === cid) || {
        color: 'transparent',
      }
    ).color
  })

  /**
   * 条件の一覧→色の一覧→マークの一覧に変換する。
   */
  const conditions = (
    <div>
      {colors.map((color, idx) => {
        return <ColorMark key={ idx } color={ color } />
      })}
    </div>
  )

  const workTypes = workType.ids
    .map(id => {
      return (master.workTypes.find(wk => wk.id === id) || {}).name || ''
    })
    .join()

  const workPoints = workPoint.ids
    .map(id => {
      return (master.workPoints.find(wk => wk.id === id) || {}).name || ''
    })
    .join()

  const staffName = () => {
    if (!stickyStaffId) {
      return ''
    }
    const name =
      (staffs.find(({ staffId }) => staffId === stickyStaffId) || {})
        .staffVerboseName || ''
    return name
  }

  // 金額
  const paymentsval = payments ? toJPYenText(payments) : '(なし)'

  const accountval = accounts ? toJPYenText(accounts) : '(なし)'

  const hsPaymentsVal = hsPayments ? toJPYenText(hsPayments) : '(なし)'

  const orderType =
    (master.orderTypes.find(order => order.id === orderTypeId) || {}).name || ''

  const handDescribe = (handOver ? handOver.freeDescribe : '') || ''

  // 確定作業日時のフォーマット
  const determinedDateTimeElem = () => {
    const determinedDate = moment((determinedDateTime || {}).date)
    const determinedDateStr = determinedDate.isValid()
      ? determinedDate.format('MM-DD')
      : ''
    const determiendStartAt = (determinedDateTime || {}).startAt || ''
    const determiendFinishAt = (determinedDateTime || {}).finishAt || ''
    const answerTimeId = (determinedDateTime || {}).answerTimeId
    const strict = !!(determinedDateTime || {}).strict
    const style = strict ? { color: 'red', fontWeight: 'bold' } : {}
    let answerString = ''
    if (answerTimeId && answerTimeId > 0) {
      answerString =
        (master.answerTimes.find(x => x.id === answerTimeId) || {}).name || ''
    }
    return (
      <span>
        <span>{determinedDateStr}</span>
        <span
          style={ { ...style, marginLeft: '8px' } }
        >{`${determiendStartAt}〜${determiendFinishAt}`}</span>
        <span style={ { marginLeft: '8px' } }>{answerString}</span>
      </span>
    )
  }

  // 建物情報
  const buildingTypeName = (() => {
    const id = ((buildingType || {}).ids || [-1])[0]
    const name = (master.buildingTypes.find(b => b.id === id) || {}).name || ''
    const free = (buildingType || {}).freeDescribe || ''
    return free ? `${name}(${free})` : name
  })()

  // 顧客タイプ
  const customerTypeName =
    (master.customerTypes.find(c => c.id === customerTypeId) || {}).name || ''

  // ハウスラボクライアント名
  const houseLaboClientName = () => {
    if (isHouseLabo) {
      const clientName = (houseLaboClientMaster || {}).name || ''
      return (
        <p>
          <Header>{'クライアント名'}</Header>
          <span>{clientName}</span>
        </p>
      )
    } else {
      return null
    }
  }

  // ハウスラボ受付番号
  const houseLaboReceiptNumber = () => {
    if (isHouseLabo) {
      const receiptNumber = (houseLaboClient || {}).receiptNumber || ''
      return (
        <p>
          <Header>{'受付番号'}</Header>
          <span>{receiptNumber}</span>
        </p>
      )
    } else {
      return null
    }
  }

  // ハウスラボクライアント請求
  const houseLaboClientTotal = () => {
    if (isHouseLabo) {
      const clientTotal = (houseLabo || {}).invoice || 0
      return (
        <p>
          <Header>{'クライアント請求'}</Header>
          <span>{clientTotal ? toJPYenText(clientTotal) : 'なし'}</span>
        </p>
      )
    } else {
      return null
    }
  }

  /**
   * 終了状態
   */
  const finishState = () => {
    if (finishStateId === config.finishState.cancel) {
      const completedAt = sticky.json().completedAt
      // 2023年4月1日からICA/ICBを廃止して、ICを利用
      const isCancelReason2 = completedAt >= '2023-04-01'
      // 2024年4月1日からIC/ICJを利用
      const isCancelReason3 = completedAt >= '2024-04-01'

      const { cancelReasons, cancelReasons2, cancelReasons3 } = master
      const foundCancelReason = (isCancelReason3
        ? cancelReasons3
        : isCancelReason2
          ? cancelReasons2
          : cancelReasons
      ).find(cancelReason => cancelReason.id === cancelReasonId)
      const ICName = foundCancelReason?.nickname || ''

      return ICName
    }
    // キャンセル以外の時は終了状態名を返す
    const finishStateName =
      (
        (master.finishStates || []).find(
          finish => finish.id === finishStateId,
        ) || {}
      ).name || '未完了'
    return finishStateName
  }

  // 受付会社（のみ）で背景色を変える
  const stickyBackGround = sticky.stickyBackGroundColor(false)

  return (
    <SectionsWrap backgroundColor={ stickyBackGround }>
      {conditions}
      <Section style={ { width: '14%' } }>
        <p>{mediumName}</p>
        <p>{regionName}</p>
        <p>
          <Header>{'受'}</Header>
          <span>{dateFormat(receivedAt)}</span>
        </p>
        <p>
          <Header>{'完'}</Header>
          <span>{dateFormat(completedAt)}</span>
        </p>
      </Section>
      <Section style={ { width: '32%' } }>
        <p>
          <Header>{'住所'}</Header>
          <span>{fullAddress}</span>
        </p>
        {houseLaboClientName()}
        <p>
          <Header>{'氏名'}</Header>
          <span>{name}</span>
        </p>
        <p>
          <Header>{'電話番号'}</Header>
          <span>{phoneNumber}</span>
        </p>
      </Section>
      <Section style={ { width: '22%' } }>
        <p>
          <Header>{'現況'}</Header>
          <span>{workTypes}</span>
        </p>
        <p>
          <Header>{'箇所'}</Header>
          <span>{workPoints}</span>
        </p>
        <p>
          <Header>{'建物情報'}</Header>
          <span>{buildingTypeName}</span>
          <span style={ { paddingLeft: '0.5rem' } } />
          <Header>{'顧客タイプ'}</Header>
          <span>{customerTypeName}</span>
        </p>
        <p>
          <Header>{'確定作業日時'}</Header>
          {determinedDateTimeElem()}
        </p>
        <p>
          <Header>{'重要申送'}</Header>
          <span>{handDescribe}</span>
        </p>
        {houseLaboReceiptNumber()}
      </Section>
      <Section style={ { width: '12%' } }>
        <p>
          <Header>{'担当者'}</Header>
          <span>{staffName()}</span>
        </p>
        <DetailButton onClick={ () => openModal(stickyId) }>
          {'メモを開く'}
        </DetailButton>
      </Section>
      <Section style={ { width: '16%' } }>
        {houseLaboClientTotal()}
        <p>
          <Header>{'現金(内金)'}</Header>
          <span>{paymentsval}</span>
        </p>
        <p>
          <Header>{'売掛金'}</Header>
          <span>{accountval}</span>
        </p>
        {isHS && (
          <p>
            <Header>{'HS'}</Header>
            <span>{hsPaymentsVal}</span>
          </p>
        )}
        <p>
          <Header>{'注文タイプ'}</Header>
          <span>{orderType}</span>
        </p>
        <p>
          <Header>{'終了状態'}</Header>
          <span>{finishState()}</span>
        </p>
      </Section>
    </SectionsWrap>
  )
}

/**
 * propTypes
 * @type {object}
 */
StickyContent.propTypes = {
  sticky: PropTypes.instanceOf(Sticky).isRequired,
  conditionIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  openModal: PropTypes.func.isRequired,
  accessToken: PropTypes.string.isRequired,
  env: PropTypes.object.isRequired,
  master: PropTypes.shape({
    media: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
      }),
    ).isRequired,
    regions: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
      }),
    ).isRequired,
    prefCodes: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    workTypes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    workPoints: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    orderTypes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    answerTimes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
    buildingTypes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
    customerTypes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
    finishStates: PropTypes.string,
    cancelReasons: PropTypes.object,
    cancelReasons2: PropTypes.object,
    cancelReasons3: PropTypes.object,
  }).isRequired,
  staffs: PropTypes.arrayOf(
    PropTypes.shape({
      staffId: PropTypes.number.isRequired,
      staffName: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default connect(StickyContent)
