// ライブラリ
import React from 'react'
import PropTypes from 'prop-types'
import connect from '../../connect'
import styled from 'styled-components'
import Sticky from 'src/lib/class-sticky'
import findContact from '../lib/find-contact'

// コンポーネント
import ContactType from './partials/contact-type'
import LegalType from './partials/legal-type'
import Name from './partials/name'
import Ruby from './partials/ruby'
import PhoneNumber from './partials/phone-number'
import Zip from './partials/zip'
import PrefCode from './partials/pref-code'
import CityCode from './partials/city-code'
import Address from './partials/address'
import Building from './partials/building'
import PhoneNumberX from './partials/phone-number-x'
import IsPayer from './partials/is-payer'
import IsClient from './partials/is-client'
import IsObserver from './partials/is-observer'
import DmForbidden from './partials/dm-forbidden'
import FreeDescribe from './partials/free-describe'
// import IsResponsible from './partials/is-responsible'
import DeleteButton from '../delete-button'
import Checkbox from 'src/styled/form/checkbox'
import { baseColor } from 'src/colors'

/**
 * 連絡先を入力します
 * @type {ReactComponent}
 */
export class SingularContact extends React.Component {
  /**
   * Validation
   * @type {object}
   */
  static propTypes = {
    update: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    contact: PropTypes.object,
    contacts: PropTypes.arrayOf(PropTypes.array),
    tabIndex: PropTypes.number.isRequired,
    contactsIndex: PropTypes.number.isRequired,
    isGhost: PropTypes.bool,
    isClaimer: PropTypes.bool,
    route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
    disabled: PropTypes.bool.isRequired,
    searchDisabled: PropTypes.bool.isRequired,
    isDebugMode: PropTypes.bool,
    env: PropTypes.object.isRequired,
    readOnly: PropTypes.bool.isRequired,
    draggable: PropTypes.bool.isRequired,
    closeModal: PropTypes.func,
    tabOrderStart: PropTypes.number.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    update: false,
    contact: {},
    contacts: [],
    isGhost: false,
    isClaimer: false,
    isDebugMode: false,
    closeModal: () => {},
  }

  state = {
    checkedReadOnly: this.props.readOnly, // チェックボックスで変更可能な読み取り専用属性
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.contact !== nextProps.contact ||
      this.props.sticky.json().regionId !== nextProps.sticky.json().regionId ||
      this.props.disabled !== nextProps.disabled ||
      this.state.checkedReadOnly !== nextState.checkedReadOnly
    )
  }

  componentWillUnmount() {
    window.addEventListener('resize', this.checkWidth)
  }

  onChangeReadOnly = e => {
    this.setState({ checkedReadOnly: !e.target.checked })
  }

  /**
   * render
   * @return {ReactDOM} rendered result
   */
  render() {
    const {
      update,
      sticky,
      tabIndex,
      contactsIndex,
      isGhost,
      isClaimer,
      isDebugMode,
      route,
      disabled,
      searchDisabled,
      env: { ES_STEP },
      readOnly,
      closeModal,
      tabOrderStart,
    } = this.props
    const { checkedReadOnly } = this.state
    const contact = findContact(sticky, tabIndex, contactsIndex) || {}

    /**
     * デバッグ用のテキストを書き出す
     * @type {ReactComponent}
     */
    const DebugDisplay = styled.span`
      background: gray;
      color: white;
      padding: 3px 5px;
    `

    /**
     * contactが削除できないかを判定する。
     * ・居住者の1番目は削除できない。
     * ・対象のcontactに現金、売掛金が登録済みの時true、それ以外の時false
     * これらのどちらかを満たす時削除できない。
     */
    const canNotDelete = () => {
      // 「居住者」の1番目（削除できない）の時trueを返す
      const indexCheck = () => {
        const { tabIndex, contactsIndex, contacts } = this.props
        // オーナー・第三者の時はfalse
        if (tabIndex !== 0) {
          return false
        }
        // 自分より上に削除・移動されていない連絡先がある時削除可能
        const contacts0 = contacts[0] || []
        if (contacts0.length > 0) {
          for (let i = contactsIndex - 1; i >= 0; i--) {
            const contact = contacts0[i]
            // deleted, moved どちらも設定されていないものがある時削除可能
            if (!(contact.deleted || contact.moved)) {
              return false
            }
          }
        }
        return true
      }
      // 現金・売掛金が登録されている
      const hasPayment = () => {
        const paymentInfo = contact.paymentInfo || {}
        // 売掛金、現金のデータが存在し、どちらかが1件以上あればtrue
        const accounts = (paymentInfo.accounts || []).length
        const payments = (paymentInfo.payments || []).length
        return accounts > 0 || payments > 0
      }
      return indexCheck() || hasPayment()
    }

    /**
     * 電話番号が削除対象になっている時trueを返す
     */
    const deleteRequest = () => {
      // 電話番号が空か0で始まる時は正常
      const isPhoneValid = phoneNumber => {
        return phoneNumber === '' || phoneNumber.startsWith('0')
      }
      // どれか一つでも削除対象があればtrueを返す
      return (
        !isPhoneValid(contact.phoneNumber || '') ||
        !isPhoneValid(contact.phoneNumber1 || '') ||
        !isPhoneValid(contact.phoneNumber2 || '')
      )
    }

    /**
     * このキーで検索結果を分割しますよ
     * @type {string}
     */
    const searchResultCompositionSlug = `contacts-${tabIndex}-${contactsIndex}`

    const contactClass =
      'telephone-items modal-horizontal-align-item' +
      (contact.accountRemain ? ' hasImcomplete' : '')

    // 削除対象の電話番号がある時背景をグレイにする。
    const isDelete = deleteRequest()
    const contactStyle = isDelete ? { background: '#e4e4e4' } : {}

    // 居住者の一番上に緑の枠をつける
    if (tabIndex === 0 && contactsIndex === 0) {
      contactStyle.border = `3px solid ${baseColor}`
      contactStyle.borderRadius = '10px'
    }

    /**
     * 種別と編集可のチェックボックス
     */
    const species = (
      <div style={ { gridColumn: '1 / 3', gridRow: '1 / 2' } }>
        <div
          style={ { display: 'flex', flexWrap: 'wrap', alignItems: 'center' } }
        >
          <ContactType
            { ...{
              sticky,
              tabIndex,
              contactsIndex,
              route,
              disabled,
              update,
            } }
          />
          {/* 種別選択 */}
          {!disabled && readOnly && (
            <div
              style={ {
                marginLeft: 'calc(1vw * 1.25)',
              } }
            >
              <Checkbox
                labelText={ '編集可' }
                checked={ !checkedReadOnly }
                onChange={ this.onChangeReadOnly }
                style={ { display: 'none' } }
              />
            </div>
          )}
          {/* 編集不可の時に特定の項目を編集可能にするために表示する。 */}
        </div>
      </div>
    )
    /**
     * 電話番号
     */
    const telNo = (
      <div style={ { gridColumn: '1 / 3', gridRow: '2 / 5' } }>
        <PhoneNumber
          { ...{
            sticky,
            tabIndex,
            contactsIndex,
            route,
            disabled,
            searchDisabled,
            slug: searchResultCompositionSlug,
            update,
            readOnly: checkedReadOnly,
            closeModal,
            tabOrder: tabOrderStart + 1,
            isDelete,
          } }
        />
        {/* 代表番号 */}
        <PhoneNumberX
          { ...{
            sticky,
            tabIndex,
            contactsIndex,
            number: 1,
            route,
            disabled,
            searchDisabled,
            update,
            readOnly: checkedReadOnly,
            closeModal,
            tabOrder: tabOrderStart + 3,
            isDelete,
          } }
        />
        {/* 電話番号2 */}
        <PhoneNumberX
          { ...{
            sticky,
            tabIndex,
            contactsIndex,
            number: 2,
            route,
            disabled,
            searchDisabled,
            update,
            readOnly: checkedReadOnly,
            closeModal,
            tabOrder: tabOrderStart + 5,
            isDelete,
          } }
        />
        {/* 電話番号3 */}
      </div>
    )

    /**
     * 依頼者・立会者・支払者
     */
    const clientType = (
      <div style={ { gridColumn: '3 / 5', gridRow: '1 / 2' } }>
        <div
          style={ { display: 'flex', alignItems: 'center', flexWrap: 'wrap' } }
        >
          <IsClient
            { ...{
              sticky,
              tabIndex,
              contactsIndex,
              route,
              disabled,
              update,
            } }
          />
          {/* 依頼者 */}
          {ES_STEP > 2 && (
            <IsObserver
              { ...{
                sticky,
                tabIndex,
                contactsIndex,
                update,
                disabled,
              } }
            />
          )}
          {/* 立会者 */}
          <IsPayer
            { ...{
              sticky,
              tabIndex,
              contactsIndex,
              disabled,
              update,
            } }
          />
          {/* 支払者 */}
        </div>
      </div>
    )

    /**
     * 顧客名の列
     */
    const clientName = (
      <div style={ { gridColumn: '3 / 5', gridRow: '2 / 4' } }>
        <Ruby
          { ...{
            sticky,
            tabIndex,
            contactsIndex,
            route,
            disabled,
            update,
            readOnly: false,
            tabOrder: tabOrderStart + 7,
            isDelete,
          } }
        />
        {/* ふりがな */}
        <Name
          { ...{
            sticky,
            tabIndex,
            contactsIndex,
            route,
            disabled,
            update,
            readOnly: false,
            tabOrder: tabOrderStart + 8,
            isDelete,
          } }
        />
        {/* 名前 */}
      </div>
    )

    /**
     * 自由記述
     */
    const freeDescribe = (
      <div style={ { gridColumn: '1 / 5', gridRow: '3 / 4' } }>
        <FreeDescribe
          { ...{
            sticky,
            tabIndex,
            contactsIndex,
            route,
            disabled,
            update,
            readOnly: false,
            tabOrder: tabOrderStart + 18,
            isDelete,
          } }
        />
      </div>
    )

    /**
     * その他の項目列
     */
    const misc = (
      <div style={ { gridColumn: '5 / 8', gridRow: '1 / 2' } }>
        <div style={ { display: 'flex', alignItems: 'baseline' } }>
          <LegalType
            { ...{
              sticky,
              tabIndex,
              contactsIndex,
              route,
              disabled,
              update,
            } }
          />
          {/* 個人・法人の別 */}
          <DmForbidden
            { ...{
              sticky,
              tabIndex,
              contactsIndex,
              disabled,
              update,
            } }
          />
          {/* DM禁止 */}
        </div>
      </div>
    )

    /**
     * 住所の列
     */
    const addr = (
      <div style={ { gridColumn: '5 / 8', gridRow: '2 / 3' } }>
        <Zip
          { ...{
            sticky,
            tabIndex,
            contactsIndex,
            route,
            disabled,
            update,
            readOnly: false,
            tabOrder: tabOrderStart + 10,
            isDelete,
          } }
        />
        {/* 郵便番号 */}
        <PrefCode
          { ...{
            sticky,
            tabIndex,
            contactsIndex,
            route,
            disabled,
            update,
            readOnly: false,
            tabOrder: tabOrderStart + 12,
            isDelete,
          } }
        />
        {/* 都道府県 */}
        <CityCode
          { ...{
            sticky,
            tabIndex,
            contactsIndex,
            route,
            disabled,
            update,
            readOnly: false,
            tabOrder: tabOrderStart + 14,
            isDelete,
          } }
        />
        {/* 市区町村 */}
      </div>
    )

    /**
     * 住所（その他）
     */
    const address = (
      <div style={ { gridColumn: '5 / 8', gridRow: '3 / 4' } }>
        <Address
          { ...{
            sticky,
            tabIndex,
            contactsIndex,
            route,
            disabled,
            update,
            readOnly: false,
            tabOrder: tabOrderStart + 15,
            isDelete,
          } }
        />
        {/* その他住所 */}
      </div>
    )

    /**
     * 建物名
     */
    const building = (
      <div style={ { gridColumn: '8 / 10', gridRow: '3 / 4' } }>
        <Building
          { ...{
            sticky,
            tabIndex,
            contactsIndex,
            route,
            disabled,
            update,
            readOnly: false,
            tabOrder: tabOrderStart + 17,
            isDelete,
          } }
        />
      </div>
    )

    /*
     * Gridの行と列は以下のような配置になっています。
     * 列は9つ、行は3つ
     *      1     2       3    4     5  6  7    8      9
     * 1 species      | clientType | misc    |    | isGhost |
     * 2 telNo        | clientName | addr    |              |
     * 3 freeDescribe              | address | building     |
     *
     */

    return (
      <div className={ contactClass } style={ contactStyle }>
        <div
          style={ {
            display: 'grid',
            gridTemplateColumns: 'repeat(9, minmax(72px, 1fr))',
            gridTemplateRows: 'repeat(3, min-content)',
            gridColumnGap: '0.75rem',
            borderRadius: '10px',
          } }
        >
          {species}
          {telNo}
          {clientType}
          {clientName}
          {freeDescribe}
          {misc}
          {addr}
          {address}
          {building}
          {isGhost || isClaimer || canNotDelete() ? null : (
            <div style={ { gridColumn: '9/ 10', gridRow: '1 / 2' } }>
              <div style={ { display: 'flex', justifyContent: 'flex-end' } }>
                <DeleteButton
                  { ...{
                    sticky,
                    route,
                    disabled,
                    tabIndex,
                    contactsIndex,
                    update,
                  } }
                />
              </div>
            </div>
          )}
        </div>

        {isDebugMode ? (
          <DebugDisplay>
            {'デバッグ|案件付箋インデックス: ' + contactsIndex}
          </DebugDisplay>
        ) : null}
        {isDebugMode ? (
          <DebugDisplay>
            {'デバッグ|id: ' +
              (
                ((sticky.json().contacts || [])[tabIndex] || [])[
                  contactsIndex
                ] || {}
              ).id}
          </DebugDisplay>
        ) : null}
      </div>
    )
  }
}

export default connect(SingularContact)
