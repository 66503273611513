import utils from 'src/lib/api-utils'

export const requestGetRegionInfo = (accessToken, env) => {
  const endpoint = `${utils.createEndpoint(env, 'regionInfo')}`

  return fetch(endpoint, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      if (res.ok) {
        return res.json()
      }
    })
    .catch(error => {
      console.log(`regionInfo${error}`)
    })
}

export default requestGetRegionInfo
