import React from 'react'
import PropTypes from 'prop-types'
import connect from './props'

// actions
import { NETWORK_STATUSES } from 'src/reducers/network-status'

// components
import ValidationErrorMessage from 'src/components/commons/validation-error-message'
import SearchResults from 'src/components/commons/search-results'
import SearchPagenation from 'src/components/commons/search-pagenation'
import DetailedSearch from './detailed-search'
import { SearchButton } from './styled'
import Checkbox from 'src/styled/form/checkbox'

// libs
import { search as requestSearch } from 'src/lib/search-api'
import config from 'src/config'
import Sticky from 'src/lib/class-sticky'

/**
 * [propTypes description]
 * @type {object}
 */
export class QuickSearchForms extends React.Component {
  /**
   * initialQueryState
   * @type {object}
   */
  static initialQueryState = {
    basicSearch: true,
    freewordSearch: null,
    detailedSearch: null,
    limit: config.initialSearchLimit,
    offset: 0,
  }

  static initialResultState = {
    stickies: [],
    esFullCount: 0,
    fullCount: 0,
    hlFullCount: 0,
  }

  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    update: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    search: PropTypes.bool,
    phoneNumber: PropTypes.string,
    // stateProps
    accessToken: PropTypes.string.isRequired,
    env: PropTypes.object.isRequired,
    isDebugMode: PropTypes.bool,
    postStatus: PropTypes.oneOf(Object.values(NETWORK_STATUSES)).isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    isDebugMode: false,
    search: false,
    phoneNumber: '',
  }

  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    this.state = {
      query: { ...QuickSearchForms.initialQueryState },
      status: 'not_yet',
      result: { ...QuickSearchForms.initialResultState },
      page: 0,
    }
  }

  /**
   * UNSAFE_componentWillReceiveProps
   * @param  {object} nextProps React props.
   * @return {void}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.postStatus === NETWORK_STATUSES.SUCCESS &&
      this.props.postStatus !== NETWORK_STATUSES.SUCCESS
    ) {
      // 付箋の投稿に成功した時、クエリを削除する
      this.removeQuery()
    }
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.query !== this.state.query ||
      nextProps.isDebugMode !== this.props.isDebugMode ||
      nextState.status !== this.state.status ||
      nextState.page !== this.state.page ||
      nextState.result !== this.state.result
    )
  }

  /**
   * クエリを入力してアップデートするときのchangeハンドラ
   * @param  {object} e Event
   * @return {void}
   */
  onTextboxChange = e => this.updateQuery({ phoneNo: e.target.value })

  /**
   * 依頼者フラグチェックボックスを入力したときのchangeハンドラ
   * @param  {object} e Event
   * @return {void}
   */
  onCheckBoxChange = e => this.updateQuery({ isClient: e.target.checked })

  /**
   * エンターキーで検索実行するためのonKeyDownハンドラ
   * @param  {object} e Event
   * @return {void}
   */
  onTextBoxEntered = e => {
    if (this.state.query.phoneNo && e.keyCode === 13) {
      this.onTextboxChange(e) // クエリをアップデートしておく
      this.updateQuery({
        phoneNo: e.target.value,
        basicSearch: true,
      })
      this.doSearchWithoutQueryOverride()
    }
  }

  /**
   * クエリのオーバーライド無しで検索を実行する
   * @return {void}
   */
  doSearchWithoutQueryOverride = () => this.doSearch(void 0)

  /**
   * 検索ボタンを押した時のclickハンドラ
   * @return {void}
   */
  doSearch = (query = {}) => {
    const { accessToken, env } = this.props

    this.setState({ status: 'request' })
    this.props.reset() // ステージをからにする

    // isCanceled, isSuspendedをtrueにする。
    return requestSearch(
      { ...this.state.query, ...query, isCanceled: true, isSuspended: true },
      accessToken,
      env,
    )
      .then(result => {
        this.setState({
          result: {
            ...result,
            stickies: (result.stickies || []).map(sticky => new Sticky(sticky)),
            esFullCount: result.esFullCount || 0,
            fullCount: result.fullCount || 0,
            hlFullCount: result.hlFullCount || 0,
          },
          status: 'success',
        })
      })
      .catch(err => {
        console.error(err)
        this.setState({ result: void 0, status: 'failure' })
      })
  }

  /**
   * ページ移動した時のハンドラ
   * @param  {number} nextPage 次のページ
   * @return {Promise}
   */
  onPageMove = nextPage => {
    const offset = nextPage * this.state.query.limit

    return this.doSearch({ offset }).then(() =>
      this.setState({ page: nextPage }),
    )
  }

  /**
   * 検索クエリを更新
   * @param  {object} query 検索クエリオブジェクト
   * @return {void}
   */
  updateQuery = query =>
    this.setState({ query: { ...this.state.query, ...query } })

  /**
   * 検索クエリをクリア
   * @return {void}
   */
  removeQuery = () =>
    this.setState({ query: { ...QuickSearchForms.initialQueryState } })

  /**
   * 検索結果を除去
   * @return {void} [description]
   */
  clearResult = () =>
    this.setState({
      status: 'not_yet',
      result: { ...QuickSearchForms.initialResultState },
    })

  /**
   * 電話番号バリデーション
   * @param  {string}  value 入力値
   * @return {boolean}       is valid
   */
  isValid = value => /^([0-9-])*$/.test(value)

  renderPagenation = () => (
    <SearchPagenation
      page={ this.state.page }
      fullCount={ this.state.result.fullCount || 0 }
      limit={ this.state.query.limit }
      onPageMove={ this.onPageMove }
    />
  )

  /**
   * render
   * @return {ReactDOM} rendering ReactDOM
   */
  render() {
    const { query, status, result } = this.state

    const phoneNo = query.phoneNo ? query.phoneNo : ''
    const isClient = !!query.isClient

    const disabled =
      phoneNo === '' || !this.isValid(phoneNo) || status === 'request'

    const showMessage = !disabled && status !== 'success'

    return (
      <div className={ 'quick-search-forms' }>
        <dl className={ 'label-inputs-wrap' }>
          <dt>
            <label htmlFor={ 'quick-search-input' }>{'履歴'}</label>
          </dt>
          <dd>
            <input
              className={
                this.isValid(phoneNo) ? 'input-valid' : 'input-invalid'
              }
              value={ phoneNo }
              id={ 'quick-search-input' }
              name={ 'quick-search-input' }
              type={ 'text' }
              placeholder={ '電話番号を入力..' }
              onChange={ this.onTextboxChange }
              onKeyDown={ this.onTextBoxEntered }
            />

            <SearchButton
              onClick={ this.doSearchWithoutQueryOverride }
              disabled={ disabled }
            >
              {'検索する'}
            </SearchButton>

            <div style={ { margin: '0 1em' } }>
              <Checkbox
                checked={ isClient }
                labelText={ '依頼者フラグ' }
                onChange={ this.onCheckBoxChange }
              />
            </div>

            <DetailedSearch
              update={ this.props.update }
              reset={ this.props.reset }
              search={ this.props.search }
              phoneNumber={ this.props.phoneNumber }
            />
          </dd>
        </dl>
        {showMessage ? (
          <ValidationErrorMessage
            message={ '検索を行うと、以下で入力中の案件の内容は消去されます。' }
          />
        ) : null}

        {status !== 'not_yet' && (
          <div>
            <SearchResults
              route={ 'call' }
              result={ result }
              query={ query }
              status={ status }
              closeMe={ this.clearResult }
              callback={ this.clearResult }
              update={ this.props.update }
              reset={ this.props.reset }
              renderPagenation={ this.renderPagenation }
              listView
            />
          </div>
        )}
      </div>
    )
  }
}

export default connect(QuickSearchForms)
