import Sticky from 'src/lib/class-sticky'
import { today } from 'src/lib/moment'

// action creators
import { createAsyncPostStickyAction } from 'src/reducers/wara/async'
import { actionCreators as $ } from 'src/reducers/wara'

// config
import config from 'src/config'

/**
 * [description]
 * @param  {object} state   root State
 * @param  {number} stickyTypeId   stickyTypeId
 * @param  {{ regionIndex: number, listIndex: number, stickyIndex: number }} locationSrc
 * @param  {{ regionIndex: number, listIndex: number }} locationDest locationDest object
 * @param  {function} dispatch dispather
 * @return {void}
 */
export default (state, stickyTypeId, locationSrc, locationDest, dispatch) => {
  const {
    regionIndex: regionIndexDest,
    listIndex: listIndexDest,
  } = locationDest

  const { accessToken } = state.login.authentication
  const { env } = state

  // NOTE: Dockの中にSpecialStickyは存在しない。また、detectStickyTypeでも避けられている
  const list = state.wara.data.regions[regionIndexDest].lists[listIndexDest]

  const stickyIndex = list.stickies.length

  /**
   * 確定作業日時を作成する
   */
  const getDeterminedDateTime = () => {
    if (list.stickies.length === 0) {
      // displayDateを設定する。
      return {
        date: state.displayDate.displayDate,
        startAt: null,
        finishAt: null,
        answerTimeId: null,
        strict: null,
      }
    } else {
      // 左の付箋に確定作業日時がある時は日付と開始時刻をコピーする。
      const leftDeterminedDateTime = {
        ...(list.stickies[stickyIndex - 1]._props.determinedDateTime || {}),
      }
      return {
        date: leftDeterminedDateTime.date || state.displayDate.displayDate,
        startAt: leftDeterminedDateTime.startAt || null,
        finishAt: null,
        answerTimeId: null,
        strict: null,
      }
    }
  }
  const determinedDateTime = getDeterminedDateTime()

  const {
    regionId,
    staffId,
    stickies: { length },
  } = list

  const sticky = new Sticky({}).update({
    companyId: config.company.ES,
    contacts: [
      [
        {
          isClient: true,
        },
      ],
    ],
    wishDateTimes: [{ date: today(), displayOrder: 0 }],
    mediumId: 999,
    orderTypeId: 0,
    stickyTypeId,
    regionId,
    staffId,
    displayDate: state.displayDate.displayDate, // NOTE: state設計のミスでこうなっている
    displayOrder: length,
    determinedDateTime,
  })

  dispatch($.addSticky(locationDest, sticky))

  dispatch(
    createAsyncPostStickyAction(
      sticky, // 特殊付箋のbody
      accessToken,
      {
        // locationSrc           : false, // 特殊付箋を作成するときは、Srcは存在しないので不要。フックは呼ぶ必要はない
        locationDest: { ...locationDest, stickyIndex },
        postStickyRequestType: config.postStickyRequestTypes.SPECIAL,
      },
      env,
    ),
  )
}
