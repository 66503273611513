import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import requestGetSticky from 'src/lib/sticky-api/get'
import SearchResultsModal from 'src/components/commons/search-results/partials/modal'
import button, { buttonFill } from 'src/styled/button'
import { baseColor } from 'src/colors'

const Button = styled.button`
  ${button};
  ${buttonFill(baseColor)};
  margin: 10px;
  padding: 5px 10px;
`

/**
 * マルシーの親付箋を表示するボタン
 * ※汎用の付箋表示ボタンを意識している
 */
class MarucParent extends React.Component {
  static propTypes = {
    sticky: PropTypes.object.isRequired,
    accessToken: PropTypes.string.isRequired,
    env: PropTypes.object.isRequired,
  }

  /**
   * constructor
   * @param {*} props
   */
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false, // マルシー親付箋の表示フラグ
      dispSticky: null, // 表示するマルシー親付箋
    }
  }

  /**
   * 付箋詳細を表示する
   * @param {*} sticky
   */
  openMarucModal = () => {
    const { sticky, accessToken, env } = this.props
    // 付箋の詳細情報を取得して表示する。
    requestGetSticky(sticky._props.parentId, accessToken, env)
      .then(rsticky => this.setState({ isOpen: true, dispSticky: rsticky }))
      .catch(err => console.error(err))
  }

  /**
   * 付箋詳細を閉じる
   */
  closeMarucModal = () => this.setState({ isOpen: false, dispSticky: null })

  render() {
    const { sticky } = this.props
    // マルシーでparentIdがある時のみ表示
    if (!sticky.isClaimerOrder || !sticky._props.parentId) {
      return null
    }
    const { isOpen, dispSticky } = this.state
    // メモ：displayRouteは表示制御にはほとんど影響はないが、コンポーネントのIDに使われている。
    // assignを設定すると元の付箋詳細と同じIDになる要素が発生し、元付箋の内容を破壊する。
    // そのため、assign以外の値を設定してIDがぶつからないようにする必要がある。
    return (
      <div>
        <Button onClick={ this.openMarucModal }>{'マルシー親付箋を表示'}</Button>
        {isOpen && (
          <SearchResultsModal
            isOpen={ isOpen }
            sticky={ dispSticky }
            closeMe={ this.closeMarucModal }
            closeResult={ this.closeMarucModal }
            parentRoute={ 'assign' }
            displayRoute={ 'maruc' } // 元の付箋詳細(assign)とぶつからない名前を指定する
            hasES={ false }
            hasHL={ false }
            stageStickyFromHistory={ () => {} }
            showButtons={ false } // 付箋作成ボタンの表示
          />
        )}
      </div>
    )
  }
}

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
const mapStateToProps = state => {
  return {
    accessToken: state.login.authentication.accessToken,
    env: state.env,
  }
}

export default connect(mapStateToProps)(MarucParent)
