import React from 'react'
import PropTypes from 'prop-types'
import connect from '../../../connect'
import Sticky from 'src/lib/class-sticky'
import Radio from 'src/styled/form/radio'

/**
 * 個人・法人の別を入力します
 * @type {ReactComponent}
 */
export class LegalType extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    tabIndex: PropTypes.number.isRequired,
    contactsIndex: PropTypes.number.isRequired,
    route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
    disabled: PropTypes.bool.isRequired,
    master: PropTypes.shape({
      legalTypes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        }),
      ),
    }).isRequired,
    // dispatchProps
    updateStage: PropTypes.func.isRequired,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return (
      this.props.master.legalTypes !== nextProps.master.legalTypes ||
      this.props.sticky.json().contacts !== nextProps.sticky.json().contacts ||
      this.props.disabled !== nextProps.disabled
    )
  }

  /**
   * radiobutton change handler
   * @param {Event} e event
   * @return {void}
   */
  onChange = e => {
    const { sticky, tabIndex, contactsIndex, updateStage } = this.props
    const id = sticky.getContactId(tabIndex, contactsIndex)
    const existsClient = sticky.existsClient()
    const legalTypeId = parseInt(e.target.value, 10)

    const diffProps = {
      contacts: {
        [tabIndex]: {
          [contactsIndex]: existsClient // NOTE: このロジック、mergeStickyにもう入ってる？
            ? { id, legalTypeId }
            : { id, legalTypeId, isClient: true },
        },
      },
    }
    updateStage(new Sticky(diffProps))
  }

  get groupName() {
    return `contact-legal-type-${this.props.route}-${this.props.tabIndex}-${this.props.contactsIndex}`
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const {
      sticky,
      tabIndex,
      contactsIndex,
      master: { legalTypes },
      disabled,
    } = this.props

    const stickyProps = sticky.json() || {}

    const { legalTypeId } =
      ((stickyProps.contacts || [])[tabIndex] || [])[contactsIndex] || {}

    return (
      <dl className={ 'label-inputs-wrap' }>
        <dd>
          <div style={ { display: 'flex', width: '150px' } }>
            {(legalTypes || []).map(option => (
              <Radio
                key={ option.id }
                checked={ option.id === legalTypeId }
                value={ option.id }
                name={ this.groupName }
                onChange={ this.onChange }
                disabled={ disabled }
                labelText={ option.name }
              />
            ))}
          </div>
        </dd>
      </dl>
    )
  }
}

export default connect(LegalType)
