// react related
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material'
import theme from 'src/theme.js'
import { ThemeProvider } from '@mui/material/styles'

// libs
// import config from 'src/config'

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
const mapStateToProps = state => ({
  creditCards: state.master.data.creditCards,
})

export class AccountCreditCard extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    value: PropTypes.number.isRequired,
    route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
    update: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    // stateProps
    creditCards: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        isOneTime: PropTypes.bool.isRequired,
      }),
    ).isRequired,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return (
      this.props.disabled !== nextProps.disabled ||
      this.props.creditCards !== nextProps.creditCards ||
      this.props.value !== nextProps.value
    )
  }

  /**
   * クレジットカードIDを変更した時にそのIDをステージング値に反映する
   * @param  {Event} e イベントハンドラです
   * @return {void}
   */
  onChange = e => {
    const val = parseInt(e.target.value, 10)
    const creditCardId = isNaN(val) ? -1 : val
    this.props.update(creditCardId)
  }

  get identifier() {
    return `account-select-how-to-retrieve-${this.props.route}`
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { value, route, creditCards, disabled: forceDisabled } = this.props

    const displayCreditCards = [
      { id: -1, name: '(未選択)' },
      ...(creditCards || []),
    ]

    const disabled = forceDisabled || route !== 'assign'

    return (
      <ThemeProvider theme={ theme }>
        <Box
          sx={ {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, max-content)',
            alignItems: 'center',
            columnGap: '0.5rem',
          } }
        >
          <Typography
            htmlFor={ `account-select-credit-card-id-${route}` }
            variant="subtitle1"
            sx={ { fontWeight: 'bold' } }
          >
            {'クレジットカード'}
          </Typography>
          <FormControl
            color="primary"
            fullWidth
            size="small"
            sx={ {
              background: '#fff',
              '.MuiOutlinedInput-root': {
                height: '28px',
                alignItems: 'center',
                '.MuiSelect-select': {
                  padding: '0px 14px',
                },
              },
            } }
          >
            <Select
              value={ value }
              id={ `account-select-credit-card-id-${route}` }
              name={ `account-select-credit-card-id-${route}` }
              onChange={ this.onChange }
              disabled={ disabled }
              MenuProps={ { style: { zIndex: 10000 } } } // TODO: 付箋詳細や詳細検索のモーダルで表示するにはこの数値が必要
            >
              {displayCreditCards.map(option => (
                <MenuItem key={ option.id } value={ option.id }>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </ThemeProvider>
    )
  }
}

export default connect(mapStateToProps)(AccountCreditCard)
