import React from 'react'
import PropTypes from 'prop-types'
import connect from '../../../connect'
import openMap from 'src/lib/open-map'
import Sticky from 'src/lib/class-sticky'
import findContact from '../../lib/find-contact'
import { contactInputStyle } from 'src/lib/format'

/**
 * その他の住所を入力します
 * @type {ReactComponent}
 */
export class Address extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    tabIndex: PropTypes.number.isRequired,
    contactsIndex: PropTypes.number.isRequired,
    route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
    disabled: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
    tabOrder: PropTypes.number,
    isDelete: PropTypes.bool,
    // StateProps
    master: PropTypes.shape({
      prefCodes: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
        }),
      ),
    }).isRequired,
    cityCodes: PropTypes.object.isRequired,
    // dispatchProps
    updateStage: PropTypes.func.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    id: null,
    contacts: [],
    address: '',
    zip: '',
    master: {},
    prefCode: '',
    cityCode: '',
    tabOrder: 0,
    isDelete: false,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return (
      this.props.sticky !== nextProps.sticky ||
      this.props.disabled !== nextProps.disabled ||
      this.props.readOnly !== nextProps.readOnly
    )
  }

  /**
   * textbox change handler
   * @param  {Event} e event
   * @return {void}
   */
  onChange = e => {
    const { sticky, tabIndex, contactsIndex, updateStage } = this.props
    const address = e.target.value

    const id = sticky.getContactId(tabIndex, contactsIndex)
    const existsClient = sticky.existsClient()

    const diffProps = {
      contacts: {
        [tabIndex]: {
          // 依頼者の人がいない時は、この人を依頼者にする
          [contactsIndex]: existsClient
            ? { id, address }
            : { id, address, isClient: true },
        },
      },
    }

    updateStage(new Sticky(diffProps))
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const {
      // ownProps
      sticky,
      tabIndex,
      contactsIndex,
      cityCodes,
      route,
      disabled,
      readOnly,
      tabOrder,
      isDelete,
      // stateProps
      master: { prefCodes },
    } = this.props

    const { address, prefCode, cityCode, cityName: enteredCityName } =
      findContact(sticky, tabIndex, contactsIndex) || {}

    const isButtonDisabled = !address

    const prefName =
      ((prefCodes || []).find(x => x.code === prefCode) || {}).name || ''

    const cityName =
      enteredCityName ||
      ((cityCodes[prefCode] || []).find(x => x.code === cityCode) || {})
        .cityName ||
      ''

    const query = [prefName, cityName, address].filter(x => !!x).join('')

    const identifier = `contact-address-${tabIndex}-${contactsIndex}-form-${route}`

    return (
      <dl
        className={ 'label-inputs-wrap' }
        style={ { display: 'grid', gridTemplateColumns: '9.25rem 1fr' } }
      >
        <dt>
          <label htmlFor={ identifier }>{'住所(その他)'}</label>
        </dt>

        <dd className={ 'input-wrap' }>
          <div className={ 'flex-full' }>
            <textarea
              rows="3"
              value={ address || '' }
              id={ identifier }
              name={ identifier }
              type={ 'text' }
              onChange={ this.onChange }
              disabled={ disabled }
              readOnly={ readOnly }
              style={ {
                ...contactInputStyle({ disabled, readOnly, isDelete }),
                width: 'calc(100% - 24px)',
              } }
              tabIndex={ tabOrder }
            />
            <button
              className={ 'button button-open label-inputs-wrap-button' }
              onClick={ openMap(query) }
              disabled={ disabled || isButtonDisabled }
              tabIndex={ tabOrder + 1 }
            >
              <i className={ 'fa fa-map' } />
            </button>
          </div>
        </dd>
      </dl>
    )
  }
}

export default connect(Address)
