// react
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { baseColor } from 'src/colors'
import styled from 'styled-components'
import { useCancelStatus } from 'src/reducers/wara/actions/useCancelStats'

/**
 * map state to props
 * @param  {object} state mapping state
 * @return {object}       mapped props
 */
const mapStateToProps = state => {
  return {
    accessToken: state.login.authentication.accessToken,
    env: state.env,
  }
}

const CancelStatsWrap = styled.span`
  color: white;
  font-weight: bold;
  background-color: ${baseColor};
  border-radius: 4px;
  font-size: 14px;
  padding: 5px 8px;
  margin-left: 10px;
`

/**
 * 付箋詳細ヘッダにキャンセルの状態を表示する。
 * @type {Object}
 */
const CancelStats = ({ parentId, accessToken, env }) => {
  const { isCancelled, isSuspended } = useCancelStatus(
    parentId,
    accessToken,
    env,
  )

  return (
    (isCancelled || isSuspended) && <CancelStatsWrap>{'復活'}</CancelStatsWrap>
  )
}

/**
 * propTypes
 * @type {object}
 */
CancelStats.propTypes = {
  // ownProps
  parentId: PropTypes.number.isRequired,
  // stateProps
  accessToken: PropTypes.string.isRequired,
  env: PropTypes.object.isRequired,
}

export default connect(mapStateToProps)(CancelStats)
