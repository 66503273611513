import switz from 'switz'
import update from 'immutability-helper'

/**
 * initial state
 * @type {object}
 */
export const initialWrappedCalendarState = {
  isOpen: {},
}

/**
 * Action Types
 * @type {string}
 */
export const UNREGISTER = 'WRAPPED_CALENDER.UNREGISTER'
export const UPDATE = 'WRAPPED_CALENDER.UPDATE'
export const CLOSE_ALL = 'WRAPPED_CALENDER.CLOSE_ALL'

/**
 * action bulk exports
 * @type {object}
 */
export const ACTION_TYPES = {
  UNREGISTER,
  UPDATE,
  CLOSE_ALL,
}

/**
 * WrappedCalendar reducer
 * @param  {object} [state=initialWrappedCalendarState] previous state
 * @param  {object} action                 dispatched action
 * @return {object}                         next state
 */
export default (state = initialWrappedCalendarState, action) => {
  const { type, payload } = action

  return switz(type, s =>
    s
      .case(UNREGISTER, () => {
        const { id } = payload
        const isOpen = { ...state.isOpen }
        delete isOpen[id]
        return update(state, { isOpen: { $set: isOpen } })
      })
      .case(UPDATE, () => {
        const { id, open } = payload
        return update(state, { isOpen: { [id]: { $set: open } } })
      })
      .case(CLOSE_ALL, () => {
        return update(state, { isOpen: { $set: {} } })
      })
      .default(() => state),
  )
}
