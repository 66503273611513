import React from 'react'
import PropTypes from 'prop-types'
import connect from './props'

import { NETWORK_STATUSES } from 'src/reducers/network-status'

import Unauthorize from 'src/components/commons/unauthorize'
import LoginMessage from './partials/login-message'
import Userid from './partials/userid'
import Password from './partials/password'
import Login from './partials/login'
import { Logo, FormWrap } from './styled'
import Ghost from 'src/styled/button/ghost'
import styled from 'styled-components'

const Button = styled(Ghost)`
  display: block;
  width: 230px;
  margin: 1em auto;
`

/**
 * 共通ヘッダーのコンポーネント
 * @type {ReactComponent}
 */
export class LoginForm extends React.Component {
  /**
   * PropTypes
   * @type {object}
   */
  static propTypes = {
    // stateProps
    loginStatus: PropTypes.oneOf(Object.values(NETWORK_STATUSES)),
    isDebugMode: PropTypes.bool,
    // dispatchProps
    loginAsOfflineMode: PropTypes.func.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    loginStatus: NETWORK_STATUSES.NOT_YET,
    isDebugMode: false,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return this.props.loginStatus !== nextProps.loginStatus
  }

  loginAsOfflineMode = () => this.props.loginAsOfflineMode()

  /**
   * render
   * @return {ReactDOM} dom
   */
  render() {
    const {
      // stateProps
      loginStatus,
      isDebugMode,
    } = this.props

    return (
      <Unauthorize>
        {isDebugMode ? (
          <p style={ { fontSize: '1em', textAlign: 'center', margin: '2em' } }>
            {'es-stickyboard'}
            <small style={ { fontSize: '.5em', marginLeft: '.5em' } }>
              {'(デバッグモードにつきロゴ省略)'}
            </small>
          </p>
        ) : (
          <Logo data-test={ 'home-main-logo' } />
        )}
        <FormWrap>
          <LoginMessage status={ loginStatus } />
          <Userid />
          <Password />
          <Login />
          {isDebugMode && (
            <Button onClick={ this.loginAsOfflineMode }>
              <i className={ 'fa fa-plane' } />
              {' オフラインモードでログイン'}
            </Button>
          )}
        </FormWrap>
      </Unauthorize>
    )
  }
}

export default connect(LoginForm)
