import utils from 'src/lib/api-utils'

// {
//   "id": 1,
//   "stickyId": 1,
//   "staffId": 1,
//   "staffReported": true,
//   "visitDate": "2018-09-01",
//   "visitStartTime": "00:00:00",
//   "visitEndDate": "00:00:00",
//   "inProgress": true,
//   "content": "クレーム内容",
//   "countermeasure": "対策",
//   "result": "結果",
//   "measures": [1, 2, 3, 4, 5],
//   "refund": 10000,
//   "complete": true,
//   "marucStatus": [3, 4, 5, 6]
// }

export const put = (body, accessToken, env) => {
  const endpoint = utils.createEndpoint(env, 'maruc')
  return fetch(endpoint, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })
}

export default put
