/**
 * ライブラリ
 */
import React from 'react'
import PropTypes from 'prop-types'

/**
 * 子要素をトグル可能なコンポーネント
 * @type {ReactComponent}
 */
export class Toggle extends React.Component {
  /**
   * default
   * @type {object}
   */
  static propTypes = {
    // Possible React children
    slug: PropTypes.string.isRequired,
    memory: PropTypes.bool,
    defaultToggleOpen: PropTypes.bool,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    openButtonTitle: PropTypes.string,
    closeButtonTitle: PropTypes.string,
  }

  /**
   * default
   * @type {object}
   */
  static defaultProps = {
    memory: false,
    defaultToggleOpen: false,
    children: [],
    openButtonTitle: '開く',
    closeButtonTitle: '閉じる',
  }

  /**
   * constructor
   * @param  {object} props props
   * @return {void}
   */
  constructor(props) {
    super(props)
    let isOpen = false
    const LOCAL_STORAGE_KEY = `PRESERVE__toggle-components-open-status_${props.slug}`

    if (props.memory) {
      try {
        isOpen =
          JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) ||
          props.defaultToggleOpen
      } catch (e) {
        isOpen = props.defaultToggleOpen
      }
    } else {
      isOpen = props.defaultToggleOpen
    }

    this.state = { isOpen, LOCAL_STORAGE_KEY }
  }

  /**
   * トグルのハンドルをクリックした時のハンドラ
   * @return {void}
   */
  onToggleClick = () => {
    const isOpenNext = !this.state.isOpen
    this.setState({ isOpen: isOpenNext })
    localStorage.setItem(this.state.LOCAL_STORAGE_KEY, isOpenNext)
  }

  /**
   * render
   * @return {ReactDOM} rendered result
   */
  render() {
    const { closeButtonTitle, openButtonTitle } = this.props

    const { isOpen } = this.state

    // 子要素が1個の時はchildrenはarray-likeでない
    const children = Array.isArray(this.props.children)
      ? this.props.children
      : [this.props.children]

    return (
      <div className={ 'toggle-wrap' }>
        <div className={ 'toggle-button-wrap' }>
          <button
            className={ 'button button-toggle ' + (isOpen ? 'open' : 'close') }
            onClick={ this.onToggleClick }
          >
            {isOpen ? closeButtonTitle : openButtonTitle}
          </button>
        </div>
        <div className={ 'toggle-body-wrap ' + (isOpen ? 'open' : 'close') }>
          {children.map((component, index) => (
            // TODO: パフォーマンスに劣る
            <div key={ index }>{component}</div>
          ))}
        </div>
      </div>
    )
  }
}

export default Toggle
