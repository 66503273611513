import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { baseColor, gray } from 'src/colors'
import { DragSource } from './dnd-helpers/drag-source'
import { DropTarget } from './dnd-helpers/drop-target'

const color1 = props =>
  props.isOver ? 'yellow' : props.isDragging ? 'lightGray' : 'white'
const color2 = props => (props.displayed ? baseColor : gray)
const line1 = props => (props.isDragging ? 'dashed' : 'solid')
const opacity1 = props => (props.displayed ? 1 : 0.5)

const Card = styled.div`
  width: 300px;
  text-align: left;
  background-color: ${color1};
  border: 2px ${line1} ${color2};
  border-radius: 4px;
  padding: 10px;
  margin: 5px;
  opacity: ${opacity1};

  &:hover {
    cursor: pointer;
  }
`

export const NameCard = props => {
  const {
    // ownProps
    name,
    displayed,
    onClick,
    // DnD
    connectDragSource,
    connectDropTarget,
    isDragging,
    isOver,
  } = props

  return connectDragSource(
    connectDropTarget(
      <div>
        <Card
          displayed={ displayed }
          isDragging={ isDragging }
          isOver={ isOver }
          onClick={ onClick }
        >
          {name}
        </Card>
      </div>,
    ),
  )
}

NameCard.propTypes = {
  // ownProps
  name: PropTypes.string.isRequired,
  displayed: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  // Injected by React DnD:
  connectDragSource: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
}

export default DropTarget(DragSource(NameCard))
