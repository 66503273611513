const findStickyFromLocation = (
  wara,
  { regionIndex, listIndex, stickyIndex },
) => {
  if (regionIndex === -1 || listIndex === -1) {
    return wara.dock.stickies[stickyIndex]
  } else {
    return wara.regions[regionIndex].lists[listIndex].stickies[stickyIndex]
  }
}

export default findStickyFromLocation
