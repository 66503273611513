/**
 * 特殊付箋の供給元です。
 */

import React from 'react'
import PropTypes from 'prop-types'
import { DragSource } from 'react-dnd'
import { connect } from 'react-redux'

/**
 * DragSource in React-DnD
 * @type {Decorator}
 */
const ConnectAsDragSource = Component => {
  return DragSource(
    'specialSticky',
    { beginDrag: x => x },
    /**
     * [descr]
     * @param  {object} connect connect
     * @param  {object} monitor monitor
     * @return {object}         mapped props
     */
    (connect, monitor) => ({
      // Call this function inside render()
      // to let React DnD handle the drag events:
      connectDragSource: connect.dragSource(),
      // You can ask the monitor about the current drag state:
      isDragging: monitor.isDragging(),
      dragIndexes: monitor.getItem(),
    }),
  )(Component)
}

/**
 * map state to props
 * @param  {object} state    mapping state
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
const mapStateToProps = (state, ownProps) => {
  // マスタを検索し、付箋ラベルを取得
  const stickyTypes = state.master.data.stickyTypes || []
  const id = ownProps.stickyTypeId
  const labels = stickyTypes
    .filter(stickyType => stickyType.id === id)
    .map(stickyType => stickyType.name)

  const label = labels.length > 0 ? labels[0] : ''

  return { label }
}

export class SpecialStickySource extends React.Component {
  /**
   * proptypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    slug: PropTypes.string.isRequired,
    // stateProps
    label: PropTypes.string.isRequired,
    // DnD props
    connectDragSource: PropTypes.func.isRequired,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return this.props.label !== nextProps.label
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const {
      // ownProps
      slug,
      // stateProps
      label,
      // DnD props
      connectDragSource,
    } = this.props

    return connectDragSource(
      <li className={ `sticky-source ${slug}-sticky-source ${slug}-sticky` }>
        <h3 className={ 'sticky-label' }>{label}</h3>
      </li>,
    )
  }
}

/**
 * redux connected
 */
export const ConnectedSpecialStickySource = connect(mapStateToProps)(
  SpecialStickySource,
)

/**
 * DragSource
 */
export default ConnectAsDragSource(ConnectedSpecialStickySource)
