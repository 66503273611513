import React from 'react'
import PropTypes from 'prop-types'
import connect from './connect'
import Checkbox from 'src/styled/form/checkbox'
import { isToday } from 'src/lib/validate'

export class Money extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    hasMoney: PropTypes.bool,
    updateTheDailyInfo: PropTypes.func.isRequired,
    displayDate: PropTypes.string.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    hasMoney: false,
  }

  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate() {
    return true
  }

  /**
   * checkboxのハンドラ
   * @param  {Event} e event
   * @return {void}
   */
  onCheckBoxChange = e => {
    if (!confirm('登録してよろしいですか？')) {
      return
    }
    this.props.updateTheDailyInfo(null, { money: e.target.checked })
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { hasMoney } = this.props
    const disabled = !isToday(this.props.displayDate)
    return (
      <Checkbox
        labelText={ '33,001円〜' }
        checked={ !!hasMoney }
        onChange={ this.onCheckBoxChange }
        disabled={ disabled }
      />
    )
  }
}

export default connect(Money)
