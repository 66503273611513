import PropTypes from 'prop-types'
import React from 'react'
import { toJPYenText } from 'src/lib/format'
import { Dd, Dl, Dt } from 'src/styled/list'
import { VerticalTable } from '../../styled'

export const Expenses = props => {
  const { items } = props
  const sum = items.reduce((prev, item) => prev + item.expenseValue, 0)
  return [
    <Dl key={ 0 }>
      <Dt>{'スタッフ自己負担金'}</Dt>
      <Dd>{toJPYenText(sum)}</Dd>
    </Dl>,
    <Dl key={ 1 }>
      <Dt />
      <Dd strech>
        {items.length > 0 && (
          <VerticalTable
            items={ items.map(
              ({ expenseName: name, expenseValue: value, id }) => ({
                name,
                value: toJPYenText(value),
                key: id,
              }),
            ) }
          />
        )}
      </Dd>
    </Dl>,
  ]
}

Expenses.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      expenseName: PropTypes.string.isRequired,
      expenseValue: PropTypes.number.isRequired,
      expenseNameId: PropTypes.number.isRequired,
    }),
  ).isRequired,
}

export default Expenses
