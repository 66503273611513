import utils from 'src/lib/api-utils'

/**
 * SSの状態を取得する
 * @param  {string} accessToken [description]
 * @param  {object} env         env
 * @return {Promise}
 */
export const requestGetStaffInfo = (accessToken, env) => {
  const endpoint = `${utils.createEndpoint(env, 'staffInfo')}`

  return fetch(endpoint, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  }).then(res => {
    if (res.ok) {
      return res.json()
    } else {
      throw 'GETエラー'
    }
  })
}

export default requestGetStaffInfo
