import { baseColor, darkBaseColor } from 'src/colors'
import styled from 'styled-components'

export const DetailedSearchButton = styled.button`
  margin: 0.2em;
  padding: 0 5px;
  border: 0;
  border-radius: 3px;
  background-color: transparent;
  line-height: 2em;
  color: ${baseColor};
  font-size: 1em;
  text-decoration: underline;

  &:hover {
    color: ${darkBaseColor};
  }
`
