import React from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'
import { ToggleButton, Wrap, TextArea, Pre } from './styled'
import noop from 'src/lib/noop'

/**
 * オブジェクトをJSONとしてダンプして表示するためのデバッグコンポーネント
 * @type {ReactComponent}
 */
export default class ObjectDumper extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    prop: PropTypes.object.isRequired,
    side: PropTypes.string,
    title: PropTypes.string,
    defaultOpen: PropTypes.bool,
    handleOffsetY: PropTypes.number,
    // Stateの改ざん機能を有効化するためには、
    // 更新後の値を引数に取るonForceAlterを実装してください。
    onForceAlter: PropTypes.func,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    side: 'right',
    title: '入力値デバッグ',
    defaultOpen: true,
    onForceAlter: noop,
    handleOffsetY: 0,
  }

  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    this.state = {
      isOpen: props.defaultOpen,
      isAltering: false,
      timerId: false,
    }
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate() {
    return true
  }

  /**
   * componentWillUnmount
   * @return {void}
   */
  componentWillUnmount() {
    clearTimeout(this.state.timerId)
  }

  /**
   * トグルボタンをクリックした時のハンドラ
   * @return {void}
   */
  onToggleClick = () => this.setState({ isOpen: !this.state.isOpen })

  /**
   * オブジェクトの改ざんを有効化するために、pre要素をクリックした時のハンドラ
   * @return {void}
   */
  onPreClick = () => {
    if (this.props.onForceAlter !== noop) {
      this.setState({
        isAltering: JSON.stringify(this.props.prop, void 0, '  '),
        // display: none だとフォーカスできないんですよ
        // まあ、50 msec. も待てば表示されているでしょう。
        timerId: setTimeout(() => this.freeDescribeTextArea.focus(), 50),
      })
    }
  }

  /**
   * オブジェクト改ざん用のテキストエリアを編集した時のハンドラ
   * @param  {Event} e event
   * @return {void}
   */
  onTextAreaChange = e => {
    this.setState(update(this.state, { isAltering: { $set: e.target.value } }))
  }

  /**
   * オブジェクト改ざん用のテキストエリアからアンフォーカスし、編集を決定しようとした時のハンドラ
   * @param  {Event} e event
   * @return {void}
   */
  onTextAreaBlur = () => {
    if (this.props.onForceAlter !== noop) {
      let result
      try {
        result = JSON.parse(this.state.isAltering)
        this.props.onForceAlter(result)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      } finally {
        this.setState(update(this.state, { isAltering: { $set: false } }))
      }
    }
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { isOpen, isAltering } = this.state

    const { prop, side, title, onForceAlter, handleOffsetY } = this.props

    const editable = onForceAlter !== noop

    const faDirection = isOpen
      ? side === 'right'
        ? 'right'
        : 'left'
      : side === 'right'
        ? 'left'
        : 'right'

    return (
      <div>
        <ToggleButton
          onClick={ this.onToggleClick }
          isOpen={ isOpen }
          offsetY={ handleOffsetY }
          side={ side }
        >
          <i className={ `fa fa-arrow-${faDirection}` } />
        </ToggleButton>
        <Wrap isOpen={ isOpen } side={ side }>
          <h3>{title}</h3>
          <TextArea
            name={ `debug__object_dumper_altering_object-${side}` }
            id={ `debug__object_dumper_altering_object-${side}` }
            onChange={ this.onTextAreaChange }
            onBlur={ this.onTextAreaBlur }
            value={ isAltering }
            show={ !!isAltering }
            // eslint-disable-next-line react/jsx-no-bind
            ref={ c => (this.freeDescribeTextArea = c) }
          />
          <Pre onClick={ this.onPreClick } show={ !isAltering } editable={ editable }>
            {JSON.stringify(prop, void 0, '  ')
              .split('\n')
              .map((p, i) => (
                // TODO: パフォーマンスに劣る
                <p key={ i }>{p}</p>
              ))}
          </Pre>
        </Wrap>
      </div>
    )
  }
}
