import switz from 'switz'
import update from 'immutability-helper'
import Sticky from 'src/lib/class-sticky'

/**
 * initial state
 * @type {object}
 */
export const initialStagesState = {
  data: {},
  selectedKey: -1,
}

/**
 * Action Types
 * @type {string}
 */
export const UPDATE = 'STAGES.UPDATE'
export const CLEAR = 'STAGES.CLEAR'
export const CLEAR_ALL = 'STAGES.CLEAR_ALL'
export const SET_SELECTED_KEY = 'STAGES.SET_SELECTED_KEY'
export const STAGE_STICKY = 'STAGES.STAGE_STICKY'
export const STAGE_STICKY_RAW = 'STAGES.STAGE_STICKY_RAW'
export const UPDATE_STAGE = 'STAGES.UPDATE_STAGE'

/**
 * action bulk exports
 * @type {object}
 */
export const ACTION_TYPES = {
  UPDATE,
  CLEAR,
  CLEAR_ALL,
  SET_SELECTED_KEY,
  STAGE_STICKY,
  STAGE_STICKY_RAW,
  UPDATE_STAGE,
}

/**
 * stages reducer action creators
 * @type {object}
 */
export const actionCreators = {
  /**
   * stage sticky
   * @param  {Sticky} sticky stageするstickyインスタンス
   * @return {object}        stage Sticky Action
   */
  stageSticky: sticky => ({
    type: STAGE_STICKY,
    payload: { sticky },
  }),

  /**
   * [stageStickyRaw description]
   * @param  {object|function} updator immutability-helperのupdaterをそのまま渡すか、updateを引数にコールする関数を渡します
   * @return {object}         stage sticky eaw action
   */
  stageStickyRaw: updator => ({
    type: STAGE_STICKY_RAW,
    payload: { updator },
  }),

  updateStage: sticky => ({
    type: UPDATE_STAGE,
    payload: { sticky },
  }),
}

/**
 * WrappedCalendar reducer
 * @param  {object} [state=initialStagesState] previous state
 * @param  {object} action                 dispatched action
 * @return {object}                         next state
 */
export default (state = initialStagesState, action) => {
  const { type, payload } = action

  return switz(type, s =>
    s
      .case(UPDATE, () => {
        const { key, sticky } = payload
        return update(state, { data: { [key]: { $set: sticky } } })
      })
      .case(CLEAR, () => {
        const { key } = payload
        return update(state, { data: { [key]: { $set: void 0 } } })
      })
      .case(CLEAR_ALL, () => {
        return update(state, { data: { $set: {} } })
      })
      .case(SET_SELECTED_KEY, () => {
        const { key } = payload
        return update(state, { selectedKey: { $set: key } })
      })
      .case(STAGE_STICKY, () => {
        // falseを渡したときに消す
        if (payload.sticky === false) {
          return update(state, {
            data: {
              [state.selectedKey]: {
                $set: new Sticky({}),
              },
            },
          })
        }

        let stage
        if (state.data[state.selectedKey] instanceof Sticky) {
          stage = state.data[state.selectedKey].merge(payload.sticky)
        } else {
          stage = payload.sticky
        }

        return update(state, {
          data: {
            [state.selectedKey]: { $set: stage },
          },
        })
      })
      .case(STAGE_STICKY_RAW, () => {
        const updator =
          typeof payload.updator === 'function'
            ? payload.updator(update)
            : payload.updator
        return update(state, {
          data: { [state.selectedKey]: updator },
        })
      })
      .case(UPDATE_STAGE, () => {
        const { sticky } = payload
        if (!sticky) {
          return update(state, {
            data: { [state.selectedKey]: { $set: void 0 } },
          })
        }
        const staged = state.data[state.selectedKey] || new Sticky({})
        const newSticky = sticky ? staged.merge(sticky) : new Sticky({})
        return update(state, {
          data: {
            [state.selectedKey]: { $set: newSticky },
          },
        })
      })
      .default(() => state),
  )
}
