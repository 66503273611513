/**
 * StickyBoardのフッターUIを提供します。
 * @file
 */

/**
 * ライブラリ
 */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import config from 'src/config'

// components
import TotalPayments from './partials/total-payments'

/**
 map state to props
 * @param  {object} state State
 * @return {object}      mapped state
 */
const mapStateToProps = state => {
  const masterRegions = state.master.data.regions
    ? state.master.data.regions
    : []

  // 表示するものかつマスタに含まれる有効なリージョンのみを取り出す
  const displayRegionIds = ((state.userPreset.data || {}).regions || [])
    .filter(x => x.display && masterRegions.map(x => x.id).includes(x.id))
    .map(x => x.id)

  // displayRegionIdsに含まれるregionに属するssのうち、
  //   以下をdisplaySSIds としてmapする
  //   - listUserDisplayRegionSSにidが含まれない
  //   - idが含まれていてもlistUserDisplayRegionSS[].display == true

  const canonicalRegions = (
    (state.userPreset.data || {}).regions || []
  ).filter(x => displayRegionIds.includes(x.id))

  const invisibleStaffIds = canonicalRegions.reduce((prev, region) => {
    return (region.listUserDisplayRegionSS || [])
      .filter(x => x.display === false) // 明確にfalseなものだけ除く
      .map(x => x.ssId)
      .concat(prev)
  }, [])

  return {
    wara: state.wara.data,
    displayRegionIds,
    invisibleStaffIds,
  }
}

/**
 * フッターコンポーネント
 * @type {ReactComponent}
 */
export class Footer extends React.Component {
  /**
   * Validation
   * @type {object}
   */
  static propTypes = {
    // stateProps
    wara: PropTypes.shape({
      dock: PropTypes.object.isRequired,
      regions: PropTypes.array.isRequired,
    }).isRequired,
    displayRegionIds: PropTypes.array.isRequired,
    invisibleStaffIds: PropTypes.array.isRequired,
    stickyFilter: PropTypes.array.isRequired,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate() {
    return true
  }

  byCompany = sticky => {
    const { stickyFilter } = this.props
    return stickyFilter.includes(sticky.json().companyId)
  }

  /**
   * render
   * @return {ReactDOM} [ReactDOM]
   */
  render() {
    const {
      // stateProps
      wara: { dock, regions },
      displayRegionIds,
      invisibleStaffIds,
    } = this.props

    const displayRegions = regions.filter(region =>
      displayRegionIds.includes(region.id),
    )

    const lists = displayRegions
      .map(x => {
        return x.lists
      })
      // flatten
      .reduce((prev, x) => {
        return [...prev, ...x]
      }, [])
      .filter(list => !invisibleStaffIds.includes(list.staffId))

    let finished = 0
    let notFinished = 0
    ;(lists || []).forEach(list => {
      ((list || {}).stickies || []).filter(this.byCompany).forEach(sticky => {
        const stickyProps = sticky.json()
        if (stickyProps.finishStateId === config.finishState.complete) {
          finished += 1
        } else {
          notFinished += 1
        }
      })
    })
    ;(dock.stickies || []).filter(this.byCompany).forEach(sticky => {
      const stickyProps = sticky.json()
      if (stickyProps.finishStateId === config.finishState.complete) {
        finished += 1
      } else {
        notFinished += 1
      }
    })

    return (
      <div className={ 'footer' } id={ 'the-footer' }>
        <div className={ 'statics-elements' }>
          <dl>
            <dt>{'表示地域'}</dt>
            <dd>
              {displayRegions.length}
              <span className={ 'small' }>{'地域'}</span>
            </dd>
          </dl>
          <dl>
            <dt id={ 'visited-not-finished-label' }>{'未完了'}</dt>
            <dd id={ 'visited-not-finished-value' }>
              {`${notFinished}`}
              <span className={ 'small' }>{`/${notFinished + finished}枚`}</span>
            </dd>
          </dl>
          <dl>
            <dt id={ 'visited-unfinished-label' }>{'完了'}</dt>
            <dd id={ 'visited-unfinished-value' }>
              {`${finished}`}
              <span className={ 'small' }>{`/${notFinished + finished}枚`}</span>
            </dd>
          </dl>
        </div>
        <div className={ 'statics-elements' }>
          <TotalPayments displayRegionIds={ displayRegionIds } />
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps)(Footer)
