import styled from 'styled-components'
import button, { buttonLine } from './'
import { baseColor } from 'src/colors'

export const Ghost = styled.button`
  ${button};
  ${buttonLine(baseColor)};
`

export default Ghost
