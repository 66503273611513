import React from 'react'
import PropTypes from 'prop-types'
import connect from './connect'
import { NETWORK_STATUSES } from 'src/reducers/network-status'
import { isToday } from 'src/lib/validate'

export class PaymentDelay extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    updateTheDailyInfo: PropTypes.func.isRequired,
    isPaymentDelay: PropTypes.bool,
    workStarted: PropTypes.bool,
    displayDate: PropTypes.string.isRequired,
    // stateProps
    networkStatus: PropTypes.symbol.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    isPaymentDelay: false,
    workStarted: false,
  }

  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    this.state = { isNetworkTrying: false }
  }

  /**
   * UNSAFE_componentWillReceiveProps
   * @param  {object} nextProps React props.
   * @return {void}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    // リクエスト中はdisableにするための処理
    if (
      this.props.networkStatus === NETWORK_STATUSES.TRYING &&
      nextProps.networkStatus !== NETWORK_STATUSES.TRYING &&
      this.state.isNetworkTrying
    ) {
      this.setState({ isNetworkTrying: false })
    }
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.isPaymentDelay !== nextProps.isPaymentDelay ||
      this.state.isNetworkTrying !== nextState.isNetworkTrying ||
      this.props.displayDate !== nextProps.displayDate
    )
  }

  /**
   * トグルボタンを押した時のハンドラ
   * @return {void}
   */
  onToggleCkick = () => {
    const { updateTheDailyInfo, isPaymentDelay, workStarted } = this.props

    this.setState({ isNetworkTrying: true })

    if (isPaymentDelay && workStarted) {
      updateTheDailyInfo({
        isPaymentDelay: false,
        isAssignable: true,
      })
    } else {
      updateTheDailyInfo({ isPaymentDelay: !isPaymentDelay })
    }
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { isNetworkTrying } = this.state

    const { isPaymentDelay } = this.props

    const disabled = !isToday(this.props.displayDate)

    return (
      <div className={ 'control-payment-delay' }>
        <button
          className={ 'button button-change-payment-delay' }
          onClick={ this.onToggleCkick }
          disabled={ isNetworkTrying || disabled }
        >
          <i aria-hidden={ 'true' } className={ 'fa fa-refresh' } />
        </button>
        <span>{`支払い遅れ : ${isPaymentDelay ? '有' : '無'}`}</span>
      </div>
    )
  }
}

export default connect(PaymentDelay)
