import React from 'react'
import PropTypes from 'prop-types'
import connect from 'src/components/commons/custom-controls/connect'

// component
import WorkItem from './work-item'
import AddButton from './add-button'
import CalcButton from './calc-button'
import TextAreaInput from 'src/components/commons/custom-controls/assign/house-labo-client/text-area-input'
import TimeInput from 'src/components/commons/custom-controls/assign/house-labo-client/time-input'
import ItemValue from 'src/components/commons/custom-controls/assign/house-labo-client/item-value'
import CheckBox from 'src/components/commons/custom-controls/assign/house-labo-client/check-box'
import ReactDataSheet from 'react-datasheet'
import UnitType from './unit-type'
import HouseLaboWork from './house-labo-work'
import ExportButton from './export-button'
import DeleteButton from './delete-button'

// lib
import Sticky from 'src/lib/class-sticky'
import styled from 'styled-components'
import { borderDarkGray, lightGreen } from 'src/colors'
import { toJPYenText, yenToValue } from 'src/lib/format'
import {
  hlMasterFromFeeId,
  hlMasterFromInputType,
  isPaymentMaster,
  isIntroduceMaster,
  salesTax,
  calcClientTotal,
  calcTotal,
  calcIntroduction,
} from './util'
import config from 'src/config'
import 'react-datasheet/lib/react-datasheet.css'
import createClassNames from 'classnames'
import moment from 'moment'
import { Box } from '@mui/material'

export const Hr = styled.hr`
  height: 1px;
  border: 0;
  background-color: ${borderDarkGray};
`

const hlSalesStyle = {
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
}

// セルの値の型
const HLTypeText = 0 // 文字列
const HLTypeNumber = 1 // 数値
const HLTypePayment = 2 // 金額
const HLTypeDistance = 3 // 距離

/**
 * ハウスラボ
 * @type {ReactComponent}
 */
export class HouseLabo extends React.Component {
  /**
   * Validation
   * @type {object}
   */
  static propTypes = {
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    route: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    contractAmount: PropTypes.object,
    // state props
    master: PropTypes.object.isRequired,
    // Auto bind action creator
    updateStage: PropTypes.func.isRequired,
  }

  static defaultProps = {
    contractAmount: {
      total: {
        totalContract: 0,
        account: 0,
      },
      collected: {
        accountCollected: 0,
      },
    },
  }

  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    // 金額の選択状態初期値
    this.state = {
      houselaboFeesSelect: [false, false, false],
      houselaboExtraFeesSelect: true,
    }
  }

  /**
   *
   */
  componentDidMount() {
    this.initialSelectState()
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return (
      this.props.sticky !== nextProps.sticky ||
      this.props.disabled !== nextProps.disabled ||
      this.props.contractAmount !== nextProps.contractAmount
    )
  }

  /**
   * 金額のトグルの初期状態を設定する。
   */
  initialSelectState = () => {
    const { sticky } = this.props
    const stickyProps = sticky.json() || {}
    const houseLaboFees = stickyProps.houseLaboFees || []
    const houseLaboExtraFees = stickyProps.houseLaboExtraFees || []
    const houseLaboClientMaster = stickyProps.houseLaboClientMaster || {}
    const masterHouseLaboFees = houseLaboClientMaster.houseLaboFees || []
    // 初期値
    const houselaboFeesSelect = new Array(masterHouseLaboFees.length).fill(true)

    let houselaboExtraFeesSelect = true
    // 全ての金額がチェックされている時はクリックすると全クリアされるようにする。
    // どれか一つでも未チェックがあればクリックすると全チェックされる。
    for (let i = 0; i < masterHouseLaboFees.length; i++) {
      const masters = masterHouseLaboFees[i]
      for (let j = 0; j < masters.length; j++) {
        const master = masters[j]
        const fee = houseLaboFees.find(fee => fee.feeId === master.feeId) || {}
        houselaboFeesSelect[i] =
          houselaboFeesSelect[i] && (fee.isExport || false)
      }
    }
    for (let i = 0; i < houseLaboExtraFees; i++) {
      houselaboExtraFeesSelect =
        houselaboExtraFeesSelect && (houselaboFeesSelect.isExport || false)
    }
    // stateに設定する。
    this.setState({
      houselaboFeesSelect,
      houselaboExtraFeesSelect,
    })
  }

  /**
   * 主にテーブルで入力不可の状態をチェックするために使用
   */
  isInputDisabled = () => {
    const { sticky, disabled } = this.props
    const stickyProps = sticky.json() || {}
    const houseLabo = stickyProps.houseLabo || {}
    const workReport = houseLabo.workReport || false // 報告書確認済み
    return disabled || workReport
  }

  /**
   * ハウスラボクライアントがグランのものであるか判定する。
   * @param {*} sticky
   */
  isGran = sticky => {
    const stickyProps = sticky.json() || {}
    const houseLaboClientMaster = stickyProps.houseLaboClientMaster || {}
    const masterHouseLaboFees = houseLaboClientMaster.houseLaboFees || []
    const masterHouseLaboFeesG = masterHouseLaboFees[2] || []
    return masterHouseLaboFeesG.length > 0
  }

  /**
   * ハウスラボデータの更新
   * @param {*} key
   * @param {*} value
   */
  updateHouseLabo = (key, value) => {
    const { sticky, updateStage } = this.props
    const stickyProps = sticky.json() || {}
    const houseLabo = stickyProps.houseLabo || {}
    const diffProps = {
      houseLabo: {
        id: houseLabo.id || null,
        [key]: value,
      },
    }
    updateStage(new Sticky(diffProps))
  }

  /**
   * 作業箇所の更新
   * @param {*} index
   * @param {*} key
   * @param {*} value
   */
  updateHouseLaboWorks = (index, key, value) => {
    const { sticky, updateStage } = this.props
    const stickyProps = sticky.json() || {}
    const houseLaboWorks = stickyProps.houseLaboWorks || []
    houseLaboWorks[index] = {
      ...houseLaboWorks[index],
      [key]: value,
    }
    const diffProps = { houseLaboWorks: [...houseLaboWorks] }
    updateStage(new Sticky(diffProps))
  }

  /**
   * ①②の金額データを更新する共通処理。feeIdがない時は追加する
   * @param {*} houseLaboFees
   * @param {*} feeId
   * @param {*} key
   * @param {*} value
   */
  updateInsertHouseLaboFee = (houseLaboFees, feeId, key, value) => {
    // 入力不可になった時は何もしない
    if (this.isInputDisabled()) {
      return
    }
    if (houseLaboFees.some(fee => fee.feeId === feeId)) {
      houseLaboFees.forEach(fee =>
        fee.feeId === feeId ? (fee[key] = value) : false,
      )
    } else {
      houseLaboFees.push({ feeId, [key]: value })
    }
  }

  /**
   * 金額データの更新　①、②
   * @param {*} feeId 金額項目のID
   * @param {*} key 金額の項目キー
   * @param {*} value 設定値
   */
  updateHouseLaboFees = (feeId, key, value) => {
    const { sticky, updateStage } = this.props
    const stickyProps = sticky.json() || {}
    const houseLabo = stickyProps.houseLabo || {}
    const houseLaboFees = stickyProps.houseLaboFees || []
    const houseLaboExtraFees = stickyProps.houseLaboExtraFees || []
    const houseLaboClientMaster = stickyProps.houseLaboClientMaster || {}
    const masterHouseLaboFees = houseLaboClientMaster.houseLaboFees || []
    // ---------------------------------------------------------------
    // 対象の金額を更新する
    this.updateInsertHouseLaboFee(houseLaboFees, feeId, key, value)
    // 該当する紹介料をクリアする
    this.clearIntroByHouseLaboFee(
      masterHouseLaboFees,
      houseLaboFees,
      feeId,
      key,
    )
    // 合計金額を再計算して保存する
    const clientTotal = calcClientTotal(
      masterHouseLaboFees,
      houseLabo,
      houseLaboFees,
      houseLaboExtraFees,
      this.isGran(sticky),
    )
    const diffProps = {
      houseLabo: {
        id: houseLabo.id || null,
        invoice: clientTotal.total,
      },
      houseLaboFees: [...houseLaboFees],
    }
    updateStage(new Sticky(diffProps))
  }

  /**
   * その他費用の更新　③
   * @param {*} index
   * @param {*} key
   * @param {*} value
   */
  updateHouseLaboExtraFees = (index, key, value) => {
    const { sticky, updateStage } = this.props
    const stickyProps = sticky.json() || {}
    const houseLabo = stickyProps.houseLabo || {}
    const houseLaboFees = stickyProps.houseLaboFees || []
    const houseLaboExtraFees = stickyProps.houseLaboExtraFees || []
    const houseLaboClientMaster = stickyProps.houseLaboClientMaster || {}
    const masterHouseLaboFees = houseLaboClientMaster.houseLaboFees || []
    //
    houseLaboExtraFees[index] = {
      ...houseLaboExtraFees[index],
      [key]: value,
    }
    // 合計金額を再計算して保存する。単位以外を変更した時消費税は10%で再計算、単位を変更したときは元の消費税を使う。
    const extraFeeTax = key === 'unitId' ? houseLabo.extraFeeTax || 0 : 0
    const clientTotal = calcClientTotal(
      masterHouseLaboFees,
      { extraFeeTax },
      houseLaboFees,
      houseLaboExtraFees,
    )
    // 単位を変更した時、消費税は元のものを使う。それ以外の時は10%で再計算する。
    const diffProps = {
      houseLabo: {
        id: houseLabo.id || null,
        extraFeeTax:
          key === 'unitId'
            ? houseLabo.extraFeeTax || 0
            : salesTax(clientTotal.extraFeeTotal),
        invoice: clientTotal.total,
      },
      houseLaboExtraFees: [...houseLaboExtraFees],
    }
    updateStage(new Sticky(diffProps))
  }

  /**
   * テキスト項目の変更ハンドラ
   * @param {*} e
   * @param {*} key
   */
  updateHouseLaboText = (e, key) => this.updateHouseLabo(key, e.target.value)

  /**
   * 開始・終了時刻の変更ハンドラ
   * @param {*} time
   * @param {*} key
   */
  updateHouseLaboTime = (time, key) => {
    const htime = time.replace(/\s/g, '')
    if (htime !== '') {
      this.updateHouseLabo(key, htime)
    }
  }

  /**
   * 二人工判別変更のコールバック
   * @param  {Event} e event
   */
  updateHouseLaboTwoPerson = e => {
    const val = parseInt(e.target.value, 10)
    this.updateHouseLabo('twoPersonWorkTypeId', val)
  }
  /**
   * 「二次対応予定調整中」の変更ハンドラ
   * @param {*} e
   */
  updateHouseLabosecondaryWork = e =>
    this.updateHouseLabo('secondaryWork', e.target.checked)

  /**
   * 「個別請求書発送要」の変更ハンドラ
   * @param {*} e
   */
  updateHouseLaboNeedsInvoice = e => {
    // 「個別請求書発送要」のチェックを外したら「個別請求書発送済」のチェックも外す
    const { sticky, updateStage } = this.props
    const stickyProps = sticky.json() || {}
    const houseLabo = stickyProps.houseLabo || {}
    const needsInvoice = e.target.checked
    const needsInvoiceSend = e.target.checked
      ? houseLabo.needsInvoiceSend
      : false
    const diffProps = {
      houseLabo: {
        id: houseLabo.id || null,
        needsInvoice,
        needsInvoiceSend,
      },
    }
    updateStage(new Sticky(diffProps))
  }

  /**
   * 「個別請求書発送済」の変更ハンドラ
   * @param {*} e
   */
  updateHouseLaboNeedsInvoiceSend = e =>
    this.updateHouseLabo('needsInvoiceSend', e.target.checked)

  /**
   * 追加作業の更新
   * @param {*} i 追加作業のインデックス
   * @param {*} value 選択肢
   * @param {*} text value=その他の時入力したテキスト
   */
  updateAdditionalWorks = (i, value, text) => {
    const { sticky, updateStage } = this.props
    const stickyProps = sticky.json() || {}
    const houseLabo = stickyProps.houseLabo || {}
    const additionalWorks = houseLabo.additionalWorks || [{}]
    additionalWorks[i] = {
      ...additionalWorks[i],
      workTypeId: value,
      freeDescribe: text,
    }
    houseLabo.additionalWorks = additionalWorks
    const diffProps = { houseLabo }
    updateStage(new Sticky(diffProps))
  }

  /**
   * 追加作業の追加
   */
  addAdditionalWork = () => {
    const { sticky, updateStage } = this.props
    const stickyProps = sticky.json() || {}
    const houseLabo = stickyProps.houseLabo || {}
    const additionalWorks = houseLabo.additionalWorks || []
    additionalWorks.push({
      workTypeId: -1,
      freeDescribe: null,
    })
    houseLabo.additionalWorks = additionalWorks
    const diffProps = { houseLabo }
    updateStage(new Sticky(diffProps))
  }

  /**
   * 作業箇所の追加
   */
  addHouseLaboWorks = () => {
    const { sticky, updateStage } = this.props
    const stickyProps = sticky.json() || {}
    const houseLaboWorks = stickyProps.houseLaboWorks || []
    houseLaboWorks.push({
      id: null,
      condition: '',
      cause: '',
      result: '',
      remark: '',
    })
    const diffProps = { houseLaboWorks: [...houseLaboWorks] }
    updateStage(new Sticky(diffProps))
  }

  /**
   * 作業箇所の削除
   * @param {*} index
   */
  removeHouseLaboWorks = index => {
    const { sticky, updateStage } = this.props
    const stickyProps = sticky.json() || {}
    const houseLaboWorks = stickyProps.houseLaboWorks || []
    houseLaboWorks.splice(index, 1)
    const diffProps = { houseLaboWorks: [...houseLaboWorks] }
    updateStage(new Sticky(diffProps))
  }

  /**
   * 作業箇所のテキスト変更
   * @param {*} e
   * @param {*} index
   * @param {*} key
   */
  updateHouseLaboWorkText = (e, index, key) =>
    this.updateHouseLaboWorks(index, key, e.target.value)

  /**
   * その他費用の追加
   */
  addHouseLaboExtraFees = () => {
    const { sticky, updateStage, master } = this.props
    const stickyProps = sticky.json() || {}
    const houseLaboExtraFees = stickyProps.houseLaboExtraFees || []
    houseLaboExtraFees.push({
      id: null,
      name: '',
      unitFee: 0,
      number: 0,
      unitId: master.hlUnitType[0].id,
      remark: '',
      isExport: true,
    })
    const diffProps = { houseLaboExtraFees: [...houseLaboExtraFees] }
    updateStage(new Sticky(diffProps))
  }

  /**
   * その他費用の削除
   * @param {*} index
   */
  removeHouseLaboExtraFees = index => {
    const { sticky, updateStage } = this.props
    const stickyProps = sticky.json() || {}
    const houseLabo = stickyProps.houseLabo || {}
    const houseLaboFees = stickyProps.houseLaboFees || []
    const houseLaboExtraFees = stickyProps.houseLaboExtraFees || []
    const houseLaboClientMaster = stickyProps.houseLaboClientMaster || {}
    const masterHouseLaboFees = houseLaboClientMaster.houseLaboFees || []
    houseLaboExtraFees.splice(index, 1)
    // 合計金額を再計算して保存する。消費税は10%で再計算
    const clientTotal = calcClientTotal(
      masterHouseLaboFees,
      { extraFeeTax: 0 },
      houseLaboFees,
      houseLaboExtraFees,
    )
    const diffProps = {
      houseLabo: {
        id: houseLabo.id || null,
        extraFeeTax: salesTax(clientTotal.extraFeeTotal),
        invoice: clientTotal.total,
      },
      houseLaboExtraFees: [...houseLaboExtraFees],
    }
    updateStage(new Sticky(diffProps))
  }

  /**
   * 金額の「出力」を一括で更新する
   * @param {*} master 表示するマスターデータ
   * @param {*} index マスターデータのインデックス
   */
  toggleHouseLaboFees = (master, index) => {
    const { sticky, updateStage } = this.props
    const { houselaboFeesSelect } = this.state
    const stickyProps = sticky.json() || {}
    const houseLabo = stickyProps.houseLabo || {}
    const houseLaboFees = stickyProps.houseLaboFees || []
    const houseLaboExtraFees = stickyProps.houseLaboExtraFees || []
    const houseLaboClientMaster = stickyProps.houseLaboClientMaster || {}
    const masterHouseLaboFees = houseLaboClientMaster.houseLaboFees || []
    //
    const isExport = !houselaboFeesSelect[index]
    houselaboFeesSelect[index] = isExport
    master.forEach(item => {
      this.updateInsertHouseLaboFee(
        houseLaboFees,
        item.feeId,
        'isExport',
        isExport,
      )
    })
    this.setState({ houselaboFeesSelect: [...houselaboFeesSelect] })
    // 合計金額を再計算して保存する
    const clientTotal = calcClientTotal(
      masterHouseLaboFees,
      houseLabo,
      houseLaboFees,
      houseLaboExtraFees,
      this.isGran(sticky),
    )
    const diffProps = {
      houseLabo: {
        id: houseLabo.id || null,
        invoice: clientTotal.total,
      },
      houseLaboFees: [...houseLaboFees],
    }
    updateStage(new Sticky(diffProps))
  }

  /**
   * 追加金額の「出力」を一括で更新する
   * @param {*} master 表示するマスターデータ
   */
  toggleHouseLaboExtraFees = () => {
    const { sticky, updateStage } = this.props
    const { houselaboExtraFeesSelect } = this.state
    const stickyProps = sticky.json() || {}
    const houseLabo = stickyProps.houseLabo || {}
    const houseLaboFees = stickyProps.houseLaboFees || []
    const houseLaboExtraFees = stickyProps.houseLaboExtraFees || []
    const houseLaboClientMaster = stickyProps.houseLaboClientMaster || {}
    const masterHouseLaboFees = houseLaboClientMaster.houseLaboFees || []
    //
    const isExport = !houselaboExtraFeesSelect
    this.setState({ houselaboExtraFeesSelect: isExport })
    const extraFees = houseLaboExtraFees.map(fee => ({ ...fee, isExport }))
    // 合計金額を再計算して保存する。消費税は10%で再計算
    const clientTotal = calcClientTotal(
      masterHouseLaboFees,
      { extraFeeTax: 0 },
      houseLaboFees,
      extraFees,
      this.isGran(sticky),
    )
    const diffProps = {
      houseLabo: {
        id: houseLabo.id || null,
        extraFeeTax: salesTax(clientTotal.extraFeeTotal),
        invoice: clientTotal.total,
      },
      houseLaboExtraFees: extraFees,
    }
    updateStage(new Sticky(diffProps))
  }

  /**
   * 付箋のデータをグリッド用のデータに変換する：①、②共通
   * @param {*} houseLaboFeesMaster ハウスラボクライアントマスタの金額データ　①/②
   * @param {*} houseLaboFees 付箋の金額情報
   * @param {*} index ハウスラボクライアントマスタのインデックス
   * @param {*} isGran グラン専用の時true
   */
  makeHouseLaboFeesGrid = (
    houseLaboFeesMaster,
    houseLaboFees,
    index,
    isGran,
  ) => {
    // 標準属性
    // value: 表示する値
    // component: 表示するコンポーネント
    // readOnly: 編集不可の時はtrue
    // width: セルの幅
    // カスタム属性
    // hlheader: 見出しの時true
    // hlnoboder: 枠線を消すときtrue
    // hlcomponent: valueではなくcomponentを表示する時true
    // hltype: セルのデータ型（数値・文字列など）
    // hlfeeid: セルの項目ID
    // hltotalvalue: 合計金額の欄

    const inputDisabled = this.isInputDisabled()
    // ---------------------------------------------------------------
    // 金額の列を作成する
    const valueItem = (master, fee) => {
      const item = {
        hlfeeid: master.feeId, // 項目ID
      }
      if (master.inputType === 2) {
        // 距離
        item.value = fee.fee || 0
        item.hltype = HLTypeDistance
      } else if (master.inputType === 3) {
        // 区間
        item.value = fee.fee || ''
        item.hltype = HLTypeText
      } else if (master.inputType === 11) {
        // 文字列
        item.value = fee.fee || ''
        item.hltype = HLTypeText
      } else {
        // 金額
        item.value = fee.fee || 0
        item.hltype = HLTypePayment
      }
      return item
    }

    // ---------------------------------------------------------------
    // 出力の列を作成する
    const exportItem = (master, fee) => {
      const item = {
        hlfeeid: master.feeId, // 項目ID
        hlcomponent: 'true',
        component: (
          <ExportButton
            isExport={ fee.isExport || false }
            // eslint-disable-next-line react/jsx-no-bind
            onClick={ value =>
              this.updateHouseLaboFees(master.feeId, 'isExport', value)
            }
            disabled={ inputDisabled }
          />
        ),
      }
      return item
    }

    // ---------------------------------------------------------------
    // 計算機ボタンの列を作成する
    const calcItem = (sticky, master, contractAmount) => {
      // 計算対象の項目の時のみボタンを表示する
      const noCalcInputTypes = [1, 2, 3, 11]
      if (noCalcInputTypes.includes(master.inputType)) {
        return { value: '', readOnly: true }
      } else {
        return {
          component: (
            <CalcButton
              inputType={ master.inputType }
              params={ master.params }
              sticky={ sticky }
              contractAmount={ contractAmount }
              // eslint-disable-next-line react/jsx-no-bind
              onDecide={ value =>
                this.updateHouseLaboFees(master.feeId, 'fee', value)
              }
              disabled={ inputDisabled }
            />
          ),
          hlcomponent: 'true',
        }
      }
    }

    // ---------------------------------------------------------------
    // 見出し

    const buttonClassNames = createClassNames({
      button: true,
      'button-open': true,
      'label-inputs-wrap-button': true,
      'button-disabled': false,
    })

    const head = [
      [
        { value: '項目', readOnly: true, hlheader: 'true', width: 280 },
        {
          value: '金額（税込）',
          readOnly: true,
          hlheader: 'true',
          width: 240,
        },
        {
          readOnly: true,
          hlheader: 'true',
          width: 40,
          hlcomponent: 'true',
          component: (
            <span
              className={ buttonClassNames }
              style={ { border: 0, fontSize: '20px' } }
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => {
                this.toggleHouseLaboFees(houseLaboFeesMaster, index)
              } }
            >
              {this.state.houselaboFeesSelect[index] ? (
                <i className={ 'fa fa-check-square-o' } />
              ) : (
                <i className={ 'fa fa-square-o' } />
              )}
            </span>
          ),
        }, // 作業報告書出力
        { value: '', readOnly: true, hlheader: 'true', width: 40 }, // 計算機ボタン
      ],
    ]

    // ---------------------------------------------------------------
    // 表示のためのデータを作成する
    let total = 0 // グループごとの合計金額
    const { sticky, contractAmount } = this.props
    const grid = head.concat(
      houseLaboFeesMaster.map(master => {
        const fee = houseLaboFees.find(fee => fee.feeId === master.feeId) || {}
        const row = []
        // 項目
        row.push({
          value: master.name,
          readOnly: true,
          hlheader: 'true',
        })
        // 金額
        row.push(valueItem(master, fee))
        if (
          isPaymentMaster(master) &&
          (isIntroduceMaster(master) || fee.isExport)
        ) {
          // 紹介料 || 出力する金額
          total += parseInt(fee.fee || '0', 10)
        }
        // 出力
        row.push(exportItem(master, fee))
        // 計算機ボタン
        row.push(calcItem(sticky, master, contractAmount))
        return row
      }),
    )
    // 合計金額の列を追加する。高さ調整のため、最後の列にダミーのdivを追加。
    grid.push([
      isGran
        ? {
          value: index === 2 ? '上記項目合計金額' : '上記項目小計',
          readOnly: true,
          hlheader: 'true',
        }
        : {
          value: '',
          readOnly: true,
          hlnoboder: 'true',
        },
      { value: toJPYenText(total), readOnly: true, hltotalvalue: 'true' },
      { value: '', readOnly: true, hlnoboder: 'true' },
      {
        hlcomponent: 'true',
        component: (
          <div
            style={ {
              height: '24px',
            } }
          >
            {''}
          </div>
        ),
        hlnoboder: 'true',
      },
    ])
    return grid
  }

  /**
   * 付箋のデータをグリッド用のデータに変換する：③
   * @param {*} houseLaboExtraFees その他の費用情報
   * @param {*} houseLabo 消費税を取得するために使う
   * @param {*} isGran グラン用の表示の時true
   */
  makeHouseLaboExtraFeesGrid = (houseLaboExtraFees, houseLabo, isGran) => {
    // 標準属性
    // value: 表示する値
    // component: 表示するコンポーネント
    // readOnly: 編集不可の時はtrue
    // width: セルの幅
    // カスタム属性
    // hlheader: 見出しの時true
    // hlnoboder: 枠線を消すときtrue
    // hlcomponent: valueではなくcomponentを表示する時true
    // hltype: セルのデータ型（数値・文字列など）
    // hlfeeindex: 金額のインデックス
    // hltotalvalue: 合計金額の欄
    // hlextrafeetax: 消費税

    const inputDisabled = this.isInputDisabled()
    /**
     * その他費用の列を作成し、合計金額を計算する。
     * @param {*} exfee その他費用
     * @param {*} index 費用のインデックス
     */
    const exfeeRow = (exfee, index, isGran) => {
      // "houseLaboExtraFees": [   //　その他の費用
      //   {
      //     "name": "商品名", 　　 // 商品名、型式、作業内容
      //     "unitFee": 1000,     // 単価(税抜き)
      //     "number": 1,         // 数量
      //     "unitId": 1,         // 単位タイプ
      //     "remark": "備考",     // 備考
      //     "isExport": true,  　 // 作業報告書出力有無
      //   }
      const row = []
      // 商品名
      row.push({
        hlfeeindex: index,
        value: exfee.name,
        hltype: HLTypeText,
      })
      // 単価
      row.push({
        hlfeeindex: index,
        value: exfee.unitFee || 0,
        hltype: HLTypePayment,
      })
      // 数量
      row.push({
        hlfeeindex: index,
        value: exfee.number || 0,
        hltype: HLTypeNumber,
      })
      // 単位
      row.push({
        hlfeeindex: index,
        hlcomponent: 'true',
        component: (
          <div
            style={ {
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            } }
          >
            <UnitType
              value={ exfee.unitId || -1 }
              // eslint-disable-next-line react/jsx-no-bind
              onChange={ value =>
                this.updateHouseLaboExtraFees(index, 'unitId', value)
              }
              route={ this.props.route }
              disabled={ this.isInputDisabled() }
            />
          </div>
        ),
      })
      // 金額
      const cash = (exfee.unitFee || 0) * (exfee.number || 0)
      if (exfee.isExport) {
        total += cash
      }
      row.push({
        hlfeeindex: index,
        value: cash,
        hltype: HLTypePayment,
      })
      // 備考
      isGran ||
        row.push({
          hlfeeindex: index,
          value: exfee.remark || '',
          hltype: HLTypeText,
        })
      // 出力
      row.push({
        hlcomponent: 'true',
        component: (
          <ExportButton
            isExport={ exfee.isExport || false }
            // eslint-disable-next-line react/jsx-no-bind
            onClick={ value => {
              this.updateHouseLaboExtraFees(index, 'isExport', value)
              this.clearIntroByExtraFee()
            } }
            disabled={ inputDisabled }
          />
        ),
      })
      // 削除
      row.push({
        hlcomponent: 'true',
        component: (
          <DeleteButton
            // eslint-disable-next-line react/jsx-no-bind
            onClick={ () => this.removeHouseLaboExtraFees(index) }
            disabled={ inputDisabled }
          />
        ),
      })
      return row
    }

    /**
     * 合計金額を表示するための列を作成する
     * @param {*} title 合計行のタイトル
     * @param {*} value 表示する金額
     */
    const totalRow = (title, value, isGran) => {
      const row = []
      row.push({ value: '', readOnly: true, hlnoboder: 'true' })
      row.push({ value: '', readOnly: true, hlnoboder: 'true' })
      row.push({ value: '', readOnly: true, hlnoboder: 'true' })
      row.push({ value: title, readOnly: true })
      row.push({ value: value, readOnly: true, hltotalvalue: 'true' })
      isGran || row.push({ value: '', readOnly: true, hlnoboder: 'true' }) // 備考
      row.push({ value: '', readOnly: true, hlnoboder: 'true' }) // 出力
      row.push({ value: '', readOnly: true, hlnoboder: 'true' }) // 削除
      return row
    }

    /**
     * 合計金額の消費税の入力行
     * @param {*} title
     * @param {*} value
     * @param {*} isGran
     */
    const totalTaxRow = (title, value, isGran) => {
      const row = []
      row.push({ value: '', readOnly: true, hlnoboder: 'true' })
      row.push({ value: '', readOnly: true, hlnoboder: 'true' })
      row.push({ value: '', readOnly: true, hlnoboder: 'true' })
      row.push({ value: title, readOnly: true })
      row.push({ value: value, hltype: HLTypePayment, hlextrafeetax: 'true' })
      isGran || row.push({ value: '', readOnly: true, hlnoboder: 'true' }) // 備考
      row.push({ value: '', readOnly: true, hlnoboder: 'true' }) // 出力
      row.push({ value: '', readOnly: true, hlnoboder: 'true' }) // 削除
      return row
    }

    // ---------------------------------------------------------------
    // 見出し
    const makeHead = isGran => {
      const head = []
      const headRow = []
      const buttonClassNames = createClassNames({
        button: true,
        'button-open': true,
        'label-inputs-wrap-button': true,
        'button-disabled': false,
      })
      headRow.push({
        value: isGran ? '特別料金項目' : '商品名・型式・作業内容',
        readOnly: true,
        hlheader: 'true',
        width: 240,
      })
      headRow.push({
        value: '単価',
        readOnly: true,
        hlheader: 'true',
        width: 240,
      })
      headRow.push({
        value: '数量',
        readOnly: true,
        hlheader: 'true',
        width: 120,
      })
      headRow.push({
        value: '単位',
        readOnly: true,
        hlheader: 'true',
        width: 120,
      })
      headRow.push({
        value: '金額（税抜）',
        readOnly: true,
        hlheader: 'true',
        width: 120,
      })
      isGran ||
        headRow.push({
          value: '備考',
          readOnly: true,
          hlheader: 'true',
          width: 240,
        })
      // 出力
      headRow.push({
        readOnly: true,
        hlheader: 'true',
        width: 40,
        hlcomponent: 'true',
        component: (
          <span
            className={ buttonClassNames }
            style={ { border: 0, fontSize: '20px' } }
            onClick={ this.toggleHouseLaboExtraFees }
          >
            {this.state.houselaboExtraFeesSelect ? (
              <i className={ 'fa fa-check-square-o' } />
            ) : (
              <i className={ 'fa fa-square-o' } />
            )}
          </span>
        ),
      })
      // 削除
      headRow.push({ value: '', readOnly: true, hlheader: 'true', width: 40 })
      //
      head.push(headRow)
      return head
    }
    const head = makeHead(isGran)

    // ---------------------------------------------------------------
    // 表示のためのデータを作成する
    let total = 0 // 小計
    const grid = head.concat(
      houseLaboExtraFees.map((exfee, index) => exfeeRow(exfee, index, isGran)),
    )
    // 合計金額を計算し、列を追加する。
    grid.push(totalRow('小計', toJPYenText(total), isGran))
    // 消費税が未入力の時は小計をもとに計算した値を表示する
    const tax = houseLabo.extraFeeTax || salesTax(total)
    grid.push(totalTaxRow('消費税(10%)', tax, isGran))
    grid.push(totalRow('合計金額', toJPYenText(total + tax), isGran))
    return grid
  }

  /**
   * 値の表示方法をカスタマイズする
   * @param {*} cell
   */
  valueRenderer = cell => {
    if (cell.hltype === HLTypePayment) {
      // 金額の時は￥マークをつける
      return toJPYenText(parseInt(cell.value, 10))
    } else if (cell.hltype === HLTypeDistance) {
      return `${cell.value}km`
    } else if (cell.hlcomponent) {
      // コンポーネントを表示する
      return cell.component
    }
    return cell.value
  }

  /**
   * セルの値を編集する時はそのまま表示する
   * @param {*} cell
   */
  dataRenderer = cell => {
    return cell.value
  }

  /**
   * セルの属性を置き換えることでスタイルを上書きする。
   * @param {*} cell
   */
  attributesRenderer = cell => {
    // 共通プロパティ
    const commonStyle = {
      color: 'black',
      padding: '0.5rem 0 0.5rem 0',
      border: '#78909c 1px solid',
      verticalAlign: 'middle',
    }
    if (cell.hlheader) {
      // 表のヘッダ
      return {
        ...cell,
        style: {
          ...commonStyle,
          background: 'linear-gradient(#eceff1, #cfd8dc)',
          fontWeight: 'bold',
        },
      }
    } else if (cell.hltotalvalue) {
      // 追加金額の合計欄を特別扱いする
      const val = yenToValue(cell.value)
      const color = isNaN(val) || val >= 0 ? 'black' : 'red'
      return {
        ...cell,
        style: {
          ...commonStyle,
          color,
          background: 'white',
          textAlign: 'right',
          paddingRight: '6px',
        },
      }
    } else if (cell.hlnoboder) {
      // 枠線を削除する
      return {
        ...cell,
        style: {
          ...commonStyle,
          border: 0,
          background: 'white',
        },
      }
    } else {
      // 値を編集する項目
      const color =
        cell.hltype === HLTypePayment && yenToValue(cell.value) < 0
          ? 'red'
          : 'black'
      // 入力不可の時背景色を変える
      const background = this.isInputDisabled() ? lightGreen : 'white'
      return {
        ...cell,
        style: {
          ...commonStyle,
          color,
          background,
          paddingRight: '6px',
        },
      }
    }
  }

  /**
   * ①、②　セルの値が変更された時付箋に反映する
   * @param {*} changes
   */
  onPaymentCellsChanged = changes => {
    // 入力不可になった時は何もしない
    if (this.isInputDisabled()) {
      return
    }
    const { sticky, updateStage } = this.props
    const stickyProps = sticky.json() || {}
    const houseLabo = stickyProps.houseLabo || {}
    const houseLaboFees = stickyProps.houseLaboFees || []
    const houseLaboExtraFees = stickyProps.houseLaboExtraFees || []
    const houseLaboClientMaster = stickyProps.houseLaboClientMaster || {}
    const masterHouseLaboFees = houseLaboClientMaster.houseLaboFees || []
    // セルのタイプに応じて値を変換する
    const toValue = (cell, value) => {
      if (cell.hltype === HLTypeText) {
        return value
      } else {
        // 数値として処理できる時は数値化→文字列変換する。数値にならない時は '0'を設定する
        // 数字の後に文字列を入力できたので、数値だけを文字列変換して保存する。
        return isNaN(parseInt(value, 10)) ? '0' : parseInt(value, 10).toString()
      }
    }
    // 実質col === 1の時だけが対象。houseLaboFees[{feeId=cell.hlfeeid}]の値を更新
    changes.forEach(({ col, cell, value }) => {
      if (col === 1) {
        const val = toValue(cell, value)
        this.updateInsertHouseLaboFee(houseLaboFees, cell.hlfeeid, 'fee', val)
        // 該当する紹介料をクリアする
        this.clearIntroByHouseLaboFee(
          masterHouseLaboFees,
          houseLaboFees,
          cell.hlfeeid,
          'fee',
        )
      }
    })
    // 合計金額を再計算して保存する
    const clientTotal = calcClientTotal(
      masterHouseLaboFees,
      houseLabo,
      houseLaboFees,
      houseLaboExtraFees,
      this.isGran(sticky),
    )
    const diffProps = {
      houseLabo: {
        ...houseLabo,
        id: houseLabo.id || null,
        invoice: clientTotal.total,
      },
      houseLaboFees: [...houseLaboFees],
    }
    updateStage(new Sticky(diffProps))
  }

  /**
   * ③　セルの値が変更された時付箋に反映する
   * @param {*} changes
   */
  onExtraPaymentCellsChanged = changes => {
    // 入力不可になった時は何もしない
    if (this.isInputDisabled()) {
      return
    }
    const { sticky, updateStage } = this.props
    const stickyProps = sticky.json() || {}
    const houseLabo = stickyProps.houseLabo || {}
    const houseLaboFees = stickyProps.houseLaboFees || []
    const houseLaboExtraFees = stickyProps.houseLaboExtraFees || []
    const houseLaboClientMaster = stickyProps.houseLaboClientMaster || {}
    const masterHouseLaboFees = houseLaboClientMaster.houseLaboFees || []
    // セルのタイプに応じて値を変換する
    const toValue = (cell, value) => {
      if (cell.hltype === HLTypeText) {
        return value
      } else {
        // 数値として処理できる時は数値化する。数値にならない時は 0を設定する
        return isNaN(parseInt(value, 10)) ? 0 : parseInt(value, 10)
      }
    }
    // houseLaboFees[cell.hlfeeindex]の値を更新
    // 列と項目名の対応。ない項目はここでは更新されない。
    const keys = ['name', 'unitFee', 'number', null, null, 'remark']
    let extax = 0
    changes.forEach(({ col, cell, value }) => {
      if (cell.hlextrafeetax) {
        if (col === 4) {
          // これ以外は変更されない
          extax = toValue(cell, value)
        }
      } else {
        const val = toValue(cell, value)
        const index = cell.hlfeeindex
        const key = keys[col] || '-'
        if (key !== '-') {
          houseLaboExtraFees[index] = {
            ...houseLaboExtraFees[index],
            [key]: val,
          }
          if (key === 'unitFee' || key === 'number') {
            // 単価または数量が変更された時クライアント紹介料と現場紹介料をクリアする
            this.clearIntroByExtraFee()
          }
          if (key === 'name' || key === 'remark') {
            // 名前または備考が変更された時、消費税は今の設定を引き継ぐ
            extax = houseLabo.extraFeeTax || 0
          }
        }
      }
    })
    // 合計金額を再計算して保存する
    const clientTotal = calcClientTotal(
      masterHouseLaboFees,
      { extraFeeTax: extax }, // 入力した消費税で計算する
      houseLaboFees,
      houseLaboExtraFees,
      this.isGran(sticky),
    )
    // 消費税が未設定（クリア）の時は再計算する
    const extraFeeTax = extax || salesTax(clientTotal.extraFeeTotal)
    // 消費税と合計金額を更新する
    const diffProps = {
      houseLabo: {
        ...houseLabo,
        id: houseLabo.id || null,
        invoice: clientTotal.total,
        extraFeeTax,
      },
      houseLaboExtraFees: [...houseLaboExtraFees],
    }
    updateStage(new Sticky(diffProps))
  }

  /**
   * ①②の金額が更新される時、該当する紹介料をクリアする
   * @param {*} masterHouseLaboFees
   * @param {*} houseLaboFees
   * @param {*} feeId 更新する金額のID
   */
  clearIntroByHouseLaboFee = (
    masterHouseLaboFees,
    houseLaboFees,
    feeId,
    key,
  ) => {
    const feeMaster = hlMasterFromFeeId(masterHouseLaboFees, feeId)
    if (feeMaster) {
      // feeIDが紹介料の時は自分をクリアしない
      if (isIntroduceMaster(feeMaster)) {
        return
      }
      // クリア対象となるターゲットタイプ
      const clearInputTypes = [
        config.houseLaboFeeTarget.type1,
        config.houseLaboFeeTarget.type3,
      ]
      // 「基本」の時のみtype4も対象とする
      if (feeMaster.inputType === config.houseLaboFeeInputType.basic) {
        clearInputTypes.push(config.houseLaboFeeTarget.type4)
      }
      // クリア対象（紹介料）のinputType
      const inputTypes = [
        config.houseLaboFeeInputType.clientIntro,
        config.houseLaboFeeInputType.sceneIntro,
      ]
      inputTypes.forEach(inputType => {
        const master = hlMasterFromInputType(masterHouseLaboFees, inputType)
        if (master && master.params) {
          if (clearInputTypes.includes(master.params.target)) {
            // ①②が対象となる時、金額をクリアする
            // type4の時、基本料金の出力が切り替えられてもクリアしない
            // 他にもクリアしないケースは増える可能性あり。
            if (
              master.params.target === config.houseLaboFeeTarget.type4 &&
              feeMaster.inputType === config.houseLaboFeeInputType.basic &&
              key === 'isExport'
            ) {
              return
            }
            this.updateInsertHouseLaboFee(
              houseLaboFees,
              master.feeId,
              'fee',
              '0',
            )
          }
        }
      })
    }
  }

  /**
   * 追加金額が変更されたときにクライアント紹介料と現場紹介料をクリアする
   */
  clearIntroByExtraFee = () => {
    const { sticky } = this.props
    const stickyProps = sticky.json() || {}
    const houseLaboClientMaster = stickyProps.houseLaboClientMaster || {}
    const masterHouseLaboFees = houseLaboClientMaster.houseLaboFees || []
    // クリア対象のinputType
    const inputTypes = [
      config.houseLaboFeeInputType.clientIntro,
      config.houseLaboFeeInputType.sceneIntro,
    ]
    inputTypes.forEach(inputType => {
      const master = hlMasterFromInputType(masterHouseLaboFees, inputType)
      if (master && master.params) {
        if (
          master.params.target === config.houseLaboFeeTarget.type1 ||
          master.params.target === config.houseLaboFeeTarget.type3
        ) {
          // ③が対象となる時、金額をクリアする
          this.updateHouseLaboFees(master.feeId, 'fee', '0')
        }
      }
    })
  }

  /**
   * クライアント紹介料・現場紹介料を再計算する
   */
  calcIntroductionFee = () => {
    const { sticky, contractAmount } = this.props
    const stickyProps = sticky.json() || {}
    // 計算の処理自体はutil.jsに移動しておく。結果のみを設定。
    const values = calcIntroduction(stickyProps, contractAmount)
    values.forEach(value => {
      this.updateHouseLaboFees(value.feeId, 'fee', value.value.toString())
    })
  }

  /**
   * render
   * @return {ReactDOM} rendered result
   */
  render() {
    const { sticky, route, disabled, contractAmount } = this.props

    const stickyProps = sticky.json() || {}
    // クライアントマスタ
    const houseLaboClientMaster = stickyProps.houseLaboClientMaster || {}
    // ハウスラボ
    const houseLabo = stickyProps.houseLabo || {}
    // 作業状況
    const houseLaboWorks = stickyProps.houseLaboWorks || []
    // 金額情報
    const houseLaboFees = stickyProps.houseLaboFees || []
    // その他の費用
    const houseLaboExtraFees = stickyProps.houseLaboExtraFees || []
    // 追加の作業内容
    const additionalWorks = houseLabo.additionalWorks || []
    // 金額情報
    const masterHouseLaboFees = houseLaboClientMaster.houseLaboFees || []
    // 二次対応予定調査中
    const secondaryWork = houseLabo.secondaryWork || false

    const workReport = houseLabo.workReport || false // 報告書確認済み
    const inputDisabled = disabled || workReport
    const isGran = this.isGran(sticky)

    // ---------------------------------------------------------------
    // 作業箇所
    const houseLaboWorksList = (
      <div>
        <dl className={ 'label-inputs-wrap' }>
          <dt>
            <label style={ { whiteSpace: 'nowrap' } }>{'作業箇所'}</label>
          </dt>
          <dd>&nbsp;</dd>
        </dl>
        <div>
          {houseLaboWorks.length > 0
            ? houseLaboWorks.map((hlwork, index) => (
              <HouseLaboWork
                key={ `hlwork-${index}` }
                houseLaboWork={ hlwork }
                index={ index }
                updateHouseLaboWorkText={ this.updateHouseLaboWorkText }
                removeHouseLaboWorks={ this.removeHouseLaboWorks }
                disabled={ inputDisabled }
              />
            ))
            : null}
        </div>
        <AddButton
          label={ '＋作業箇所を追加する' }
          onClick={ this.addHouseLaboWorks }
          disabled={ inputDisabled }
        />
      </div>
    )

    // ---------------------------------------------------------------
    // 二次対応予定調整中
    const secondaryWorkView = (
      <div
        style={ {
          display: 'flex',
          flexDirection: 'row',
        } }
      >
        <div style={ { flex: 1 } }>&nbsp;</div>
        <div style={ { flex: 1 } }>
          <CheckBox
            label={ '二次対応予定調整中' }
            value={ secondaryWork }
            name={ 'secondaryWork' }
            onChange={ this.updateHouseLabosecondaryWork }
            route={ route }
            disabled={ false }
          />
        </div>
      </div>
    )

    // ---------------------------------------------------------------
    // 作業内容
    const additionalWorksList = (
      <dl className={ 'label-inputs-wrap' }>
        <div
          style={ {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'baseline',
          } }
        >
          <dt style={ { minWidth: '8rem' } }>
            <label style={ { whiteSpace: 'nowrap' } }>{'作業内容'}</label>
          </dt>
          <dd>&nbsp;</dd>
          {additionalWorks.length > 0
            ? additionalWorks.map((item, index) => (
              <WorkItem
                key={ index }
                label={ `作業内容${index + 1}` }
                value={ item.workTypeId || -1 }
                itemValue={ item.freeDescribe || '' }
                name={ `work-site-item-${index}` }
                itemIndex={ index }
                onChange={ this.updateAdditionalWorks }
                route={ route }
                disabled={ inputDisabled }
              />
            ))
            : null}
          <AddButton
            label={ '＋作業内容を追加する' }
            onClick={ this.addAdditionalWork }
            disabled={ inputDisabled }
          />
        </div>
      </dl>
    )

    // ---------------------------------------------------------------
    // 現状状況等
    const workCondition = (
      <>
        {houseLaboWorksList}
        {secondaryWorkView}
        {additionalWorksList}
        <Box
          sx={ {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, max-content)',
            alignItems: 'center',
            columnGap: '1.5rem',
          } }
        >
          <TimeInput
            label={ '作業開始時刻' }
            value={ houseLabo.workStartTime || '' }
            name={ 'work-start-time' }
            // eslint-disable-next-line react/jsx-no-bind
            onChange={ time => this.updateHouseLaboTime(time, 'workStartTime') }
            route={ route }
            disabled={ inputDisabled }
          />
          <TimeInput
            label={ '作業終了時刻' }
            value={ houseLabo.workEndTime || '' }
            name={ 'work-end-time' }
            // eslint-disable-next-line react/jsx-no-bind
            onChange={ time => this.updateHouseLaboTime(time, 'workEndTime') }
            route={ route }
            disabled={ inputDisabled }
          />
        </Box>
      </>
    )

    // ---------------------------------------------------------------
    // 金額入力のための表Gran用
    const masterHouseLaboFeesG = masterHouseLaboFees[2] || []
    const gridG = this.makeHouseLaboFeesGrid(
      masterHouseLaboFeesG,
      houseLaboFees,
      2,
      isGran,
    )

    // ---------------------------------------------------------------
    // 金額入力のための表①
    const masterHouseLaboFees1 = masterHouseLaboFees[0] || []
    const grid1 = this.makeHouseLaboFeesGrid(
      masterHouseLaboFees1,
      houseLaboFees,
      0,
      isGran,
    )

    // ---------------------------------------------------------------
    // 金額入力のための表②
    const masterHouseLaboFees2 = masterHouseLaboFees[1] || []
    const grid2 = this.makeHouseLaboFeesGrid(
      masterHouseLaboFees2,
      houseLaboFees,
      1,
      isGran,
    )

    // ---------------------------------------------------------------
    // 金額入力のための表スタイル
    const feeSheetStyle = {
      marginTop: '10px',
      marginBottom: '4px',
      marginLeft: '10px',
      marginRight: '10px',
    }

    // ---------------------------------------------------------------
    // 追加金額入力のための表③
    const grid3 = this.makeHouseLaboExtraFeesGrid(
      houseLaboExtraFees,
      houseLabo,
      isGran,
    )

    // ---------------------------------------------------------------
    // 追加金額入力のための表スタイル
    const extraFeeSheetStyle = {
      marginTop: '10px',
      marginBottom: '20px',
      marginLeft: '10px',
      marginRight: '40px',
    }

    // ---------------------------------------------------------------
    // 合計金額を表示するための表。
    const makeTotal = (
      masterHouseLaboFees,
      houseLabo,
      houseLaboFees,
      houseLaboExtraFees,
      isGran,
    ) => {
      // 合計金額を計算
      const { total, total_with_intro } = calcClientTotal(
        masterHouseLaboFees,
        houseLabo,
        houseLaboFees,
        houseLaboExtraFees,
        isGran,
      )
      // ---------------------------------------------------------------
      // カラムのスタイル
      const clientTotalTdStyle = {
        border: '1px solid gray',
        paddingTop: '4px',
        paddingBottom: '4px',
        paddingLeft: '6px',
        paddingRight: '6px',
      }
      const clientTotalTdTitleStyle = {
        ...clientTotalTdStyle,
        width: '150px',
        fontWeight: 'bold',
        // background: 'lightgrey',
        background: 'linear-gradient(#eceff1, #cfd8dc)',
        textAlign: 'center',
      }
      const clientTotalTdValueStyle = {
        ...clientTotalTdStyle,
        width: '120px',
        fontWeight: 'normal',
        background: 'white',
        textAlign: 'right',
      }
      // ---------------------------------------------------------------
      // クライアント請求金額表示
      const totalPayments = (
        <table
          style={ {
            marginBottom: '10px',
          } }
        >
          <tbody>
            <tr>
              <td style={ clientTotalTdTitleStyle }>{'クライアント請求'}</td>
              <td style={ clientTotalTdValueStyle }>{toJPYenText(total)}</td>
            </tr>
            <tr>
              <td style={ clientTotalTdTitleStyle }>{'実クライアント請求'}</td>
              <td style={ clientTotalTdValueStyle }>
                {toJPYenText(total_with_intro)}
              </td>
            </tr>
          </tbody>
        </table>
      )
      // ---------------------------------------------------------------
      // グラン用の合計金額表示：仕様変更で表示しなくなった
      let granTotal = null
      return { totalPayments, granTotal }
    }

    const { totalPayments } = makeTotal(
      masterHouseLaboFees,
      houseLabo,
      houseLaboFees,
      houseLaboExtraFees,
      isGran,
    )

    /**
     * 個別請求書のチェックボックスと日時・名称を表示
     */
    const needsInvoiceInfo = (() => {
      // 個別請求書
      const needsInvoice = houseLabo.needsInvoice || false // 個別請求書発送要
      const needsInvoiceDate = houseLabo.needsInvoiceDate || '' // 個別請求書発送要設定日時
      const needsInvoiceUserId = houseLabo.needsInvoiceUserId || '' // 個別請求書発送要設定者
      //
      const mdate = moment(needsInvoiceDate)
      const needsInvoiceDateStr = mdate.isValid()
        ? mdate.format('YYYY-MM-DD HH:mm:ss')
        : '-'
      //
      const needsInvoiceUserIdName =
        (
          this.props.master.allUsers.find(
            user => user.id === needsInvoiceUserId,
          ) || {}
        ).name || ''
      //
      return (
        <div style={ { display: 'flex', alignItems: 'baseline' } }>
          <CheckBox
            label={ '※個別請求書発送要' }
            value={ needsInvoice }
            name={ 'needsInvoice' }
            onChange={ this.updateHouseLaboNeedsInvoice }
            route={ route }
            disabled={ inputDisabled }
          />
          <span>{needsInvoiceDateStr}</span>
          <span>{'　　'}</span>
          <span>{needsInvoiceUserIdName}</span>
        </div>
      )
    })()

    /**
     * 個別請求書発送済のチェックボックスと日時・名称を表示
     */
    const needsInvoiceSend = (() => {
      // 個別請求書発送済
      const needsInvoice = houseLabo.needsInvoice || false // 個別請求書発送要
      const needsInvoiceSend = houseLabo.needsInvoiceSend || false // 個別請求書発送済 (個別請求書発送要をfalseにするとfalseになる)
      const needsInvoiceSendDate = houseLabo.needsInvoiceSendDate || '' // 個別請求書発送済設定日時
      const needsInvoiceSendUserId = houseLabo.needsInvoiceSendUserId || '' // 個別請求書発送済設定者

      //
      const mdate = moment(needsInvoiceSendDate)
      const needsInvoiceSendDateStr = mdate.isValid()
        ? mdate.format('YYYY-MM-DD HH:mm:ss')
        : '-'
      //
      const needsInvoiceSendUserIdName =
        (
          this.props.master.allUsers.find(
            user => user.id === needsInvoiceSendUserId,
          ) || {}
        ).name || ''
      //
      return (
        <div style={ { display: 'flex', alignItems: 'baseline' } }>
          <CheckBox
            label={ '※個別請求書発送済' }
            value={ needsInvoiceSend }
            name={ 'needsInvoiceSend' }
            onChange={ this.updateHouseLaboNeedsInvoiceSend }
            route={ route }
            disabled={ inputDisabled || !needsInvoice }
          />
          <span>{needsInvoiceSendDateStr}</span>
          <span>{'　　'}</span>
          <span>{needsInvoiceSendUserIdName}</span>
        </div>
      )
    })()

    // ---------------------------------------------------------------
    // 請求額・備考などを表示
    const clientTotal = (
      <div style={ { marginLeft: '10px' } }>
        <div style={ { display: 'flex' } }>
          <div style={ { flexGrow: 1 } }>
            {totalPayments}
            {needsInvoiceInfo}
            {needsInvoiceSend}
          </div>
          <div style={ { flexGrow: 2 } }>
            <dl className={ 'label-inputs-wrap' }>
              <TextAreaInput
                label={ '備考' }
                value={ houseLabo.remark || '' }
                placeholder={ '' }
                name={ 'houselab_remark' }
                // eslint-disable-next-line react/jsx-no-bind
                onChange={ e => this.updateHouseLaboText(e, 'remark') }
                route={ route }
                disabled={ inputDisabled }
              />
            </dl>
          </div>
        </div>
      </div>
    )

    // ---------------------------------------------------------------
    // 総売上、準売上を計算
    const { totalSales, netSales } = calcTotal(
      contractAmount,
      masterHouseLaboFees,
      houseLabo,
      houseLaboFees,
      houseLaboExtraFees,
      isGran,
    )

    // ---------------------------------------------------------------
    // 全体の描画
    return (
      <div>
        {workCondition}
        <Hr />
        <div>
          <div style={ { display: 'flex' } }>
            <div style={ feeSheetStyle }>
              <ReactDataSheet
                data={ grid1 }
                valueRenderer={ this.valueRenderer }
                dataRenderer={ this.dataRenderer }
                onCellsChanged={ this.onPaymentCellsChanged }
                attributesRenderer={ this.attributesRenderer }
              />
            </div>
            <div style={ feeSheetStyle }>
              <ReactDataSheet
                data={ grid2 }
                valueRenderer={ this.valueRenderer }
                dataRenderer={ this.dataRenderer }
                onCellsChanged={ this.onPaymentCellsChanged }
                attributesRenderer={ this.attributesRenderer }
              />
            </div>
            {isGran && (
              <div style={ feeSheetStyle }>
                <ReactDataSheet
                  data={ gridG }
                  valueRenderer={ this.valueRenderer }
                  dataRenderer={ this.dataRenderer }
                  onCellsChanged={ this.onPaymentCellsChanged }
                  attributesRenderer={ this.attributesRenderer }
                />
              </div>
            )}
          </div>
          <div style={ extraFeeSheetStyle }>
            <ReactDataSheet
              data={ grid3 }
              valueRenderer={ this.valueRenderer }
              dataRenderer={ this.dataRenderer }
              onCellsChanged={ this.onExtraPaymentCellsChanged }
              attributesRenderer={ this.attributesRenderer }
            />
            <div
              style={ {
                width: '65%',
                display: 'flex',
                justifyContent: 'space-between',
              } }
            >
              <AddButton
                label={ '＋行を追加する' }
                onClick={ this.addHouseLaboExtraFees }
                disabled={ inputDisabled }
              />
            </div>
            {/* isGran && granTotal 出力しない */}
            <AddButton
              label={ '紹介料再計算' }
              onClick={ this.calcIntroductionFee }
              disabled={ inputDisabled }
            />
          </div>
          {clientTotal}
          <div style={ hlSalesStyle }>
            <div>
              <ItemValue label={ '総売上' } value={ toJPYenText(totalSales) } />
            </div>
            <div>
              <ItemValue label={ '純売上' } value={ toJPYenText(netSales) } />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(HouseLabo)
