// action types
import { ACTION_TYPES as STICKY_GET_ALL_ACTION_TYPES } from 'src/reducers/wara/async/get-all-sticky'
import { ACTION_TYPES as STICKY_GET_DOCK_ACTION_TYPES } from 'src/reducers/wara/async/get-dock-sticky'
// import { creator as createRequestPaymentAction } from 'src/reducers/payment'
// import Sticky from 'src/lib/class-sticky'

// TODO: 検索や、親の付箋を取ってくるときも、同じ処理がいる
export const getPayments = x => x
// (store, action) => {
//   const { accessToken } = store.getState().login
//   const stickies = action.payload.map(props => new Sticky(props))
//
//   const stickyContactIds = sticky.stickyContactIds
//   store.dispatch(createRequestPaymentAction(stickyContactIds, accessToken))
// }

export default {
  [STICKY_GET_ALL_ACTION_TYPES.GET_STICKY_SUCCESS]: getPayments,
  [STICKY_GET_DOCK_ACTION_TYPES.GET_DOCK_SUCCESS]: getPayments,
}
