import { connect } from 'react-redux'
import { ACTION_TYPES as CLICKER_ACTION_TYPES } from 'src/reducers/clicker'
import { createAsyncCityCodeUpdateAction } from 'src/reducers/master'
import { actionCreators as $ } from 'src/reducers/wara'

/**
 map state to props
 * @param  {object} state State
 * @return {object}       State
 */
const mapStateToProps = state => {
  const prefCodes = state.master.data.prefCodes || []
  const prefCodesClassified = prefCodes.reduce((prev, pref) => {
    if (prev[pref.regionId]) {
      prev[pref.regionId].push(pref)
    } else {
      prev[pref.regionId] = [pref]
    }
    return prev
  }, {})

  return {
    wara: state.wara.data,
    master: state.master.data,
    splitSourceLocation: state.clicker.splitter.location,
    cityCodes: state.master.cityCodes,
    isDebugMode: state.env.DEBUG,
    splits: state.clicker.splits, // 分裂している付箋の数: TODO: これは、localStorageに格納するので、多分いらなくなる
    env: state.env,
    accessToken: state.login.authentication.accessToken,
    waraRegions: ((state.userPreset.data || {}).regions || [])
      .filter(region => region.display)
      .map(region => region.id),
    docRegions: state.wara.dissociatedRegionsFilter,
    prefCodesClassified,
  }
}

/**
 * map dispatch to props
 * @param  {function} dispatch dispatcher
 * @return {object}            mapped auto bind action creators
 */
const mapDispatchToProps = dispatch => {
  return {
    stopSplitMode: () =>
      dispatch({ type: CLICKER_ACTION_TYPES.UNREGISTER_SPLITTER }),

    // TODO: これめちゃくちゃ
    __getCityCodes: env => (prefCode, accessToken) =>
      dispatch(createAsyncCityCodeUpdateAction(prefCode, accessToken, env)),

    startSplitMode: location =>
      dispatch({
        type: CLICKER_ACTION_TYPES.REGISTER_SPLITTER,
        payload: { location },
      }),

    deleteMe: location => dispatch($.deleteSticky(location, false)),
    deleteStickyById: stickyId => dispatch($.deleteStickyById(stickyId)),
    updateSticky: (sticky, location) =>
      dispatch($.updateSticky(sticky, location)),
  }
}

/**
 * map state to props
 * @param  {object} stateProps    state props
 * @param  {object} dispatchProps dispatch props
 * @param  {object} ownProps      own props
 * @return {object}               props
 */
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    // merge
    getCityCodes: dispatchProps.__getCityCodes(stateProps.env),
  }
}

/**
 * export as decorator
 * @param  {ReactComponent} Component 被デコレートコンポーネント
 * @return {ReactComponent}           デコレートされたコンポーネント
 */
export default Component =>
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component)
