import React from 'react'
import PropTypes from 'prop-types'
import { FileSpec } from './styled'
import { makeCancelable, noop } from 'src/lib/cancelable-promise'

// アップロードをリクエストし、アップロード状態を表示する
export class Upload extends React.PureComponent {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    file: PropTypes.shape({
      errorMessage: PropTypes.string,
      data: PropTypes.any,
      name: PropTypes.string.isRequired,
    }).isRequired,
    upload: PropTypes.func.isRequired, // ファイル内容をアップロードする。upload(base64, [filename])
    fire: PropTypes.bool, // アップロードを発火させる
    dataFilter: PropTypes.func.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    fire: false,
  }

  state = {
    status: 'not_yet',
    onUnmount: noop,
  }

  /**
   * componentDidMount
   * @return {void}
   */
  componentDidMount() {
    this.props.fire && this.request()
  }

  /**
   * componentDidUpdate
   * 親からfire プロパティ経由でアップロードのスイッチを入れる
   * @param  {object} prevProps prev props
   * @param  {object} prevState prev state
   * @param  {object} snapshot  snapshot
   * @return {void}
   */
  componentDidUpdate(prevProps) {
    if (!prevProps.fire && this.props.fire) {
      this.request()
    }
  }

  /**
   * componentWillUnmount
   * @return {void}
   */
  componentWillUnmount() {
    this.state.onUnmount()
  }

  request = () => {
    const { file, upload, dataFilter } = this.props
    console.log(file.data)
    const { promise, cancel: onUnmount } = makeCancelable(
      upload(dataFilter(file.data), file.name),
    )

    this.setState({ status: 'requesting', onUnmount })

    promise
      .then(res => {
        console.log(res)
        if (Array.isArray(res)) {
          // 画像アップロードの時、新旧のAPIをまとめて実行するため、配列で結果が返される。
          let result = true
          res.forEach(r => {
            result &= r.ok
          })
          if (result) {
            return {}
          } else {
            throw 'multi upload failed'
          }
        } else {
          if (res.ok) {
            return res.json()
          } else {
            throw res.json()
          }
        }
      })
      .then(() => this.setState({ status: 'success' }))
      .catch(err => {
        if (!err.isCanceled) {
          this.setState({ status: 'failure' })
          console.error(err)
        }
      })
  }

  renderMessage = () => {
    const { status } = this.state
    const {
      file: { errorMessage },
    } = this.props

    if (errorMessage) {
      return (
        <FileSpec style={ { flex: 1 } } failure>
          {errorMessage}
        </FileSpec>
      )
    } else {
      if (status === 'not_yet') {
        return <FileSpec>{''}</FileSpec>
      } else if (status === 'success') {
        return <FileSpec success>{'アップロード成功'}</FileSpec>
      } else if (status === 'requesting') {
        return <FileSpec>{'アップロード中...'}</FileSpec>
      } else if (status === 'failure') {
        return <FileSpec failure>{'サーバーエラー'}</FileSpec>
      } else {
        return <FileSpec failure>{'不明なエラー'}</FileSpec>
      }
    }
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const {
      file: { name, errorMessage },
    } = this.props

    return (
      <>
        <FileSpec width={ 200 } cancel={ !!errorMessage }>
          {name}
        </FileSpec>
        {this.renderMessage()}
      </>
    )
  }
}

export default Upload
