import React from 'react'
import PropTypes from 'prop-types'
import connect from '../../../../connect'
import update from 'immutability-helper'

import createClassNames from 'classnames'
import Sticky from 'src/lib/class-sticky'
import { normalizeZip } from 'src/lib/format'
import { formatZip } from 'src/lib/format'
import findContact from '../../../lib/find-contact'
import { toHalf } from 'src/lib/to-half'
import { baseColor } from 'src/colors'
import styled from 'styled-components'

import requestZip from 'src/lib/zip-api/get'
import { makeCancelable, noop } from 'src/lib/cancelable-promise'
import { contactInputStyle } from 'src/lib/format'

/**
 * 郵便番号を入力します
 * @type {ReactComponent}
 */
export class Zip extends React.Component {
  /**
   * validation
   * @param  {string}  value validating phone number
   * @return {boolean}       result
   */
  static isValid(value) {
    const formattedZip = normalizeZip(toHalf(value) || '')
    return /^(\d{3}-?\d{4}|)$/.test(formattedZip) || formattedZip === ''
  }

  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    tabIndex: PropTypes.number.isRequired,
    contactsIndex: PropTypes.number.isRequired,
    route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
    disabled: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
    tabOrder: PropTypes.number,
    isDelete: PropTypes.bool,
    // dispatchProps
    updateStage: PropTypes.func.isRequired,
    login: PropTypes.shape({
      accessToken: PropTypes.string.isRequired,
    }).isRequired,
    env: PropTypes.object.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    tabOrder: 0,
    isDelete: false,
  }

  state = {
    onEditing: false,
    status: 'not_yet',
    notFound: false,
    timerId: false,
    onUnmount: noop,
    zipData: [], // 郵便番号検索の結果
    showZipSelect: false, // 郵便番号選択画面表示フラグ
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.sticky.json().contacts !== this.props.sticky.json().contacts ||
      this.state.onEditing !== nextState.onEditing ||
      this.state.status !== nextState.status ||
      this.state.notFound !== nextState.notFound ||
      this.props.disabled !== nextProps.disabled ||
      this.props.readOnly !== nextProps.readOnly ||
      this.state.zipData !== nextState.zipData ||
      this.state.showZipSelect !== nextState.showZipSelect
    )
  }

  /**
   * componentWillUnmount
   * @return {void}
   */
  componentWillUnmount() {
    this.state.onUnmount()
    clearTimeout(this.state.timerId)
  }

  /**
   * フォーカスした時に編集開始。（初期値をコピーしてBlurまでstateで管理する）
   * フォームにフォーカスした際にエラー表示を消す
   * @return {void}
   */
  onFocus = () => {
    const { sticky, tabIndex, contactsIndex } = this.props
    const stickyProps = sticky.json()

    const contact =
      ((stickyProps.contacts || [])[tabIndex] || [])[contactsIndex] || {}

    this.setState(
      update(this.state, { onEditing: { $set: contact.zip || '' } }),
    )
  }

  /**
   * 編集中の値として、フォームの変更を反映
   * @param  {Event} e onChange event
   * @return {void}
   */
  onChange = e =>
    this.setState(update(this.state, { onEditing: { $set: e.target.value } }))

  /**
   * フォームを離れた時にバリデーションをかける
   * @return {void}
   */
  onBlur = () => {
    // バリデーション。成功したときだけ、ステージング値をアップデートする
    const _zip = this.state.onEditing

    // if (Zip.isValid(_zip)) {
    const zip = normalizeZip(toHalf(_zip) || '')
    const { sticky, tabIndex, contactsIndex, updateStage } = this.props
    const id = sticky.getContactId(tabIndex, contactsIndex)
    const existsClient = sticky.existsClient()

    const diffProps = {
      contacts: {
        [tabIndex]: {
          [contactsIndex]: existsClient
            ? { id, zip }
            : { id, zip, isClient: true },
        },
      },
    }
    updateStage(new Sticky(diffProps))
    // }

    // 編集中の値を消す
    this.setState(update(this.state, { onEditing: { $set: false } }))
  }

  /**
   * 郵便番号検索ボタン押下時のハンドラ
   * @return {void}
   */
  onSearchClick = () => {
    const {
      sticky,
      tabIndex,
      contactsIndex,
      login: { accessToken },
      env,
    } = this.props
    const stickyProps = sticky.json()

    const contact =
      ((stickyProps.contacts || [])[tabIndex] || [])[contactsIndex] || {}

    const zipCode = normalizeZip(contact.zip || '')

    const { promise, cancel: onUnmount } = makeCancelable(
      requestZip(zipCode, accessToken, env),
    )
    this.setState({ status: 'request', onUnmount })

    // 郵便番号検索実行
    promise
      .then(res => {
        if (res.ok) {
          return res.json()
        } else {
          throw { statusCode: res.status }
        }
      })
      .then(data => {
        if (!Array.isArray(data) || data.length === 0) {
          throw { statusCode: 404 }
        }
        // 複数の結果がある時は選択画面を表示する
        const showZipSelect = data.length > 1
        this.setState({ status: 'success', zipData: data, showZipSelect })
        // 複数の場合でも最初の住所を設定する
        this.updateAddress(data[0])
      })
      .catch(error =>
        this.setState({
          status: 'failure',
          notFound: error.statusCode === 404,
          timerId: setTimeout(
            () =>
              this.setState({
                status: 'not_yet',
                notFound: false,
              }),
            2000,
          ),
        }),
      )
  }

  get groupName() {
    return `contact-zip-text-${this.props.tabIndex}-${this.props.contactsIndex}-${this.props.route}`
  }

  updateAddress = data => {
    const { sticky, tabIndex, contactsIndex, updateStage } = this.props
    const id = sticky.getContactId(tabIndex, contactsIndex)
    const existsClient = sticky.existsClient()

    const nextContactPropsDiff = {
      prefCode: data.prefCode,
      cityCode: data.cityCode,
      cityName: data.cityName,
      address: data.address,
    }

    const diffProps = {
      contacts: {
        [tabIndex]: {
          [contactsIndex]: existsClient
            ? nextContactPropsDiff
            : { ...nextContactPropsDiff, id, isClient: true },
        },
      },
    }
    updateStage(new Sticky(diffProps))
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { onEditing, status, notFound, zipData, showZipSelect } = this.state

    const {
      sticky,
      tabIndex,
      contactsIndex,
      disabled,
      readOnly,
      tabOrder,
      isDelete,
    } = this.props

    const zip = (findContact(sticky, tabIndex, contactsIndex) || {}).zip || ''
    const displayZipValue = onEditing === false ? formatZip(zip) : onEditing
    const isValid = Zip.isValid(onEditing === false ? zip : onEditing)
    const isButtonDisabled = !Zip.isValid(displayZipValue) || !displayZipValue
    const buttonClassNames = createClassNames({
      button: true,
      'button-open': true,
      'label-inputs-wrap-button': true,
      'button-disabled': isButtonDisabled,
    })

    const identifier = 'id-' + this.groupName

    // 複数の住所があるときの表示スタイル
    const zipSelctDivStyle = {
      position: 'absolute',
      top: '300px',
      left: '550px',
      height: '300px',
      padding: '8px',
      background: 'rgba(255, 255, 255, .9)',
      borderRadius: '10px',
      zIndex: 20,
      cursor: 'pointer',
      boxShadow: 'rgba(0, 0, 0, 0.4) 0px 10px 10px',
      backdropFilter: 'blur(4px)',
    }

    const zipSelectHeader = {
      margin: '0.75rem',
    }

    const zipSelectClose = {
      position: 'absolute',
      top: '5px',
      right: '10px',
      fontSize: '2em',
    }

    const zipSelectTableWrap = {
      margin: '0.75rem',
      height: '270px',
      overflowX: 'hidden',
      overflowY: 'scroll',
    }

    // テーブルヘッダに枠線をつける
    const zipSelectTh = {
      borderBottom: 'solid 1px',
      background: 'rgb(27, 149, 191)',
      padding: '0.5rem',
    }

    // テーブルに枠線をつける
    const zipSelectTd = {
      borderBottom: 'dotted 1px',
      padding: '0.5rem',
    }

    const ZipSelectedTr = styled.tr`
      &:hover {
        background-color: ${baseColor};
      }
    `

    // テーブルで住所を選択したときの処理
    const selectZip = index => {
      const data = this.state.zipData[index]
      this.updateAddress(data)
      this.setState({ showZipSelect: false })
    }

    // テーブルを閉じる
    const closeZipTable = () => {
      this.setState({ showZipSelect: false })
    }

    return (
      <dl
        className={ 'label-inputs-wrap' }
        style={ { display: 'grid', gridTemplateColumns: '9.25rem 1fr' } }
      >
        <dt>
          <label htmlFor={ identifier }>{'郵便番号'}</label>
        </dt>

        <dd className={ 'input-wrap' }>
          <div className={ 'flex' }>
            <input
              className={
                'shorter ' + (isValid ? 'input-valid' : 'input-invalid')
              }
              value={ displayZipValue }
              id={ identifier }
              name={ this.groupName }
              type={ 'text' }
              onFocus={ this.onFocus }
              onChange={ this.onChange }
              onBlur={ this.onBlur }
              disabled={ disabled }
              readOnly={ readOnly }
              style={
                (contactInputStyle({ disabled, readOnly, isDelete }),
                { maxWidth: '12rem' })
              }
              tabIndex={ tabOrder }
            />
            <button
              className={ buttonClassNames }
              onClick={ this.onSearchClick }
              disabled={ disabled || isButtonDisabled }
              tabIndex={ tabOrder + 1 }
            >
              <i className={ 'fa fa-search' } />
            </button>
          </div>
          {status === 'failure' && (
            <span className={ 'notice notice-zip-not-found' }>
              {notFound ? '該当なし' : '通信エラー'}
            </span>
          )}
          {/* 郵便番号に該当する住所が複数ある時は一覧を表示する */}
          {showZipSelect && (
            <div style={ zipSelctDivStyle }>
              <div style={ zipSelectHeader }>
                <span>{'郵便番号: '}</span>
                <span>{displayZipValue}</span>
                <a style={ zipSelectClose } onClick={ closeZipTable }>
                  {'×'}
                </a>
              </div>
              <div style={ zipSelectTableWrap }>
                <table>
                  <thead>
                    <tr>
                      <th style={ zipSelectTh }>{'都道府県'}</th>
                      <th style={ zipSelectTh }>{'市町村'}</th>
                      <th style={ zipSelectTh }>{'その他'}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {zipData.map((zip, index) => {
                      return (
                        <ZipSelectedTr
                          key={ index }
                          onClick={ () => selectZip(index) }
                        >
                          <td style={ zipSelectTd }>{zip.prefName}</td>
                          <td style={ zipSelectTd }>{zip.cityName}</td>
                          <td style={ zipSelectTd }>{zip.address}</td>
                        </ZipSelectedTr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </dd>
      </dl>
    )
  }
}

export default connect(Zip)
