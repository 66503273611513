import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { toJPYenText } from 'src/lib/format'
import ValidationErrorMessage from 'src/components/commons/validation-error-message'
import createClassNames from 'classnames'
import styled from 'styled-components'

import {
  workCompleteContract,
  listOriginalContract,
} from 'src/lib/inventory-api/workCompleteContract'
import { baseColor } from 'src/colors'

export const mapStateToProps = state => {
  return {
    accessToken: state.login.authentication.accessToken,
    env: state.env,
  }
}

const ContractTotalPayment = props => {
  const { contractAmount, sticky, getStickyPayments, accessToken, env } = props

  const [contracts, setContracts] = useState([])
  const [modal, setModal] = useState(false)

  const identifier = 'total-payment'

  const totalValue = contractAmount ? contractAmount.total.totalContract : 0 // 契約金総額
  const totalAccount = contractAmount ? contractAmount.total.account : 0 // 売掛け金総額
  const totalCollected = contractAmount
    ? contractAmount.collected.accountCollected
    : 0 // 売掛金回収総額
  const totalRemain = totalAccount - totalCollected // 売掛金残金
  const totalValueText = toJPYenText(totalValue)
  const totalAccountText = toJPYenText(totalAccount)
  const remainingText = `うち残金 ${toJPYenText(totalRemain)}`
  const percent =
    Math.round((10000 * totalCollected) / totalAccount) / 100 || '--' // 回収金総額 / 売掛金総額 (パーセント)

  const totalAccountValueText = `${totalAccountText} (${remainingText}、${percent}% 回収済み)`
  // ValidationErrorMessage

  const totalPaymentStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: 24,
  }
  const buttonClassNames = createClassNames({
    button: true,
    'button-open': true,
    'label-inputs-wrap-button': true,
    // 'button-disabled': disabled,
  })
  const workCompleteContractButton = {
    fontSize: 14,
    fontWeight: 'bold',
  }
  const modalDialog = {
    position: 'fixed',
    inset: 0,
    margin: 'auto',
    width: '32vw',
    height: '32vh',
    border: '2px solid #eeeeee',
    borderRadius: '0.5rem',
    background: 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'saturate(180%) blur(20px)',
    zIndex: 10,
  }
  const modalContent = {
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 48,
  }
  const modalFooter = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  }
  const ContractNumberButton = styled.button`
    min-width: 64px;
    margin: 12px auto;
    padding: 6px 16px;
    border: 1px solid ${baseColor};
    background-color: transparent;
    color: ${baseColor};

    &:hover {
      font-weight: bold;
    }
  `
  const CloseButton = styled.button`
    min-width: 64px;
    margin: 6px 0;
    padding: 6px 16px;
    border: none;
    background-color: transparent;
    color: ${baseColor};

    &:hover {
      font-weight: bold;
    }
  `

  const getListContractByOrderId = async () => {
    if (!sticky.orderId || sticky.orderId === '') {
      setContracts([])
      return
    }

    try {
      const res = await listOriginalContract(sticky.orderId, accessToken, env)
      const data = await res.json()
      // 金額を流し込みたい契約書を選ぶために、破棄も完工もされていない契約書の番号を取得します
      const contracts = data.data.listOriginalContract.filter(
        contract =>
          contract.documentStatus !== 'Disposed' &&
          contract.workComplete === false,
      )
      // 契約書番号があれば「契約金額を取得」ボタンを押せるように
      setContracts(contracts)
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    void getListContractByOrderId()
  }, [sticky.orderId])

  // 契約書の金額を取得する
  const getWorkCompleteContract = async contractNumber => {
    try {
      await workCompleteContract(sticky.id, contractNumber, accessToken, env)
      getStickyPayments()
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div style={ totalPaymentStyle }>
      <div>
        <dl className={ 'label-inputs-wrap' }>
          <dt className={ 'input-headline' }>
            <label htmlFor={ identifier }>{'契約金額総額'}</label>
          </dt>

          <dd className={ 'input-wrap' }>
            <span>{totalValueText}</span>
          </dd>
        </dl>

        <dl className={ 'label-inputs-wrap' }>
          <dt className={ 'input-headline' }>
            <label htmlFor={ identifier }>{'売掛金総額'}</label>
          </dt>

          <dd className={ 'input-wrap' }>
            <span>{totalAccountValueText}</span>
            {totalCollected > totalAccount && (
              <ValidationErrorMessage
                message={ '売掛金の回収金額が予定額を超過しています。' }
              />
            )}
          </dd>
        </dl>
      </div>
      <button
        className={ buttonClassNames }
        onClick={ () => setModal(true) }
        disabled={ !contracts.length || !!sticky.twoPersonParentId }
        style={ workCompleteContractButton }
      >
        {'契約金額を取得'}
      </button>
      <div>
        <div
          style={ {
            ...modalDialog,
            visibility: modal ? 'visible' : 'hidden',
            opacity: modal ? 1 : 0,
          } }
        >
          <div style={ modalContent }>
            <div>
              {'契約金額を自動入力する契約書の番号を選択してください。'}
            </div>
            <div style={ { display: 'flex', gap: '1.5rem' } }>
              {contracts.map(contract => (
                <div key={ contract.id }>
                  <ContractNumberButton
                    onClick={ () =>
                      getWorkCompleteContract(contract.contractNumber)
                    }
                  >
                    {contract.contractNumber}
                  </ContractNumberButton>
                </div>
              ))}
            </div>
            <div style={ modalFooter }>
              <CloseButton onClick={ () => setModal(false) }>
                {'閉じる'}
              </CloseButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ContractTotalPayment.propTypes = {
  contractAmount: PropTypes.object,
  sticky: PropTypes.object,
  getStickyPayments: PropTypes.func,
  accessToken: PropTypes.string,
  env: PropTypes.object,
}

ContractTotalPayment.defaultProps = {
  value: 0,
  contractAmount: {
    total: {
      totalContract: 0,
      account: 0,
    },
    collected: {
      accountCollected: 0,
    },
  },
  sticky: {},
  getStickyPayments: () => null,
  accessToken: '',
  env: {},
}

export default connect(mapStateToProps)(ContractTotalPayment)
