import { connect } from 'react-redux'
import { createActions as createLoginActions } from 'src/reducers/login'
import sign from 'jsonwebtoken/sign'
import { cert } from 'src/assets/keys/private.key'
import { ACTION_TYPES as NETWORK_STATUS_ACTION_TYPES } from 'src/reducers/network-status'

/**
 map state to props
 * @param  {object} state mapping state
 * @return {object}       mapped state as props
 */
export const mapStateToProps = state => {
  return {
    loginStatus: state.networkStatus.login,
    isDebugMode: state.env.DEBUG,
  }
}

/**
 * map dispatch to props
 * @param  {function} dispatch dispatcher
 * @param  {object}   ownProps own props
 * @return {object}            dispatch props
 */
const mapDispatchToProps = dispatch => {
  return {
    /**
     * オフラインモードを実現する。オフラインモードとは、開発用のダミーログイン機能
     * @return {void} [description]
     */
    loginAsOfflineMode: () => {
      dispatch(createLoginActions.setUsername('offline'))
      dispatch(createLoginActions.setPassword('offline'))
      dispatch(createLoginActions.setOfflineMode(true))
      const token = sign(
        {
          profile: {
            name: 'offline',
            roles: ['assign', 'call', 'accounting', 'consultation'],
          },
          exp: (Date.now() + 3600 * 24 * 1000) / 1000,
        },
        cert,
        { algorithm: 'RS256' },
      )
      const meta = { userid: 'offline', cached: true }
      dispatch(createLoginActions.loginSuccess(token, meta))
      dispatch({
        type: NETWORK_STATUS_ACTION_TYPES.SUCCESS,
        payload: {
          target: 'login',
        },
      })
    },
  }
}

export default LoginForm =>
  connect(mapStateToProps, mapDispatchToProps)(LoginForm)
