import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ActivePageLink from './active-page-link'
import config from 'src/config'

import { NavItemUL, DetailedSeatchNavItemUL } from './styled'

const mapStateToProps = state => ({
  isDebugMode: state.env.DEBUG,
})

/**
 * 検索結果のページネーションコンポーネント
 */
export class SearchPagenation extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    fixed: PropTypes.bool,
    type: PropTypes.oneOf(['detailed-search', '']),
    page: PropTypes.number.isRequired,
    fullCount: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    onPageMove: PropTypes.func.isRequired,
    // stateProps
    isDebugMode: PropTypes.bool.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    fixed: false,
    type: '',
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return (
      this.props.page !== nextProps.page ||
      this.props.fullCount !== nextProps.fullCount
    )
  }

  /**
   * ページネーションに必要な値を算出する
   * @param  {number} page           現在のページ(0始まり)
   * @param  {number} fullCount      コンテンツの数
   * @param  {number} limit        ページあたりのコンテンツの数
   * @param  {number} displayPageMax 移動可能なリンクとして表示するページの数
   * @return {{hasFormer: boolean, top: number, bottom: number hasFollower: boolean, lastPage: number}}  ページングに必要な値
   */
  createPagenation = ({ page, fullCount, limit, displayPageMax }) => {
    const lastPage = Math.ceil(fullCount / limit) - 1

    const delta = Math.floor((displayPageMax + 1) / 2) - 1
    const bottom = Math.max(0, page - delta)
    const top = Math.min(lastPage, page + delta)

    return {
      hasFormer: bottom > 0,
      top,
      bottom,
      hasFollower: lastPage - top > 0,
      lastPage,
    }
  }

  createOnPageMove = nextPage => () => this.props.onPageMove(nextPage)

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { type, page, fullCount, limit, isDebugMode, fixed } = this.props

    const displayPageMax = config.displayPageMax
    const {
      hasFormer,
      top,
      bottom,
      hasFollower,
      lastPage,
    } = this.createPagenation({
      page,
      fullCount,
      limit,
      displayPageMax,
    })

    const Ul = type === 'detailed-search' ? DetailedSeatchNavItemUL : NavItemUL

    return (
      <Ul fixed={ fixed } onClick={ e => e.stopPropagation() }>
        {isDebugMode && <li>{`f:${fullCount} l:${limit}`}</li>}
        <li>
          <ActivePageLink
            isActive={ page !== 0 }
            onButtonClick={ this.createOnPageMove(0) }
            isFirstLink
          />
        </li>
        <li>
          <ActivePageLink
            isActive={ page > 0 }
            onButtonClick={ this.createOnPageMove(page - 1) }
            isPrevLink
          />
        </li>
        {hasFormer ? '...' : null}
        {Array(top - bottom + 1)
          .fill(0)
          .map((_0, index) => {
            const indicatedPage = index + bottom
            return (
              <li
                className={
                  page === indicatedPage
                    ? 'button-active-wrap'
                    : 'button-inactive-wrap'
                }
                key={ indicatedPage }
              >
                <ActivePageLink
                  isActive={ page !== indicatedPage }
                  page={ indicatedPage }
                  onButtonClick={ this.createOnPageMove(indicatedPage) }
                />
              </li>
            )
          })}
        {hasFollower ? '...' : null}
        <li>
          <ActivePageLink
            isActive={ page < lastPage }
            onButtonClick={ this.createOnPageMove(page + 1) }
            isNextLink
          />
        </li>
        <li>
          <ActivePageLink
            isActive={ page !== lastPage }
            onButtonClick={ this.createOnPageMove(lastPage) }
            isLastLink
          />
        </li>
      </Ul>
    )
  }
}

export default connect(mapStateToProps)(SearchPagenation)
