import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { baseColor } from 'src/colors'
import Authorize from 'src/components/commons/authorize'
import CommonFooter from 'src/components/layouts/common-footer'
import CommonHeader from 'src/components/layouts/common-header'
import config from 'src/config'
import styled from 'styled-components'
import {
  CardContainer,
  LogoWrap,
  NavCard,
  NavLi,
  NavLink,
  NavUl,
} from './styled'

export const Li = styled.li`
  font-size: 1.2em;
  list-style: disc;
  margin: 0.3em;
`

/**
 * 経理課コンポーネント
 * @type {ReactComponent}
 */
export class AccountingView extends React.Component {
  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate() {
    return false
  }

  /**
   * render
   * @return {ReactDOM} dom
   */
  render() {
    const {
      env: { COMMODITY_KEIRI_URL },
    } = this.props

    const masterUrl = `${COMMODITY_KEIRI_URL}`
    const linkStyle = {
      display: 'flex',
      cursor: 'pointer',
      alignItems: 'center',
      textDecoration: 'none',
      color: baseColor,
      fontSize: '1.2em',
      padding: '10px 30px',
      backgroundColor: 'white',
      whiteSpace: 'nowrap',
    }
    const ToLink = styled.span`
      margin-right: 4px;

      &:hover {
        font-weight: bold;
      }
    `
    // window.open()で開くように変更
    const openMaster = () => {
      window.open(masterUrl, '_blank')
    }
    return (
      <div className={ 'callview__wrapper' }>
        <CommonHeader route={ 'accounting' } />
        <Authorize roles={ config.roles.accounting }>
          <div className={ 'main-navigation' }>
            <CardContainer>
              <NavCard>
                <LogoWrap>
                  <i className={ 'fa fa-check-circle fa-4x' } />
                </LogoWrap>
                <NavUl height={ 160 }>
                  {/* <NavLi>
                    <NavLink to={ '/accounting/check' }>{'金額確認'}</NavLink>
                  </NavLi>
                  <NavLi>
                    <NavLink to={ '/accounting/receivables' }>
                      {'売掛金管理'}
                    </NavLink>
                  </NavLi>
                  <NavLi>
                    <NavLink to={ '/accounting/invoice' }>{'書類発行'}</NavLink>
                  </NavLi> */}
                  <NavLi>
                    {' '}
                    <a
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={ openMaster }
                      style={ linkStyle }
                    >
                      <ToLink>{'経理確認処理'}</ToLink>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#1b95bf"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <rect x="7" y="3" width="14" height="14" rx="2" />
                        <path d="M17 17v2a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2" />
                      </svg>
                    </a>
                  </NavLi>
                </NavUl>
              </NavCard>

              <NavCard>
                <LogoWrap>
                  <i className={ 'fa fa-upload fa-4x' } />
                </LogoWrap>
                <NavUl height={ 160 }>
                  {/* <NavLi>
                    <NavLink to={ '/accounting/ss-self-payment' }>
                      {'SS自己負担登録'}
                    </NavLink>
                  </NavLi> */}
                  <NavLi>
                    <NavLink to={ '/accounting/settlement' }>
                      {'精算処理'}
                    </NavLink>
                  </NavLi>
                </NavUl>
              </NavCard>

              <NavCard width={ 240 }>
                <LogoWrap>
                  <i className={ 'fa fa-download fa-4x' } />
                </LogoWrap>
                <NavUl height={ 240 }>
                  {/* <NavLi>
                    <NavLink to={ '/accounting/card' }>{'カード管理表'}</NavLink>
                  </NavLi>
                  <NavLi>
                    <NavLink to={ '/accounting/ranking' }>
                      {'SSリアルタイム'}
                      <br />
                      {'売上ランキングデータ'}
                    </NavLink>
                  </NavLi>
                  <NavLi>
                    <NavLink to={ '/accounting/payment-csv' }>{'入金表'}</NavLink>
                  </NavLi> */}
                  <NavLi>
                    <NavLink to={ '/accounting/reward' }>{'報酬明細'}</NavLink>
                  </NavLi>
                  {/* <NavLi>
                    <NavLink to={ '/accounting/attendance' }>
                      {'勤怠表示'}
                    </NavLink>
                  </NavLi> */}
                </NavUl>
              </NavCard>
            </CardContainer>
          </div>
        </Authorize>
        <CommonFooter />
      </div>
    )
  }
}

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
const mapStateToProps = state => {
  return {
    accessToken: state.login.authentication.accessToken,
    env: state.env,
  }
}

AccountingView.propTypes = {
  // stateProps
  env: PropTypes.object.isRequired,
}

export default connect(mapStateToProps)(AccountingView)
