import React from 'react'
import PropTypes from 'prop-types'
import connect from '../../../connect'
import Autocomplete from 'react-autocomplete'
import Sticky from 'src/lib/class-sticky'
import findContact from '../../lib/find-contact'
import ValidationErrorMessage from 'src/components/commons/validation-error-message'
import { contactPrefInputStyle } from 'src/lib/format'

/**
 * 市町村コード・市町村名を入力します
 * @type {ReactComponent}
 */
export class CityCode extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    tabIndex: PropTypes.number.isRequired,
    contactsIndex: PropTypes.number.isRequired,
    route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
    disabled: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
    tabOrder: PropTypes.number,
    isDelete: PropTypes.bool,
    // stateProps
    cityCodes: PropTypes.object.isRequired,
    // dispatchProps
    updateStage: PropTypes.func.isRequired,

    cityCode: PropTypes.string,
    prefCode: PropTypes.string,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    id: null,
    contacts: [],
    cityCode: '',
    prefCode: '',
    tabOrder: 0,
    isDelete: false,
  }

  /**
   * コンストラクタ
   * @param {*} props
   */
  constructor(props) {
    super(props)
    const cityName = this.getCityName()
    this.state = {
      isEditing: false,
      editingValue: cityName,
      didEdit: false,
    }
  }

  /**
   * プロパティのqueryで渡される値がクリアされた時、
   * 項目が編集中でない時はテキストをクリアする。
   * @param {*} nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      // ownProps
      sticky,
      tabIndex,
      contactsIndex,
      cityCodes,
    } = this.props
    const contact = findContact(sticky, tabIndex, contactsIndex) || {}
    const { prefCode } = contact
    const prefCityCodes = cityCodes[prefCode] || []
    const cityName = (
      prefCityCodes.find(city => city.code === nextProps.cityCode) || {}
    ).cityName
    this.setState({
      editingValue: cityName,
      didEdit: false,
    })
  }

  /**
   * コンポーネントが更新された
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    const { prevSticky } = prevProps
    const {
      // ownProps
      sticky,
      tabIndex,
      contactsIndex,
      cityCodes,
    } = this.props
    // 都道府県コードが変わって、市町村の一覧がまだ取得されていない時は取得する。
    const prevContact = findContact(prevSticky, tabIndex, contactsIndex) || {}
    const { prevPrefCode } = prevContact
    const contact = findContact(sticky, tabIndex, contactsIndex) || {}
    const { prefCode } = contact
    const prefCityCodes = cityCodes[prefCode] || []
    if (prefCode && prevPrefCode !== prefCode && prefCityCodes.length === 0) {
      const {
        login: { accessToken },
        getCityCodes,
      } = this.props
      getCityCodes(prefCode, accessToken)
    }
  }

  /**
   * 付箋のcontactに登録された市町村の名前を取得する。
   */
  getCityName = () => {
    const {
      // ownProps
      sticky,
      tabIndex,
      contactsIndex,
      cityCodes,
    } = this.props

    const contact = findContact(sticky, tabIndex, contactsIndex) || {}
    const { cityCode, prefCode } = contact
    const prefCityCodes = cityCodes[prefCode] || []
    const cityName = (prefCityCodes.find(city => city.code === cityCode) || {})
      .cityName
    return cityName
  }

  getIdentifier = route =>
    `contact-city-code-text-form-${this.props.tabIndex}-${this.props.contactsIndex}-${route}`

  /**
   * 項目から表示する値を取得する
   */
  getItemValue = city => city.cityName

  /**
   * 個々の項目を描画する
   */
  renderItem = (city, isHighlighted, style) => (
    <div
      key={ city.code }
      style={ {
        ...style,
        backgroundColor: isHighlighted ? 'lightgray' : 'white',
      } }
    >
      {city.cityName}
    </div>
  )

  /**
   * メニューの描画
   * @param {*} items
   * @param {*} value
   * @param {*} style
   */
  renderMenu(items, value, style) {
    return (
      <div
        style={ { ...style, ...this.menuStyle } }
        className={ 'autocomplete-menu' }
        // eslint-disable-next-line react/no-children-prop
        children={ items }
      />
    )
  }

  /**
   * 入力項目の描画
   */
  renderInput = props => {
    const { invalidcity } = props
    const { route, disabled, readOnly, tabOrder, isDelete } = this.props
    return (
      <input
        { ...props }
        id={ this.getIdentifier(route) }
        name={ this.getIdentifier(route) }
        disabled={ disabled }
        readOnly={ readOnly }
        onFocus={ this.onInputFocus }
        style={ {
          ...contactPrefInputStyle({
            disabled,
            readOnly,
            isDelete,
            invalid: invalidcity,
          }),
          width: '18rem',
        } }
        tabIndex={ tabOrder }
      />
    )
  }

  /**
   * 項目を描画するかの判定
   */
  shouldItemRender = (city, inputValue) => {
    // 市町村一覧の読み込みが完了していない時、未入力の時は全てを表示させる
    if (!city || inputValue === '') {
      return true
    }
    // 入力された名前が市町村名に前方一致するか
    const [name, value] = [city.cityName, inputValue].map(element =>
      element.replace(/ /g, ''),
    )
    return name.indexOf(value) === 0
  }

  /**
   * 入力にフォーカスが当たった時
   */
  onInputFocus = () =>
    this.setState({
      isEditing: true,
    })

  /**
   * 名前が変更された時
   */
  onChange = e => {
    this.state.isEditing &&
      this.setState({ editingValue: e.target.value, didEdit: true })
    // 市がクリアされた時は市とその他をクリアする
    if (e.target.value === '') {
      const { tabIndex, contactsIndex, updateStage } = this.props
      const diffProps = {
        contacts: {
          [tabIndex]: {
            [contactsIndex]: {
              cityName: '',
              cityCode: '',
              address: '',
            },
          },
        },
      }
      updateStage(new Sticky(diffProps))
    }
  }

  /**
   * 項目が選択された時
   */
  onSelect = (_0, city) => {
    this.setState({ editingValue: city.cityName, didEdit: true })
    const { sticky, tabIndex, contactsIndex, updateStage } = this.props

    const id = sticky.getContactId(tabIndex, contactsIndex)
    const existsClient = sticky.existsClient()
    const cityName = city.cityName
    const cityCode = city.code

    const diffProps = {
      contacts: {
        [tabIndex]: {
          [contactsIndex]: existsClient
            ? { id, cityName, cityCode }
            : { id, cityName, cityCode, isClient: true },
        },
      },
    }
    updateStage(new Sticky(diffProps))
  }

  setCityName = (cityName, didEdit) => {
    this.setState({ editingValue: cityName, didEdit })
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const {
      sticky,
      tabIndex,
      contactsIndex,
      route,
      // stateProps
      cityCodes,
    } = this.props
    const { editingValue } = this.state
    const contact = findContact(sticky, tabIndex, contactsIndex) || {}
    const { prefCode } = contact
    const prefCityCodes = cityCodes[prefCode] || []
    const cityName = (
      prefCityCodes.find(city => city.cityName === editingValue) || {}
    ).cityName
    const invalidCity = !!editingValue && !cityName
    // constructorで市町村名が取得できなかった時のため、市町村を編集していない時のみ、contactに登録された市町村の名前を表示する。
    if (!this.state.didEdit) {
      const cityName = this.getCityName()
      if (cityName) {
        // 名前が取得できたら編集済みとし、ここに再入しないようにする。
        this.setCityName(cityName, true)
      }
    }

    return (
      <div>
        <dl
          className={ 'label-inputs-wrap' }
          style={ { display: 'grid', gridTemplateColumns: '9.25rem 1fr' } }
        >
          <dt>
            <label htmlFor={ this.getIdentifier(route) }>{'住所(市町村)'}</label>
          </dt>
          <dd className={ 'autocomplete-wrap-narrow' }>
            <Autocomplete
              getItemValue={ this.getItemValue }
              items={ prefCityCodes }
              renderItem={ this.renderItem }
              renderMenu={ this.renderMenu }
              inputProps={ { invalidcity: invalidCity.toString() } }
              renderInput={ this.renderInput }
              shouldItemRender={ this.shouldItemRender }
              onChange={ this.onChange }
              onSelect={ this.onSelect }
              value={ editingValue }
            />
          </dd>
        </dl>
        {invalidCity && (
          <ValidationErrorMessage message={ '不明な市区町村名です' } />
        )}
      </div>
    )
  }
}

export default connect(CityCode)
