import React from 'react'
import PropTypes from 'prop-types'
import connect from '../../connect'

/**
 * 追加金額の単位種別入力コンポーネント
 * @type {ReactComponent}
 */
export const UnitType = ({ value, onChange, route, disabled, master }) => {
  /**
   * ドロップダウンが変更された
   * @param {*} e
   */
  const onUnitTypechange = e => {
    const UnitTypeId = parseInt(e.target.value, 10)
    onChange(UnitTypeId)
  }

  const groupName = `houselabo-${name}-${route}`
  const identifier = `id-${groupName}`
  const dispUnitTypes = master.hlUnitType

  return (
    <select
      name={ identifier }
      id={ identifier }
      onChange={ onUnitTypechange }
      value={ value }
      disabled={ disabled }
      style={ { width: '80px' } }
    >
      {dispUnitTypes.map(item => (
        <option key={ item.id } value={ item.id }>
          {item.name}
        </option>
      ))}
    </select>
  )
}

/**
 * propTypes
 * @type {object}
 */
UnitType.propTypes = {
  value: PropTypes.number.isRequired, // 表示する値
  onChange: PropTypes.func.isRequired, // 入力があった時のコールバック
  route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
  disabled: PropTypes.bool.isRequired,
  // stateprops
  master: PropTypes.object.isRequired,
}

export default connect(React.memo(UnitType))
