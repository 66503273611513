import update from 'immutability-helper'

/**
 * タイプ定義
 * @type {string}
 */
const type = 'WARA.TOGGLE_ASSIGN_BUTTON_FORCE_DISABLED'

/**
 * exportation
 */
export default {
  /**
   * action type
   * @type {string}
   */
  type,

  /**
   * action creator
   * @param {boolean} disabled ボタンを使用不可にするかどうか
   * @return {object} action
   */
  creator: disabled => {
    return {
      type,
      payload: { disabled },
    }
  },

  /**
   * [handler description]
   * @param  {object}  state   [description]
   * @param  {{payload:{disabled:boolean=false}}} action  [description]
   * @return {object}          [description]
   */
  handler: (state, { payload: { disabled } }) => {
    return update(state, {
      modal: {
        toggleAssignButtonForceDisabled: { $set: disabled },
      },
    })
  },
}
