import utils from 'src/lib/api-utils'
import Sticky from 'src/lib/class-sticky'

export const putOrPostCollectStickies = (
  payment,
  collectProps,
  accessToken,
  env,
) => {
  const endpoint = `${utils.createEndpoint(env, 'sticky')}`
  const method = collectProps.id ? 'PUT' : 'POST'

  return fetch(endpoint, {
    method,
    headers: {
      'Content-Type': 'Application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(new Sticky(collectProps, { normalize: false }).json()),
  })
    .then(res => {
      if (res.ok) {
        return res.json()
      } else {
        throw '集金付箋作成エラー'
      }
    })
    .then(data => ({
      sticky: new Sticky(data, { normalize: false }),
      method,
    }))
}

export default putOrPostCollectStickies
