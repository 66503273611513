import utils from 'src/lib/api-utils'
import { CALL_API } from 'redux-api-middleware'
import { NETWORKER } from 'src/middlewares/network-monitor'

import switz from 'switz'
import update from 'immutability-helper'
import { ACTION_TYPES as NETWORK_ACTION_TYPES } from 'src/reducers/network-status'

import { today } from 'src/lib/moment'

export const PUT_STAFF_DAILY_REQUEST = 'PUT_STAFF_DAILY_REQUEST'
export const PUT_STAFF_DAILY_SUCCESS = 'PUT_STAFF_DAILY_SUCCESS'
export const PUT_STAFF_DAILY_FAILURE = 'PUT_STAFF_DAILY_FAILURE'

/**
 * action type name bulk export
 * @type {object}
 */
export const ACTION_TYPES = {
  PUT_STAFF_DAILY_REQUEST,
  PUT_STAFF_DAILY_SUCCESS,
  PUT_STAFF_DAILY_FAILURE,
}

/**
 * [creator description]
 * @param  {object} ssProps     [description]
 * @param  {number} id          dailyInfoのID
 * @param  {number} staffId     [description]
 * @param  {string} accessToken [description]
 * @param  {{regionIndex: number, listIndex: number}} このstaffのロケーション
 * @return {object} redux-api-middleware に渡す非同期処理情報を内包したアクション
 */
export const creator = (ssProps, id, staffId, accessToken, location, env) => {
  const endpoint = `${utils.createEndpoint(env, 'staff')}`
  const date = today()
  const body = JSON.stringify({
    dailyInfo: {
      id,
      staffId,
      date,
      ...ssProps,
    },
  })
  // eslint-disable-next-line no-console
  !__TEST__ && console.log('put-staff', endpoint, body)
  return {
    [CALL_API]: {
      endpoint,
      method: 'PUT',
      headers: { Authorization: `Bearer ${accessToken}` },
      body,
      types: [
        {
          type: PUT_STAFF_DAILY_REQUEST,
          meta: {
            staffId,
            date,
            location,
            [NETWORKER]: {
              target: 'putStaffDaily',
              networkActionType: NETWORK_ACTION_TYPES.REQUEST,
            },
          },
        },
        {
          type: PUT_STAFF_DAILY_SUCCESS,
          meta: {
            staffId,
            date,
            location,
            [NETWORKER]: {
              target: 'putStaffDaily',
              networkActionType: NETWORK_ACTION_TYPES.SUCCESS,
            },
          },
          payload: (action, state, res) => res.json().then(payload => payload),
        },
        {
          type: PUT_STAFF_DAILY_FAILURE,
          meta: {
            staffId,
            date,
            location,
            [NETWORKER]: {
              target: 'putStaffDaily',
              networkActionType: NETWORK_ACTION_TYPES.FAILURE,
            },
          },
          payload: (action, state, res) => res.json().then(payload => payload),
        },
      ],
    },
  }
}
/**
 * [AsyncWaraReducers description]
 * @param  {object} state   state
 * @param  {object} action action
 * @return {object}         reducer
 */
export const partialReducer = (state, action) => {
  const { type, payload, meta } = action

  return switz(type, s =>
    s
      .case(PUT_STAFF_DAILY_REQUEST, () => state)
      .case(PUT_STAFF_DAILY_SUCCESS, () => {
        const { regionIndex, listIndex } = meta.location

        return update(state, {
          data: {
            regions: {
              [regionIndex]: {
                lists: {
                  [listIndex]: {
                    dailyInfo: {
                      $merge: payload.dailyInfo,
                    },
                  },
                },
              },
            },
          },
        })
      })
      .case(PUT_STAFF_DAILY_FAILURE, () => {
        alert(
          'スタッフ情報の更新でエラーになりました。画面をリロードしてください。',
        )
        return state
      })
      .default(() => state),
  )
}
