import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { divide } from 'src/lib/format'
import { today } from 'src/lib/moment'

// Components
import Authorize from 'src/components/commons/authorize'
import CommonFooter from 'src/components/layouts/common-footer'
import CommonHeader from 'src/components/layouts/common-header'
import { NavListItem } from '../home-view'
import {
  Container,
  ItemsWrap,
  NavLabel,
  NavLink,
  NavigationItems,
  Pillar,
  Title,
} from './styled'

import { SINGULATE_USERPRESET_SELECTED_REGION } from 'src/reducers/user-preset'

import config from 'src/config'

/**
 * map state to props
 * @param  {object} state state
 * @return {object}      mapped state as props
 */
const mapStateToProps = state => {
  return {
    masterRegions: state.master.data.regions,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    /**
     * 表示する地域を選んでユーザープリセットを更新する
     * @param  {array} masterRegions     マスタ
     * @param  {number} selectedRegionId 選択した地域id
     * @return {void}
     */
    selectDisplayRegions: (masterRegions, selectedRegionId) => {
      dispatch({
        type: SINGULATE_USERPRESET_SELECTED_REGION,
        payload: { regionId: selectedRegionId, masterRegions },
      })
    },
  }
}

/**
 * アサイン課業務コンポーネント
 * @type {ReactComponent}
 */
export class AssignView extends React.PureComponent {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // stateProps
    masterRegions: PropTypes.array,
    // dispatchProps
    selectDisplayRegions: PropTypes.func.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    masterRegions: [],
  }

  createSelectDisplayRegionsHandler = regionId => () =>
    this.props.selectDisplayRegions(this.props.masterRegions, regionId)

  /**
   * render
   * @return {ReactDOM} dom
   */
  render() {
    const {
      // stateProps
      masterRegions,
    } = this.props

    const [year, month, day] = today().split('-')
    /**
     * 3個づつに分けたregionのマスタ
     * @type {Array<Array<object>>}
     */
    const displayRegions = (masterRegions || [])
      .filter(region => !/HL|Q/.test(region.name))
      .filter(region => region.isValid)
    const dividedMaterRegions = divide(displayRegions, 3)


    return (
      <div>
        <CommonHeader route={'assign'} />

        <Title>{'案件一覧ページ'}</Title>

        <Container>
          <NavigationItems role={'navigation'}>
            <NavListItem
              role={config.roles.assign}
              roleor={false}
              to={`/assign/wara/${year}/${month}/${day}`}
              icon={'fa-file-o'}
              label={'地域別わら管理'}
            />
          </NavigationItems>

          <ItemsWrap role={'navigation'}>
            {dividedMaterRegions.map((regions, divisionIndex) => (
              // TODO: パフォーマンスに劣る
              <Pillar key={divisionIndex}>
                {regions.map(region => (
                  <Authorize roles={config.roles.assign} key={region.id}>
                    <NavLink
                      to={`/assign/wara/${year}/${month}/${day}`}
                      onClick={this.createSelectDisplayRegionsHandler(
                        region.id,
                      )}
                    >
                      <NavLabel>{region.name}</NavLabel>
                    </NavLink>
                  </Authorize>
                ))}
              </Pillar>
            ))}
          </ItemsWrap>
        </Container>

        <CommonFooter />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignView)
