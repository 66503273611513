import React from 'react'
import PropTypes from 'prop-types'
import connect from 'src/components/commons/custom-controls/connect'
import DeleteButton from './delete-button'

/**
 * 作業内容の各テキスト
 * @param {*} props
 */
class HouseLaboWorkText extends React.Component {
  static propTypes = {
    houseLaboWork: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    keyname: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    updateHouseLaboWorkText: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.identifier = `hlwork-${props.keyname}-${props.index}`
  }

  /**
   * 最初の表示時に行数を調整するため、ここでadjustHeight()を呼ぶ。
   */
  componentDidMount() {
    this.adjustHeight(this.identifier)
  }

  /**
   * textareaの高さを入力に応じて変更する
   * @param {*} identifier textareaのID
   */
  adjustHeight = identifier => {
    const target = document.getElementById(identifier)
    let lineHeight = Number(target.getAttribute('rows'))
    while (target.scrollHeight > target.offsetHeight) {
      lineHeight++
      target.setAttribute('rows', lineHeight)
    }
  }

  // テキストが変わった時は反映後サイズ変更する
  changeText = e => {
    const { index, keyname, updateHouseLaboWorkText } = this.props
    updateHouseLaboWorkText(e, index, keyname)
    this.adjustHeight(this.identifier)
  }

  render() {
    const { houseLaboWork, keyname, label, disabled } = this.props
    const textAreaStyle = {
      height: 'initial',
      width: '90%',
    }
    return (
      <div
        className={ 'label-inputs-wrap' }
        style={ { flexGrow: 1, marginTop: '2px', marginBottom: '2px' } }
      >
        <div style={ { display: 'flex', alignItems: 'baseline', width: '100%' } }>
          <label
            htmlFor={ this.identifier }
            style={ { fontWeight: 'bold', width: '80px', overflow: 'visible' } }
          >
            {label}
          </label>
          <textarea
            style={ textAreaStyle }
            rows={ '1' }
            value={ houseLaboWork[keyname] || '' }
            id={ this.identifier }
            name={ this.identifier }
            onKeyDown={ () => this.adjustHeight(this.identifier) }
            onFocus={ () => this.adjustHeight(this.identifier) }
            onChange={ this.changeText }
            disabled={ disabled }
          />
        </div>
      </div>
    )
  }
}

/**
 * 作業内容
 * @type {ReactComponent}
 */
const HouseLaboWork = props => {
  const {
    houseLaboWork,
    index,
    updateHouseLaboWorkText,
    removeHouseLaboWorks,
    disabled,
    isHomeServe,
  } = props

  return (
    <div
      style={ {
        display: 'flex',
        marginTop: '2px',
        marginBottom: '4px',
        paddingLeft: '10px',
        paddingTop: '2px',
        paddingBottom: '2px',
        border: '1px lightgrey solid',
        borderRadius: '5px',
      } }
    >
      <div style={ { flexGrow: 2 } }>
        <div style={ { display: 'flex' } }>
          <HouseLaboWorkText
            houseLaboWork={ houseLaboWork }
            index={ index }
            keyname={ 'condition' }
            label={ isHomeServe ? '原因' : '現場状況' }
            disabled={ disabled }
            updateHouseLaboWorkText={ updateHouseLaboWorkText }
          />
          <HouseLaboWorkText
            houseLaboWork={ houseLaboWork }
            index={ index }
            keyname={ 'cause' }
            label={ isHomeServe ? '結果' : '原因' }
            disabled={ disabled }
            updateHouseLaboWorkText={ updateHouseLaboWorkText }
          />
        </div>
        <div style={ { display: 'flex' } }>
          <HouseLaboWorkText
            houseLaboWork={ houseLaboWork }
            index={ index }
            keyname={ 'result' }
            label={ '対応' }
            disabled={ disabled }
            updateHouseLaboWorkText={ updateHouseLaboWorkText }
          />
          <HouseLaboWorkText
            houseLaboWork={ houseLaboWork }
            index={ index }
            keyname={ 'remark' }
            label={ '補足' }
            disabled={ disabled }
            updateHouseLaboWorkText={ updateHouseLaboWorkText }
          />
        </div>
      </div>
      <div style={ { marginTop: '17px' } }>
        <DeleteButton
          onClick={ () => removeHouseLaboWorks(index) }
          disabled={ disabled }
        />
      </div>
    </div>
  )
}

/**
 * Validation
 * @type {object}
 */
HouseLaboWork.propTypes = {
  // ownProps
  houseLaboWork: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  updateHouseLaboWorkText: PropTypes.func.isRequired, // (e, idx, keyname) => 作業内容の更新
  removeHouseLaboWorks: PropTypes.func.isRequired, // (idx) => 作業内容の削除
  disabled: PropTypes.bool.isRequired,
  isHomeServe: PropTypes.bool, // ホームサーブで使うときはtrue
}

HouseLaboWork.defaultProps = {
  isHomeServe: false,
}

export default connect(HouseLaboWork)
