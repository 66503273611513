import React from 'react'
import PropTypes from 'prop-types'
import connect from '../../../connect'
import Autocomplete from 'react-autocomplete'
import Sticky from 'src/lib/class-sticky'
import findContact from '../../lib/find-contact'
import ValidationErrorMessage from 'src/components/commons/validation-error-message'
import createClassNames from 'classnames'
import requestAddress from 'src/lib/address-api/post'
import { contactPrefInputStyle } from 'src/lib/format'
import { baseColor } from 'src/colors'
import styled from 'styled-components'

/**
 * 都道府県コードを入力します
 * @type {ReactComponent}
 */
export class PrefCode extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    tabIndex: PropTypes.number.isRequired,
    contactsIndex: PropTypes.number.isRequired,
    route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
    disabled: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
    tabOrder: PropTypes.number,
    isDelete: PropTypes.bool,
    // stateProps
    master: PropTypes.shape({
      prefCodes: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          regionId: PropTypes.number.isRequired,
        }),
      ),
    }).isRequired,
    login: PropTypes.shape({
      accessToken: PropTypes.string.isRequired,
    }).isRequired,
    env: PropTypes.object.isRequired,
    cityCodes: PropTypes.object.isRequired,
    // dispatchProps
    updateStage: PropTypes.func.isRequired,
    getCityCodes: PropTypes.func.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    id: null,
    contacts: [],
    prefCode: '',
    isClient: false,
    tabOrder: 0,
    isDelete: false,
  }

  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    const {
      sticky,
      tabIndex,
      contactsIndex,
      master: { prefCodes },
    } = this.props
    const contact = findContact(sticky, tabIndex, contactsIndex) || {}
    const { prefCode } = contact
    const prefName = (prefCodes.find(pref => pref.code === prefCode) || {}).name
    this.state = {
      isEditing: false,
      editingValue: prefName,
      notFound: false,
      timerId: false,
      status: 'not_yet',
      addrData: [], // 住所検索の結果
      showAddrSelect: false, // 住所選択画面表示フラグ
    }
    // マウント時に市町村をとって来させる
    this.getCityCodesAsync()
  }

  /**
   * プロパティのqueryで渡される値がクリアされた時、
   * 項目が編集中でない時はテキストをクリアする。
   * @param {*} nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { sticky, tabIndex, contactsIndex } = nextProps
    const nextPrefCode = (findContact(sticky, tabIndex, contactsIndex) || {})
      .prefCode
    const {
      master: { prefCodes },
    } = this.props
    const prefName = (prefCodes.find(pref => pref.code === nextPrefCode) || {})
      .name
    this.setState({ editingValue: prefName })
    // isClientという名前だが、実際は「居住者」の先頭を指す。「依頼者」の扱いが変わったことによる変更。
    const isClient = tabIndex === 0 && contactsIndex === 0

    // 該当contactの都道府県が変わった時に市町村データを取得する。
    // tabIndex, contactsIndexは変わらないはず？何考えているのかわからん。
    const {
      sticky: cSticky,
      tabIndex: cTabIndex,
      contactsIndex: cContactsIndex,
    } = this.props
    const currentPrefCode = (
      findContact(cSticky, cTabIndex, cContactsIndex) || {}
    ).prefCode
    if (currentPrefCode !== nextPrefCode) {
      this.getCityCodesAsync(nextPrefCode)
    }
    // エリア自動選択（地域自動選択）
    if (
      isClient &&
      (this.props.sticky.findClient() || {}).prefCode !==
        (nextProps.sticky.findClient() || {}).prefCode
    ) {
      // エリア自動選択
      const filtered = (this.props.master.prefCodes || []).filter(
        x => x.code === nextPrefCode,
      )
      const nextRegionId = (filtered[0] || {}).regionId || null

      const diffProps = {
        regionId: nextRegionId,
      }
      this.props.updateStage(new Sticky(diffProps))
    }
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.sticky.json().contacts !== nextProps.sticky.json().contacts ||
      this.props.master.prefCodes !== nextProps.master.prefCodes ||
      this.props.sticky.json().regionId !== nextProps.sticky.json().regionId ||
      this.props.disabled !== nextProps.disabled ||
      this.props.readOnly !== nextProps.readOnly ||
      this.state.isEditing !== nextState.isEditing ||
      this.state.editingValue !== nextState.editingValue ||
      this.state.status !== nextState.status ||
      this.state.notFound !== nextState.notFound ||
      this.state.addrData !== nextState.addrData ||
      this.state.showAddrSelect !== nextState.showAddrSelect ||
      this.props.narrowScreen !== nextProps.narrowScreen
    )
  }

  /**
   * componentWillUnmount
   * @return {void}
   */
  componentWillUnmount() {
    clearTimeout(this.state.timerId)
  }

  /**
   * 該当する都道府県コードの市町村コードを取得するリクエストを発出し、statePropsに格納する
   * @param  {string|undefined} givenPrefCode 都道府県コード。undefinedの時は、this.propsを使う
   * @return {void}
   */
  getCityCodesAsync = givenPrefCode => {
    if (this.props.route !== 'history') {
      const { sticky, tabIndex, contactsIndex, cityCodes } = this.props

      const prefCode =
        givenPrefCode ||
        (findContact(sticky, tabIndex, contactsIndex) || {}).prefCode

      if (
        prefCode &&
        (!cityCodes[prefCode] || cityCodes[prefCode].length === 0)
      ) {
        const {
          login: { accessToken },
          getCityCodes,
        } = this.props
        getCityCodes(prefCode, accessToken)
      }
    }
  }

  getIdentifier = route =>
    `contact-pref-code-text-form-${this.props.tabIndex}-${this.props.contactsIndex}-${route}`

  /**
   * 項目から表示する値を取得する
   */
  getItemValue = pref => pref.name

  /**
   * 個々の項目を描画する
   */
  renderItem = (pref, isHighlighted) => (
    <div
      key={ pref.code }
      style={ {
        backgroundColor: isHighlighted ? 'lightgray' : 'white',
      } }
    >
      {pref.name}
    </div>
  )

  /**
   * メニューの描画
   * @param {*} items
   * @param {*} value
   * @param {*} style
   */
  renderMenu(items, value, style) {
    return (
      <div
        style={ { ...style, ...this.menuStyle } }
        className={ 'autocomplete-menu' }
        // eslint-disable-next-line react/no-children-prop
        children={ items }
      />
    )
  }

  /**
   * 入力項目の描画
   */
  renderInput = props => {
    const { invalidpref } = props
    const { route, disabled, readOnly, tabOrder, isDelete } = this.props
    return (
      <input
        { ...props }
        id={ this.getIdentifier(route) }
        name={ this.getIdentifier(route) }
        disabled={ disabled }
        readOnly={ readOnly }
        onFocus={ this.onInputFocus }
        style={
          (contactPrefInputStyle({
            disabled,
            readOnly,
            isDelete,
            invalid: invalidpref,
          }),
          {
            width: '12rem',
          })
        }
        tabIndex={ tabOrder }
      />
    )
  }

  /**
   * 項目を描画するかの判定
   */
  shouldItemRender = (pref, inputValue) => {
    // 未入力の時は全てを表示させる
    if (inputValue === '') {
      return true
    }
    // 入力された名前が都道府県名に前方一致するか
    const [name, value] = [pref.name, inputValue].map(element =>
      element.replace(/ /g, ''),
    )
    return name.indexOf(value) === 0
  }

  /**
   * 入力にフォーカスが当たった時
   */
  onInputFocus = () =>
    this.setState({
      isEditing: true,
    })

  /**
   * 名前が変更された時
   */
  onChange = e => {
    this.state.isEditing && this.setState({ editingValue: e.target.value })
    // 都道府県がクリアされた時は住所全てをクリアする。
    if (e.target.value === '') {
      const { sticky, tabIndex, contactsIndex, updateStage } = this.props
      const id = sticky.getContactId(tabIndex, contactsIndex)
      const diffProps = {
        contacts: {
          [tabIndex]: {
            [contactsIndex]: {
              id, // idを抜くと、「更新する」押下後に復活する
              prefName: '',
              prefCode: '',
              cityName: '',
              cityCode: '',
              address: '',
            },
          },
        },
      }
      updateStage(new Sticky(diffProps))
    }
  }

  /**
   * 項目が選択された時
   */
  onSelect = (_0, pref) => {
    this.setState({ editingValue: pref.name })
    const { sticky, tabIndex, contactsIndex, updateStage } = this.props

    const id = sticky.getContactId(tabIndex, contactsIndex)
    const existsClient = sticky.existsClient()
    const prefName = pref.name
    const prefCode = pref.code

    const isClient = !!(
      ((sticky.json().contacts || [])[tabIndex] || [])[contactsIndex] || {}
    ).isClient

    // 市町村コードを取得する
    let nextRegionId = null
    if (prefCode) {
      this.getCityCodesAsync(prefCode)

      // エリア自動選択
      const filtered = (this.props.master.prefCodes || []).filter(
        x => x.code === prefCode,
      )
      nextRegionId = (filtered[0] || {}).regionId || null
    }

    const diffProps = {
      contacts: {
        [tabIndex]: {
          [contactsIndex]: existsClient
            ? { id, prefName, prefCode }
            : { id, prefName, prefCode, isClient: true },
        },
      },
      regionId: x => (existsClient && !isClient ? x : nextRegionId),
    }
    updateStage(new Sticky(diffProps))
  }

  /**
   * 住所一覧検索
   */
  onSearchClick = () => {
    const {
      sticky,
      tabIndex,
      contactsIndex,
      login: { accessToken },
      env,
    } = this.props
    const stickyProps = sticky.json()

    const contact =
      ((stickyProps.contacts || [])[tabIndex] || [])[contactsIndex] || {}
    const prefName = contact.prefName || ''
    const cityName = contact.cityName || ''
    const address = contact.address || ''

    this.setState({ status: 'request' })
    // 住所検索実行
    requestAddress(prefName, cityName, address, accessToken, env)
      .then(res => {
        if (res.ok) {
          return res.json()
        } else {
          throw { statusCode: res.status }
        }
      })
      .then(data => {
        if (!Array.isArray(data) || data.length === 0) {
          throw { statusCode: 404 }
        }
        // 選択画面を表示する
        const showAddrSelect = data.length > 0
        this.setState({ status: 'success', addrData: data, showAddrSelect })
      })
      .catch(error =>
        this.setState({
          status: 'failure',
          notFound: error.statusCode === 404,
          timerId: setTimeout(
            () =>
              this.setState({
                status: 'not_yet',
                notFound: false,
              }),
            2000,
          ),
        }),
      )
  }

  /**
   * 選択した住所を反映する
   */
  updateAddress = data => {
    const { sticky, tabIndex, contactsIndex, updateStage } = this.props
    const id = sticky.getContactId(tabIndex, contactsIndex)
    const client = sticky.findClient() || {}
    const existsClient = client.isClient

    const nextContactPropsDiff = {
      zip: data.zipCode,
      prefCode: data.prefCode,
      cityCode: data.cityCode,
      cityName: data.cityName,
      address: data.address,
    }

    const diffProps = {
      contacts: {
        [tabIndex]: {
          [contactsIndex]: existsClient
            ? nextContactPropsDiff
            : { ...nextContactPropsDiff, id, isClient: true },
        },
      },
    }
    updateStage(new Sticky(diffProps))
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const {
      sticky,
      tabIndex,
      contactsIndex,
      route,
      master: { prefCodes },
      disabled,
      tabOrder,
    } = this.props
    const contact = findContact(sticky, tabIndex, contactsIndex) || {}
    const {
      editingValue,
      status,
      notFound,
      addrData,
      showAddrSelect,
    } = this.state

    const prefName = (prefCodes.find(pref => pref.name === editingValue) || {})
      .name
    const invalidPref = !!editingValue && !prefName
    // 都道府県名、市町村名、住所のどれかが入力されていたらボタンクリック可能にする。
    const contPrefName = contact.prefName || ''
    const cityName = contact.cityName || ''
    const address = contact.address || ''

    const isButtonDisabled = !(
      contPrefName !== '' ||
      cityName !== '' ||
      address !== ''
    )
    const buttonClassNames = createClassNames({
      button: true,
      'button-open': true,
      'label-inputs-wrap-button': true,
      'button-disabled': isButtonDisabled,
    })

    // 住所選択ポップアップのスタイル
    const addrSelectDivStyle = {
      position: 'absolute',
      top: '300px',
      left: '400px',
      height: '300px',
      width: '400px',
      padding: '8px',
      background: 'rgba(255, 255, 255, .9)',
      borderRadius: '10px',
      zIndex: 20,
      cursor: 'pointer',
      boxShadow: 'rgba(0, 0, 0, 0.4) 0px 10px 10px',
      backdropFilter: 'blur(4px)',
    }

    const addrSelectHeader = {
      margin: '0.75rem',
    }

    const addrSelectClose = {
      position: 'absolute',
      top: '5px',
      right: '10px',
      fontSize: '2em',
    }

    const addrSelectTableWrap = {
      margin: '0.75rem',
      height: '270px',
      overflowX: 'hidden',
      overflowY: 'scroll',
    }

    // const addrSelectTr = {
    //   borderRight: 'solid 1px #1b95bf',
    //   borderLeft: 'solid 1px #1b95bf',
    // }

    // テーブルヘッダに枠線をつける
    const addrSelectTh = {
      borderBottom: 'dotted 1px rgb(255, 255, 255)',
      background: 'rgb(27, 149, 191)',
      padding: '0.5rem',
    }

    const addrSelectThKana = {
      borderBottom: 'solid 2px',
      background: 'rgb(27, 149, 191)',
      padding: '0.5rem',
    }

    // テーブルに枠線をつける
    const addrSelectTd = {
      borderBottom: 'dotted 1px grey',
      padding: '0.5rem',
    }

    const addrSelectTdKana = {
      borderBottom: 'solid 1px',
      padding: '0.5rem',
    }

    const AddrSelectTbody = styled.tbody`
      width: 100%;

      &:hover {
        background-color: ${baseColor};
      }
    `

    // テーブルで住所を選択したときの処理
    const selectAddr = index => {
      const data = this.state.addrData[index]
      this.updateAddress(data)
      this.setState({ showAddrSelect: false })
    }

    // テーブルを閉じる
    const closeAddrTable = () => {
      this.setState({ showAddrSelect: false })
    }

    return (
      <div>
        <dl
          className={ 'label-inputs-wrap' }
          style={ { display: 'grid', gridTemplateColumns: '9.25rem 1fr' } }
        >
          <dt>
            <label
              htmlFor={ this.getIdentifier(route) }
              className={ tabIndex === 0 ? 'is-required' : '' }
            >
              {'都道府県'}
            </label>
          </dt>
          <dd className={ 'autocomplete-wrap-narrow' }>
            <div style={ { display: 'flex' } }>
              <Autocomplete
                getItemValue={ this.getItemValue }
                items={ prefCodes }
                renderItem={ this.renderItem }
                renderMenu={ this.renderMenu }
                inputProps={ { invalidpref: invalidPref.toString() } }
                renderInput={ this.renderInput }
                shouldItemRender={ this.shouldItemRender }
                onChange={ this.onChange }
                onSelect={ this.onSelect }
                value={ editingValue }
              />
              <button
                className={ buttonClassNames }
                onClick={ this.onSearchClick }
                disabled={ isButtonDisabled || disabled }
                tabIndex={ tabOrder + 1 }
              >
                <i className={ 'fa fa-search' } />
              </button>
            </div>
            {status === 'failure' && (
              <span className={ 'notice notice-zip-not-found' }>
                {notFound ? '該当なし' : '通信エラー'}
              </span>
            )}
          </dd>
        </dl>
        {/* 住所選択結果の一覧を表示する */}
        {showAddrSelect && (
          <div style={ addrSelectDivStyle }>
            <div style={ addrSelectHeader }>
              <span>{'住所検索'}</span>
              <a style={ addrSelectClose } onClick={ closeAddrTable }>
                {'×'}
              </a>
            </div>
            <div style={ addrSelectTableWrap }>
              <table style={ { width: '100%' } }>
                <thead>
                  <tr>
                    <th style={ addrSelectTh }>{'郵便番号'}</th>
                    <th style={ { ...addrSelectTh, whiteSpace: 'nowrap' } }>
                      {'都道府県'}
                    </th>
                    <th style={ { ...addrSelectTh, whiteSpace: 'nowrap' } }>
                      {'市町村'}
                    </th>
                    <th style={ addrSelectTh }>{'その他'}</th>
                  </tr>
                  <tr>
                    <th style={ addrSelectThKana } colSpan="2">
                      &nbsp;
                    </th>
                    <th style={ addrSelectThKana } colSpan="2">
                      {'読み仮名'}
                    </th>
                  </tr>
                </thead>
                {addrData.map((zip, index) => {
                  return (
                    <AddrSelectTbody
                      key={ index }
                      onClick={ () => selectAddr(index) }
                    >
                      <tr>
                        <td style={ addrSelectTd }>{zip.zipCode}</td>
                        <td style={ addrSelectTd }>{zip.prefName}</td>
                        <td style={ addrSelectTd }>{zip.cityName}</td>
                        <td style={ addrSelectTd }>{zip.address}</td>
                      </tr>

                      <tr>
                        <td style={ addrSelectTdKana } colSpan="2">
                          &nbsp;
                        </td>
                        <td style={ addrSelectTdKana } colSpan="2">
                          {`${zip.cityRuby}　${zip.addressRuby}`}
                        </td>
                      </tr>
                    </AddrSelectTbody>
                  )
                })}
              </table>
            </div>
          </div>
        )}
        {invalidPref && (
          <ValidationErrorMessage message={ '不明な都道府県名です' } />
        )}
      </div>
    )
  }
}

export default connect(PrefCode)
