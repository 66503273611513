import { connect } from 'react-redux'

/**
 * dispatch関連
 */
import { createActions as createMessengerActions } from 'src/reducers/messenger'
import { createActions as createCallActions } from 'src/reducers/call'
import { createActions as createComplaintsActions } from 'src/reducers/complaints'

import { RESET } from 'src/reducers/network-status'
import { actionCreators as $ } from 'src/reducers/wara'

/**
 map state to props
 * @param  {object} state mapping state
 * @return {object}       mapped state as props
 */
const mapStateToProps = state => {
  return {
    modal: state.wara.modal,
    master: state.master.data,
    isDebugMode: state.env.DEBUG,
    stagedDiff: state.wara.modal.stage,
    postStatus: state.networkStatus.postSticky,
    putStatus: state.networkStatus.putSticky,
    accessToken: state.login.authentication.accessToken,
    env: state.env,
    complaint: state.complaints['call-screen'] || false,
    messenger: state.messenger,
  }
}

/**
 * map dispatch to props
 * @param  {function} dispatch Dispatcher
 * @return {object}             mapped dispatcher as props
 */
const mapDispatchToProps = dispatch => ({
  // ネストしたコンポーネントにコールバックを渡す
  registerMessengers: ({ update, reset }) => {
    dispatch(createMessengerActions.set('update', update))
    dispatch(createMessengerActions.set('reset', reset))
  },

  unregisterMessengers: () => {
    dispatch(createMessengerActions.unset('update'))
    dispatch(createMessengerActions.unset('reset'))
  },

  /**
   * ネットワーク状態をリセット
   * @return {void}
   */
  resetPostStatus: () => {
    dispatch({ type: RESET, target: 'postSticky' })
  },

  /**
   * 入力内容を消す処理
   * @return {void}
   */
  removeEntries: () => dispatch($.updateStage(false)),
  clearStage: () => dispatch($.updateStage(false)),

  updateComplaint: complaint =>
    dispatch(createComplaintsActions.update('call-screen', complaint)),

  resetComplaint: () => dispatch(createComplaintsActions.remove('call-screen')),

  setStaffs: staffs => dispatch(createCallActions.set(staffs)),
})

/**
 * map state to props
 * @param  {object} stateProps    state props
 * @param  {object} dispatchProps dispatch props
 * @param  {object} ownProps      own props
 * @return {object}               props
 */
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
  }
}

export default Component =>
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component)
