import React from 'react'
import styled from 'styled-components'
import form from './'

const Input = styled.input`
  ${form};
`

export const TextInput = props => <Input { ...props } type={ 'text' } />

export default TextInput
