import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import button, { buttonFill } from 'src/styled/button'
import { baseColor } from 'src/colors'
import { qsjoin } from 'src/lib/format'

const Button = styled.button`
  ${button};
  ${buttonFill(baseColor)};
  font-size: 1em;
  width: 160px;
  margin-top: 20px;
`

// history-linked ではないが、同じところで使われるのでここに置いておく
export class Reload extends React.PureComponent {
  onClick = () => {
    this.props.onChange({ ...this.props.query, page: '1' })
    this.props.replace('?' + qsjoin({ ...this.props.query, page: '1' }))
    this.props.onClick()
  }
  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const buttonProps = {
      onClick: this.props.onClick,
      disabled: this.props.disabled,
    }

    return this.props.oneline ? (
      <span style={ { marginRight: 20 } }>
        <Button { ...buttonProps } onClick={ this.onClick }>
          <i className={ 'fa fa-search' } />
          {'検索・再読み込み'}
        </Button>
      </span>
    ) : (
      <dl
        className={ 'label-inputs-wrap label-inputs-wrap-inline' }
        style={ { display: this.props.isFlex ? 'flex' : 'block' } }
      >
        <dt>{'読み込み'}</dt>
        <dd className={ 'input-wrap' }>
          <Button { ...buttonProps } onClick={ this.onClick }>
            <i className={ 'fa fa-search' } />
            {'検索・再読み込み'}
          </Button>
        </dd>
      </dl>
    )
  }
}

Reload.propTypes = {
  query: PropTypes.object,
  replace: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isFlex: PropTypes.bool,
  oneline: PropTypes.bool,
}

Reload.defaultProps = {
  query: {},
  disabled: false,
  isFlex: false,
  oneline: true,
}

export default Reload
