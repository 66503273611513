import React from 'react'
import PropTypes from 'prop-types'
import Authorize from 'src/components/commons/authorize'
import CommonHeader from 'src/components/layouts/common-header'
import CommonFooter from 'src/components/layouts/common-footer'
import OrderListApp from 'src/components/divisions/order-list/app'
import { qsparse } from 'src/lib/format'
import config from 'src/config'
import { today } from 'src/lib/moment'

/**
 * 注文一覧コンポーネント
 * @type {ReactComponent}
 */
export class OrderListView extends React.PureComponent {
  /**
   * @type {Object}
   */
  static initialQueryState = {
    startDate: today(),
    endDate: today(),
    page: '1',
    limit: '50',
    conditionId: 10,
  }

  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    location: PropTypes.shape({ search: PropTypes.string.isRequired })
      .isRequired,
    history: PropTypes.shape({ replace: PropTypes.func.isRequired }).isRequired,
  }

  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    const initialQuery = {
      ...OrderListView.initialQueryState,
      ...qsparse(props.location.search),
    }
    this.state = { query: initialQuery }
  }

  onChange = nextQuery =>
    this.setState({ query: { ...this.state.query, ...nextQuery } })

  /**
   * render
   * @return {ReactDOM} dom
   */
  render() {
    const { query } = this.state
    const {
      history: { replace },
    } = this.props

    return (
      <div className={ 'callview__wrapper' }>
        <CommonHeader route={ 'order-list' } />

        <Authorize roles={ config.roles.orderList }>
          <div className={ 'box' }>
            <OrderListApp
              query={ query }
              replace={ replace }
              onChange={ this.onChange }
            />
          </div>
        </Authorize>

        <CommonFooter />
      </div>
    )
  }
}

export default OrderListView
