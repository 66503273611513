import React from 'react'
import PropTypes from 'prop-types'
import {
  Screen,
  ContentWrap,
  Title,
  dropZoneStyles,
  CloseButton,
  FileListItem,
  AllFileStatus,
} from './styled'
import DropZone from 'react-dropzone'
import Upload from './upload'
import ExcelUpload from './excelupload'
import ShipInventoryUpload from './shipInventoryUpload'
import { makeCancelable } from 'src/lib/cancelable-promise'
import { readFile } from './read-file'
import UploadButton from 'src/styled/button/decision'
import styled from 'styled-components'
import { today, thisWeek } from 'src/lib/moment'
import config from 'src/config'
import { WeekUpload } from 'src/components/commons/history-linked-forms/week'

export const FormsWrap = styled.div`
  align-items: center;
  width: 100%;
  margin: 20px auto;
`

export class FileUploader extends React.PureComponent {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    title: PropTypes.string.isRequired,
    upload: PropTypes.func.isRequired, // ファイル内容をアップロードする。upload(base64, [filename])
    closeMe: PropTypes.func.isRequired,
    readerType: PropTypes.oneOf(['base64', 'text', 'binary']).isRequired,
    PreButton: PropTypes.func,
    dataFilter: PropTypes.func,
    encode: PropTypes.oneOf(['shift-jis', 'utf-8']),
    dispWeek: PropTypes.bool,
    changeWeek: PropTypes.func,
    excelUpload: PropTypes.bool, // 売掛金管理→Excelアップロードのときのフラグ
    isShipInventoryPDF: PropTypes.bool, // 出庫同意書のPDFをアップロードの時のflag
    category: PropTypes.string, // 金額確認でのみ使う画像カテゴリ名
    checkCategory: PropTypes.func, // 金額確認でのみ使う画像カテゴリのチェックメソッド
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    PreButton: () => false,
    dataFilter: x => x,
    encode: 'utf-8',
    dispWeek: false,
    changeWeek: () => {},
    excelUpload: false,
    isShipInventoryPDF: false,
    category: null,
    checkCategory: null,
  }

  state = {
    status: 'not_yet', // ファイル読み込みステータス
    files: [],
    ignited: false, // アップロードが発火された
    disabled: !(
      window.File &&
      window.FileReader &&
      window.FileList &&
      window.Blob
    ),
    onUnmounts: [],
    query: {
      startDate: thisWeek(today(), config.startDayOfWeek),
    },
  }

  /**
   * componentWillUnmount
   * @return {void}
   */
  componentWillUnmount() {
    this.state.onUnmounts.forEach(x => typeof x === 'function' && x())
  }

  onDrop = files => {
    const { readerType, encode } = this.props

    const { promise, cancel } = makeCancelable(
      Promise.all(files.map(readFile(readerType, encode))),
    )

    this.setState({
      files: [],
      ignited: false,
      status: 'reading',
      onUnmounts: [...this.state.onUnmounts, cancel],
    })

    promise
      .then(files => this.setState({ status: 'success', files }))
      .catch(error => {
        if (!error.isCanceled) {
          // 予期しないエラー
          console.error(error)
          this.setState({ status: 'failure' })
        }
      })
  }

  preventClose = e => e.stopPropagation()

  fileStatusText = () => {
    const { files, status } = this.state
    const availables = files.filter(x => !x.errorMessage)

    if (status === 'not_yet') {
      return ''
    } else if (status === 'reading') {
      return 'ファイル読み込み中...'
    } else if (status === 'failure') {
      return 'ファイルの読み込みに失敗しました。再度お試しください。'
    } else if (status === 'success') {
      const total = `（${files.length}個中）`
      if (availables.length === 0) {
        return `アップロード可能なファイルはありません。 ${total}`
      } else {
        return `アップロード可能な ${availables.length} 個のファイル ${total}`
      }
    } else {
      return '不明なエラーです。'
    }
  }

  /**
   * カレンダーの日付が変わった時
   */
  onWeekChange = value => {
    const { changeWeek } = this.props
    const { startDate } = value
    changeWeek(startDate)
    this.setState({ query: { startDate } })
  }

  onUploadClick = () => this.setState({ ignited: true })

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const {
      closeMe,
      title,
      upload,
      PreButton,
      dataFilter,
      dispWeek,
      excelUpload,
      isShipInventoryPDF,
      category,
      checkCategory,
    } = this.props
    const { files, disabled, ignited } = this.state
    const availables = files.filter(x => !x.errorMessage)
    const selectedProps = {
      value: '',
      onChange: this.onWeekChange,
      query: this.state.query,
      replace: () => {},
    }
    // 金額確認の時カテゴリが不正でないかをチェックする
    const validCategory =
      checkCategory !== null ? checkCategory(category) : true

    return (
      <Screen onClick={ closeMe }>
        <ContentWrap onClick={ this.preventClose }>
          <Title>{title}</Title>

          {disabled ? (
            <p>{'このブラウザはファイルのアップロードに対応していません'}</p>
          ) : (
            <div>
              <DropZone
                disableClick
                style={ dropZoneStyles.default }
                activeStyle={ dropZoneStyles.active }
                onDrop={ this.onDrop }
              >
                <p>
                  <span style={ { fontWeight: 'bold' } }>
                    {'ファイルをドロップ'}
                  </span>
                </p>
              </DropZone>
              <div
                style={ {
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'baseline',
                } }
              >
                <input
                  type="file"
                  accept={ isShipInventoryPDF ? '.pdf' : '' }
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={ e => {
                    const files = []
                    for (let i = 0; i < e.target.files.length; i++) {
                      files.push(e.target.files[i])
                    }
                    this.onDrop(files)
                  } }
                />
                <UploadButton
                  onClick={ this.onUploadClick }
                  disabled={
                    availables.length === 0 || ignited || !validCategory
                  }
                >
                  {'アップロード実行'}
                </UploadButton>
              </div>
              {dispWeek && (
                <FormsWrap>
                  <WeekUpload
                    { ...selectedProps }
                    disabled={ disabled }
                    nocalendar
                  />
                </FormsWrap>
              )}

              <AllFileStatus>{this.fileStatusText()}</AllFileStatus>

              <ul>
                {files.map(file => (
                  <FileListItem key={ file.name }>
                    {excelUpload ? (
                      <ExcelUpload
                        upload={ upload }
                        dataFilter={ dataFilter }
                        file={ file }
                        fire={ ignited }
                      />
                    ) : isShipInventoryPDF ? (
                      <ShipInventoryUpload
                        upload={ upload }
                        dataFilter={ dataFilter }
                        file={ file }
                        fire={ ignited }
                      />
                    ) : (
                      <Upload
                        upload={ upload }
                        dataFilter={ dataFilter }
                        file={ file }
                        fire={ ignited }
                      />
                    )}
                  </FileListItem>
                ))}
              </ul>

              <PreButton files={ files } />

              <CloseButton onClick={ closeMe } />
            </div>
          )}
        </ContentWrap>
      </Screen>
    )
  }
}

export default FileUploader
