import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Authorize from 'src/components/commons/authorize'
import CommonHeader from 'src/components/layouts/common-header'
import CommonFooter from 'src/components/layouts/common-footer'
import styled from 'styled-components'
import config from 'src/config'
import { baseColor } from 'src/colors'
import {
  CardContainer,
  NavCard,
  NavUl,
  NavLi,
  // NavLink,
  LogoWrap,
} from './styled'

export const Li = styled.li`
  font-size: 1.2em;
  list-style: disc;
  margin: 0.3em;
`

/**
 * 商材管理課 コンポーネント
 * @type {ReactComponent}
 */
export class CommodityView extends React.Component {
  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate() {
    return false
  }

  /**
   * render
   * @return {ReactDOM} dom
   */
  render() {
    const {
      env: { COMMODITY_MASTER_URL },
    } = this.props

    const masterUrl = `${COMMODITY_MASTER_URL}`

    const linkStyle = {
      display: 'flex',
      cursor: 'pointer',
      alignItems: 'center',
      textDecoration: 'none',
      color: baseColor,
      fontSize: '1.2em',
      padding: '10px 30px',
      backgroundColor: 'white',
      whiteSpace: 'nowrap',
    }

    const ToLink = styled.span`
      margin-right: 4px;

      &:hover {
        font-weight: bold;
      }
    `

    // window.open()で開くように変更
    const openMaster = () => {
      window.open(masterUrl, '_blank')
    }

    return (
      <div className={ 'callview__wrapper' }>
        <CommonHeader route={ 'commodity' } />
        <Authorize roles={ config.roles.commodity }>
          <div className={ 'main-navigation' }>
            <CardContainer>
              <NavCard>
                <LogoWrap>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="68"
                    height="68"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="#ffffff"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <polyline points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3" />
                    <line x1="12" y1="12" x2="20" y2="7.5" />
                    <line x1="12" y1="12" x2="12" y2="21" />
                    <line x1="12" y1="12" x2="4" y2="7.5" />
                    <line x1="16" y1="5.25" x2="8" y2="9.75" />
                  </svg>
                </LogoWrap>
                <NavUl height={ 160 }>
                  <NavLi>
                    {' '}
                    <a
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={ openMaster }
                      style={ linkStyle }
                    >
                      <ToLink>{'マスタ管理'}</ToLink>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#1b95bf"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <rect x="7" y="3" width="14" height="14" rx="2" />
                        <path d="M17 17v2a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2" />
                      </svg>
                    </a>
                  </NavLi>
                </NavUl>
              </NavCard>
            </CardContainer>
          </div>
        </Authorize>
        <CommonFooter />
      </div>
    )
  }
}

CommodityView.propTypes = {
  // stateProps
  env: PropTypes.object.isRequired,
}

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
const mapStateToProps = state => {
  return {
    accessToken: state.login.authentication.accessToken,
    env: state.env,
  }
}

export default connect(mapStateToProps)(CommodityView)
