import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// components
import { Header, Container, FormsWrap } from '../../styled'
import { WeekFrom } from 'src/components/commons/history-linked-forms/week'
import RegionCheckList from 'src/components/commons/region-check-list'
import Button from 'src/styled/button/decision'

// libs
import { qsjoin } from 'src/lib/format'
import updateRequestHandler from '../../update-request-handler'
import { getMulti } from 'src/lib/reward-api/download'
import { makeCancelable, noop } from 'src/lib/cancelable-promise'
import { fluffyPromise as fluffy } from 'src/lib/api-utils'
import { downloadBase64Encoded } from 'src/lib/download'

// constants
import { mimes } from 'src/config'

const Loading = props =>
  !props.isHidden && <i className={ 'fa fa-spinner fa-pulse fa-fw fa-2w' } />

const errorMessageStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  rowGap: '0.75rem',
  padding: '1rem',
  border: '1px solid red',
  borderRadius: '0.5rem',
  backgroundColor: '#ffdfe2',
  textAlign: 'center',
  color: '#ff4f3d',
}

const ERROR_MESSAGE = {
  400: '選択エリアのデータで問題が起きました',
  401: '認証に失敗しました。再ログインしてお試しください',
  403: 'ダウンロードが許可されていません',
  404: '作成可能な報酬明細がありません',
  500: 'サーバーで問題がありました。時間をおいて再度お試しください',
}

export class RewardApp extends React.PureComponent {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    //  ownProps
    query: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
    // statePtops
    accessToken: PropTypes.string.isRequired,
    env: PropTypes.object.isRequired,
    regions: PropTypes.array.isRequired,
  }

  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    props.replace('?' + qsjoin(props.query))
    this.state = {
      status: 'not_yet',
      cancel: noop,
      checkedRegions: [],
      errorMessage: '',
    }
  }

  /**
   * componentDidUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {void}
   */
  componentDidUpdate = nextProps => {
    updateRequestHandler(this.props.query, nextProps.query, this.request)
  }

  /**
   * componentWillUnmount
   * @return {void}
   */
  componentWillUnmount() {
    this.state.cancel()
  }

  // エクセル取得
  request = () => {
    const { accessToken, env, query } = this.props
    const { checkedRegions } = this.state
    const { promise, cancel } = makeCancelable(
      getMulti(query.startDate, checkedRegions, accessToken, env),
    )

    this.setState({ status: 'requesting', cancel })

    fluffy(promise)
      .then(res => {
        if (res.ok) {
          return res.json()
        } else {
          const errorMessage =
            ERROR_MESSAGE[res.status] || 'ダウンロードに失敗しました'
          throw new Error(errorMessage)
        }
      })
      .then(result => {
        const filename = `報酬明細_${this.props.query.startDate}.xlsx`
        const mimeType = mimes.excel[0]
        const content = result.rewardFile || result.rewordFile

        downloadBase64Encoded(filename, mimeType, content)
        this.setState({ status: 'success' })
      })
      .catch(error => {
        if (!error.isCanceled) {
          this.setState({ status: 'failure', errorMessage: error.message })
        }
      })
  }

  /**
   * 表示エリアを設定する
   * @param {*} checkedRegions
   */
  setRegions = checkedRegions => {
    this.setState({ checkedRegions })
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { status, checkedRegions, errorMessage } = this.state
    const selectedProps = {
      value: this.props.value,
      onChange: this.props.onChange,
      query: this.props.query,
      replace: this.props.replace,
    }

    const disabled = status === 'requesting'

    return (
      <Container style={ { maxWidth: '1300px' } }>
        <Header title={ '報酬明細' } />
        <FormsWrap>
          <WeekFrom { ...selectedProps } disabled={ disabled } nolabel />
        </FormsWrap>
        <FormsWrap>
          <RegionCheckList
            label={ 'エリア:' }
            checkedRegions={ checkedRegions }
            setRegions={ this.setRegions }
          />
        </FormsWrap>

        <FormsWrap>
          <Button onClick={ this.request } disabled={ disabled }>
            <i className="fa fa-download" aria-hidden="true" />
            {'　ダウンロード'}
          </Button>
        </FormsWrap>

        <FormsWrap>
          <Loading isHidden={ status !== 'requesting' } />
          {status === 'failure' && (
            <div style={ errorMessageStyle }>
              <p>{`${errorMessage}`}</p>
            </div>
          )}
        </FormsWrap>
      </Container>
    )
  }
}

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
export const mapStateToProps = state => {
  return {
    accessToken: state.login.authentication.accessToken,
    env: state.env,
    regions: state.master.data.regions,
  }
}

export default connect(mapStateToProps)(RewardApp)
