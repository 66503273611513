// react
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// redux
import connect from '../connect'
import { NETWORK_STATUSES } from 'src/reducers/network-status'

// components
import SlideToggleButton from './partials/slide-toggle-button'

// lib
import expoNotify, { NofifyType } from 'src/lib/expo-push-api/notify'
import { now } from 'src/lib/moment'
import moment from 'moment'
import { isToday, isBeforeToday } from 'src/lib/validate'
import config from 'src/config'

const Flex = styled.div`
  display: flex;
  justify-content: space-around;
`

const workItems = [
  {
    label: '未使用',
    value: {},
  },
  {
    label: '稼働中',
    value: { workStarted: true, workFinished: false, isAssignable: true },
  },
  {
    label: '稼働終了',
    value: { workStarted: true, workFinished: true, isAssignable: false },
  },
  {
    label: '未稼働',
    value: { workStarted: false, workFinished: false, isAssignable: false },
  },
]

export class TimesAndWorkStart extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    staffId: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    updateTheDailyInfo: PropTypes.func.isRequired,
    lastFinishedTime: PropTypes.string.isRequired,
    startTime: PropTypes.string.isRequired,
    dailyInfoId: PropTypes.number.isRequired, // dailyInfoのID(最初だけ-1になる)
    ssWorkStatus: PropTypes.object.isRequired, // SSの稼働状況
    getSSworkList: PropTypes.func.isRequired, // SSの稼働状況更新
    setWorkState: PropTypes.func.isRequired, // SSの稼働状況更新
    // statePrpos
    accessToken: PropTypes.string.isRequired,
    env: PropTypes.object.isRequired,
    networkStatus: PropTypes.symbol.isRequired,
    displayDate: PropTypes.string.isRequired,
  }

  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    this.state = {
      isNetworkTrying: false,
    }
  }

  /**
   * UNSAFE_componentWillReceiveProps
   * @param  {object} nextProps React props.
   * @return {void}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.networkStatus === NETWORK_STATUSES.TRYING &&
      nextProps.networkStatus !== NETWORK_STATUSES.TRYING &&
      this.state.isNetworkTrying
    ) {
      this.setState({ isNetworkTrying: false })
    }
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps, nextState) {
    const prevStartStickies = this.props.ssWorkStatus.startStickies || []
    const nextStartStickies = nextProps.ssWorkStatus.startStickies || []
    return (
      this.props.lastFinishedTime !== nextProps.lastFinishedTime ||
      this.props.startTime !== nextProps.startTime ||
      this.props.ssWorkStatus.workState !== nextProps.ssWorkStatus.workState ||
      this.props.ssWorkStatus.startTime !== nextProps.ssWorkStatus.startTime ||
      this.props.ssWorkStatus.lastFinishedTime !==
        nextProps.ssWorkStatus.lastFinishedTime ||
      prevStartStickies.length !== nextStartStickies.length ||
      this.state.isNetworkTrying !== nextState.isNetworkTrying ||
      this.props.displayDate !== nextProps.displayDate
    )
  }

  /**
   * 新稼働通知APIの呼び出し
   * @param {*} staffId
   * @param {*} displayDate
   * @param {*} notifyType
   * @param {*} name
   */
  expoWorkNotify = (staffId, displayDate, notifyType, name) => {
    expoNotify(
      notifyType,
      staffId,
      displayDate,
      this.props.accessToken,
      this.props.env,
    )
      .then(res => {
        if (res.ok) {
          return { ok: true }
        } else {
          return res.json()
        }
      })
      .then(json => {
        if (json.ok) {
          this.props.getSSworkList(this.props.displayDate)
          this.setState({ isNetworkTrying: false })
        } else {
          console.error(json)
          const message = `${name}の通知に失敗しました。${json.message || ''}`
          console.log(message)
          // window.alert(message)
          this.setState({ isNetworkTrying: false })
        }
      })
      .catch(() => {
        console.log(`${name}の通知に失敗しました。`)
        // window.alert(`${name}の通知に失敗しました。`)
        this.setState({ isNetworkTrying: false })
      })
  }

  /**
   * 新API：稼働開始
   * @param {*} staffId
   * @param {*} displayDate
   */
  expoWorkNotifyStart = (staffId, displayDate) => {
    this.expoWorkNotify(staffId, displayDate, NofifyType.start, '稼働開始')
  }

  /**
   * 新API：稼働終了
   * @param {*} staffId
   * @param {*} displayDate
   */
  expoWorkNotifyEnd = (staffId, displayDate) => {
    this.expoWorkNotify(staffId, displayDate, NofifyType.end, '稼働終了')
  }

  /**
   * 新API：稼働キャンセル
   * @param {*} staffId
   * @param {*} displayDate
   */
  expoWorkNotifyCancel = (staffId, displayDate) => {
    this.expoWorkNotify(
      staffId,
      displayDate,
      NofifyType.cancel,
      '稼働キャンセル',
    )
  }

  /**
   * 稼働トグル操作
   * @param {*} next ◀︎:false、▶︎:true
   */
  onWorkChange = next => {
    const {
      ssWorkStatus, // SSの稼働状況
      setWorkState, // 稼働状況の設定
    } = this.props
    // SSの稼働状況が不明の時は稼働キャンセル（未稼働と同じ）とする。
    const ssWorkState = ssWorkStatus.workState || config.ssworkState.workCancel
    // dailyInfoのID: 最初に未稼働→稼働中にした時は -1が渡される。
    const id = this.props.dailyInfoId !== -1 ? this.props.dailyInfoId : null
    if (next) {
      // ▶︎をクリックした時
      if (ssWorkState === config.ssworkState.workCancel) {
        // 未稼働→稼働中
        if (window.confirm('稼働開始の指示をSSに通知しますか？')) {
          // 最初に押した時の時間を入れる
          const startTime = ssWorkStatus.startTime || now()
          const workState = workItems[config.ssworkState.workStart].value
          this.setState({ isNetworkTrying: true })
          this.props.updateTheDailyInfo(
            {
              id,
              date: this.props.date,
              startTime,
              ...workState,
            },
            true, // シリアライズフラグ
          )
          this.expoWorkNotifyStart(this.props.staffId, this.props.displayDate)
          setWorkState(config.ssworkState.workStart)
        }
      } else if (ssWorkState === config.ssworkState.workStart) {
        // 稼働中→稼働終了
        if (window.confirm('稼働終了の指示をSSに通知しますか？')) {
          // 終了時刻は毎回設定する。
          const endTime = this.endTime()
          const workState = workItems[config.ssworkState.workEnd].value
          this.setState({ isNetworkTrying: true })
          this.props.updateTheDailyInfo(
            {
              id,
              date: this.props.date,
              endTime,
              ...workState,
            },
            true, // シリアライズフラグ
          )
          this.expoWorkNotifyEnd(this.props.staffId, this.props.displayDate)
          setWorkState(config.ssworkState.workEnd)
        }
      }
    } else {
      // ◀︎をクリックした時
      if (ssWorkState === config.ssworkState.workEnd) {
        // 稼働終了→稼働開始
        if (window.confirm('稼働開始の指示をSSに通知しますか？')) {
          // 最初に押した時の時間を入れる。startTimeがあるはず?
          const startTime = ssWorkStatus.startTime || now()
          const workState = workItems[config.ssworkState.workStart].value
          this.setState({ isNetworkTrying: true })
          this.props.updateTheDailyInfo(
            {
              id,
              date: this.props.date,
              startTime,
              ...workState,
            },
            true, // シリアライズフラグ
          )
          this.expoWorkNotifyStart(this.props.staffId, this.props.displayDate)
          setWorkState(config.ssworkState.workStart)
        }
      } else if (ssWorkState === config.ssworkState.workStart) {
        // 稼働開始→稼働キャンセル
        if (window.confirm('稼働キャンセルの指示をSSに通知しますか？')) {
          const workState = workItems[config.ssworkState.workCancel].value
          this.setState({ isNetworkTrying: true })
          this.props.updateTheDailyInfo(
            {
              id,
              date: this.props.date,
              ...workState,
            },
            true, // シリアライズフラグ
          )
          this.expoWorkNotifyCancel(this.props.staffId, this.props.displayDate)
          setWorkState(config.ssworkState.workCancel)
        }
      }
    }
  }

  /**
   * 現在時刻をHH:MM形式で返す。日付が変わった時は24hを超える時刻とする。
   */
  endTime = () => {
    const date = moment(this.props.date)
    const now = moment()
    // ないとは思うが、2日以上経った時も考慮する。
    const hour = Number(now.hour() + 24 * (date.date() - now.date()))
    const minute = Number(now.minute())
    return `${hour.toString().padStart(2, '0')}:${minute
      .toString()
      .padStart(2, '0')}`
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { isNetworkTrying } = this.state

    const {
      // ownProps
      ssWorkStatus, // SSの稼働状況
      // stateProps
      displayDate,
    } = this.props

    // ssWorkStatus
    //   "1": {　// staffId
    //     "workState": 1,                             // 1:稼働開始、2:稼働終了、3:稼働キャンセル(画面は未稼働と表示する)
    //     "createAt": "2017-09-20T01:54:19.62721Z",   // アサイン課が稼働状態を変更した時刻
    //     "createUserId": "ope1",                     // アサイン課担当
    //     "notifyAt": "2017-09-20T01:54:19.62721Z",   // iPadが通知を受け取った時刻。iPadがまだ通知を受け取っていなければnull
    //     "lastFinishedTime": "21:00"                 // 前日に稼働があれば、その終了時刻。日をまたいで終了した場合、25:00の様な表記になる。
    //     "startTime": "09:00",                       // 稼働開始時刻
    //     "endTime": "17:00",                         // 稼働終了時刻
    //     "startStickies": [1,2,3],                   // 着手開始している付箋のID。このリストが空でないと稼働終了、稼働キャンセルできない
    //  }

    // 稼働状況のラベルのインデックス
    const workState = ssWorkStatus.workState || config.ssworkState.workCancel
    const selectedIndex = workState
    // 着手中の付箋がある時は◀︎ ▶︎を押せないようにする
    const hasStartStickies = (ssWorkStatus.startStickies || []).length !== 0

    // 過去日付で稼働中の時、◀︎ ▶︎を押せるようにする。
    const toggleDisabled = !(
      isToday(displayDate) ||
      (isBeforeToday(displayDate) && workState === config.ssworkState.workStart)
    )

    return (
      <div className={ 'list-time' }>
        <Flex className={ 'start-working-wrap' }>
          <SlideToggleButton
            onChange={ this.onWorkChange }
            selectedIndex={ selectedIndex }
            items={ workItems }
            disabled={ isNetworkTrying || toggleDisabled }
            displayDate={ displayDate }
            hasStartStickies={ hasStartStickies }
          />
        </Flex>
      </div>
    )
  }
}

export default connect(TimesAndWorkStart)
