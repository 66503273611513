import React from 'react'
import PropTypes from 'prop-types'

// redux
import { connect } from 'react-redux'

// lib
import dummy from './data/dummy'
import Sticky from 'src/lib/class-sticky'

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
const mapStateToProps = state => {
  return {
    isDebugMode: state.env.DEBUG,
  }
}

export class InputDummy extends React.Component {
  static propTypes = {
    // ownProps
    update: PropTypes.func.isRequired,
    // stateProps
    isDebugMode: PropTypes.bool.isRequired,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate() {
    return false
  }

  enterExample = () => this.props.update(new Sticky(dummy))

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    return (
      this.props.isDebugMode && (
        <button className={ 'button button-debug' } onClick={ this.enterExample }>
          {'新規付箋作成 デバッグ > 必須項目を適当に入力'}
        </button>
      )
    )
  }
}

export default connect(mapStateToProps)(InputDummy)
