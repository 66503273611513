// react
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// components
import TSVTable from './tsv-table'

// lib
import utils from 'src/lib/api-utils'

/**
 * map state to props
 * @param  {object} state mapping state
 * @return {object}       mapped props
 */
const mapStateToProps = state => {
  return {
    accessToken: state.login.authentication.accessToken,
    env: state.env,
  }
}

/**
 * 変更履歴表示用コンポーネント
 * @type {ReactComponent}
 */
const ChangeLogsItem = ({
  stickyId,
  item,
  label,
  evenBackGroundColor,
  accessToken,
  env,
}) => {
  const [histories, setHistories] = useState('')

  const tableTitle = {
    marginTop: 24,
    marginBottom: 12,
    color: '#424242',
    fontWeight: 'bold',
    fontSize: 20,
  }

  useEffect(() => {
    const endpoint = `${utils.createEndpoint(env, item)}?stickyId=${stickyId}`
    const fetchHistories = async () => {
      const res = await fetch(endpoint, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'text/plain',
        },
      })
      const tsv = await res.text()
      setHistories(tsv)
    }
    fetchHistories()
  }, [])

  return (
    <div>
      <dl>
        <dt style={ tableTitle }>{label}</dt>
        <dd>
          <TSVTable tsv={ histories } evenBackGroundColor={ evenBackGroundColor } />
        </dd>
      </dl>
    </div>
  )
}

/**
 * propTypes
 * @type {object}
 */
ChangeLogsItem.propTypes = {
  // ownProps
  stickyId: PropTypes.number.isRequired,
  item: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  evenBackGroundColor: PropTypes.string,
  // stateProps
  accessToken: PropTypes.string.isRequired,
  env: PropTypes.object.isRequired,
}

/**
 * defaultProps
 * @type {object}
 */
ChangeLogsItem.defaultProps = {
  evenBackGroundColor: '',
}

export default connect(mapStateToProps)(React.memo(ChangeLogsItem))
