import { connect as _connect } from 'react-redux'

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
const mapStateToProps = state => {
  return {
    regions: state.wara.data.regions,
    userPreset: state.userPreset.data.regions,
    regionsMaster: state.master.data.regions,
    isDebugMode: state.env.DEBUG,
  }
}
/**
 * map dispatch to props
 * @param  {function} dispatch dispatcher
 * @param  {object}   ownProps own props
 * @return {object}            dispatch props
 */
const mapDispatchToProps = () => {
  return {}
}

export const connect = Component =>
  _connect(mapStateToProps, mapDispatchToProps)(Component)
