// lib
import { createExpoEndpoint } from 'src/lib/api-utils'
import { qsjoin } from 'src/lib/format'

/**
 * 画像削除のAPIを叩く
 * @return {{promise:Promise, cancel:Function}} promise to access API
 */
export const post = (imageId, accessToken, env) => {
  const endpoint = createExpoEndpoint(env, 'delete')

  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: qsjoin({ id: imageId }),
  }

  const request = fetch(endpoint, options)

  return request
}

export default post
