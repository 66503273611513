import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import AutocompleteSs from 'src/components/commons/history-linked-forms/autocomplete-ss'
import { Box, Typography } from '@mui/material'

/**
 * 代理回収者
 * @type {ReactComponent}
 */
export class CollectedStaffId extends React.PureComponent {
  /**
   * Validation
   * @type {object}
   */
  static propTypes = {
    // ownProps
    value: PropTypes.number.isRequired,
    route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
    disabled: PropTypes.bool.isRequired,
    update: PropTypes.func.isRequired,
    // state Props
    callStaffs: PropTypes.arrayOf(
      PropTypes.shape({
        staffId: PropTypes.number.isRequired,
        staffName: PropTypes.string.isRequired,
        regionId: PropTypes.number.isRequired,
      }),
    ).isRequired,
  }

  /**
   * 代理回収者の変更のハンドラ
   * @param  {Event} e event
   * @return {void}
   */
  onChange = e => {
    const val = parseInt(e.staffId, 10)
    const staffId = isNaN(val) ? -1 : val
    this.props.update(staffId)
  }

  /**
   * render
   * @return {ReactDOM} rendered result
   */
  render() {
    const { value, route, disabled: forceDisabled, callStaffs } = this.props

    const disabled = forceDisabled || route !== 'assign'

    const currentSsRegion =
      (callStaffs.find(staff => staff.staffId === value) || {}).regionId || -1

    return (
      <Box
        sx={ {
          display: 'grid',
          gridTemplateColumns: 'repeat(2, max-content)',
          columnGap: '1.5rem',
        } }
      >
        <Typography
          id={ `cash-select-payment-payment-times-${route}` }
          variant="subtitle1"
          sx={ { fontWeight: 'bold' } }
        >
          {'代理回収者'}
        </Typography>
        <AutocompleteSs
          staffId={ value }
          onChange={ this.onChange }
          query={ { staffId: '' } }
          replace={ () => {} }
          disabled={ disabled }
          small
          showEndStaff
          esStaffs
          hlStaffs
          qiStaffs={ false }
          showRegion
          ssRegion={ currentSsRegion }
          inputName={ 'maruc-current-staff-input' }
        />
      </Box>
    )
  }
}

const mapStateToProps = state => {
  return {
    callStaffs: state.master.data.staffs,
  }
}

export default connect(mapStateToProps)(CollectedStaffId)
