import switz from 'switz'
import update from 'immutability-helper'

const OPEN = 'LIGHTBOX.OPEN'
const CLOSE = 'LIGHTBOX.CLOSE'

export const initialState = {
  isOpen: false,
  stickyId: void 0,
  orderId: '',
  position: 'FULL',
  apitype: '',
}

export const ACTION_TYPES = {
  OPEN,
  CLOSE,
}

export const actionCreators = {
  /**
   * 写真画面を表示する。
   * stickyId: 付箋のID
   * orderId: 注文ID
   * position: 写真画面の表示位置('FULL': 全画面、'RIGHT': 右寄せ)
   * apitype: 使用するAPIの種類('ORG': 旧API, 'EXPO': 新API)
   */
  open: (stickyId, orderId, position = 'FULL', apitype = 'EXPO') => ({
    type: ACTION_TYPES.OPEN,
    payload: { stickyId, orderId, position, apitype },
  }),

  close: () => ({
    type: ACTION_TYPES.CLOSE,
    payload: {},
  }),
}

export const partialReducer = (state = initialState, action) => {
  const { type, payload } = action
  return switz(type, s =>
    s
      .case(OPEN, () => {
        const { stickyId, orderId, position, apitype } = payload
        return update(state, {
          isOpen: { $set: true },
          stickyId: { $set: stickyId },
          orderId: { $set: orderId },
          position: { $set: position },
          apitype: { $set: apitype },
        })
      })
      .case(CLOSE, () => {
        return update(state, {
          isOpen: { $set: false },
          stickyId: { $set: void 0 },
          orderId: { $set: '' },
        })
      })
      .default(() => state),
  )
}

export default partialReducer
