import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const SortButtonStyle = styled.button`
  border: 1px solid white;
  border-radius: 4px;
  background-color: transparent;
  color: white;
  transition-duration: 0.2s;
  margin-bottom: 0.5rem;

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

export const I = styled.i`
  margin-right: 0.5em;
`

export const SortButton = props => (
  <SortButtonStyle { ...props }>
    <I className={ 'fa fa-sort' } />
    {props.children}
  </SortButtonStyle>
)

SortButton.propTypes = {
  children: PropTypes.string.isRequired,
}

export default SortButton
