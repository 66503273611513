import List from './'
import { connect } from 'react-redux'
import { actionCreators as $ } from 'src/reducers/wara'
// import utils from 'src/lib/api-utils'

/**
 map state to props
 * @param  {object} state redux state
 * @param  {object} ownProps own Props
 * @return {object}       merge with props
 */
const mapStateToProps = state => {
  return {
    wara: state.wara.data,
    isDebugMode: state.env.DEBUG,
  }
}

/**
 * map dispatch to props
 * @param  {function} dispatch dispatcher
 * @param  {object}    props    props
 * @return {object}            mapped props
 */
const mapDispatchToProps = (dispatch, props) => {
  return {
    updateTheDailyInfo: (ssProps, serialize = true) =>
      dispatch($.updateDailyInfo(props.location, ssProps, serialize)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(List)
