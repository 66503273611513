import React from 'react'
import PropTypes from 'prop-types'

// libs
import noop from 'src/lib/noop'

export class NumberInput extends React.Component {
  /**
   * validate number
   * @param  {any}     value given value
   * @return {boolean}       validation result
   */
  static isValid = value => {
    return (
      value === '' ||
      value === null ||
      /[０１２３４５６７８９0123456789,、，]+$/.test(value.toString())
    )
  }

  static normalize = value =>
    parseInt(
      (value || '0')
        .replace(/[０１２３４５６７８９]/g, s =>
          String.fromCharCode(s.charCodeAt(0) - 65248),
        )
        .replace(/[,、，]/g, '')
        .trim(),
      10,
    )

  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.number,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    isValid: PropTypes.bool,
    id: PropTypes.string,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    className: '',
    value: 0,
    disabled: false,
    onChange: noop,
    isValid: true,
    id: 'numberinput',
  }

  state = { editing: false }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.value !== nextProps.value ||
      this.state.editing !== nextState.editing ||
      this.props.disabled !== nextProps.disabled
    )
  }

  focus = () => this.setState({ editing: this.props.value || '' })
  change = e => this.setState({ editing: e.target.value })
  blur = e => {
    const { value } = e.target
    if (NumberInput.isValid(value)) {
      this.props.onChange(NumberInput.normalize(value))
    }
    this.setState({ editing: false })
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { editing } = this.state
    const { className, value, disabled, isValid: externalValidity } = this.props

    const displayValue = editing === false ? value || '' : editing
    const isValid = NumberInput.isValid(displayValue) && externalValidity

    const inputProps = {
      id: this.props.id,
      className: this.props.className,
      value: this.props.value,
      disabled: this.props.disabled,
      onChange: this.props.onChange,
    }

    return (
      <input
        { ...inputProps }
        className={
          className + ' ' + (isValid ? 'input-valid' : 'input-invalid')
        }
        style={ { width: '90px', lineHeight: '1.7em', margin: '0 6px 0 2px' } }
        value={ displayValue }
        type={ 'text' }
        onFocus={ this.focus }
        onChange={ this.change }
        onBlur={ this.blur }
        disabled={ disabled }
      />
    )
  }
}

export default NumberInput
