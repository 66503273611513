import styled from 'styled-components'
import { borderDarkGray } from 'src/colors'

export const Dl = styled.dl`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0.5em;
`

export const Dt = styled.dt`
  font-weight: bold;
  width: 180px;
  margin: 0.5em;
`

export const Dd = styled.dd`
  margin: 0.5em;
  border-radius: 4px;
  ${props => (props.strech ? 'flex: 1' : '')};
`

export const Hr = styled.hr`
  color: ${borderDarkGray};
  margin: 30px 0;
`
