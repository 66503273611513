import utils from 'src/lib/api-utils'

/**
 * SSの週次売上取得API
 * @param {*} displayDate
 * @param {*} accessToken
 * @param {*} env
 */
export const requestGetSSWeeklySales = (displayDate, accessToken, env) => {
  const endpoint = `${utils.createEndpoint(
    env,
    'staffWeeklySales',
  )}/${displayDate}`

  return fetch(endpoint, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  }).then(res => {
    if (res.ok) {
      return res.json()
    } else {
      throw 'GETエラー'
    }
  })
}

export default requestGetSSWeeklySales
