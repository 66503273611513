// API のバージョンを取得する
import utils from 'src/lib/api-utils'

/**
 * 未回収売掛金のダウンロード
 * @param  {number} accountId sticky contact ID
 * @param  {string} accessToken     アクセストークン
 * @param  {object} env             環境情報
 * @return {Promise}                fetch
 */
export const get = (accessToken, env) => {
  const url = utils.createEndpoint(env, 'version')

  const request = fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then(res => (res.ok ? res.json() : 'unknown'))

  return request
}

export default get
