// react
import React from 'react'
import PropTypes from 'prop-types'

// components
import ChangeLogsItem from './change-logs-item'

/**
 * 変更履歴表示用コンポーネント
 * @type {ReactComponent}
 */
const ChangeLogsView = ({
  stickyId,
  orderId,
  evenBackGroundColor,
  onCloseClick,
}) => {
  const modalTitle = {
    marginBottom: 12,
    fontWeight: 'bold',
    fontSize: 24,
  }
  const modalSubtitle = {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 12,
    marginBottom: 12,
  }

  return (
    <div>
      <h1 style={ modalTitle }>{'変更履歴'}</h1>
      <div>
        <dl style={ modalSubtitle }>
          <dt style={ { fontWeight: 'bold' } }>{'注文Id'}</dt>
          <dd>{orderId}</dd>
        </dl>
      </div>
      <ChangeLogsItem
        stickyId={ stickyId }
        orderId={ orderId }
        label={ '基本情報' }
        item={ 'stickyHistories' }
        evenBackGroundColor={ evenBackGroundColor }
      />
      <ChangeLogsItem
        stickyId={ stickyId }
        orderId={ orderId }
        label={ '顧客情報' }
        item={ 'contactHistories' }
        evenBackGroundColor={ evenBackGroundColor }
      />
      <ChangeLogsItem
        stickyId={ stickyId }
        orderId={ orderId }
        label={ '対応希望日時' }
        item={ 'wishDateTimeHistories' }
        evenBackGroundColor={ evenBackGroundColor }
      />
      <ChangeLogsItem
        stickyId={ stickyId }
        orderId={ orderId }
        label={ '交渉履歴' }
        item={ 'negotiationHistories' }
        evenBackGroundColor={ evenBackGroundColor }
      />
      <ChangeLogsItem
        stickyId={ stickyId }
        orderId={ orderId }
        label={ '現金' }
        item={ 'paymentHistories' }
        evenBackGroundColor={ evenBackGroundColor }
      />
      <ChangeLogsItem
        stickyId={ stickyId }
        orderId={ orderId }
        label={ '売掛金' }
        item={ 'accountHistories' }
        evenBackGroundColor={ evenBackGroundColor }
      />
      {/* <ChangeLogsItem
        stickyId={ stickyId }
        orderId={ orderId }
        label={ 'ハウスラボ付箋履歴' }
        item={ 'stickyHouseLaboHistories' }
        evenBackGroundColor={ evenBackGroundColor }
      />
      <ChangeLogsItem
        stickyId={ stickyId }
        orderId={ orderId }
        label={ 'ハウスラボクライアント付箋履歴' }
        item={ 'stickyHouseLaboClientHistories' }
        evenBackGroundColor={ evenBackGroundColor }
      />
      <ChangeLogsItem
        stickyId={ stickyId }
        orderId={ orderId }
        label={ 'ハウスラボ作業内容履歴' }
        item={ 'stickyHouseLaboWorkHistories' }
        evenBackGroundColor={ evenBackGroundColor }
      />
      <ChangeLogsItem
        stickyId={ stickyId }
        orderId={ orderId }
        label={ 'ハウスラボ費用履歴' }
        item={ 'stickyHouseLaboFeeHistories' }
        evenBackGroundColor={ evenBackGroundColor }
      />
      <ChangeLogsItem
        stickyId={ stickyId }
        orderId={ orderId }
        label={ 'ハウスラボその他費用履歴' }
        item={ 'stickyHouseLaboExtraFeeHistories' }
        evenBackGroundColor={ evenBackGroundColor }
      /> */}
      <button
        className={ 'button button-fixed close-mark' }
        onClick={ onCloseClick }
      />
    </div>
  )
}

/**
 * propTypes
 * @type {object}
 */
ChangeLogsView.propTypes = {
  // ownProps
  stickyId: PropTypes.number.isRequired,
  orderId: PropTypes.string.isRequired,
  evenBackGroundColor: PropTypes.string,
  onCloseClick: PropTypes.func.isRequired,
}

/**
 * defaultProps
 * @type {object}
 */
ChangeLogsView.defaultProps = {
  evenBackGroundColor: '',
}

export default React.memo(ChangeLogsView)
