/**
 * 変更履歴のreducer
 * @file
 */

import { CALL_API } from 'redux-api-middleware'
import { NETWORKER } from 'src/middlewares/network-monitor'

// Using redux-api-middleware
// https://www.npmjs.com/package/redux-api-middleware

// type RSAA: Redux Standard API-calling Actions
// https://www.npmjs.com/package/redux-api-middleware#redux-standard-api-calling-actions

import switz from 'switz'
import update from 'immutability-helper'
import utils from 'src/lib/api-utils'
import { qsjoin } from 'src/lib/format'
import { ACTION_TYPES as NETWORK_ACTION_TYPES } from 'src/reducers/network-status'

/**
 * initial state
 * @type {object}
 */
export const initialHistoriesState = {
  data: '',
}

/**
 * Action Types
 * @type {string}
 */
export const HISTORIES_GET_REQUEST = 'HISTORIES.HISTORIES_GET_REQUEST'
export const HISTORIES_GET_SUCCESS = 'HISTORIES.HISTORIES_GET_SUCCESS'
export const HISTORIES_GET_FAILURE = 'HISTORIES.HISTORIES_GET_FAILURE'
export const REMOVE_RESULT = 'HISTORIES.REMOVE_RESULT'

/**
 * action bulk exports
 * @type {object}
 */
export const ACTION_TYPES = {
  HISTORIES_GET_REQUEST,
  HISTORIES_GET_SUCCESS,
  HISTORIES_GET_FAILURE,
  REMOVE_RESULT,
}

/**
 * 検索履歴を取得する
 * @param  {string} orderId     orderId
 * @param  {string} accessToken access token
 * @return {object} redux-api-middleware に渡す非同期処理情報を内包したアクション
 */
export const createAsyncGetHistoriesAction = (orderId, accessToken, env) => {
  const endpoint = `${utils.createEndpoint(env, 'histories')}?${qsjoin({
    orderId,
  })}`

  !__TEST__ &&
    // eslint-disable-next-line no-console
    console.log(endpoint)

  return {
    [CALL_API]: {
      endpoint,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      types: [
        {
          type: HISTORIES_GET_REQUEST,
          meta: {
            [NETWORKER]: {
              target: 'getHistories',
              networkActionType: NETWORK_ACTION_TYPES.REQUEST,
            },
          },
        },
        {
          type: HISTORIES_GET_SUCCESS,
          meta: {
            [NETWORKER]: {
              target: 'getHistories',
              networkActionType: NETWORK_ACTION_TYPES.SUCCESS,
            },
          },
          payload: (action, state, res) =>
            res.text().then(tsv => ({ result: tsv })),
        },
        {
          type: HISTORIES_GET_FAILURE,
          meta: {
            [NETWORKER]: {
              target: 'getHistories',
              networkActionType: NETWORK_ACTION_TYPES.FAILURE,
            },
          },
          payload: (action, state, res) =>
            res.text().then(tsv => ({ result: tsv })),
        },
      ],
    },
  }
}

/**
 * search reducer
 * @param  {object} [state=initialHistoriesState] previous state
 * @param  {object} action                    dispatched action
 * @return {object}                            next state
 */
export default (state = initialHistoriesState, action) => {
  const { type, payload } = action
  return switz(type, s =>
    s
      // reducer for search updating
      .case(HISTORIES_GET_REQUEST, () => state)
      .case(HISTORIES_GET_SUCCESS, () => {
        const tsv = payload.result || ''
        return update(state, {
          data: { $set: tsv },
        })
      })
      .case(HISTORIES_GET_FAILURE, () => state)
      .case(REMOVE_RESULT, () =>
        update(state, {
          data: { $set: '' },
        }),
      )
      .default(() => state),
  )
}
