import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { baseColor, white, borderDarkGray, red } from 'src/colors'

export const Screen = styled.div`
  background-color: ${white};
  padding: 20px;
`

export const SingleTable = props => {
  const { names, values } = props.params.reduce(
    (prev, { name, value }) => ({
      names: [...prev.names, name],
      values: [...prev.values, value],
    }),
    { names: [], values: [] },
  )

  return (
    <Table>
      <TableHead>
        <tr>
          {names.map(name => (
            <Th key={ name }>{name}</Th>
          ))}
        </tr>
      </TableHead>
      <tbody>
        <tr>
          {values.map((value, index) => (
            <Td key={ names[index] }>{value}</Td>
          ))}
        </tr>
      </tbody>
    </Table>
  )
}

SingleTable.propTypes = {
  params: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

const PluralTableTr = styled.tr`
  border-bottom: 1px solid ${borderDarkGray};
`

export const PluralTable = props => {
  const {
    tableSpec: { keyOrders, headers, transform },
    items,
    keyName,
  } = props
  return (
    <Table plural>
      <TableHead>
        <tr>
          {keyOrders.map(key => (
            <Th key={ key }>{headers[key]}</Th>
          ))}
        </tr>
      </TableHead>

      <tbody>
        {items.map(item => (
          <PluralTableTr key={ item[keyName] }>
            {keyOrders.map(key => (
              <Td key={ key }>
                {transform[key] ? transform[key](item[key]) : item[key]}
              </Td>
            ))}
          </PluralTableTr>
        ))}
      </tbody>
    </Table>
  )
}

PluralTable.propTypes = {
  tableSpec: PropTypes.shape({
    keyOrders: PropTypes.arrayOf(PropTypes.string).isRequired,
    headers: PropTypes.objectOf(PropTypes.string).isRequired,
    transform: PropTypes.objectOf(PropTypes.func).isRequired,
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  keyName: PropTypes.string,
}

PluralTable.defaultProps = {
  keyName: 'id',
}

const Table = styled.table`
  max-width: calc(100% - 30px);
  ${props => (props.plural ? 'width : 100%' : '')};
  margin-left: 30px;
`

const Th = styled.th`
  color: ${baseColor};
  padding: 3px 0 2px;
  text-align: left;
`

const Td = styled.td`
  padding: 3px 0 2px;
`

const TableHead = styled.thead`
  border-bottom: 1px solid ${borderDarkGray};
`

export const Notice = styled.span`
  color: ${red};
`

const IterateWrap = styled.dl`
  display: flex;
  flex-direction: row;
`

const IterateLabel = styled.dt`
  color: ${baseColor};
  width: 120px;
`

const IterateItem = styled.dd`
  flex: 1;
`

export const Iterate = props => (
  <IterateWrap>
    <IterateLabel>{props.label}</IterateLabel>
    <IterateItem>{props.items.join(', ')}</IterateItem>
  </IterateWrap>
)

Iterate.propTypes = {
  label: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const VerticalTableTr = styled.tr`
  border-bottom: 1px solid ${borderDarkGray};
`

const VerticalTableTh = styled.th`
  color: ${baseColor};
  padding: 3px 10px 2px;
  text-align: left;
  width: 1px;
  white-space: nowrap;
`

const VerticalTableTd = styled.td`
  padding: 3px 10px 2px;
  text-align: right;
`

export const VerticalTable = props => {
  return (
    <Table>
      <tbody>
        {props.items.map(({ name, value, key }) => (
          <VerticalTableTr key={ key }>
            <VerticalTableTh>{name}</VerticalTableTh>
            <VerticalTableTd>{value}</VerticalTableTd>
          </VerticalTableTr>
        ))}
      </tbody>
    </Table>
  )
}

VerticalTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }),
  ).isRequired,
}
