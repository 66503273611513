import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import connect from './connect'
import linkHistoryHOC from './hocs/link-history'
import { Box, FormControl, ListItemIcon, MenuItem, Select } from '@mui/material'
import theme from 'src/theme.js'
import { ThemeProvider } from '@mui/material/styles'
import { Check } from '@mui/icons-material'

const menuItemStyle = {
  columnGap: '0.5rem',
  display: 'grid',
  gridTemplateColumns: '1rem max-content',
  lineHeight: '1rem',
}

export const SelectPrefCode = ({
  value,
  onChange,
  disabled,
  regionId,
  master,
  isFlex,
}) => {
  const items = useMemo(() => {
    const regionIds = regionId.split(',').map(id => id.trim())
    return regionId === '-1' || regionId === ''
      ? master.prefCodes
      : master.prefCodes.filter(prefCode =>
        regionIds.includes(prefCode.regionId.toString()),
      )
  }, [regionId])

  useEffect(() => {
    // 選択されている都道府県のregionIdがエリアのregionIdと異なる場合、未選択にリセット
    if (value !== '-1' && !items.find(item => item.code === value)) {
      onChange({ target: { value: '-1' } }) // 未選択状態にリセット
    }
  }, [value, items, onChange])

  const renderValue = selected => {
    if (selected === '-1') {
      return '未選択'
    }
    const selectedItem = items.find(item => item.code === selected)
    return selectedItem ? selectedItem.name : '未選択'
  }

  return (
    <ThemeProvider theme={ theme }>
      <Box>
        <label style={ { fontWeight: 'bold' } }>{'都道府県'}</label>
        <FormControl
          color="primary"
          fullWidth
          sx={ {
            display: isFlex ? 'flex' : 'block',
            background: '#fff',
            '.MuiOutlinedInput-root': {
              height: '28px',
              alignItems: 'center',
              '.MuiSelect-select': {
                padding: '0px 14px',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0, 0, 0, 0.23)',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'primary.main',
              },
            },
          } }
          variant="outlined"
        >
          <Select
            disabled={ disabled }
            displayEmpty
            onChange={ onChange }
            MenuProps={ {
              anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
              transformOrigin: { vertical: 'top', horizontal: 'left' },
              PaperProps: { style: { maxHeight: '50vh' } },
            } }
            renderValue={ renderValue }
            sx={ { width: '7rem' } }
            value={ value }
          >
            <MenuItem value="-1" sx={ menuItemStyle }>
              <ListItemIcon>
                {value === '-1' && (
                  <Check color="primary" style={ { fontSize: '0.875rem' } } />
                )}
              </ListItemIcon>
              {'未選択'}
            </MenuItem>
            {items.map(pref => (
              <MenuItem
                color="primary"
                key={ pref.code }
                sx={ menuItemStyle }
                value={ pref.code }
              >
                <ListItemIcon>
                  {value === pref.code.toString() && (
                    <Check color="primary" style={ { fontSize: '0.875rem' } } />
                  )}
                </ListItemIcon>
                {pref.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </ThemeProvider>
  )
}

/**
 * propTypes
 * @type {object}
 */
SelectPrefCode.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  regionId: PropTypes.number,

  // stateProps
  master: PropTypes.shape({
    prefCodes: PropTypes.array.isRequired,
  }).isRequired,

  // よく分からないが、注文一覧のビューでは flex を取ってしまうと崩れるし、それ以外ではflexを取る必要がある
  isFlex: PropTypes.bool,
}

/**
 * defaultProps
 * @type {object}
 */
SelectPrefCode.defaultProps = {
  isFlex: false,
  disabled: false,
  regionId: -1,
}

export default linkHistoryHOC(connect(React.memo(SelectPrefCode)), 'prefCode')
