import DELETE_STICKY_ACTION from 'src/reducers/wara/actions/delete-sticky'
import { createAsyncDeleteStickyAction } from 'src/reducers/wara/async'
import { findStickyFromWara } from 'src/lib/search'

/**
 *  付箋の削除 -> シリアライズのチェーン
 *  action.meta.onlyInUI == true を渡すと、リクエストを出さないで、処理をUIで完結する
 *  NOTE:/src/components/divisions/assign/sticky/partials/buttons.jsx:106 で完結するべき
 *  そっちに写したい
 * @param  {object} store  redux store
 * @param  {object} action redux action
 * @return {void}
 */
export const preDeleteStickyHook = (store, action) => {
  const state = store.getState()
  const { location } = action.payload
  const { onlyInUI } = action.meta || {}
  // NOTE onlyInUI == true の時は付箋の削除が走るので注意
  const sticky = findStickyFromWara(state.wara.data, location)
  if (!sticky || onlyInUI) {
    return
  }

  const { id } = sticky
  const {
    login: {
      authentication: { accessToken },
    },
    env,
  } = state

  store.dispatch(createAsyncDeleteStickyAction(id, accessToken, env))
}

export default {
  [DELETE_STICKY_ACTION.type]: preDeleteStickyHook,
}
