import React from 'react'
import PropTypes from 'prop-types'
import linkHistoryHOC from './hocs/link-history'
import TextInput from 'src/styled/form/text-input'

export const HlClientName = props => {
  const { value, onChange, isFlex, disabled } = props

  return (
    <dl
      className={ 'label-inputs-wrap label-inputs-wrap-inline' }
      style={ { display: isFlex ? 'flex' : 'block' } }
    >
      <dt className={ 'input-headline' }>{'クライアント名'}</dt>
      <dd>
        <TextInput
          type={ 'text' }
          value={ value }
          onChange={ onChange }
          disabled={ disabled }
          placeholder={ 'カナを入力' }
        />
      </dd>
    </dl>
  )
}

/**
 * propTypes
 * @type {object}
 */
HlClientName.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isFlex: PropTypes.bool,
}

HlClientName.defaultProps = {
  disabled: false,
  isFlex: false,
}

export default linkHistoryHOC(HlClientName, 'hlClientName', '')
