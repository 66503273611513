import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { createAsyncLoginAction } from 'src/reducers/login'
import { ACTION_TYPES as LOGIN_ACTION_TYPES } from 'src/reducers/login'
import { NETWORK_STATUSES } from 'src/reducers/network-status'

import { FormLine, Label, Input, WarningLine } from './styled'

/**
 map state to props
 * @param  {object} state mapping state
 * @return {object}       mapped state as props
 */
export const mapStateToProps = state => {
  return {
    userid: state.login.authentication.userid,
    password: state.login.authentication.password,
    loginStatus: state.networkStatus.login,
    __env: state.env,
  }
}

/**
 * map dispatch to props
 * @param  {function} dispatch dispatcher
 * @return {object}               mapped dispatcher as props
 */
export const mapDispatchToProps = dispatch => {
  return {
    /**
     * ログインする
     * @param  {string} userid   user id
     * @param  {string} password password
     * @return {void}
     */
    __login: env => (userid, password) =>
      dispatch(createAsyncLoginAction(userid, password, env)),

    /**
     * パスワードをアップデートする
     * @param {string} password password
     * @return {void}
     */
    setPassword: password =>
      dispatch({
        type: LOGIN_ACTION_TYPES.SET_PASSWORD,
        payload: { password },
      }),
  }
}

/**
 * map state to props
 * @param  {object} stateProps    state props
 * @param  {object} dispatchProps dispatch props
 * @param  {object} ownProps      own props
 * @return {object}               props
 */
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    // merge
    login: dispatchProps.__login(stateProps.__env),
    // make private
    __env: void 0,
    __login: void 0,
  }
}

/**
 * パスワードの入力を行うコンポーネント
 * @param {object} props props
 * @return {ReactComponent}
 */
export class Password extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // stateProps
    userid: PropTypes.string,
    password: PropTypes.string,
    loginStatus: PropTypes.oneOf(Object.values(NETWORK_STATUSES)),
    // dispatchProps
    setPassword: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    userid: '',
    password: '',
    loginStatus: NETWORK_STATUSES.NOT_YET,
  }

  state = { isCapsLocked: false } // 最初はCapsLockが押されているかは未知なので、疑わしきは罰せず

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.password !== nextProps.password ||
      this.props.loginStatus !== nextProps.loginStatus ||
      !!(this.state.isCapsLocked !== nextState.isCapsLocked)
    )
  }

  /**
   * パスワードフォーム変更のハンドラ
   * @param  {Event} e event
   * @return {void}
   */
  onPasswordChange = e => {
    if (e.target.value === '') {
      // 警告を消す
      this.setState({ isCapsLocked: false })
    }
    this.props.setPassword(e.target.value)
  }

  /**
   * エンターキーを押してログインをするためのKeyDownハンドラ
   * @param  {Event} e event
   * @return {void}
   */
  onEnterKeyDownToLogin = e =>
    this.props.userid !== '' &&
    this.props.password !== '' &&
    e.keyCode === 13 &&
    this.props.login(this.props.userid, this.props.password)

  /**
   * `キーダウンにフックしてCapsLockを検知する
   * @param  {Event} e evnet
   * @return {void}
   */
  onKeyPressToDetectCapsLock = e => {
    const char = String.fromCharCode(e.keyCode || e.which)

    if (char.toUpperCase() === char.toLowerCase()) {
      // アルファベット以外が入力されているので、CapsLock押下を感知しようがないよね
      return
    }

    // アルファベットが入力された時。シフト入力も考慮
    if (
      (e.shiftKey && char.toLowerCase() === char) ||
      (!e.shiftKey && char.toUpperCase() === char)
    ) {
      this.setState({ isCapsLocked: true })
    } else {
      this.setState({ isCapsLocked: false })
    }
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { password, loginStatus } = this.props

    const { isCapsLocked } = this.state

    const disabled = loginStatus === NETWORK_STATUSES.TRYING

    return (
      <FormLine>
        <Label htmlFor={ 'password' }>{'パスワード'}</Label>
        <Input
          autoComplete={ 'off' }
          value={ password }
          id={ 'password' }
          name={ 'password' }
          type={ 'password' }
          onChange={ this.onPasswordChange }
          onKeyDown={ this.onEnterKeyDownToLogin }
          onKeyPress={ this.onKeyPressToDetectCapsLock }
          disabled={ disabled }
        />
        <WarningLine isVisible={ isCapsLocked }>
          {'CapsLockキーが押されています'}
        </WarningLine>
      </FormLine>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Password)
