import { Link } from 'react-router-dom'
import { baseColor } from 'src/colors'

import styled from 'styled-components'

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  max-width: 1200px;
  margin: 30px auto;
`

export const NavCard = styled.div`
  display: block;
  width: ${props => (props.width ? props.width : 180)}px;
  transition: 0.2s;
  border-radius: 8px;
  background: #fff;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 3px;
`

export const LogoWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  border-radius: 8px 8px 0 0;
  background: ${baseColor};
  color: #fff;
`

export const NavUl = styled.ul`
  height: ${props => (props.height ? props.height : 200)}px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 10px;
`

export const NavLi = styled.li`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const NavLink = styled(Link)`
  color: ${baseColor};
  font-size: 1.25rem;
  text-decoration: none;
  text-align: center;
  transition: 0.2s;

  :hover {
    color: darken(${baseColor}, 10%);
  }
`
