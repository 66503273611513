import React from 'react'
import PropTypes from 'prop-types'
import connect from './connect'
import Sticky from 'src/lib/class-sticky'
import Checkbox from 'src/styled/form/checkbox'

/**
 * マルシー代行かどうか
 * reRequestプロパティを使う
 * @type {ReactComponent}
 */
export class IsAlternative extends React.Component {
  /**
   * Validation
   * @type {object}
   */
  static propTypes = {
    // ownProps
    staffName: PropTypes.string,
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
    disabled: PropTypes.bool.isRequired,
    // dispatchProps
    updateStage: PropTypes.func.isRequired,
  }

  /**
   * Defaults
   * @type {object}
   */
  static defaultProps = {
    staffName: '-',
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return (
      this.props.sticky.json().reRequest !==
        nextProps.sticky.json().reRequest ||
      this.props.disabled !== nextProps.disabled
    )
  }

  /**
   * onChange handler
   * @param  {Event} e event
   * @return {void}
   */
  onChange = e => {
    const { updateStage } = this.props
    const diffProps = {
      reRequest: e.target.checked ? 'マルシー代行' : '',
    }
    updateStage(new Sticky(diffProps))
  }

  /**
   * render
   * @return {ReactDOM} rendered result
   */
  render() {
    const {
      // ownProps
      staffName,
      sticky,
      route,
      disabled,
    } = this.props

    const reRequest = sticky.json().reRequest

    const checked = reRequest !== ''

    return (
      <div>
        <dl className={ 'label-inputs-wrap' } id={ `is-alternative-${route}` }>
          <dt>
            <label>{'担当SS'}</label>
          </dt>
          <dd className={ 'input-wrap' }>{staffName}</dd>
          <dd className={ 'input-wrap' }>
            <Checkbox
              checked={ checked }
              onChange={ this.onChange }
              disabled={ disabled }
              labelText={ 'マルシー代行' }
            />
          </dd>
        </dl>
      </div>
    )
  }
}

export default connect(IsAlternative)
