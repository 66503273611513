import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { create as createEnvAction } from 'src/reducers/env'

const ID = 'input-step-number'

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
const mapStateTopProps = state => {
  return {
    stepNumber: state.env.ES_STEP,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateStepNumber: value =>
      dispatch(createEnvAction.update('ES_STEP', value)),
  }
}

export class DebugStepNumber extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // stateProps
    stepNumber: PropTypes.number.isRequired,
    // dispatchProps
    updateStepNumber: PropTypes.func.isRequired,
  }

  state = { editingValue: false }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate() {
    return true
  }

  onChange = e => this.setState({ editingValue: e.target.value })

  onBlur = () => {
    const val = parseInt(this.state.editingValue, 10)
    if (isNaN(val)) {
      this.props.updateStepNumber(val)
      this.setState({ editingValue: false })
    }
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { editingValue } = this.state
    const { stepNumber } = this.props
    const displayValue = editingValue !== false ? editingValue : stepNumber
    return (
      <div>
        <label htmlFor={ ID }>{'2期か3期か'}</label>
        <input
          id={ ID }
          type={ 'text' }
          value={ displayValue }
          onChange={ this.onChange }
        />
      </div>
    )
  }
}

export default connect(mapStateTopProps, mapDispatchToProps)(DebugStepNumber)
