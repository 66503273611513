/**
 * React-DnDのコールバックとして使う action autobind
 * @file
 */

import { actionCreators as $ } from 'src/reducers/wara'
import { ACTION_TYPES as DND_ACTION_TYPES } from 'src/reducers/dnd'
import config from 'src/config'

/**
 * [beginDrag description]
 * @param  {object}   props     props
 * @param  {object}   monitor   monitor
 * @param  {function} component component
 * @return {object}           [description]
 */
export const drop = (props, monitor, component) => {
  // dragSourceの情報取得
  const { location: locationSrc } = monitor.getItem()

  const {
    regionIndex: regionIndexSrc,
    listIndex: listIndexSrc,
    stickyIndex: stickyIndexSrc,
  } = locationSrc

  // dropTargetの情報取得
  const { location: locationDest, isAssignable } = props
  const {
    regionIndex: regionIndexDest,
    listIndex: listIndexDest,
    stickyIndex: stickyIndexDest,
  } = locationDest

  // dispatcherの取得
  const { dispatch, getState } = component.context.store
  const displayDate = getState().displayDate.displayDate

  // 付箋が所属するSSが割り当て不可の時はドロップできない。
  if (!isAssignable) {
    // draggingの状態をリセット
    dispatch({ type: DND_ACTION_TYPES.CLEAR_STICKY })
    return
  }

  if (regionIndexDest === regionIndexSrc && listIndexDest === listIndexSrc) {
    // list内移動である
    const targetRegionIndex = regionIndexDest
    const targetListIndex = listIndexDest
    dispatch(
      $.insertSticky(
        targetRegionIndex,
        targetListIndex,
        stickyIndexSrc,
        stickyIndexDest,
      ),
    )
  } else {
    // list間移動の時
    dispatch($.assignSticky(locationSrc, locationDest, displayDate))
  }

  // draggingの状態をリセット
  dispatch({ type: DND_ACTION_TYPES.CLEAR_STICKY })
}

/**
 * beginDrag mapProps
 * @param  {object}    props mapping props
 * @param  {object}    monitor   [description]
 * @param  {Component} component [description]
 * @return {object}      mapped props
 */
export const beginDrag = (props, monitor, component) => {
  // console.log('begin drag')
  // sourceの取得
  const result = {
    location: props.location,
    stickyStatusId: props.stickyStatusId,
  }

  // dispatcherの取得
  const { dispatch } = component.context.dragDropManager.store

  // どれをドラッグしているかを知る
  dispatch({
    type: DND_ACTION_TYPES.UPDATE_STICKY,
    payload: { ...result, status: 'isDragging' },
  })

  return result
}

/**
 * アサイン不可のSSに配置されている付箋をドラッグ不可にする。
 * 未配置付箋(staffId=-1)とアサイン可能(isAssignable=true)を対象とする。
 * かつ「着手」でない
 * かつ「完了」でない
 * 時のみドラッグ可能とする。
 * 終了状態もプロパティに含めてあるので判定に使うことが可能。
 * @param {*} props
 * src/components/divisions/assign/list/partials/stickies.jsx で <Sticky>に渡したプロパティ
 * src/components/divisions/assign/sticky/drag-source.js arePropsEqual()で変更チェックしないと変更が反映されない。
 */
export const canDrag = props => {
  // console.log('canDrag')
  // console.log(props)
  // 未配置付箋はロード完了するまでドラッグ不可
  if (props.location.listIndex === -1 && props.location.regionIndex === -1) {
    // 通常付箋はdockStickiesLoadedプロパティを持たせてない
    const dockStickiesLoaded = props.dockStickiesLoaded || false
    return dockStickiesLoaded
  }
  // 通常付箋で着手中のものはドラッグ不可
  const stickyStatusId = props.stickyStatusId || 0
  const finishStateId = props.finishStateId || -1
  return (
    (props.staffId === -1 || props.isAssignable) &&
    stickyStatusId !== config.stickyStatus.working &&
    finishStateId !== config.finishState.complete
  )
}

/**
 * 未配置付箋をわら上の通常付箋にドロップできないようにする。
 * 「付箋をここにドロップして配置」とは別クラスなので、単純にregionIndex !== -1 で判定しても問題ない。
 * dropが動いてしまうとわらに配置されたことになるようなので、ここで止める必要がある。
 * ドロップ先のSSが割り当て可能かどうかの判定をここでも行う。上の判定は不要になるはずだが、念のため残しておく。
 * @param {*} props
 */
export const canDrop = props => {
  // console.log('canDrop')
  // console.log(props)
  const {
    dragIndexes: { location: srcLocation }, // beginDragで設定されるプロパティ
    location: dstLocation, // ドロップ先付箋のlocation
    isAssignable, // ドロップ先付箋(SS)のアサイン可否
  } = props
  if (srcLocation.regionIndex === -1 && dstLocation.regionIndex !== -1) {
    // 未配置付箋→通常付箋の時ドロップ不可
    return false
  } else if (!isAssignable) {
    // 割り当て不可のSSの時ドロップ不可
    return false
  }
  return true
}
