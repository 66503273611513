import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import connect from './connect'
import linkHistoryHOC from './hocs/link-history'
import { Box, FormControl, ListItemIcon, MenuItem, Select } from '@mui/material'
import theme from 'src/theme.js'
import { ThemeProvider } from '@mui/material/styles'
import { Check } from '@mui/icons-material'

export const Regions = ({
  value,
  onChange,
  disabled,
  isSticky,
  isValid,
  label,
  master,
  isFlex,
}) => {
  const filterRegion = region => {
    if (region.companyId === 3) return false
    if (isSticky) return region.isSticky
    if (isValid) return region.isValid
    return true
  }
  const regions = useMemo(() => master.regions.filter(filterRegion), [
    master.regions,
    isSticky,
    isValid,
  ])
  const displayLabel = label || 'エリア'

  // 文字列で渡されたvalueの処理。-1を除外し、数値の配列に変換
  const selectedValues =
    typeof value === 'string'
      ? value
        .split(',')
        .filter(val => val !== '-1')
        .map(Number)
      : Array.isArray(value)
        ? value
        : [value].filter(val => val !== -1)

  const renderValue = selected => {
    if (!(selected || []).length) {
      return '未選択'
    }
    return selected
      .map(value => regions.find(region => region.id === value)?.name)
      .join(', ')
  }

  return (
    <ThemeProvider theme={ theme }>
      <Box>
        <label style={ { fontWeight: 'bold' } }>{displayLabel}</label>
        <FormControl
          color="primary"
          fullWidth
          sx={ {
            display: isFlex ? 'flex' : 'block',
            background: '#fff',
            '.MuiOutlinedInput-root': {
              height: '28px',
              alignItems: 'center',
              '.MuiSelect-select': {
                padding: '0px 14px',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0, 0, 0, 0.23)',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'primary.main',
              },
            },
          } }
          variant="outlined"
        >
          <Select
            disabled={ disabled }
            displayEmpty
            inputProps={ {
              name: 'regions',
              id: 'region-select',
            } }
            MenuProps={ {
              anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
              transformOrigin: { vertical: 'top', horizontal: 'left' },
              PaperProps: { style: { maxHeight: '50vh' } },
            } }
            multiple
            onChange={ onChange }
            renderValue={ renderValue }
            sx={ { width: '7rem' } }
            value={ selectedValues }
          >
            {regions.map(region => (
              <MenuItem
                color="primary"
                key={ region.id }
                sx={ {
                  columnGap: '0.5rem',
                  display: 'grid',
                  gridTemplateColumns: '1rem max-content',
                  lineHeight: '1rem',
                } }
                value={ region.id }
              >
                <ListItemIcon>
                  {selectedValues.includes(region.id) && (
                    <Check color="primary" style={ { fontSize: '0.875rem' } } />
                  )}
                </ListItemIcon>
                {region.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </ThemeProvider>
  )
}

Regions.propTypes = {
  // ownProps
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isSticky: PropTypes.bool, // 付箋関連のエリアのみを表示
  isValid: PropTypes.bool, // 有効なエリアのみを表示
  label: PropTypes.string, // タイトル
  // stateProps
  master: PropTypes.shape({
    regions: PropTypes.array.isRequired,
  }).isRequired,
  // よく分からないが、注文一覧のビューでは flex を取ってしまうと崩れるし、それ以外ではflexを取る必要がある
  isFlex: PropTypes.bool,
}

/**
 * defaultProps
 * @type {object}
 */
Regions.defaultProps = {
  isFlex: false,
  disabled: false,
  isSticky: false,
  isValid: false,
  label: '',
}

export default linkHistoryHOC(connect(Regions), 'regionIds')
export const SsRegions = linkHistoryHOC(
  connect(React.memo(Regions)),
  'ssRegionIds',
)
