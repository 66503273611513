import update from 'immutability-helper'
import { findStickyFromWara } from 'src/lib/search'

/**
 * タイプ定義
 * @type {string}
 */
const type = 'WARA.COMMIT_STICKY'

/**
 * exportation
 */
export default {
  /**
   * action type
   * @type {string}
   */
  type,

  /**
   * action creator
   * @param  {{regionIndex: number, listIndex: number, stickyIndex: number}}  regionIndex 更新するstickyがアサインされたlistのindexです
   * @return {object} commitSticky Action
   */
  creator: location => {
    return {
      type,
      payload: { location },
    }
  },

  /**
   * [handler description]
   * @param  {object}  state   [description]
   * @param  {object} action  [description]
   * @return {object}          [description]
   */
  handler: (state, { payload: { location } }) => {
    const sticky = state.modal.stage
    // nothing to commit
    if (!sticky) {
      return state
    }

    // idが更新されないようにプロパティを除外してマージする
    delete sticky.id

    const { regionIndex, listIndex, stickyIndex } = location

    const isDocked = listIndex === -1 || regionIndex === -1

    const oldSticky = findStickyFromWara(state.data, location)

    const newSticky = oldSticky.merge(sticky)

    const updator = isDocked
      ? {
        data: {
          dock: {
            stickies: {
              [stickyIndex]: { $set: newSticky },
            },
          },
        },
      }
      : {
        data: {
          regions: {
            [regionIndex]: {
              lists: {
                [listIndex]: {
                  stickies: {
                    [stickyIndex]: { $set: newSticky },
                  },
                },
              },
            },
          },
        },
      }

    return update(state, updator)
  },
}
