import React from 'react'
import PropTypes from 'prop-types'
import connect from './connect'
import TimeRange from 'src/components/commons/time-range'
import Sticky from 'src/lib/class-sticky'
import { Box, Typography } from '@mui/material'

/**
 * 所要時間の変更
 * 私用付箋で用いられる
 * determinedDateTime.startAt, determinedDateTime.finishAt としてシリアライズする
 * @type {ReactComponent}
 */
export class PrivateTime extends React.Component {
  /**
   * Validation
   * @type {object}
   */
  static propTypes = {
    // ownProps
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
    disabled: PropTypes.bool.isRequired,
    // dispatchProps
    updateStage: PropTypes.func.isRequired,
    prevId: PropTypes.string.isRequired,
    nextId: PropTypes.string.isRequired,
    displayDate: PropTypes.string.isRequired,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return (
      this.props.sticky.json().determinedDateTime !==
        nextProps.sticky.json().determinedDateTime ||
      this.props.disabled !== nextProps.disabled
    )
  }

  /**
   * 開始時間選択後のコールバック
   * @param  {string} startAt DateTimeString
   * @return {void}
   */
  onStartAtChange = startAt => {
    const { displayDate } = this.props
    const diffProps = {
      determinedDateTime: {
        date: displayDate,
        startAt,
      },
      displayDate,
    }
    this.props.updateStage(new Sticky(diffProps))
  }

  /**
   * 開始時間選択後のコールバック
   * @param  {string} finishAt DateTimeString
   * @return {void}
   */
  onFinishAtChange = finishAt => {
    const { displayDate } = this.props
    const diffProps = {
      determinedDateTime: {
        date: displayDate,
        finishAt,
      },
      displayDate,
    }
    this.props.updateStage(new Sticky(diffProps))
  }

  /**
   * render
   * @return {ReactDOM} dom
   */
  render() {
    const { sticky, route, prevId, nextId, disabled } = this.props
    const stickyProps = sticky.json()

    const startAt =
      (stickyProps.determinedDateTime &&
        stickyProps.determinedDateTime.startAt) ||
      ''
    const finishAt =
      (stickyProps.determinedDateTime &&
        stickyProps.determinedDateTime.finishAt) ||
      ''

    const identifier = 'input-private-time'

    return (
      <Box
        sx={ {
          display: 'grid',
          gridTemplateColumns: '5.5rem max-content',
          alignItems: 'center',
        } }
      >
        <Typography
          htmlFor={ identifier }
          variant="subtitle1"
          sx={ { fontWeight: 'bold' } }
        >
          {'所要時間'}
        </Typography>

        <Box id={ identifier }>
          <TimeRange
            { ...{ startAt, finishAt, prevId, nextId } }
            startAtId={ `private-date-time-start-at-${route}` }
            finishAtId={ `private-date-time-finish-at-${route}` }
            onStartAtChange={ this.onStartAtChange }
            onFinishAtChange={ this.onFinishAtChange }
            disabled={ disabled }
          />
        </Box>
      </Box>
    )
  }
}

export default connect(PrivateTime)
