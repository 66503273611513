import utils from 'src/lib/api-utils'
import { CALL_API } from 'redux-api-middleware'
import { NETWORKER } from 'src/middlewares/network-monitor'

import update from 'immutability-helper'
import switz from 'switz'
import { ACTION_TYPES as NETWORK_ACTION_TYPES } from 'src/reducers/network-status'

import { today } from 'src/lib/moment'

export const STAFF_GET_REQUEST = 'STAFF_GET_REQUEST'
export const STAFF_GET_SUCCESS = 'STAFF_GET_SUCCESS'
export const STAFF_GET_FAILURE = 'STAFF_GET_FAILURE'

/**
 * action type name bulk export
 * @type {object}
 */
export const ACTION_TYPES = {
  STAFF_GET_REQUEST,
  STAFF_GET_SUCCESS,
  STAFF_GET_FAILURE,
}

/**
 * スタッフを取得するリクエストのサイドエフェクトを持ったアクションを作る
 * @param  {string} accessToken     access token
 * @param  {string} givenDateString date string
 * @return {object} redux-api-middleware に渡す非同期処理情報を内包したアクション
 */
export const creator = (accessToken, givenDateString, env) => {
  const base = `${utils.createEndpoint(env, 'staffs')}`
  const datestring = givenDateString || today()
  const endpoint = `${base}/${datestring}`

  return {
    [CALL_API]: {
      endpoint,
      method: 'GET',
      headers: { Authorization: `Bearer ${accessToken}` },
      types: [
        {
          type: STAFF_GET_REQUEST,
          meta: {
            datestring,
            [NETWORKER]: {
              target: 'getStaffs',
              networkActionType: NETWORK_ACTION_TYPES.REQUEST,
            },
          },
        },
        {
          type: STAFF_GET_SUCCESS,
          meta: {
            datestring,
            [NETWORKER]: {
              target: 'getStaffs',
              networkActionType: NETWORK_ACTION_TYPES.SUCCESS,
            },
          },
          payload: (action, state, res) => res.json().then(payload => payload),
        },
        {
          type: STAFF_GET_FAILURE,
          meta: {
            datestring,
            [NETWORKER]: {
              target: 'getStaffs',
              networkActionType: NETWORK_ACTION_TYPES.FAILURE,
            },
          },
          payload: (action, state, res) => res.json().then(payload => payload),
        },
      ],
    },
  }
}
/**
 * [AsyncWaraReducers description]
 * @param  {object} state   state
 * @param  {object} action action
 * @return {object}         reducer
 */
export const partialReducer = (state, action) => {
  const { type, payload } = action

  return switz(type, s =>
    s
      .case(STAFF_GET_REQUEST, () => state)
      .case(STAFF_GET_SUCCESS, () => {
        const staffss = payload || {}
        const regions = Object.keys(staffss).map(key => {
          const regionId = parseInt(key, 10)
          // eslint-disable-next-line no-console
          regionId || console.warn('不正なサービススタッフオブジェクトです。')
          // 重複する担当者を除去する
          const noDupStaffs = staffss[key].reduce(
            (prev, current) =>
              prev.staffId !== current.staffId
                ? {
                  staffId: current.staffId,
                  staffs: [...prev.staffs, current],
                }
                : prev,
            { staffId: null, staffs: [] },
          )
          // 付箋を入れるハコを作る
          const lists = noDupStaffs.staffs.map(staff => ({
            ...staff,
            stickies: [],
          }))
          return { id: regionId, lists }
        })
        return update(state, {
          data: {
            regions: { $set: regions },
          },
        })
      })
      .case(STAFF_GET_FAILURE, () => {
        // 失敗したときは空の配列を入れる
        return update(state, { data: { $set: {} } })
      })
      .default(() => state),
  )
}
