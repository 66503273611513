import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { baseColor } from 'src/colors'
import { darken } from 'polished'

export const Container = styled.div`
  width: 400px;
  margin: 30px auto;
`

export const Title = styled.h1`
  margin: 20px 0;
  font-size: 2rem;
  text-align: center;
`

export const NavigationItems = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ItemsWrap = styled.ul`
  margin-top: 30px;
`

export const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 128px;
  margin: 10px 10px 0 0;
  border-radius: 8px;
  background: #fff;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 3px;
  text-align: center;
  font-size: 18px;
  height: 58px;

  &:hover {
    transform: translate3d(0, 1px, 0);
    box-shadow: 0;

    span {
      color: ${darken(0.1, baseColor)};
    }
  }
`

export const NavLabel = styled.span`
  color: ${baseColor};
`

export const Pillar = styled.div`
  display: flex;
  flex-direction: row;
`
