// lib
import { createImageEndpoint } from 'src/lib/api-utils'

export const get = (imageId, accessToken, env) => {
  const url = createImageEndpoint(env, 'download')

  const request = fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: `id=${imageId}`,
  })

  return request
}

export default get
