import React from 'react'
import PropTypes from 'prop-types'
import switz from 'switz'
import noop from 'src/lib/noop'
import { NavButton } from './styled'

export default class ActivePageLink extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    isActive: PropTypes.bool.isRequired,
    page: PropTypes.number,
    baseClassName: PropTypes.string,
    onButtonClick: PropTypes.func,
    isFirstLink: PropTypes.bool,
    isPrevLink: PropTypes.bool,
    isNextLink: PropTypes.bool,
    isLastLink: PropTypes.bool,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    baseClassName: '',
    onButtonClick: noop,
    page: -1,
    isFirstLink: false,
    isPrevLink: false,
    isNextLink: false,
    isLastLink: false,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate() {
    return true
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const {
      isActive,
      page,
      baseClassName,
      onButtonClick,
      isFirstLink,
      isPrevLink,
      isNextLink,
      isLastLink,
    } = this.props
    const className = `${baseClassName} page-${page + 1} buttton-${
      isActive ? 'active' : 'in-acvtive'
    }`
    const displayPage =
      page === -1
        ? // pageを与えなかった時
        switz(
          [isFirstLink, isPrevLink, isNextLink, isLastLink].indexOf(true),
          s =>
            s
              .case(0, () => '最初')
              .case(1, () => '前へ')
              .case(2, () => '次へ')
              .case(3, () => '最後'),
        )
        : page + 1
    return isActive ? (
      <NavButton className={ className } onClick={ onButtonClick }>
        {displayPage}
      </NavButton>
    ) : (
      <span className={ className }>{displayPage}</span>
    )
  }
}
