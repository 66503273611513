import React from 'react'
import PropTypes from 'prop-types'
import connect from '../../connect'

/**
 * 追加作業種別入力コンポーネント
 * @type {ReactComponent}
 */
export class WorkItem extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    label: PropTypes.string.isRequired, // 見出し
    value: PropTypes.number.isRequired, // 表示する値
    itemValue: PropTypes.string.isRequired, // value=「その他」の時の値
    name: PropTypes.string.isRequired, // 項目名
    itemIndex: PropTypes.number.isRequired, // 作業項目のインデックス
    onChange: PropTypes.func.isRequired, // 入力があった時のコールバック
    route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
    disabled: PropTypes.bool.isRequired,
    // stateprops
    master: PropTypes.object.isRequired,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return (
      this.props.value !== nextProps.value ||
      this.props.itemValue !== nextProps.itemValue ||
      this.props.disabled !== nextProps.disabled
    )
  }

  /**
   * ドロップダウンが変更された
   * @param {*} e
   */
  onWorkItemchange = e => {
    const { itemValue, itemIndex, onChange } = this.props
    const workItemType = parseInt(e.target.value, 10)
    // 「その他」以外を選択した時はテキストをクリアする
    const text = workItemType === 99 ? itemValue : null
    onChange(itemIndex, workItemType, text)
  }

  /**
   * その他のテキストが変更された
   * @param {*} e
   */
  onWorkItemTextChange = e => {
    const { value, itemIndex, onChange } = this.props
    const text = e.target.value
    onChange(itemIndex, value, text)
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const {
      label,
      value,
      itemValue,
      name,
      route,
      disabled,
      master,
    } = this.props

    const dtStyle = {
      marginTop: '10px',
    }

    const ddStyle = {
      flexGrow: 0,
      marginRight: '20px',
      marginTop: '10px',
    }

    const groupName = `houselabo-${name}-${route}`
    const identifier = `id-${groupName}`

    const dispWorkItems = [
      { id: -1, name: '未選択' },
      ...master.additionalWorkType,
    ]
    const other = value === 99 // 「その他」が選択された

    return (
      <>
        <dt style={ dtStyle }>
          <label htmlFor={ identifier }>{label}</label>
        </dt>
        <dd className={ 'input-wrap' } style={ ddStyle }>
          <div className={ 'flex' }>
            <select
              name={ identifier }
              id={ identifier }
              onChange={ this.onWorkItemchange }
              value={ value }
              disabled={ disabled }
            >
              {dispWorkItems.map(item => (
                <option key={ item.id } value={ item.id }>
                  {item.name}
                </option>
              ))}
            </select>
            {other ? (
              <input
                className={ 'short' }
                value={ itemValue }
                id={ `${identifier}-input` }
                name={ groupName }
                type={ 'text' }
                onChange={ this.onWorkItemTextChange }
                disabled={ disabled }
              />
            ) : null}
          </div>
        </dd>
      </>
    )
  }
}

export default connect(WorkItem)
