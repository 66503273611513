/**
 * ライブラリ
 */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

/**
 * コンポーネント
 */
import FloatId from './debugger/float-id'
import NormalStickySurface from './partials/sticky-surface/normal'
import SpecialStickySurface from './partials/sticky-surface/specials'

import createClasses from './style'
import config from 'src/config'
import ClassSticky from 'src/lib/class-sticky'
import styled from 'styled-components'

/**
 * 付箋詳細表示中に被せるコンポーネント
 * isDocked(trueの時未アサイン)によって位置とサイズを調整する。
 * z-indexは
 * src/styles/components/_sticky.scss
 * で設定したホバー時の付箋のz-indexより大きなものを設定する。
 */
const Waiting = styled.div`
  position: absolute;
  top: 0;
  left: ${props => (props.isDocked ? 0 : '10px')};
  width: ${props => (props.isDocked ? '100%' : '95%')};
  height: 100%;
  background-color: gray;
  opacity: 0.7;
  z-index: 20;
`

/**
 * 付箋のコンポーネント
 * @type {ReactComponent}
 */
const Sticky = props => {
  const {
    location,
    sticky: propSticky,
    getSSworkList,
    workState,
    master: { orderRanks: orderRanksMaster = {} },
    splitSourceLocation,
    accessToken,
    cityCodes,
    isDebugMode,
    getCityCodes,
    canDrop,
    connectDragSource,
    connectDropTarget,
    dragIndexes,
    isDragging,
    isOver,
  } = props

  const sticky = propSticky || new ClassSticky({})
  const { prefCode } = sticky.findClient() || {}

  const [isWaiting, setIsWaiting] = useState(false)

  // 必要な市町村コードを取得して格納する
  useEffect(() => {
    if (prefCode && !cityCodes[prefCode]) {
      getCityCodes(prefCode, accessToken)
    }
  }, [propSticky, cityCodes, accessToken, getCityCodes])

  /**
   * 待ち状態を更新する
   */
  const setWaiting = waiting => {
    setIsWaiting(waiting)
  }

  const stickyProps = sticky.json()

  let droppingFromLeft = false
  let droppingFromRight = false
  let droppingToLeft = false
  let droppingToRight = false

  // どちらからドラッグして入れようとしているのかを判定する簡易的な部分
  // 本当はマウス位置と対象のコンポーネントのポジションを比較する必要がある
  if (isOver) {
    if (location.listIndex === dragIndexes.listIndex) {
      // リスト内交換
      droppingFromLeft = location.stickyIndex > dragIndexes.index
      droppingFromRight = location.stickyIndex < dragIndexes.index
    } else {
      // リスト間交換
      droppingToLeft = location.stickyIndex <= dragIndexes.index
      droppingToRight = location.stickyIndex > dragIndexes.index
    }
  }

  // clicker
  /**
   * 未配置付箋の分裂モードを開始する
   * @return {[type]} [description]
   */
  const startSplitMode = () => {}

  const stopSplitMode = () => {}

  const isWorking = stickyProps.stickyStatusId === config.stickyStatus.working
  const isActualSplitSource =
    location.regionIndex === splitSourceLocation.regionIndex &&
    location.listIndex === splitSourceLocation.listIndex &&
    location.stickyIndex === splitSourceLocation.stickyIndex

  const isAdjusting =
    !!JSON.parse(sticky.getUserValue('adjusting') || null) || false

  const isCanceled = stickyProps.finishStateId === config.finishState.cancel
  const isNa = stickyProps.finishStateId === config.finishState.na
  const isReformCompleted =
    stickyProps.finishStateId === config.finishState.reformComplete

  const isSuspended = ((stickyProps.suspendReason || {}).ids || []).includes(
    config.suspendReason.suspend1,
  )
  const hasUndecidedAfter = (
    (stickyProps.suspendReason || {}).ids || []
  ).includes(config.suspendReason.afterUndecided)

  const hasAfter = !!(
    stickyProps.afterDateTime && stickyProps.afterDateTime.date
  )

  const classes = createClasses(
    sticky,
    {
      dragging: isDragging,
      droppable: canDrop,
      dropping: isOver,
      'dropping-from-left': droppingFromLeft,
      'dropping-from-right': droppingFromRight,
      'dropping-to-left': droppingToLeft,
      'dropping-to-right': droppingToRight,
      'is-working': isWorking && !hasAfter,
      'is-adjusting': isAdjusting,
      'is-split-sticky': isActualSplitSource,
      'is-canceled': isCanceled,
      'is-na': isNa,
      'is-reform-completed': isReformCompleted,
      'is-suspended': isSuspended,
      'has-undecided-after': hasUndecidedAfter,
      'has-after': hasAfter,
    },
    orderRanksMaster,
  )

  const isDocked = location.regionIndex === -1 || location.listIndex === -1
  const isNormal =
    !stickyProps.stickyTypeId ||
    stickyProps.stickyTypeId === config.stickyType.normal

  const isMaruc = stickyProps.orderTypeId === config.orderType.claimer
  const isReRequest = !!sticky.getUserValue('reRequestOf')
  const isPhoneCall = !!sticky.getUserValue('leave-me-until-phone-call')

  // 注文タイプがリピートで未着手のときリピ表示にチェックを入れたら「リピ」バッジを表示
  // ただし注文タイプがリピートでもアフターと保留復活・キャンセル復活は「リピ」バッジの表示は不要
  const isAfterChildren =
    stickyProps.userField && stickyProps.userField.includes('afterOf')
  const isResume =
    stickyProps.userField && stickyProps.userField.includes('resume')
  const isShowSchedule =
    stickyProps.orderTypeId === config.orderType.repeat &&
    stickyProps.stickyStatusId === config.stickyStatus.notWorking &&
    !stickyProps.cancelReasonId &&
    stickyProps.showSchedule &&
    !isAfterChildren &&
    !isResume

  /**
   * 付箋の情報取得状態を表す表示
   */
  const waitingDOM = (
    <Waiting isDocked={ isDocked }>
      <div
        style={ {
          width: '33%',
          height: '33%',
          marginLeft: '33%',
          marginTop: '33%',
        } }
      >
        <i className={ 'fa fa-refresh fa-spin fa-3x fa-fw' } />
      </div>
    </Waiting>
  )

  /**
   * returnされるDOM
   * @return {ReactDOM} not decorated sticky components
   */
  const stickyDOM = (
    <li
      // 分裂モード切り替え
      onDoubleClick={
        isDocked
          ? isActualSplitSource
            ? stopSplitMode
            : startSplitMode
          : void 0
      }
      style={ { position: 'relative' } }
    >
      {isDebugMode && <FloatId id={ stickyProps.id } />}
      {isNormal ? (
        <NormalStickySurface
          className={ classes }
          location={ location }
          sticky={ sticky }
          isSplitSource={ isActualSplitSource }
          setWaiting={ setWaiting }
          isMaruc={ isMaruc }
          isReRequest={ isReRequest }
          isPhoneCall={ isPhoneCall }
          isShowSchedule={ isShowSchedule }
          getSSworkList={ getSSworkList }
          workState={ workState }
        />
      ) : (
        <SpecialStickySurface
          className={ classes }
          location={ location }
          sticky={ sticky }
          setWaiting={ setWaiting }
          isMaruc={ isMaruc }
          isReRequest={ isReRequest }
          isPhoneCall={ isPhoneCall }
          isShowSchedule={ isShowSchedule }
          getSSworkList={ getSSworkList }
          workState={ workState }
        />
      )}
      {isWaiting && waitingDOM}
    </li>
  )

  return isDocked
    ? connectDragSource(stickyDOM)
    : connectDropTarget(connectDragSource(stickyDOM))
}

/**
 * Validation
 * @type {object}
 */
Sticky.propTypes = {
  // ownProps
  location: PropTypes.shape({
    regionIndex: PropTypes.number.isRequired,
    listIndex: PropTypes.number.isRequired,
    stickyIndex: PropTypes.number.isRequired,
  }).isRequired,
  sticky: PropTypes.instanceOf(ClassSticky).isRequired,
  getSSworkList: PropTypes.func.isRequired,
  workState: PropTypes.number.isRequired,
  // stateProps
  wara: PropTypes.shape({
    regions: PropTypes.arrayOf(
      PropTypes.shape({
        lists: PropTypes.arrayOf(
          PropTypes.shape({
            stickies: PropTypes.arrayOf(PropTypes.instanceOf(ClassSticky)),
          }),
        ),
      }),
    ).isRequired,
  }).isRequired,
  master: PropTypes.object.isRequired,
  splitSourceLocation: PropTypes.object,
  accessToken: PropTypes.string.isRequired,
  // env: PropTypes.object.isRequired,
  cityCodes: PropTypes.object,
  isDebugMode: PropTypes.bool,
  // splits: PropTypes.array.isRequired, // 分裂モード中に分裂させた付箋
  waraRegions: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired, // 表示するわらのエリア
  docRegions: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired, // 表示する未配置付箋のエリア
  prefCodesClassified: PropTypes.object.isRequired, // エリアごとの県コードの一覧
  // dispatchProps
  getCityCodes: PropTypes.func.isRequired,
  // dndProps
  canDrop: PropTypes.bool,
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  dragIndexes: PropTypes.shape({
    index: PropTypes.number,
    listIndex: PropTypes.number,
  }),
  isDragging: PropTypes.bool,
  isOver: PropTypes.bool,
}

/**
 * defaults
 * @type {object}
 */
Sticky.defaultProps = {
  isDebugMode: false,
  canDrop: false,
  isDragging: false,
  isOver: false,
  dragIndexes: {},
  dropIndexes: {},
  cityCodes: {},
  // clicker
  isSplitSource: false,
  splitSourceLocation: {},
  dockStickiesLoaded: true,
}

export default Sticky
