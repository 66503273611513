import styled from 'styled-components'
import { baseColor, white } from 'src/colors'

export const Screen = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 50000000000000;
`

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`

const buttonProps = {
  open: `
    right: 48px;
    bottom: 48px;
  `,
  close: `
    top: 44px;
    right: 48px;
  `,
}

const selectButtonProps = props => {
  if (props.open) {
    return buttonProps.open
  } else if (props.close) {
    return buttonProps.close
  } else {
    return ''
  }
}

export const Button = styled.button`
  color: ${baseColor};
  width: 50px;
  height: 50px;
  border: solid 2px ${baseColor};
  border-radius: 25px;
  font-size: 28px;
  position: fixed;
  ${selectButtonProps};

  &:hover {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    background-color: #ffffff;
  }
`

export const Table = styled.table`
  min-width: 900px;
  margin: auto;
  font-size: 1.2em;

  &:first-child {
    margin-bottom: 3em;
  }
`

export const Caption = styled.caption`
  color: ${white};
  margin: 0.5em;
`

export const Tr = styled.tr`
  border-bottom: ${props => (props.underline ? '1px' : 0)} solid black;
`

export const Th = styled.th`
  padding: 5px;
  text-align: left;
  color: ${baseColor};
  font-weight: bold;
`

export const Td = styled.td`
  background-color: ${white};
  padding: 5px;
  text-align: left;
`
