import { connect } from 'react-redux'
import noop from 'src/lib/noop'

export const mapStateToProps = (state, ownProps) => ({
  accessToken: state.login.authentication.accessToken,
  env: state.env,
  update: ownProps.route === 'call' ? state.messenger.update : noop,
  reset: ownProps.route === 'call' ? state.messenger.reset : noop,
})

export default Component => connect(mapStateToProps)(Component)
