import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import button, { buttonFill } from './'
import { baseColor } from 'src/colors'

const Frame = styled.div`
  text-align: center;
`

/**
 * ページの最下部などに表示する、これに決めた、的なボタン。
 * 押下でリクエストが発出したりするもので、そのページの最終目的のアクションに使うイメージ
 * @type {React}
 */
const DecisionButton = styled.button`
  ${button};
  ${buttonFill(baseColor)};
  font-size: 16px;
  font-weight: bold;
  height: 45px;
  width: 300px;
`

export const Decision = props => (
  <Frame>
    <DecisionButton { ...props }>{props.children}</DecisionButton>
  </Frame>
)

Decision.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}
export default Decision
