import utils from 'src/lib/api-utils'
import { qsjoin } from 'src/lib/format'

/**
 * telephone number search action creator
 * @param  {object} query       search with
 * @param  {string} accessToken access token
 * @return {Promise}            promised fetch
 */
export const get = (query, accessToken, env) => {
  const base = `${utils.createEndpoint(env, 'orderList')}`
  const endpoint = `${base}?${qsjoin(query)}`

  const request = fetch(endpoint, {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  })

  return request
}

export default get
