import React from 'react'
import PropTypes from 'prop-types'
import connect from './connect'
import linkHistoryHOC from './hocs/link-history'

export const CompanyIds = ({
  value,
  onChange,
  disabled,
  isFlex,
  showAll,
  master,
}) => {
  const companyIds = [{ id: -1, name: '全て' }]

  return (
    <dl
      className={ 'label-inputs-wrap label-inputs-wrap-inline' }
      style={ { display: isFlex ? 'flex' : 'block' } }
    >
      <dt>{'受付会社'}</dt>
      <dd className={ 'input-wrap' }>
        <select
          value={ value }
          onChange={ onChange }
          disabled={ disabled }
          className={ 'short' }
        >
          {companyIds.map(company => (
            <option value={ company.id } key={ company.id }>
              {company.name}
            </option>
          ))}
        </select>
      </dd>
    </dl>
  )
}

CompanyIds.propTypes = {
  // ownProps
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isFlex: PropTypes.bool,
  showAll: PropTypes.bool,
  // stateProps
  master: PropTypes.shape({
    companies: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
}

/**
 * defaultProps
 * @type {object}
 */
CompanyIds.defaultProps = {
  isFlex: false,
  disabled: false,
  showAll: true,
}

export default linkHistoryHOC(connect(React.memo(CompanyIds)), 'companyId')
