import { CALL_API } from 'redux-api-middleware'
import { NETWORKER } from 'src/middlewares/network-monitor'
import { ACTION_TYPES as NETWORK_ACTION_TYPES } from 'src/reducers/network-status'
import utils from 'src/lib/api-utils'
import switz from 'switz'
import update from 'immutability-helper'
import decodeJWT from 'jsonwebtoken/decode'
import { decode } from 'src/lib/obfuscate'

// 適当に決めた値
export const USERID_LS_KEY = '0831d014-961f-46d3-8271-9c1e70f4d1e7'
export const PASSWORD_LS_KEY = '3a8b5fba-10a7-42a3-9589-852880491641'

/**
 * initial state
 * @type {object}
 */
export const initialLoginState = {
  authentication: {
    // TODO: これは、多分いらない
    userid: decode(localStorage.getItem(USERID_LS_KEY)) || '',
    password: decode(localStorage.getItem(PASSWORD_LS_KEY)) || '',
    accessToken: '',
    displayName: '',
    roles: [],
    expiredAt: -1,
  },
  offlineMode: false,
}

/**
 * Action Types
 * @type {string}
 */
export const LOGIN_REQUEST = 'LOGIN.REQUEST'
export const LOGIN_SUCCESS = 'LOGIN.SUCCESS'
export const LOGIN_FAILURE = 'LOGIN.FAILURE'

export const SET_USERNAME = 'LOGIN.SET_USERNAME'
export const SET_PASSWORD = 'LOGIN.SET_PASSWORD'
export const SET_OFFLINE_MODE = 'LOGIN.SET_OFFLINE_MODE'
export const SET_LOGIN_STATUS = 'LOGIN.SET_LOGIN_STATUS'
export const DO_LOGOUT = 'LOGIN.DO_LOGOUT'

/**
 * action bulk exports
 * @type {object}
 */
export const ACTION_TYPES = {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,

  SET_USERNAME,
  SET_PASSWORD,
  SET_OFFLINE_MODE,
  SET_LOGIN_STATUS,
  DO_LOGOUT,
}

export const createActions = {
  setUsername: userid => ({ type: SET_USERNAME, payload: { userid } }),
  setPassword: password => ({ type: SET_PASSWORD, payload: { password } }),
  setOfflineMode: value => ({ type: SET_OFFLINE_MODE, payload: { value } }),
  loginSuccess: (token, meta) => ({
    type: LOGIN_SUCCESS,
    payload: { token },
    meta,
  }),
}

/**
 * login action creator
 * @param  {string} userid user to login
 * @param  {string} password password to login
 * @return {object} redux-api-middleware に渡す非同期処理情報を内包したアクション
 */
export const createAsyncLoginAction = (userid, password, env) => {
  const endpoint = `${utils.createEndpoint(env, 'login')}`

  return {
    [CALL_API]: {
      endpoint,
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `userid=${userid}&password=${password}`,
      types: [
        {
          type: LOGIN_REQUEST,
          meta: {
            [NETWORKER]: {
              target: 'login',
              networkActionType: NETWORK_ACTION_TYPES.REQUEST,
            },
          },
        },
        {
          type: LOGIN_SUCCESS,
          meta: {
            userid,
            cached: false,
            [NETWORKER]: {
              target: 'login',
              networkActionType: NETWORK_ACTION_TYPES.SUCCESS,
            },
          },
        },
        {
          type: LOGIN_FAILURE,
          meta: {
            [NETWORKER]: {
              target: 'login',
              networkActionType: NETWORK_ACTION_TYPES.FAILURE,
            },
          },
        },
      ],
    },
  }
}

/**
 * login reducer
 * @param  {object} [state=initialLoginState] previous state
 * @param  {object} action                 dispatched action
 * @return {object}                         next state
 */
export default (state = initialLoginState, action) => {
  const { type, payload } = action
  return switz(type, s =>
    s
      // reducer for Login
      .case(LOGIN_REQUEST, () => state)
      .case(LOGIN_SUCCESS, () => {
        const { token: accessToken } = payload
        const JWTpayload = decodeJWT(accessToken)
        if (JWTpayload) {
          const displayName = JWTpayload.profile.name
          const roles = JWTpayload.profile.roles
          const expiredAt = JWTpayload.exp
          return update(state, {
            authentication: {
              accessToken: { $set: accessToken },
              roles: { $set: roles },
              displayName: { $set: displayName },
              expiredAt: { $set: expiredAt },
            },
          })
        } else {
          return update(state, {
            authentication: {
              accessToken: { $set: '' },
              roles: { $set: [] },
              displayName: { $set: '' },
              expiredAt: { $set: -1 },
            },
          })
        }
      })
      .case(LOGIN_FAILURE, () => state)
      // others
      .case(SET_USERNAME, () =>
        update(state, {
          authentication: {
            userid: {
              $set: payload.userid,
            },
          },
        }),
      )
      .case(SET_PASSWORD, () =>
        update(state, {
          authentication: {
            password: {
              $set: payload.password,
            },
          },
        }),
      )
      .case(SET_OFFLINE_MODE, () =>
        update(state, {
          offlineMode: { $set: payload.value },
        }),
      )
      .case(SET_LOGIN_STATUS, () =>
        update(state, {
          status: {
            $set: payload.status,
          },
        }),
      )
      .case(DO_LOGOUT, () =>
        update(state, {
          authentication: {
            accessToken: { $set: '' },
            userid: { $set: '' },
            password: { $set: '' },
            displayName: { $set: '' },
            roles: { $set: [] },
            expiredAt: { $set: -1 },
          },
        }),
      )
      .default(() => state),
  )
}
