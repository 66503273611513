// react related
import React from 'react'
import PropTypes from 'prop-types'

// component
import WrappedCalendar from 'src/components/commons/wrapped-calendar'
import { Box, Typography } from '@mui/material'

export class AccountDate extends React.PureComponent {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    value: PropTypes.string.isRequired,
    route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
    disabled: PropTypes.bool.isRequired,
    required: PropTypes.bool.isRequired,
    // dispatchProps
    update: PropTypes.func.isRequired,
  }

  /**
   * 売掛金の日付を変更した時にステージング値に反映する
   * @param  {string} date  date String
   * @return {void}
   */
  onCalendarSelect = date => this.props.update(date)

  /**
   * 入力値リセットボタンを押した時のハンドラ
   * @return {void}
   */
  onResetClick = () => this.props.update('')

  get identifier() {
    return `account-select-retrieve-date-${this.props.route}`
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { route, value, required } = this.props

    const disabled = this.props.disabled || route !== 'assign'

    return (
      <Box
        sx={ {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          columnGap: '0.5rem',
        } }
      >
        <Typography
          htmlFor={ 'account-date' }
          variant="subtitle1"
          sx={ {
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
          } }
        >
          {'集金・振込日'}
          {required && (
            <span style={ { color: '#ff4f3d', marginLeft: '0.25rem' } }>
              {'*'}
            </span>
          )}
        </Typography>
        <WrappedCalendar
          buttonId={ `account-date-${route}` }
          inputId={ `account-date-${route}-input` }
          value={ value }
          onSelect={ this.onCalendarSelect }
          disabled={ disabled }
        />
        {value ? (
          <button
            className={ 'button button-icon-delete button-modal-delete' }
            onClick={ this.onResetClick }
            disabled={ disabled }
          >
            {'リセット'}
          </button>
        ) : null}
      </Box>
    )
  }
}

export default AccountDate
