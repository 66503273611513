import React from 'react'
import styled from 'styled-components'

const StickyLoadingWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`

export const StickyLoading = () => (
  <StickyLoadingWrap>
    <i className={ 'fa fa-spinner fa-pulse fa-3x' } aria-hidden={ 'true' } />
  </StickyLoadingWrap>
)

export const Screen = styled.div`
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2;
`
