import switz from 'switz'
import update from 'immutability-helper'

/**
 * 初期値
 * @type {object}
 */
export const initialDisplayDateState = {
  displayDate: '',
}

/**
 * Action Types
 * @type {string}
 */
export const UPDATE = 'DISPLAY_DATE.UPDATE'

/**
 * action bulk exports
 * @type {object}
 */
export const ACTION_TYPES = {
  UPDATE,
}

/**
 * displayDate reducer
 * 現在表示しているわらの日付を格納するreducer
 * @param  {object} [state=initialDisplayDateState] previous state
 * @param  {object} action                 dispatched action
 * @return {object}                         next state
 */
export default (state = initialDisplayDateState, action) => {
  const { type, payload } = action

  return switz(type, s =>
    s
      .case(UPDATE, () => {
        const { displayDate } = payload
        return update(state, { displayDate: { $set: displayDate } })
      })
      .default(() => state),
  )
}
