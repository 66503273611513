/**
 * 付箋の初期値
 * @type {Object}
 */
export const defaultStickyProps = {
  afterDateTime: {},
  buildingType: {},
  contacts: [],
  determinedDateTime: {},
  handOver: {},
  negotiationRecords: [],
  marucComplaint: {},
  stickyStatusId: 0,
  suspendReason: {},
  thankYouCall: {},
  visitedHistories: [],
  wishDateTimes: [],
  workPoint: {},
  workType: {},
}

/**
 * サーバーから入ってきた付箋データをオブジェクトとして正規化する
 * isClientなcontentを1つに保つ、
 * idの無いcontact, wishDateTimes、negotiationRecordsを削除
 * @param  {object} props 付箋オブジェクト
 * @return {object}             正規化された付箋オブジェクト
 */
const normalize = props => {
  const stickyProps = { ...defaultStickyProps, ...props }
  // stickyStatusIdがnull or undefined なら、0（通常状態）にする
  if (
    stickyProps.stickyStatusId === null ||
    stickyProps.stickyStatusId === void 0
  ) {
    stickyProps.stickyStatusId = 0
  }

  // おかしなregionIdが入ってきたら、nullにする
  if (stickyProps.regionId === -1) {
    stickyProps.regionId = null
  }

  // contacts の処理
  const tabContacts = stickyProps.contacts || []
  // `isClient === true` なやつを見つけたフラグ
  let foundClient = false
  for (let j = 0; j < tabContacts.length; j++) {
    const contacts = tabContacts[j] || []
    for (let k = 0; k < contacts.length; k++) {
      const contact = contacts[k] || {}

      // contact.idを持たない奴を削除
      if (isNaN(contact.id)) {
        // TODO: ここはなぜコメントアウトされている？
        // delete contacts[k]
      } else {
        // isClient属性の処理
        if (!foundClient && contact.isClient) {
          foundClient = true
        } else {
          contact.isClient = false
        }
      }
    }
  }

  // idを持たないwishDateTimesを削除
  stickyProps.wishDateTimes = (stickyProps.wishDateTimes || []).filter(
    wishDateTime => !isNaN(wishDateTime.id),
  )

  // idを持たないnegotiationRecordsを削除
  stickyProps.negotiationRecords = (
    stickyProps.negotiationRecords || []
  ).filter(record => !isNaN(record.id))

  // 作成日時に応じてソート
  stickyProps.negotiationRecords.sort(
    (a, b) => new Date(b.createAt) - new Date(a.createAt),
  )

  return stickyProps
}

export default normalize
