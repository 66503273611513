import styled from 'styled-components'
import { borderDarkGray, red, baseColor } from 'src/colors'

export const Hr = styled.hr`
  height: 1px;
  border: 0;
  background-color: ${borderDarkGray};
`

export const TitleWrap = styled.span`
  display: flex;
  align-items: center;
  flex-direction: row;
`

// 売掛金の残金あり表示
export const ImcompletContact = styled.span`
  margin-left: 10px;
  margin-right: 20px;
  padding: 4px 6px;
  color: ${red};
  font-weight: bold;
  font-size: 1.2em;
  border: solid 2px;
  border-radius: 4px;
`

export const PhotoButton = styled.button`
  color: ${baseColor};
  width: 50px;
  height: 50px;
  border: solid 2px ${baseColor};
  border-radius: 25px;
  font-size: 24px;
  position: fixed;
  right: 108px;
  bottom: 48px;

  &:hover {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    background-color: #ffffff;
  }
`

export const KPhotoButton = styled.button`
  color: ${baseColor};
  width: 50px;
  height: 50px;
  border: solid 2px ${baseColor};
  border-radius: 25px;
  font-size: 24px;

  &:hover {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    background-color: #ffffff;
  }
`

export const QuotationButton = styled(PhotoButton)`
  right: 468px;
`

export const ContractButton = styled(PhotoButton)`
  right: 408px;
`

export const WorkCompleteButton = styled(PhotoButton)`
  right: 348px;
`

export const ReceiptButton = styled(PhotoButton)`
  right: 288px;
`

export const ShipInventoryButton = styled(PhotoButton)`
  right: 228px;
`

export const HLWorkCompleteButton = styled(PhotoButton)`
  right: 168px;
`

export const ESignAgreementButton = styled(PhotoButton)`
  right: 528px;
`

export const CloseButton = styled.div`
  color: gray;
  font-size: 3rem;
  font-weight: bold;
  margin: 4px;
  margin-top: -6px;
  cursor: pointer;

  &:hover {
    color: black;
  }
`
