import React from 'react'
import PropTypes from 'prop-types'
import { Hr } from 'src/styled/list'
import { Screen } from '../styled'

import Documents from './partials/documents'
import AccountHistories from './partials/account-histories'
import IncompleteAccounts from './partials/incomplete-accounts'
import Expenses from './partials/expenses'
import PipeMaterial from './partials/pipe-material'
import SmileData from './partials/smile-data'
import Payments from './partials/payments'
import Advance from './partials/advance'
import LiquidationData from './partials/liquidation-data'
import Refunds from './partials/refunds'

export const Description = props => {
  const {
    settlement: {
      accountHistories, // 売掛金履歴
      expenses, // 自己負担
      payments,
      pipeMaterial, // 材料費等
      smileData,
      incompleteAccounts, // 未回収売掛金
      advance,
      liquidations, // 外注費
      documents, // 提出済・未提出の伝票
      refund, // 返金
    },
    uploadDocuments,
    query,
    isSettled,
    accessToken,
    env,
    refundMethods,
  } = props

  return (
    <Screen>
      {/* TODO: 足し合わせる */}
      {/* <Nyukin value={ 123 } /> */}
      {/* TODO: 足し合わせる */}
      {/* <SekisanUchikin value={ 50 } /> */}
      {/* <SekisanUrikake value={ 25 } /> */}
      {/* <AnshinSupport value={ 344234 } /> */}
      <Payments items={ payments } accessToken={ accessToken } env={ env } />
      <Hr />
      <AccountHistories items={ accountHistories } />
      <Hr />
      <Refunds refund={ refund } refundMethods={ refundMethods } />
      <Hr />
      <PipeMaterial items={ pipeMaterial } />
      <Hr />
      <LiquidationData items={ liquidations || [] } />
      <Hr />
      <SmileData items={ smileData } />
      <Hr />
      <Advance advance={ advance } query={ query } isSettled={ isSettled } />
      <Hr />
      <IncompleteAccounts items={ incompleteAccounts } />
      <Hr />
      <Expenses items={ expenses } />
      <Hr />
      <Documents name={ '提出済伝票' } items={ documents.submitted || [] } />
      <Hr />
      <Documents name={ '未提出伝票' } items={ documents.unsubmitted || [] } />
      <Hr />
      <Documents
        name={ '提出済伝票（アプリ）' }
        items={ uploadDocuments.submitted || [] }
      />
      <Hr />
      <Documents
        name={ '未提出伝票（アプリ）' }
        items={ uploadDocuments.unsubmitted || [] }
      />
    </Screen>
  )
}

/**
 * propTypes
 * @type {object}
 */
Description.propTypes = {
  settlement: PropTypes.shape({
    accountHistories: PropTypes.array.isRequired,
    expenses: PropTypes.array.isRequired,
    payments: PropTypes.array.isRequired,
    pipeMaterial: PropTypes.array.isRequired,
    incompleteAccounts: PropTypes.array.isRequired,
    smileData: PropTypes.array.isRequired,
    isSettled: PropTypes.bool.isRequired,
    documents: PropTypes.array.isRequired,
  }).isRequired,
  uploadDocuments: PropTypes.array.isRequired,
  query: PropTypes.object.isRequired,
  isSettled: PropTypes.bool.isRequired,
  accessToken: PropTypes.string.isRequired,
  env: PropTypes.object.isRequired,
  refundMethods: PropTypes.array.isRequired,
}

// // 伝票コンポーネントのテストデータ
// const submitted = [
//   {
//     documentType: 2,
//     numbers: [
//       '1',
//       '3-4',
//       '5',
//       '10000',
//       '10000',
//       '10000',
//       '10000',
//       '10000',
//       '10000',
//       '10000',
//       '10000',
//       '10000',
//       '10000',
//       '10000',
//       '10000',
//       '10000',
//     ],
//   },
//   {
//     documentType: 3,
//     numbers: ['2', '6-8'],
//   },
//   {
//     documentType: 1,
//     numbers: ['なし'],
//   },
//   {
//     documentType: 4,
//     numbers: ['なし'],
//   },
//   {
//     documentType: 5,
//     numbers: ['なし'],
//   },
// ]

// // 外注費のテストデータ
// const testLiquidations = [
//   {
//     liquidation: {
//       id: 64,
//       completeDate: '2019-03-04',
//       customerName: '藤田　貞夫',
//       workSummary: 'BKK, 洗面台交換',
//     },
//     companyOrders: [
//       {
//         id: 60,
//         partnerName: 'パートナー01',
//         costBillingDate: '2019-03-06',
//         costBillingMoney: 25000,
//       },
//       {
//         id: 61,
//         partnerName: 'パートナー01',
//         costBillingDate: '2019-03-06',
//         costBillingMoney: 25000,
//       },
//     ],
//   },
//   {
//     liquidation: {
//       id: 65,
//       completeDate: '2019-03-04',
//       customerName: '梅田　貞夫',
//       workSummary: '高圧洗浄',
//     },
//     companyOrders: [
//       {
//         id: 70,
//         partnerName: 'パートナー01',
//         costBillingDate: '2019-03-06',
//         costBillingMoney: 25000,
//       },
//       {
//         id: 71,
//         partnerName: 'パートナー01',
//         costBillingDate: '2019-03-06',
//         costBillingMoney: 25000,
//       },
//     ],
//   },
// ]

export default Description
