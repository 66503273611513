import HTML5Backend from 'react-dnd-html5-backend'
import TouchBackend from 'react-dnd-touch-backend'

/**
 * DragDropContextを提供するため、モバイル判定を行う
 */
const mobilePattern = /(Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone)/i

/**
 * [getBackendContext description]
 * @param  {string} [UA=navigator.userAgent] userAgent
 * @return {object}                          backend contexts
 */
const getBackendContext = (UA = navigator.userAgent) =>
  UA.match(mobilePattern) === null ? HTML5Backend : TouchBackend

export default getBackendContext
