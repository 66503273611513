import utils from 'src/lib/api-utils'

/**
 * DELETE stickyのアクションクリエーター
 * @param  {number} stickyId    [description]
 * @param  {string} accessToken [description]
 * @param  {object} env         env
 * @return {Promise}
 */
export const requestDeleteSticky = (stickyId, accessToken, env) => {
  const endpoint = `${utils.createEndpoint(env, 'sticky')}/${stickyId}`

  return fetch(endpoint, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      if (res.ok) {
        return res.json()
      } else {
        throw 'GETエラー'
      }
    })
    .then(id => id)
}

export default requestDeleteSticky
