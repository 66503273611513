import config from 'src/config'

export const createEndpoint = (env, type) => {
  const schema = env.API_HTTPS ? 'https' : 'http'
  return `${schema}://${env.API_HOST}/${config.API[type]?.dir}`
}

export const createImageEndpoint = (env, type) => {
  const schema = env.API_HTTPS ? 'https' : 'http'
  return `${schema}://${env.IMAGE_API_HOST}/${config.IMAGE_API[type].dir}`
}

export const createExpoEndpoint = (env, type) => {
  const schema = env.API_HTTPS ? 'https' : 'http'
  return `${schema}://${env.EXPO_API_HOST}/${config.EXPO_API[type].dir}`
}

export const createInventoryEndpoint = (env, type) => {
  const schema = env.API_HTTPS ? 'https' : 'http'
  return `${schema}://${env.INVENTORY_API_HOST}/${config.INVENTORY_API[type].dir}`
}

const wait = msec => new Promise(resolve => setTimeout(resolve, msec))

export const fluffyPromise = (request, lazy = 300) =>
  Promise.all([request, wait(lazy)]).then(data => data[0])

export default {
  createEndpoint,
  createImageEndpoint,
  createExpoEndpoint,
  createInventoryEndpoint,
  fluffyPromise,
}
