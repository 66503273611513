import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Container, FormsWrap, ContentWrap, Header } from '../../../styled'
import { ButtonLink } from '../styled'
import { Dl, Dt, Dd } from 'src/styled/list'
import PaperDescription from './partials/paper-description'
import BtnDemo from './partials/buttonDemo'
import ValidationErrorMessage from 'src/components/commons/validation-error-message'

// libs
import moment from 'moment'
import getDocuments from 'src/lib/documents-api/get-documents'
import getDocTypes from 'src/lib/documents-api/get-documenttypes'
import { makeCancelable, noop } from 'src/lib/cancelable-promise'
import { fluffyPromise as fluffy } from 'src/lib/api-utils'

export class SettlementManageApp extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    staffId: PropTypes.number.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    goBack: PropTypes.func.isRequired,
    // stateProps
    staffs: PropTypes.arrayOf(
      PropTypes.shape({
        staffId: PropTypes.number.isRequired,
        staffName: PropTypes.string.isRequired,
      }),
    ).isRequired,
    accessToken: PropTypes.string.isRequired,
    env: PropTypes.object.isRequired,
  }

  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    this.state = {
      status: 'not_yet',
      documents: [],
      docTypes: [],
      parsedDocs: {},
      onUnmount: noop,
      isSettled: false,
    }
  }

  /**
   * componentDidMount
   * @return {void}
   */
  componentDidMount() {
    this.fetchDocuments()
      .then(res => {
        if (res.ok) {
          return res.json()
        } else {
          throw new Error(res)
        }
      })
      .then(res => {
        this.setState({
          documents: res.documents || [],
          startDate: res.startDate,
          isSettled: res.isSettled,
        })
        this.getDocumentTypes()
          .then(res2 => {
            if (res2.ok) {
              return res2.json()
            } else {
              throw new Error(res2)
            }
          })
          .then(docTypes => {
            this.setState({ docTypes: docTypes, status: 'success' }, () => {
              this.parseDocuments()
            })
          })
          .catch(error => {
            console.error(error)
            this.setState({ status: 'failure', documents: [] })
          })
      })
      .catch(error => {
        console.error(error)
        this.setState({ status: 'failure', documents: [] })
      })
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.status !== nextState.status ||
      this.state.parsedDocs !== nextState.parsedDocs ||
      this.state.toggle !== nextState.toggle
    )
  }

  /**
   * componentWillUnmount
   * @return {void}
   */
  componentWillUnmount() {
    this.state.onUnmount()
  }

  updateDocuments = () => {
    this.fetchDocuments()
      .then(res => {
        if (res.ok) {
          return res.json()
        } else {
          throw new Error(res)
        }
      })
      .then(res => {
        this.setState({ documents: res.documents || [] }, () => {
          this.parseDocuments()
          this.setState({ status: 'success' })
        })
      })
      .catch(error => {
        console.error(error)
        this.setState({ status: 'failure', documents: [] })
      })
  }

  fetchDocuments = () => {
    const { staffId, from, to, accessToken, env } = this.props
    const { promise, cancel } = makeCancelable(
      getDocuments({ from, to }, staffId, accessToken, env),
    )
    this.setState({ status: 'requesting', onUnmount: cancel })
    return fluffy(promise)
  }

  getDocumentTypes = () => {
    const { accessToken, env } = this.props
    const { promise, cancel } = makeCancelable(getDocTypes(accessToken, env))
    this.setState({ status: 'requesting', onUnmount: cancel })
    return fluffy(promise)
  }

  parseDocuments = () => {
    const { documents, docTypes } = this.state
    let parsedDocs = {}
    docTypes.forEach(docType => {
      let matches = []
      documents.forEach(doc => {
        if (doc.documentType === docType.id) {
          matches = [
            ...matches,
            {
              documentId: doc.documentId,
              documentType: doc.documentType,
              numbers: doc.numbers,
            },
          ]
        }
      })
      parsedDocs = {
        ...parsedDocs,
        [docType.displayIndex]: {
          id: docType.id,
          displayIndex: docType.displayIndex,
          name: docType.name,
          documents: matches,
        },
      }
    })
    this.setState({ parsedDocs })
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { parsedDocs } = this.state
    if (!parsedDocs) {
      return null
    }

    const { from, to, staffId, staffs, goBack, accessToken, env } = this.props
    const { status, startDate, isSettled } = this.state

    const weekStart = moment(from).format('YYYY-MM-DD')
    const weekEnd = moment(to)
      .add(-1, 'day')
      .format('YYYY-MM-DD')

    const duration = weekStart + ' - ' + weekEnd
    const selectedProps = {
      accessToken,
      env,
      from,
      to,
      staffId,
      startDate,
      weekStart,
      weekEnd,
      updateDocuments: this.updateDocuments,
      isSettled,
    }

    const staff = staffs.find(staff => staff.staffId === staffId) || {}

    return (
      <Container>
        <Header title={ '伝票処理' } />

        <FormsWrap alignLeft>
          <ButtonLink onClick={ goBack }>{'精算処理一覧'}</ButtonLink>
        </FormsWrap>

        <ContentWrap>
          <Dl>
            <Dt>{'凡例'}</Dt>
            <Dd>
              <BtnDemo />
            </Dd>
          </Dl>
          <Dl>
            <Dt>{'精算期間'}</Dt>
            <Dd>{duration}</Dd>
          </Dl>
          <Dl>
            <Dt>{'担当者名'}</Dt>
            <Dd>{staff.staffVerboseName}</Dd>
          </Dl>
          {status === 'failure' ? (
            <ValidationErrorMessage message={ 'サーバーエラー' } />
          ) : status === 'success' ? (
            Object.keys(parsedDocs).map(key => (
              <PaperDescription
                key={ parsedDocs[key].displayIndex }
                documentType={ parsedDocs[key].id }
                name={ parsedDocs[key].name }
                documents={ parsedDocs[key].documents }
                { ...selectedProps }
              />
            ))
          ) : null}
        </ContentWrap>
      </Container>
    )
  }
}

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
const mapStateToProps = state => {
  return {
    staffs: state.master.data.staffs,
    accessToken: state.login.authentication.accessToken,
    env: state.env,
  }
}

export default connect(mapStateToProps)(SettlementManageApp)
