import React from 'react'
import { ContractButton } from './styled'

class BtnDemo extends React.PureComponent {
  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    this.state = {
      labels: {
        1: '無効',
        2: '前々週提出済',
        3: '前週提出済',
        4: '未使用',
        5: '今週提出済',
        6: '未提出',
        7: '紛失',
      },
      currentStatus: 1,
    }
  }

  // onToggleClick = () => {
  //   const { currentStatus } = this.state
  //   const nextStatus = currentStatus === 7 ? 1 : currentStatus + 1
  //   this.setState({ currentStatus: nextStatus })
  // }

  render() {
    let { labels, currentStatus } = this.state

    return (
      <div className={ 'label-inputs-wrap ' }>
        {Object.keys(labels).map(key => {
          currentStatus = Number(key)
          return (
            <ContractButton
              key={ key }
              status={ currentStatus }
              style={ {
                minWidth: '80px',
                width: '100%',
                paddingLeft: '10px',
                paddingRight: '10px',
              } }
              value={ currentStatus }
            >
              {labels[key]}
            </ContractButton>
          )
        })}
      </div>
    )
  }
}

export default BtnDemo
