import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Screen,
  ScreenHeader,
  DeleteButton,
  ContractButton,
  ContractWrap,
  NoContents,
  DocumentsTitle,
} from './styled'
import AddButton from 'src/styled/button/ghost'
import UpdateStatusesButton from 'src/styled/button/ghost'
import NumberInput from './input'
import StatusMessage from './statusMessage'

import { makeCancelable, noop } from 'src/lib/cancelable-promise'
import { fluffyPromise as fluffy } from 'src/lib/api-utils'
import moment from 'moment'
import postDocuments from 'src/lib/documents-api/post-documents'
import deleteDocuments from 'src/lib/documents-api/delete-documents'
import updateStatuses from 'src/lib/documents-api/put-documentStatuses'

export class PaperDescription extends React.Component {
  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    this.state = {
      status: 'success',
      cardSubmitDispId: 0,
      cardSubmitDate: '',
      nextDocuments: {},
      actualStartNumber: 0,
      errorMessage: '',
      onUnmount: noop,
      toggle: {},
    }
  }

  /**
   * componentWillUnmount
   * @return {void}
   */
  componentWillUnmount() {
    this.state.onUnmount()
  }

  addDocs = () => {
    const {
      state: { actualStartNumber },
      props: { staffId, from, documentType, accessToken, env },
    } = this
    if (actualStartNumber === 0) {
      this.setState({
        status: 'failure',
        errorMessage: '番号を入力してください。',
      })
      return
    }

    const { promise, cancel } = makeCancelable(
      postDocuments(
        { staffId, documentType, actualStartNumber, startDate: from },
        accessToken,
        env,
      ),
    )

    this.setState({ status: 'requesting', onUnmount: cancel })

    fluffy(promise)
      .then(res => {
        if (res.ok) {
          return res
        } else {
          throw res
        }
      })
      .then(() => {
        this.setState({ status: 'success' }, () => {
          this.props.updateDocuments()
        })
      })
      .catch(error => {
        console.error(error)
        this.setState({
          status: 'failure',
          errorMessage:
            error.status === 409
              ? '指定した番号は割り当て済みです。'
              : 'サーバーエラー',
        })
      })
  }

  onChange = actualStartNumber =>
    this.setState({ actualStartNumber, status: 'success' })

  deleteDocumentBundle = e => {
    this.setState({ status: 'requesting' })
    const { accessToken, env } = this.props

    const docId = e.target.value

    const { promise, cancel } = makeCancelable(
      deleteDocuments(docId, accessToken, env),
    )

    this.setState({ status: 'requesting', onUnmount: cancel })

    fluffy(promise)
      .then(res => {
        if (res.ok) {
          return res
        } else {
          throw res
        }
      })
      .then(() => {
        this.setState({ status: 'success' }, () => {
          this.props.updateDocuments()
        })
      })
      .catch(error => {
        console.error(error)
        this.setState({
          status: 'failure',
          errorMessage:
            error.status === 409
              ? '台帳の束の中に提出済み、未提出、紛失、先週以前提出済みがあります。'
              : 'サーバーエラー',
        })
      })
  }

  updateStatuses = () => {
    const {
      props: { accessToken, env, staffId, from, to, documentType },
      state: { nextDocuments },
    } = this
    const nextStatuses = {
      staffId: staffId,
      startDate: from,
      endDate: to,
      documents: Object.keys(nextDocuments).map(documentId => ({
        documentId: Number(documentId),
        documentType,
        numbers: Object.keys(nextDocuments[documentId]).map(documentNumber => ({
          documentNumber: Number(documentNumber),
          documentStatus: nextDocuments[documentId][documentNumber],
          submitDate: from,
        })),
      })),
    }

    const { promise, cancel } = makeCancelable(
      updateStatuses(accessToken, env, nextStatuses),
    )

    this.setState({ status: 'requesting', onUnmount: cancel })

    fluffy(promise)
      .then(res => {
        if (res.ok) {
          return res
        } else {
          throw res
        }
      })
      .then(() => {
        this.setState({ status: 'display_success' })
      })
      .catch(error => {
        console.error(error)
        this.setState({
          status: 'failure',
          errorMessage:
            error.status === 400 ? '更新できませんでした。' : 'サーバーエラー',
        })
      })
  }

  onHover = e => {
    const { documentId, index, bundleIndex } = JSON.parse(e.target.value)
    const { documentStatus, submitDate } = this.props.documents[
      bundleIndex
    ].numbers[index]
    this.setState({
      cardSubmitDispId: documentId,
      cardSubmitDate:
        documentStatus === 7
          ? `${moment(submitDate).format('YYYY/MM/DD')}に紛失した伝票です`
          : documentStatus === 5
            ? `${moment(submitDate).format('YYYY/MM/DD')}に提出した伝票です`
            : [2, 3].includes(documentStatus)
              ? `${moment(submitDate).format(
                'YYYY/MM/DD',
              )}に提出した伝票です[確定](押せません)`
              : '',
    })
  }

  onToggleClick = e => {
    this.setState({ status: 'success' })
    let { documentNumber, documentId, index, bundleIndex } = JSON.parse(
      e.target.value,
    )

    // ステータスのサイクルは 6: 未提出 →　4: '未使用' → 5: '今週提出済' → 7: '紛失' → 4: '未使用'
    const toggles = [4, 5, 7]
    const { documentStatus } = this.props.documents[bundleIndex].numbers[index]
    const currentToggle = this.state.toggle[documentNumber] || documentStatus
    let nextToggle = documentStatus
    if (toggles.includes(currentToggle)) {
      const nextIndex = (toggles.indexOf(currentToggle) + 1) % toggles.length
      nextToggle = toggles[nextIndex]
    } else if (currentToggle === 6) {
      nextToggle = 4
    } else {
      return
    }

    this.setState({
      toggle: { ...this.state.toggle, [documentNumber]: nextToggle },
      nextDocuments: {
        ...this.state.nextDocuments,
        [documentId]: {
          ...this.state.nextDocuments[documentId],
          [documentNumber]: nextToggle,
        },
      },
    })
  }

  onToggleClick_none = () => {}

  render() {
    const {
      props: { name, documents, documentType, isSettled },
      state: {
        status,
        errorMessage,
        actualStartNumber,
        cardSubmitDispId,
        cardSubmitDate,
        toggle,
      },
    } = this
    const disabled = isSettled // 精算済みの時はボタンを無効にする
    const requesting = status === 'requesting' // 通信中もボタンを無効にする
    let idx = 0 // ボタンの通し番号
    return (
      <Screen disabled={ status !== 'requesting' }>
        <ScreenHeader>
          <DocumentsTitle>{name}</DocumentsTitle>
          <div>
            <AddButton onClick={ this.addDocs } disabled={ disabled || requesting }>
              {'+ 追加'}
            </AddButton>
            <NumberInput
              id={ `input${documentType}` }
              onChange={ this.onChange }
              value={ actualStartNumber }
              disabled={ disabled }
            />
            <UpdateStatusesButton
              onClick={ this.updateStatuses }
              disabled={ disabled || requesting }
            >
              {'一括更新'}
            </UpdateStatusesButton>
          </div>
        </ScreenHeader>
        {status === 'failure' ? (
          <StatusMessage status={ status } message={ errorMessage } />
        ) : status === 'display_success' ? (
          <StatusMessage status={ status } message={ '更新しました。' } />
        ) : status === 'requesting' ? (
          <i className={ 'fa fa-spinner fa-pulse fa-fw' } />
        ) : null}
        <ContractWrap>
          {documents[0] ? (
            documents.map(({ documentId, numbers }, bundleIndex) => {
              // ボタンの並びを作成
              // 無効にする時disabledではボタンが見辛くなるので、イベントハンドラを無効にする
              const buttons = numbers.map(
                ({ documentNumber, documentStatus }, index) => {
                  const thisStatus =
                    toggle[documentNumber] ||
                    (documentStatus ? documentStatus : 4)
                  return (
                    <Fragment key={ documentNumber }>
                      <ContractButton
                        key={ documentNumber }
                        value={ JSON.stringify({
                          documentNumber,
                          bundleIndex,
                          index,
                          documentId,
                        }) }
                        status={ thisStatus }
                        documentType={ documentType }
                        onClick={
                          disabled
                            ? this.onToggleClick_none
                            : this.onToggleClick
                        }
                        onMouseOver={ this.onHover }
                      >
                        {documentNumber}
                      </ContractButton>
                      {numbers.length - 1 === index ? (
                        <DeleteButton
                          onClick={ this.deleteDocumentBundle }
                          value={ documentId }
                          disabled={ disabled || requesting }
                        >
                          {`${numbers[0].documentNumber} - ${numbers[index].documentNumber}を削除`}
                        </DeleteButton>
                      ) : null}
                      {++idx % 10 === 0 ? <br /> : null}
                    </Fragment>
                  )
                },
              )
              // ボタンのグループを表示
              return (
                <div key={ `buttons_${documentId}` }>
                  <div
                    id={ `update_${documentId}` }
                    style={ { marginBottom: '4px' } }
                  >
                    {cardSubmitDate && cardSubmitDispId === documentId ? (
                      <span>{cardSubmitDate}</span>
                    ) : (
                      <span>&nbsp;</span>
                    )}
                  </div>
                  {buttons}
                </div>
              )
            })
          ) : (
            <NoContents>{'台帳の束が登録されていません。'}</NoContents>
          )}
        </ContractWrap>
      </Screen>
    )
  }
}

PaperDescription.propTypes = {
  name: PropTypes.string.isRequired,
  documents: PropTypes.array.isRequired,
  staffId: PropTypes.number.isRequired,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  documentType: PropTypes.number.isRequired,
  updateDocuments: PropTypes.func.isRequired,
  isSettled: PropTypes.bool.isRequired,
  // stateProps
  accessToken: PropTypes.string.isRequired,
  env: PropTypes.object.isRequired,
}

export default PaperDescription

// ===================================================================
// ローカルテスト用データ: this.props.documents を置き換えて使用
// const testDocuments = [
//   {
//     documentId: 177,
//     documentType: 2,
//     numbers: [
//       {
//         documentNumber: 1,
//         documentStatus: 4,
//         submitDate: '2020-03-01',
//         createAt: '2020-03-30T10:26:20.007+09:00',
//         createUserId: 'ope1',
//         updateAt: '2020-03-30T10:26:20.007+09:00',
//         updateUserId: 'ope1',
//       },
//     ],
//   },
// ]
