import { connect } from 'react-redux'
import { createAsyncCityCodeUpdateAction } from 'src/reducers/master'

/**
 map state to props
 * @param  {object} state mapping state
 * @return {object}       mapped props
 */
const mapStateToProps = state => {
  return {
    masterWorkPoints: state.master.data.workPoints,
    masterWorkTypes: state.master.data.workTypes,
    masterPrefCodes: state.master.data.prefCodes,
    masterCompanies: state.master.data.companies,
    cityCodes: state.master.cityCodes,
    accessToken: state.login.authentication.accessToken,
    env: state.env,
    isDebugMode: state.env.DEBUG,
    staffs: state.master.data.staffs,
  }
}

/**
 * map dispatch to props
 * @param  {function} dispatch dispatcher
 * @return {object}               mapped dispatcher as props
 */
const mapDisptchToProps = dispatch => {
  return {
    __getCityCodes: env => (prefCode, accessToken) =>
      dispatch(createAsyncCityCodeUpdateAction(prefCode, accessToken, env)),
  }
}

/**
 * map state to props
 * @param  {object} stateProps    state props
 * @param  {object} dispatchProps dispatch props
 * @param  {object} ownProps      own props
 * @return {object}               props
 */
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    // merge
    getCityCodes: dispatchProps.__getCityCodes(stateProps.env),
    // make private
    __getCityCodes: void 0,
    __env: void 0,
  }
}

export default StickyAbbr =>
  connect(mapStateToProps, mapDisptchToProps, mergeProps)(StickyAbbr)
