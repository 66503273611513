import config from 'src/config'
import switz from 'switz'

import normalize from './lib/normalize'
import findClient from './lib/find-client'
import isValidBeforePost from './lib/validate/before-post'
import { isValidSticky as isValidStickyBeforePut } from './lib/validate/before-put'
import transformBeforePost from './lib/transform/before-post'
import transformBeforePut from './lib/transform/before-put'
import transformBeforeGenerateRepeat from './lib/transform/before-generate-repeat'
import transformBeforeGenerateReRequest from './lib/transform/before-generate-rerequest'
import transformBeforeGenerateMaruc from './lib/transform/before-generate-maruc'
import transformBeforeGeneratePrivate from './lib/transform/before-generate-private'
import transformBeforeGenerateMemo from './lib/transform/before-generate-memo'
import stickyMerge from './lib/sticky-merge'
import pickStickyContactIds from './lib/pick-sticky-contact-ids'
import listPayers from './lib/list-payers'
import castOff from './lib/cast-off'
import {
  stickyEsmile,
  stickyHouseLabo,
  stickyQireie,
  stickyServe,
  stickyPhoneCall,
  stickyRed,
} from 'src/colors'

const defaultOptions = { normalize: false }

const BEFORE_POST = Symbol('before post')
const BEFORE_PUT = Symbol('before put')
const BEFORE_GENERATE_REPEAT = Symbol('brfore generate repeat')
const BEFORE_GENERATE_RESUME = Symbol('before generate resume')
const BEFORE_GENERATE_REREQUEST = Symbol('before generate rerequest')
const BEFORE_GENERATE_MARUC = Symbol('before generate maruc')
const BEFORE_GENERATE_PRIVATE = Symbol('before generate private')
const BEFORE_GENERATE_MEMO = Symbol('before generate memo')

const companyColors = {
  [config.company.ES]: stickyEsmile,
  [config.company.HL]: stickyHouseLabo,
  [config.company.QI]: stickyQireie,
  [config.company.SV]: stickyServe,
  [config.company.EC]: stickyQireie,
}

/**
 * 個別の付箋データを扱うクラス
 * NOTE: reducerの更新の際にはnormalizeされるような方法でこのクラスを使ってはいけない
 * contact や wishDateTimeのidが無いと、正規化過程でそれらが削除されてしまう
 */
class Sticky {
  /**
   * バリデーションのパターン特定用定数
   * @type {Object}
   */
  static VALIDATION_PATTERN = {
    BEFORE_POST,
    BEFORE_PUT,
  }

  /**
   * 付箋変形のパターン特定用定数
   * @type {Object}
   */
  static TRANSFORM_PATTERN = {
    BEFORE_POST,
    BEFORE_PUT,
    BEFORE_GENERATE_REPEAT,
    BEFORE_GENERATE_RESUME,
    BEFORE_GENERATE_REREQUEST,
    BEFORE_GENERATE_MARUC,
    BEFORE_GENERATE_PRIVATE,
    BEFORE_GENERATE_MEMO,
  }

  /**
   * 受付会社ごとの背景色
   * @param {*} companyId
   * @returns
   */
  static backGroundColor = companyId => companyColors[companyId]

  /**
   * [constructor description]
   * @param  {object} stickyProps sticky propsd
   * @param  {Object} [opts={}]   オプション
   */
  constructor(stickyProps, opts = {}) {
    if (typeof stickyProps !== 'object') {
      throw new Error(
        `不正なコンストラクタ引数 \`arguments[0]: ${stickyProps}\` です。Stickyクラスの第1引数はオブジェクト型である必要があります。`,
      )
    }
    const option = { ...defaultOptions, ...opts }
    this._props = { ...stickyProps }
    if (option.normalize) {
      this.normalize()
    }
    this._opts = opts
  }

  /**
   * idを取得する getter
   * @return {string} 付箋のid
   */
  get id() {
    return this._props.id
  }

  /**
   * ES案件かどうか
   * @return {boolean} 判定値
   */
  get isES() {
    return this._props.companyId === config.company.ES
  }

  /**
   * HL案件かどうか
   * @return {boolean} 判定値
   */
  get isHL() {
    return this._props.companyId === config.company.HL
  }

  /**
   * キャンセル付箋かどうか
   * @return {boolean} 判定値
   */
  get isCanceled() {
    return this._props.finishStateId === config.finishState.cancel
  }

  /**
   * 保留付箋かどうか
   * @return {boolean} 判定値
   */
  get isSuspended() {
    return ((this._props.suspendReason || {}).ids || []).includes(
      config.suspendReason.suspend1,
    )
  }

  /**
   * マルシー注文な付箋かどうか
   * @return {boolean} 判定値
   */
  get isClaimerOrder() {
    return this._props.orderTypeId === config.orderType.claimer
  }

  /**
   * マルシー注文な付箋かどうか
   * @return {boolean} 判定値
   */
  get isRepeatOrder() {
    return this._props.orderTypeId === config.orderType.repeat
  }

  get clientLocation() {
    const { contacts } = this._props
    if (!contacts) {
      return { tabIndex: void 0, contactsIndex: void 0 }
    }
    return findClient(contacts, true)
  }

  get stickyContactIds() {
    const { contacts = [] } = this._props
    return pickStickyContactIds(contacts, void 0, { pickDeleted: true })
  }

  get payerIds() {
    const { contacts = [] } = this._props
    return pickStickyContactIds(contacts, contact => contact.isPayer)
  }

  /**
   * プロパティを正規化する
   * @return {[type]} [description]
   */
  normalize = () => {
    this._props = normalize(this._props)
  }

  /**
   * 付箋を削除状態にするメソッド
   * @return {Sticky} [description]
   */
  delete = () => {
    const newStickyProps = {
      ...this._props,
      stickyStatusId: config.stickyStatus.deleted,
    }
    return new Sticky(newStickyProps)
  }

  /**
   * この関数は、内部的のプロパティを更新し、同時に新しい付箋インスタンスを生成します
   * @param  {Sticky|object} sticky [description]
   * @return {Sticky}                this
   */
  update = (sticky, option = {}) => {
    const newStickyProps = sticky instanceof Sticky ? sticky.json() : sticky
    const appliedProps = Object.keys(newStickyProps).reduce((prev, key) => {
      const value = newStickyProps[key]
      prev[key] =
        typeof value === 'function'
          ? value(this._props[key], this._props)
          : value
      return prev
    }, {})

    const nextProps = option.shallow
      ? { ...this._props, ...appliedProps }
      : stickyMerge(this._props, appliedProps, option)
    return new Sticky(nextProps, option)
  }

  /**
   * updateのエイリアス
   * @type {function}
   */
  merge = this.update

  /**
   * 複製する
   * @return {Sticky} [description]
   */
  clone = () => this.update({})

  /**
   * key:value で指定したプロパティを除外した新しい付箋を返す
   * @param  {object} obj 削除するプロパティをキーにした値をtrueで指定する { some: true } でsomeプロパティを削除
   * @return {Sticky}     新しいStickyインスタンス
   */
  remove = (obj, option) =>
    new Sticky(
      Object.keys(this._props).reduce(
        (prev, key) => (obj[key] ? prev : { ...prev, [key]: this._props[key] }),
        {},
      ),
      option,
    )

  /**
   * [json description]
   * @return {object} 付箋の元になっているオブジェクトを返す
   */
  json = () => this._props

  /**
   * JSON.stringifyの糖衣構文
   * @param  {[type]} args [description]
   * @return {string}      [description]
   */
  toJSONString = (...args) => JSON.stringify(...[this._props, ...args])

  /**
   * 依頼者オブジェクトを探す
   * @return {object|false} 依頼者オブジェクト
   */
  findClient = () => findClient(this._props.contacts)

  /**
   * 支払者リストを作成する
   * @return {{id: number, name: string}} [description]
   */
  listPayers = () => listPayers(this._props.contacts)

  /**
   * インデックスを指定して連絡先を取得するショートハンドメソッド
   * @param  {number} tabIndex     tabIndex
   * @param  {number} contactIndex contactsIndex
   * @return {object}              contact
   */
  getContactByIndex = (tabIndex, contactIndex) =>
    this._props.contacts[tabIndex][contactIndex]

  getContactById = id =>
    this._props.contacts
      .filter(x => !!x)
      .map(tabContacts => tabContacts.filter(x => !!x))
      .reduce((prev, tabContacts) => [...prev, ...tabContacts], [])
      .find(x => x.id === id)

  /**
   * バリデーション
   * @param  {symbol}  mode [description]
   * @return {boolean}      [description]
   */
  isValid = mode => {
    return switz(mode, s =>
      s
        .case(BEFORE_POST, () => isValidBeforePost(this._props))
        .case(BEFORE_PUT, () => isValidStickyBeforePut(this._props))
        .default(() => ({
          result: false,
          messages: ['不明なバリデーションパターンです'],
        })),
    )
  }

  castOff = () => castOff(this._props)

  /**
   * 固有の値変換を行うラッパーメソッド
   * @param  {props} props [description]
   * @return {Sticky}      [description]
   */
  transform = props => {
    const { pattern } = props
    return switz(pattern, s =>
      s
        .case(BEFORE_POST, () => {
          const sticky = this
          const { type, isAfterCreation } = props
          return transformBeforePost(sticky, type, isAfterCreation)
        })
        .case(BEFORE_PUT, () => {
          // TODO: これは、リファクタリングしたい。sticky = this でいいはずだが..
          const { sticky } = props
          return transformBeforePut(sticky)
        })
        .case(BEFORE_GENERATE_REPEAT, () => {
          const { repeatProps } = props
          return transformBeforeGenerateRepeat(this, repeatProps, false)
        })
        .case(BEFORE_GENERATE_RESUME, () => {
          const { repeatProps } = props
          return transformBeforeGenerateRepeat(this, repeatProps, true)
        })
        .case(BEFORE_GENERATE_REREQUEST, () =>
          transformBeforeGenerateReRequest(this),
        )
        .case(BEFORE_GENERATE_MARUC, () => transformBeforeGenerateMaruc(this))
        .case(BEFORE_GENERATE_PRIVATE, () =>
          transformBeforeGeneratePrivate(this),
        )
        .case(BEFORE_GENERATE_MEMO, () => transformBeforeGenerateMemo(this))
        .default(() => this),
    )
  }

  /**
   * [getUserValue description]
   * @param  {string} propName [description]
   * @return {any}          [description]
   */
  getUserValue = (propName, options = {}) => {
    let userField
    if (this._props.userField === void 0) {
      userField = {}
    } else {
      try {
        userField = JSON.parse(this._props.userField) || {}
      } catch (e) {
        options.quiet ||
          // eslint-disable-next-line no-console
          console.warn('ユーザーフィールドが不正です: ' + this._props.userField)
        userField = {}
      }
    }
    return userField[propName]
  }

  /**
   * [setUserValue description]
   * @param {string} propName  [description]
   * @param {Sticky} propValue [description]
   * @return Sticky
   */
  setUserValue = (propName, propValue) => {
    let userField
    if (this._props.userField === void 0) {
      userField = {}
    } else {
      try {
        userField = JSON.parse(this._props.userField)
      } catch (e) {
        console.warn('ユーザーフィールドが不正です: ' + this._props.userField)
        userField = {}
      }
    }

    const nextUserField = JSON.stringify({
      ...userField,
      [propName]: propValue,
    })

    return this.clone().update(
      { userField: nextUserField },
      { normalize: false, shallow: true },
    )
  }

  getContactId = (tabIndex, contactsIndex) => {
    let result = null
    try {
      result = this._props.contacts[tabIndex][contactsIndex].id
    } catch (e) {
      result = null
    }
    return result
  }

  /**
   * contactsに「依頼者」が設定されている時trueを返す
   * 「依頼者」が消えることもありうるようになった
   */
  existsClient = () => {
    const contacts = this._props.contacts || []
    for (let i = 0; i < contacts.length; i++) {
      const tabContacts = contacts[i] || []
      for (let j = 0; j < tabContacts.length; j++) {
        const contact = tabContacts[j]
        if (!contact.deleted && !contact.moved && contact.isClient) {
          return true
        }
      }
    }
    return false
  }

  /**
   * @returns
   */

  /**
   * 背景色
   *
   * @param {*} full: trueの時受付会社以外の条件も色の判定に加える。
   * @returns
   */
  stickyBackGroundColor = (full = true) => {
    if (full) {
      // 入電あるまで手配不要
      const leaveItUntilPhoneCall = !!this.getUserValue(
        'leave-me-until-phone-call',
      )
      // 受注不可・受注注意
      const handOverIds = (this._props.handOver || {}).ids || []
      const hasHandOver =
        handOverIds.includes(config.handOverStatuses.reject) ||
        handOverIds.includes(config.handOverStatuses.other)
      //
      if (leaveItUntilPhoneCall) {
        return stickyPhoneCall
      } else if (hasHandOver) {
        return stickyRed
      }
    }
    // 受付会社によって色を変更する
    const companyId = this._props.companyId
    return Sticky.backGroundColor(companyId)
  }

  log = () => console.log(this.toJSONString())
}

export default Sticky
