/**
 * NoramFormの背景コンポーネント
 * 付箋の状態に応じて背景色を変えて表示する。
 */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

export const mapStateToProps = state => {
  return {
    master: state.master.data,
  }
}

const Background = props => {
  const { sticky } = props

  const backgroundColor = sticky.stickyBackGroundColor()
  const style = { backgroundColor }

  return <div style={ style }>{props.children}</div>
}

Background.propTypes = {
  sticky: PropTypes.object.isRequired,
  children: PropTypes.array.isRequired,
  // stateProps
  master: PropTypes.shape({
    handOver: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
}

export default connect(mapStateToProps)(Background)
