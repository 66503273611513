import React from 'react'
import { Link } from 'react-router-dom'

/**
 * パンくずリストコンポーネント
 * @type {function} コンポーネント
 */
export default class Breadcrumbs extends React.Component {
  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate() {
    return false
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    return (
      <nav className={ 'navigation side-navigation' }>
        <ul className={ 'breadcrumbs' }>
          <li className={ 'breadcrumb-item breadcrumb-item-home' }>
            <Link to={ '/' }>{'ホーム'}</Link>
          </li>
          <li className={ 'breadcrumb-item' }>
            <Link to={ '/assign' }>{'案件ダッシュボード'}</Link>
          </li>
        </ul>
      </nav>
    )
  }
}
