import update from 'immutability-helper'
import Sticky from 'src/lib/class-sticky'

/**
 * タイプ定義
 * @type {string}
 */
export const type = 'WARA.STAGE_STICKY'

/**
 * exportation
 */
export default {
  /**
   * action type
   * @type {string}
   */
  type,

  /**
   * action creator
   * @param  {Prop}  sticky 更新する付箋のパラメータです
   * @return {object} [description]
   */
  creator: (sticky, shallow = false) => {
    return {
      type,
      payload: { sticky, shallow },
    }
  },

  /**
   * [handler description]
   * @param  {object}  state   [description]
   * @param  {object} action  [description]
   * @return {object}          [description]
   */
  handler: (state, { payload }) => {
    // falseを渡したときに消す
    if (payload.sticky === false) {
      return update(state, { modal: { stage: { $set: new Sticky({}) } } })
    }
    const stage = payload.shallow
      ? payload.sticky.clone()
      : state.modal.stage.merge(payload.sticky)
    return update(state, { modal: { stage: { $set: stage } } })
  },
}
