import { useState, useEffect } from 'react'
// lib
import utils from 'src/lib/api-utils'
// config
import config from 'src/config'

export const useCancelStatus = (parentId, accessToken, env) => {
  const [isCancelled, setIsCancelled] = useState(false)
  const [isSuspended, setIsSuspended] = useState(false)

  useEffect(() => {
    const fetchStatus = async () => {
      if (parentId === -1) return

      const endpoint = `${utils.createEndpoint(env, 'search')}/${parentId}`
      try {
        const res = await fetch(endpoint, {
          method: 'GET',
          headers: { Authorization: `Bearer ${accessToken}` },
        })

        if (!res.ok) {
          throw new Error('GETエラー')
        }

        const result = await res.json()

        const cancelled = result.finishStateId === config.finishState.cancel
        const suspended = ((result.suspendReason || {}).ids || []).includes(
          config.suspendReason.suspend1,
        )

        setIsCancelled(cancelled)
        setIsSuspended(suspended)
      } catch (error) {
        console.error('Error fetching status:', error)
      }
    }

    fetchStatus()
  }, [parentId, accessToken, env])

  return { isCancelled, isSuspended }
}
