import update from 'immutability-helper'

/**
 * タイプ定義
 * @type {string}
 */
const type = 'WARA.ASSIGN_STICKY'

/**
 * exportation
 */
export default {
  /**
   * action type
   * @type {string}
   */
  type,

  /**
   * action creator
   * @param  {{ regionIndex: number, listIndex: number, stickyIndex: number }} locationSrc  [description]
   * @param  {{ regionIndex: number, listIndex: number }}                      locationDest [description]
   * @param  {string} displayDate YYYY-MM-DD
   * @return {object} created action
   */
  creator: (locationSrc, locationDest, displayDate) => {
    return {
      type,
      payload: {
        locationSrc,
        locationDest,
        displayDate,
      },
    }
  },

  /**
   * 関数本体
   * @param  {object} state    Immutableなstateです。このオブジェクトを変更すべきではありません。
   * @param  {object} payload 関数に与える引数です。
   * @return {object}          適切にUpdateされた新しいStateを返却します。
   */
  handler: (
    state,
    {
      payload: {
        locationSrc: {
          regionIndex: regionIndexSrc,
          listIndex: listIndexSrc,
          stickyIndex: stickyIndexSrc,
        },
        locationDest: {
          regionIndex: regionIndexDest,
          listIndex: listIndexDest,
        },
        displayDate,
      },
    },
  ) => {
    if (regionIndexSrc === -1 || listIndexSrc === -1) {
      // dock -> regions
      const stickySrc = state.data.dock.stickies[stickyIndexSrc]
      const regionDest = state.data.regions[regionIndexDest]
      const listDest = regionDest.lists[listIndexDest]

      const nextSticky = stickySrc.update({
        regionId: regionDest.id,
        staffId: listDest.staffId,
        displayOrder: listDest.stickies.length,
        displayDate,
        determinedDateTime: {
          ...stickySrc.determinedDateTime,
          date: displayDate,
        },
      })

      // 未配置付箋の一覧から対象の付箋を除去する。インデックスによるspliceからIDでフィルタするように変更。
      const newDocStickies = (state.data.dock.stickies || []).filter(
        sticky => sticky.id !== stickySrc.id,
      )

      return update(state, {
        data: {
          dock: { stickies: { $set: newDocStickies } },
          regions: {
            [regionIndexDest]: {
              lists: {
                [listIndexDest]: { stickies: { $push: [nextSticky] } },
              },
            },
          },
        },
      })
    } else if (regionIndexDest === -1 || listIndexDest === -1) {
      // docke <- regions
      const stickySrc =
        state.data.regions[regionIndexSrc].lists[listIndexSrc].stickies[
          stickyIndexSrc
        ]
      const nextSticky = stickySrc.update({
        regionId: -1,
        listId: -1,
      })

      return update(state, {
        data: {
          dock: { stickies: { $push: [nextSticky] } },
          regions: {
            [regionIndexSrc]: {
              lists: {
                [listIndexSrc]: {
                  stickies: { $splice: [[stickyIndexSrc, 1]] },
                },
              },
            },
          },
        },
      })
    } else if (regionIndexSrc === regionIndexDest) {
      // セクション内での移動
      const stickySrc =
        state.data.regions[regionIndexSrc].lists[listIndexSrc].stickies[
          stickyIndexSrc
        ]
      const listDest = state.data.regions[regionIndexDest].lists[listIndexDest]
      const nextSticky = stickySrc.update({
        staffId: listDest.staffId,
        displayOrder: listDest.stickies.length,
        displayDate,
        determinedDateTime: {
          ...stickySrc.determinedDateTime,
          date: displayDate,
        },
      })

      return update(state, {
        data: {
          regions: {
            [regionIndexSrc]: {
              lists: {
                [listIndexSrc]: {
                  stickies: { $splice: [[stickyIndexSrc, 1]] },
                },
                [listIndexDest]: { stickies: { $push: [nextSticky] } },
              },
            },
          },
        },
      })
    } else {
      // regions間の移動を実行
      const stickySrc =
        state.data.regions[regionIndexSrc].lists[listIndexSrc].stickies[
          stickyIndexSrc
        ]
      const regionDest = state.data.regions[regionIndexDest]
      const listDest = state.data.regions[regionIndexDest].lists[listIndexDest]
      const nextSticky = stickySrc.update({
        regionId: regionDest.id,
        staffId: listDest.staffId,
        displayOrder: listDest.stickies.length,
        displayDate,
        determinedDateTime: {
          ...stickySrc.determinedDateTime,
          date: displayDate,
        },
      })

      return update(state, {
        data: {
          regions: {
            [regionIndexSrc]: {
              lists: {
                [listIndexSrc]: {
                  stickies: { $splice: [[stickyIndexSrc, 1]] },
                },
              },
            },
            [regionIndexDest]: {
              lists: { [listIndexDest]: { stickies: { $push: [nextSticky] } } },
            },
          },
        },
      })
    }
  },
}
