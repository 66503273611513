// react
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// libs
import createClassNames from 'classnames'
import Sticky from 'src/lib/class-sticky'

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
const mapStateToProps = state => {
  return {
    isDebugMode: state.env.DEBUG,
  }
}

export const PutModeButtons = props => {
  const {
    // ownProps
    sticky,
    putDisabled,
    resetDisabled,
    onPutClick,
    onResetClick,
    divClassName,
    // stateProps
    isDebugMode,
  } = props

  const putClassNames = createClassNames({
    button: true,
    'button-create': true,
    'button-icon-add': true,
    'button-create-sticky': true,
    'create-sticky-form-wrap_button': true,
    'button-disabled': putDisabled,
  })
  const resetClassNames = createClassNames({
    button: true,
    'button-icon-reset': true,
    'create-sticky-form-wrap_button': true,
    'button-disabled': resetDisabled,
  })

  return (
    <div className={ divClassName }>
      <button
        className={ putClassNames }
        disabled={ putDisabled }
        onClick={ onPutClick }
      >
        {'案件を更新'}
      </button>
      <button
        className={ resetClassNames }
        disabled={ resetDisabled }
        onClick={ onResetClick }
      >
        {'新たに案件を作成する'}
      </button>
      {isDebugMode && (
        <button
          className={ 'button button-debug' }
          /* eslint-disable react/jsx-no-bind */
          onClick={
            /* eslint-disable no-console */
            () => console.log(sticky.json(), sticky.toJSONString())

            /* eslint-enable no-console */
          }
          /* eslint-enable react/jsx-no-bind */
        >
          {'dump'}
        </button>
      )}
    </div>
  )
}

/**
 * propTypes
 * @type {object}
 */
PutModeButtons.propTypes = {
  // ownProps
  sticky: PropTypes.instanceOf(Sticky).isRequired,
  putDisabled: PropTypes.bool.isRequired,
  resetDisabled: PropTypes.bool.isRequired,
  onPutClick: PropTypes.func.isRequired,
  onResetClick: PropTypes.func.isRequired,
  divClassName: PropTypes.string.isRequired,
  // stateProps
  isDebugMode: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps)(PutModeButtons)
