import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Checkbox from 'src/styled/form/checkbox'
import putSettlement from 'src/lib/settlement-api/put-settlement'
import { makeCancelable, noop } from 'src/lib/cancelable-promise'
import { fluffyPromise as fluffy } from 'src/lib/api-utils'

export class Settle extends React.PureComponent {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    settlement: PropTypes.shape({
      isSettled: PropTypes.bool.isRequired,
    }).isRequired,
    query: PropTypes.shape({
      staffId: PropTypes.number.isRequired,
      from: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    }).isRequired,
    // stateProps
    accessToken: PropTypes.string.isRequired,
    env: PropTypes.object.isRequired,
    setCheckedStatus: PropTypes.func.isRequired,
  }

  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    this.state = {
      settled: !!props.settlement.isSettled,
      status: 'not_yet',
      cancel: noop,
    }
  }

  /**
   * componentWillUnmount
   * @return {void}
   */
  componentWillUnmount() {
    this.state.cancel()
  }

  onChange = e => {
    const settled = e.target.checked
    const { accessToken, env, query, setCheckedStatus } = this.props
    const { promise, cancel } = makeCancelable(
      putSettlement({ ...query, settled }, accessToken, env),
    )
    setCheckedStatus(settled)

    this.setState({ settled, cancel, status: 'requesting' })

    fluffy(promise)
      .then(res => {
        if (res.ok) {
          return res.json()
        } else {
          console.error(res)
          throw new Error('ネットワークエラー')
        }
      })
      .then(() => this.setState({ status: 'success' }))
      .catch(() => this.setState({ status: 'failure' }))
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { settled, status } = this.state
    const disabled = status === 'requesting'

    return (
      <Checkbox
        checked={ settled }
        onChange={ this.onChange }
        labelText={ '確定する' }
        disabled={ disabled }
      />
    )
  }
}

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
const mapStateToProps = state => {
  return {
    accessToken: state.login.authentication.accessToken,
    env: state.env,
  }
}

export default connect(mapStateToProps)(Settle)
