export const cert = `-----BEGIN RSA PRIVATE KEY-----
MIIEowIBAAKCAQEA32AJjk6P9ZmDlPcE+KdCBbQrIoxtEAgjZTKE572lyFCLJ7Z7
PrdpwSQLGELu4fxOqHRCbHRQUgsjspS3YskT5wJVWwnTm6AzIPmV3q9ga5KDve4n
isfMk1WaVrtRtIHzOTHTh8FxlKTCrFAVMDz9wPFqZteOWsnkAPlZRIJSGSiOpq5f
bSKk5kji4yoQ+YprF0zxYwC80rhetS6Gu7pDGlhMEx+poteYGU4Hnzq2R8PREyxg
KWDJLvFsrnErs6Hv+1Mc9pVwfvrvKjJYPYbgRsY2ZF8b3+qb05kTgPr8nUeS9T7E
AKKz5U7LVJvr9OYRcm+w5J+Pmg/bAJzSAAYWTQIDAQABAoIBAEG8yrMlrboytoWl
eMIQvx5cmoEtYH9iVcBXcMOejWZ5RaqnSnf3lFjx2Y4HTLQXA7MRm+a9CFJM0vGg
UKPy1zeZ+TK0nqDjMYjXyuJhLHgcA0dnCh4OlMcbXzN9Jxt/TuwbHMyMAngjUONT
IhkzKd6ZbZ3cfBl95uredwXxcGXUe7ri09E4mi2h9hf5pOgi86Rk1gcmio1oMi9+
3Z5ACXyYOn4PRrOUU3f4OYre33zf01chfJ/i0LA0ZY5EVVXFd5XX3DDH6gkBRDT1
3QqiUzwMYpJyS8Pic0uFKomCR4ps2mttdh1SkylSCEcdkoB+mMyxtrYSrOP4x5Gg
X0vzmSUCgYEA/j0ski+N9RTOKO0ojNQH+fcKxKdK7mMrEnDdqVGDRM+5Goca5MgI
DiDC64p5ZchjRnk368k7FAXAweTpZHNb0xwUQSCKeZHYBgC00y9Cp75wh2wTtnsB
MsSQJPt4iKqneZ/2KcIbHIIXx8E1iaQKtk89UtMhOA1QN4aS4mGbAXcCgYEA4Owi
ZeGvWOpkLcwDVhCekhr0xe688CMWpBmCWIkUXfgy+eAYFanwPlKy74xatTss4Rp0
dufi6MsYaCxuLfvyNU3KwfivOQO8klo3Gv8QVnIDpZ1SY2M1vfsh26a/gklmeAU1
QZvYI9G8+Opaxmvd0ieA899UOgWJ+y45H4tEN1sCgYEAxW0jy3F6PzMk1Ms1jgap
GwcoKYXvvibsL6nNt8pAsZLNHziDPd3Hw5DVTKt/kfaGgRdowYTGh8nf4bGtPAAI
8mr/n4NxhO4oXqD1ynkMvc44/DCFLlNentndRZqyAw7idqQtOEgvXXf4l3oF5Rip
6OCnskWZqjlMbZHnRjydbgkCgYAL5FA5RMJpmBLt3PB8N2q63NP+Nw4kQCatTG/4
0F6UTVAcp/19/7slAGhXg/szw8ouqKBpvP7hQgOrVlYI9l4q9U74DHbvM8RflId/
VOb/Ow34TqYRfShVQBbdLybG86yIQfY73sqBslAkdPeRsBhNSStnOXKRksNl1V7N
zSYxKwKBgALNQ8xlsGr8/AFMPjFDMMKYUk69GDYAxGvkPkq5mKsJzicDqdmsPp+M
nbIf5jRIWc73z78YQlAkp5XmLWGa/maiCADRJQGReJSwZCrSfpWCU2qKyhfwi13r
Y0A0VhPtacE8vjIy1++y6raBSdV7OSMp03TsJ6yIbEA6caPkzEPD
-----END RSA PRIVATE KEY-----
`
