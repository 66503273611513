import React from 'react'
import connect from '../connect'
import PropTypes from 'prop-types'
import Sticky from 'src/lib/class-sticky'
// import config from 'src/config'

export class AddButton extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    disabled: PropTypes.bool.isRequired,
    tabIndex: PropTypes.number.isRequired,
    updateStage: PropTypes.func.isRequired,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return this.props.disabled !== nextProps.disabled
  }

  /**
   * 値更新用のメソッド
   * @param {number} contactsIndex どこに配置するか
   */
  addNewContactAt = contactsIndex => {
    const { updateStage, tabIndex } = this.props
    const diffProps = {
      contacts: {
        [tabIndex]: {
          [contactsIndex]: { deleted: false }, // 新しいのを足しているが、最初はGhostにする。幽霊フォーム。見えてるけど、実態はfalse。これは、サーバーに送る時にフィルタされる。
        },
      },
    }
    updateStage(new Sticky(diffProps))
  }

  /**
   * [onClick description]
   * @param  {Event} e event
   * @return {void}
   */
  onClick = () => {
    const { sticky, tabIndex } = this.props
    // 幾つのコンタクトがあるか
    const { contacts } = sticky.json()
    const { length } = contacts[tabIndex] || []

    if (length === 0) {
      // コンタクトが0個のときは何も無いので、追加ボタンを押されたら2個にする
      this.addNewContactAt(0)
      this.addNewContactAt(1)
    } else if (0 < length) {
      // そうでないときは普通に追加
      const next = length // index + 1
      this.addNewContactAt(next)
    }
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { disabled } = this.props

    return (
      <div className={ 'contact-addButton' }>
        <button
          className={
            'button button-icon-add button-icon-add-contact button-green'
          }
          onClick={ this.onClick }
          disabled={ disabled }
        >
          {'追加'}
        </button>
      </div>
    )
  }
}

export default connect(AddButton)
