import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Components
import Modal from 'react-modal'
import ModalContent from './modal-content'

// libs
import Sticky from 'src/lib/class-sticky'
import requestPutSticky from 'src/lib/sticky-api/put'
import requestPostSticky from 'src/lib/sticky-api/post'
import requestGetSticky from 'src/lib/sticky-api/get'
import requestDeleteSticky from 'src/lib/sticky-api/delete'
import requestCanUpdateSticky from 'src/lib/sticky-api/canupdate'
import requestPutMarucRefund from 'src/lib/maruc-api/put-refund'
import { actionCreators } from 'src/reducers/maruc-refund'
import { actionCreators as createPaymentsAction } from 'src/reducers/payments'
import putPayments from 'src/lib/payments-api/put'
import config from 'src/config'
import switz from 'switz'
import {
  toJPYenText,
  setStickyDisplay,
  setFinishStatus,
  setMarucStaff,
  getRegionId,
} from 'src/lib/format'
import { today } from 'src/lib/moment'

const mapStateToProps = state => ({
  accessToken: state.login.authentication.accessToken,
  env: state.env,
  storedPayments: state.payments.store,
  wara: state.wara.data,
  master: state.master.data,
  marucRefund: state.marucRefund,
  messenger: state.messenger,
})

/**
 * map dispatch to props
 * @param  {function} dispatch dispatcher
 * @param  {object} props       Props
 * @return {object}            mapped auto bind action creators
 */
export const mapDispatchToProps = dispatch => {
  return {
    setMarucRefund: refund => dispatch(actionCreators.setMarucRefund(refund)),
    storePayments: (stickyId, allPayments) =>
      dispatch(createPaymentsAction.store(stickyId, allPayments)),
  }
}

export class SearchResultsModal extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    isOpen: PropTypes.bool.isRequired,
    sticky: PropTypes.oneOfType([PropTypes.instanceOf(Sticky), PropTypes.bool]),
    closeMe: PropTypes.func.isRequired,
    closeResult: PropTypes.func.isRequired,
    parentRoute: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
    hasES: PropTypes.bool.isRequired,
    hasHL: PropTypes.bool.isRequired,
    stageStickyFromHistory: PropTypes.func.isRequired, // 新規注文を作成し、編集中の付箋を置き換える
    displayRoute: PropTypes.oneOf(['call', 'assign', 'history', 'maruc']),
    linkToWara: PropTypes.bool,
    consultation: PropTypes.bool,
    modified: PropTypes.func,
    marucRepeat: PropTypes.bool, // trueのときマルシーリピートがあるマルシーの交渉履歴を入力不可にする
    createSticky: PropTypes.bool, // trueのとき: コール課の検索以外から新規注文を作成する
    showButtons: PropTypes.bool, // trueのとき: 付箋作成のボタンを表示する
    // stateProps
    accessToken: PropTypes.string.isRequired,
    env: PropTypes.object.isRequired,
    storedPayments: PropTypes.any.isRequired,
    master: PropTypes.object.isRequired,
    messenger: PropTypes.object.isRequired, // コール課画面から渡されたコールバック
    // dispatchProps
    setMarucRefund: PropTypes.func.isRequired,
    storePayments: PropTypes.func.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    sticky: false,
    displayRoute: null,
    linkToWara: false,
    linkMarucRepeat: -1,
    consultation: false,
    modified: null,
    marucRepeat: false,
    createSticky: false,
    showButtons: true,
  }

  constructor(props) {
    super(props)
    this.state = {
      putStickyStatus: 'not_yet', // 付箋の保存のネットワーク状況
      timerId: false,
      orgsticky: this.props.sticky,
      newSticky: false, // 新規付箋を作るときは true になる
    }
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.isOpen !== nextProps.isOpen ||
      this.state.putStickyStatus !== nextState.putStickyStatus ||
      this.state.getPaymentsStatus !== nextState.getPaymentsStatus ||
      this.state.payments !== nextState.payments ||
      this.props.sticky !== nextProps.sticky ||
      this.state.newSticky !== nextState.newSticky ||
      this.props.messenger.update !== nextProps.messenger.update ||
      this.props.messenger.reset !== nextProps.messenger.reset
    )
  }

  /**
   * componentWillUnmount
   * @return {void}
   */
  componentWillUnmount() {
    clearTimeout(this.state.timerId)
  }

  /**
   * 新規作成ボタンを押した時のハンドラ
   * @param  {Sticky} sticky ソースになる案件付箋オブジェクト
   * @return {void}
   */
  onDecideClick = sticky => {
    const {
      stageStickyFromHistory,
      hasES,
      hasHL,
      createSticky,
      parentRoute,
    } = this.props
    if (parentRoute === 'call') {
      console.log('call:', this.props.messenger)
    }
    // 都道府県に合わせた新エリアを設定する。
    const { prefCode } = sticky.findClient()
    const regionId = getRegionId(prefCode, this.props.master.prefCodes)
    const nextSticky = sticky.transform({
      pattern: Sticky.TRANSFORM_PATTERN.BEFORE_GENERATE_REPEAT,
      repeatProps: {
        regionId,
        hasES,
        hasHL,
      },
    })
    // コール課でstageStickyFromHistoryの呼び出し時、既存の差分とマージしないようにする。
    // src/components/divisions/call/entry/index.jsx(145) update参照。
    // それ以外は基本的にnextStickyで編集中の付箋を置き換えている。
    // src/components/divisions/order-list/app.jsx(254)等参照。モーダル呼び出し画面ごとに定義している。
    nextSticky._props.noMerge = true

    // update は同期的で良い
    stageStickyFromHistory(nextSticky)

    // コール課の検索結果から呼ばれたときはモーダルと検索結果を閉じる。
    if (parentRoute !== 'call') {
      // それ以外の付箋詳細から呼ばれたときはstageStickyFromHistory()で編集中の付箋を置き換え、モーダルはそのまま
      if (!createSticky) {
        this.props.closeMe()
        this.props.closeResult() // unmount
      } else {
        nextSticky._props.id = null
        this.setState({ orgsticky: nextSticky, newSticky: true })
      }
    }
  }

  /**
   * 復活ボタンを押した時のハンドラ
   * @param  {Sticky} sticky ソースになる案件付箋オブジェクト
   * @return {void}
   */
  onResumeClick = sticky => {
    const {
      stageStickyFromHistory,
      hasES,
      hasHL,
      createSticky,
      parentRoute,
    } = this.props
    const nextSticky = sticky.transform({
      pattern: Sticky.TRANSFORM_PATTERN.BEFORE_GENERATE_RESUME,
      repeatProps: {
        hasES,
        hasHL,
      },
    })

    // update は同期的で良い
    nextSticky._props.noMerge = true
    stageStickyFromHistory(nextSticky)

    // コール課の検索結果から呼ばれたときはモーダルと検索結果を閉じる。
    if (parentRoute !== 'call') {
      // それ以外の付箋詳細から呼ばれたときはstageStickyFromHistory()で編集中の付箋を置き換え、モーダルはそのまま
      if (!createSticky) {
        this.props.closeMe()
        this.props.closeResult() // unmount
      } else {
        nextSticky._props.id = null
        this.setState({ orgsticky: nextSticky, newSticky: true })
      }
    }
  }

  onReRequestClick = sticky => {
    const { stageStickyFromHistory, createSticky, parentRoute } = this.props
    const nextSticky = sticky.transform({
      pattern: Sticky.TRANSFORM_PATTERN.BEFORE_GENERATE_REREQUEST,
    })

    // update は同期的で良い
    nextSticky._props.noMerge = true
    stageStickyFromHistory(nextSticky)

    // コール課の検索結果から呼ばれたときはモーダルと検索結果を閉じる。
    if (parentRoute !== 'call') {
      // それ以外の付箋詳細から呼ばれたときはstageStickyFromHistory()で編集中の付箋を置き換え、モーダルはそのまま
      if (!createSticky) {
        this.props.closeMe()
        this.props.closeResult() // unmount
      } else {
        nextSticky._props.id = null
        this.setState({ orgsticky: nextSticky, newSticky: true })
      }
    }
  }

  /**
   * これはマルシーとして流し込む時のハンドラ
   * @param  {Sticky} sticky ソースになる案件付箋オブジェクト
   * @return {void}
   */
  onMarucClick = sticky => {
    const {
      stageStickyFromHistory,
      createSticky,
      parentRoute,
      master: { validStaffs },
    } = this.props
    const nextSticky = sticky.transform({
      pattern: Sticky.TRANSFORM_PATTERN.BEFORE_GENERATE_MARUC,
    })
    setMarucStaff(nextSticky, validStaffs)
    if (parentRoute === 'call') {
      console.log('call:', this.props.messenger)
    }

    // update は同期的で良い
    nextSticky._props.noMerge = true
    stageStickyFromHistory(nextSticky)

    // コール課の検索結果から呼ばれたときはモーダルと検索結果を閉じる。
    if (parentRoute !== 'call') {
      // それ以外の付箋詳細から呼ばれたときはstageStickyFromHistory()で編集中の付箋を置き換え、モーダルはそのまま
      if (!createSticky) {
        this.props.closeMe()
        this.props.closeResult() // unmount
      } else {
        nextSticky._props.id = null
        this.setState({ orgsticky: nextSticky, newSticky: true })
      }
    }
  }

  /**
   * 過去履歴の編集
   * @param  {Sticky} sticky [description]
   * @return {void}
   */
  onEditHistoryClick = async diffSticky => {
    const {
      accessToken,
      env,
      modified,
      storedPayments,
      master: { validStaffs },
      marucRefund,
      setMarucRefund,
      storePayments,
    } = this.props
    const { orgsticky: sticky } = this.state

    this.setState({ putStickyStatus: 'request' })
    // 更新情報をマージした付箋を作成
    const mergedSticky = sticky.update(diffSticky)
    try {
      await requestCanUpdateSticky(
        sticky.id,
        sticky._props.updateAt,
        accessToken,
        env,
      )
    } catch (e) {
      console.error(e)
      this.setState({ putStickyStatus: 'failure', orgsticky: mergedSticky })
      this.resetStatus()
      if (e === config.conflictError) {
        alert(config.conflictMessage)
      } else {
        alert(e)
      }
      return
    }

    //------------------------------------------------------------------
    // 全ての支払情報を取得
    const allPayments = (storedPayments[sticky.id] || [])
      .filter(p => p)
      // stickyIdをくっつける
      .map(payment => ({
        ...payment,
        data: { ...payment.data, stickyId: sticky.id },
      }))

    //------------------------------------------------------------------
    // 付箋の支払情報を更新し、ストアに保存する
    let putPaymentsList = []
    if (allPayments.length > 0) {
      try {
        putPaymentsList = await putPayments(allPayments, accessToken, env)
      } catch (e) {
        console.error(e)
        if (e === config.conflictError) {
          alert(config.conflictMessage)
        } else {
          alert('支払情報の更新に失敗しました')
        }
        return
      }
    }
    //------------------------------------------------------------------
    // マルシー付箋の時は返金情報を更新する
    if (sticky.isClaimerOrder && marucRefund) {
      const refund = marucRefund[sticky.id] || {}
      // 返金データがあり、更新されている時のみAPIを呼び出す。
      if (refund && refund.modified) {
        let result = {}
        try {
          delete refund.modified
          const res = await requestPutMarucRefund(refund, accessToken, env)
          if (res.ok) {
            result = await res.json()
            setMarucRefund(result)
            // console.log(result)
          } else {
            alert('マルシー返金の更新に失敗しました。')
          }
        } catch (e) {
          console.error(e)
          alert('マルシー返金の更新に失敗しました。')
        }
      }
    }

    let displayOrder = mergedSticky._props.displayOrder || 0
    let newSticky = null // アフタまたはマルシーリピート作成時、更新された親付箋が設定される。
    // ================================================================
    // アフタ付箋の作成
    // まだアフターが設定されていないか
    const afterHasNotBeenCreated =
      !sticky._props.afterDateTime || !sticky._props.afterDateTime.date
    // アフタを作るべきか
    const afterShouldBeCreated =
      afterHasNotBeenCreated &&
      mergedSticky._props.afterDateTime &&
      mergedSticky._props.afterDateTime.date

    if (afterShouldBeCreated) {
      // アフタ付箋の支払情報をクリアする。
      const afterContacts = mergedSticky._props.contacts
      for (let i = 0; i < afterContacts.length; i++) {
        if (afterContacts[i]) {
          const afterTabContacts = [...afterContacts[i]]
          for (let j = 0; j < afterTabContacts.length; j++) {
            if (afterTabContacts[j]) {
              afterTabContacts[j] = {
                ...afterTabContacts[j],
                id: null,
                paymentInfo: null,
              }
            }
          }
          afterContacts[i] = afterTabContacts
        }
      }
      // アフターの付箋を作成
      const determinedDateTime =
        (sticky._props.determinedDateTime || {}).date || ''
      displayOrder += 1
      const afterSticky = mergedSticky
        .update(
          {
            contacts: afterContacts,
            determinedDateTime: {
              ...mergedSticky._props.afterDateTime,
              answerTimeId: null,
            },
            displayDate: { ...mergedSticky._props.afterDateTime }.date,
            parentId: mergedSticky.id,
            displayOrder, // orderは最後にする
            afterDateTime: null,
            finishStateId: null,
            stickyStatusId: config.stickyStatus.notWorking,
            // アフタの重要申し送りにいつのアフタか記載する
            // 受注不可、受注注意のチェックボックスの状態を残す
            handOver: {
              freeDescribe: [
                (mergedSticky._props.handOver || {}).freeDescribe,
                determinedDateTime.substr(5, 5) + 'のアフタ',
              ].join(' '),
              ids: [...((mergedSticky._props.handOver || {}).ids || [])],
            },
            // 中断理由には {} を指定する。see: parse-props.js
            suspendReason: {},
            showSchedule: true,
          },
          { shallow: true },
        )
        .remove({ id: true, cash: true, account: true })
        .setUserValue('afterOf', sticky.id) // 付箋間の関係を定義する
      // アフタ付箋の金額を削除
      afterSticky._props.contacts &&
        afterSticky._props.contacts.forEach(idContact => {
          idContact.forEach(contact => {
            contact.id = null
            contact.stickyId = null
            contact.paymentInfo = null
          })
        })

      try {
        // アフタ付箋の新規作成
        const nextAfterStickyProps = await requestPostSticky(
          afterSticky,
          accessToken,
          env,
        )
        // 親付箋にアフタ付箋のIDを登録する。
        const orgSticky = new Sticky({
          id: sticky.id,
          updateAt: sticky._props.updateAt,
          userField: sticky._props.userField,
        }).setUserValue('hasAfter', nextAfterStickyProps.id)
        newSticky = await requestPutSticky(orgSticky, accessToken, env)
      } catch (e) {
        console.error(e)
        this.setState({ orgsticky: mergedSticky })
        if (e === config.conflictError) {
          alert(config.conflictMessage)
        } else {
          alert('付箋の更新に失敗しました')
        }
        return
      }
    }
    // ================================================================
    // マルシーリピート付箋の作成
    // まだマルシーリピートが作られていないか
    const marucRepeatHasNotBeenCreated = !mergedSticky.getUserValue(
      'hasMarucRepeat',
    )
    // マルシーリピートを作るべきかどうか
    const storedComplaint = mergedSticky._props.marucComplaint
    // 「再修理」にチェックあり、かつ「マルシー完了」にチェックなし
    const marucRepeatShouldBeCreated =
      storedComplaint &&
      (storedComplaint.measures || []).includes(config.measures.payedRepaier) &&
      !(storedComplaint.complete || false)

    // マルシーリピートを作るべきか
    const isMarucRepeatCreation = !!(
      mergedSticky.isClaimerOrder &&
      marucRepeatHasNotBeenCreated &&
      marucRepeatShouldBeCreated
    )
    const refund = storedComplaint && storedComplaint.refund
    if (isMarucRepeatCreation) {
      // マルシーリピート付箋の支払情報をクリアする。
      const marucContacts = mergedSticky._props.contacts
      for (let i = 0; i < marucContacts.length; i++) {
        if (marucContacts[i]) {
          const marucTabContacts = [...marucContacts[i]]
          for (let j = 0; j < marucTabContacts.length; j++) {
            if (marucTabContacts[j]) {
              marucTabContacts[j] = {
                ...marucTabContacts[j],
                id: null,
                paymentInfo: null,
              }
            }
          }
          marucContacts[i] = marucTabContacts
        }
      }
      // マルシーリピートの付箋を作成
      const displayDate = sticky._props.displayDate || ''
      displayOrder += 1
      const marucRepeatSticky = mergedSticky
        .update(
          {
            stickyTypeId: config.stickyType.normal, // マルシーもnormalだが、明示的に設定する
            orderTypeId: config.orderType.repeat,
            contacts: marucContacts,
            determinedDateTime: {
              date: displayDate,
              answerTimeId: null,
            },
            displayDate,
            parentId: null, // リピート付箋では指定しないようにする
            displayOrder,
            afterDateTime: null,
            finishStateId: null,
            stickyStatusId: config.stickyStatus.notWorking,
            wishDateTimes: [{ date: today(), displayOrder: 0 }], // 当日の日付を設定
            // 重要申し送りにいつのマルシーリピートかを記載する
            handOver: isMarucRepeatCreation
              ? {
                freeDescribe: [
                  (sticky._props.handOver || {}).freeDescribe,
                  displayDate.substr(5, 5) +
                      `のマルシーリピ(有償修理${toJPYenText(refund)})`,
                ].join(' '),
              }
              : null,
          },
          { shallow: true },
        )
        .remove({ id: true, cash: true, account: true, orderId: true })
        .setUserValue('marucRepeatOf', sticky.id) // 付箋間の関係を定義する
      try {
        // マルシーリピート付箋の新規作成
        const nextMarucRepeatStickyProps = await requestPostSticky(
          marucRepeatSticky,
          accessToken,
          env,
        )
        // 親付箋にリピート付箋のIDを登録する。
        const updateAt =
          newSticky !== null ? newSticky.updateAt : sticky._props.updateAt
        const userField =
          newSticky !== null ? newSticky.userField : sticky._props.userField
        const orgSticky = new Sticky({
          id: sticky.id,
          userField,
          updateAt,
        }).setUserValue('hasMarucRepeat', nextMarucRepeatStickyProps.id)
        // マルシーリピートを作成するとき、付箋を完了させる
        if (isMarucRepeatCreation) {
          orgSticky._props.finishStateId = config.finishState.complete
        }
        newSticky = await requestPutSticky(orgSticky, accessToken, env)
      } catch (e) {
        console.error(e)
        this.setState({ orgsticky: mergedSticky })
        if (e === config.conflictError) {
          alert(config.conflictMessage)
        } else {
          alert('付箋の更新に失敗しました')
        }
        return
      }
    }

    //------------------------------------------------------------------
    // 集金付箋の作成
    //------------------------------------------------------------------
    // 集金付箋と関係を持つべき支払情報
    //   ・売掛け金
    //   ・回収方法 = 集金
    //   ・回収予定売掛け金 - 回収売掛け金 > 0 （残金あり）
    const collectPayments = putPaymentsList.filter(
      payment =>
        payment.paymentType === 'accounts' &&
        payment.data.accountCollectionMethodId ===
          config.howToRetrieve.collect &&
        !payment.data.childCollected &&
        payment.data.accountValue - (payment.data.accountCollectedValue || 0) >=
          0,
    )
    // 更新後の付箋の情報を取得する。集金付箋のcontactsにコピーするために使用。
    let orgsticky
    try {
      orgsticky = await requestGetSticky(sticky.id, accessToken, env)
    } catch (e) {
      console.error(e)
      return
    }

    // 新規に作成した集金付箋の一覧
    // 元の付箋のcontactsをJSON文字列の形で保管し、後で復元できるようにする。
    const orgStickyProps = orgsticky.json()
    const orgContactsJson = JSON.stringify(orgStickyProps.contacts)
    let paymentDisplay = (orgsticky._props.displayOrder || 0) + 1

    // 集金付箋の対象となる支払情報を元に集金付箋を作成する。
    // eslint-disable-next-line no-unused-vars
    let collectStickiesResults = []
    try {
      const displayDate = orgStickyProps.displayDate
      collectStickiesResults = await Promise.all(
        collectPayments.map(payment => {
          // 元の付箋のcontactsを集金付箋用に加工する。
          // contactsは以下の処理で変更されるため、上で保存したJSON文字列から変更前のものを復元する。
          //   ・登録するpaymentのidと一致するaccountだけを残す
          //   ・支払情報が関連づく付箋情報をnullにする
          //   ・現金がある場合は削除する
          //   ・売掛け金の回収方法は「集金」に設定
          // 元の付箋のcontactsから、登録しようとする支払情報のみを残す
          const orgContacts = JSON.parse(orgContactsJson)
          const paymentContact = [...orgContacts]
          for (let i = 0; i < paymentContact.length; i++) {
            const contactn = paymentContact[i]
            for (let j = 0; j < contactn.length; j++) {
              const contact = contactn[j]
              contact.paymentInfo.accounts = contact.paymentInfo.accounts.filter(
                account => account.id === payment.data.id,
              )
              // 実際は一つになるはずだが、念のためループしておく
              contact.paymentInfo.accounts.forEach(account => {
                account.accountCollectionMethodId = config.howToRetrieve.collect
              })
              contact.paymentInfo.stickyContactId = null
              contact.paymentInfo.stickyId = null
              contact.paymentInfo.payments = []
            }
          }
          const collectProps = {
            parentId: orgsticky.id,
            parentAccountId: payment.data.id,
            userField: JSON.stringify({
              collectStickyOf: orgsticky.id,
              collectPaymentOf: payment.data.id,
            }),
            stickyTypeId: config.stickyType.payment, // 集金付箋
            isAfter: 'false',
            stickyStatusId: config.stickyStatus.notworking, // 未アサイン
            regionId: orgStickyProps.regionId,
            staffId: orgStickyProps.staffId,
            displayDate: payment.data.accountDate || displayDate,
            displayOrder: paymentDisplay++, // orderは最後にする
            orderId: orgsticky.orderId,
            contacts: paymentContact, // 加工した元付箋のcontacts
            companyId: orgStickyProps.companyId, // 親の会社名を引き継ぐ
            // 集金付箋の重要申し送りにいつの集金か記載する
            handOver: {
              freeDescribe:
                displayDate.substring(5, 7) +
                '月' +
                displayDate.substring(8, 10) +
                '日分集金',
            },
          }
          return requestPostSticky(
            new Sticky(collectProps, { normalize: false }),
            accessToken,
            env,
          )
        }),
      )
    } catch (e) {
      console.error(e)
      alert('集金付箋の作成に失敗しました')
      return
    }
    // 前回作成された集金付箋を削除する。
    putPaymentsList.forEach(payment => {
      if (
        payment.paymentType === 'accounts' &&
        (payment.data.childStickyId || -1) !== -1 &&
        !payment.data.childCollected
      ) {
        const paymentStickyId = payment.data.childStickyId
        requestDeleteSticky(paymentStickyId, accessToken, env)
          .then(() => {})
          .catch(err => {
            console.log(err)
          })
      }
    })

    // ================================================================
    // 元の付箋を更新する。
    const updateAt =
      newSticky !== null ? newSticky.updateAt : sticky._props.updateAt
    // 付箋の表示状態を設定する
    setStickyDisplay(
      diffSticky,
      validStaffs,
      newSticky !== null ? new Sticky(newSticky) : sticky,
    )
    let putSticky = diffSticky

    requestPutSticky(
      putSticky.update({ id: sticky.id, updateAt }),
      accessToken,
      env,
    )
      .then(result => {
        const newSticky = new Sticky(result)
        this.setState({ putStickyStatus: 'success', orgsticky: newSticky })
        // 金額の差分をクリアする
        storePayments(sticky.id, [])
        this.resetStatus()
      })
      .catch(err => {
        console.error(err)
        this.setState({ putStickyStatus: 'failure', orgsticky: mergedSticky })
        this.resetStatus()
        if (err === config.conflictError) {
          alert(config.conflictMessage)
        } else {
          alert('付箋の更新に失敗しました')
        }
      })
    modified && modified() // 変更を親に通知する
  }

  /**
   * 付箋の登録結果をリセットする
   */
  resetStatus = () =>
    this.setState({
      timerId: setTimeout(
        () => this.setState({ putStickyStatus: 'not_yet' }),
        config.messageResultDelay,
      ),
    })

  /**
   * 付箋を保存し、編集対象に設定する。
   */
  postSticky = async diffSticky => {
    const {
      sticky,
      // stateProps
      accessToken,
      env,
      master: { validStaffs },
    } = this.props
    this.setState({ putStickyStatus: 'request' })
    const mergedSticky = new sticky.merge(diffSticky)
    // 付箋の表示状態を設定する
    setStickyDisplay(mergedSticky, validStaffs)
    // 終了状態を修正する
    setFinishStatus(mergedSticky)
    requestPostSticky(mergedSticky, accessToken, env)
      .then(res => {
        this.setState({
          putStickyStatus: 'success',
          orgsticky: new Sticky(res),
          newSticky: false,
        })
        this.resetStatus()
      })
      .catch(err => {
        console.log(err)
        this.setState({ putStickyStatus: 'failure', newSticky: false })
        this.resetStatus()
      })
  }
  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { putStickyStatus, orgsticky: sticky } = this.state
    const {
      isOpen,
      closeMe,
      parentRoute,
      displayRoute,
      linkToWara,
      consultation,
      marucRepeat,
      createSticky,
      showButtons,
    } = this.props

    return (
      <Modal isOpen={ isOpen } ariaHideApp={ false }>
        {sticky && (
          <ModalContent
            sticky={ sticky }
            parentRoute={ parentRoute }
            displayRoute={ displayRoute }
            linkToWara={ linkToWara }
            consultation={ consultation }
            onDecideClick={ this.onDecideClick } // 新規作成として流し込み
            onResumeClick={ this.onResumeClick } // 復活として流し込み
            onReRequestClick={ this.onReRequestClick } // 再依頼として流し込み
            onMarucClick={ this.onMarucClick } // マルシーとして流し込み
            onEditHistoryClick={ this.onEditHistoryClick } // 過去編集
            marucRepeat={ marucRepeat } // マルシーリピートがあるとき交渉履歴を無効にする
            createSticky={ createSticky } // 新規注文ボタンを有効にする
            newSticky={ this.state.newSticky } // 新規付箋の作成
            postSticky={ this.postSticky } // 新規付箋の作成処理
            showButtons={ showButtons } // 付箋作成ボタンの表示
            closeMe={ closeMe }
          />
        )}
        <button
          className={ 'button button-fixed close-mark' }
          onClick={ closeMe }
        />
        {switz(putStickyStatus, t =>
          t
            .case('request', () => (
              <div className={ 'alert alert--normal' } style={ { height: 'auto' } }>
                <div className={ 'alert-icon' }>
                  <i className="fa fa-spinner fa-pulse fa-2x fa-fw" />
                </div>
              </div>
            ))
            .case('success', () => (
              <div className={ 'alert alert--normal' } style={ { height: 'auto' } }>
                <div className={ 'alert-icon' }>
                  <i className="fa fa-2x fa-check-circle" />
                  {'更新に成功しました'}
                </div>
              </div>
            ))
            .case('failure', () => (
              <div className={ 'alert alert--error' } style={ { height: 'auto' } }>
                <div className={ 'alert-icon' }>
                  <i className="fa fa-2x fa-times-circle" />
                  {'更新に失敗しました'}
                </div>
              </div>
            ))
            .default(() => null),
        )}
      </Modal>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultsModal)
