import React from 'react'
import PropTypes from 'prop-types'

// lib
import Sticky from 'src/lib/class-sticky'

export class SelectPayer extends React.PureComponent {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    label: PropTypes.string.isRequired,
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
    disabled: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    disabled: false,
  }

  onChange = e => {
    const payer = JSON.parse(e.target.value)
    const { id: stickyId } = this.props.sticky
    this.props.onSelect({ ...payer }, stickyId)
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { label, sticky, route } = this.props
    const disabled = this.props.disabled || route !== 'assign'
    const contacts = sticky.json().contacts || []

    const payers = contacts
      .reduce(
        (prev, tabContacts, tabIndex) => [
          ...prev,
          ...(tabContacts.map((contact, contactIndex) => ({
            ...contact,
            tabIndex,
            contactIndex,
          })) || []),
        ],
        [],
      ) // flatten
      .filter(x => !!x) // remove null
      .map(x => ({
        id: x.id,
        name: x.name ? x.name + (x.isPayer ? '(支払者)' : '') : x.name,
        tabIndex: x.tabIndex,
        contactIndex: x.contactIndex,
      }))

    const payerOptions = [{ id: -1, name: '(支払者を選択)' }, ...payers]

    return (
      <dl className={ 'label-inputs-wrap ' }>
        <dt>
          <label htmlFor={ `payer-select-${route}` }>{label}</label>
        </dt>
        <dd className={ 'input-wrap' }>
          <select
            style={ { backgroundColor: 'ivory' } }
            className={ 'short' }
            value={ -1 }
            id={ `payer-select-${route}` }
            name={ `payer-select-${route}` }
            disabled={ disabled || payers.length === 0 }
            onChange={ this.onChange }
          >
            {payerOptions.map(payer => (
              <option
                key={ payer.id }
                value={ JSON.stringify(payer) } // NOTE: 複数の情報を渡したいだけなんだ
              >
                {payer.name || '(名前未入力)'}
              </option>
            ))}
          </select>
        </dd>
      </dl>
    )
  }
}

export default SelectPayer
