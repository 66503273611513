import update from 'immutability-helper'
import { findStickyFromWara } from 'src/lib/search'

/**
 * タイプ定義
 * @type {string}
 */
const type = 'WARA.UPDATE_STICKY'

/**
 * exportation
 */
export default {
  /**
   * action type
   * @type {string}
   */
  type,

  /**
   * action creator
   * @param  {{ regionIndex: number, listIndex: number, stikcyIndex: number }}  location 更新するstickyがアサインされたlistのindexです
   * @return {object} [description]
   */
  creator: (sticky, location) => {
    return {
      type,
      payload: { sticky, location },
    }
  },

  /**
   * stageを経ずに、案件情報を直接変更するハンドラ
   * @param  {object}  state redux state
   * @param  {object} action action
   * @return {object}        new state
   */
  handler: (state, action) => {
    const { location, sticky } = action.payload || {}

    const { regionIndex, listIndex, stickyIndex } = location

    if (
      regionIndex === void 0 ||
      listIndex === void 0 ||
      stickyIndex === void 0
    ) {
      return state
    }

    const oldSticky = findStickyFromWara(state.data, location)

    if (!oldSticky) {
      return state
    }

    const newSticky = oldSticky.merge(sticky)

    if (regionIndex === -1 || listIndex === -1) {
      return update(state, {
        data: {
          dock: {
            stickies: {
              [stickyIndex]: { $set: newSticky },
            },
          },
        },
      })
    } else {
      return update(state, {
        data: {
          regions: {
            [regionIndex]: {
              lists: {
                [listIndex]: {
                  stickies: {
                    [stickyIndex]: { $set: newSticky },
                  },
                },
              },
            },
          },
        },
      })
    }
  },
}
