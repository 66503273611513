import React from 'react'
import PropTypes from 'prop-types'

import TimeAutocomplete from 'src/components/commons/time-autocomplete'

import { Typography } from '@mui/material'

export const Time = ({ value, label, disabled, update, id, nextId }) => {
  return (
    <div className={ 'ss-time-picker autocomplete-wrap' }>
      <Typography variant="caption">{label}</Typography>
      <TimeAutocomplete
        value={ value }
        onValidateSuccess={ update }
        disabled={ disabled }
        inputId={ id }
        nextId={ nextId }
      />
    </div>
  )
}

/**
 * propTypes
 * @type {object}
 */
Time.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  update: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  nextId: PropTypes.string,
}

/**
 * defaultProps
 * @type {object}
 */
Time.defaultProps = {
  disabled: false,
  nextId: '',
}

export default React.memo(Time)
