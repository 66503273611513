import React from 'react'
import PropTypes from 'prop-types'
import Sticky from 'src/lib/class-sticky'

/**
 * 注文方法表示
 * @type {ReactComponent}
 */
export default class OrderId extends React.Component {
  /**
   * Validation
   * @type {object}
   */
  static propTypes = {
    // ownProps
    sticky: PropTypes.instanceOf(Sticky).isRequired,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return this.props.sticky.json().orderId !== nextProps.sticky.json().orderId
  }

  /**
   * render
   * @return {ReactDOM} rendered result
   */
  render() {
    // null避け
    const { orderId } = this.props.sticky.json() || ''

    return (
      <dl className={ 'label-inputs-wrap' }>
        <dt>{'注文ID'}</dt>
        <dd className={ 'input-wrap' }>{orderId || '未設定'}</dd>
      </dl>
    )
  }
}
