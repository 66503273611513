// react related
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// components
import PayerSelect from '../common/payer-select'
import SendRequest from '../common/send-request'
import AccountValue from './partials/account-value'
import AccountCollectedValue from './partials/account-collected-value'
import AccountCollectionMethod from './partials/account-collection-method'
import AccountCreditCard from './partials/account-credit-card'
import AccountPaymentMethod from './partials/account-payment-method'
import AccountPaymentTimes from './partials/account-payment-times'
import AccountDate from './partials/account-date'
import ValidationErrorMessage from 'src/components/commons/validation-error-message'

// lib
import Sticky from 'src/lib/class-sticky'
import config from 'src/config'
import { isValidAccount } from 'src/lib/validate'
import { Box } from '@mui/material'

const mapStateToProps = state => ({
  creditCards: state.master.data.creditCards,
})

const Account = props => {
  const {
    paymentIndex,
    account,
    route,
    sticky,
    disabled,
    isConfirmed,
    onChange,
    onChangeMeta,
    highlight,
    isCollectSticky,
    creditCards,
  } = props

  useEffect(() => {
    if (!hasRemain && account.data.accountDate) {
      const updateAccountDate = {
        ...account.data,
        accountDate: '',
      }
      onChange(updateAccountDate, paymentIndex)
    }
  }, [hasRemain])

  const createUpdateHandler = propName => value => {
    onChange({ [propName]: value }, paymentIndex)
  }

  // 支払回数1回で固定するタイプのカードの時も支払回数・分割回数を無効にする。
  const checkIsOneTimeOnly = id => {
    const result =
      creditCards && creditCards.find(creditCard => creditCard.id === id)
    return result ? result.isOneTime : null
  }
  const specialCreditCard = checkIsOneTimeOnly(account.data.accountCreditCardId)

  // クレジットカードの変更によって支払回数を設定する
  const updateAccountCreditCardId = accountCreditCardId => {
    const selectedCreditCard = checkIsOneTimeOnly(accountCreditCardId)
    const accountPaymentMethodId = selectedCreditCard ? 1 : -1
    const payment = { accountCreditCardId, accountPaymentMethodId }
    onChange(payment, paymentIndex)
  }

  const { messages } = isValidAccount(account, isCollectSticky)

  // 通常付箋：クレジットカード、集金付箋：クレジットカードと集金の時
  const isCreditCard =
    account.data.accountCollectionMethodId ===
      config.howToRetrieve.creditCard ||
    (isCollectSticky &&
      account.data.accountCollectionMethodId === config.howToRetrieve.collect)

  // クレジットカードで分割以外の時は支払回数を無効にする
  const creditCardTimesDisabled =
    isCreditCard &&
    account.data.accountPaymentMethodId !== config.paymentMethodId.installments

  // 残金があるか
  const hasRemain =
    (account.data.accountValue || 0) -
      (account.data.accountCollectedValue || 0) >
    0

  // 金額が回収済みか
  const childCollected = account.data.childCollected || false
  // 集金付箋が着手中か
  const childWorkStart = account.data.childWorkStart || false

  // メモ：集金付箋の時は「回収方法」の代わりに「次回の回収方法」を位置を変えて表示する。
  //   設定するaccountの項目自体は同じもので、表示名と位置のみが異なる。
  //   「次回〜」のためにNextCollectionMethod を作ったが使わないように変更。
  return (
    <div className={ highlight ? 'is-selected' : '' }>
      <div
        style={ {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'baseline',
        } }
      >
        <PayerSelect
          label={ '支払者' }
          paymentIndex={ paymentIndex }
          payment={ account }
          sticky={ sticky }
          route={ route }
          disabled={ disabled }
          onChangeMeta={ onChangeMeta }
        />
        <span
          style={ {
            marginLeft: '1.5rem',
            marginRight: '0.75rem',
            fontWeight: 'bold',
          } }
        >
          {'契約書No. '}
        </span>
        <span>{account.data.contractNumber || '----'}</span>
      </div>
      <SendRequest
        label={ '請求書要送付' }
        sticky={ sticky }
        data={ account.data }
        paymentKey={ 'invoice' }
        paymentIndex={ paymentIndex }
        disabled={ disabled }
        onChange={ onChange }
        route={ route }
      />
      <SendRequest
        label={ '領収書要送付' }
        sticky={ sticky }
        data={ account.data }
        paymentKey={ 'receipt' }
        paymentIndex={ paymentIndex }
        disabled={ disabled }
        onChange={ onChange }
        route={ route }
      />
      <Box
        sx={ {
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
          columnGap: '1.5rem',
        } }
      >
        <AccountValue
          value={ account.data.accountValue }
          sticky={ sticky }
          route={ route }
          update={ createUpdateHandler('accountValue') }
          disabled={ disabled || isCollectSticky || isConfirmed }
          readOnly={ isCollectSticky }
        />
        {isCollectSticky && (
          <AccountCollectedValue
            value={ account.data.accountCollectedValue }
            sticky={ sticky }
            route={ route }
            update={ createUpdateHandler('accountCollectedValue') }
            disabled={ disabled || isConfirmed }
            isValid={
              (account.data.accountValue || 0) -
                (account.data.accountCollectedValue || 0) >=
              0
            }
          />
        )}
        {!isCollectSticky && (
          <AccountCollectionMethod
            value={ account.data.accountCollectionMethodId || -1 }
            sticky={ sticky }
            route={ route }
            update={ createUpdateHandler('accountCollectionMethodId') }
            disabled={ disabled || childCollected || childWorkStart }
            label={ '回収方法' }
          />
        )}
        <AccountCreditCard
          value={ account.data.accountCreditCardId || -1 }
          sticky={ sticky }
          route={ route }
          update={ updateAccountCreditCardId }
          disabled={ disabled || !isCreditCard }
        />
        <AccountPaymentMethod
          value={ account.data.accountPaymentMethodId || -1 }
          sticky={ sticky }
          route={ route }
          update={ createUpdateHandler('accountPaymentMethodId') }
          disabled={ disabled || !isCreditCard || specialCreditCard }
        />
        <AccountPaymentTimes
          value={ account.data.accountPaymentTimes || -1 }
          sticky={ sticky }
          route={ route }
          update={ createUpdateHandler('accountPaymentTimes') }
          disabled={
            disabled ||
            !isCreditCard ||
            creditCardTimesDisabled ||
            specialCreditCard
          }
        />
        {isCollectSticky && (
          <AccountCollectionMethod
            value={ account.data.accountCollectionMethodId || -1 }
            sticky={ sticky }
            route={ route }
            update={ createUpdateHandler('accountCollectionMethodId') }
            disabled={ disabled || !hasRemain }
            label={ '次回の回収方法' }
          />
        )}
        <AccountDate
          value={ hasRemain ? account.data.accountDate || '' : '' }
          sticky={ sticky }
          route={ route }
          update={ createUpdateHandler('accountDate') }
          disabled={ disabled || !hasRemain || childCollected }
          required={ false }
        />
      </Box>

      {messages.map(message => (
        <ValidationErrorMessage key={ message } message={ message } />
      ))}
    </div>
  )
}

Account.propTypes = {
  // ownProps
  paymentIndex: PropTypes.number.isRequired,
  account: PropTypes.object.isRequired,
  route: PropTypes.string.isRequired,
  sticky: PropTypes.instanceOf(Sticky).isRequired,
  disabled: PropTypes.bool,
  isConfirmed: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onChangeMeta: PropTypes.func.isRequired,
  highlight: PropTypes.bool.isRequired,
  isCollectSticky: PropTypes.bool,
  creditCards: PropTypes.bool.isRequired,
}

Account.defaultProps = {
  disabled: false,
  isConfirmed: false,
  isCollectSticky: false,
}

export default connect(mapStateToProps)(React.memo(Account))
