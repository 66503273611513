import utils from 'src/lib/api-utils'

/**
 * 未回収売掛金のダウンロード
 * @param  {number} accountId sticky contact ID
 * @param  {string} accessToken     アクセストークン
 * @param  {object} env             環境情報
 * @return {Promise}                fetch
 */
export const get = (accountId, accessToken, env) => {
  const base = utils.createEndpoint(env, 'accountHistory')
  const url = `${base}/${accountId}`

  const request = fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  })

  return request
}

export default get
