/**
 * custom-controlsをReduxに繋ぐ共通処理
 * @file
 */

import { actionCreators as $1 } from 'src/reducers/wara'
import { actionCreators as $2 } from 'src/reducers/stages'
import { connect } from 'react-redux'
import { createAsyncCityCodeUpdateAction } from 'src/reducers/master'

/**
 map state to props
 * @param  {object} state state
 * @return {object}      mapped state
 */
export const mapStateToProps = state => {
  return {
    staffs: (state.wara.data.lists || []).map(list => [list.id, list.ss]),
    staged: state.wara.modal.stage,
    wara: state.wara.data,
    login: {
      isLoggedIn: state.login.authentication.accessToken,
      userid: state.login.authentication.userid,
      displayName: state.login.authentication.displayName,
      accessToken: state.login.authentication.accessToken,
    },
    master: state.master.data,
    cityCodes: state.master.cityCodes || {},
    zipCodeNetworkError: state.master.zipCodeNetworkError,
    isDebugMode: state.env.DEBUG,
    networkStatuses: state.networkStatus,
    env: state.env,
    displayDate: state.displayDate.displayDate,
    callStaffs: state.master.data.staffs,
  }
}

/**
 * map dispatch to props
 * @param  {function} dispatch dispatcher
 * @param  {object} props       Props
 * @return {object}            mapped auto bind action creators
 */
export const mapDispatchToProps = (dispatch, props) => {
  // TODO $2 はいらなくなる
  const $ = props.route === 'history' ? $2 : $1

  return {
    /**
     * モーダルを閉じる
     * @return {void}
     */
    closeModal: () =>
      props.route === 'assign'
        ? dispatch($1.closeModal())
        : () => {
          // NOTE ここはコントロールされており、assign ルート以外でモーダルを閉じるような処理は呼ばれないはず
          // eslint-disable-next-line no-console
          console.warn(
            '意図しないタイミングで、モーダルを閉じようとしています。',
          )
        },

    /**
     * prefCodeで与えられた都道府県に属する市町村マスタを取得する
     * @param  {string} prefCode    都道府県コード
     * @param  {string} accessToken アクセストークン
     * @return {void}
     */
    __getCityCodes: env => (prefCode, accessToken) =>
      dispatch(createAsyncCityCodeUpdateAction(prefCode, accessToken, env)),

    /**
     * アサイン課で入力確定のボタンのdisabledを切り替える
     * @param  {boolean} disabled disabled
     * @return {void}
     */
    toggleAssignButtonForceDisabled: disabled => {
      props.route === 'assign'
        ? dispatch($1.toggleAssignButtonForceDisabled(disabled))
        : () => {
          // NOTE ここはコントロールされており、assign ルート以外でこのボタンを不活化するような処理は呼ばれないはず
          // eslint-disable-next-line no-console
          console.warn(
            '意図しないタイミングで、ボタンを不活化する処理が呼ばれました。',
          )
        }
    },

    /**
     * stageを新しいstickyインスタンスに置き換える
     * @param  {Sticky} sticky 新しいstickyインスタンス
     * @return {void}
     */
    updateStage: props.update || (sticky => dispatch($.updateStage(sticky))),

    /**
     * 付箋を配置する
     * @param {*} location
     * @param {*} sticky
     */
    addSticky: (location, sticky) => dispatch($1.addSticky(location, sticky)),
  }
}

/**
 * map state to props
 * @param  {object} stateProps    state props
 * @param  {object} dispatchProps dispatch props
 * @param  {object} ownProps      own props
 * @return {object}               props
 */
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    // merge
    getCityCodes: dispatchProps.__getCityCodes(stateProps.env),
    // make private
    __getCityCodes: void 0,
  }
}

/**
 * @type {function} 共通するconnect関数
 */
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)
