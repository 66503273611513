// action types
import { ACTION_TYPES as LOGIN_ACTION_TYPES } from 'src/reducers/login'
import { ACTION_TYPES as MASTER_ACTION_TYPES } from 'src/reducers/master'

// lib and config
import switz from 'switz'
import config from 'src/config'
import { LSUtil } from 'src/lib/local-storage'

/**
 * ローカルストレージのキー
 */
const { ACCESS_TOKEN_KEY, USERID_KEY, VERSION_INFO_KEY } = config.constants.keys

/**
 * テスト用にエクスポートされた、ローカルストレージからアクセストークンを取得するためのキー
 * @type {string|null}
 */
export const LOCAL_STORAGE_ACCESS_TOKEN_KEY = __TEST__ ? ACCESS_TOKEN_KEY : null

/**
 * テスト用にエクスポートされた、ローカルストレージからユーザーIDを取得するためのキー
 * @type {string|null}
 */
export const LOCAL_STORAGE_USERID_KEY = __TEST__ ? USERID_KEY : null

/**
 * テスト用にエクスポートされた、ローカルストレージからアプリのバージョン情報を取得するためのキー
 * @type {string|null}
 */
export const LOCAL_STORAGE_VERSION_INFO_KEY = __TEST__ ? VERSION_INFO_KEY : null

/**
 * LocalStorageとの間の副作用を司るMiddleware
 * @param  {object} store  Redux store
 * @return {function} callback typed (next: function) => (action: object) => void
 */
export const localStorageIO = () => {
  return next => action => {
    // ログイン成功時・マスタ取得成功時にローカルストレージに値を格納
    switz(action.type, s =>
      s
        .case(LOGIN_ACTION_TYPES.LOGIN_SUCCESS, () => {
          const { token: accessToken } = action.payload
          localStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
        })
        .case(LOGIN_ACTION_TYPES.SET_USERNAME, () => {
          localStorage.setItem(USERID_KEY, action.payload.userid)
        })
        .case(MASTER_ACTION_TYPES.MASTER_GET_SUCCESS, () => {
          const masterData = action.payload
          const key = action.meta.masterKey
          const LS_MASTER_KEY = `${config.constants.keys.LS_MASTER_PREFIX}-${key}`
          localStorage.setItem(LS_MASTER_KEY, JSON.stringify(masterData))
        })
        .case(MASTER_ACTION_TYPES.CITYCODE_SUCCESS, () => {
          const cityCodeData = action.payload
          const code = action.meta.prefCode
          const LS_CITY_CODES_KEY = `${config.constants.keys.LS_CITYCODES_PREFIX}-${code}`
          localStorage.setItem(LS_CITY_CODES_KEY, JSON.stringify(cityCodeData))
        })
        .case(LOGIN_ACTION_TYPES.DO_LOGOUT, () => LSUtil.preservableClear()),
    )

    next(action)
  }
}

/**
 * export
 */
export default localStorageIO
