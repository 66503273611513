import { createInventoryEndpoint } from 'src/lib/api-utils'

/**
 * GraphQueryによる見積書・請求書PDF一覧の取得
 * @returns
 */
const query = (body, accessToken, env) => {
  const url = createInventoryEndpoint(env, 'query')
  const request = fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body,
  })
  return request
}

/**
 * 見積書PDF一覧
 * @param {*} stickyId
 * @param {*} accessToken
 * @param {*} env
 * @returns
 */
export const listQuotation = (stickyId, accessToken, env) => {
  const body = {
    query: `query listQuotation($stickyId: Int!) {
      listQuotation(stickyId: $stickyId) {
        id
        stickyId
        orderId
        staffId
        quotationNumber
        documentStatus
        issuedDate
        limitDate
        s3Filename
        uploadAt
      }
    }
  `,
    variables: {
      stickyId,
    },
  }
  return query(JSON.stringify(body), accessToken, env)
}

/**
 * 見積書PDF一覧
 * @param {*} orderId
 * @param {*} accessToken
 * @param {*} env
 * @returns
 */
export const listQuotationByOrderId = (orderId, accessToken, env) => {
  const body = {
    query: `query listQuotationByOrderId($orderId: String!) {
      listQuotationByOrderId(orderId: $orderId) {
        id
        stickyId
        orderId
        staffId
        quotationNumber
        documentStatus
        issuedDate
        limitDate
        s3Filename
        uploadAt
      }
    }
  `,
    variables: {
      orderId,
    },
  }
  return query(JSON.stringify(body), accessToken, env)
}

/**
 * 請求書PDF一覧
 * @param {*} stickyId
 * @param {*} accessToken
 * @param {*} env
 * @returns
 */
export const listContract = (stickyId, accessToken, env) => {
  const body = {
    query: `query listContract($stickyId: Int!) {
      listContract(stickyId: $stickyId) {
        id
        quotationId
        stickyId
        orderId
        staffId
        contractNumber
        documentStatus
        issuedDate
        s3Filename
        uploadAt
      }
    }
  `,
    variables: {
      stickyId,
    },
  }
  return query(JSON.stringify(body), accessToken, env)
}

/**
 * 請求書PDF一覧
 * @param {*} orderId
 * @param {*} accessToken
 * @param {*} env
 * @returns
 */
export const listContractByOrderId = (orderId, accessToken, env) => {
  const body = {
    query: `query listContractByOrderId($orderId: String!) {
      listContractByOrderId(orderId: $orderId) {
        id
        quotationId
        stickyId
        orderId
        staffId
        contractNumber
        documentStatus
        issuedDate
        s3Filename
        workComplete
        uploadAt
      }
    }
  `,
    variables: {
      orderId,
    },
  }
  return query(JSON.stringify(body), accessToken, env)
}

/**
 * 工事完了確認書PDF一覧
 * @param {*} stickyId
 * @param {*} accessToken
 * @param {*} env
 * @returns
 */
export const listWorkComplete = (stickyId, accessToken, env) => {
  const body = {
    query: `query listWorkComplete($stickyId: Int!) {
      listWorkComplete(stickyId: $stickyId) {
        id
        quotationId
        stickyId
        orderId
        staffId
        workCompleteNumber
        documentStatus
        workCompleteDate
        s3Filename
        uploadAt
      }
    }
  `,
    variables: {
      stickyId,
    },
  }
  return query(JSON.stringify(body), accessToken, env)
}

export const listWorkCompleteByOrderId = (orderId, accessToken, env) => {
  const body = {
    query: `query listWorkCompleteByOrderId($orderId: String!) {
      listWorkCompleteByOrderId(orderId: $orderId) {
        id
        quotationId
        stickyId
        orderId
        staffId
        workCompleteNumber
        documentStatus
        workCompleteDate
        s3Filename
        uploadAt
      }
    }
  `,
    variables: {
      orderId,
    },
  }
  return query(JSON.stringify(body), accessToken, env)
}

/**
 * 領収書PDF一覧
 * @param {*} stickyId
 * @param {*} accessToken
 * @param {*} env
 * @returns
 */
export const listReceiptByOrderId = (orderId, accessToken, env) => {
  const body = {
    query: `query listReceiptByOrderId($orderId: String!) {
      listReceiptByOrderId(orderId: $orderId) {
        id
        quotationId
        stickyId
        orderId
        staffId
        receiptNumber
        documentStatus
        documentStatus
        issuedDate
        s3Filename
        uploadAt
      }
    }
  `,
    variables: {
      orderId,
    },
  }
  return query(JSON.stringify(body), accessToken, env)
}

/**
 * 出庫同意PDF一覧
 * @param {*} stickyId
 * @param {*} accessToken
 * @param {*} env
 * @returns
 */
export const listShipInventoryByOrderId = (orderId, accessToken, env) => {
  const body = {
    query: `query listShipInventoryByOrderId($orderId: String!) {
      listShipInventoryByOrderId(orderId: $orderId) {
        id
        stickyId
        orderId
        toStaffId
        s3Filename
        createAt
        createUserId
      }
    }
  `,
    variables: {
      orderId,
    },
  }
  return query(JSON.stringify(body), accessToken, env)
}

/**
 * 作業完了確認書PDF一覧
 * @param {*} stickyId
 * @param {*} accessToken
 * @param {*} env
 * @returns
 */
export const listHLWorkCompleteByOrderId = (orderId, accessToken, env) => {
  const body = {
    query: `query listHLWorkCompleteByOrderId($orderId: String!) {
      listHLWorkCompleteByOrderId(orderId: $orderId) {
        id
        stickyId
        orderId
        staffId
        hlWorkCompleteNumber
        documentStatus
        s3Filename
        uploadAt
      }
    }
  `,
    variables: {
      orderId,
    },
  }
  return query(JSON.stringify(body), accessToken, env)
}

/**
 * 「交換の達人」工事完了確認書PDF一覧
 * @param {*} stickyId
 * @param {*} accessToken
 * @param {*} env
 * @returns
 */
export const listECWorkCompleteByOrderId = (orderId, accessToken, env) => {
  const body = {
    query: `query listECWorkCompleteByOrderId($orderId: String!) {
      listECWorkCompleteByOrderId(orderId: $orderId) {
        id
        stickyId
        orderId
        staffId
        ecWorkCompleteNumber
        documentStatus
        issuedDate
        s3Filename
        uploadAt
      }
    }
  `,
    variables: {
      orderId,
    },
  }
  return query(JSON.stringify(body), accessToken, env)
}

/**
 * 「交換の達人」工事完了確認書PDF一覧
 * @param {*} stickyId
 * @param {*} accessToken
 * @param {*} env
 * @returns
 */
export const listESignAgreementByOrderId = (orderId, accessToken, env) => {
  const body = {
    query: `query listESignAgreementByOrderId($orderId: String!) {
      listESignAgreementByOrderId(orderId: $orderId) {
        id
        stickyId
        orderId
        staffId
        eSignAgreementNumber
        quotationNumber
        documentStatus
        issuedDate
        s3Filename
        uploadAt
      }
    }
  `,
    variables: {
      orderId,
    },
  }
  return query(JSON.stringify(body), accessToken, env)
}
