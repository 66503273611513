import switz from 'switz'
import update from 'immutability-helper'

/**
 * initial state
 * @type {object}
 */
export const initialMessengerState = {}

/**
 * Action Types
 * @type {string}
 */
export const SET = 'MESSENGER.SET'
export const UNSET = 'MESSENGER.UNSET'

/**
 * action bulk exports
 * @type {object}
 */
export const ACTION_TYPES = {
  SET,
  UNSET,
}

export const createActions = {
  set: (key, value) => ({ type: SET, payload: { key, value } }),
  unset: key => ({ type: SET, payload: { key } }),
}

/**
 * clicker reducer
 * @param  {object} [state=initialDnDState] previous state
 * @param  {object} action                 dispatched action
 * @return {object}                         next state
 */
export default (state = initialMessengerState, action) => {
  const { type, payload: { key, value } = {} } = action

  return switz(type, s =>
    s
      .case(SET, () => update(state, { [key]: { $set: value } }))
      .case(UNSET, () => update(state, { [key]: { $set: void 0 } }))
      .default(() => state),
  )
}
