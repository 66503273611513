import utils from 'src/lib/api-utils'
import config from 'src/config'

/**
 * 付箋が更新可能かを判定する。
 *
 * @param {*} stickyId
 * @param {*} updatedAt
 * @param {*} accessToken
 * @param {*} env
 */
const requestCanUpdateSticky = (stickyId, updateAt, accessToken, env) => {
  const endpoint = utils.createEndpoint(env, 'canUpdateSticky')

  const body = JSON.stringify({
    id: stickyId,
    updateAt,
  })

  return fetch(endpoint, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body,
  }).then(res => {
    if (res.ok) {
      return res.json()
    } else if (res.status === config.conflictStatus) {
      throw config.conflictError
    } else {
      throw 'POSTエラー'
    }
  })
}

export default requestCanUpdateSticky
