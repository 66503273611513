import { connect as _connect } from 'react-redux'

/**
 map state to props
 * @param  {object} state mapping state
 * @return {object}       mapped state as props
 */
export const mapStateToProps = state => ({
  postStatus: state.networkStatus.postSticky,
  isDebugMode: state.env.DEBUG,
  accessToken: state.login.authentication.accessToken,
  env: state.env,
})

export const connect = Copmonent => _connect(mapStateToProps)(Copmonent)

export default connect
