import React from 'react'
import PropTypes from 'prop-types'
import connect from './connect'
import Sticky from 'src/lib/class-sticky'

/**
 * メモ
 * 伝言付箋で用いられる
 * workContent: stringプロパティとしてシリアライズする
 * @type {ReactComponent}
 */
export class Memo extends React.Component {
  /**
   * Validation
   * @type {object}
   */
  static propTypes = {
    // ownProps
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
    disabled: PropTypes.bool.isRequired,
    // dispatchProps
    updateStage: PropTypes.func.isRequired,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return (
      this.props.sticky.json().workContent !==
        nextProps.sticky.json().workContent ||
      this.props.disabled !== nextProps.disabled
    )
  }

  /**
   * text area change handler
   * @param  {Event} e event
   * @return {void}
   */
  onTextChange = e => {
    const { updateStage } = this.props
    const diffProps = { workContent: e.target.value }
    updateStage(new Sticky(diffProps))
  }

  /**
   * render
   * @return {ReactDOM} dom
   */
  render() {
    const { sticky, route, disabled } = this.props
    const workContent = sticky.json().workContent || ''

    return (
      <dl className={ 'label-inputs-wrap' } id={ `input-memo-${route}` }>
        <dt>
          <label htmlFor={ `input-memo-${route}` }>{'メモ'}</label>
        </dt>

        <dd className={ 'input-wrap' }>
          <textarea
            value={ workContent }
            id={ `input-memo-form-${route}` }
            name={ `input-memo-form-${route}` }
            onChange={ this.onTextChange }
            className={ 'long' }
            disabled={ disabled }
          />
        </dd>
      </dl>
    )
  }
}

export default connect(Memo)
