import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useState } from 'react'
import connect from '../connect'

// component
import ValidationErrorMessage from 'src/components/commons/validation-error-message'
import WrappedCalendar from 'src/components/commons/wrapped-calendar'
import Radio from 'src/styled/form/radio'

// lib
import { Box, Button, Modal, Typography } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import moment from 'moment'
import config from 'src/config'
import Sticky from 'src/lib/class-sticky'
import theme from 'src/theme.js'

/**
 * 顧客タイプ選択
 * @type {ReactComponent}
 */
const FinishState = ({
  sticky,
  isSpecial,
  route,
  disabled,
  master,
  updateStage,
}) => {
  const [editCompleteDate, setEditCompleteDate] = useState(false)
  const [completeDate, setCompleteDate] = useState('')

  const stickyProps = sticky.json() || {}
  const finishStateId = stickyProps.finishStateId || -1
  const completedAt = stickyProps.completedAt || undefined
  const afterDateTime = stickyProps.afterDateTime || {}
  const suspendReason = stickyProps.suspendReason || {}
  const isDocked = false // 未配置の条件をチェックする

  console.log({ stickyProps })

  const completedDate = completedAt
    ? moment(completedAt).format('YYYY-MM-DD')
    : ''
  const completeTime = completedAt
    ? moment(completedAt).format('HH:mm:ss')
    : ''

  const masterFinishStates = useMemo(() => {
    return (master.finishStates || [])
      .filter(
        x =>
          isSpecial
            ? x.id === config.finishState.complete ||
              finishStateId === config.finishState.reformComplete
            : true,
      )
      .concat({ id: -1, name: '未完了' })
  }, [master.finishStates, isSpecial, finishStateId])

  const isFinished =
    finishStateId === config.finishState.complete ||
    finishStateId === config.finishState.cancel ||
    finishStateId === config.finishState.na ||
    finishStateId === config.finishState.reformComplete

  const suspendReasonIds = (suspendReason || {}).ids || []
  const isSuspend =
      suspendReasonIds.includes(config.suspendReason.suspend1) ||
      suspendReasonIds.includes(config.suspendReason.afterUndecided)
  const forceDisabled = disabled || isSuspend

  const registerDisabled =
    (route === 'assign' || route === 'history') && !!afterDateTime.date

  /**
   * on change handler
   * @param  {Event} e event
   * @return {void}
   */
  const onChange = useCallback(
    e => {
      const val = parseInt(e.target.value, 10)
      const finishStateId = isNaN(val) ? -1 : val

      // Req1207: 未着手の付箋を完了・リフォーム引継するときに警告を出す。
      const isWorking =
        (sticky.json().stickyStatusId || -1) === config.stickyStatus.working
      if (
        !isWorking &&
        (finishStateId === config.finishState.complete ||
          finishStateId === config.finishState.reformComplete)
      ) {
        if (!confirm('着手されていない案件を完了しますがよろしいですか？')) {
          return
        }
      }

      let diffSticky = new Sticky({}, { normalize: false })

      if (
        finishStateId === config.finishState.complete ||
        finishStateId === config.finishState.cancel ||
        finishStateId === config.finishState.na ||
        finishStateId === config.finishState.reformComplete
      ) {
        diffSticky = diffSticky.update(
          {
            finishStateId,
            completedAt: moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
          },
          { normalize: false },
        )
      } else {
        diffSticky = diffSticky.update(
          { finishStateId, completedAt: '' },
          { normalize: false },
        )
      }

      // 終了状態を「キャンセル」以外にした時、キャンセル理由をクリアする。
      if (finishStateId !== config.finishState.cancel) {
        diffSticky = diffSticky.update({ cancelReasonId: -1 })
      }

      // 終了状態を「除外対象」以外にした時、除外対象理由をクリアする。
      if (finishStateId !== config.finishState.na) {
        diffSticky = diffSticky.update({ naReasonId: -1, ignoreCount: false })
      }

      updateStage(diffSticky)
    },
    [sticky, updateStage],
  )

  const groupName = `select-finish-state-type-${route}`

  // 完了日編集ダイアログを表示
  const openEditCompleteDate = useCallback(date => {
    setEditCompleteDate(true)
    setCompleteDate(date)
  }, [])

  // 完了日編集ダイアログを閉じる
  const closeEditComplete = useCallback(() => {
    setEditCompleteDate(false)
  }, [])

  // 完了日を更新
  const updateEditCompleteDate = useCallback(() => {
    const diffProps = {
      completedAt: `${completeDate} 12:00:00.000`,
    }
    updateStage(new Sticky(diffProps))
    setEditCompleteDate(false)
  }, [completeDate, updateStage])

  // 完了日が選択された
  const onDateSelect = useCallback(date => {
    setCompleteDate(date)
  }, [])

  return (
    <ThemeProvider theme={ theme }>
      <dl className={ 'label-inputs-wrap' }>
        <dt className={ 'input-headline' } style={ { marginRight: '1.5rem' } }>
          <label
            htmlFor={ `select-finish-state-type-${route}` }
            className={ 'is-required' }
          >
            {'終了状態'}
          </label>
        </dt>

        <dd className={ 'input-wrap' } id={ `select-finish-state-type-${route}` }>
          {masterFinishStates.map(option => {
            const eachDisabled = forceDisabled
            return (
              <Radio
                key={ option.id }
                labelText={ option.name }
                checked={ option.id === finishStateId }
                value={ option.id }
                name={ groupName }
                onChange={ onChange }
                disabled={ eachDisabled }
              />
            )
          })}
          {registerDisabled && route === 'assign' && isDocked ? (
            <ValidationErrorMessage
              message={ 'この案件は未配置のため完了できません' }
            />
          ) : null}
          {/*
          {registerDisabled && route === 'assign' && hasChild ? (
            <ValidationErrorMessage
              message={ 'この案件にはアフタが設定されているため完了できません。' }
            />
          ) : null}
          */}
        </dd>

        {isFinished ? (
          <>
            <dt className={ 'primary-label' }>
              <label htmlFor={ 'completed-date' }>{'完了日'}</label>
            </dt>
            <dd className={ 'input-wrap calendar-wrap' }>{completedDate}</dd>
            <dt className={ 'primary-label' }>
              <label htmlFor={ 'completed-time' }>{'完了時間'}</label>
            </dt>
            <dd className={ 'input-wrap calendar-wrap' }>{completeTime}</dd>
            <button
              className={ 'button display-releaf_button' }
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => openEditCompleteDate(completedDate) }
            >
              {'完了日編集'}
            </button>
            {editCompleteDate && (
              <Modal
                open={ editCompleteDate }
                onClose={ closeEditComplete }
                sx={ { position: 'relative', zIndex: 100000 } }
              >
                <Box
                  sx={ {
                    position: 'absolute',
                    bottom: 400,
                    right: 156,
                    display: 'grid',
                    gridTemplateRows: 'repeat(3, max-content)',
                    rowGap: '0.5rem',
                    padding: '0.75rem',
                    background: '#fff',
                    width: 'max-content',
                  } }
                >
                  <Typography variant="subtitle1">{'完了日編集'}</Typography>
                  <WrappedCalendar
                    buttonId={ `complete-date-${route}` }
                    inputId={ `complete-date-${route}-input` }
                    value={ completeDate }
                    onSelect={ onDateSelect }
                    disabled={ forceDisabled }
                  />
                  <Box sx={ {
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, max-content)',
                    justifyContent: 'right',
                    alignItems: 'center',
                    columnGap: '1.5rem',
                  } }
                  >
                    <Button
                      color="primary"
                      onClick={ updateEditCompleteDate }
                    >
                      {'OK'}
                    </Button>
                    <Button color="primary" onClick={ closeEditComplete }>
                      {'閉じる'}
                    </Button>
                  </Box>
                </Box>
              </Modal>
            )}
          </>
        ) : null}
      </dl>
    </ThemeProvider>
  )
}

/**
   * Validation
   * @type {object}
   */
FinishState.propTypes = {
  sticky: PropTypes.instanceOf(Sticky).isRequired,
  isSpecial: PropTypes.bool,
  route: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    regionIndex: PropTypes.number.isRequired,
    listIndex: PropTypes.number.isRequired,
    stickyIndex: PropTypes.number.isRequired,
  }).isRequired,
  // stateProps
  master: PropTypes.shape({
    finishStates: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  // Auto bind action creator
  updateStage: PropTypes.func.isRequired,
}

/**
   * Defaults
   * @type {object}
   */
FinishState.defaultProps = {
  suspendReason: { ids: [], freeDescribe: '' },
  childId: -1,
  isSpecial: false,
}

export default connect(FinishState)
