import styled, { css } from 'styled-components'
import button, { buttonLine, buttonFill } from 'src/styled/button'
import {
  white,
  green,
  red,
  borderDarkGray,
  bgGray,
  textColorA,
  stickyYellow,
} from 'src/colors'

export const NoContents = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  padding: 20px;
`
export const DocumentsTitle = styled.span`
  margin-right: 10px;
`
export const Screen = styled.div`
  background-color: ${white};
  padding: 20px;
`

export const ScreenHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5em;
`
export const ErrorMessage = styled.span`
  color: ${red};
  margin-left: 1em;
`

export const DeleteButton = styled.button`
  ${button};
  ${buttonLine(red)};
  ${buttonFill(red)};
  margin-bottom: 10px;
`

export const ContractWrap = styled.div`
  display: block;
`

// 1:無効
// 2:前週以前提出済みで連番
// 3:前週以前提出済みで飛び番
// 4:未使用
// 5:今週提出済
// 6:未提出
// 7:紛失
const statuses = {
  // 1:無効
  1: css`
    ${button};
    ${buttonLine(borderDarkGray)};
    ${buttonFill(borderDarkGray)};
    color: ${white};

    &:hover:not([disabled]) {
      color: ${white};
    }
  `,
  // 2:前週以前提出済みで連番
  2: css`
    ${button};
    ${buttonLine(borderDarkGray)};
    ${buttonFill(bgGray)};
    color: ${textColorA};
  `,
  // 3:前週以前提出済みで飛び番
  3: css`
    ${button};
    ${buttonLine(red)};
    ${buttonFill(red)};

    &:hover:not([disabled]) {
      color: ${white};
    }
  `,
  // 4:未使用
  4: css`
    ${button};
    ${buttonLine(green)};
  `,
  // 5:今週提出済
  5: css`
    ${button};
    ${buttonLine(textColorA)};
    ${buttonFill(textColorA)};
    color: ${white};

    &:hover:not([disabled]) {
      color: ${white};
    }
  `,
  // 6:未提出
  6: css`
    ${button};
    ${buttonLine(borderDarkGray)};
    ${buttonFill(stickyYellow)};
    color: ${textColorA};
  `,
  // 7:紛失
  7: css`
    ${button};
    ${buttonLine('#c259ff')};
    ${buttonFill('#c259ff')};
    color: ${white};

    &:hover:not([disabled]) {
      color: ${white};
    }
  `,
  // 14:未使用:見積書
  14: css`
    ${button};
    ${buttonLine('#00bfff')};
    ${buttonFill('#00bfff')};
  `,
  // 24:未使用:契約書
  24: css`
    ${button};
    ${buttonLine('#7cfc00')};
    ${buttonFill('#7cfc00')};
    color: ${textColorA};
  `,
  // 34:未使用:領収書
  34: css`
    ${button};
    ${buttonLine('#ff8c00')};
    ${buttonFill('#ff8c00')};
  `,
  // 44:未使用:作業確認図
  44: css`
    ${button};
    ${buttonLine('#4b0082')};
    ${buttonFill('#4b0082')};
  `,
  // 54:未使用:工事完了確認書
  54: css`
    ${button};
    ${buttonLine('#90ee90')};
    ${buttonFill('#90ee90')};
    color: ${textColorA};
  `,
}

const selectStyle = props => {
  if (props.status === 4) {
    // 「4:未使用」の時はdocumentTypeに応じて色を変える
    return statuses[props.documentType * 10 + props.status]
  } else {
    return statuses[props.status]
  }
}

export const ContractButton = styled.button`
  ${selectStyle};
  padding: 2px 0 1px;
  width: 60px;
  text-align: center;
`
