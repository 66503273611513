// react
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// components
import ValidationErrorMessage from 'src/components/commons/validation-error-message'
import { Screen, Flex, Button, Table, Caption, Tr, Th, Td } from './styled'

// lib
import getFacility from 'src/lib/facility-api/get'
import getFamily from 'src/lib/family-api/get'
import Sticky from 'src/lib/class-sticky'
import { makeCancelable, noop } from 'src/lib/cancelable-promise'

export class FacilityFamilyModal extends React.PureComponent {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    // stateProps
    facilityType: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
    facilityAge: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
    familyRelationship: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
    ageGroup: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
    accessToken: PropTypes.string.isRequired,
    env: PropTypes.object.isRequired,
  }

  state = {
    isOpen: false,
    error: false,
    facility: [],
    family: [],
    onUnMount: noop,
  }

  /**
   * componentDidMount
   * @return {void}
   */
  componentDidMount() {
    const { sticky } = this.props
    const stickyId = sticky.id
    const stickyContactId = (sticky.findClient() || {}).id

    this.request(stickyId, stickyContactId)
  }

  /**
   * componentWillUnmount
   * @return {void}
   */
  componentWillUnmount() {
    this.state.onUnMount()
  }

  request = (stickyId, stickyContactId) => {
    const { promise, cancel } = makeCancelable(
      Promise.all([
        getFacility(stickyId, this.props.accessToken, this.props.env).then(
          res => {
            if (res.ok) {
              return res.json()
            } else {
              throw 'error'
            }
          },
        ),
        getFamily(stickyContactId, this.props.accessToken, this.props.env).then(
          res => {
            if (res.ok) {
              return res.json()
            } else {
              throw 'error'
            }
          },
        ),
      ]),
    )

    this.setState({ onUnMount: cancel })

    return promise
      .then(results => {
        if (
          stickyId === results[0].stickyId &&
          stickyContactId === results[1].stickyContactId
        ) {
          this.setState({
            facility: results[0].facility.map(this.extendFacility),
            family: results[1].family.map(this.extendFamily),
          })
        } else {
          throw 'id不一致'
        }
      })
      .catch(this.raiseError)
  }

  toggleModal = () => this.setState({ isOpen: !this.state.isOpen })

  raiseError = err => console.error(err) || this.setState({ error: true })

  extendFacility = ({ id, facilityTypeId, facilityAgeId }) => {
    const { facilityType, facilityAge } = this.props
    return {
      id,
      type: (facilityType.find(x => x.id === facilityTypeId) || {}).name || '-',
      age: (facilityAge.find(x => x.id === facilityAgeId) || {}).name || '-',
    }
  }

  /**
   * 家族構成を表示データに変換する
   * @param {*} 家族構成データ
   */
  extendFamily = ({
    id,
    familyRelationshipId,
    familyRelationshipDesc,
    sex,
    ageGroupId,
    isObserver,
    isThird,
  }) => {
    const { familyRelationship, ageGroup } = this.props
    const isSelected = val => (val ? '　✅' : '　-')
    const familyRelationShipName =
      (familyRelationship.find(x => x.id === familyRelationshipId) || {})
        .name || '-'
    const familyRelation =
      familyRelationshipId === 99
        ? `${familyRelationShipName}(${familyRelationshipDesc || ''})`
        : familyRelationShipName
    return {
      id,
      relation: familyRelation,
      gender: sex === 'M' ? '男性' : sex === 'F' ? '女性' : '-',
      age: (ageGroup.find(x => x.id === ageGroupId) || {}).name || '-',
      isObserver: isSelected(isObserver || false),
      isThird: isSelected(isThird || false),
    }
  }

  render() {
    const { isOpen, error, facility, family } = this.state
    const { env } = this.props

    if (env.ES_STEP < 3) {
      return null
    }

    return (
      <div>
        <Button onClick={ this.toggleModal } open>
          <i className={ 'fa fa-home' } />
        </Button>
        {isOpen && (
          <Screen>
            {error && (
              <ValidationErrorMessage
                message={ 'データが取得できませんでした' }
              />
            )}
            <Flex>
              {facility.length === 0 ? (
                <ValidationErrorMessage message={ '設備の情報はありません。' } />
              ) : (
                <Table>
                  <Caption>{'設備状況'}</Caption>
                  <thead
                    style={ {
                      borderBottom: '1px solid black',
                      background: 'white',
                    } }
                  >
                    <Tr>
                      <Th>{'設備'}</Th>
                      <Th>{'使用年数'}</Th>
                    </Tr>
                  </thead>
                  <tbody>
                    {facility.map(item => (
                      <Tr key={ item.id }>
                        <Td>{item.type}</Td>
                        <Td>{item.age}</Td>
                      </Tr>
                    ))}
                  </tbody>
                </Table>
              )}

              {family.length === 0 ? (
                <ValidationErrorMessage
                  message={ '家族構成の情報はありません。' }
                />
              ) : (
                <Table>
                  <Caption>{'家族構成'}</Caption>
                  <thead
                    style={ {
                      borderBottom: '1px solid black',
                      background: 'white',
                    } }
                  >
                    <Tr>
                      <Th>{'立会者'}</Th>
                      <Th>{'第三者確認'}</Th>
                      <Th>{'性別　　　'}</Th>
                      <Th>{'家族　　　　　'}</Th>
                      <Th>{'年齢　　　'}</Th>
                    </Tr>
                  </thead>
                  <tbody>
                    {family.map(item => (
                      <Tr key={ item.id }>
                        <Td>{item.isObserver}</Td>
                        <Td>{item.isThird}</Td>
                        <Td>{item.gender}</Td>
                        <Td>{item.relation}</Td>
                        <Td>{item.age}</Td>
                      </Tr>
                    ))}
                  </tbody>
                </Table>
              )}

              <Button onClick={ this.toggleModal } close>
                {'X'}
              </Button>
            </Flex>
          </Screen>
        )}
      </div>
    )
  }
}

export const mapStateToProps = state => ({
  facilityType: state.master.data.facilityType,
  facilityAge: state.master.data.facilityAge,
  familyRelationship: state.master.data.familyRelationship,
  ageGroup: state.master.data.ageGroup,
  env: state.env,
  accessToken: state.login.authentication.accessToken,
})

export default connect(mapStateToProps)(FacilityFamilyModal)
