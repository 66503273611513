import update from 'immutability-helper'

/**
 * タイプ定義
 * @type {string}
 */
export const type = 'WARA.INSERT_STICKY'

/**
 * exportation
 */
export default {
  /**
   * action type
   * @type {string}
   */
  type,

  /**
   * action creator
   * @param  {number} targetRegionIndex [description]
   * @param  {number} targetListIndex    [description]
   * @param  {number} stickyIndexSrc     [description]
   * @param  {number} stickyIndexDest    [description]
   * @return {object}               [description]
   */
  creator: (
    targetRegionIndex,
    targetListIndex,
    stickyIndexSrc,
    stickyIndexDest,
  ) => {
    return {
      type,
      payload: {
        targetRegionIndex,
        targetListIndex,
        stickyIndexSrc,
        stickyIndexDest,
      },
    }
  },

  /**
   * 関数本体
   * @param  {object} state    Immutableなstateです。このオブジェクトを変更すべきではありません。
   * @param  {object} action  関数に与える引数です。
   * @return {object}          適切にUpdateされた新しいStateを返却します。
   */
  handler: (
    state,
    {
      payload: {
        targetRegionIndex,
        targetListIndex,
        stickyIndexSrc,
        stickyIndexDest,
      },
    },
  ) => {
    if (
      targetRegionIndex === -1 ||
      targetListIndex === -1 ||
      stickyIndexSrc === stickyIndexDest
    ) {
      return state
    }

    const stickies =
      state.data.regions[targetRegionIndex].lists[targetListIndex].stickies

    const moveItem = (arr, from, to) => {
      const result = [...arr]
      const [movedItem] = result.splice(from, 1)
      result.splice(to, 0, movedItem)
      return result
    }

    const reorderedStickies = moveItem(
      stickies,
      stickyIndexSrc,
      stickyIndexDest > stickyIndexSrc ? stickyIndexDest - 1 : stickyIndexDest,
    )

    return update(state, {
      data: {
        regions: {
          [targetRegionIndex]: {
            lists: {
              [targetListIndex]: {
                stickies: { $set: reorderedStickies },
              },
            },
          },
        },
      },
    })
  },
}
