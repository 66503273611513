import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const FloatP = styled.p`
  position: 'absolute';
  bottom: 0;
  font-weight: 'bold';
  z-index: 10000;
  padding: '2px 5px';
  background-color: '#eee';
  border-radius: '5px';
  opacity: 0.7;
`

/**
 * Idを浮かせて表示するためのデバッガ
 * @param {object} props [description]
 */
const FloatId = ({ id }) => (
  <FloatP>{`デバッグ情報: ID=${
    id === -1 ? '(不明または未割り当て)' : id
  }`}</FloatP>
)

/**
 * propTypes
 * @type {object}
 */
FloatId.propTypes = {
  id: PropTypes.number,
}

FloatId.defaultProps = {
  id: -1,
}

export default FloatId
