import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// components
import Page from 'src/components/commons/history-linked-forms/page'
import Limit from 'src/components/commons/history-linked-forms/limit'
import AutocompleteSs from 'src/components/commons/history-linked-forms/autocomplete-ss'
import Regions from 'src/components/commons/history-linked-forms/regions'
import SelectPrefCode from 'src/components/commons/history-linked-forms/select-pref-code'
import Conditions from 'src/components/commons/history-linked-forms/conditions'
import CompanyIds from 'src/components/commons/history-linked-forms/companyIds'
import {
  DateStart,
  DateEnd,
} from 'src/components/commons/history-linked-forms/date'
import Reload from 'src/components/commons/history-linked-forms/reload'
import Labels from './partials/labels'
import Stickies from './partials/stickies'
import { Header, FormsWrap, ContentWrap } from '../styled'
import SearchResultsModal from 'src/components/commons/search-results/partials/modal'
import Button from 'src/styled/button/decision'
import Authorize from 'src/components/commons/authorize'

// libs
import { qsjoin } from 'src/lib/format'
import getOrderList from 'src/lib/order-list-api'
import Sticky from 'src/lib/class-sticky'
import noop from 'src/lib/noop'
import updateRequestHandler from '../update-request-handler'
import { makeCancelable } from 'src/lib/cancelable-promise'
import { fluffyPromise as fluffy } from 'src/lib/api-utils'
import requestGetSticky from 'src/lib/sticky-api/get'
import config, { mimes } from 'src/config'
import downloadOrderCsv from 'src/lib/order-list-api/download'
import { downloadBase64Encoded } from 'src/lib/download'
import { conditionIds } from './partials/conditions'
import styled from 'styled-components'
import HlClientName from 'src/components/commons/history-linked-forms/hl-client-kana-name'
import { Alert, Box, Divider } from '@mui/material'

// 注文一覧の印刷のために flex なしにする。
const Container = styled.div`
  margin: 50px auto 0;
`

const Warning = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 4rem;
  background: #ffebee;
  border: 3px solid #f50057;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.4) 0 10px 10px;
  z-index: 100;
`

const GearSVG = styled.div`
  animation: 3s linear infinite rotation;

  @keyframes rotation {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`

const WarningMessage = styled.p`
  font-size: 3rem;
  margin-bottom: 0;
`

const WarningMessage2 = styled(WarningMessage)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: large;
  margin-bottom: 1rem;
`

const searchForm = {
  display: 'grid',
  gridTemplateColumns: '1fr max-content',
  alignItems: 'center',
  width: '100%',
  marginBottom: '3rem',
}

const searchConditions = {
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: '0.75rem',
  rowGap: '0.5rem',
}

export class OrderListApp extends React.PureComponent {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    //  ownProps
    query: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
    // statePtops
    accessToken: PropTypes.string.isRequired,
    env: PropTypes.object.isRequired,
    staffs: PropTypes.array.isRequired,
  }
  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    props.replace('?' + qsjoin(props.query))
    const noQuery = Object.keys(props.query).length === 0
    this.state = {
      data: [],
      status: noQuery ? 'not_yet' : 'requesting',
      downloading: false, // CSVダウンロード中
      maxPage: 0,
      timerId: false,
      onUnmount: noop,
      isOpen: false,
      sticky: false,
      cancel: noop,
    }
  }

  /**
   * componentDidMount
   * @return {void}
   */
  componentDidMount() {
    this.state.status === 'requesting' && this.request()
  }
  /**
   * プロパティのqueryで渡される値が変更された時、検索を実行する。
   * @param {*} nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const prevProp = this.props.query
    if (
      prevProp.companyId !== nextProps.query.companyId ||
      prevProp.regionId !== nextProps.query.regionId ||
      prevProp.prefCode !== nextProps.query.prefCode ||
      prevProp.conditionId !== nextProps.query.conditionId
    ) {
      // 2020/03/23 DBの負担が大きいため、自動検索はしないようにする。
      // this.request(nextProps.query)
    }
  }

  /**
   * componentDidUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {void}
   */
  componentDidUpdate = nextProps =>
    updateRequestHandler(this.props.query, nextProps.query, this.request)

  /**
   * componentWillUnmount
   * @return {void}
   */
  componentWillUnmount() {
    this.state.onUnmount()
    clearTimeout(this.state.timerId)
    this.state.cancel()
  }

  request = (nextQuery = null) => {
    const { accessToken, env, query } = this.props
    const orderQuery = nextQuery || query
    // ハウスラボのみの時はエリアの選択を無効にする。
    if (orderQuery.conditionId === '11') {
      orderQuery.regionId = null
    }
    const { promise, cancel: onUnmount } = makeCancelable(
      getOrderList(orderQuery, accessToken, env),
    )
    this.setState({ status: 'requesting', onUnmount })

    fluffy(promise)
      .then(res => {
        if (res.ok) {
          return res.json()
        } else {
          throw new Error(res)
        }
      })
      .then(({ maxPage, stickies }) => {
        this.setState({
          status: 'success',
          maxPage,
          data: stickies.map(sticky => ({
            sticky: new Sticky(sticky, { normalize: false }),
            conditionId: parseInt(query.conditionId, 10),
            conditionIds: conditionIds(sticky),
          })),
        })
        this.resetStatus()
      })
      .catch(err => {
        if (!err.isCanceled) {
          console.error(err)
          this.setState({ status: 'failure' })
          this.resetStatus()
        }
      })
  }

  resetStatus = () =>
    this.setState({
      timerId: setTimeout(() => this.setState({ status: 'not_yet' }), 2000),
    })

  /**
   * 付箋のモーダル画面を表示する
   */
  openModal = stickyId => {
    const { accessToken, env } = this.props
    // 付箋の詳細情報を取得して表示する。
    requestGetSticky(stickyId, accessToken, env)
      .then(rsticky => {
        const marucRepeatStickyId = rsticky.getUserValue('hasMarucRepeat')
        if (marucRepeatStickyId) {
          requestGetSticky(marucRepeatStickyId, accessToken, env)
            .then(repSticky => {
              const diffSticky = new Sticky({
                negotiationRecords: repSticky._props.negotiationRecords,
              })
              const nsticky = rsticky.update(diffSticky)
              return nsticky
            })
            .then(nsticky => {
              this.setState({ isOpen: true, sticky: nsticky })
            })
        } else {
          this.setState({ isOpen: true, sticky: rsticky })
        }
      })
      .catch(err => console.error(err))
  }

  /**
   * 付箋のモーダル画面を閉じる
   */
  closeModal = () => this.setState({ isOpen: false, sticky: false })

  /**
   * 注文一覧CSVのダウンロード
   */
  requestDownloadOrderCsv = () => {
    const { accessToken, env, query, staffs } = this.props
    const { promise, cancel } = makeCancelable(
      downloadOrderCsv(query, accessToken, env),
    )
    this.setState({ status: 'requesting', cancel, downloading: true })

    // ファイル名の構成要素
    const staffId = query.staffId ? parseInt(query.staffId, 10) : -1
    const staffName =
      (staffId > 0
        ? (staffs.find(staff => staff.staffId === staffId) || {}).staffName
        : '') || '担当者未指定'
    const conditions = [{ id: 10, name: '全て' }, ...config.stickyConditions]
    const conditionId = query.conditionId ? parseInt(query.conditionId) : 10
    const condition = (
      conditions.find(condition => condition.id === conditionId) || {}
    ).name

    fluffy(promise)
      .then(res => {
        if (res.ok) {
          return res.text()
        } else {
          throw new Error(res)
        }
      })
      .then(content => {
        const filename = `受注一覧_${staffName}_${condition}_${query.startDate}_${query.endDate}.csv`
        const mimeType = mimes.csv
        downloadBase64Encoded(filename, mimeType, content)
        this.setState({ status: 'success', downloading: false })
      })
      .catch(error => {
        if (!error.isCanceled) {
          this.setState({ status: 'failure', downloading: false })
        }
      })
  }

  // 編集中の付箋を置き換える
  stageSticky = sticky => {
    sticky._props.id = null
    this.setState({ sticky })
  }

  render() {
    const {
      props,
      state: { data, status, isOpen, sticky, downloading },
    } = this

    const selectedProps = {
      value: props.value,
      onChange: props.onChange,
      query: props.query,
      replace: props.replace,
    }

    const disabled = status === 'requesting'
    const onlyHouseLabo = props.query.conditionId === '11'
    const regionId = Array.isArray(props.query.regionIds)
      ? props.query.regionIds.join(', ')
      : '-1'

    // 検索条件が旧ICならば4月1日以降の新しいICは検索できないので、その旨を表示します
    const exIcMessage =
      props.query.conditionId === '29' && props.query.endDate >= '2024-04-01'
    const IcMessage =
      props.query.conditionId === '30' && props.query.startDate <= '2024-03-31'

    return (
      <Container>
        {downloading && (
          <Warning>
            <div
              style={ {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: '3rem',
              } }
            >
              <GearSVG>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={ 64 }
                  height={ 64 }
                  viewBox="0 0 48 48"
                  fill="none"
                  stroke={ '#f50057' }
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M14.67,36a9.66,9.66,0,0,1,.94,5,17.36,17.36,0,0,0,2.47,1c3.85-4.19,8.16-4.19,12,0a17.36,17.36,0,0,0,2.47-1c-.23-5.68,2.81-8.73,8.49-8.49a18.39,18.39,0,0,0,1-2.47c-4.19-3.85-4.19-8.17,0-12a18.39,18.39,0,0,0-1-2.47c-5.68.24-8.72-2.81-8.49-8.49a17.36,17.36,0,0,0-2.47-1c-3.85,4.19-8.16,4.19-12,0a17.36,17.36,0,0,0-2.47,1c.23,5.68-2.82,8.73-8.5,8.49a21.42,21.42,0,0,0-1,2.47c4.19,3.85,4.19,8.17,0,12a21.42,21.42,0,0,0,1,2.47,12,12,0,0,1,2.36.11" />
                  <path d="M18.15,24.91a6,6,0,1,1,6.84,5,5.94,5.94,0,0,1-3-.32" />
                </svg>
              </GearSVG>
              <WarningMessage style={ { fontWeight: 'bold' } }>
                {'受注一覧CSVを作成中です。'}
              </WarningMessage>
            </div>
            <WarningMessage2>{'ダウンロード完了まで、'}</WarningMessage2>
            <WarningMessage2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={ 32 }
                height={ 32 }
                viewBox="0 0 32 64"
                fill="none"
                stroke={ '#f50057' }
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M21.4019,8.4474,4.5981,37.5526a3,3,0,0,0,2.5981,4.5H40.8038a3,3,0,0,0,2.5981-4.5L26.5981,8.4474A3,3,0,0,0,21.4019,8.4474Z" />
                <rect x="22" y="18" width="4" height="11" rx="1.8166" />
                <circle cx="24" cy="35" r="2" />
              </svg>
              {'ウィンドウまたはタブを閉じないでください。'}
            </WarningMessage2>
            <WarningMessage2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={ 32 }
                height={ 32 }
                viewBox="0 0 32 64"
                fill="none"
                stroke={ '#f50057' }
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M21.4019,8.4474,4.5981,37.5526a3,3,0,0,0,2.5981,4.5H40.8038a3,3,0,0,0,2.5981-4.5L26.5981,8.4474A3,3,0,0,0,21.4019,8.4474Z" />
                <rect x="22" y="18" width="4" height="11" rx="1.8166" />
                <circle cx="24" cy="35" r="2" />
              </svg>
              {'他のページに移動しないでください。'}
            </WarningMessage2>
            <WarningMessage2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={ 32 }
                height={ 32 }
                viewBox="0 0 32 64"
                fill="none"
                stroke={ '#f50057' }
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M21.4019,8.4474,4.5981,37.5526a3,3,0,0,0,2.5981,4.5H40.8038a3,3,0,0,0,2.5981-4.5L26.5981,8.4474A3,3,0,0,0,21.4019,8.4474Z" />
                <rect x="22" y="18" width="4" height="11" rx="1.8166" />
                <circle cx="24" cy="35" r="2" />
              </svg>
              {'同じアカウントで他のPCからダウンロードできません。'}
            </WarningMessage2>
            <WarningMessage2 style={ { marginTop: '1.25rem' } }>
              {'1ヶ月分のCSV作成に約3分かかります。'}
            </WarningMessage2>
          </Warning>
        )}
        <Header title={ '注文一覧' } />

        <Authorize roles={ config.roles.superuser }>
          <FormsWrap alignRight>
            <Button
              onClick={ this.requestDownloadOrderCsv }
              disabled={ disabled }
              style={ { width: '150px' } }
            >
              <i className="fa fa-download" aria-hidden="true" />
              {' 受注一覧'}
            </Button>
          </FormsWrap>
        </Authorize>
        {status === 'failure' && (
          <div
            style={ {
              display: 'flex',
              justifyContent: 'flex-end',
              color: 'red',
            } }
          >
            <p>{'通信エラー'}</p>
          </div>
        )}
        <div style={ searchForm }>
          <div style={ searchConditions }>
            <Box>
              <label style={ { fontWeight: 'bold' } }>{'担当者'}</label>
              <AutocompleteSs
                { ...selectedProps }
                disabled={ disabled }
                verboseName
                allStaffs
                inputName={ 'orderlist-staff-input' }
                small
              />
            </Box>
            <Divider orientation="vertical" flexItem />
            <CompanyIds { ...selectedProps } disabled={ disabled } />
            <Regions
              { ...selectedProps }
              disabled={ disabled || onlyHouseLabo }
              isSticky
            />
            <SelectPrefCode
              { ...selectedProps }
              disabled={ disabled }
              regionId={ regionId }
              key={ regionId }
            />
            <HlClientName { ...selectedProps } disabled={ disabled } />
            <Conditions { ...selectedProps } disabled={ disabled } />
            <div>
              <DateStart
                { ...selectedProps }
                disabled={ disabled }
                buttonId={ 'order-list-datestart' }
                inputId={ 'order-list-datestart-input' }
              />
              {IcMessage && (
                <Alert severity="warning">
                  {'ICは2024年4月1日以降が検索対象です'}
                </Alert>
              )}
            </div>
            <div>
              <DateEnd
                { ...selectedProps }
                disabled={ disabled }
                buttonId={ 'order-list-dateend' }
                inputId={ 'order-list-dateend-input' }
              />
              {exIcMessage && (
                <Alert severity="warning">
                  {'旧ICは2024年3月31日までが検索対象です'}
                </Alert>
              )}
            </div>
          </div>
          <Reload
            { ...selectedProps }
            onClick={ this.request }
            disabled={ disabled }
          />
        </div>
        <Labels />

        <ContentWrap>
          {status === 'requesting' && (
            <i className={ 'fa fa-spinner fa-pulse fa-fw' } />
          )}
          <Stickies
            data={ data }
            disabled={ disabled }
            openModal={ this.openModal }
          />
        </ContentWrap>

        <Page
          { ...selectedProps }
          disabled={ disabled }
          maxPage={ this.state.maxPage }
        />
        <Limit { ...selectedProps } />
        {status === 'failure' && '通信エラー'}
        {isOpen && (
          <SearchResultsModal
            isOpen={ isOpen }
            sticky={ sticky }
            closeMe={ this.closeModal }
            closeResult={ this.closeModal }
            parentRoute={ 'assign' }
            displayRoute={ 'assign' }
            linkToWara
            hasES={ false }
            hasHL={ false }
            stageStickyFromHistory={ this.stageSticky }
            marucRepeat
            createSticky
          />
        )}
      </Container>
    )
  }
}

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
export const mapStateToProps = state => {
  return {
    accessToken: state.login.authentication.accessToken,
    env: state.env,
    staffs: state.master.data.staffs,
  }
}

export default connect(mapStateToProps)(OrderListApp)
