import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { toJPYenText } from 'src/lib/format'
import Sticky from 'src/lib/class-sticky'

export const Payment = props => {
  const { account, sticky, cityCodes } = props
  const accountValue = account.value === null ? '' : account.value

  // 依頼者コンタクトオブジェクトを取得
  const client = sticky.findClient() || {}

  // 依頼者の市区町村名を取得
  const filtered = (cityCodes[client.prefCode] || []).filter(
    x => x.code === client.cityCode,
  )[0]
  const cityName = filtered && filtered.cityName ? filtered.cityName : ''
  const clientName = client.name ? client.name : '(名前未入力)'
  const respective = client.name ? ' 様' : ''
  const displayClientName = cityName + ' ' + clientName + respective

  // 重要申送
  const handOverText = (sticky._props.handOver || {}).freeDescribe || ''

  // メモ
  const workContent = sticky._props.workContent || ''

  return (
    <div className={ 'sticky-payment-content' }>
      <div className={ 'sticky-element sticky-address sticky-place' }>
        {displayClientName}
      </div>
      <div className={ 'sticky-element' }>
        <span>{toJPYenText(accountValue)}</span>
      </div>
      <div className={ 'sticky-element sticky-memo' }>{handOverText}</div>
      <div className={ 'sticky-element sticky-message' }>{workContent}</div>
    </div>
  )
}

Payment.propTypes = {
  sticky: PropTypes.instanceOf(Sticky).isRequired,
  account: PropTypes.object.isRequired,
  // stateProps
  cityCodes: PropTypes.object.isRequired,
}

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
const mapStateToProps = state => {
  return {
    cityCodes: state.master.cityCodes,
  }
}

export default connect(mapStateToProps)(Payment)
