/**
 * StickyBoardのヘッダーUIを提供します。
 * @file {Component}
 */

/**
 * ライブラリ
 */
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

/**
 * React以外のライブラリ
 */
import config from 'src/config'
import { validMaruc } from 'src/lib/validate'
/**
 * コンポーネント
 */
import Toggle from 'src/components/commons/toggle'
import Dock from '../dock'
import SpecialStickySource from '../special-sticky-surface'
import StickyEmpty from '../sticky-empty/container'
import Breadcrumbs from './breadcrumbs'
import DateDisplay from './date-display'
import Footer from './footer'
import SelectRegionsForAssociates from './select-regions-for-associates'
import SelectRegionsForDissociates from './select-regions-for-dissociates'

import { ACTION_TYPES as USERPRESET_ACTION_TYPES } from 'src/reducers/user-preset'

/**
 * map state to props
 * @param  {object} state stae
 * @return {Location}    region and date
 */
const mapStateToProps = state => {
  return {
    dock: state.wara.data.dock,
    dissociatedRegionsFilter: state.wara.dissociatedRegionsFilter,
    dissociatedPrefsFilter: state.wara.dissociatedPrefsFilter,
    dissociatedMarucFilter: state.wara.dissociatedMarucFilter,
    master: state.master.data,
    displayDate: state.displayDate.displayDate,
  }
}

/**
 * map dispatch to props
 * @param  {function} dispatch dispatcher
 * @return {object}            mapped auto bind action creators
 */
const mapDispatchToProps = dispatch => ({
  /**
   * 単一の地域だけを表示する状態にユーザープリセットを更新する
   * @param  {number} regionId     region id
   * @param  {array} masterRegions マスタ
   * @return {void}
   */
  displaySimpleReagion: (regionId, masterRegions) =>
    dispatch({
      type: USERPRESET_ACTION_TYPES.SHOW_SIMPLE_REGION,
      payload: { regionId, masterRegions },
    }),
})

/**
 * サイドバーのコンポーネント
 * @type {ReactComponent}
 */
export class Side extends React.Component {
  /**
   * Validation
   * @type {object}
   */
  static propTypes = {
    // ownProps
    id: PropTypes.string.isRequired,
    locationParams: PropTypes.object.isRequired,
    // stateProps
    master: PropTypes.shape({
      prefCodes: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string.isRequired,
          regionId: PropTypes.number.isRequired,
        }),
      ),
    }).isRequired,
    dock: PropTypes.object,
    dissociatedRegionsFilter: PropTypes.array.isRequired,
    dissociatedPrefsFilter: PropTypes.array.isRequired,
    dissociatedMarucFilter: PropTypes.bool.isRequired,
    displayDate: PropTypes.string.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    dock: {},
  }
  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)

    // ドックの表示日による絞り込みは無効とし、固定条件による絞り込みとする。byDate()参照。
    let filterIC = true
    let stickyFilter = [config.company.ES]

    try {
      filterIC = !!JSON.parse(
        localStorage.getItem(Side.LSKEY_FILTER_IC) || 'true',
      )
      stickyFilter = JSON.parse(
        localStorage.getItem(Side.LSKEY_FILTER_STICKY) ||
          `[${config.company.ES}]`,
      )
    } catch (e) {
      filterIC = true
      stickyFilter = [config.company.ES]
    }

    this.state = { filterIC, stickyFilter }
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.filterIC !== nextState.filterIC ||
      this.state.stickyFilter !== nextState.stickyFilter ||
      this.props.dissociatedRegionsFilter.length !==
        nextProps.dissociatedRegionsFilter.length ||
      this.props.dissociatedPrefsFilter.length !==
        nextProps.dissociatedPrefsFilter.length ||
      this.props.dissociatedMarucFilter !== nextProps.dissociatedMarucFilter ||
      this.props.dock.stickies !== nextProps.dock.stickies ||
      this.props.locationParams.year !== nextProps.locationParams.year ||
      this.props.locationParams.month !== nextProps.locationParams.month ||
      this.props.locationParams.day !== nextProps.locationParams.day ||
      this.props.displayDate !== nextProps.displayDate
    )
  }

  // static LSKEY_FILTER_TODAY = 'PRESERVE__wara-side-filter-today'
  // static LSKEY_FILTER_HL = 'PRESERVE__wara-side-filter-hl'
  static LSKEY_FILTER_STICKY = 'PRESERVE__wara-side-filter-sticky'
  static LSKEY_FILTER_IC = 'PRESERVE__wara-side-filter-ic'

  /**
   * filter関数の共通コールバック
   * @param  {Sticky} sticky 付箋オブジェクト
   * @return {boolean}       フィルタ
   */
  byRegionAndPref = ({ sticky }) => {
    const { regionId } = sticky.json()
    const { prefCode } = sticky.findClient() || {}
    const { regionId: prefRegionId } =
      (this.props.master.prefCodes || []).find(
        pref => pref.code === prefCode,
      ) || {}

    // フィルタできないものはデフォルト表示
    if (!regionId && !prefCode && !prefRegionId) {
      return true
    }

    const {
      dissociatedRegionsFilter: regionsFilter,
      dissociatedPrefsFilter: prefsFilter,
    } = this.props

    if (prefCode) {
      return prefsFilter.includes(prefCode)
    } else if (prefRegionId) {
      regionsFilter.includes(prefRegionId)
    } else if (regionId) {
      regionsFilter.includes(regionId)
    } else {
      return false
    }
  }

  isMaruc = ({ sticky }) => {
    // マルシーのみの時は表示対象かつマルシーのみとする。
    const stickyProps = sticky.json()
    const isMaruc = validMaruc(stickyProps)
    return this.props.dissociatedMarucFilter
      ? isMaruc && sticky.isClaimerOrder
      : isMaruc
  }

  /**
   * render
   * @return {ReactDOM} [ReactDOM]
   */
  render() {
    const { filterIC, stickyFilter } = this.state
    const {
      locationParams: { year, month, day },
      id,
      dock: { stickies },
      displayDate,
    } = this.props

    const byIC = filterIC
      ? ({ sticky }) => {
        return (
          sticky.json().finishStateId !== config.cancelReason.ICA &&
            sticky.json().finishStateId !== config.cancelReason.ICB
        )
      }
      : () => true

    const byCompany = ({ sticky }) => {
      const companyId = sticky.json().companyId
      return stickyFilter.includes(companyId)
    }

    // 確定作業日が空欄または当日のもののみ表示する。第1対応希望日時は条件に含めない。
    const byDate = ({ sticky }) => {
      const stickyProps = sticky.json()
      const determinedDate = (stickyProps.determinedDateTime || {}).date || ''
      return determinedDate === '' || determinedDate === displayDate
    }

    const byRegionAndPref = this.byRegionAndPref

    // eslint-disable-next-line no-useless-escape
    const adjustingString = /\"adjusting\":true/   /** 調整中ならばuserFieldに入る **/

    const byAdjusting = ({ sticky }) => {
      return adjustingString.test(sticky.json().userField)
    }

    const byUnadjusting = ({ sticky }) => {
      return !adjustingString.test(sticky.json().userField)
    }

    // dockに入る付箋の振り分け
    const _justRegistered = [] // 予定未調整
    const _accepted = [] // 希望承り済み（調整中でないものと調整中のものすべて）
    const _messaged = [] // 目安お伝え済み
    ;(stickies || []).forEach((sticky, stickyIndex) => {
      // 確定作業日時あり（顧客伝達状況は見ない）
      const isMessaged =
        sticky.json().determinedDateTime &&
        (sticky.json().determinedDateTime.date ||
          sticky.json().determinedDateTime.startAt ||
          sticky.json().determinedDateTime.finishAt)

      // 対応希望日時あり
      const isAccepted =
        Array.isArray(sticky.json().wishDateTimes) &&
        sticky.json().wishDateTimes.length > 0 &&
        ((sticky.json().wishDateTimes[0] &&
          (sticky.json().wishDateTimes[0].date ||
            sticky.json().wishDateTimes[0].startAt ||
            sticky.json().wishDateTimes[0].finishAt)) ||
          (sticky.json().wishDateTimes[1] &&
            (sticky.json().wishDateTimes[1].date ||
              sticky.json().wishDateTimes[1].startAt ||
              sticky.json().wishDateTimes[1].finishAt)) ||
          (sticky.json().wishDateTimes[2] &&
            (sticky.json().wishDateTimes[2].date ||
              sticky.json().wishDateTimes[2].startAt ||
              sticky.json().wishDateTimes[2].finishAt)))

      if (isMessaged) {
        // 確定作業日時あり→目安お伝え済み
        _messaged.push({ sticky, stickyIndex })
      } else if (isAccepted) {
        // 確定作業日時なし、対応希望日時あり→希望承り済み
        _accepted.push({ sticky, stickyIndex })
      } else {
        // それ以外→予定未調整
        _justRegistered.push({ sticky, stickyIndex })
      }
    })

    const justRegistered = _justRegistered
      .filter(this.isMaruc)
      .filter(byIC)
      .filter(byCompany)
      .filter(byRegionAndPref)
      .filter(byDate)
      .filter(({ sticky }) => !sticky.json().cancelReasonId)

    const adjusting = _accepted
      .filter(this.isMaruc)
      .filter(byIC)
      .filter(byCompany)
      .filter(byRegionAndPref)
      .filter(byDate)
      .filter(({ sticky }) => !sticky.json().cancelReasonId)
      .filter(byAdjusting)

    const accepted = _accepted
      .filter(this.isMaruc)
      .filter(byIC)
      .filter(byCompany)
      .filter(byRegionAndPref)
      .filter(byDate)
      .filter(({ sticky }) => !sticky.json().cancelReasonId)
      .filter(byUnadjusting)

    const messaged = _messaged
      .filter(this.isMaruc)
      .filter(byIC)
      .filter(byCompany)
      .filter(byRegionAndPref)
      .filter(byDate)
      .filter(({ sticky }) => !sticky.json().cancelReasonId)

    const columnsCount = Math.max(
      1,
      [justRegistered, accepted, messaged].filter(x => x.length > 0).length, // acceptedとadjustingは同じ列になるのでカウントに含めない
    )

    const columnsClassName = `col-${columnsCount}`

    return (
      <div className={ `side ${columnsClassName}` } id={ id }>
        <Breadcrumbs />

        <Toggle slug={ 'sidebar-controls' } memory>
          <div className={ 'clearfix header-wrap' }>
            <DateDisplay { ...{ year, month, day } } />

            <ul className={ 'special-sticky-source-wrap' }>
              <SpecialStickySource
                slug={ 'memo' }
                stickyTypeId={ 4 }
                isSpecialSticky // 苦肉の策。この要素がドロップされた時に自分自身であることを判定する
                location={ { regionIndex: -1, listIndex: -1 } }
              />
              <SpecialStickySource
                slug={ 'private' }
                stickyTypeId={ 3 }
                isSpecialSticky // 苦肉の策。この要素がドロップされた時に自分自身であることを判定する
                location={ { regionIndex: -1, listIndex: -1 } }
              />
            </ul>
          </div>

          <hr />

          <section>
            <h3 className={ 'sidebar-title' }>
              {'エリア選択 - 配置済案件付箋(左)'}
            </h3>
            <SelectRegionsForAssociates />
          </section>

          <hr />

          <section>
            <h3 className={ 'sidebar-title' }>
              {'エリア選択 - 未配置案件付箋(右)'}
            </h3>
            <SelectRegionsForDissociates />
          </section>

          <hr />

          <StickyEmpty location={ { regionIndex: -1, listIndex: -1 } } />
        </Toggle>

        <div
          style={ {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: 'auto',
            padding: '0 20px',
            margin: '0 10px',
            borderBottom: 'lightgrey 1px solid',
          } }
        >
          {/* {config.companies.map((c, i) => (
            <div
              key={ `${c.id}-${i}` }
              style={ {
                gridRow: parseInt(i / 2, 10) + 1,
                gridColumn: (i % 2) + 1,
              } }
            >
              <Checkbox
                value={ c.id }
                checked={ stickyFilter.includes(c.id) }
                // eslint-disable-next-line react/jsx-no-bind
                onChange={ e => {
                  const companyId = parseInt(e.target.value, 10)
                  const checked = e.target.checked
                  const filter = stickyFilter.filter(x => x !== companyId)
                  const newFilter = checked
                    ? [...filter, companyId]
                    : [...filter]
                  this.setState({ stickyFilter: newFilter })
                  localStorage.setItem(
                    Side.LSKEY_FILTER_STICKY,
                    JSON.stringify(newFilter),
                  )
                } }
                labelText={ c.name }
              />
            </div>
          ))} */}
        </div>

        <Dock
          id={ 'the-dock' }
          justRegistered={ justRegistered }
          wished={ accepted }
          adjusting={ adjusting }
          messaged={ messaged }
          displayDate={ displayDate }
        />

        <Footer stickyFilter={ stickyFilter } />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Side)
