import utils from 'src/lib/api-utils'

export const put = (accessToken, env, payload) => {
  const endpoint = `${utils.createEndpoint(env, 'documents')}`

  const options = {
    method: 'PUT',
    headers: { Authorization: `Bearer ${accessToken}` },
    body: JSON.stringify(payload),
  }

  const request = fetch(endpoint, options)

  return request
}

export default put
