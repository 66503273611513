import React from 'react'
import config from 'src/config'

/**
 *Config確認用コンポーネント
 * @type {ReactComponent}
 */
export default class ConfigView extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {}

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate() {
    return false
  }

  /**
   * render
   * @return {ReactDOM} dom
   */
  render() {
    return (
      <div className={ 'masterview__wrapper' }>
        <h1>{'constants'}</h1>
        <p>{JSON.stringify(config)}</p>
      </div>
    )
  }
}
