import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Box, Typography } from '@mui/material'

// actions
import { NETWORK_STATUSES } from 'src/reducers/network-status'

// components
import SearchPagenation from 'src/components/commons/search-pagenation'
import ValidationErrorMessage from 'src/components/commons/validation-error-message'
import Input from './partials/input'
import SearchResults from 'src/components/commons/search-results'
import { Button, ClearButton } from './styled'
import Checkbox from 'src/styled/form/checkbox'
import CompleteCityCode from 'src/components/commons/history-linked-forms/complete-city-code.jsx'
import AutocompleteSs from 'src/components/commons/history-linked-forms/autocomplete-ss'
import WrappedCalendar from './partials/wrapped-calendar'
import AllUsers from 'src/components/commons/history-linked-forms/all-users'

// libs
import { search as requestSearch } from 'src/lib/search-api'
import Sticky from 'src/lib/class-sticky'
import config, { freewordTypes } from 'src/config'

export const isPhoneNoValid = value => /^([0-9-])*$/.test(value)

/**
 * map state to props
 * @param  {object} state mapping state
 * @return {object}       mapped state as props
 */
const mapStateToProps = state => {
  return {
    accessToken: state.login.authentication.accessToken,
    env: state.env,
    postStatus: state.networkStatus.postSticky,
    status: state.networkStatus.getStickySearch,
  }
}

/**
 * [propTypes description]
 * @type {object}
 */
export class DetailedSearchForms extends Component {
  /**
   * initialQueryState
   * @type {object}
   */
  static initialQueryState = {
    basicSearch: false,
    freewordSearch: false,
    detailedSearch: true,
    limit: config.initialSearchLimit,
    offset: 0,
    isCanceled: false,
    isSuspended: false,
  }

  static initialResultState = {
    stickies: [],
    esFullCount: 0,
    fullCount: 0,
    hlFullCount: 0,
  }

  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    update: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    closeMe: PropTypes.func.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    // stateProps
    accessToken: PropTypes.string.isRequired,
    env: PropTypes.object.isRequired,
    postStatus: PropTypes.oneOf(Object.values(NETWORK_STATUSES)).isRequired,
  }

  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    this.state = {
      query: {
        ...DetailedSearchForms.initialQueryState,
      },
      status: 'not_yet',
      result: { ...DetailedSearchForms.initialResultState },
      page: 0,
    }
    // クエリで電話番号が渡された時は初期値として設定する。
    if (props.phoneNumber !== '') {
      this.state.query.phoneNo = props.phoneNumber
    }
  }

  /**
   * UNSAFE_componentWillReceiveProps
   * @param  {object} nextProps React props.
   * @return {void}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.postStatus === NETWORK_STATUSES.SUCCESS &&
      this.props.postStatus !== NETWORK_STATUSES.SUCCESS
    ) {
      // 付箋の投稿に成功した時、クエリを削除する
      this.removeQuery()
    }
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate() {
    return true
  }

  /**
   * 検索ボタンを押した時のclickハンドラ
   * @return {void}
   */
  doSearch = (query = {}, callback) => {
    const { accessToken, env } = this.props
    const { query: stquery } = this.state

    this.setState({ status: 'request' })
    this.props.reset()
    // // フリーワードで検索する時のクエリ
    // const freeQuery = {}
    // if (stquery.freewordSearch) {
    //   freeQuery.freewordSearch = true
    //   freeQuery.limit = stquery.limit
    //   freeQuery.freeword = stquery.freeword
    //   if (stquery.isNegotiationRecord) {
    //     freeQuery.isNegotiationRecord = true
    //   }
    //   if (stquery.isWorkContent) {
    //     freeQuery.isWorkContent = true
    //   }
    // }
    // // フリーワードで検索しない時は種別をどちらもfalseにする
    // const searchQuery = stquery.freeword
    //   ? freeQuery
    //   : { ...stquery, isNegotiationRecord: false, isWorkContent: false }

    requestSearch({ ...stquery, ...query }, accessToken, env)
      .then(result => {
        this.setState({
          result: {
            ...result,
            stickies: (result.stickies || []).map(sticky => new Sticky(sticky)),
            esFullCount: result.esFullCount || 0,
            fullCount: result.fullCount || 0,
            hlFullCount: result.hlFullCount || 0,
          },
          status: 'success',
        })
        typeof callback === 'function' && callback()
      })
      .catch(err => {
        console.error(err)
        this.setState({ result: {}, status: 'failure' })
      })
  }

  // 引数をクエリにするので、onClickのイベントオブジェクトを渡さない
  doSearchDefault = () => this.doSearch(void 0)

  /**
   * 検索クエリを更新
   * @param  {object} query 検索クエリオブジェクト
   * @return {void}
   */
  updateQuery = query =>
    this.setState({ query: { ...this.state.query, ...query } })

  createUpdateQueryHandler = query => () => this.updateQuery(query)

  /**
   * 検索クエリをクリア
   * @return {void}
   */
  removeQuery = () =>
    this.setState({ query: { ...DetailedSearchForms.initialQueryState } })

  onPageMoveClick = nextPage => {
    const offset = nextPage * this.state.query.limit
    this.doSearch({ offset }, () => this.setState({ page: nextPage }))
  }

  /**
   * 住所の変更
   */
  onCityChange = e => {
    const { prefCode, cityCode } = e.cityCode
    // 都道府県コードと市町村コードが同じ時は都道府県のみを選択したことになる
    if (prefCode === cityCode) {
      this.setState({ query: { ...this.state.query, prefCode, cityCode: '' } })
    } else {
      this.setState({ query: { ...this.state.query, prefCode, cityCode } })
    }
  }

  /**
   * 住所(その他)の変更
   */
  onChangeAddr = e => {
    const address = e.target.value
    this.setState({ query: { ...this.state.query, address } })
  }

  /**
   * 担当者の変更
   */
  onSsChange = e => {
    const staffId = e.staffId
    this.setState({ query: { ...this.state.query, staffId } })
  }

  /**
   * 更新者の変更
   */
  onUserChange = e => {
    const userId = e.userId
    this.setState({ query: { ...this.state.query, userId } })
  }

  /**
   * 開始日付の変更
   */
  onDateStartChange = date => {
    this.setState({ query: { ...this.state.query, dateStart: date } })
  }

  /**
   * 終了日付の変更
   */
  onDateEndChange = date => {
    this.setState({ query: { ...this.state.query, dateEnd: date } })
  }

  /**
   * フリーワードの検索対象の変更
   */
  onFreeWordChange = e => {
    const val = e.target.value
    const query = { ...this.state.query }
    freewordTypes.forEach(option => {
      delete query[option.id]
    })
    query[val] = true
    this.setState({ query })
  }

  renderPagenation = () => (
    <SearchPagenation
      type={ 'detailed-search' }
      page={ this.state.page }
      fullCount={ this.state.result.fullCount || 0 }
      limit={ this.state.query.limit }
      onPageMove={ this.onPageMoveClick }
    />
  )

  /**
   * render
   * @return {ReactDOM} rendering ReactDOM
   */
  render() {
    const { query, status, result } = this.state
    const {
      // ownProps
      closeMe,
    } = this.props

    const phoneNo = query.phoneNo ? query.phoneNo : ''
    const clientName = query.clientName ? query.clientName : ''
    const prefCode = query.prefCode ? query.prefCode : ''
    const cityCode = query.cityCode ? query.cityCode : ''
    const address = query.address ? query.address : ''
    const staffId = query.staffId ? query.staffId : ''
    const dateStart = query.dateStart ? query.dateStart : ''
    const dateEnd = query.dateEnd ? query.dateEnd : ''
    const userId = query.userId ? query.userId : ''
    const orderId = query.orderId ? query.orderId : ''
    const negotiation = query.negotiation ? query.negotiation : ''
    const workType = query.workType ? query.workType : ''
    const receiptNumber = query.receiptNumber ? query.receiptNumber : ''
    const { isClient, isPayer, isCanceled, isSuspended } = query

    const disabled =
      ((phoneNo === '' || !isPhoneNoValid(phoneNo)) &&
        clientName === '' &&
        prefCode === '' &&
        address === '' &&
        staffId === '' &&
        dateStart === '' &&
        dateEnd === '' &&
        userId === '' &&
        orderId === '' &&
        negotiation === '' &&
        workType === '' &&
        receiptNumber === '') ||
      status === 'request'

    // フリーワード検索を他の検索とまとめる
    // const isFreewordSpecialized = !!freeword
    // const isFreewordCountFulfiled =
    //   isFreewordSpecialized &&
    //   freeword.length > config.freewordCountFulfiled - 1
    const isFreewordSpecialized = false
    const showMessage = true // 最初から表示する。

    const isSearchButtonEnabled = disabled

    return (
      <div className={ 'detailed-search' }>
        <h2 className={ 'form-title' }>{'詳細検索'}</h2>
        {showMessage ? (
          <ValidationErrorMessage
            message={ '検索を行うと、背後で入力中の案件の内容は消去されます。' }
          />
        ) : null}
        <div className={ 'flex detailed-search-wrap' }>
          <Box
            sx={ {
              display: 'grid',
              gridTemplateRows: 'repeat(4, max-content)',
              rowGap: '0.25rem',
            } }
          >
            <Box
              sx={ {
                padding: '0.5rem',
                border: '1px solid #e0e0e0',
              } }
            >
              <Box
                sx={ {
                  display: 'grid',
                  gridTemplateRows: 'repeat(7, max-content)',
                  rowGap: '0.25rem',
                  padding: '0.5rem',
                  background: '#eee',
                } }
              >
                <Input
                  name={ 'phoneNo' }
                  label={ '電話番号' }
                  disabled={ isFreewordSpecialized }
                  value={ phoneNo || '' }
                  update={ this.updateQuery }
                />
                <Input
                  name={ 'clientName' }
                  label={ '顧客名' }
                  disabled={ isFreewordSpecialized }
                  value={ clientName || '' }
                  update={ this.updateQuery }
                  wide
                />
                <CompleteCityCode
                  label={ '住所' }
                  cityCode={ cityCode }
                  onChange={ this.onCityChange }
                  query={ { cityCode: `${prefCode}${cityCode}` } }
                  replace={ () => {} }
                  disabled={ isFreewordSpecialized }
                  wide
                />
                <div>
                  <dl className={ 'label-inputs-wrap' }>
                    <dt className={ 'input-headline' }>
                      <label>{'住所(その他)'}</label>
                    </dt>
                    <dd className={ 'autocomplete-wrap' }>
                      <textarea
                        rows="2"
                        value={ address }
                        type={ 'text' }
                        onChange={ this.onChangeAddr }
                        disabled={ isFreewordSpecialized }
                        style={ { height: 'initial', width: '90%' } }
                      />
                    </dd>
                  </dl>
                </div>
                <Box
                  sx={ {
                    display: 'grid',
                    gridTemplateColumns: '5.5rem max-content',
                    alignItems: 'center',
                  } }
                >
                  <Typography variant="subtitle1" sx={ { fontWeight: 'bold' } }>
                    {'案件担当SS'}
                  </Typography>
                  <AutocompleteSs
                    staffId={ parseInt(staffId, 10) }
                    onChange={ this.onSsChange }
                    query={ { staffId } }
                    replace={ () => {} }
                    disabled={ isFreewordSpecialized }
                    small
                    verboseName
                    allStaffs
                    showRegion
                    inputName={ 'detailed-search-staff-input' }
                  />
                </Box>
                <Box
                  sx={ {
                    display: 'grid',
                    gridTemplateColumns: '5.5rem repeat(3, max-content)',
                    alignItems: 'center',
                  } }
                >
                  <Typography variant="subtitle1" sx={ { fontWeight: 'bold' } }>
                    {'日付'}
                  </Typography>
                  <WrappedCalendar
                    buttonId={ 'searchDateStart' }
                    value={ dateStart || '' }
                    onSelect={ this.onDateStartChange }
                    disabled={ isFreewordSpecialized }
                  />
                  <Typography>{'〜'}</Typography>
                  <WrappedCalendar
                    buttonId={ 'searchDateEnd' }
                    value={ dateEnd || '' }
                    onSelect={ this.onDateEndChange }
                    disabled={ isFreewordSpecialized }
                  />
                </Box>
                <AllUsers
                  label={ '更新者' }
                  userId={ userId }
                  onChange={ this.onUserChange }
                  query={ { userId } }
                  replace={ () => {} }
                  disabled={ isFreewordSpecialized }
                  wide
                />
              </Box>

              <Box
                sx={ {
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  columnGap: '1.5rem',
                  paddingInlineStart: '0.5rem',
                } }
              >
                <Checkbox
                  labelText={ '依頼者フラグ' }
                  labelWeight={ 'bold' }
                  disabled={ isFreewordSpecialized }
                  checked={ !!isClient }
                  value={ !!isClient }
                  onChange={ this.createUpdateQueryHandler({
                    isClient: !isClient,
                  }) }
                />
                <Checkbox
                  labelText={ '支払者フラグ' }
                  labelWeight={ 'bold' }
                  disabled={ isFreewordSpecialized }
                  checked={ !!isPayer }
                  value={ !!isPayer }
                  onChange={ this.createUpdateQueryHandler({
                    isPayer: !isPayer,
                  }) }
                />
              </Box>
              <Box
                sx={ {
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  columnGap: '1.5rem',
                  paddingInlineStart: '0.5rem',
                } }
              >
                <Checkbox
                  labelText={ 'キャンセル' }
                  labelWeight={ 'bold' }
                  disabled={ isFreewordSpecialized }
                  checked={ !!isCanceled }
                  value={ !!isCanceled }
                  onChange={ this.createUpdateQueryHandler({
                    isCanceled: !isCanceled,
                  }) }
                />
                <Checkbox
                  labelText={ '保留' }
                  labelWeight={ 'bold' }
                  disabled={ isFreewordSpecialized }
                  checked={ !!isSuspended }
                  value={ !!isSuspended }
                  onChange={ this.createUpdateQueryHandler({
                    isSuspended: !isSuspended,
                  }) }
                />
              </Box>
              <Box sx={ { paddingInlineStart: '0.5rem' } }>
                <Input
                  name={ 'orderId' }
                  label={ '注文ID' }
                  disabled={ isFreewordSpecialized }
                  value={ orderId || '' }
                  update={ this.updateQuery }
                />
              </Box>
            </Box>
            <Input
              name={ 'negotiation' }
              label={ '交渉履歴' }
              value={ negotiation || '' }
              update={ this.updateQuery }
              wide
            />
            <Input
              name={ 'workType' }
              label={ '依頼内容' }
              value={ workType || '' }
              update={ this.updateQuery }
              wide
            />
            <Input
              name={ 'receiptNumber' }
              label={ '受付番号' }
              value={ receiptNumber || '' }
              update={ this.updateQuery }
              wide
            />
            <div style={ { display: 'flex' } }>
              <ClearButton onClick={ this.removeQuery }>{'クリア'}</ClearButton>
              <Button
                onClick={ this.doSearchDefault }
                disabled={ isSearchButtonEnabled }
              >
                {isFreewordSpecialized ? 'フリーワード検索' : '詳細検索'}
              </Button>
            </div>
          </Box>
          {/* .detailed-search-forms */}

          {status !== 'not_yet' && (
            <div className={ 'detailed-search-results-wrap' }>
              <SearchResults
                route={ 'call' }
                className={ 'detailed-search-results' }
                result={ result }
                query={ query }
                status={ status }
                // 詳細検索から呼ばれた時に限り、コールバックを渡しておく
                // 詳細検索のモーダルを消したいため
                isDetailedSearch
                callback={ closeMe }
                update={ this.props.update }
                reset={ this.props.reset }
                renderPagenation={ this.renderPagenation }
                page={ this.state.page }
              />
            </div>
          )}
        </div>

        <button
          className={ 'button button-fixed close-mark' }
          onClick={ closeMe }
        />
      </div>
    )
  }
}

export default connect(mapStateToProps)(DetailedSearchForms)
