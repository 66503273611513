import React from 'react'
import PropTypes from 'prop-types'
import { Dl, Dt, Dd } from 'src/styled/list'
import { borderDarkGray } from 'src/colors'

/**
 * 精算処理｜詳細　伝票一覧の表示
 */

// 伝票の種別に対応する名前(2,3,1,4,5の順)
const documentType = {
  1: '見積書',
  2: '契約書',
  3: '領収書',
  4: '作業確認図',
  5: '工事完了確認書',
  6: '作業完了確認書',
}

// テーブルのスタイル
const tableStyle = {
  marginLeft: '30px',
}

const trStyle = {
  borderBottom: `1px solid ${borderDarkGray}`,
}

const tdStyle = {
  width: '120px',
  padding: '3px 0 2px',
}

const tdNumStyle = {
  padding: '3px 0 2px 10px',
}

export const Documents = props => {
  const { name, items } = props

  return [
    <Dl key={ 0 } style={ { alignItems: 'baseline' } }>
      <Dt>{name}</Dt>
      <Dd strech>
        {items.length > 0 && (
          <table style={ tableStyle }>
            <thead />
            <tbody>
              {items.map(item => {
                return (
                  <>
                    <tr key={ item['documentType'] } style={ trStyle }>
                      <td style={ tdStyle }>
                        {documentType[item['documentType']]}
                      </td>
                      <td style={ tdNumStyle }>{item['numbers'].join(', ')}</td>
                    </tr>
                  </>
                )
              })}
            </tbody>
          </table>
        )}
      </Dd>
    </Dl>,
  ]
}

Documents.propTypes = {
  name: PropTypes.string.isRequired, // 表のタイトル
  items: PropTypes.array.isRequired, // 伝票のデータ
  uploads: PropTypes.array.isRequired, // アップロードした伝票のデータ
}

export default Documents
