import utils from 'src/lib/api-utils'

export const getSettlement = ({ from, to }, staffId, accessToken, env) => {
  const base = `${utils.createEndpoint(env, 'settlement')}`
  const endpoint = `${base}/${from},${to}/${staffId}`

  const request = fetch(endpoint, {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  })

  return request
}

export default getSettlement
