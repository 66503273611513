import React from 'react'
import PropTypes from 'prop-types'
import connect from '../../../connect'
import Sticky from 'src/lib/class-sticky'
import findContact from '../../lib/find-contact'
import { contactInputStyle } from 'src/lib/format'

/**
 * その他の住所を入力します
 * @type {ReactComponent}
 */
export class FreeDescibe extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    tabIndex: PropTypes.number.isRequired,
    contactsIndex: PropTypes.number.isRequired,
    route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
    disabled: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
    tabOrder: PropTypes.number,
    isDelete: PropTypes.bool,
    // dispatchProps
    updateStage: PropTypes.func.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    id: null,
    contacts: [],
    tabOrder: 0,
    isDelete: false,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return (
      this.props.sticky !== nextProps.sticky ||
      this.props.disabled !== nextProps.disabled ||
      this.props.readOnly !== nextProps.readOnly
    )
  }

  /**
   * textbox change handler
   * @param  {Event} e event
   * @return {void}
   */
  onChange = e => {
    const { sticky, tabIndex, contactsIndex, updateStage } = this.props
    const freeDescribe = e.target.value

    const id = sticky.getContactId(tabIndex, contactsIndex)

    const diffProps = {
      contacts: {
        [tabIndex]: {
          [contactsIndex]: { id, freeDescribe },
        },
      },
    }

    updateStage(new Sticky(diffProps))
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const {
      // ownProps
      sticky,
      tabIndex,
      contactsIndex,
      route,
      disabled,
      readOnly,
      tabOrder,
      isDelete,
      // stateProps
    } = this.props

    const contact = findContact(sticky, tabIndex, contactsIndex) || {}
    const freeDescribe = contact.freeDescribe || ''

    const identifier = `contact-free-describe-${tabIndex}-${contactsIndex}-form-${route}`

    return (
      <dl
        className={ 'label-inputs-wrap' }
        style={ {
          display: 'grid',
          gridTemplateColumns: '5.5rem 1fr',
          columnGap: '0.5rem',
        } }
      >
        <dt>
          <label htmlFor={ identifier }>{'引継ぎ事項'}</label>
        </dt>

        <dd className={ 'input-wrap' }>
          <div>
            <textarea
              rows="3"
              value={ freeDescribe || '' }
              id={ identifier }
              name={ identifier }
              type={ 'text' }
              onChange={ this.onChange }
              disabled={ disabled }
              readOnly={ readOnly }
              style={ {
                ...contactInputStyle({ disabled, readOnly, isDelete }),
                width: 'clamp(200px, calc(100vw * .42 - 148px), 620px)',
              } }
              tabIndex={ tabOrder }
            />
          </div>
        </dd>
      </dl>
    )
  }
}

export default connect(FreeDescibe)
