/**
 * noop does nothing
 * @param  {any} x anything
 * @return {any}   passed arguments
 */
export default x => x

/**
 * render null
 * @return {null} null
 */
export const renderNull = () => null
