import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import Sticky from 'src/lib/class-sticky'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`

const TimeText = styled.span`
  margin-left: 10px;
  font-size: 0.5em;
  font-weight: bold;

  &:first-child {
    margin-bottom: 3px;
  }
`

export const Updated = props => {
  const { updateAt, receivedAt } = props.sticky.json()
  return (
    <Wrap>
      <TimeText>
        {'受付: ' + moment(receivedAt).format('YYYY-MM-DD HH:mm')}
      </TimeText>
      <TimeText>
        {'更新: ' + moment(updateAt).format('YYYY-MM-DD HH:mm')}
      </TimeText>
    </Wrap>
  )
}

/**
 * propTypes
 * @type {object}
 */
Updated.propTypes = {
  sticky: PropTypes.instanceOf(Sticky).isRequired,
}

export default Updated
