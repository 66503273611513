import { connect } from 'react-redux'

/**
 * dispatch関連
 */
import { createActions as createCallActions } from 'src/reducers/call'

/**
 map state to props
 * @param  {object} state mapping state
 * @return {object}       mapped state as props
 */
const mapStateToProps = state => {
  return {
    accessToken: state.login.authentication.accessToken,
    env: state.env,
  }
}

/**
 * map dispatch to props
 * @param  {function} dispatch Dispatcher
 * @return {object}             mapped dispatcher as props
 */
const mapDispatchToProps = dispatch => ({
  setStaffs: staffs => dispatch(createCallActions.set(staffs)),
})

export default Component =>
  connect(mapStateToProps, mapDispatchToProps)(Component)
