import React from 'react'
import PropTypes from 'prop-types'
import { NETWORK_STATUSES } from 'src/reducers/network-status'
import { ErrorLine, IconWrap } from './styled'

/**
 * ログイン状態を表示するコンポーネント
 * @param {object} props props
 * @return {ReactComponent}
 */
const Status = props => {
  const { status } = props

  // ネットワーク成否に応じて表示内容を制御する
  if (status === NETWORK_STATUSES.FAILURE) {
    return <ErrorLine>{'ログインできませんでした。'}</ErrorLine>
  } else {
    if (status === NETWORK_STATUSES.TRYING) {
      return (
        <IconWrap>
          <i className={ 'fa fa-spinner fa-pulse fa-fw' } />
        </IconWrap>
      )
    } else {
      return null
    }
  }
}

/**
 * proptyppes
 * @type {object}
 */
Status.propTypes = {
  // ownProps
  status: PropTypes.symbol.isRequired,
}

export default Status
