/**
 * 付箋データ入力フォームの並び順などを定義する
 * @file
 */

import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { connect } from 'react-redux'
import ReRequestMark from 'src/components/commons/re-request-mark'
import Updated from 'src/components/commons/updated'
import config from 'src/config'
import Sticky from 'src/lib/class-sticky'
import { renderNull } from 'src/lib/noop'
import switz from 'switz'

import FacilityFamilyModal from 'src/components/commons/facility-family-modal'
import ValidationErrorMessage from 'src/components/commons/validation-error-message'
/**
 * 再利用性の低いコンポーネントたち
 */
// コール課題業務
import Contacts from '../custom-controls/contacts'
// import VisitedHistories from '../custom-controls/visited-histories'
// アサイン課業務
import HlTukanStats from 'src/components/commons/custom-controls/hl-tukan.jsx'
import ReformStaffSelect from '../custom-controls/assign/reform-staff-select'
// マルシー
// 汎用コンポーネント
import Authorize from 'src/components/commons/authorize'
import CancelStats from 'src/components/commons/custom-controls/cancel-stats'
import MarucParent from 'src/components/commons/maruc-parent'
import TwoWorkParent from 'src/components/commons/twowork-parent'
import { ConnectedBubble } from 'src/components/divisions/consultation/maruc-list/home/partials/bubbles'
import {
  Label,
  LabelText,
  LabelsWrap,
} from 'src/components/divisions/order-list/partials/labels'
import Background from '../background'
import {
  CloseButton,
  ContractButton,
  ESignAgreementButton,
  HLWorkCompleteButton,
  ImcompletContact,
  KPhotoButton,
  PhotoButton,
  QuotationButton,
  ReceiptButton,
  ShipInventoryButton,
  TitleWrap,
  WorkCompleteButton
} from './styled'
// lib
import { conditionIds } from 'src/components/divisions/order-list/partials/conditions'
import { actionCreators as createLightboxActions } from 'src/reducers/lightbox'

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
export const mapStateToProps = state => {
  return {
    paymentNetworkStatus: state.payments.network,
    roles: state.login.authentication.roles,
  }
}

/**
 * map dispatch to props
 * @param  {function} dispatch dispatcher
 * @param  {object}   ownProps own props
 * @return {object}            dispatch props
 */
const mapDispatchToProps = dispatch => {
  return {
    openLightbox: (stickyId, orderId, apitype) =>
      dispatch(createLightboxActions.open(stickyId, orderId, 'RIGHT', apitype)),
  }
}

/**
 * 付箋入力フォームを描画します
 * @class {ReactComponent}
 */
const NormalForms = ({
  location,
  sticky,
  route,
  allowEdit,
  update,
  consultation, // 相談室のときtrue
  marucRepeat, // trueのときリピートのあるマルシーの交渉履歴を入力不可にする
  closeModal,
  newSticky, // 新規付箋の作成
  paymentNetworkStatus,
  roles,
  openLightbox,
  renderPreContent,
  renderPostContent,
}) => {
  const [showQuotation, setShowQuotation] = useState(false)
  const [showContract, setShowContract] = useState(false)

  const isEC = sticky.json()?.companyId === 5

  const houseLaboClientRef = useRef()

  /**
   * アサイン課から呼び出した時、コール課から呼び出した時, 過去履歴を呼び出した時でタイトルを変える
   * @return {string} タイトル
   */
  const getTitle = () => {
    const parentId = sticky._props.parentId || -1

    // 一番よく使うタイトル。各種モーダルを表示するときに特別なrouteを指定して表示する時に使用。
    const getAssignTitle = sticky => {
      if (sticky.getUserValue('reRequestOf')) {
        return newSticky ? (
          <TitleWrap>
            <ReRequestMark />
          </TitleWrap>
        ) : (
          <TitleWrap>
            <Updated sticky={ sticky } />
            <ReRequestMark />
            <HlTukanStats sticky={ sticky } />
            {stickyStatuses(sticky)}
            <OwnerInfo sticky={ sticky } />
          </TitleWrap>
        )
      } else {
        return newSticky ? (
          <TitleWrap>
            <CancelStats parentId={ parentId } />
          </TitleWrap>
        ) : (
          <TitleWrap>
            <Updated sticky={ sticky } />
            <CancelStats parentId={ parentId } />
            <HlTukanStats sticky={ sticky } />
            {stickyStatuses(sticky)}
            <OwnerInfo sticky={ sticky } />
          </TitleWrap>
        )
      }
    }

    return switz(route, s =>
      s
        .case('assign', () => {
          return getAssignTitle(sticky)
        })
        .case('twowork', () => {
          return getAssignTitle(sticky)
        })
        .case('maruc', () => {
          return getAssignTitle(sticky)
        })
        .case('call', () => {
          if (sticky.getUserValue('reRequestOf')) {
            return (
              <TitleWrap>
                <ReRequestMark />
              </TitleWrap>
            )
          } else {
            return (
              <TitleWrap>
                <CancelStats parentId={ parentId } />
              </TitleWrap>
            )
          }
        })
        .case('history', () => {
          if (sticky.getUserValue('reRequestOf')) {
            return newSticky ? (
              <TitleWrap>
                <ReRequestMark />
              </TitleWrap>
            ) : (
              <TitleWrap>
                <Updated sticky={ sticky } />
                <ReRequestMark />
                <HlTukanStats sticky={ sticky } />
                {stickyStatuses(sticky)}
                <OwnerInfo sticky={ sticky } />
              </TitleWrap>
            )
          } else {
            return newSticky ? (
              <TitleWrap>
                <CancelStats parentId={ parentId } />
              </TitleWrap>
            ) : (
              <TitleWrap>
                <Updated sticky={ sticky } />
                <CancelStats parentId={ parentId } />
                <HlTukanStats sticky={ sticky } />
                {stickyStatuses(sticky)}
                <OwnerInfo sticky={ sticky } />
              </TitleWrap>
            )
          }
        })
        .default(() => `不明な画面です: ${route}`),
    )
  }

  /**
   * 付箋の状態を表示する。
   */
  const stickyStatuses = sticky => {
    const { stickyConditions } = config
    const cids = conditionIds(sticky.json())
    const statuses = cids.map(id => {
      const condition = stickyConditions.find(cond => cond.id === id)
      if (condition) {
        return (
          <LabelsWrap key={ condition.id }>
            <Label
              color={ condition.color }
              borderColor={ condition.borderColor }
            />
            <LabelText>{condition.dispName || condition.name}</LabelText>
          </LabelsWrap>
        )
      } else {
        return null
      }
    })
    // マルシー付箋の時は状態表示を追加する。
    const marucStatus = (sticky._props.marucComplaint || {}).marucStatus
    const marucStatusDisp =
      sticky.isClaimerOrder && marucStatus ? (
        <ConnectedBubble
          marucStatusId={ marucStatus }
          style={ { marginLeft: '20px', marginTop: '0' } }
        />
      ) : null
    return (
      <LabelsWrap center style={ { fontSize: '0.7em' } }>
        {hasIncomplete(sticky)}
        {statuses}
        {marucStatusDisp}
        <MarucParent sticky={ sticky } />
        <TwoWorkParent sticky={ sticky } />
      </LabelsWrap>
    )
  }

  /**
   * 売掛金の残金がある時の表示
   */
  const hasIncomplete = sticky => {
    // 一つでもaccountRemain=trueのcontactがあるか
    const hasIncompleteContact = (sticky._props.contacts || []).reduce(
      (prev, tabContacts) =>
        prev ||
        (tabContacts || []).reduce(
          (prev2, contact) => prev2 | !!contact.accountRemain,
          false,
        ),
      false,
    )
    return hasIncompleteContact ? (
      <ImcompletContact>{'残金あり'}</ImcompletContact>
    ) : null
  }

  /**
   * 写真を表示する
   */
  const openPhotoLightbox = () => {
    const { orderId } = sticky.json()
    openLightbox(sticky.id, orderId, 'EXPO')
  }

  /**
   * 見積書PDFの一覧表示
   */
  const openQuotationLightbox = () => {
    const { orderId } = sticky.json()
    setShowQuotation(false)
    openLightbox(sticky.id, orderId, 'QPDF')
  }

  /**
   * 保留時角印付きの見積書PDFの一覧表示
   */
  const openKQuotationLightbox = () => {
    const { orderId } = sticky.json()
    setShowQuotation(false)
    openLightbox(sticky.id, orderId, 'QKPDF')
  }

  /**
   * 請求書PDFの一覧表示
   */
  const openContractLightbox = () => {
    const { orderId } = sticky.json()
    setShowContract(false)
    openLightbox(sticky.id, orderId, 'CPDF')
  }

  /**
   * 保留時角印付きの請求書PDFの一覧表示
   */
  const openKContractLightbox = () => {
    const { orderId } = sticky.json()
    setShowContract(false)
    openLightbox(sticky.id, orderId, 'CKPDF')
  }

  /**
   * 工事完了書PDFの一覧表示
   */
  const openWorkCompleteLightbox = () => {
    const { orderId } = sticky.json()
    openLightbox(sticky.id, orderId, 'WPDF')
  }

  /**
   * 領収書PDFの一覧表示
   */
  const openReceiptLightbox = () => {
    const { orderId } = sticky.json()
    openLightbox(sticky.id, orderId, 'RPDF')
  }

  /**
   * 出庫同意書PDFの一覧表示
   */
  const openShipInventoryLightbox = () => {
    const { orderId } = sticky.json()
    openLightbox(sticky.id, orderId, 'SPDF')
  }

  /**
   * 作業完了確認書PDFの一覧表示
   */
  const openHLWorkCompleteLightbox = () => {
    const { orderId } = sticky.json()
    openLightbox(sticky.id, orderId, 'HLWorkCompletePDF')
  }

  /**
   * 「交換の達人」の工事完了確認書PDFの一覧表示
   */
  const openECWorkCompleteLightbox = () => {
    const { orderId } = sticky.json()
    openLightbox(sticky.id, orderId, 'ECWorkCompletePDF')
  }

  /**
   * 電子交付の承諾書PDFの一覧表示
   */
  const openESignAgreementLightbox = () => {
    const { orderId } = sticky.json()
    openLightbox(sticky.id, orderId, 'ESignAgreement')
  }

  /**
   * 見積書表示の処理切り替え
   * メモ：ダイアログが不要になり、経理課で印有のみを表示するという変更があった場合：
   * （どちらも見たいという要望に合わせたが、印有だけでいいと変更することがありそうなため）
   * showQuotation, showContractに関する処理を削除。
   * render()内でダイアログのdivと使っているスタイルを削除。
   */
  const necessaryRoles = [config.roles.accounting, config.roles.hlsolution]
  const isAuthorized = necessaryRoles.some(role => roles.includes(role))

  const openQuotation = () => {
    if (isAuthorized) {
      setShowQuotation(true)
    } else {
      openQuotationLightbox()
    }
  }

  const closeQuotation = () => {
    setShowQuotation(false)
  }

  /**
   * 契約書表示の処理切り替え
   */
  const openContract = () => {
    if (isAuthorized) {
      setShowContract(true)
    } else {
      openContractLightbox()
    }
  }

  const closeContract = () => {
    setShowContract(false)
  }

  const onChangeFreeDial = freeDial => {
    houseLaboClientRef.current?.changeFreeDial(freeDial)
  }

  const stickyProps = sticky.json()
  const isMailerEnabled = route === 'assign' || route === 'history'

  let newContactExists = false
  for (let i = 0; i < sticky.stickyContactIds.length; i++) {
    const id = sticky.stickyContactIds[i]
    if (id === -1 || id === null || id === void 0) {
      newContactExists = true
      break
    }
  }

  // SSに割当、確定作業日時の指定があるときも配置済みとする。
  const determinedDate = (stickyProps.determinedDateTime || {}).date || null
  const isAssigned = stickyProps.staffId !== null && determinedDate !== null

  const isDocked =
    (location.regionIndex === -1 || location.listIndex === -1) && !isAssigned

  const disabled = !allowEdit && route === 'history'

  const isClaim = sticky.json().orderTypeId === config.orderType.claimer

  // マルシーリピート付箋がある時は交渉履歴を入力不可にする（アサイン課画面は除く）
  const hasMarucRepeat =
    isClaim && !!sticky.getUserValue('hasMarucRepeat') && marucRepeat
  const Loading = () =>
    switz(paymentNetworkStatus, s =>
      s
        .case('REQUESTING', () => (
          <i className={ 'fa fa-spinner fa-pulse fa-fw' } />
        ))
        .default(() => null),
    )

  const PreContent = renderPreContent
  const PostContent = renderPostContent

  const isHouseLabo = sticky._props.companyId === config.company.HL
  const isHomeServe = sticky._props.companyId === config.company.SV
  const twoPersonParentId = sticky._props.twoPersonParentId || -1
  const isHLTwoWork = isHouseLabo && twoPersonParentId !== -1

  const buttonView = {
    position: 'fixed',
    bottom: '45px',
    right: '164px',
    zIndex: 1000,
    background: 'rgba(255, 255, 255, 0.9)',
    padding: '8px',
    boxShadow: 'rbga(0, 0, 0, 0.4) 0px 10px 10px',
    backdropFilter: 'blur(4px)',
    borderRadius: '14px',
  }
  const buttonHeader = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }
  const buttonTitle = {
    flex: 1,
    background: 'lightgrey',
    padding: '4px',
    marginBottom: '4px',
    textAlign: 'center',
  }
  const buttonListWrap = {
    width: '220px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  }
  const buttonList = {
    width: '150px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  }

  return (
    <Background sticky={ sticky }>
      <div className={ 'new-order-input' }>
        <h2 className={ 'form-title' }>
          {getTitle()}
          {route === 'call' ? null : <Loading />}
        </h2>
        {paymentNetworkStatus === 'FAILURE' ? (
          <ValidationErrorMessage
            message={ 'サーバーエラー: 支払情報が取得できません' }
          />
        ) : null}
        {/* callのとき、案件登録のボタンをPreContentで渡す */}
        {route === 'call' && <PreContent />}
        <PostContent />
        {/* TODO: コンタクトのupdateオーバーライドが聞いていないっぽい 2018/07/07 */}

        {/* {isHLTwoWork && (
          <HlTwoPersonWorkType
            sticky={ sticky }
            route={ route }
            disabled={ disabled }
            update={ update }
          />
        )}
        {isHLTwoWork && <Hr />} */}
        {/* HL二人工判別 */}

        {/* {route === 'call' ? (
          <FreeDial
            sticky={ sticky }
            route={ route }
            update={ update }
            onChangeFreeDial={ onChangeFreeDial }
          />
        ) : (
          <div
            style={ {
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'baseline',
            } }
          >
            <div>
              <OrderId sticky={ sticky } />
            </div>
            <div style={ { marginLeft: '5rem' } }>
              <FreeDial
                sticky={ sticky }
                route={ route }
                update={ update }
                onChangeFreeDial={ onChangeFreeDial }
              />
            </div>
          </div>
        )}

        <Hr />

        <OrderType
          sticky={ sticky }
          route={ route }
          disabled={ disabled }
          update={ update }
        /> */}
        {/* 注文タイプ */}

        {/* <Hr />

        <Company
          sticky={ sticky }
          route={ route }
          disabled={ disabled }
          update={ update }
        /> */}
        {/* 受付会社 */}

        {/* <Hr />

        <PersonalType
          sticky={ sticky }
          route={ route }
          disabled={ disabled }
          update={ update }
        /> */}
        {/* 年齢層(受電タイプ) */}

        {/* <Hr />

        <Medium
          sticky={ sticky }
          route={ route }
          disabled={ disabled }
          update={ update }
        /> */}
        {/* 受電媒体（受電） */}

        {/* <Hr />
        <div style={ { display: isHouseLabo ? 'inherit' : 'none' } }>
          <HouseLaboClient
            sticky={ sticky }
            route={ route }
            disabled={ disabled }
            update={ update }
            forwardRef={ houseLaboClientRef }
          />
        </div>
        {isHomeServe && (
          <HomeServeClient
            sticky={ sticky }
            route={ route }
            disabled={ disabled }
            update={ update }
          />
        )} */}
        <Contacts
          { ...{
            sticky,
            route,
            disabled,
            update,
          } }
          closeModal={ closeModal }
        />

        {/* <Hr />

        <BuildingType
          sticky={ sticky }
          route={ route }
          disabled={ disabled }
          update={ update }
        /> */}
        {/* 建物情報 */}

        {/* <Hr />

        <CustomerType
          sticky={ sticky }
          route={ route }
          disabled={ disabled }
          update={ update }
        /> */}
        {/* 顧客タイプ */}

        {/* <Hr />

        <BuildYearsRank
          sticky={ sticky }
          route={ route }
          disabled={ disabled }
          update={ update }
        /> */}
        {/* 築年数 */}

        {/* <Hr />

        <WorkPoint
          sticky={ sticky }
          route={ route }
          disabled={ disabled }
          update={ update }
        /> */}
        {/* 作業箇所 */}

        {/* <Hr />

        <WorkType
          sticky={ sticky }
          route={ route }
          disabled={ disabled }
          update={ update }
        /> */}
        {/* 現況 */}
        {/*
        {isClaim && <Hr />}
        {isClaim && (
          <MarucContent
            sticky={ sticky }
            location={ location }
            route={ route }
            disabled={ disabled }
            update={ update }
          />
        )} */}
        {/* マルシー内容：マルシー付箋の時のみ表示 */}
        {/* {!isClaim && (
          <>
            <Hr />
            <div
              style={ {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'center',
              } }
            >
              <WishDateTime
                { ...{ sticky, wishDateTimeIndex: 0, route, disabled } }
                update={ update }
              /> */}
        {/* 対応希望日1 */}
        {/* <WishDateTime
                { ...{ sticky, wishDateTimeIndex: 1, route, disabled } }
                update={ update }
              /> */}
        {/* 対応希望日2 */}
        {/* <WishDateTime
                { ...{ sticky, wishDateTimeIndex: 2, route, disabled } }
                update={ update }
              /> */}
        {/* 対応希望日3 */}
        {/* <LeaveMeUntilPhoneCall
                { ...{
                  sticky,
                  disabled,
                  update,
                } }
              />
              <Adjusting
                { ...{
                  sticky,
                  disabled,
                  update,
                } }
              />
            </div>
          </>
        )}

        <Hr />
        <DeterminedDateTime
          sticky={ sticky }
          route={ route }
          disabled={ disabled }
          location={ location }
          update={ update }
        /> */}
        {/* 目安作業日 */}
        {/*
        <Hr />
        <HandOver
          sticky={ sticky }
          route={ route }
          disabled={ disabled }
          update={ update }
        /> */}
        {/* 重要申し送り事項 */}
        {/*
        <Hr />
        <NegotiationRecords
          sticky={ sticky }
          route={ route }
          disabled={ disabled || hasMarucRepeat }
          hasMarucRepeat={ hasMarucRepeat }
          update={ update }
          consultation={ consultation }
        /> */}
        {/* 交渉履歴 */}

        {/* {isClaim && <Hr />}
        <Complaint
          sticky={ sticky }
          route={ route }
          disabled={ disabled }
          update={ update }
        /> */}
        {/* マルシー内容入力 */}

        {/* <VisitedHistories { ...{ visitedHistories, route } } /> */}
        {/* 訪問履歴 */}
        {/*
        {!isClaim && <Hr />}
        {!isClaim && (
          <Region
            sticky={ sticky }
            route={ route }
            disabled={ disabled }
            update={ update }
          />
        )} */}
        {/* 地域選択 */}
        {/* <Hr />

        <Priority
          sticky={ sticky }
          route={ route }
          disabled={ disabled }
          update={ update }
        /> */}
        {/* 優先度 */}
        {/* </div>

      <div className={ 'assign-input' }>
        <Hr />
        <div>
          {isClaim || (
            <> */}
        {/* <ServiceStaff route={ route } /> */}
        {/* <SSStatus sticky={ sticky } update={ update } /> */}
        {/* 担当者 */}
        {/* </>
          )}
          <Hr />
          {isMailerEnabled ? <Messager sticky={ sticky } /> : null} */}
        {/* メッセージ送信 */}
        {/* {isMailerEnabled ? <Mailer sticky={ sticky } route={ route } /> : null} */}
        {/* メール送信 */}
        {/* {isClaim || (
            <>
              <ReRequest
                sticky={ sticky }
                route={ route }
                disabled={ disabled }
                update={ update }
              /> */}
        {/* 再依頼 */}
        {/* <EstimateNumber
                sticky={ sticky }
                route={ route }
                disabled={ disabled }
                update={ update }
              /> */}
        {/* 見積もり番号 */}
        {/* <EstimatePayment
                sticky={ sticky }
                route={ route }
                disabled={ disabled }
                update={ update }
                maruc={ false }
              /> */}
        {/* 見積もり */}
        {/* <WorkContent
                sticky={ sticky }
                route={ route }
                disabled={ disabled }
                update={ update }
              /> */}
        {/* 作業内容 */}
        {/* <SuspendReason
                sticky={ sticky }
                route={ route }
                disabled={ disabled }
                update={ update }
              /> */}
        {/* 中断理由 */}
        {/* <Hr />
            </>
          )}
          {isClaim && (
            <>
              <EstimatePayment
                sticky={ sticky }
                route={ route }
                disabled={ disabled }
                update={ update }
                maruc
              /> */}
        {/* 見積もりのみマルシーでも表示 */}
        {/* </>
          )}
        </div>
        <AfterDateTime
          sticky={ sticky }
          route={ route }
          disabled={ disabled || isDocked } // 未配置状態の時は、アフタを作らせない
          update={ update }
          isDocked={ isDocked }
          nextId={ '' }
        /> */}
        {/* アフター */}
        {/* <Hr />
        {isClaim || isHLTwoWork || (
          <>
            <TwoWorkSticky sticky={sticky} />
            <Hr />
          </>
        )} */}
        {/* 二人工付箋作成 */}
        {/* <IsLiquidated sticky={sticky} disabled={disabled} update={update} />
        {isClaim || (
          <div> */}
        {/* 精算済み */}
        {/* <PaymentsWrap
              sticky={sticky}
              route={route === 'history' ? 'assign' : route}
              disabled={disabled || false}
              newContactExists={newContactExists}
              propUpdate={update}
            /> */}
        {/* ハウスラボ のデータを更新できるようupdateを渡すよう変更 */}
        {/* コール課の検索画面から表示した際も金額を編集できるようにするため、routeを置き換える */}
        {/* 支払 */}
        {/* <Hr />
            <ThankYouCall
              sticky={sticky}
              route={route}
              disabled={disabled}
              update={update}
            /> */}
        {/* サンキューコール */}
        {/* <Hr />
          </div>
        )}
        <FinishState
          {...{
            sticky,
            route,
            location,
            disabled,
          }}
          update={update}
        /> */}
        {/* 終了状態 */}
        {/* TODO: stickyインスタンスのメソッドを呼ぶようにする */}
        {/* キャンセル理由 */}
        {/* {sticky.json().finishStateId === config.finishState.cancel ? (
          <CancelReason
            sticky={sticky}
            route={route}
            disabled={disabled}
            update={update}
          />
        ) : null}
        {/* リフォーム引継 */}
        {sticky.json().finishStateId === config.finishState.reformComplete ? (
          <ReformStaffSelect
            disabled={ disabled }
            route={ route }
            sticky={ sticky }
            update={ update }
          />
        ) : null}
        {/* 除外対象理由 */}
        {/* {sticky.json().finishStateId === config.finishState.na ? (
          <NaReason
            sticky={sticky}
            route={route}
            disabled={disabled}
            update={update}
          />
        ) : null} */}
        {/* TODO: 表示用のフラグとボタンを追加する。 */}
        {/* {isHouseLabo && (
          <>
            <Hr />
            <HlInvoce
              sticky={sticky}
              route={route}
              disabled={disabled}
              update={update}
            />
          </>
        )} */}
        {/* <Hr />
        <Warranty sticky={sticky} update={update} /> */}
        {/* あんしんサポート */}
        <PostContent />
      </div>
      {route !== 'call' && !isEC && (
        <Authorize roles={ config.roles.all }>
          <div style={ { position: 'relative' } }>
            {showQuotation && (
              <div style={ buttonView }>
                <div style={ buttonHeader }>
                  <p style={ buttonTitle }>{'見積書'}</p>
                  <CloseButton onClick={ closeQuotation }>
                    <i className={ 'fa fa-times' } />
                  </CloseButton>
                </div>
                <div style={ buttonListWrap }>
                  <div style={ buttonList }>
                    <KPhotoButton onClick={ openKQuotationLightbox }>
                      <i className={ 'fa fa-file-pdf-o' } />
                      <p style={ { fontSize: '0.75rem' } }>{'印有'}</p>
                    </KPhotoButton>
                    <KPhotoButton onClick={ openQuotationLightbox }>
                      <i className={ 'fa fa-file-pdf-o' } />
                      <p style={ { fontSize: '0.75rem' } }>{'印無'}</p>
                    </KPhotoButton>
                  </div>
                </div>
              </div>
            )}
            <QuotationButton onClick={ openQuotation }>
              <i className={ 'fa fa-file-pdf-o' } />
              <p style={ { fontSize: '0.75rem' } }>{'見積'}</p>
            </QuotationButton>
          </div>
        </Authorize>
      )}
      {route !== 'call' && !isEC && (
        <Authorize roles={ config.roles.all }>
          <div style={ { position: 'relative' } }>
            {showContract && (
              <div style={ buttonView }>
                <div style={ buttonHeader }>
                  <p style={ buttonTitle }>{'契約書'}</p>
                  <CloseButton onClick={ closeContract }>
                    <i className={ 'fa fa-times' } />
                  </CloseButton>
                </div>
                <div style={ buttonListWrap }>
                  <div style={ buttonList }>
                    <KPhotoButton onClick={ openKContractLightbox }>
                      <i className={ 'fa fa-file-pdf-o' } />
                      <p style={ { fontSize: '0.75rem' } }>{'印有'}</p>
                    </KPhotoButton>
                    <KPhotoButton onClick={ openContractLightbox }>
                      <i className={ 'fa fa-file-pdf-o' } />
                      <p style={ { fontSize: '0.75rem' } }>{'印無'}</p>
                    </KPhotoButton>
                  </div>
                </div>
              </div>
            )}
            <ContractButton onClick={ openContract }>
              <i className={ 'fa fa-file-pdf-o' } />
              <p style={ { fontSize: '0.75rem' } }>{'契約'}</p>
            </ContractButton>
          </div>
        </Authorize>
      )}
      {route !== 'call' && !isEC && (
        <Authorize roles={ config.roles.all }>
          <div>
            <WorkCompleteButton onClick={ openWorkCompleteLightbox }>
              <i className={ 'fa fa-file-pdf-o' } />
              <p style={ { fontSize: '0.75rem' } }>{'確認'}</p>
            </WorkCompleteButton>
          </div>
        </Authorize>
      )}
      {route !== 'call' && !isEC && (
        <Authorize roles={ config.roles.all }>
          <div>
            <ReceiptButton onClick={ openReceiptLightbox }>
              <i className={ 'fa fa-file-pdf-o' } />
              <p style={ { fontSize: '0.75rem' } }>{'領収'}</p>
            </ReceiptButton>
          </div>
        </Authorize>
      )}
      {route !== 'call' && !isEC && (
        <Authorize roles={ config.roles.all }>
          <div>
            <ShipInventoryButton onClick={ openShipInventoryLightbox }>
              <i className={ 'fa fa-file-pdf-o' } />
              <p style={ { fontSize: '0.75rem' } }>{'出庫'}</p>
            </ShipInventoryButton>
          </div>
        </Authorize>
      )}
      {/* {route !== 'call' && !isEC && (
        <Authorize roles={ config.roles.all }>
          <div>
            <HLWorkCompleteButton onClick={ openHLWorkCompleteLightbox }>
              <i className={ 'fa fa-file-pdf-o' } />
              <p style={ { fontSize: '0.75rem' } }>{'HL'}</p>
            </HLWorkCompleteButton>
          </div>
        </Authorize>
      )} */}
      {route !== 'call' && isEC && (
        <Authorize roles={ config.roles.all }>
          <div>
            <HLWorkCompleteButton onClick={ openECWorkCompleteLightbox }>
              <i className={ 'fa fa-file-pdf-o' } />
              <p style={ { fontSize: '0.75rem' } }>{'達人'}</p>
            </HLWorkCompleteButton>
          </div>
        </Authorize>
      )}
      {route !== 'call' && !isEC && (
        <Authorize roles={ config.roles.all }>
          <div>
            <ESignAgreementButton onClick={ openESignAgreementLightbox }>
              <i className={ 'fa fa-file-pdf-o' } />
              <p style={ { fontSize: '0.75rem' } }>{'承諾'}</p>
            </ESignAgreementButton>
          </div>
        </Authorize>
      )}
      {route !== 'call' && (
        <div>
          <PhotoButton onClick={ openPhotoLightbox }>
            <i className={ 'fa fa-camera' } />
          </PhotoButton>
        </div>
      )}
      {route !== 'call' && <FacilityFamilyModal sticky={ sticky } />}
    </Background>
  )
}

/**
 * PropTypes 型定義
 * @type {object}
 */
NormalForms.propTypes = {
  // ownProps
  location: PropTypes.shape({
    regionIndex: PropTypes.number.isRequired,
    listIndex: PropTypes.number.isRequired,
    stickyIndex: PropTypes.number.isRequired,
  }).isRequired,
  sticky: PropTypes.instanceOf(Sticky).isRequired,
  route: PropTypes.oneOf(['call', 'assign', 'history', 'maruc']).isRequired,
  renderPreContent: PropTypes.func,
  renderPostContent: PropTypes.func,
  isAfterPreview: PropTypes.bool,
  allowEdit: PropTypes.bool,
  update: PropTypes.func,
  consultation: PropTypes.bool, // 相談室のときtrue
  marucRepeat: PropTypes.bool, // trueのときリピートのあるマルシーの交渉履歴を入力不可にする
  closeModal: PropTypes.func,
  newSticky: PropTypes.bool, // 新規付箋の作成
  // stateProps
  paymentNetworkStatus: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  openLightbox: PropTypes.func.isRequired,
}

/**
 * defaultProps
 * @type {object}
 */
NormalForms.defaultProps = {
  isAfterPreview: false,
  allowEdit: true,
  update: void 0,
  consultation: false,
  marucRepeat: false,
  closeModal: () => {},
  newSticky: false,
}

/**
 * defaultProps
 * @type {object}
 */
NormalForms.defaultProps = {
  renderPreContent: renderNull,
  renderPostContent: renderNull,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(NormalForms))
