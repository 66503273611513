import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { CALL_API } from 'redux-api-middleware'
import { creator as createGetAllStickyAction } from 'src/reducers/wara/async/get-all-sticky'

const mapStateToProps = state => {
  return {
    accessToken: state.login.authentication.accessToken,
  }
}

export class APIDebugger extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    accessToken: PropTypes.string.isRequired,
  }

  state = { date: '', results: {} }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps, nextState) {
    return this.state !== nextState
  }

  onDateChange = e => this.setState({ date: e.target.value })

  onGetStickiesClick = () => {
    const { accessToken } = this.props
    const options = createGetAllStickyAction(this.state.date, accessToken)[
      CALL_API
    ]
    return fetch(options.endpoint, options)
      .then(res => res.json())
      .then(result =>
        this.setState({
          results: { ...this.state.results, getAllSticky: result },
        }),
      )
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const {
      date,
      results: { getAllSticky },
    } = this.state
    return (
      <div>
        <input type={ 'text' } value={ date } onChange={ this.onDateChange } />
        <button onClick={ this.onGetStickiesClick }>{'getStickies'}</button>
        <p>{JSON.stringify(getAllSticky)}</p>
      </div>
    )
  }
}

export default connect(mapStateToProps)(APIDebugger)
