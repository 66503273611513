/**
 * 現金の設定をチェックする
 * @param {*} payment
 */
export const isValidRefundPayment = payments => {
  const messages = []
  const cashValues = {} // contactName: sum(cashValue)
  const refundValues = {} // contactName: sum(cashValue)
  // contactName ごとに元の金額と返金額の合計を計算
  payments.forEach(payment => {
    const contactName = payment.origin.contactName
    cashValues[contactName] =
      (cashValues[contactName] || 0) + payment.origin.cashValue
    const refunds = payment.refunds || []
    const refundValue = refunds.reduce(
      (prev, refund) => prev + refund.refundValue,
      0,
    )
    refundValues[contactName] = (refundValues[contactName] || 0) + refundValue
  })
  // 一つでも元の金額より多い返金があればエラーにする
  let isValidRefunds = true
  Object.keys(cashValues).forEach(key => {
    if (cashValues[key] < (refundValues[key] || 0)) {
      isValidRefunds = false
    }
  })
  !isValidRefunds && messages.push('元の金額以上に返金することはできません。')
  // 結果を返す
  const result = isValidRefunds
  return { result, messages }
}

/**
 * 売掛金の設定をチェックする
 * @param {*} account
 */
export const isValidRefundAccount = accounts => {
  let result = true
  const messages = []
  for (let i = 0; i < accounts.length; i++) {
    const account = accounts[i]

    const remain = account.remain
    // accounts > histories > refundsを指定されているのに、afterRefundが0でない場合
    let isValidAfterRefund = true
    let hasRefunds = false // どこかにrefundsが指定されている
    const histories = account.histories || []
    histories.forEach(history => {
      if ((history.refunds || []).length > 0) {
        hasRefunds = true
      }
    })
    if (hasRefunds && remain.afterRefund !== 0) {
      isValidAfterRefund = false
      messages.push('回収履歴に返金があるとき残金の返金は設定できません。')
    }
    // remain.afterRefund > beforeRefund の時
    const isValidRemain = remain.afterRefund <= remain.beforeRefund
    !isValidRemain && messages.push('売掛金残金を超えた返金は設定できません。')

    // history.origin.collectionValue < sum(history.refunds.refundValue)の時
    let isValidHistory = true
    histories.forEach(history => {
      let refundValue = 0 // 返金の合計額
      const refunds = history.refunds || []
      refunds.forEach(refund => {
        refundValue += refund.refundValue || 0
      })
      if (refundValue > history.history.collectionValue) {
        isValidHistory = false
      }
    })
    !isValidHistory &&
      messages.push('回収履歴の金額を超えた返金は指定できません。')

    // エラーになったaccountが見つかった時点でチェックを終了
    result = isValidAfterRefund && isValidRemain && isValidHistory
    if (!result) {
      break
    }
  }
  return { result, messages }
}

/**
 * 付箋のマルシー返金にエラーがないかチェックする
 * @param {*} marucRefund ストアのマルシー返金
 * @param {*} stickyId 付箋のID
 */
export const isValidRefund = (marucRefund, stickyId) => {
  const stickyRefund = marucRefund[stickyId] || {}
  const payments = stickyRefund.payments || []
  const accounts = stickyRefund.accounts || []
  const { result: p_result, messages: p_messages } = isValidRefundPayment(
    payments,
  )
  const { result: a_result, messages: a_messages } = isValidRefundAccount(
    accounts,
  )
  const validRefund = p_result && a_result
  const allmessages = p_messages.concat(a_messages)
  return { validRefund, messages: allmessages }
}
