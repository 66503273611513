import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createActions as createAPIMetaActions } from 'src/reducers/api-meta'

// libs
import requestAPIVersion from 'src/lib/version-api'
import requestExpoAPIVersion from 'src/lib/version-api/expo-version'
import requestInventoryAPIVersion from 'src/lib/version-api/inventory-version'

export class ApiInitializer extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // stateProps
    accessToken: PropTypes.string.isRequired,
    env: PropTypes.object.isRequired,
    // dispatchProps
    setAPIVersion: PropTypes.func.isRequired,
    setExpoAPIVersion: PropTypes.func.isRequired,
    setInventoryAPIVersion: PropTypes.func.isRequired,
  }

  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    // バージョンは別々に取得するよう変更。
    requestAPIVersion(props.accessToken, props.env)
      .then(version => props.setAPIVersion(version))
      .catch(() => props.setAPIVersion('unknown'))
    requestExpoAPIVersion(props.accessToken, props.env)
      .then(version => props.setExpoAPIVersion(version))
      .catch(() => props.setExpoAPIVersion('unknown'))
    requestInventoryAPIVersion(props.accessToken, props.env)
      .then(version => props.setInventoryAPIVersion(version))
      .catch(() => props.setInventoryAPIVersion('unknown'))
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate = () => false

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render = () => null
}

/**
 map state to props
 * @param  {object} state mapping state
 * @return {object}       mapped state as props
 */
const mapStateToProps = state => {
  return {
    accessToken: state.login.authentication.accessToken,
    env: state.env,
  }
}

/**
 * map dispatch to props
 * @param  {function} dispatch Dispatcher
 * @return {object}             mapped dispatcher as props
 */
const mapDispatchToProps = dispatch => ({
  setAPIVersion: version => dispatch(createAPIMetaActions.set(version)),
  setExpoAPIVersion: version => dispatch(createAPIMetaActions.setExpo(version)),
  setInventoryAPIVersion: version =>
    dispatch(createAPIMetaActions.setInventory(version)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ApiInitializer)
