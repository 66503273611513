import React from 'react'
import PropTypes from 'prop-types'
import { toJPYenText } from 'src/lib/format'
import { Dl, Dt, Dd } from 'src/styled/list'
import { PluralTable } from '../../styled'

// {
//   "id": 1,
//   "companyName": "〇〇菅材",
//   "billingStart": "2018-10-01",
//   "billingEnd": "2018-10-07",
//   "sectionName": "関西",
//   "sectionId": 7,
//   "amount": 40000,
//   "updateAt": "2018-10-01T12:00:00.000000Z",
//   "updateUserId": "ope1",
//   "isConfirmed": false,
//   "isSettled": false
// }

const tableSpec = {
  headers: {
    companyName: '会社名',
    billingStart: '開始日',
    billingEnd: '終了日',
    amount: '金額',
    isConfirmed: '確定済み',
    isSettled: '精算済み',
  },
  keyOrders: [
    'companyName',
    'billingStart',
    'billingEnd',
    'amount',
    'isConfirmed',
    'isSettled',
  ],
  transform: {
    amount: toJPYenText,
    isConfirmed: isConfirmed => (isConfirmed ? '✅' : '-'),
    isSettled: isSettled => (isSettled ? '✅' : '-'),
  },
}

export const PipeMaterial = props => {
  const { items } = props
  const sum = items.reduce((prev, item) => prev + (item.amount || 0), 0)

  return [
    <Dl key={ 0 }>
      <Dt>{'材料費等'}</Dt>
      <Dd>{toJPYenText(sum)}</Dd>
    </Dl>,
    <Dl key={ 1 }>
      <Dt />

      <Dd strech>
        {items.length > 0 && (
          <PluralTable items={ items } tableSpec={ tableSpec } />
        )}
      </Dd>
    </Dl>,
  ]
}

PipeMaterial.propTypes = {
  items: PropTypes.array.isRequired,
}

export default PipeMaterial
