import { ACTION_TYPES as SEND_MAIL_ACTION_TYPES } from 'src/reducers/send-mail'
import { RESET as RESET_NETWORK_STATUS } from 'src/reducers/network-status'
import config from 'src/config'

/**
 * メール送信後、ちょっと待ってから、ステータスを消す
 * @param {{dispatch: function}} store redux store
 */
const resetSendMailNetworkStatus = ({ dispatch }) => {
  setTimeout(
    () =>
      dispatch({
        type: RESET_NETWORK_STATUS,
        payload: {
          target: 'sendMail',
        },
      }),
    config.messageResultDelay,
  )
}

export default {
  [SEND_MAIL_ACTION_TYPES.SENDMAIL_SUCCESS]: resetSendMailNetworkStatus,
  [SEND_MAIL_ACTION_TYPES.SENDMAIL_FAILURE]: resetSendMailNetworkStatus,
}
