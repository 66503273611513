import styled from 'styled-components'

export const Table = {
  Wrap: styled.div`
    display: block;
  `,

  Table: styled.table`
    overflow: scroll;
  `,

  // 今の所奇数行の背景は白で固定
  Tr: styled.tr`
    &:nth-child(odd) {
      background: white;
    }

    &:nth-child(even) {
      background: ${props => (props.even ? props.even : '#ddffdd')};
    }
  `,

  Th: styled.th`
    border: 1px solid #ceced3;
    padding: 4px 8px;
    background: #eeeeee;
    font-weight: bold;
    white-space: nowrap;
  `,

  Td: styled.td`
    border: 1px solid #ceced3;
    padding: 4px 8px;
  `,
}
