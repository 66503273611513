import React from 'react'
import PropTypes from 'prop-types'
import connect from './props'

// libs
import { toJPYenText } from 'src/lib/format.js'
import Sticky from 'src/lib/class-sticky'
import requestGetSticky from 'src/lib/sticky-api/get'
import config from 'src/config'

/**
 * 検索結果として得られた案件付箋を表示するコンポーネント
 * @param {object} props
 * @return {Component} the component
 */
export class StickyRow extends React.PureComponent {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    onSelect: PropTypes.func.isRequired,
    index: PropTypes.number,
    // StateProps
    master: PropTypes.object.isRequired,
    cityCodes: PropTypes.object,
    accessToken: PropTypes.string.isRequired,
    env: PropTypes.object.isRequired,
    staffs: PropTypes.arrayOf(
      PropTypes.shape({
        staffId: PropTypes.number.isRequired,
        staffName: PropTypes.string.isRequired,
      }),
    ).isRequired,
    // dispatchProps
    getCityCodes: PropTypes.func.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    index: -1,
    masterWorkPoints: [],
    masterWorkTypes: [],
    masterPrefCodes: [],
    masterCompanies: [],
    cityCodes: {},
  }

  /**
   * constructor
   * @param  {object} props props
   * @return {void}
   */
  constructor(props) {
    super(props)
    this.state = {
      sticky: props.sticky,
    }
  }

  componentDidMount() {
    // 一覧表示後、付箋の詳細情報を取得して置き換える
    const { accessToken, env, sticky: orgsticky } = this.props
    new Promise(function(resolve) {
      const wait = 100
      setTimeout(function() {
        resolve(orgsticky)
      }, wait)
    })
      .then(orgsticky =>
        requestGetSticky(orgsticky._props.id, accessToken, env),
      )
      .then(sticky => {
        this.setState({ sticky })
      })
      .catch(err => console.error(err))
    const { prefCode } = this.props.sticky.findClient() || {}
    if (
      prefCode &&
      (!this.props.cityCodes[prefCode] ||
        this.props.cityCodes[prefCode].length === 0)
    ) {
      // prefCodeをセットしたタイミングでcityCode一覧を取得する
      const accessToken = this.props.accessToken
      this.props.getCityCodes(prefCode, accessToken)
    }
  }

  /**
   * クリックして過去履歴を洗濯した時のハンドラ
   * @return {void}
   */
  onClickToSelect = e => {
    e.stopPropagation()
    this.props.onSelect(this.props.sticky)
  }

  /**
   * エンター押下で過去履歴を洗濯した時のハンドラ
   * @return {void}
   */
  onEnterToSelect = e =>
    e.keyCode === 13 && this.props.onSelect(this.props.sticky)

  /**
   * マスタからIDを検索し、名前を取得する
   */
  searchMaster = (items, id) =>
    (items.find(item => item.id === id) || {}).name || ''

  /**
   * [prepare description]
   * @param  {object} props レンダリングするためのオリジナルのProps
   * @return {object}       レンダリングに必要なProps
   */
  prepareRender = () => {
    const {
      // ownProps
      index,
      // stateProps
      master,
      cityCodes,
      staffs,
    } = this.props

    const { sticky } = this.state

    const {
      id,
      companyId,
      receivedAt,
      wishDateTimes,
      handOver,
      staffId,
      workPoint,
      workType,
      negotiationRecords,
      workContent,
      cash,
      account,
      suspendReason,
      afterDateTime,
      finishStateId,
      cancelReasonId,
      completedAt,
      estimateNumber,
      estimatePayment,
      reRequest,
      buildingType,
      customerTypeId,
      contacts,
      regionId,
      mediumId,
      thankYouCall,
      updateUserId,
      orderId,
    } = sticky.json()

    // 受注会社によって背景色を切り替える
    const tdClassName = companyId === config.company.HL ? 'houselabo' : 'esmile'
    // 受注会社名
    const companyName = this.searchMaster(master.companies, companyId)
    // 入力日
    const inputDate = receivedAt
    // 対応希望日
    const wishDate =
      wishDateTimes && wishDateTimes.length > 0
        ? wishDateTimes[0].date || ''
        : ''
    // 重要申送
    const handOverContent = handOver ? handOver.freeDescribe || '' : ''
    // 担当者
    const staffName =
      staffId === null
        ? '(未アサイン)'
        : (staffs.find(staff => staff.staffId === staffId) || {}).staffName ||
          '(不明)'
    // 作業箇所
    const workPointIds = (workPoint || {}).ids || []
    const workPointNames = workPointIds
      .map(id => {
        return (master.workPoints.find(wk => wk.id === id) || {}).name || ''
      })
      .join(',')
    // 現況
    const workTypeIds = (workType || {}).ids || []
    const workTypeNames = workTypeIds
      .map(id => {
        return (master.workTypes.find(wk => wk.id === id) || {}).name || ''
      })
      .join(',')
    // メモ: 交渉利益の最初
    const memo =
      (negotiationRecords || []).length > 0
        ? negotiationRecords[0].freeDescribe || ''
        : ''
    // 現金(内金)
    const cashValue = cash && cash.value ? toJPYenText(cash.value) : ''
    // 売掛金
    const accountValue =
      account && account.value ? toJPYenText(account.value) : ''
    // 売掛金回収方法：なしとする
    // const accountRetrieveId = (account || {}).howToRetrieveId || ''
    // const accountRetrieve =
    //   this.searchMaster(master.receivableCollection, accountRetrieveId) || ''
    // 回収予定日：なしとする。
    // 中断理由
    const suspendReasonIds = suspendReason ? suspendReason.ids : []
    const suspendReasonId =
      suspendReasonIds.length > 0 ? suspendReasonIds[0] : -1
    const suspendReasonName =
      this.searchMaster(master.suspendReasons, suspendReasonId) || ''
    // 中断日
    const suspendDate = suspendReason ? suspendReason.date || '' : ''
    // 中断期限
    const suspendLimit = suspendReason ? suspendReason.limit || '' : ''
    // アフター
    const after = (afterDateTime || {}).date || ''
    // 終了状態
    const finishStatusName =
      this.searchMaster(master.finishStates, finishStateId) || ''
    // キャンセル理由
    const cancelReasonName =
      this.searchMaster(master.cancelReasons, cancelReasonId) || ''
    // キャンセル理由
    const cancelReasonName2 =
      this.searchMaster(master.cancelReasons2, cancelReasonId) || ''
    // キャンセル理由
    const cancelReasonName3 =
      this.searchMaster(master.cancelReasons3, cancelReasonId) || ''
    // 終了日時
    // 見積
    const estimatePaymentValue = estimatePayment
      ? toJPYenText(estimatePayment)
      : ''
    // クライアント情報（電話番号など）
    const client = sticky.findClient() || {}
    // 都道府県
    const prefNames = (master.prefCodes || []).filter(
      x => x.code === client.prefCode,
    )
    const prefName = prefNames.length > 0 ? prefNames[0].name : ''
    // 住所(市区町村)
    const citynames = ((cityCodes && cityCodes[client.prefCode]) || []).filter(
      x => x.code === client.cityCode,
    )
    const cityName = citynames.length > 0 ? citynames[0].cityName : ''
    // 建物情報
    const buildingTypeId = ((buildingType || {}).ids || [])[0] || -1
    const buildingTypeName =
      this.searchMaster(master.buildingTypes, buildingTypeId) || ''
    // 顧客タイプ
    const customerTypeName =
      this.searchMaster(master.customerTypes, customerTypeId || -1) || ''
    // 管理会社・オーナー関与
    const hasOwner = contacts && contacts[1] && contacts[1].length > 0
    // ありの場合の連絡先
    const ownerPhoneNo = hasOwner && contacts[1][0].phoneNumber
    // 判断：なし
    // 判断者：なし
    // エリア
    const regionName = this.searchMaster(master.regions, regionId) || ''
    // 受電
    const mediaName = this.searchMaster(master.media, mediumId) || ''
    // サンキューコール
    const thankYouCallName = (thankYouCall || {}).done ? '済' : '未'
    // 内容：なし

    return {
      id,
      tdClassName,
      companyName,
      inputDate,
      wishDate,
      handOverContent,
      staffName,
      workPointNames,
      workTypeNames,
      memo,
      workContentText: workContent || '',
      cashValue,
      accountValue,
      suspendReasonName,
      suspendDate,
      suspendLimit,
      after,
      finishStatusName,
      cancelReasonName,
      cancelReasonName2,
      cancelReasonName3,
      completedDateTime: completedAt || '',
      estimateNumberText: estimateNumber || '',
      estimatePaymentValue,
      reRequestText: reRequest || '',
      client,
      prefName,
      cityName,
      buildingTypeName,
      customerTypeName,
      hasOwner,
      ownerPhoneNo,
      regionName,
      mediaName,
      thankYouCallName,
      updateUserId: updateUserId || '',
      orderId,
      index,
    }
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const {
      tdClassName,
      companyName,
      inputDate,
      wishDate,
      handOverContent,
      staffName,
      workPointNames,
      workTypeNames,
      memo,
      workContentText,
      cashValue,
      accountValue,
      suspendReasonName,
      suspendDate,
      suspendLimit,
      after,
      finishStatusName,
      cancelReasonName,
      cancelReasonName2,
      cancelReasonName3,
      completedDateTime,
      estimateNumberText,
      estimatePaymentValue,
      reRequestText,
      client,
      prefName,
      cityName,
      buildingTypeName,
      customerTypeName,
      hasOwner,
      ownerPhoneNo,
      regionName,
      mediaName,
      thankYouCallName,
      updateUserId,
      orderId,
      index,
    } = this.prepareRender()

    return (
      <tr key={ index } onClick={ this.onClickToSelect }>
        <td className={ tdClassName }>{companyName}</td>
        <td className={ tdClassName }>{inputDate}</td>
        <td className={ tdClassName }>{wishDate}</td>
        <td className={ tdClassName }>{handOverContent}</td>
        <td className={ tdClassName }>{staffName}</td>
        <td className={ tdClassName }>{workPointNames}</td>
        <td className={ tdClassName }>{workTypeNames}</td>
        <td className={ tdClassName }>{memo}</td>
        <td className={ tdClassName }>{workContentText}</td>
        <td className={ tdClassName }>{cashValue}</td>
        <td className={ tdClassName }>{accountValue}</td>
        <td className={ tdClassName }>{''}</td>
        <td className={ tdClassName }>{''}</td>
        <td className={ tdClassName }>{suspendReasonName}</td>
        <td className={ tdClassName }>{suspendDate}</td>
        <td className={ tdClassName }>{suspendLimit}</td>
        <td className={ tdClassName }>{after}</td>
        <td className={ tdClassName }>{finishStatusName}</td>
        <td className={ tdClassName }>{cancelReasonName}</td>
        <td className={ tdClassName }>{cancelReasonName2}</td>
        <td className={ tdClassName }>{cancelReasonName3}</td>
        <td className={ tdClassName }>{completedDateTime}</td>
        <td className={ tdClassName }>{estimateNumberText}</td>
        <td className={ tdClassName }>{estimatePaymentValue}</td>
        <td className={ tdClassName }>{reRequestText}</td>
        <td className={ tdClassName }>{''}</td>
        <td className={ tdClassName }>{client.phoneNumber}</td>
        <td className={ tdClassName }>{client.phoneNumber1 || ''}</td>
        <td className={ tdClassName }>
          {client.name ? client.name + ' 様' : '( 名前未入力) '}
        </td>
        <td className={ tdClassName }>{client.zip || ''}</td>
        <td className={ tdClassName }>{prefName}</td>
        <td className={ tdClassName }>{cityName}</td>
        <td className={ tdClassName }>{client.address || ''}</td>
        <td className={ tdClassName }>{buildingTypeName}</td>
        <td className={ tdClassName }>{customerTypeName}</td>
        <td className={ tdClassName }>{hasOwner ? 'あり' : 'なし'}</td>
        <td className={ tdClassName }>{ownerPhoneNo}</td>
        <td className={ tdClassName }>{''}</td>
        <td className={ tdClassName }>{''}</td>
        <td className={ tdClassName }>{regionName}</td>
        <td className={ tdClassName }>{mediaName}</td>
        <td className={ tdClassName }>{thankYouCallName}</td>
        <td className={ tdClassName }>{''}</td>
        <td className={ tdClassName }>{updateUserId}</td>
        <td className={ tdClassName }>{orderId}</td>
      </tr>
    )
  }
}

/**
 * connected as default
 * @type {Function}
 */
export default connect(StickyRow)
