import switz from 'switz'
import update from 'immutability-helper'

/**
 * initialState
 * @type {object}
 */
export const initialClickerState = {
  splitter: {
    location: {},
  },
  splits: [],
  assigner: {
    location: {},
  },
}

/**
 * Action Types
 * @type {string}
 */
export const REGISTER_SPLITTER = 'CLICKER.REGISTER_SPLITTER'
export const REGISTER_ASSIGNER = 'CLICKER.REGISTER_ASSIGNER'
export const UNREGISTER_SPLITTER = 'CLICKER.UNREGISTER_SPLITTER'
export const UNREGISTER_ASSIGNER = 'CLICKER.UNREGISTER_SSIGNER'
export const DO_SPLIT = 'CLICKER.DO_SPLIT'

/**
 * action bulk exports
 * @type {object}
 */
export const ACTION_TYPES = {
  REGISTER_SPLITTER,
  REGISTER_ASSIGNER,
  UNREGISTER_SPLITTER,
  UNREGISTER_ASSIGNER,
  DO_SPLIT,
}

/**
 * clicker reducer
 * @param  {object} [state=initialClickerState] previous state
 * @param  {object} action                 dispatched action
 * @return {object}                         next state
 */
export default (state = initialClickerState, action) => {
  const { type, payload } = action

  return switz(type, s =>
    s
      .case(REGISTER_SPLITTER, () => {
        return update(state, {
          splitter: {
            location: {
              $set: payload.location,
            },
          },
        })
      })
      .case(REGISTER_ASSIGNER, () => {
        return update(state, {
          assigner: {
            location: {
              $set: payload.location,
            },
          },
        })
      })
      .case(DO_SPLIT, () => {
        return update(state, {
          splits: {
            $push: [payload.location],
          },
        })
      })
      .case(UNREGISTER_SPLITTER, () => {
        return update(state, {
          splitter: {
            location: {
              $set: {},
            },
          },
          splits: { $set: [] },
        })
      })
      .case(UNREGISTER_ASSIGNER, () => {
        return update(state, {
          assigner: {
            location: {
              $set: {},
            },
          },
        })
      })
      .default(() => state),
  )
}
