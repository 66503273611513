import styled from 'styled-components'
import { baseColor, red, black } from 'src/colors'

const fileSpecColor = props =>
  props.success ? baseColor : props.failure ? red : black

export const FileSpec = styled.span`
  color: ${fileSpecColor};
  text-decoration: ${props => (props.cancel ? 'line-through' : 'none')};
  width: ${props => (props.width ? props.width : 200)}px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: nowrap;

  &:not(:last-child) {
    margin-right: 0.5em;
  }
`
