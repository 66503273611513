/**
 * iterate contacts
 * @param  {array} contacts [[{},{}],[{}],[null,{}],null]
 * @param  {function} callback regionIndex, contacts index, contact
 * @return {array}            mutatedContact
 */
export const contacts = (contacts, callback) => {
  if (!Array.isArray(contacts)) {
    return
  }

  for (let i = 0; i < contacts.length; i++) {
    if (!Array.isArray(contacts[i])) {
      continue
    }

    for (let j = 0; j < contacts[i].length; j++) {
      const contact = contacts[i][j]
      contact && callback(i, j, contact)
    }
  }
}
