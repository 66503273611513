import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { baseColor } from 'src/colors'
import { darken } from 'polished'

export const Container = styled.div`
  min-width: 800px;
  max-width: 1200px;
  margin: 50px auto;
`

export const NavigationItems = styled.ul`
  display: grid;
  grid-gap: 12px;
  grid-auto-rows: 172px;
  grid-template-columns: repeat(auto-fill, minmax(172px, 1fr));
  margin: 24px;
`

export const NavLabel = styled.span`
  color: ${baseColor};
  font-size: 1.25rem;
  line-height: 58px;
  text-align: center;
`

export const Mark = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  border-radius: 8px 8px 0 0;
  background: ${baseColor};
  color: #fff;
  font-size: 18px;
`

export const NavLink = styled(Link)`
  display: block;
  width: 180px;
  height: 160px;
  transition: 0.2s;
  border-radius: 8px;
  background: #fff;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 3px;
  text-align: center;

  &:hover {
    transform: translate3d(0, 1px, 0);
    box-shadow: none;

    .mark {
      background: ${darken(0.1, baseColor)};
    }

    span {
      color: ${darken(0.1, baseColor)};
    }
  }
`
