import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from './props'
import update from 'immutability-helper'

// component
import Tab from 'src/components/commons/tab'
import NameCard from './partials/name-card'
import CloseButton from './partials/close-button'
import ApplyButton from './partials/apply-button'
import { Active, Deactive, Plain, Item } from './debugger/styled'
import ValidationErrorMessage from 'src/components/commons/validation-error-message'

const SortListModalContent = ({
  sortModalError,
  sordModalDisplayRegionId,
  regions,
  regionsMaster,
  closeMe,
  isDebugMode,
  apply,
  userPreset,
}) => {
  const initialUserPreset = userPreset.map(userPreset => {
    return {
      ...userPreset,
      ssOrder: [
        ...(userPreset.ssOrder || []),
        ...(regions.filter(region => region.id === userPreset.id)[0] !== void 0
          ? regions
            .filter(region => region.id === userPreset.id)[0]
            .lists.map(list => list.staffId)
            .filter(staffId => !(userPreset.ssOrder || []).includes(staffId))
          : []),
      ]
        // region.list.staffId に含まれるものだけ有効にする
        .filter(
          staffId =>
            regions.filter(region => region.id === userPreset.id)[0] &&
            regions
              .filter(region => region.id === userPreset.id)[0]
              .lists?.map(list => list.staffId)
              .includes(staffId),
        ),
      listUserDisplayRegionSS: [
        ...(userPreset.listUserDisplayRegionSS || []),
        ...(regions.filter(region => region.id === userPreset.id)[0] !== void 0
          ? regions
            .filter(region => region.id === userPreset.id)[0]
            .lists?.map(list => ({ ssId: list.staffId, display: true }))
          : []),
      ].filter(
        (element, index, self) =>
          self.findIndex(e => e.ssId === element.ssId) === index,
      ), // 重複するssIdを除いてます
    }
  })

  // initialUserPreset
  //  display: boolean
  //  displayOrder: number エリアの表示順
  //  id: number エリアのID
  //  listUserDisplayRegionSS: {} 表示・非表示の操作をしたSS
  //  ssOrder: []
  //  userId: string

  const [initUserPreset, setInitUserPreset] = useState(initialUserPreset)

  const createStaffDisplayToggleHandler = (
    regionId,
    staffId,
    displayed,
  ) => () => {
    const regionIndex = initUserPreset.map(x => x.id).indexOf(regionId)

    const listIndex = (
      initUserPreset[regionIndex].listUserDisplayRegionSS || []
    )
      .map(x => x.ssId)
      .indexOf(staffId)

    const nextListIndex =
      listIndex === -1
        ? (initUserPreset[regionIndex].listUserDisplayRegionSS || []).length
        : listIndex

    setInitUserPreset(
      update(initUserPreset, {
        [regionIndex]: {
          $apply: x =>
            update(x || {}, {
              listUserDisplayRegionSS: {
                $apply: x =>
                  update(x || [], {
                    [nextListIndex]: {
                      $apply: x =>
                        update(x || {}, {
                          display: { $set: !displayed },
                          ssId: { $set: staffId },
                          regionId: { $set: regionId },
                        }),
                    },
                  }),
              },
            }),
        },
      }),
    )
  }

  const createOrderStaffHandler = regionId => (fromIndex, toIndex) => {
    const regionIndex = initUserPreset.map(x => x.id).indexOf(regionId)
    const ssOrderPrev =
      initUserPreset.filter(preset => preset.id === regionId)[0].ssOrder || []

    const fromId = ssOrderPrev[fromIndex]

    // SS表示・非表示・並び替えモーダルで選択したエリアのSSの配列を取得します。二次元配列になるので、最後にflat()してます。
    const regionLists = regions
      .filter(region => region.id === regionId)
      .map(region => region.lists)
      .flat()
    // staffIdのみの配列を取得
    const originalSSOrder = regionLists.map(regionList => regionList.staffId)

    // 変更するinitUserPresetの配列番号を指定している
    setInitUserPreset(
      update(initUserPreset, {
        [regionIndex]: {
          $apply: x =>
            update(x || {}, {
              ssOrder: {
                $apply: x =>
                  update(x || originalSSOrder, {
                    $splice: [
                      [fromIndex, 1],
                      [toIndex, 0, fromId],
                    ],
                  }),
              },
            }),
        },
      }),
    )
  }

  const save = () => apply(initUserPreset)

  const displayRegionIndex = regions
    .map(x => x.id)
    .indexOf(sordModalDisplayRegionId)

  return (
    <div>
      <h2>{'サービススタッフ表示・非表示切り替え、並び替え'}</h2>
      <Tab initialIndex={ displayRegionIndex }>
        {regions.map((region, regionIndex) => {
          const { ssOrder, listUserDisplayRegionSS } =
            initUserPreset.find(x => x.id === region.id) || {}

          // 順番が設定されていないss
          const noOrders = (region.lists || [])
            .map(x => x.staffId)
            .filter(staffId => !(ssOrder || []).includes(staffId))

          // 実際に画面上に表示するSSのリスト
          const clonedLists = ((ssOrder || []).length > 0
            ? [...ssOrder, ...noOrders].map(ssId =>
              region.lists.find(list => list.staffId === ssId),
            )
            : [...(region.lists || [])]
          )
            // undefined が混じる。userPresetとマスタの不整合っぽい
            .filter(x => !!x)

          return (
            <div
              key={ region.id }
              data-tab-header={ {
                head:
                  (regionsMaster.find(x => x.id === region.id) || {}).name ||
                  '地域名称不明',
                length: 0,
              } }
            >
              {isDebugMode && [
                <Item key={ 0 }>
                  <strong>{'region.staff'}</strong>
                  {`(${region.lists.length})`}
                  <br />
                  {(region.lists || []).map(list => (
                    <Plain key={ list.staffId }>{list.staffId}</Plain>
                  ))}
                </Item>,

                <Item key={ 1 }>
                  <strong>{'listUserDisplayRegionSS props '}</strong>
                  {`(${
                    (
                      (userPreset.filter(x => x.id === region.id)[0] || {})
                        .listUserDisplayRegionSS || []
                    ).length
                  })`}
                  <br />
                  {(
                    (userPreset.filter(x => x.id === region.id)[0] || {})
                      .listUserDisplayRegionSS || []
                  ).map(x =>
                    x.display ? (
                      <Active key={ x.ssId }>{x.ssId}</Active>
                    ) : (
                      <Deactive key={ x.ssId }>{x.ssId}</Deactive>
                    ),
                  )}
                </Item>,

                <Item key={ 2 }>
                  <strong>{'listUserDisplayRegionSS'}</strong>
                  {`(${(listUserDisplayRegionSS || []).length})`}
                  <br />
                  {(listUserDisplayRegionSS || []).map(x =>
                    x.display ? (
                      <Active key={ x.ssId }>{x.ssId}</Active>
                    ) : (
                      <Deactive key={ x.ssId }>{x.ssId}</Deactive>
                    ),
                  )}
                </Item>,

                <Item key={ 3 }>
                  <strong>{'ssOrder props'}</strong>
                  {`(${
                    ((userPreset[regionIndex] || {}).ssOrder || []).length
                  })`}
                  <br />
                  {(userPreset.filter(x => x.id === region.id)[0] || {}).ssOrder
                    ? (
                      userPreset.filter(x => x.id === region.id)[0] || {}
                    ).ssOrder.map(ssId => <Plain key={ ssId }>{ssId}</Plain>)
                    : 'なし'}
                </Item>,

                <Item key={ 4 }>
                  <strong>{'ssOrder'}</strong>
                  {`(${(ssOrder || []).length})`}
                  <br />
                  {ssOrder
                    ? ssOrder.map(ssId => <Plain key={ ssId }>{ssId}</Plain>)
                    : 'なし'}
                </Item>,

                <Item key={ 5 }>
                  <strong>{'regionId'}</strong>
                  {`: ${region.id}`}
                </Item>,
              ]}
              {clonedLists.map((list, index) => {
                const { staffId, staffName: name } = list || {}
                const displayed = !!(
                  (
                    (initUserPreset.find(x => x.id === region.id) || {})
                      .listUserDisplayRegionSS || []
                  ).find(x => x.ssId === staffId) || { display: true }
                ).display // undefined の時は、ユーザーの並び替えなどが実行されていない。デフォルトは表示
                return (
                  <NameCard
                    key={ staffId }
                    name={ name + (isDebugMode ? ` (${staffId})` : '') }
                    staffId={ staffId }
                    index={ index }
                    displayed={ displayed }
                    onClick={ createStaffDisplayToggleHandler(
                      region.id,
                      staffId,
                      displayed,
                    ) }
                    onDrop={ createOrderStaffHandler(region.id) }
                  />
                )
              })}
            </div>
          )
        })}
      </Tab>
      <ApplyButton onClick={ save } />
      {sortModalError && (
        <ValidationErrorMessage message={ '通信エラーが発生しました。' } />
      )}
      <CloseButton onClick={ closeMe } />
    </div>
  )
}

/**
 * propTypes
 * @type {object}
 */
SortListModalContent.propTypes = {
  // ownProps
  sortModalError: PropTypes.bool.isRequired,
  sordModalDisplayRegionId: PropTypes.number.isRequired,
  closeMe: PropTypes.func.isRequired,
  apply: PropTypes.func.isRequired,
  // stateProps
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      lists: PropTypes.arrayOf(
        PropTypes.shape({
          staffId: PropTypes.number.isRequired,
          displayIndex: PropTypes.number.isRequired,
          staffName: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  userPreset: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      displayOrder: PropTypes.number.isRequired,
      display: PropTypes.bool.isRequired,
      listUserDisplayRegionSS: PropTypes.oneOfType([
        PropTypes.arrayOf(
          PropTypes.shape({
            ssId: PropTypes.number.isRequired,
            display: PropTypes.bool.isRequired,
            displayOrder: PropTypes.number.isRequired,
          }),
        ).isRequired,
        PropTypes.oneOf([null]),
      ]),
      ssOrder: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.oneOf([null]),
      ]),
    }),
  ).isRequired,
  regionsMaster: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isDebugMode: PropTypes.bool.isRequired,
}

export default connect(SortListModalContent)
