import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Authorize from 'src/components/commons/authorize'
import CommonHeader from 'src/components/layouts/common-header'
import CommonFooter from 'src/components/layouts/common-footer'
import styled from 'styled-components'
import config from 'src/config'
import { baseColor } from 'src/colors'
import {
  CardContainer,
  NavCard,
  NavUl,
  NavLi,
  // NavLink,
  LogoWrap,
} from './styled'
import engineeringLogo from 'src/styles/assets/home-engineering-icon.png'

export const Li = styled.li`
  font-size: 1.2em;
  list-style: disc;
  margin: 0.3em;
`

// 工務課
const EngineeringView = ({ env }) => {
  const masterUrl = `${env.COMMODITY_ENGINEERING_URL}`

  const linkStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 30px',
    color: baseColor,
    fontSize: '1.2em',
    backgroundColor: '#fff',
    cursor: 'pointer,',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
  }

  const ToLink = styled.span`
    margin-right: 4px;

    &:hover {
      font-weight: bold;
    }
  `

  const openMaster = () => {
    window.open(masterUrl, '_blank')
  }

  return (
    <div className={ 'callview_wrapper' }>
      <CommonHeader route={ 'engineering' } />
      <Authorize
        roles={ [config.roles.commodity, config.roles.engineering] }
        roleOr
      >
        <div className={ 'main-navigation' }>
          <CardContainer>
            <NavCard>
              <LogoWrap>
                <img src={ engineeringLogo } width="64" height="64" />
              </LogoWrap>
              <NavUl height={ 160 }>
                <NavLi>
                  {' '}
                  <a
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={ openMaster }
                    style={ linkStyle }
                  >
                    <ToLink>
                      {'SS発注依頼'}
                      <br />
                      {'（個人発注）'}
                    </ToLink>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#1b95bf"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <rect x="7" y="3" width="14" height="14" rx="2" />
                      <path d="M17 17v2a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2" />
                    </svg>
                  </a>
                </NavLi>
              </NavUl>
            </NavCard>
          </CardContainer>
        </div>
      </Authorize>
      <CommonFooter />
    </div>
  )
}

EngineeringView.propTypes = {
  env: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
  return {
    accessToken: state.login.authentication.accessToken,
    env: state.env,
  }
}

export default connect(mapStateToProps)(EngineeringView)
