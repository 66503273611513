import utils from 'src/lib/api-utils'

/**
 * PUT stickyのアクションクリエーター
 * sticky.staffId === -1 でDockに戻せる
 * @param  {Sticky} sticky      [description]
 * @param  {string} accessToken [description]
 * @param  {object} env         env
 * @return {Promise}
 */
export const requestZip = (zipCode, accessToken, env) => {
  const endpoint = `${utils.createEndpoint(env, 'zip')}/${zipCode}`

  const request = fetch(endpoint, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  })

  return request
}

export default requestZip
