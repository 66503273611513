import update from 'immutability-helper'
import findStickyById from 'src/lib/find-sticky-by-id'

/**
 * タイプ定義
 * @type {string}
 */
const type = 'WARA.UPDATE_STICKY_BY_ID'

/**
 * exportation
 */
export default {
  /**
   * action type
   * @type {string}
   */
  type,

  /**
   * action creator
   * @param  {{ regionIndex: number, listIndex: number, stikcyIndex: number }}  location 更新するstickyがアサインされたlistのindexです
   * @return {object} [description]
   */
  creator: sticky => {
    return {
      type,
      payload: { sticky },
    }
  },

  /**
   * stageを経ずに、案件情報を直接変更するハンドラ
   * @param  {object}  state redux state
   * @param  {object} action action
   * @return {object}        new state
   */
  handler: (state, action) => {
    const { sticky } = action.payload || {}

    // 更新対象の付箋をIDで検索する
    const { sticky: oldSticky, location } = findStickyById(
      state.data,
      sticky.id,
    )

    if (location === false) {
      return state
    }
    const { regionIndex, listIndex, stickyIndex } = location

    const newSticky = oldSticky.merge(sticky)

    if (regionIndex === -1 || listIndex === -1) {
      return update(state, {
        data: {
          dock: {
            stickies: {
              [stickyIndex]: { $set: newSticky },
            },
          },
        },
      })
    } else {
      return update(state, {
        data: {
          regions: {
            [regionIndex]: {
              lists: {
                [listIndex]: {
                  stickies: {
                    [stickyIndex]: { $set: newSticky },
                  },
                },
              },
            },
          },
        },
      })
    }
  },
}
