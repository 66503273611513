// action types
import {
  ACTION_TYPES as LOGIN_ACTION_TYPES,
  DO_LOGOUT,
  SET_USERNAME,
  SET_PASSWORD,
} from 'src/reducers/login'
import { MASTER_GET_FAILURE } from 'src/reducers/master'
import { RESET as RESET_NETWORK_STATUS } from 'src/reducers/network-status'

// action creators
import { createAsyncMasterUpdateAction } from 'src/reducers/master'
import { createAsyncGetUserPresetAction } from 'src/reducers/user-preset'
import { push } from 'react-router-redux'

// config
import config from 'src/config'
import { USERID_LS_KEY, PASSWORD_LS_KEY } from 'src/reducers/login'
import { encode } from 'src/lib/obfuscate'

/**
 * ログインのステータスをリセットする
 * @param  {{dispatch:function}} store redux store
 * @return {void}
 */
export const resetLoginStatus = ({ dispatch }) =>
  dispatch({ type: RESET_NETWORK_STATUS, payload: { target: 'login' } })

/**
 * ログアウト後にネットワークステータスをリセットし、ルートに移動する
 * @param  {{dispatch:function}} store redux store
 * @return {void}
 */
export const postLogoutHook = ({ dispatch }) => {
  resetLoginStatus({ dispatch })
  dispatch(push('/'))
}

const loginPosthook = (store, { payload: { userid, password } }) => {
  resetLoginStatus(store)
  userid && localStorage.setItem(USERID_LS_KEY, encode(userid))
  password && localStorage.setItem(PASSWORD_LS_KEY, encode(password))
}

export default {
  [SET_USERNAME]: loginPosthook,
  [SET_PASSWORD]: loginPosthook,
  [LOGIN_ACTION_TYPES.LOGIN_SUCCESS]: loginPosthook,
  [DO_LOGOUT]: postLogoutHook,
  // マスタ読み込みに失敗した時の処理
  [MASTER_GET_FAILURE]: ({ dispatch }, { payload }) => {
    payload.message === 'Unauthorized' && dispatch({ type: DO_LOGOUT })
  },

  /**
   * ログイン成功 -> マスタ取得・ユーザープリセット取得のAPIチェーン
   * @param  {Store}  store  redux store
   * @param  {object} action redux action
   * @return {void}
   */
  [LOGIN_ACTION_TYPES.LOGIN_SUCCESS]: (store, action) => {
    // chain login to master update
    // NOTE: 似たようなコードがlocal-storage-io.jsにもいるので、管理上注意する。
    // 随意ログインが、自動ログインかの違い。ここは随意ログイン
    // ログイン -> マスタ取得
    const {
      meta: { userid, cached } = {},
      payload: { token: accessToken },
    } = action
    const master = config.constants.master

    const { env } = store.getState()

    // master取得
    cached ||
      master.keys.forEach(key =>
        store.dispatch(createAsyncMasterUpdateAction(key, accessToken, env)),
      )
    // userPreset取得
    store.dispatch(createAsyncGetUserPresetAction(userid, accessToken, env))
  },
}
