import Sticky from 'src/lib/class-sticky'

/**
 *iインデックスを指定して1つの連絡先を探す
 * @param  {array|Sticky} obj [description]
 * @param  {index} tabIndex        [description]
 * @param  {index} contactsIndex   [description]
 * @return {[object]}               [description]
 */
const findContact = (obj, tabIndex, contactsIndex) => {
  if (!obj) {
    return undefined
  } else {
    let contacts
    if (obj instanceof Sticky) {
      contacts = obj.json().contacts
    } else if (Array.isArray(obj)) {
      contacts = obj
    } else if (typeof obj === 'object') {
      contacts = obj.contacts
    }
    return ((contacts || [])[tabIndex] || [])[contactsIndex]
  }
}
export default findContact
