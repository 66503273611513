import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Table, Tr, Th, Td, ButtonLink } from './styled'
import Checkbox from 'src/styled/form/checkbox'
import Button from 'src/styled/button/ghost'

export const SettlementTable = props => {
  // 一括確定・解除のボタンをテーブルのヘッダに表示する。
  const statHead = (
    <div style={ { display: 'flex' } }>
      <span style={ { marginRight: '20px' } }>{'状態'}</span>
      <div style={ { marginTop: '-5px' } }>
        <Button onClick={ props.setAllSettlement } disabled={ props.disabled }>
          {'一括確定'}
        </Button>
        <Button onClick={ props.resetAllSettlement } disabled={ props.disabled }>
          {'一括解除'}
        </Button>
      </div>
    </div>
  )
  return (
    <Table disabled={ props.disabled }>
      <thead>
        <Tr>
          <Th>{'エリア'}</Th>
          <Th>{'担当者'}</Th>
          <Th />
          <Th />
          <Th>{statHead}</Th>
        </Tr>
      </thead>
      <tbody>
        {props.staffIds.map((dispStaff, index) => {
          const {
            staffId,
            staffVerboseName,
            regionId,
            hasSettlement,
          } = dispStaff
          let regionName = '不明'
          if (regionId) {
            const region =
              props.regions.find(region => region.id === regionId) || {}
            regionName = region.name || `(エリア名不明 id:${regionId})`
          }

          const resource = `${staffId}/${props.from},${props.to}`
          const isvalid = !!hasSettlement

          return (
            <Tr key={ `${staffId}${index}` } underline>
              <Td>{regionName}</Td>
              <Td>{staffVerboseName}</Td>
              <Td>
                <ButtonLink
                  to={ `/accounting/settlement/${resource}` }
                  disabled={ !isvalid }
                >
                  {'詳細'}
                </ButtonLink>
              </Td>
              <Td>
                <ButtonLink
                  to={ `/accounting/settlement/manage/${resource}` }
                  disabled={ !isvalid }
                >
                  {'伝票管理'}
                </ButtonLink>
              </Td>
              <Td>
                <Checkbox
                  onChange={ e => props.onConfirm(e, staffId) }
                  labelText={ '確定する' }
                  checked={ !!dispStaff.isSettled }
                  disabled={ !isvalid }
                />
              </Td>
            </Tr>
          )
        })}
      </tbody>
    </Table>
  )
}

SettlementTable.propTypes = {
  // ownProps
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  staffIds: PropTypes.arrayOf(
    PropTypes.shape({
      staffId: PropTypes.number.isRequired,
      isSettled: PropTypes.bool,
    }),
  ).isRequired,
  disabled: PropTypes.bool.isRequired,
  setAllSettlement: PropTypes.func.isRequired,
  resetAllSettlement: PropTypes.func.isRequired,
  // stateprops
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onConfirm: PropTypes.func.isRequired,
}

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
const mapStateToProps = state => {
  return {
    staffs: state.master.data.staffs,
    regions: state.master.data.regions,
  }
}

export default connect(mapStateToProps)(SettlementTable)
