import { connect as _connect } from 'react-redux'
import { actionCreators as $ } from 'src/reducers/wara'
import { actionCreators as createLightboxActions } from 'src/reducers/lightbox'
import switz from 'switz'
import config from 'src/config'

/**
 * Components
 */
import StickyModalContent from 'src/components/commons/sticky-modal-content'

/**
 * map State to props
 * @param  {object} state event object
 * @return {object}          [description]
 */
const mapStateToProps = state => {
  return {
    wara: state.wara.data,
    splitterLocation: state.clicker.splitter.location,
    isDebugMode: state.env.DEBUG,
    accessToken: state.login.authentication.accessToken,
    env: state.env,
    displayDate: state.displayDate.displayDate,
    nodeenv: state.env.NODE_ENV,
  }
}

/**
 * map Dispatch to props
 * @param  {function} dispatch [description]
 * @param  {object}    props    [description]
 * @return {object}          [description]
 */
const mapDispatchToProps = (dispatch, props) => {
  const { location } = props

  return {
    /**
     * モーダルを開き、付箋の編集を開始する
     * @param  {object} sticky 付箋オブジェクト
     * @return {void}
     */
    editSticky: sticky => {
      const type = switz(sticky.json().stickyTypeId, s =>
        s
          .case(config.stickyType.normal, () => 'normal')
          .case(config.stickyType.claimer, () => 'claimer')
          .case(config.stickyType.memo, () => 'memo')
          .case(config.stickyType.private, () => 'private')
          .case(config.stickyType.payment, () => 'payment')
          .default(() => 'normal'),
      )
      dispatch(
        $.openModal(StickyModalContent, { type, location, sticky }, sticky),
      )
    },

    /**
     * 付箋を変更する
     * @param  {argument} args argument
     * @return {void}
     */
    updateSticky: (sticky, location) =>
      dispatch($.updateSticky(sticky, location)),

    /**
     * 付箋を削除する
     * @param  {object} location 削除する付箋の位置
     * @return {void}
     */
    deleteSticky: location => dispatch($.deleteSticky(location, false)),
    deleteStickyById: stickyId => dispatch($.deleteStickyById(stickyId)),
    openLightbox: (stickyId, orderId, apitype) =>
      dispatch(createLightboxActions.open(stickyId, orderId, 'RIGHT', apitype)),
    addSticky: (location, sticky) => dispatch($.addSticky(location, sticky)),
  }
}

const connect = Component =>
  _connect(mapStateToProps, mapDispatchToProps)(Component)

/**
 * export
 */
export default connect
