import React from 'react'
import { baseColor } from 'src/colors'
import styled from 'styled-components'

const ReRequestMarkWrap = styled.span`
  color: white;
  font-weight: bold;
  background-color: ${baseColor};
  border-radius: 4px;
  font-size: 14px;
  padding: 5px 8px;
  margin-left: 10px;
`

export const ReRequestMark = () => (
  <ReRequestMarkWrap>{'再依頼'}</ReRequestMarkWrap>
)
export default ReRequestMark
