import utils from 'src/lib/api-utils'
import convertBeforePut from './lib/convert-before-put'
import convertAfterGet from './lib/convert-after-get'
import config from 'src/config'

export const put = (payments, accessToken, env) => {
  const endpoint = utils.createEndpoint(env, 'payments')
  const body = JSON.stringify({ payments: convertBeforePut(payments) })

  return fetch(endpoint, {
    method: 'PUT',
    headers: { Authorization: `Bearer ${accessToken}` },
    body,
  })
    .then(res => {
      if (res.ok) {
        return res.json()
      } else if (res.status === config.conflictStatus) {
        throw config.conflictError
      } else {
        throw res.text()
      }
    })
    .then(data => convertAfterGet(data.payments || []))
}

export default put
