import React from 'react'
import PropTypes from 'prop-types'

/**
 * 仕様付箋の表面の記述内容のコンポーネント
 * @param {object} props props
 juuuuh */
const Private = props => {
  const { workPoint, determinedDateTime, workContent } = props

  return (
    <div className={ 'sticky-private-content' }>
      <div className={ 'special-sticky-item' }>
        <div className={ 'sticky-element sticky-time' }>
          <span>{`${determinedDateTime.startAt ||
            '--:--'} 〜 ${determinedDateTime.finishAt || '--:--'}`}</span>
        </div>
        <div className={ 'sticky-element sticky-place' }>
          <div className={ 'special-sticky-description' }>
            <span>{workPoint.freeDescribe || '(未記入)'}</span>
          </div>
        </div>
      </div>
      <div className={ 'sticky-element sticky-message' }>
        <span>{workContent || '(未記入)'}</span>
      </div>
    </div>
  )
}

/**
 * propTypes
 * @type {object}
 */
Private.propTypes = {
  workPoint: PropTypes.object,
  determinedDateTime: PropTypes.object,
  workContent: PropTypes.string,
}

/**
 * defaultProps
 * @type {object}
 */
Private.defaultProps = {
  workPoint: {},
  determinedDateTime: {},
  workContent: '',
}

export default Private
