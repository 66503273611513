import React from 'react'
import PropTypes from 'prop-types'
import Autocomplete from 'react-autocomplete'
import { getIntervalTimeStrings } from 'src/lib/range'
import noop from 'src/lib/noop'
import { zerofillTime } from 'src/lib/format'
import { toHalf } from 'src/lib/to-half'
import { Box, TextField } from '@mui/material'
import theme from 'src/theme.js'
import { ThemeProvider } from '@mui/material/styles'

// constants
const isFireFox = navigator.userAgent.toLowerCase().indexOf('firefox') !== -1

/**
 * 時間のAutocompleteにvalidationを足したコンポーネント
 * @type {object}
 */
export class TimeAutocomplete extends React.Component {
  /**
   * 基本のバリデーション
   * @param {*} value
   */
  static validate = value => {
    const target = toHalf(value || '')

    if (target === '') {
      return { result: true }
    }

    if (target.length === 1) {
      const result = /^([3-9])$/.test(target)
      const normalized = zerofillTime(`${target}:00`)
      return { result, normalized }
    } else if (target.length === 2) {
      const result = /^(0[3-9])|(1[0-9])|(2[0-9])|(30)$/.test(target)
      const normalized = `${target}:00`
      return { result, normalized }
    } else if (target.length === 3) {
      if (target.includes(':')) {
        const [hour, min] = target.split(':')
        const result = /^[3-9]$/.test(hour) && /^[0-9]$/.test(min)
        const normalized = `0${hour}:0${min}`
        return { result, normalized }
      } else {
        return { result: false }
      }
    } else if (target.length === 4) {
      if (target.includes(':')) {
        const [hour, min] = target.split(':')
        const result =
          /^(0?[3-9]|1[0-9]|2[0-9]|30)$/.test(hour) &&
          /^(0?[0-9]|[1-5][0-9])$/.test(min)
        const normalized = zerofillTime(`${hour}:${min}`)
        return { result, normalized }
      } else {
        const match = target.match(/^(0[3-9]|1[0-9]|2[0-9]|30)([0-5][0-9])$/)
        const normalized = match && `${match[1]}:${match[2]}`
        return { result: !!match, normalized }
      }
    } else {
      if (target.includes(':')) {
        const [hour, min] = target.split(':')
        const result =
          /^(0[3-9]|1[0-9]|2[0-9]|30)$/.test(hour) &&
          /^(0[0-9]|[1-5][0-9])$/.test(min)
        const normalized = `${hour}:${min}`
        return { result, normalized }
      } else {
        return { result: false }
      }
    }
    //
    // // 空白またHH:mm文字列
    // if (value.split().includes(':')) {
    //   const hour =
    // } else {
    //
    // }
    // return (
    //   value === '' ||
    //   /^(0?[3-9]|1[0-9]|2[0-7]):([0-5][0-9]|[0-9])$/.test(toHalf(value || ''))
    // )
  }

  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    value: PropTypes.string,
    onValidateSuccess: PropTypes.func,
    inputId: PropTypes.string,
    disabled: PropTypes.bool,
    interval: PropTypes.number, // 時刻の間隔(分)
    addValidate: PropTypes.func, // 追加のバリデータ HH:MM形式の文字列をチェックし、true/falseを返す
    // 次にフォーカスする
    nextId: PropTypes.string.isRequired,
    // prevId: PropTypes.string.isRequired,
    // 自由に時刻を入力する
    freeInput: PropTypes.bool,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    value: '',
    onValidateSuccess: noop,
    inputId: '',
    disabled: false,
    freeInput: false,
    interval: 30,
    addValidate: () => true,
  }

  state = { editingText: false }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.value !== this.props.value ||
      nextProps.disabled !== this.props.disabled ||
      nextState.editingText !== this.state.editingText
    )
  }

  /**
   * フォームの値のバリデーションを実行する
   * @param  {string}  value                      バリデートしようとしている値
   * @return {result:boolean, normalized: string} 判定結果
   */
  timeValidate = value => {
    // 基本のバリデータでチェック
    const { result: orgresult, normalized } = TimeAutocomplete.validate(value)
    let result = orgresult
    // チェックOKの時は追加のバリデータでチェックする。
    if (result) {
      result = result && this.props.addValidate(normalized)
    }
    return { result, normalized }
  }

  /**
   * onChangeとonSelectをまとめたやつ
   * @param  {Event|string} arg イベントオブジェクト、または編集中テキスト
   * @return {void}
   */
  onChangeSelect = arg => {
    const editingText = arg.target ? arg.target.value : arg
    this.setState({ editingText: editingText })

    if (!arg.target) {
      // select の時
      const { result, normalized } = this.timeValidate(editingText)
      if (result) {
        // validationに成功した時は、表示するstate値を更新してpropsも更新する
        this.props.onValidateSuccess(normalized)
      }
      this.setState({ editingText: false })
      this.props.nextId &&
        document.getElementById(this.props.nextId) &&
        document.getElementById(this.props.nextId).focus()
    }
  }

  /**
   * Blurイベントハンドラ
   * @return {void}
   */
  onBlur = () => {
    window.getSelection().removeAllRanges()
    const { editingText } = this.state
    const { result, normalized } = this.timeValidate(editingText)
    if (result && normalized) {
      // validationに成功した時は、表示するstate値を更新してpropsも更新する
      this.props.onValidateSuccess(normalized)
    }
    this.setState({ editingText: false })
  }

  renderItem = (item, isHighlighted) => (
    <div
      key={ item }
      style={ {
        background: isHighlighted ? 'lightgray' : 'white',
        fontSize: '1rem',
        rowGap: '0.25rem',
        padding: '0.5rem',
      } }
    >
      {item}
    </div>
  )

  renderMenu(items, value, style) {
    return (
      <Box
        style={ { ...style, ...this.menuStyle, ...{ zIndex: 10000 } } }
        className={ 'autocomplete-menu' }
        // eslint-disable-next-line react/no-children-prop
        children={ items }
      />
    )
  }

  renderInput = props => (
    <TextField
      { ...props }
      disabled={ props.disabled }
      size="small"
      sx={ {
        background: props.disabled ? '"0e0e0e0e' : '#fff',
        '& .MuiOutlinedInput-root': {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'primary.main',
          },
        },
        '& .MuiInputBase-root': { height: '28px' },
        '& .MuiInputBase-input': { height: '20px', padding: '0 14px' },
      } }
    />
  )

  get displayValue() {
    const { editingText } = this.state
    const { value } = this.props
    return editingText === false ? value : editingText
  }

  get inputProps() {
    const { inputId } = this.props

    return {
      id: inputId,
      className: this.timeValidate(this.displayValue).result
        ? 'input-valid'
        : 'input-invalid',

      // NOTE: FireFoxでマウスクリックでのonBlurが発火しないので、無理やり発火させている
      onFocus: !isFireFox
        ? void 0
        : () => {
          [
            window._es_stickyboard_preserved_window_onclick,
            window.onclick,
          ] = [window.onclick, this.onBlur]
        },
      onBlur: !isFireFox
        ? this.onBlur
        : e => {
          if (
            typeof window._es_stickyboard_preserved_window_onclick ===
              'function'
          ) {
            // 本来のonclickを実行
            window._es_stickyboard_preserved_window_onclick(e)
          }
          [
            window.onclick,
            window._es_stickyboard_preserved_window_onclick,
          ] = [window._es_stickyboard_preserved_window_onclick, null]
          this.onBlur(e)
        },
      disabled: this.props.disabled,
    }
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { freeInput, disabled, interval } = this.props
    const intervalTimes = getIntervalTimeStrings(interval, 180, 1810) // 30:00まで表示
    return (
      <ThemeProvider theme={ theme }>
        {freeInput ? (
          <TextField
            id={ this.inputProps.id }
            onFocus={ this.inputProps.onFocus }
            onBlur={ this.inputProps.onBlur }
            value={ this.displayValue }
            onChange={ this.onChangeSelect }
            onSelect={ this.onChangeSelect }
            disabled={ disabled }
            size="small"
            sx={ {
              background: '#fff',
              '& .MuiOutlinedInput-root': {
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'primary.main',
                },
              },
              '& .MuiInputBase-root': { height: '28px' },
              '& .MuiInputBase-input': { height: '20px', padding: '0 14px' },
            } }
          />
        ) : (
          <Autocomplete
            items={ intervalTimes }
            getItemValue={ item => item }
            renderItem={ this.renderItem }
            renderMenu={ this.renderMenu }
            inputProps={ this.inputProps }
            value={ this.displayValue }
            onChange={ this.onChangeSelect }
            onSelect={ this.onChangeSelect }
            renderInput={ this.renderInput }
          />
        )}
      </ThemeProvider>
    )
  }
}

export default TimeAutocomplete
