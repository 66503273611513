import utils from 'src/lib/api-utils'
import { CALL_API } from 'redux-api-middleware'
import { NETWORKER } from 'src/middlewares/network-monitor'
import Sticky from 'src/lib/class-sticky'

import update from 'immutability-helper'
import switz from 'switz'
import { ACTION_TYPES as NETWORK_ACTION_TYPES } from 'src/reducers/network-status'

import { idToIndex } from 'src/lib/format'
import { validMaruc } from 'src/lib/validate'

export const GET_STICKY_REQUEST = 'WARA.GET_STICKY_REQUEST NO_DOCK'
export const GET_STICKY_SUCCESS = 'WARA.GET_STICKY_SUCCESS NO_DOCK'
export const GET_STICKY_FAILURE = 'WARA.GET_STICKY_FAILURE NO_DOCK'

/**
 * action type name bulk export
 * @type {object}
 */
export const ACTION_TYPES = {
  GET_STICKY_REQUEST,
  GET_STICKY_SUCCESS,
  GET_STICKY_FAILURE,
}

/**
 * get sticky action creator
 * @param  {number} regionId    region id to place
 * @param  {number} staffId     staff id
 * @param  {string} accessToken access token
 * @param  {onject} env         environmental variables
 * @return {object} redux-api-middleware に渡す非同期処理情報を内包したアクション
 */
export const creator = (datestring, accessToken, env, verbose) => {
  const base = utils.createEndpoint(env, 'dockStickies')
  const verboseflag = verbose ? '&verbose=true' : ''
  const endpoint = `${base}?displayDate=${datestring}&fixed=true${verboseflag}`

  return {
    [CALL_API]: {
      endpoint,
      method: 'GET',
      headers: { Authorization: `Bearer ${accessToken}` },
      types: [
        {
          type: GET_STICKY_REQUEST,
          meta: {
            [NETWORKER]: {
              target: 'getSticky',
              networkActionType: NETWORK_ACTION_TYPES.REQUEST,
            },
          },
        },
        {
          type: GET_STICKY_SUCCESS,
          meta: {
            today: datestring,
            [NETWORKER]: {
              target: 'getSticky',
              networkActionType: NETWORK_ACTION_TYPES.SUCCESS,
            },
          },
          payload: (action, state, res) => res.json().then(payload => payload),
        },
        {
          type: GET_STICKY_FAILURE,
          meta: {
            [NETWORKER]: {
              target: 'getSticky',
              networkActionType: NETWORK_ACTION_TYPES.FAILURE,
            },
          },
          payload: (action, state, res) => res.json().then(payload => payload),
        },
      ],
    },
  }
}

/**
 * [AsyncWaraReducers description]
 * @param  {object} state   state
 * @param  {object} action action
 * @return {object}         reducer
 */
export const partialReducer = (state, action) => {
  const { type, payload, meta } = action
  return switz(type, s =>
    s
      .case(GET_STICKY_REQUEST, () => state)
      .case(GET_STICKY_SUCCESS, () => {
        // NOTE: このアクションは、必ずSS取得のあとに呼ばれる。
        // なので、SSのハコが既に存在しているのは保証されているはず。
        // レスポンスをパースして、入れる

        const { today } = meta
        const regionIds = Object.keys(payload[today] || [])
          .map(regionId => parseInt(regionId, 10))
          .filter(regionId => !isNaN(regionId))

        let regionsUpdator = {}

        regionIds.forEach(regionId => {
          const staffIds = Object.keys(payload[today][regionId])
            .map(staffId => parseInt(staffId, 10))
            .filter(staffId => !isNaN(staffId))

          staffIds.forEach(staffId => {
            let regionIndex, listIndex
            try {
              const idPair = idToIndex(state, regionId, staffId)
              regionIndex = idPair.regionIndex
              listIndex = idPair.listIndex
              if (regionIndex === -1 || listIndex === -1) {
                throw new Error(`${JSON.stringify(idPair)}`)
              }
              // $apply が不安定なので、ハコを作っておく
              if (!regionsUpdator[regionIndex]) {
                regionsUpdator[regionIndex] = { lists: {} }
              }
              regionsUpdator[regionIndex].lists[listIndex] = {}
            } catch (e) {
              /* eslint-disable no-console */
              console.warn(e)
              console.warn(regionId, staffId, payload)
              regionId === 0 && console.warn('無効なデータを検出')
              /* eslint-enable no-console */
              regionIndex = false
              listIndex = false
            }

            let stickies
            // マルシー付箋で表示対象外としているものを取り除く。
            try {
              stickies = payload[today][regionId][staffId]
                .filter(validMaruc)
                .map(props => new Sticky(props))
            } catch (e) {
              /* eslint-disable no-console */
              console.warn(e)
              /* eslint-enable no-console */
              stickies = []
            }
            if (regionIndex !== false && listIndex !== false) {
              regionsUpdator[regionIndex].lists[listIndex].stickies = {
                $set: stickies,
              }
            }
          })
        })
        const result = update(state, {
          data: {
            regions: regionsUpdator,
          },
        })
        return result
      })
      .case(GET_STICKY_FAILURE, () => state)
      .default(() => state),
  )
}
