import utils from 'src/lib/api-utils'

export const getStaffs = ({ from, to }, accessToken, env) => {
  const base = `${utils.createEndpoint(env, 'settlementStaffs')}`
  const endpoint = `${base}/${from},${to}`

  return fetch(endpoint, {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  })
}

export default getStaffs
