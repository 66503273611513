/**
 * リストのIDからそのIndex値を検索します
 * @param  {object} state  [description]
 * @param  {string} listId [description]
 * @return {object}        [description]
 */
export const getListIndex = (state, listId) => {
  // dockのことを探そうとしているかどうかを検証
  if (state.dock && state.dock.id && state.dock.id === listId) {
    return { listIndex: -1, isDocked: true }
  }

  // listsを検索
  for (let i = 0; i < (state.lists || []).length; i++) {
    let list = state.lists[i]
    if (list.id === listId) {
      return { listIndex: i, isDocked: false }
    }
  }

  // 見つからない時
  return { listIndex: -1, isDocked: false }
}

/**
 * 付箋のIDからそのインデックス値を検索します
 * @param  {object} state  [description]
 * @param  {string} stickyId [description]
 * @return {object}        {stickyIndex, listIndex, isDocked}
 */
export const getStickyIndex = (state, stickyId) => {
  // dock内部を検索
  for (
    let h = 0;
    h < (state.dock ? state.dock.stickies || [] : []).length;
    h++
  ) {
    let sticky = state.dock.stickies[h]
    if (sticky.id === stickyId) {
      return {
        listIndex: -1,
        stickyIndex: h,
        isDocked: true,
      }
    }
  }

  // lists内部を検索
  for (let i = 0; i < (state.lists ? state.lists : []).length; i++) {
    let list = state.lists[i]
    for (let j = 0; j < (list.stickies ? list.stickies : []).length; j++) {
      let sticky = list.stickies[j]
      if (sticky.id === stickyId) {
        return {
          listIndex: i,
          stickyIndex: j,
          isDocked: false,
        }
      }
    }
  }

  // 見つからない時
  return {
    listIndex: -1,
    stickyIndex: -1,
    isDocked: false,
  }
}

/**
 * マスタを引く関数
 * @param  {Array<{id: number, [propName]: string>} master   [description]
 * @param  {number} id       [description]
 * @return {object|undefined}   [description]
 */
export const master = (master, id) => {
  if (!Array.isArray(master)) {
    return void 0
  }
  const data = master.filter(x => x.id === id)
  if (data.length > 0) {
    return data[0]
  } else {
    return void 0
  }
}

export const findRegionIdFromPrefectureMaster = (prefCodes, prefCode) => {
  if (!Array.isArray(prefCodes)) {
    return void 0
  }
  const foundPrefs = prefCodes.filter(x => x.code === prefCode)
  if (foundPrefs.length > 0) {
    return foundPrefs[0].regionId
  } else {
    return void 0
  }
}

/**
 * 完全な住所を取得する関数
 * @param  {string} prefName pref code string
 * @param  {string} cityName city code string
 * @param  {string} address  住所
 * @param  {string} buildingName 建物名
 * @return {string}          完全な住所
 */
export const getFullAddress = (prefName, cityName, address, buildingName) => {
  const addr = [prefName, cityName, address].filter(x => !!x).join(' ')
  return `${addr}　${buildingName || ''}`.trim()
}

/**
 * [findStickyFromWara description]
 * @param  {object} wara     [description]
 * @param  {object} location [description]
 * @return {object}          [description]
 */
export const findStickyFromWara = (wara, location) => {
  if (!location) {
    return void 0
  }

  const { regionIndex, listIndex, stickyIndex } = location
  try {
    if (regionIndex === -1 || listIndex === -1) {
      return wara.dock.stickies[stickyIndex]
    } else {
      return wara.regions[regionIndex].lists[listIndex].stickies[stickyIndex]
    }
  } catch (e) {
    return void 0
  }
}

/**
 * [findStickyFromWara description]
 * @param  {object} wara     [description]
 * @param  {object} location [description]
 * @return {object}          [description]
 */
export const findListFromWara = (wara, location) => {
  const { regionIndex, listIndex } = location

  if (regionIndex === -1 || listIndex === -1) {
    return wara.dock
  } else {
    return wara.regions[regionIndex].lists[listIndex]
  }
}

/**
 * [searchDescend description]
 * 双方向リストオブジェクトgraphを親ノード -> 子ノードの順に探索
 * graphと探索を開始するIdをinitialIdとして与えると探索を開始し、Idを順に取得して配列に格納して返します
 * 循環した場合はその時点で探索を終了します
 * - エラー時の処理
 *   - 存在しない initialId を引数に指定した場合 -> [] を返します
 *   - 親子ともに null の initialId を引数に指定した場合 -> [initialId] を返します
 * @param  {object} graph     [description]
 * @param  {number} initialId [description]
 * @return {array}            [description]
 */

export const searchDescend = (graph, initialId) => {
  const result = []

  // initialIdの存在チェック
  if (!graph[initialId]) {
    return result
  }
  if (graph[initialId].parentId === null && graph[initialId].childId === null) {
    result.push(initialId)
    return result
  }

  for (let child = initialId; !!child !== false; child = graph[child].childId) {
    // 循環の確認
    if (result[0] !== child) {
      result.push(child)
    } else {
      return result
    }
    // 異常なchildIdの排除
    if (!graph[graph[child].childId]) {
      return result
    }
  }
  return result
}

/**
 * [searchAscend description]
 * 双方向リストオブジェクトgraphを子ノード -> 親ノードの順に探索
 * graphと探索を開始するIdをinitialIdとして与えると探索を開始し、Idを順に取得して配列に格納して返します
 * 循環した場合はその時点で探索を終了します
 * - エラー時の処理
 *   - 存在しない initialId を引数に指定した場合 -> [] を返します
 *   - 親子ともに null の initialId を引数に指定した場合 -> [initialId] を返します
 * @param  {object} graph     [description]
 * @param  {number} initialId [description]
 * @return {array}            [description]
 */
export const searchAscend = (graph, initialId) => {
  const result = []

  // initialIdの存在チェック
  if (!graph[initialId]) {
    return result
  }
  if (graph[initialId].parentId === null && graph[initialId].childId === null) {
    result.push(initialId)
    return result
  }

  for (
    let parent = initialId;
    !!parent !== false;
    parent = graph[parent].parentId
  ) {
    // 循環の確認
    if (result[0] !== parent) {
      result.push(parent)
    } else {
      return result
    }
    // 異常なparentIdの排除
    if (!graph[graph[parent].parentId]) {
      return result
    }
  }
  return result
}

/**
 * 付箋のIDからわら上の位置を取得する。
 * @param {*} wara
 * @param {*} stickyId
 */
export const getStickyLocation = (wara, stickyId) => {
  // dock内部を検索
  for (let h = 0; h < (wara.dock ? wara.dock.stickies || [] : []).length; h++) {
    let sticky = wara.dock.stickies[h]
    if (sticky.id === stickyId) {
      return {
        regionIndex: -1,
        listIndex: -1,
        stickyIndex: h,
      }
    }
  }
  // regionsを探索
  for (let i = 0; i < (wara.regions || []).length; i++) {
    let region = wara.regions[i]
    for (let j = 0; j < (region.lists || []).length; j++) {
      let list = region.lists[j]
      for (let k = 0; k < (list.stickies || []).length; k++) {
        let sticky = list.stickies[k]
        if (sticky.id === stickyId) {
          return {
            regionIndex: i,
            listIndex: j,
            stickyIndex: k,
          }
        }
      }
    }
  }

  // 見つからない時
  return {
    regionIndex: -1,
    listIndex: -1,
    stickyIndex: -1,
  }
}

/**
 * わら上の付箋がある担当者の付箋数を返す。
 * @param {*} wara
 * @param {*} location
 */
export const getListLength = (wara, location) => {
  const { regionIndex, listIndex } = location
  // dock
  if (regionIndex === -1 || listIndex === -1) {
    return (wara.dock ? wara.dock.stickies || [] : []).length
  }
  // regions
  const list = wara.regions[regionIndex].lists[listIndex] || {}
  return (list.stickies || []).length
}

/**
 * 担当者の所属するリストを検索する。
 * @param {*} wara
 * @param {*} staffId
 * @returns {reginonIndex, listIndex, stickyIndex}: stickyIndexは担当者が持つ付箋の数
 */
export const getStaffList = (wara, staffId) => {
  // dockは検索対象外
  // regionsを探索
  for (let i = 0; i < (wara.regions || []).length; i++) {
    let region = wara.regions[i]
    for (let j = 0; j < (region.lists || []).length; j++) {
      let list = region.lists[j]
      if (list.staffId === staffId) {
        const stickyLength = (list.stickies || []).length
        return {
          regionIndex: i,
          listIndex: j,
          stickyIndex: stickyLength,
        }
      }
    }
  }

  // 見つからない時
  return {
    regionIndex: -1,
    listIndex: -1,
    stickyIndex: -1,
  }
}
