import { DropTarget } from 'react-dnd'
import detectStickyType, { stickyTypes } from './lib/detect'
import dropNormalSticky from './async/drop-normal-sticky'
import dropSpecialSticky from './async/drop-special-sticky'
import switz from 'switz'

const ACCEPTABLE_TYPES = ['sticky', 'specialSticky']

const stickySource = {
  /**
   * [drop description]
   * @param  {object}        props     Destination props
   * @param  {TargetMonitor} monitor   Source
   * @param  {Component}     component [description]
   * @return {void}
   */
  drop: (props, monitor, component) => {
    const { dispatch, getState } = component.context.store

    const state = getState()
    const item = monitor.getItem()

    const { location: locationDest } = props
    const { location: locationSrc, stickyTypeId } = item

    switz(detectStickyType(locationDest, item), s =>
      s
        .case(stickyTypes.NORMAL, () => {
          dropNormalSticky(state, locationSrc, locationDest, dispatch)
        })
        .case(stickyTypes.SPLIT, () => {
          dropNormalSticky(state, locationSrc, locationDest, dispatch) // Normalと一緒ね
        })
        .case(stickyTypes.SPECIAL, () => {
          dropSpecialSticky(
            state,
            stickyTypeId,
            locationSrc,
            locationDest,
            dispatch,
          )
        }),
    )
  },
}

export default Component =>
  DropTarget(
    // type
    ACCEPTABLE_TYPES,
    // spec
    stickySource,
    // collect
    (connect, monitor) => {
      return {
        connectDropTarget: connect.dropTarget(),
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver({ shallow: true }),
      }
    },
  )(Component)
