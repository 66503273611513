/**
 * わらのコンポーネント
 * @file
 */

/**
 * ライブラリ
 */
import React from 'react'
import PropTypes from 'prop-types'
import { zerofillDate } from 'src/lib/format'
import utils from 'src/lib/api-utils'
import connect from './connect'
import { qsparse, qsjoin } from 'src/lib/format'
import findStickyById from 'src/lib/find-sticky-by-id'
import findStickyFromLocation from 'src/lib/find-sticky-from-location'
import requestGetSticky from 'src/lib/sticky-api/get'
import requestGetSSWeeklySales from 'src/lib/staff-api/weekly-sales'
import expoGetWorkList from 'src/lib/expo-push-api/worklist'
import requestGetStaffInfo from 'src/lib/staff-api/get-staff-info'
import requestPutStaffInfo from 'src/lib/staff-api/put-staff-info'
import { today } from 'src/lib/moment'

/**
 * コンポーネント
 */
import Region from '../region/container'
import Side from '../side'
import Modal from 'react-modal'
import SortListModalContent from './partials/sort-list-modal-content'
import CommonHeader from 'src/components/layouts/common-header'

import requestGetRegionInfo from '../../../../lib/region-Info-api/get' // エリア別の特記事項

/**
 * Render わら
 * @type {ReactComponent}
 */
export class Wara extends React.Component {
  /**
   * validation
   * @type {object}
   */
  static propTypes = {
    // stateProps
    env: PropTypes.object.isRequired,
    userid: PropTypes.string.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    regions: PropTypes.array.isRequired,
    match: PropTypes.shape({ params: PropTypes.object.isRequired }).isRequired,
    modal: PropTypes.shape({ isOpen: PropTypes.bool, props: PropTypes.any })
      .isRequired,
    accessToken: PropTypes.string.isRequired,
    regionIds: PropTypes.array.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string,
    }).isRequired,
    fixedStickiesLoaded: PropTypes.bool.isRequired,
    dockStickiesLoaded: PropTypes.bool.isRequired,
    wara: PropTypes.object.isRequired,
    master: PropTypes.object.isRequired,
    // dispatchProps
    getDockStickies: PropTypes.func.isRequired,
    refresh: PropTypes.func.isRequired,
    removeEntries: PropTypes.func.isRequired,
    resetPostStatus: PropTypes.func.isRequired,
    setUserPreset: PropTypes.func.isRequired,
    editSticky: PropTypes.func.isRequired,
  }

  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)

    // ログインしていなければホームに戻る
    const { isLoggedIn } = this.props
    const [, , , year, month, day] = this.props.location.pathname
      .split('/')
      .map(x => parseFloat(x))

    const isInt = x => Math.round(x) === x
    const isValidDate = isInt(year) && isInt(month) && isInt(day)

    if (!isLoggedIn || !isValidDate) {
      this.props.history.push('/')
      return
    }

    // データのリフレッシュ
    const dateString = zerofillDate(`${year}-${month}-${day}`)
    this.props.refresh(dateString, this.props.accessToken)
    // 前に表示されていた付箋があればそれもクリアする。
    this.props.wara.regions.forEach(region => {
      region.lists.forEach(ss => {
        ss.stickies = []
      })
    })

    // マウント時にstageの値（フォームの入力値をセットしてある）や検索結果をリセット
    this.props.removeEntries()
    // それから、付箋作成ネットワークの正否を一応リセット
    this.props.resetPostStatus()

    this.state = {
      sordModalDisplayRegionId: 1,
      isSortModalOpen: false,
      sortModalError: false,
      showAllStickies: true, // 特定の付箋を表示しない時falseにする
      displayDate: dateString, // わらの表示日付
      staffWeeklySales: {}, // SSの週次売上
      getWeeklySales: false, // 週次売上取得済みの時true
      worklist: {}, // SSの稼働状況
      staffInfoList: {}, // SSの情報
      regionInfo: {}, // エリア別の特記事項
    }
  }

  /**
   *
   */
  componentDidMount() {
    const { displayDate } = this.state
    this.getSSWeeklySales(displayDate)
    this.getSSworkList(displayDate)
    this.getStaffInfoList()
    this.getRegionInfo()
  }

  /**
   * UNSAFE_componentWillReceiveProps
   * @param  {object} nextProps React props.
   * @return {void}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    // onEnter
    if (this.props.location.pathname !== nextProps.location.pathname) {
      const [, , , year, month, day] = nextProps.location.pathname
        .split('/')
        .map(x => parseFloat(x))
      const dateString = zerofillDate(`${year}-${month}-${day}`)
      this.setState({ displayDate: dateString })
      this.getSSWeeklySales(dateString)
      this.getSSworkList(dateString)
      this.getStaffInfoList()
      this.props.refresh(dateString, this.props.accessToken)
    }
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps, nextState) {
    // 編集中の付箋が更新された時に再描画
    const thisSticky = (this.props.modal.props || {}).sticky || {}
    const nextSticky = (nextProps.modal.props || {}).sticky || {}
    return (
      this.state.sortModalError !== nextState.sortModalError ||
      this.state.isSortModalOpen !== nextState.isSortModalOpen ||
      this.state.sordModalDisplayRegionId !==
        nextState.sordModalDisplayRegionId ||
      this.props.regions !== nextProps.regions ||
      this.props.regionIds.length !== nextProps.regionIds.length ||
      this.props.modal.isOpen !== nextProps.modal.isOpen ||
      thisSticky !== nextSticky ||
      this.props.fixedStickiesLoaded !== nextProps.fixedStickiesLoaded ||
      this.props.dockStickiesLoaded !== nextProps.dockStickiesLoaded ||
      this.state.showAllStickies !== nextState.showAllStickies ||
      this.state.staffWeeklySales !== nextState.staffWeeklySales ||
      this.state.displayDate !== nextState.displayDate ||
      this.state.worklist !== nextState.worklist ||
      this.state.staffInfoList !== nextState.staffInfoList
    )
  }

  /**
   * componentDidUpdate
   * @param  {object} prevProps prev props
   * @param  {object} prevState prev state
   * @param  {object} snapshot  snapshot
   * @return {void}
   */
  componentDidUpdate(prevProps) {
    const query = qsparse(this.props.location.search) || {}
    const stickyId = parseInt(query.stickyId, 10)

    // 開いた時に、stickyId クエリがあれば、それを開く
    // すでにポップアップが開かれているときは開かない
    if (!!(prevProps.modal || {}).isOpen === false && stickyId) {
      const { fixedStickiesLoaded, dockStickiesLoaded } = this.props
      if (
        stickyId &&
        fixedStickiesLoaded &&
        dockStickiesLoaded &&
        (!prevProps.fixedStickiesLoaded || !prevProps.dockStickiesLoaded)
      ) {
        // 全部が正しく読めた時に、特定の付箋が指定されているならば、そのモーダルを開く
        // 開く付箋のわら上の位置を取得する。2021/01/05 の修正でlocationが未設定になっていた。
        const { location } = findStickyById(this.props.wara, stickyId)
        const { accessToken, env } = this.props
        requestGetSticky(stickyId, accessToken, env)
          .then(rsticky => this.props.editSticky(rsticky, location))
          .catch(err => {
            // alert(`付箋詳細の取得に失敗しました。（付箋ID: ${stickyId}）`)
            console.error(err)
          })
      }
    }

    // 開いた時
    if (
      !!(this.props.modal || {}).isOpen === true &&
      !!(prevProps.modal || {}).isOpen === false
    ) {
      const { location: stickyLocation } = this.props.modal.props
      if (stickyLocation.regionIndex) {
        const { id: stickyId } = findStickyFromLocation(
          this.props.wara,
          stickyLocation,
        )
        const nextQuery = { ...query, stickyId }
        this.props.history.replace('?' + qsjoin(nextQuery))
      }
    }

    // 閉じた時
    if (
      stickyId &&
      !!(this.props.modal || {}).isOpen === false &&
      !!(prevProps.modal || {}).isOpen === true
    ) {
      const nextQuery = { ...query, stickyId: void 0 }
      this.props.history.replace('?' + qsjoin(nextQuery))
      this.getStickies()
    }
  }

  /**
   * SSのその週の売り上げ合計を取得する
   */
  getSSWeeklySales = displayDate => {
    const { accessToken, env } = this.props
    const thisday = today()
    // 表示する日付が今日以外の時はSSの売上を取得しない。
    if (displayDate === thisday && !this.state.getWeeklySales) {
      requestGetSSWeeklySales(displayDate, accessToken, env).then(res => {
        this.setState({
          staffWeeklySales: res,
          getWeeklySales: true,
        })
      })
    }
  }

  /**
   * SSの稼働状態を取得する
   */
  getSSworkList = displayDate => {
    const { accessToken, env } = this.props
    expoGetWorkList(displayDate, accessToken, env)
      .then(res => {
        if (res.ok) {
          return res.json()
        } else {
          throw 'SS稼働状態取得エラー'
        }
      })
      .then(worklist => {
        // console.log(worklist)
        this.setState({
          worklist,
        })
      })
      .catch(err => {
        console.error(err)
      })
  }

  /**
   * SSの情報を取得する
   */
  getStaffInfoList = () => {
    const { accessToken, env } = this.props
    requestGetStaffInfo(accessToken, env)
      .then(staffInfo => {
        // console.log(staffInfo)
        this.setState({ staffInfoList: staffInfo.staffInfo })
      })
      .catch(err => {
        console.error(err)
      })
  }

  /**
   * SSの情報を更新する
   * @param {*} ssProps
   */
  putStaffInfo = (orgInfo, diffProps) => {
    const { accessToken, env } = this.props
    const staffInfo = { ...orgInfo, ...diffProps }
    requestPutStaffInfo(staffInfo, accessToken, env)
      .then(res => {
        const { staffInfoList } = this.state
        let newinfo = { ...staffInfoList }
        newinfo[res.staffId] = { ...res }
        this.setState({ staffInfoList: newinfo })
      })
      .catch(err => {
        console.error(err)
      })
  }

  /**
   * getStickies 見配置付箋などを取得する
   * @return {void}
   */
  getStickies() {
    const { year, month, day } = this.props.match.params
    const { accessToken } = this.props
    this.props.getDockStickies(year, month, day, accessToken)
  }

  createOpenSortListModalHandler = regionId => () =>
    this.setState({
      sordModalDisplayRegionId: regionId,
      isSortModalOpen: true,
    })

  closeSortListModal = () => this.setState({ isSortModalOpen: false })

  applySort = nextUserPreset => {
    this.setState({ sortModalError: false })

    const base = `${utils.createEndpoint(this.props.env, 'userPreset')}`
    const endpoint = `${base}/${this.props.userid}`

    fetch(endpoint, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ regions: nextUserPreset }),
    })
      .then(res => {
        if (res.ok) {
          this.props.setUserPreset({ regions: nextUserPreset })
          this.closeSortListModal()
        } else {
          throw '通信エラー'
        }
      })
      .catch(
        err => console.error(err) || this.setState({ sortModalError: true }),
      )
  }

  /**
   * 表示する付箋の種類を切り替える
   */
  toggleSticky = () => {
    this.setState({ showAllStickies: !this.state.showAllStickies })
  }

  // エリアごとの特記事項の情報を取得します
  getRegionInfo = () => {
    const { accessToken, env } = this.props
    requestGetRegionInfo(accessToken, env)
      .then(data => {
        this.setState({ regionInfo: data.regionInfo })
      })
      .catch(error => {
        console.log(`getRegionInfo: ${error}`)
      })
  }

  /**
   * render
   * @return {ReactDOM} rendered result
   */
  render() {
    const {
      isSortModalOpen,
      sordModalDisplayRegionId,
      sortModalError,
      showAllStickies,
      displayDate,
      staffWeeklySales,
      worklist,
      staffInfoList,
      regionInfo,
    } = this.state
    const {
      regions,
      regionIds,
      modal,
      match: { params }, // location params
      history: { push }, // browser history push
      master,
    } = this.props

    const hasModalContent = modal.content && typeof modal.content === 'function'

    /**
     * wara.regionsはregionIdの昇順（正確にはObject.keysの順番）にソートされた配列のため、
     * master.regionsのdisplayOrderで指定された順に並べ替える。
     * その際、wara.regionsのインデックスが必要になるため、regionIndexを設定する。
     * 表示の際、RegionのlocationのregionIndexにはここで設定したregionIndexを渡す。
     */
    const sortRegions = () => {
      const ssRegions = regions || []
      const masterRegions = master.regions || []
      const dispRegions = []
      masterRegions.forEach(mregion => {
        for (let i = 0; i < ssRegions.length; i++) {
          const sRegion = ssRegions[i]
          if (sRegion.id === mregion.id) {
            dispRegions.push({
              ...sRegion,
              display: regionIds.includes(sRegion.id),
              regionIndex: i,
            })
            break
          }
        }
      })
      return dispRegions
    }
    const displayRegions = sortRegions()
    // console.log(displayRegions)

    const weeklySales = displayDate === today() ? staffWeeklySales : {}

    return (
      <>
        <CommonHeader route={ 'assign' } subRoute={ 'wara' } />
        <div className={ 'wara clearfix' } id={ 'wara' }>
          <div id={ 'main' } className={ 'main' }>
            {/**
             * セクションごとのわら要素を列挙します。
             * @return {Array<ReactDOM>} ReactDOM
             */
              displayRegions.map(region => {
                return (
                  <Region
                    openSortListModal={ this.createOpenSortListModalHandler(
                      region.id,
                    ) }
                    key={ region.id }
                    display={ region.display }
                    location={ { regionIndex: region.regionIndex } }
                    showAllStickies={ showAllStickies }
                    toggleSticky={ this.toggleSticky }
                    staffWeeklySales={ weeklySales }
                    worklist={ worklist }
                    getSSworkList={ this.getSSworkList }
                    staffInfoList={ staffInfoList }
                    putStaffInfo={ this.putStaffInfo }
                    regionInfo={ regionInfo }
                  />
                )
              })}
          </div>

          {/* シングルトンなモーダル */}
          <Modal
            contentLabel={ 'Wara Modal Editor' }
            isOpen={ modal.isOpen }
            shouldCloseOnOverlayClick={ false }
            ariaHideApp={ false }
          >
            {hasModalContent ? <modal.content { ...modal.props } /> : null}
          </Modal>

          {/* リスト並べ替えモーダル */}
          <Modal
            contentLabel={ 'list-sort' }
            isOpen={ isSortModalOpen }
            shouldCloseOnOverlayClick={ false }
            ariaHideApp={ false }
          >
            <SortListModalContent
              sortModalError={ sortModalError }
              sordModalDisplayRegionId={ sordModalDisplayRegionId }
              closeMe={ this.closeSortListModal }
              apply={ this.applySort }
            />
          </Modal>

          {/* サイドバーコンポーネント */}
          <Side
            id={ 'the-side' }
            location={ this.props.location } // URLのロケーション
            locationParams={ params } // わらの日付
            push={ push }
          />
        </div>
      </>
    )
  }
}

export default connect(Wara)
