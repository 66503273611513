import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import config from 'src/config'
import Button from 'src/styled/button/ghost'
import Checkbox from 'src/styled/form/checkbox'

/**
 * エリアを複数チェックして選択する
 * @param {*} props
 */
function RegionCheckList(props) {
  const { label, checkedRegions, regions, setRegions, hlOnly } = props
  // 有効なエリアのみを表示対象とする
  const selectRegions = () => {
    if (hlOnly) {
      return regions.filter(
        region => region.isValid && region.companyId === config.company.HL,
      )
    } else {
      return regions.filter(region => region.isValid)
    }
  }
  const displayRegions = selectRegions().filter(region => !/HL|Q/.test(region.name))

  // 見出しエリアのスタイル
  const titleDivStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    width: '80%',
  }

  // 見出しのスタイル
  const titleStyle = {
    fontWeight: 'bold',
  }

  // ボタンを囲むDIVのスタイル
  const buttonDivStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '130px',
  }

  // チェックボックスを囲むDIVのスタイル
  const areaDivStyle = {
    paddingLeft: '50px',
    paddingTop: '10px',
  }

  /**
   * チェックボックスの選択が変わった時のイベントハンドラ
   * @param {*} e
   */
  const onChangeCheck = e => {
    const regionId = parseInt(e.target.value, 10)
    const checked = e.target.checked
    if (checked) {
      // エリアのIDが含まれない時は追加する
      if (!checkedRegions.includes(regionId)) {
        setRegions([...checkedRegions, regionId])
      }
    } else {
      // エリアのIDが含まれる時は削除する
      const p = checkedRegions.indexOf(regionId)
      if (p >= 0) {
        const regions = [...checkedRegions]
        regions.splice(p, 1)
        setRegions(regions)
      }
    }
  }

  /**
   * チェックボックスを一括設定する
   * 親コンポーネントの選択済エリアに表示しているすべてのエリアを設定する
   */
  const selectAll = () => {
    const regionIds = displayRegions.map(region => region.id)
    setRegions(regionIds)
  }

  /**
   * チェックボックスを一括解除する
   * 親コンポーネントの選択済エリアに[]を設定する
   */
  const clearAll = () => {
    setRegions([])
  }

  // エリア名の改行制御
  let hl = hlOnly // HLが始まったらtrue HLのみの時は最初からtrue
  let qi = false // キレイエが始まったらtrue
  let old = false // 旧が始まったらtrue

  return (
    <div>
      <div style={ titleDivStyle }>
        <div>
          <span style={ titleStyle }>{label}</span>
        </div>
        <div style={ buttonDivStyle }>
          <Button onClick={ selectAll }>{'全選択'}</Button>
          <Button onClick={ clearAll }>{'全解除'}</Button>
        </div>
      </div>
      <div style={ areaDivStyle }>
        {displayRegions.map(region => {
          const checked = (checkedRegions || []).includes(region.id)
          // HL、Q、(旧)が始まるときに改行する。
          let br = null
          if (!hl && region.companyId === config.company.HL) {
            hl = true
            br = <br />
          } else if (!qi && region.companyId === config.company.QI) {
            qi = true
            br = <br />
          } else if (!old && region.name.endsWith('(旧)')) {
            old = true
            br = <br />
          }
          return (
            <>
              {br}
              <Checkbox
                key={ region.id }
                value={ region.id }
                checked={ checked }
                onChange={ onChangeCheck }
                labelText={ region.name }
              />
            </>
          )
        })}
      </div>
    </div>
  )
}

/**
 * propTypes
 */
RegionCheckList.propTypes = {
  // ownprops
  label: PropTypes.string.isRequired, // タイトル
  checkedRegions: PropTypes.arrayOf(PropTypes.number).isRequired, // 選択されたエリアIDのリスト
  setRegions: PropTypes.func.isRequired, // 選択したエリアを設定する setRegions([regionId])
  hlOnly: PropTypes.bool, // ハウスラボのエリアのみを表示する
  // stateprops
  regions: PropTypes.array.isRequired, // マスタのエリアリスト
}

RegionCheckList.defaultProps = {
  hlOnly: false,
}

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
const mapStateToProps = state => {
  return {
    regions: state.master.data.regions,
  }
}

export default connect(mapStateToProps)(RegionCheckList)
