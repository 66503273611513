// partialHooks
import wara from './partials/wara'
import login from './partials/login'
import sendMail from './partials/send-mail'
import userPresetHooks from './partials/user-preset'
// import insertSticky from './partials/insert-sticky'
import getSticky from './partials/get-sticky'
import postSticky from './partials/post-sticky'

/**
 * redux hook middleware に与える posthooks 関数オブジェクトです
 * @type {Object}
 */
export const posthooks = {
  // partial なフック関数
  ...login,
  ...wara,
  ...sendMail,
  ...userPresetHooks,
  // ...insertSticky,
  ...getSticky,
  ...postSticky,
}

export default posthooks
