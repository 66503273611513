/**
 * 付箋データ入力フォームの並び順などを定義する
 * @file
 */

import React, { memo } from 'react'
import PropTypes from 'prop-types'

import FinishState from '../custom-controls/assign/finish-state'
import Place from '../custom-controls/place'
import Memo from '../custom-controls/memo'
import PrivateTime from '../custom-controls/private-time'
import moment from 'moment'

// libs
import Sticky from 'src/lib/class-sticky'

const updateInfo = {
  display: 'grid',
  marginBlock: '3rem',
  rowGap: '1rem',
}
const updateRow = {
  display: 'grid',
  gridTemplateColumns: '12rem 1fr',
}
const updateHeader = {
  fontWeight: 'bold',
}

/**
 * 付箋入力フォームを描画します
 * @class {ReactComponent}
 */
const PrivateForms = memo(
  props => {
    const { sticky, route, location, master } = props

    const updateAt =
      moment(sticky._props.updateAt).format('YYYY/MM/DD HH:mm') || ''
    const updateUser =
      master.allUsers.find(user => user.id === sticky._props.updateUserId)
        .name || ''

    return (
      <div onClick={ e => e.stopPropagation() }>
        <div className={ 'new-order-input' }>
          <h2 className={ 'form-title' }>{'私用入力'}</h2>

          <PrivateTime
            sticky={ sticky }
            route={ route }
            prevId={ '' }
            nextId={ 'input-private-place' + route }
            disabled={ false }
          />
          {/* determinedDateTimeを使う */}

          <Place sticky={ sticky } route={ route } disabled={ false } />
          {/* workPointを使う */}

          <Memo sticky={ sticky } route={ route } disabled={ false } />
          {/* workContentを使う */}

          <FinishState
            sticky={ sticky }
            route={ route }
            location={ location }
            isSpecial
            disabled={ false }
          />
          {/* 終了状態 */}
          <div style={ updateInfo }>
            <div style={ updateRow }>
              <span style={ updateHeader }>{'最終更新日時'}</span>
              <span>{updateAt}</span>
            </div>
            <div style={ updateRow }>
              <span style={ updateHeader }>{'最終更新者'}</span>
              <span>{updateUser}</span>
            </div>
          </div>
        </div>
      </div>
    )
  },
  (prevProps, nextProps) => prevProps.sticky === nextProps.sticky,
)

/**
 * PropTypes 型定義
 * @type {object}
 */
PrivateForms.propTypes = {
  sticky: PropTypes.instanceOf(Sticky).isRequired,
  route: PropTypes.oneOf(['call', 'assign']).isRequired,
  location: PropTypes.shape({
    regionIndex: PropTypes.number.isRequired,
    listIndex: PropTypes.number.isRequired,
    stickyIndex: PropTypes.number.isRequired,
  }).isRequired,
  master: PropTypes.object.isRequired,
}
PrivateForms.displayName = 'PrivateForms'

export default PrivateForms
