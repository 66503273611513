import React, { useState, useEffect, useMemo } from 'react'
import connect from './connect'
import PropTypes from 'prop-types'
import linkHistoryHOC from './hocs/link-history'
import config from 'src/config'
import {
  Autocomplete,
  Box,
  FormControl,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@mui/material'
import theme from 'src/theme.js'
import { ThemeProvider } from '@mui/material/styles'

const AutoCompleteSs = ({
  small,
  disabled,
  staffs,
  validStaffs,
  verboseName,
  showRegion,
  esStaffs,
  hlStaffs,
  qiStaffs,
  master: { regions },
  staffId,
  ssRegion,
  onChange,
  inputName,
  showEndStaff,
}) => {
  /**
   * 表示する項目名
   */
  const getItemValue = staff => {
    const name = (verboseName ? staff.staffVerboseName : staff.staffName) || ''
    if (!name) return
    const contractEndedPrefix =
      showEndStaff && !staff?.isValid ? '(契約終了) ' : ''
    return `${contractEndedPrefix}${name}`
  }

  const getKanaValue = staff => {
    return String(staff.staffKana) || ''
  }

  const [, setInputValue] = useState('')
  const [regionId, setRegionId] = useState(ssRegion || -2)

  const [selectedItem, setSelectedItem] = useState(null)

  useEffect(() => {
    const staff = staffs.find(staff => staff.staffId === parseInt(staffId, 10))
    if (staff) {
      setSelectedItem({ id: staff.staffId, name: getItemValue(staff) })
      setInputValue(getItemValue(staff))
    } else {
      setSelectedItem(null)
      setInputValue('')
    }
  }, [staffId, staffs, verboseName])

  // 有効なエリアのみを表示対象とする。
  const validRegions = useMemo(
    () =>
      regions.filter(region => {
        // 選択した会社のエリアを表示
        const validCompany = companyId => {
          if (companyId === config.company.HL) {
            return hlStaffs
          } else if (companyId === config.company.ES) {
            return esStaffs
          } else {
            return false
          }
        }
        return region.isValid && validCompany(region.companyId || -1)
      }),
    [regions, esStaffs, hlStaffs, qiStaffs],
  )

  // エリアに所属する担当者のみを表示する。
  let items = useMemo(
    () =>
      regionId >= 0
        ? validStaffs
          .filter(staff => staff.regionId === regionId)
          .map(staff => ({
            id: staff.staffId,
            name: getItemValue(staff),
            kana: getKanaValue(staff),
          }))
        : staffs.map(staff => ({
          id: staff.staffId,
          name: getItemValue(staff),
          kana: getKanaValue(staff),
        })),
    [showRegion, regionId, validStaffs, staffs],
  )

  /**
   * スタッフのエリアが変更された
   */
  const onRegionChange = e => {
    const newRegion = parseInt(e.target.value, 10)
    setRegionId(newRegion)
    setInputValue('')
    setSelectedItem(null)
    onChange({ target: { value: '' } })
  }

  return (
    <ThemeProvider theme={ theme }>
      <Box sx={ { display: 'flex', alignItems: 'center' } }>
        {!small && <label style={ { fontWeight: 'bold' } }>{'担当者'}</label>}
        <Box
          sx={ {
            display: 'grid',
            gridTemplateColumns: showRegion ? '9.75rem 21.5rem' : '21.5rem',
            alignItems: 'center',
            columnGap: '0.5rem',
          } }
        >
          {showRegion && (
            <FormControl
              color="primary"
              fullWidth
              size="small"
              disabled={ disabled }
              variant="outlined"
              sx={ {
                // TODO: 既存のMUI以外で作成されているselectなどと揃えるための設定
                background: '#fff',
                '.MuiOutlinedInput-root': {
                  height: '28px',
                  alignItems: 'center',
                  '.MuiSelect-select': {
                    padding: '0px 14px',
                  },
                },
              } }
            >
              <Select
                color="primary"
                value={ regionId }
                onChange={ onRegionChange }
                MenuProps={ {
                  anchorOrigin: { vertical: 'bottom', horizontal: 'left' }, // TextFieldの下から表示開始
                  transformOrigin: { vertical: 'top', horizontal: 'left' },
                  PaperProps: { style: { maxHeight: '50vh' } },
                  style: { zIndex: 10000 }, // TODO: 付箋詳細や詳細検索のモーダルで表示するにはこの数値が必要
                } }
                size="small"
              >
                {[{ id: -1, name: '未選択' }, ...validRegions].map(region => (
                  <MenuItem
                    color="primary"
                    key={ region.id }
                    sx={ { lineHeight: '1rem' } }
                    value={ region.id }
                  >
                    {region.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Autocomplete
            color="primary"
            disabled={ disabled }
            disableClearable
            options={ items }
            getOptionLabel={ option => option.name }
            value={ selectedItem }
            onChange={ (_event, newValue) => {
              setSelectedItem(newValue)
              if (newValue !== null && newValue !== undefined) {
                onChange({ target: { value: newValue.id.toString() } })
                setInputValue(newValue.name)
              } else {
                onChange({ target: { value: '' } })
                setInputValue(newValue.name)
                setInputValue('')
              }
            } }
            isOptionEqualToValue={ (option, value) => option.id === value.id }
            ListboxProps={ { style: { maxHeight: '50vh' } } }
            PaperComponent={ props => (
              <Paper
                { ...props }
                style={ { width: 'fit-content', minWidth: '100%' } }
              />
            ) }
            renderInput={ params => (
              <TextField
                { ...params }
                variant="outlined"
                disabled={ disabled }
                fullWidth
                size="small"
                sx={ {
                  background: '#fff',
                  '& .MuiOutlinedInput-root': {
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(0, 0, 0, 0.23)',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'primary.main',
                    },
                  },
                  '& .MuiInputBase-root': { height: '28px' },
                  '& .MuiInputBase-input': {
                    height: '20px',
                    padding: '0 14px',
                  },
                } }
              />
            ) }
            renderOption={ (props, option) => (
              <Box
                component="li"
                { ...props }
                sx={ {
                  lineHeight: '1rem',
                  whiteSpace: 'nowrap',
                } }
              >
                {`${option.name}（${option.kana}）`}
              </Box>
            ) }
          />
        </Box>
      </Box>
    </ThemeProvider>
  )
}

/**
 * propTypes
 * @type {object}
 */
AutoCompleteSs.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  inputName: PropTypes.string.isRequired, // inputのname属性

  // stateProps
  // 退職者も含めた担当者一覧
  staffs: PropTypes.arrayOf(
    PropTypes.shape({
      staffId: PropTypes.number.isRequired,
      staffName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  // 退職者を除いた担当者一覧
  validStaffs: PropTypes.arrayOf(
    PropTypes.shape({
      staffId: PropTypes.number.isRequired,
      staffName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  // マスターからエリアの一覧
  master: PropTypes.shape({
    regions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,

  // よく分からないが、注文一覧のビューでは flex を取ってしまうと崩れるし、それ以外ではflexを取る必要がある
  isFlex: PropTypes.bool,
  small: PropTypes.bool, // ドロップダウンだけを表示する時trueにする
  staffId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // 担当者の初期値を設定する
  verboseName: PropTypes.bool, // trueの時長い名前で表示する
  showEndStaff: PropTypes.bool, // trueの時契約終了の名前の前に(契約終了)をつける
  showRegion: PropTypes.bool, // trueの時エリアによる絞り込みを可能にする
  ssRegion: PropTypes.number, // showRegion=trueのとき、エリアを指定する
  esStaffs: PropTypes.bool, // 表示するSSの受付会社の種類を選択する
  hlStaffs: PropTypes.bool,
  qiStaffs: PropTypes.bool,
}

/**
 * defaultProps
 * @type {object}
 */
AutoCompleteSs.defaultProps = {
  isFlex: false,
  disabled: false,
  small: false,
  staffId: -1,
  verboseName: false,
  allStaffs: false,
  showEndStaff: false,
  showRegion: false,
  ssRegion: -1,
  esStaffs: true,
  hlStaffs: true,
  qiStaffs: true,
}

export default linkHistoryHOC(connect(AutoCompleteSs), 'staffId')
