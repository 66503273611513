import moment from 'moment'

/**
 * 今日の日付を取得する関数
 * @return {string} YYYY-MM-DD
 */
export const today = () => {
  const today = moment()
  if (today.hour() < 3) {
    return today.add(-1, 'day').format('YYYY-MM-DD')
  } else {
    return today.format('YYYY-MM-DD')
  }
}

/**
 * 前日の日付を取得する関数
 * @return {string} YYYY-MM-DD
 */
export const yesterday = () => {
  const yesterdayMoment = moment().subtract(1, 'day')
  if (yesterdayMoment.hour() < 3) {
    return yesterdayMoment.add(-1, 'day').format('YYYY-MM-DD')
  } else {
    return yesterdayMoment.format('YYYY-MM-DD')
  }
}

export const now = () => {
  const now = moment()
  if (now.hour() < 3) {
    return now.add(1, 'day').format('HH:mm')
  } else {
    return now.format('HH:mm')
  }
}

/**
 * 指定日のX曜日の日付を取得する関数
 * @param {string} originDate YYYY-MM-DD
 * @param {number} targetDay Sunday is 0, Monday is 1, and so on.
 * @return {string} YYYY-MM-DD
 */
export const thisWeek = (originDate, targetDay = 1) => {
  const origin = moment(originDate, 'YYYY-MM-DD')
  return origin
    .startOf('isoWeek')
    .isoWeekday(targetDay)
    .format('YYYY-MM-DD')
}

/**
 * 指定日の前の週のX曜日の日付を取得する関数
 * @param {string} originDate YYYY-MM-DD
 * @param {number} targetDay Sunday is 0, Monday is 1, and so on.
 * @return {string} YYYY-MM-DD
 */
export const lastWeek = (originDate, targetDay = 1) => {
  const origin = moment(originDate, 'YYYY-MM-DD').subtract(1, 'week')
  return origin
    .startOf('isoWeek')
    .isoWeekday(targetDay)
    .format('YYYY-MM-DD')
}

/**
 * 指定日の月の最初の日を取得する関数
 * @param {string} originDate YYYY-MM-DD
 * @return {string} YYYY-MM
 */
export const thisMonth = originDate => {
  const origin = moment(originDate, 'YYYY-MM-DD')
  return origin.startOf('month').format('YYYY-MM-DD')
}
