import styled from 'styled-components'
import { baseColor } from 'src/colors'

export const Active = styled.span`
  margin-left: 0.5em;
  background-color: ${baseColor};
  color: white;
`

export const Deactive = styled.span`
  color: black;
  opacity: 0.3;
  margin-left: 0.5em;
`
export const Plain = styled.span`
  margin-left: 0.5em;
`
export const Item = styled.p`
  padding: 5px;
`
