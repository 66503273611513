/* global saveAs */
// react
import React from 'react'
import PropTypes from 'prop-types'

// redux
import { connect } from 'react-redux'
import { actionCreators as createLightboxActions } from 'src/reducers/lightbox'

// components
import Loading from 'src/components/commons/loading-screen/partials/initial-loading'
import ValidationErrorMessage from 'src/components/commons/validation-error-message'
import LazyImage from './lazy-image'
import { ScreenFull, ScreenRight, Console, Button, CloseButton } from './styled'
import FileUploader from 'src/components/commons/file-uploader'

// lib
import getImageList from 'src/lib/image-api/get-list'
import expoGetImageList from 'src/lib/expo-image-api/get-list'
import {
  listQuotationByOrderId,
  listContractByOrderId,
  listWorkCompleteByOrderId,
  listReceiptByOrderId,
  listShipInventoryByOrderId,
  listHLWorkCompleteByOrderId,
  listECWorkCompleteByOrderId,
  listESignAgreementByOrderId,
} from 'src/lib/inventory-api/list'
import { uploadShipInventoryPDF } from 'src/lib/inventory-api/shipInventory'
import config from 'src/config'
import { makeCancelable } from 'src/lib/cancelable-promise'
import styled from 'styled-components'
import { baseColor, gray, darkBaseColor } from 'src/colors'
import JSZip from 'jszip'
import FileSaver from 'file-saver' // eslint-disable-line no-unused-vars
import moment from 'moment'
import { mimes } from 'src/config'
import { downloadBase64Encoded } from 'src/lib/download'
import { isInventory } from '../../../lib/validate'
import { paperTypeName } from 'src/lib/format'

const UploadButton = styled.button`
  position: absolute;
  right: 340px;
  bottom: 50px;
  width: 50px;
  height: 50px;
  background-color: ${props => (props.disabled ? gray : baseColor)};
  border: none;
  border-radius: 25px;
  color: white;
  font-size: 2em;

  &:hover {
    background-color: ${darkBaseColor};
  }
`

const DownloadButton = styled.button`
  position: absolute;
  right: 220px;
  bottom: 50px;
  width: 50px;
  height: 50px;
  background-color: ${props => (props.disabled ? gray : baseColor)};
  border: none;
  border-radius: 25px;
  color: white;
  font-size: 2em;

  &:hover {
    background-color: ${darkBaseColor};
  }
`
const DownloadAllButton = styled.button`
  position: absolute;
  right: 280px;
  bottom: 50px;
  width: 50px;
  height: 50px;
  background-color: ${props => (props.disabled ? gray : baseColor)};
  border: none;
  border-radius: 25px;
  color: white;
  font-size: 2em;

  &:hover {
    background-color: ${darkBaseColor};
  }
`

export class Lightbox extends React.PureComponent {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    env: PropTypes.object.isRequired,
    accessToken: PropTypes.string.isRequired,
    stickyId: PropTypes.number,
    orderId: PropTypes.string,
    position: PropTypes.string,
    apitype: PropTypes.string.isRequired,
    closeMe: PropTypes.func.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    stickyId: void 0,
    orderId: '',
    position: 'FULL', // 全画面表示
  }

  state = {
    images: [],
    displayIndex: 0,
    error: false,
    timerId: false,
    onUnmount: [],
    allImages: [],
    allLoaded: false,
    downLoading: false,
    uploading: false,
    isFileUploaderOpen: false,
  }

  /**
   * UNSAFE_componentWillReceiveProps
   * @param  {object} nextProps React props.
   * @return {void}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if ((this.props.isOpen === false && nextProps.isOpen) === true) {
      this.setState({
        images: null,
        displayIndex: 0,
        allImages: [],
        allLoaded: false,
        selectedCheckboxes: [],
      })
    }

    if (
      nextProps.stickyId &&
      (nextProps.stickyId !== this.props.stickyId ||
        nextProps.orderId !== this.props.orderId ||
        nextProps.apitype !== this.props.apitype)
    ) {
      // 新旧のAPIを切り替えて一覧を取得する
      if (nextProps.apitype === 'EXPO') {
        this.getImageListExpo(nextProps)
      } else if (isInventory(nextProps.apitype)) {
        this.getImageListInventory(nextProps)
      } else {
        this.getImageListOrg(nextProps)
      }
    }
  }

  /**
   * componentWillUnmount
   * @return {void}
   */
  componentWillUnmount() {
    this.state.onUnmount.map(cb => typeof cb === 'function' && cb())
    clearTimeout(this.state.timerId)
  }

  /**
   * 旧画像APIで画像一覧を取得
   * @param {*} nextProps
   */
  getImageListOrg = nextProps => {
    this.setState({
      error: false,
      onUnmount: [...this.state.onUnmount, cancel],
    })
    const { accessToken, env } = this.props
    const { stickyId } = nextProps

    const { promise, cancel } = makeCancelable(
      getImageList(stickyId, accessToken, env),
    )

    promise
      .then(res => {
        if (res.ok) {
          return res.json()
        } else {
          throw {
            error: new Error('画像一覧が取得できませんでした'),
            body: res.json(),
          }
        }
      })
      .then(({ data: { imageList } }) => {
        const images = imageList.map(({ id, category }) => ({ id, category }))
        return images
      })
      .then(images => this.setState({ images }))
      .catch(err => {
        console.log(err)
        this.setState({
          error: true,
          timerId: setTimeout(
            () => this.setState({ error: false }),
            config.messageResultDelay,
          ),
        })
      })
  }

  /**
   * 新画像APIで画像一覧を取得
   * @param {*} nextProps
   */
  getImageListExpo = nextProps => {
    this.setState({
      error: false,
      onUnmount: [...this.state.onUnmount, cancel],
    })
    const { accessToken, env } = this.props
    const { stickyId } = nextProps

    const { promise, cancel } = makeCancelable(
      expoGetImageList(stickyId, accessToken, env),
    )

    promise
      .then(res => {
        if (res.ok) {
          return res.json()
        } else {
          throw {
            error: new Error('画像一覧が取得できませんでした'),
            body: res.json(),
          }
        }
      })
      .then(data => {
        const images = data.imageList.map(({ id, category, originalTime }) => ({
          id,
          category,
          originalTime,
        }))
        return images
      })
      .then(images =>
        this.setState({ images, allImages: [], allLoaded: false }),
      )
      .catch(err => {
        console.log(err)
        this.setState({
          error: true,
          timerId: setTimeout(
            () => this.setState({ error: false }),
            config.messageResultDelay,
          ),
        })
      })
  }

  /**
   * 見積書・請求書・工事完了確認書・領収書PDFの一覧取得
   * @param {*} nextProps
   */
  getImageListInventory = nextProps => {
    this.setState({
      error: false,
      onUnmount: [...this.state.onUnmount, cancel],
    })
    const { accessToken, env } = this.props
    const { orderId } = nextProps
    const isQuotation = nextProps.apitype === 'QPDF'
    const isQuotationKeiri = nextProps.apitype === 'QKPDF'
    const isContract = nextProps.apitype === 'CPDF'
    const isContractKeiri = nextProps.apitype === 'CKPDF'
    const isWorkComp = nextProps.apitype === 'WPDF'
    const isReceipt = nextProps.apitype === 'RPDF'
    const isShipInventory = nextProps.apitype === 'SPDF'
    const isHLWorkComplete = nextProps.apitype === 'HLWorkCompletePDF'
    const isECWorkComplete = nextProps.apitype === 'ECWorkCompletePDF'
    const isESignAgreement = nextProps.apitype === 'ESignAgreement'

    let promise = null
    let cancel = null
    if (isQuotation || isQuotationKeiri) {
      ({ promise, cancel } = makeCancelable(
        listQuotationByOrderId(orderId, accessToken, env),
      ))
    } else if (isContract || isContractKeiri) {
      ({ promise, cancel } = makeCancelable(
        listContractByOrderId(orderId, accessToken, env),
      ))
    } else if (isWorkComp) {
      ({ promise, cancel } = makeCancelable(
        listWorkCompleteByOrderId(orderId, accessToken, env),
      ))
    } else if (isReceipt) {
      ({ promise, cancel } = makeCancelable(
        listReceiptByOrderId(orderId, accessToken, env),
      ))
    } else if (isShipInventory) {
      ({ promise, cancel } = makeCancelable(
        listShipInventoryByOrderId(orderId, accessToken, env),
      ))
    } else if (isHLWorkComplete) {
      ({ promise, cancel } = makeCancelable(
        listHLWorkCompleteByOrderId(orderId, accessToken, env),
      ))
    } else if (isECWorkComplete) {
      ({ promise, cancel } = makeCancelable(
        listECWorkCompleteByOrderId(orderId, accessToken, env),
      ))
    } else if (isESignAgreement) {
      ({ promise, cancel } = makeCancelable(
        listESignAgreementByOrderId(orderId, accessToken, env),
      ))
    } else {
      return
    }
    promise
      .then(res => {
        if (res.ok) {
          return res.json()
        } else {
          throw {
            error: new Error('PDF一覧が取得できませんでした'),
            body: res.json(),
          }
        }
      })
      .then(data => {
        const imageList =
          isQuotation || isQuotationKeiri
            ? data.data.listQuotationByOrderId
            : isContract || isContractKeiri
              ? data.data.listContractByOrderId
              : isWorkComp
                ? data.data.listWorkCompleteByOrderId
                : isReceipt
                  ? data.data.listReceiptByOrderId
                  : isShipInventory
                    ? data.data.listShipInventoryByOrderId
                    : isHLWorkComplete
                      ? data.data.listHLWorkCompleteByOrderId
                      : isECWorkComplete
                        ? data.data.listECWorkCompleteByOrderId
                        : data.data.listESignAgreementByOrderId
        // 新しい順にソートする
        imageList.sort(
          (a, b) => moment(b.uploadAt).unix() - moment(a.uploadAt).unix(),
        )
        const images = imageList.map(
          ({ id, uploadAt, documentStatus, toStaffId }) => ({
            id,
            category:
              isQuotation || isQuotationKeiri
                ? 'estimate'
                : isContract || isContractKeiri
                  ? 'contract'
                  : isWorkComp
                    ? 'workcomp'
                    : isReceipt
                      ? 'receipt'
                      : isShipInventory
                        ? 'shipInventory'
                        : isHLWorkComplete
                          ? 'HLWorkCompletePDF'
                          : isECWorkComplete
                            ? 'ECWorkCompletePDF'
                            : 'ESignAgreement',
            originalTime: uploadAt,
            documentStatus: documentStatus,
            toStaffId: toStaffId === 0, // 車載出庫PDFで0ならば削除可能
          }),
        )
        return images
      })
      .then(images =>
        this.setState({ images, allImages: [], allLoaded: false }),
      )
      .catch(err => {
        console.log(err)
        this.setState({
          error: true,
          timerId: setTimeout(
            () => this.setState({ error: false }),
            config.messageResultDelay,
          ),
        })
      })
  }

  onPrevClick = e => {
    this.preventClose(e)
    const displayIndex =
      this.state.displayIndex === 0
        ? this.state.images.length - 1
        : this.state.displayIndex - 1
    this.setState({ displayIndex })
  }

  onNextClick = e => {
    this.preventClose(e)
    const displayIndex =
      this.state.displayIndex === this.state.images.length - 1
        ? 0
        : this.state.displayIndex + 1
    this.setState({ displayIndex })
  }

  closeMe = e => {
    this.preventClose(e)
    // reset
    this.setState({ displayIndex: 0 })
    this.props.closeMe()
  }

  createDeleteHandler = index => () => {
    const nextImages = [...this.state.images]
    nextImages.splice(index, 1)
    const nextAllImages = [...this.state.allImages]
    nextAllImages.splice(index, 1)
    this.setState({
      images: nextImages,
      allImages: nextAllImages,
      displayIndex: Math.max(0, this.state.displayIndex - 1),
    })
  }

  /**
   * イメージの選択切り替え
   * @param {*} imgInfo
   */
  toggleCheckbox = imgInfo => {
    const existIndex = this.state.selectedCheckboxes.findIndex(
      v => v.imageId === imgInfo.imageId,
    )
    if (existIndex === -1) {
      const selectedCheckboxes = [...this.state.selectedCheckboxes]
      selectedCheckboxes.push(imgInfo)
      this.setState({ selectedCheckboxes })
    } else {
      const selectedCheckboxes = [...this.state.selectedCheckboxes]
      selectedCheckboxes.splice(existIndex, 1)
      this.setState({ selectedCheckboxes })
    }
  }

  /**
   * イメージの一括ダウンロード
   * @param {*} images
   */
  downloadImageList = images => {
    if (images.length === 0) {
      alert('画像を選択してください')
    } else {
      const mimeType = mimes.image[0]
      if (images.length === 1) {
        const name = paperTypeName(images[0].paperTypes, images[0].category)
        const ext = images[0].s3Filename.toLowerCase().endsWith('pdf')
          ? 'pdf'
          : 'jpg'
        const filename = `${name}_${images[0].imageId}.${ext}`
        downloadBase64Encoded(filename, mimeType, images[0].base64Image)
      } else {
        this.setState({ downLoading: true })
        const zip = new JSZip()
        images.forEach(checkbox => {
          const name = paperTypeName(checkbox.paperTypes, checkbox.category)
          const ext = checkbox.s3Filename.toLowerCase().endsWith('pdf')
            ? 'pdf'
            : 'jpg'
          const filename = `${name}_${checkbox.imageId}.${ext}`
          zip.file(filename, checkbox.base64Image, { base64: true })
        })
        const zipFileName = 'images_' + moment().format('YYYYMMDD')
        zip.generateAsync({ type: 'blob' }).then(content => {
          this.setState({ downLoading: false })
          saveAs(content, zipFileName)
        })
      }
    }
  }

  // 選択したイメージを一括ダウンロード
  downloadImage = () => this.downloadImageList(this.state.selectedCheckboxes)

  // 全てのイメージを一括ダウンロード
  downloadAllImage = () => {
    const { allLoaded } = this.state
    if (allLoaded) {
      this.downloadImageList(this.state.allImages)
    }
  }

  openUploadModal = () => this.setState({ isFileUploaderOpen: true })
  closeUploadModal = () => this.setState({ isFileUploaderOpen: false })

  // 車載出庫のPDFをアップロード
  uploadShipInventoryPdf = async base64Image => {
    const { accessToken, env, stickyId } = this.props
    const pattern = 'data:application/pdf;base64,'
    const result = base64Image.substring(pattern.length)
    try {
      this.setState({ uploading: true })
      const res = await uploadShipInventoryPDF(
        stickyId,
        result,
        accessToken,
        env,
      )
      this.getImageListInventory(this.props)
      return res
    } catch (error) {
      console.log('upload error:', error)
      throw new Error('PDFのアップロードに失敗しました')
    } finally {
      this.setState({ uploading: false })
    }
  }

  /**
   * 各イメージがダウンロードできた時のコールバック
   * @param {*} index イメージのインデックス
   * @param {*} imgInfo イメージの情報（toggleCheckboxの引数と同じもの）
   */
  addImages = (index, imgInfo) => {
    const { images, allImages } = this.state
    allImages[index] = imgInfo
    // 全イメージが読み込まれたか確認
    let allLoaded = false
    if (images && allImages.length === images.length) {
      for (let i = 0; i < allImages.length; i++) {
        const image = allImages[i] || {}
        if ((image.imageId || null) === null) {
          break
        }
        allLoaded = true
      }
    }
    this.setState({ allImages, allLoaded })
  }

  preventClose = e => e.stopPropagation()

  render() {
    const {
      error,
      displayIndex,
      images,
      allLoaded,
      downLoading,
      uploading,
      selectedCheckboxes,
      isFileUploaderOpen,
    } = this.state
    const { isOpen, position, apitype } = this.props
    // 写真表示画面の表示位置を切り替える
    const Screen = position === 'RIGHT' ? ScreenRight : ScreenFull

    return (
      <Screen isOpen={ isOpen } onClick={ this.preventClose }>
        {error && (
          <ValidationErrorMessage message={ '画像が取得できませんでした' } />
        )}
        {!error && images !== null && images.length === 0 && (
          <ValidationErrorMessage message={ '画像はありません' } />
        )}
        {!error && images === null && <Loading />}
        {!error &&
          images !== null &&
          images.length > 0 &&
          images.map((image, index) => {
            const isChecked =
              selectedCheckboxes.findIndex(v => v.imageId === image.id) !== -1
            return (
              <LazyImage
                key={ `${image.id}-${index}` }
                imageId={ image.id }
                display={ index === displayIndex }
                index={ index }
                totalCount={ images.length }
                deleteMe={ this.createDeleteHandler(index) }
                handleCheckboxChange={ this.toggleCheckbox }
                apitype={ this.props.apitype }
                addImages={ this.addImages }
                isChecked={ isChecked }
                originalTime={ image.originalTime || '' }
                documentStatus={ image.documentStatus }
                canDelete={ image.toStaffId }
              />
            )
          })}
        {/* <Label>{label}</Label>
        <LazyImage src={ url } /> */}
        <Console onClick={ this.preventClose }>
          {!error && images !== null && images.length !== 0 && (
            <Button onClick={ this.onPrevClick } prev>
              {'←'}
            </Button>
          )}
          {!error && images !== null && images.length !== 0 && (
            <Button onClick={ this.onNextClick } next>
              {'→'}
            </Button>
          )}
          <CloseButton onClick={ this.closeMe }>{'X'}</CloseButton>
        </Console>
        {apitype === 'SPDF' && (
          <UploadButton onClick={ this.openUploadModal } disabled={ uploading }>
            <i className="fa fa-upload" />
          </UploadButton>
        )}
        <DownloadButton onClick={ this.downloadImage } disabled={ downLoading }>
          <i className="fa fa-download" />
        </DownloadButton>
        <DownloadAllButton
          onClick={ this.downloadAllImage }
          disabled={ !allLoaded || downLoading }
        >
          <i className="fa fa-archive" />
        </DownloadAllButton>
        {isFileUploaderOpen && (
          <FileUploader
            title={ '車載出庫のアップロード' }
            upload={ this.uploadShipInventoryPdf }
            closeMe={ this.closeUploadModal }
            readerType={ 'base64' }
            isShipInventoryPDF
          />
        )}
      </Screen>
    )
  }
}

export const mapStateToProps = state => ({
  isOpen: state.lightbox.isOpen,
  position: state.lightbox.position,
  apitype: state.lightbox.apitype,
  env: state.env,
  accessToken: state.login.authentication.accessToken,
  stickyId: state.lightbox.stickyId,
  orderId: state.lightbox.orderId,
})

export const mapDispatchToProps = dispatch => ({
  closeMe: () => dispatch(createLightboxActions.close()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Lightbox)
