import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#1b95bf',
    },
  },
  zIndex: {
    modal: 100000,
    popover: 100000,
  },
})

export default theme
