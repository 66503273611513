import React from 'react'
import PropTypes from 'prop-types'
import connect from '../../connect'

/**
 * テキスト入力共通コンポーネント
 * @type {ReactComponent}
 */
export const TextAreaInput = ({
  label,
  value,
  placeholder,
  name,
  onChange,
  route,
  disabled,
}) => {
  const groupName = `houselabo-${name}-${route}`
  const identifier = `id-${groupName}`

  return (
    <>
      <dt style={ { width: '110px' } }>
        <label htmlFor={ identifier }>{label}</label>
      </dt>
      <dd className={ 'input-wrap' }>
        <span className={ 'label-inputs-wrap free-describe-wrap' }>
          <textarea
            className={ 'long' }
            value={ value }
            placeholder={ placeholder }
            id={ identifier }
            name={ groupName }
            onChange={ onChange }
            disabled={ disabled }
          />
        </span>
      </dd>
    </>
  )
}

/**
 * propTypes
 * @type {object}
 */
TextAreaInput.propTypes = {
  label: PropTypes.string.isRequired, // 見出し
  value: PropTypes.string.isRequired, // 表示する値
  placeholder: PropTypes.string.isRequired, // プレースホルダ
  name: PropTypes.string.isRequired, // 項目名
  onChange: PropTypes.func.isRequired, // 入力があった時のコールバック
  route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
  disabled: PropTypes.bool.isRequired,
}

/**
 * defaultProps
 */
TextAreaInput.defaultProps = {
  searchButton: false,
  onSearchClick: null,
  wide: false,
}

export default connect(React.memo(TextAreaInput))
