import React from 'react'
import PropTypes from 'prop-types'
import connect from '../../connect'
import TimeAutocomplete from 'src/components/commons/time-autocomplete'
import { Box, Typography } from '@mui/material'

/**
 * 時刻入力共通コンポーネント
 * @type {ReactComponent}
 */
export const TimeInput = ({
  label,
  value,
  name,
  onChange,
  route,
  disabled,
}) => {
  /**
   * 分が10分単位であることを追加チェックする
   * @param {*} time HH:MM に正規化された時刻
   */
  const timeValidation = time => {
    const target = time || ''
    if (target === '') {
      return true
    }
    const x = target.split(':')
    return /[0-5]0/.test(x[1])
  }

  const groupName = `houselabo-${name}-${route}`
  const identifier = `id-${groupName}`

  return (
    <Box
      sx={ {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, max-content)',
        alignItems: 'center',
        columnGap: '0.5rem',
      } }
    >
      <Typography
        htmlFor={ identifier }
        variant="subtitle1"
        sx={ { fontWeight: 'bold' } }
      >
        {label}
      </Typography>
      <div className={ 'autocomplete-wrap time-picker' }>
        <TimeAutocomplete
          inputId={ identifier }
          value={ value } // 'HH:mm'
          onValidateSuccess={ onChange }
          disabled={ disabled }
          nextId={ '' }
          interval={ 10 }
          addValidate={ timeValidation }
        />
      </div>
    </Box>
  )
}

/**
 * propTypes
 * @type {object}
 */
TimeInput.propTypes = {
  label: PropTypes.string.isRequired, // 見出し
  value: PropTypes.string.isRequired, // 表示する値
  name: PropTypes.string.isRequired, // 項目名
  onChange: PropTypes.func.isRequired, // 入力があった時のコールバック
  route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default connect(React.memo(TimeInput))
