import { DragSource as DnDDragSource } from 'react-dnd'

const cardSource = {
  beginDrag: props => props,
}

export const DragSource = Component =>
  DnDDragSource('name-card', cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }))(Component)
