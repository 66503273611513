import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import connect from '../connect'
import config from 'src/config'
import Sticky from 'src/lib/class-sticky'
import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material'
import theme from 'src/theme.js'
import { ThemeProvider } from '@mui/material/styles'


/**
 * リフォーム引継の社員選択
 * @type {ReactComponent}
 */
const ReformStaffSelect = ({ sticky, disabled, master, updateStage }) => {
  const stickyProps = sticky.json() || {}
  const [finishStateId, setFinishStateId] = useState(stickyProps.finishStateId || -1)
  const [selectedUserId, setSelectedUserId] = useState(stickyProps.reformUserId || '')

  useEffect(() => {
    setFinishStateId(stickyProps.finishStateId || -1)
  }, [stickyProps.finishStateId])

  /**
   * on change handler
   * @param  {Event} e event
   * @return {void}
   */
  const onChange = e => {
    const val = e.target.value
    setSelectedUserId(val)
    updateStage(new Sticky({ reformUserId: val }))
  }

  const required = finishStateId === config.finishState.na ? 'is-required' : ''

  const reformUsers = master.allUsers.filter(user => user.roles.includes('reform'))

  return (
    <ThemeProvider theme={ theme }>
      <Box
        sx={ {
          alignItems: 'center',
          columnGap: '1.5rem',
          display: 'grid',
          gridTemplateColumns: 'repeat(2, max-content)',
          marginInlineStart: '7.5rem',
        } }
      >
        <Box
          sx={ {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, max-content)',
            alignItems: 'center',
            columnGap: '0.25rem',
          } }
        >
          <Typography sx={ { fontWeight: 'bold' } } variant="subtitle1" >
            {'案件表示ユーザ'}
          </Typography>
          {required && <Typography
            variant="h5"
            sx={ {
              fontWeight: 'bold',
              color: '#ff4f3d',
              marginLeft: '0.25rem',
            } }
          >{'*'}</Typography>}
        </Box>
        <FormControl
          color="primary"
          disabled={ disabled }
          fullWidth
          size="small"
          sx={ {
            background: '#fff',
            '.MuiOutlinedInput-root': {
              alignItems: 'center',
              height: '28px',
              minWidth: '12rem',
              '.MuiSelect-select': {
                padding: '0px 14px',
              },
            },
          } }
        >
          <Select
            id={ 'reform-user-select' }
            MenuProps={ { style: { zIndex: 10000 } } } // TODO: 付箋詳細モーダルで表示するにはこの数値が必要
            onChange={ onChange }
            value={ selectedUserId }
          >
            {reformUsers.map(user => (
              <MenuItem key={ user.id } value={ user.id }>{user.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </ThemeProvider>
  )
}

ReformStaffSelect.propTypes = {
  sticky: PropTypes.instanceOf(Sticky).isRequired,
  disabled: PropTypes.bool.isRequired,
  master: PropTypes.shape({
    allUsers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        nickname: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  updateStage: PropTypes.func.isRequired,
}

export default connect(ReformStaffSelect)
