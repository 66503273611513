import styled from 'styled-components'
import button, { buttonLine } from 'src/styled/button'
import { baseColor } from 'src/colors'

export const Flex = styled.div`
  display: flex;
  justify-content: space-around;
`

export const Button = styled.button`
  ${button};
  ${buttonLine(baseColor)};
  margin-top: 5px;
`

export const SelectButton = styled.button`
  ${button};
  ${buttonLine(baseColor)};
  margin: 0;
  line-height: initial;
  font-size: 1rem;
`
