// React
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from './connect'

// コンポーネント
import NotFound from './partials/not-found'
import StickyAbbr from './partials/sticky-abbr'
import StickyHeader from './partials/sticky-row/sticky-header'
import StickyRow from './partials/sticky-row'
import SearchResultsModal from './partials/modal'
import { StickyLoading, Screen } from './styled'

// ライブラリ
import createClassNames from 'classnames'
import noop from 'src/lib/noop'
import Sticky from 'src/lib/class-sticky'
import requestGetSticky from 'src/lib/sticky-api/get'

/**
 * React components
 * @type {object}
 */
export class SearchResults extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    closeMe: PropTypes.func,
    className: PropTypes.string,
    route: PropTypes.string.isRequired,
    result: PropTypes.shape({
      stickies: PropTypes.arrayOf(PropTypes.instanceOf(Sticky)).isRequired,
      esFullCount: PropTypes.number.isRequired,
      fullCount: PropTypes.number.isRequired,
      hlFullCount: PropTypes.number.isRequired,
    }).isRequired,
    status: PropTypes.oneOf(['not_yet', 'request', 'success', 'failure'])
      .isRequired,
    renderPagenation: PropTypes.func.isRequired,
    isDetailedSearch: PropTypes.bool,
    listView: PropTypes.bool, // 横長表示するときtrue
    page: PropTypes.number.isRequired,
    // stateProps
    stageSticky: PropTypes.func.isRequired,
    accessToken: PropTypes.string.isRequired,
    env: PropTypes.object.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    closeMe: noop,
    className: '',
    isDetailedSearch: false,
    callback: noop,
    listView: false,
  }

  state = { isOpen: false, sticky: void 0 }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.result !== nextProps.result ||
      this.state !== nextState ||
      this.props.renderPagenation !== nextProps.renderPagenation ||
      this.props.page !== nextProps.page
    )
  }

  /**
   * ある付箋についてモーダルを開く
   * @param  {Sticky} sticky [description]
   * @return {void}          [description]
   */
  openModal = sticky => {
    const { accessToken, env } = this.props
    // 付箋の詳細情報を取得して表示する。
    requestGetSticky(sticky._props.id, accessToken, env)
      .then(rsticky => this.setState({ isOpen: true, sticky: rsticky }))
      .catch(err => console.error(err))
  }

  /**
   * モーダルを閉じる
   * @return {void} [description]
   */
  closeModal = () => this.setState({ isOpen: false, sticky: false })

  // コール課以外で編集中の付箋を置き換える
  stageStickyFromHistory = sticky => {
    this.setState({ sticky })
  }

  renderContent = () => {
    const { isOpen, sticky } = this.state
    const {
      status,
      result: { stickies = [], esFullCount, hlFullCount } = { stickies: [] },
      closeMe,
      route,
      stageSticky,
      listView,
    } = this.props

    const hasES = (esFullCount || 0) > 0
    const hasHL = (hlFullCount || 0) > 0

    const isHidden = status === 'not_yet'
    const isLoading = status === 'request'
    const isFailed = status === 'failure'

    const className =
      createClassNames({
        'quick-search-results': true,
        'has-result': stickies.length > 0,
        'result-hidden': isHidden,
      }) +
      ' ' +
      this.props.className

    return (
      <div className={ className }>
        {listView ? (
          <div className={ 'quick-search-results-inner-list' }>
            {this.props.renderPagenation()}
            {isLoading ? (
              <StickyLoading />
            ) : stickies.length > 0 ? (
              <table className="sticky-table">
                <thead>
                  <StickyHeader />
                </thead>
                <tbody>
                  {stickies.map((sticky, index) => (
                    <StickyRow
                      key={ sticky.id }
                      sticky={ sticky }
                      onSelect={ this.openModal }
                      index={ index }
                    />
                  ))}
                </tbody>
              </table>
            ) : (
              <NotFound isFailed={ isFailed } />
            )}
          </div>
        ) : (
          <div className={ 'quick-search-results-inner' }>
            {this.props.renderPagenation()}
            {isLoading ? (
              <StickyLoading />
            ) : stickies.length > 0 ? (
              stickies.map((sticky, index) => (
                <StickyAbbr
                  key={ sticky.id }
                  sticky={ sticky }
                  onSelect={ this.openModal }
                  index={ index }
                />
              ))
            ) : (
              <NotFound isFailed={ isFailed } />
            )}
          </div>
        )}
        {isOpen && (
          <SearchResultsModal
            isOpen={ isOpen }
            sticky={ sticky }
            closeMe={ this.closeModal }
            closeResult={ closeMe }
            parentRoute={ route }
            createSticky
            hasES={ hasES }
            hasHL={ hasHL }
            stageStickyFromHistory={
              route === 'call' ? stageSticky : this.stageStickyFromHistory
            }
          />
        )}

        {typeof closeMe === 'function' && (
          <button
            className={ 'close-mark button-search-result' }
            onClick={ closeMe }
          />
        )}
      </div>
    )
  }

  /**
   * render
   * @return {ReactDOM} rendering ReactDOM
   */
  render() {
    const { isDetailedSearch } = this.props

    return isDetailedSearch ? (
      this.renderContent()
    ) : (
      <Screen>{this.renderContent()}</Screen>
    )
  }
}

export default connect(SearchResults)
