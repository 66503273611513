import utils from 'src/lib/api-utils'
import { CALL_API } from 'redux-api-middleware'
import { NETWORKER } from 'src/middlewares/network-monitor'

import switz from 'switz'
import { ACTION_TYPES as NETWORK_ACTION_TYPES } from 'src/reducers/network-status'

export const POST_EXCHANGE_STICKIES_REQUEST = 'POST_EXCHANGE_STICKIES_REQUEST'
export const POST_EXCHANGE_STICKIES_SUCCESS = 'POST_EXCHANGE_STICKIES_SUCCESS'
export const POST_EXCHANGE_STICKIES_FAILURE = 'POST_EXCHANGE_STICKIES_FAILURE'

/**
 * action type name bulk export
 * @type {object}
 */
export const ACTION_TYPES = {
  POST_EXCHANGE_STICKIES_REQUEST,
  POST_EXCHANGE_STICKIES_SUCCESS,
  POST_EXCHANGE_STICKIES_FAILURE,
}

/**
 * [creator description]
 * @param  {Array<number>} order [description]
 * @param  {string} accessToken アクセストークン
 * @return {object} redux-api-middleware に渡す非同期処理情報を内包したアクション
 */
export const creator = (order, accessToken, env) => {
  const endpoint = `${utils.createEndpoint(env, 'exchangeStickies')}`
  const body = JSON.stringify(order)

  // eslint-disable-next-line no-console
  console.log('exchange-stickies', endpoint, order, body)

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body,
      types: [
        {
          type: POST_EXCHANGE_STICKIES_REQUEST,
          meta: {
            [NETWORKER]: {
              target: 'exchangeStickies',
              networkActionType: NETWORK_ACTION_TYPES.REQUEST,
            },
          },
        },
        {
          type: POST_EXCHANGE_STICKIES_SUCCESS,
          meta: {
            [NETWORKER]: {
              target: 'exchangeStickies',
              networkActionType: NETWORK_ACTION_TYPES.SUCCESS,
            },
          },
        },
        {
          type: POST_EXCHANGE_STICKIES_FAILURE,
          meta: {
            [NETWORKER]: {
              target: 'exchangeStickies',
              networkActionType: NETWORK_ACTION_TYPES.FAILURE,
            },
          },
        },
      ],
    },
  }
}

/**
 * [AsyncWaraReducers description]
 * @param  {object} state   state
 * @param  {object} action action
 * @return {object}         reducer
 */
export const partialReducer = (state, action) => {
  const { type } = action

  return switz(type, s =>
    s
      // これらのリクエストの成否はネットワーク状態を更新するだけ。ネットワークの状態更新はmiddlewareで行う
      .case(POST_EXCHANGE_STICKIES_REQUEST, () => state)
      .case(POST_EXCHANGE_STICKIES_SUCCESS, () => state)
      .case(POST_EXCHANGE_STICKIES_FAILURE, () => state)
      .default(() => state),
  )
}
