import update from 'immutability-helper'

/**
 * タイプ定義
 * @type {string}
 */
const type = 'WARA.UPDATE_SERVICESTAFF'

/**
 * exportation
 */
export default {
  /**
   * action type
   * @type {string}
   */
  type,

  /**
   * action creator
   * @param {{ regionIndex: number, listIndex: numvber }} location 更新するssが所属するregionとlistのIndexです
   * @param  {object}  ssProps     更新するサービススタッフのパラメータです
   * @param {bool} serialize シリアライズするかどうか
   * @return {object} [description]
   */
  creator: (location, ssProps, serialize) => {
    return {
      type,
      payload: { location, ssProps },
      meta: { serialize },
    }
  },

  /**
   * [handler description]
   * @param  {object}  state   [description]
   * @param  {{payload:{location:object, ssProps:object}}} action  [description]
   * @return {object}          [description]
   */
  handler: (state, { payload: { location, ssProps: _ssProps } }) => {
    const ssProps = { ..._ssProps }
    delete ssProps.id

    const { regionIndex, listIndex } = location

    const oldSSProps =
      state.data.regions[regionIndex].lists[listIndex].dailyInfo || {}

    const newSSProps = {
      ...oldSSProps,
      ...ssProps,
    }

    return update(state, {
      data: {
        regions: {
          [regionIndex]: {
            lists: {
              [listIndex]: {
                dailyInfo: { $set: newSSProps },
              },
            },
          },
        },
      },
    })
  },
}
