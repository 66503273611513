import { connect } from 'react-redux'
import { ACTION_TYPES as WRAPPERD_CALENDER_ACTION_TYPES } from 'src/reducers/wrapped-calendar'

const mapStateToProps = state => ({
  isOpen: id => !!state.wrappedCalendar.isOpen[id],
})

/**
 * map dispatch to props
 * @param  {function} dispatch dispatcher
 * @return {object}            mapped auto bind action creators
 */
const mapDispatchToProps = dispatch => ({
  /**
   * 全部のカレンダーを閉じる
   * @return {void}
   */
  closeAll: () => dispatch({ type: WRAPPERD_CALENDER_ACTION_TYPES.CLOSE_ALL }),

  /**
   * 特定のカレンダーを開ける
   * @param  {string} id   カレンダー識別子
   * @param  {boolean} open 開閉
   * @return {void}
   */
  toggle: (id, open) =>
    dispatch({
      type: WRAPPERD_CALENDER_ACTION_TYPES.UPDATE,
      payload: { id, open },
    }),

  /**
   * カレンダーを登録除外する
   * @param  {string} id カレンダー識別子
   * @return {void}
   */
  unregister: id =>
    dispatch({
      type: WRAPPERD_CALENDER_ACTION_TYPES.UNREGISTER,
      payload: { id },
    }),
})

/**
 * カレンダーピッカーをトグルボタン、表示ラベルテキストと一緒に包んだもの
 * @class {ReactComponent}
 */
export default WrappedCalendar =>
  connect(mapStateToProps, mapDispatchToProps)(WrappedCalendar)
