import { connect as _connect } from 'react-redux'

// action creators
import { actionCreators as $ } from 'src/reducers/wara'
import { actionCreators as createPaymentsAction } from 'src/reducers/payments'
import { actionCreators as createMarucActions } from 'src/reducers/maruc'
import { actionCreators as createMarucRefundActions } from 'src/reducers/maruc-refund'

// libs
import getPayments from 'src/lib/payments-api/get'

/**
 map state to props
 * @param  {object} state state
 * @return {object}      mapped states
 */
const mapStateToProps = state => {
  return {
    wara: state.wara.data,
    staged: state.wara.modal.stage,
    accessToken: state.login.authentication.accessToken,
    displayDate: state.displayDate.displayDate,
    isDebugMode: state.env.DEBUG,
    env: state.env,
    storedPayments: state.payments.store,
    __payments: state.payments.data,
    master: state.master.data,
    marucRefund: state.marucRefund,
  }
}

/**
 * map dispatch to props
 * @param  {function} dispatch dispatcher
 * @param  {object}    props    Props
 * @return {object}            mapped auto bind action creators
 */
const mapDispatchToProps = dispatch => {
  return {
    addSticky: (location, sticky) => dispatch($.addSticky(location, sticky)),
    /**
     * 付箋の入力内容のコミットを実行
     * @param  {object} location 付箋のロケーション
     * @return {void}
     */
    commitSticky: location => dispatch($.commitSticky(location)),

    /**
     * 直接更新
     * @param  {Sticky} sticky   [description]
     * @param  {Location} location [description]
     * @return {void}          [description]
     */
    updateSticky: (sticky, location) =>
      dispatch($.updateSticky(sticky, location)),

    /**
     * 付箋の更新
     * @param  {Sticky} sticky   [description]
     * @return {void}          [description]
     */
    updateStickyById: sticky => dispatch($.updateStickyById(sticky)),

    /**
     * 付箋を削除する
     * @param  {object} location 削除する付箋のロケーション
     * @return {void}
     */
    deleteSticky: location =>
      // TODO: ここは、結局決めうちになってしまった
      // UIのみのデータを更新するdispatchに変更できる
      dispatch($.deleteSticky(location, true)),

    /**
     * IDを指定して付箋を削除する
     * @param  {number} stickyId [description]
     * @return {void}            [description]
     */
    deleteStickyById: stickyId => dispatch($.deleteStickyById(stickyId)),

    /**
     * 編集中の付箋をstickyで置き換える。
     * locationは必要かも。
     * @param  {object} sticky  新しい編集用の付箋
     * @param  {object} staging 差分データ
     * @return {void}            [description]
     */
    updateEditSticky: (sticky, staging) => {
      dispatch($.openModal(null, { location: null, sticky }, staging))
    },

    /**
     * Assign sticky
     * @param  {object} locationSrc
     * @param  {object} locationDest
     * @param  {object} displayDate
     * @return {void}
     */
    assignSticky: (locationSrc, locationDest, displayDate) => {
      dispatch($.assignSticky(locationSrc, locationDest, displayDate))
    },

    /**
     * モーダルを閉じる
     * @return {void}
     */
    closeModal: () => dispatch($.closeModal()),

    resetComplaint: () => dispatch(createMarucActions.reset()),

    storePayments: (stickyId, allPayments) =>
      dispatch(createPaymentsAction.store(stickyId, allPayments)),

    __getPayments: (accessToken, env) => stickyContactIds =>
      new Promise(resolve =>
        getPayments(stickyContactIds, accessToken, env).then(payments => {
          dispatch(createPaymentsAction.updatePayments(payments))
          resolve(payments)
        }),
      ),
    setMarucRefund: refund =>
      dispatch(createMarucRefundActions.setMarucRefund(refund)),
  }
}

/**
 * map state to props
 * @param  {object} stateProps    state props
 * @param  {object} dispatchProps dispatch props
 * @param  {object} ownProps      own props
 * @return {object}               props
 */
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { accessToken, env } = stateProps

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    // merge
    getPayments: dispatchProps.__getPayments(accessToken, env),
    // make private
    __getPayments: void 0,
  }
}

export const connect = Component =>
  _connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component)

export default connect
