import config from 'src/config'
import { normalizeZip } from 'src/lib/format'
import { toHalf } from 'src/lib/to-half'

export const MESSAGES = {
  NO_ORDER_TYPE_ID: '「注文タイプ」を選択してください',
  NO_COMPANY_ID: '「受注会社」を選択してください',
  NO_PERSONAL_TYPE_CODE: '「受電タイプ」を入力してください',
  NO_MEDIUM_ID: '「受電」を入力してください',
  NO_PREFCODE: '「居住者」の「都道府県」を入力してください',
  AT_LEAST_ONE_PHONE_NUMBER: '少なくとも1つの「電話番号」を入力してください',
  NO_BUILDING_TYPE: '「建物情報」を入力してください',
  NO_CUSTOMER_TYPE_ID: '「顧客タイプ」を入力してください',
  NO_WORK_POINT: '「作業箇所」を入力してください',
  NO_WORK_TYPE: '「現況」を入力してください',
  NO_WISH_DATE_TIMES: '「対応希望日」を入力してください',
  NO_REGION_ID: '「地域」を選択してください',
  INVALID_PHONE_NUMBER: '「電話番号」は数字とハイフンのみで入力してください',
  INVALID_ZIP: '「郵便番号」は数字とハイフンのみで入力してください',
  HAS_CANCEL_FILLED: '「キャンセル」の設定が正しくありません',
  NO_FINISH_WITH_AFTER: '「完了」「キャンセル」のときはアフタは設定できません',
  NO_LIVING_CONTACT: '少なくとも「居住者」を1名入力してください',
  HL_NO_PAYMENT_PLUS: '請求状態を「未」にして請求書支払日を設定してください。',
  // HL_NO_CLIENT: 'ハウスラボのクライアントを設定してください',
}

/**
 * [isValid description]
 * @param  {[type]}  props  [description]
 * @param  {[type]}  client [description]
 * @return {result:boolean, messages:string[]}        [description]
 */
export const isValid = props => {
  const messages = []
  const isHouseLabo = props.companyId === config.company.HL // ハウスラボ
  const isSV = props.companyId === config.company.SV // ES提携
  const houseLabo = props.houseLabo || {}
  const houseLaboClientMaster = props.houseLaboClientMaster || {}
  const invoice = houseLabo.invoice || 0 // クライアント請求額
  const claimStatus = houseLabo.claimStatus || false // 請求状態
  const claimPaymentDate = houseLabo.claimPaymentDate || '' // 請求書支払日
  const exportTypeId = houseLaboClientMaster.exportTypeId || 0 // クライアントの請求書タイプ

  const isNa = props.finishStateId === 99 // 終了状態が除外対象を選択

  // 注文タイプを入力している
  // const hasValidOrderTypeId = !!(props.orderTypeId && props.orderTypeId !== -1)
  // hasValidOrderTypeId || messages.push(MESSAGES.NO_ORDER_TYPE_ID)

  // 受注会社を入力している
  // const hasValidCompanyId = !!(props.companyId && props.companyId !== -1)
  // hasValidCompanyId || messages.push(MESSAGES.NO_COMPANY_ID)

  // 受電タイプのチェックをしない(2019/11/29 コール課要望: 797)
  // const hasPersonalTypeCode = true

  // 受電媒体を入力している
  // const hasValidMediumId = !!(props.mediumId && props.mediumId !== -1)
  // hasValidMediumId || messages.push(MESSAGES.NO_MEDIUM_ID)

  // // ハウスラボの時はクライアントが検索して設定されているかチェック
  // const houseLaboClientMaster = props.houseLaboClientMaster || {}
  // const hlClientId = houseLaboClientMaster.id || null
  // const hasHlValidCilent = isHouseLabo ? hlClientId !== null : true
  // hasHlValidCilent || messages.push(MESSAGES.HL_NO_CLIENT)
  // const hasHlValidCilent = true // クライアントのチェックをしない。

  // 居住者が少なくとも1名設定されている
  const contacts = props.contacts || []
  const contacts_0 = contacts[0] || []
  const atLeastOneLiving = isNa || contacts_0.length >= 1
  if (!isNa) {
    atLeastOneLiving || messages.push(MESSAGES.NO_LIVING_CONTACT)
  }

  // 有効な居住者の連絡先を入力している。削除、種別変更で移動されたものは対象外
  const hasValidClientContactProp =
    isNa ||
    contacts_0.reduce(
      (prev, contact) =>
        prev && (contact.deleted || contact.moved || !!contact.prefCode),
      true,
    )
  if (!isNa) {
    hasValidClientContactProp || messages.push(MESSAGES.NO_PREFCODE)
  }

  // // 少なくとも一つは電話番号を入力している
  // // ハウスラボとES提携の時は電話番号の必須チェックを行わない
  // const hasAtLeastOnePhoneNumber =
  //   isHouseLabo ||
  //   isSV ||
  //   isNa ||
  //   (props.contacts || [])
  //     .flat()
  //     .filter(x => !!x)
  //     .some(contact => !!contact.phoneNumber)
  // hasAtLeastOnePhoneNumber || messages.push(MESSAGES.AT_LEAST_ONE_PHONE_NUMBER)

  /**
   * contactの電話番号、第2電話番号、第3電話番号が数字とハイフンで構成されているかチェックする。
   * @param {*} contact
   */
  const validContactPhone = contact => {
    const patPhone = /^[\d-]*$/
    return (
      patPhone.test((contact || {}).phoneNumber || '') &&
      patPhone.test((contact || {}).phoneNumber1 || '') &&
      patPhone.test((contact || {}).phoneNumber2 || '')
    )
  }

  // /[0-9-]+/ にマッチしない電話番号がある
  const hasValidPhoneNumber = (props.contacts || [])
    .filter(x => !!x)
    .reduce((prev, tabContacts) => [...prev, ...tabContacts], [])
    .reduce((prev, contact) => prev && validContactPhone(contact), true)
  hasValidPhoneNumber || messages.push(MESSAGES.INVALID_PHONE_NUMBER)

  /**
   * contactの郵便番号が数字とハイフンで構成されているかチェックする。
   * @param {*} contact
   */
  const validZip = contact => {
    const zip = (contact || {}).zip || ''
    const formattedZip = normalizeZip(toHalf(zip) || '')
    return /^(\d{3}-?\d{4}|)$/.test(formattedZip) || formattedZip === ''
  }

  /**
   * 不正な郵便番号があるかチェックする。
   */
  const hasValidZip = (props.contacts || [])
    .filter(x => !!x)
    .reduce((prev, tabContacts) => [...prev, ...tabContacts], [])
    .reduce((prev, contact) => prev && validZip(contact), true)
  hasValidZip || messages.push(MESSAGES.INVALID_ZIP)

  // // 建物情報を入力している
  // const hasBuildingType =
  //   isNa ||
  //   (props.buildingType &&
  //     Array.isArray(props.buildingType.ids) &&
  //     props.buildingType.ids.length > 0)
  // if (!isNa) {
  //   hasBuildingType || messages.push(MESSAGES.NO_BUILDING_TYPE)
  // }

  // // 顧客タイプが入力されている
  // const hasCustomerTypeId =
  //   isNa || (props.customerTypeId && props.customerTypeId !== -1)
  // if (!isNa) {
  //   hasCustomerTypeId || messages.push(MESSAGES.NO_CUSTOMER_TYPE_ID)
  // }

  // // 作業箇所が入力されている
  // const hasWorkPoint =
  //   isNa ||
  //   (props.workPoint &&
  //     Array.isArray(props.workPoint.ids) &&
  //     props.workPoint.ids.length > 0)
  // if (!isNa) {
  //   hasWorkPoint || messages.push(MESSAGES.NO_WORK_POINT)
  // }

  // // 現況が入力されている
  // const hasWorkType =
  //   isNa ||
  //   (props.workType &&
  //     Array.isArray(props.workType.ids) &&
  //     props.workType.ids.length > 0)
  // if (!isNa) {
  //   hasWorkType || messages.push(MESSAGES.NO_WORK_TYPE)
  // }

  // // 対応希望日時を聞き取りしている。マルシーの場合は対応希望日不要とする。
  // const hasValidWishDateTimes =
  //   !!(
  //     props.wishDateTimes &&
  //     ((props.wishDateTimes[0] && !!props.wishDateTimes[0].date) ||
  //       (props.wishDateTimes[1] && !!props.wishDateTimes[1].date) ||
  //       (props.wishDateTimes[2] && !!props.wishDateTimes[2].date))
  //   ) || props.orderTypeId === config.orderType.claimer
  // hasValidWishDateTimes || messages.push(MESSAGES.NO_WISH_DATE_TIMES)

  // // 地域が選択されている
  // const hasValidRegionId = isNa || (props.regionId && props.regionId !== -1)
  // if (!isNa) {
  //   hasValidRegionId || messages.push(MESSAGES.NO_REGION_ID)
  // }

  // // キャンセル理由が設定されているか
  // const { finishStateId, cancelReasonId } = props
  // const hasCancelFilled =
  //   // キャンセルではないか、
  //   finishStateId !== config.finishState.cancel ||
  //   // キャンセルが選択されていて
  //   (finishStateId === config.finishState.cancel &&
  //     // 有効なキャンセル理由が設定されている
  //     cancelReasonId &&
  //     cancelReasonId !== -1 &&
  //     cancelReasonId !== null)
  // hasCancelFilled || messages.push(MESSAGES.HAS_CANCEL_FILLED)

  // // 「完了」または「キャンセル」にアフタの日付を設定できない。
  // const afterDateTime = props.afterDateTime || {}
  // const noFinishWithAfter = !(
  //   (finishStateId === config.finishState.complete ||
  //     finishStateId === config.finishState.cancel) &&
  //   (afterDateTime.date || '') !== ''
  // )
  // noFinishWithAfter || messages.push(MESSAGES.NO_FINISH_WITH_AFTER)

  // let isValidClaimPaymentDate = true
  // if (isHouseLabo) {
  //   // 請求書タイプ=なし && invoice !== 0 && claimPaymentDate === '' で、
  //   // 請求書状態が「済」の時「支払日が指定されていません」エラーとする
  //   if (
  //     exportTypeId === config.houseLaboExportType.none &&
  //     invoice !== 0 &&
  //     claimPaymentDate === '' &&
  //     claimStatus
  //   ) {
  //     isValidClaimPaymentDate = false
  //     messages.push(MESSAGES.HL_NO_PAYMENT_PLUS)
  //   }
  // }

  const result =
    // hasValidOrderTypeId &&
    // hasValidCompanyId &&
    // hasPersonalTypeCode &&
    // hasValidMediumId &&
    // hasHlValidCilent &&
    atLeastOneLiving &&
    hasValidClientContactProp &&
    // hasAtLeastOnePhoneNumber &&
    // hasBuildingType &&
    // hasCustomerTypeId &&
    // hasWorkPoint &&
    // hasWorkType &&
    // hasValidWishDateTimes &&
    // hasValidRegionId &&
    hasValidPhoneNumber &&
    hasValidZip
  // hasCancelFilled &&
  // noFinishWithAfter &&
  // isValidClaimPaymentDate

  return { result, messages }
}

export default isValid
