export const downloadAsBlob = (filename, mimeType, content) => {
  const bom = new Uint8Array([0xef, 0xbb, 0xbf])
  const blob = new Blob([bom, content], { type: mimeType })

  const a = document.createElement('a')
  a.download = filename
  a.target = '_blank'

  if (window.URL && window.URL.createObjectURL) {
    // for Firefox
    a.href = window.URL.createObjectURL(blob)
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  } else if (window.webkitURL && window.webkitURL.createObject) {
    // for Chrome
    a.href = window.webkitURL.createObjectURL(blob)
    a.click()
  } else {
    // for Safari
    window.open(
      'data:' + mimeType + ';base64,' + window.Base64.encode(content),
      '_blank',
    )
  }
}

export const downloadBase64Encoded = (filename, mimeType, content) => {
  const a = document.createElement('a')
  a.download = filename
  a.target = '_blank'

  if (window.URL && window.URL.createObjectURL) {
    // for Firefox
    a.href = 'data:' + mimeType + ';base64,' + content
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  } else if (window.webkitURL && window.webkitURL.createObject) {
    // for Chrome
    a.href = window.webkitURL.createObjectURL(
      'data:' + mimeType + ';base64,' + content,
    )
    a.click()
  } else {
    // for Safari
    window.open('data:' + mimeType + ';base64,' + content, '_blank')
  }
}
