import React from 'react'
import Modal from 'react-modal'

import { DetailedSearchButton } from './styled'
import DetailedSearchForms from './partials/detailed-search-forms'

export class DetailedSearch extends React.Component {
  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    this.state = { isOpen: this.props.search } // URLでsearchが指定された時は詳細検索を開く
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps, nextState) {
    return this.state.isOpen !== nextState.isOpen
  }

  closeMe = () => this.setState({ isOpen: false })
  openMe = () => this.setState({ isOpen: true })

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { isOpen } = this.state
    return (
      <span>
        <DetailedSearchButton onClick={ this.openMe }>
          <i className={ 'fa fa-caret-right' } />
          {' 詳細検索'}
        </DetailedSearchButton>
        <Modal isOpen={ isOpen } ariaHideApp={ false }>
          <DetailedSearchForms { ...this.props } closeMe={ this.closeMe } />
        </Modal>
      </span>
    )
  }
}

export default DetailedSearch
