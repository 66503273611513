import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// libs
import { toJPYenText } from 'src/lib/format.js'
import { createEndpoint } from 'src/lib/api-utils'
import { Button } from '../../styled'

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
export const mapStateToProps = state => {
  return {
    accessToken: state.login.authentication.accessToken,
    env: state.env,
    displayDate: state.displayDate.displayDate,
  }
}

export class TotalPayments extends React.PureComponent {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    displayRegionIds: PropTypes.arrayOf(PropTypes.number).isRequired,

    // stateProps
    accessToken: PropTypes.string.isRequired,
    env: PropTypes.object.isRequired,
    displayDate: PropTypes.string.isRequired,
  }

  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    this.state = {
      totalSales: [],
      error: false,
      refreshEnabled: false,
      timerId: false,
    }
  }

  /**
   * componentDidMount
   * @return {void}
   */
  componentDidMount() {
    this.request(this.props.displayDate, true)
  }

  /**
   * UNSAFE_componentWillReceiveProps
   * @param  {object} nextProps React props.
   * @return {void}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.displayDate !== nextProps.displayDate ||
      this.props.displayRegionIds.length !== nextProps.displayRegionIds.length
    ) {
      this.request(nextProps.displayDate)
    }
  }

  /**
   * componentWillUnmount
   * @return {void}
   */
  componentWillUnmount() {
    clearTimeout(this.state.timerId)
  }

  request = (displayDate, initialCall = false) => {
    initialCall || this.setState({ refreshEnabled: false })
    this.setState({
      timerId: setTimeout(() => this.setState({ refreshEnabled: true }), 5000),
    })

    const { accessToken, env } = this.props
    const endpoint = createEndpoint(env, 'totalSales') + `/${displayDate}`

    fetch(endpoint, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(res => {
        if (res.ok) {
          this.setState({ error: false })
          return res.json()
        } else {
          this.setState({ error: true })
          throw { error: new Error('通信エラー'), body: res.json() }
        }
      })
      .then(totalSales => this.setState({ totalSales, error: false }))
      .catch(console.error)
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { error, totalSales, refreshEnabled } = this.state
    const { displayRegionIds, displayDate } = this.props

    // console.log(totalSales)
    const { accountValue, cashValue } = totalSales.reduce(
      (prev, sale) =>
        displayRegionIds.includes(sale.regionId)
          ? {
            accountValue: prev.accountValue + sale.accountValue,
            cashValue: prev.cashValue + sale.cashValue,
          }
          : prev,
      { accountValue: 0, cashValue: 0 },
    )

    return (
      <dl>
        <dt id={ 'visited-sum-label' }>{'売上合計'}</dt>
        <dd>
          <div style={ { display: 'flex', justifyContent: 'space-evenly' } }>
            <span>
              {'(現)'}
              {error ? '通信エラー' : toJPYenText(cashValue)}
            </span>
            <span>
              {'(掛)'}
              {error ? '通信エラー' : toJPYenText(accountValue)}
            </span>
            <Button
              style={ { marginTop: '-5px' } }
              disabled={ !refreshEnabled }
              onClick={ () => this.request(displayDate) }
            >
              {'更新'}
            </Button>
          </div>
        </dd>
      </dl>
    )
  }
}

export default connect(mapStateToProps)(TotalPayments)
