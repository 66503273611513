import React, { useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Sticky from '../../sticky/container'
import { findListFromWara } from 'src/lib/search'
// import config from 'src/config'
import { conditionIds } from 'src/components/divisions/order-list/partials/conditions'
import { validMaruc } from 'src/lib/validate'
import moment from 'moment'

/**
 * map state to props
 * @param  {object} state stored state
 * @return {object}       merging props
 */
const mapStateToProps = state => {
  return {
    wara: state.wara.data,
    dnd: state.dnd,
    isDebugMode: state.env.DEBUG,
  }
}

const Stickies = ({
  location: { regionIndex, listIndex },
  showAllStickies,
  getSSworkList,
  workState,
  wara,
  dnd,
  isDebugMode = false,
}) => {
  /**
   * ドラッグ中かを判定します
   * @param  {number}  stickyIndex [description]
   * @return {boolean}             [description]
   */
  const isDraggingSticky = useCallback(
    stickyIndex =>
      dnd.status === 'isDragging' &&
      dnd.regionIndex === location.regionIndex &&
      dnd.listIndex === location.listIndex &&
      dnd.stickyIndex === stickyIndex,
    [dnd, regionIndex, listIndex],
  )

  /**
   * 表示しない付箋の判定処理
   * マルシー付箋で非表示の条件をチェックし、それと以下の条件を合わせる
   * showAllStickies = trueの時は全ての付箋を表示する。
   * showAllStickies = falseの時は付箋の表示(true)・非表示(false)を判定して返す。
   */
  const showSticky = useCallback(
    sticky => {
      const show = validMaruc(sticky.json())
      if (showAllStickies) return show
      // 表示しない付箋の時
      // アサイン実行中、担当者未連絡は表示、それ以外は非表示
      const cids = conditionIds(sticky.json())
      return show && (cids[0] === 1 || cids[0] === 2)
    },
    [showAllStickies],
  )

  const stickies = useMemo(() => {
    const listStickies = wara.regions[regionIndex].lists[listIndex].stickies

    const determinedDate = props => {
      const determinedDateTime = props.determinedDateTime || {}
      let date = determinedDateTime.date || '2999-12-31'
      let startAt = determinedDateTime.startAt || '00:00'
      // 25:00のような時刻表記の処理
      const timeParts = startAt.split(':')
      let hours = parseInt(timeParts[0], 10)
      let minutes = parseInt(timeParts[1], 10)
      // 時刻が24時以上の時は日付を1日進める
      if (hours >= 24) {
        hours -= 24
        date = moment(date)
          .add(1, 'days')
          .format('YYYY-MM-DD')
      }
      startAt = `${hours
        .toString()
        .padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`

      return moment(`${date}${startAt}`, 'YYYY-MM-DD HH:mm').unix()
    }
    // 付箋を確定作業日時の昇順にソートする
    return listStickies.sort((a, b) => {
      const aProps = a.json()
      const bProps = b.json()
      return determinedDate(aProps) - determinedDate(bProps)
    })
  }, [wara, regionIndex, listIndex])

  if (regionIndex === -1 || listIndex === -1) {
    /* eslint-disable no-console */
    console.log('list -> stickies に不明な location オブジェクトが渡されました')
    /* eslint-enable no-console */
    return null
  }
  const loc = findListFromWara(wara, {
    regionIndex,
    listIndex,
    stickyIndex: 0,
  })

  const isAssignable = loc.dailyInfo ? loc.dailyInfo.isAssignable : false

  if (stickies.length === 0) return null

  return (
    <ul className={ 'list-stickies' }>
      {isDebugMode && (
        <p
          style={ {
            position: 'absolute',
            left: '530px',
            top: '-5px',
            fontWeight: 'bold',
            zIndex: 10000,
            padding: '2px 5px',
            backgroundColor: '#eee',
            borderRadius: '5px',
            opacity: 0.7,
          } }
        >
          {'付箋ID順デバッグ: ' +
            stickies
              ?.map(sticky => `${sticky.id}[${sticky.json().displayOrder}]`)
              .join(' ')}
        </p>
      )}
      {stickies.map((sticky, stickyIndex) => {
        return showSticky(sticky) ? (
          <div key={ sticky.id }>
            <Sticky
              location={ { regionIndex, listIndex, stickyIndex } }
              sticky={ sticky }
              isAssignable={ isAssignable }
              stickyStatusId={ sticky._props.stickyStatusId }
              finishStateId={ sticky._props.finishStateId || -1 }
              getSSworkList={ getSSworkList }
              workState={ workState }
            />
          </div>
        ) : null
      })}
    </ul>
  )
}

/**
 * propTypes
 * @type {object}
 */
Stickies.propTypes = {
  // ownProps
  location: PropTypes.shape({
    regionIndex: PropTypes.number.isRequired,
    listIndex: PropTypes.number.isRequired,
  }).isRequired,
  showAllStickies: PropTypes.bool.isRequired,
  getSSworkList: PropTypes.func.isRequired, // SSの稼働状況更新
  workState: PropTypes.number.isRequired, // SSの稼働状況
  // stateProps
  wara: PropTypes.shape({
    regions: PropTypes.arrayOf(
      PropTypes.shape({
        lists: PropTypes.arrayOf(
          PropTypes.shape({
            // stickies: PropTypes.arrayOf(PropTypes.instanceOf(Sticky)),
            // なんかうまくいかないHOCで改造されてる?
            stickies: PropTypes.array,
          }),
        ),
      }),
    ).isRequired,
  }).isRequired,
  dnd: PropTypes.object.isRequired,
  isDebugMode: PropTypes.bool,
}

/**
 * defaultProps
 * @type {object}
 */
Stickies.defaultProps = {
  isDebugMode: false,
}

export default connect(mapStateToProps)(Stickies)
