import { createInventoryEndpoint } from 'src/lib/api-utils'

/**
 * GraphQueryによるiPadの伝票取得状況の取得
 * @returns
 */
const query = (body, accessToken, env) => {
  const url = createInventoryEndpoint(env, 'query')
  const request = fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body,
  })
  return request
}

/**
 * 見積書PDF
 * @param {*} id
 * @param {*} accessToken
 * @param {*} env
 * @returns
 */
export const getDocument = (startDate, endDate, staffId, accessToken, env) => {
  const body = {
    query: `query getDocument($startDate: String!, $endDate: String!, $staffId: Int!) {
      getDocument(startDate: $startDate, endDate: $endDate, staffId: $staffId) {
        submitted {
          documentType
          numbers
        }
        unsubmitted {
          documentType
          numbers
        }
      }
    }
  `,
    variables: {
      startDate,
      endDate,
      staffId,
    },
  }
  return query(JSON.stringify(body), accessToken, env)
}
