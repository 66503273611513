import React from 'react'
import styled from 'styled-components'
import { baseColor, darkBaseColor } from 'src/colors'

// 写真をフルスクリーンで表示する
export const ScreenFull = styled.div`
  position: fixed;
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 15000;
`

// 写真を右寄せで表示する
export const ScreenRight = styled.div`
  position: fixed;
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  width: 1100px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 15000;
`

export const Console = styled.div`
  display: block;
`

const buttonProps = {
  next: `
    right: 48px;
    top: 50%;
  `,
  prev: `
    left: 48px;
    top: 50%;
  `,
}

const selectButtonProps = props => {
  if (props.next) {
    return buttonProps.next
  } else if (props.prev) {
    return buttonProps.prev
  } else {
    return ''
  }
}

export const Button = styled.button`
  color: ${baseColor};
  width: 50px;
  height: 50px;
  border: solid 2px ${baseColor};
  border-radius: 25px;
  font-size: 24px;
  position: absolute;
  ${selectButtonProps};

  &:hover {
    color: white;
    background-color: ${darkBaseColor};
  }
`

const CloseButtonStyle = styled.button`
  background: transparent;
  border: none;
  position: absolute;
  right: 30px;
  top: 30px;
  font-size: 40px;
  transition-duration: 0.2s;
  color: ${baseColor};

  &:hover {
    font-size: 50px;
    cursor: pointer;
  }
`

export const CloseButton = props => (
  <CloseButtonStyle { ...props }>
    <i className={ 'fa fa-times' } />
  </CloseButtonStyle>
)
