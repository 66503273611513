import { CALL_API } from 'redux-api-middleware'
import { NETWORKER } from 'src/middlewares/network-monitor'
import { ACTION_TYPES as NETWORK_ACTION_TYPES } from 'src/reducers/network-status'
import utils from 'src/lib/api-utils'
import switz from 'switz'
import update from 'immutability-helper'
import { formatMaster, formatAllServiceStaffs } from 'src/lib/format'

/**
 * initial master state
 * @type {object}
 */
export const initialMasterState = {
  data: {},
  cityCodes: {},
}

/**
 * Action Types
 * @type {string}
 */
export const MASTER_UPDATE_ALL = 'MASTER.MASTER_UPDATE_ALL'
export const CITYCODES_UPDATE_ALL = 'MASTER.CITYCODES_UPDATE_ALL'

export const MASTER_GET_REQUEST = 'MASTER.MASTER_GET_REQUEST'
export const MASTER_GET_SUCCESS = 'MASTER.MASTER_GET_SUCCESS'
export const MASTER_GET_FAILURE = 'MASTER.MASTER_GET_FAILURE'

export const CITYCODE_REQUEST = 'MASTER.CITYCODE_REQUEST'
export const CITYCODE_SUCCESS = 'MASTER.CITYCODE_SUCCESS'
export const CITYCODE_FAILURE = 'MASTER.CITYCODE_FAILURE'

/**
 * action bulk exports
 * @type {object}
 */
export const ACTION_TYPES = {
  MASTER_UPDATE_ALL,
  CITYCODES_UPDATE_ALL,

  MASTER_GET_REQUEST,
  MASTER_GET_SUCCESS,
  MASTER_GET_FAILURE,
  CITYCODE_REQUEST,
  CITYCODE_SUCCESS,
  CITYCODE_FAILURE,
}

/**
 * master update action creator
 * @param  {string} key         master key
 * @param  {string} accessToken access token
 * @return {object} redux-api-middleware に渡す非同期処理情報を内包したアクション
 */
export const createAsyncMasterUpdateAction = (key, accessToken, env) => {
  const base = `${utils.createEndpoint(env, 'master')}`
  const endpoint = `${base}/${key}`

  return {
    [CALL_API]: {
      endpoint,
      method: 'GET',
      headers: { Authorization: `Bearer ${accessToken}` },
      types: [
        {
          type: MASTER_GET_REQUEST,
          meta: {
            [NETWORKER]: {
              target: 'getMaster',
              networkActionType: NETWORK_ACTION_TYPES.REQUEST,
            },
          },
        },
        {
          type: MASTER_GET_SUCCESS,
          meta: {
            // これらは形が特殊なマスタなので、format === false を渡す
            format:
              key !== 'orderRanks' &&
              key !== 'freeDial' &&
              key !== 'allServiceStaffs' &&
              key !== 'warrantyCategoryPeriod',
            [NETWORKER]: {
              target: 'getMaster',
              networkActionType: NETWORK_ACTION_TYPES.SUCCESS,
            },
            masterKey: key,
          },
          payload: (action, state, res) => res.json().then(payload => payload),
        },
        {
          type: MASTER_GET_FAILURE,
          meta: {
            [NETWORKER]: {
              target: 'getMaster',
              networkActionType: NETWORK_ACTION_TYPES.FAILURE,
            },
            masterKey: key,
          },
          payload: (action, state, res) => res.json().then(payload => payload),
        },
      ],
    },
  }
}

/**
 * master update action creator
 * @param  {string} prefCode         master prefCode
 * @param  {string} accessToken access token
 * @return {object} redux-api-middleware に渡す非同期処理情報を内包したアクション
 */
export const createAsyncCityCodeUpdateAction = (prefCode, accessToken, env) => {
  const base = `${utils.createEndpoint(env, 'master')}`
  const endpoint = `${base}/cityCodes/${prefCode.trim()}`

  return {
    [CALL_API]: {
      endpoint,
      method: 'GET',
      headers: { Authorization: `Bearer ${accessToken}` },
      types: [
        {
          type: CITYCODE_REQUEST,
          meta: { prefCode },
        },
        {
          type: CITYCODE_SUCCESS,
          meta: { prefCode },
          payload: (action, state, res) => res.json().then(payload => payload),
        },
        {
          type: CITYCODE_FAILURE,
          meta: { prefCode },
        },
      ],
    },
  }
}

/**
 * master reducer
 * @param  {object} [state=initialMasterState] previous state
 * @param  {object} action                    dispatched action
 * @return {object}                            next state
 */
export default (state = initialMasterState, action) => {
  const { type, payload, meta } = action

  return switz(type, s =>
    s
      .case(MASTER_UPDATE_ALL, () => {
        const master = payload || {}
        // 通常マスタはフォーマットがいるので、reduceする
        const merging = Object.keys(master).reduce((prev, key) => {
          const isNeedFormatting =
            key !== 'orderRanks' &&
            key !== 'freeDial' &&
            key !== 'allServiceStaffs' &&
            key !== 'warrantyCategoryPeriod'
          prev[key] = isNeedFormatting ? formatMaster(master[key]) : master[key]
          // 画面をリロードしたとき、スタッフ一覧からstaffs, validStaffsを作る
          if (key === 'allServiceStaffs') {
            const { staffs, validStaffs } = formatAllServiceStaffs(master[key])
            prev.staffs = staffs
            prev.validStaffs = validStaffs
          }
          return prev
        }, {})
        return update(state, {
          data: { $set: merging },
        })
      })
      .case(CITYCODES_UPDATE_ALL, () => {
        const cityCodes = payload || {}
        return update(state, { cityCodes: { $set: cityCodes } })
      })
      // reducer for master updating
      .case(MASTER_GET_REQUEST, () => state)
      .case(MASTER_GET_SUCCESS, () => {
        // スタッフ一覧からstaffs, validStaffsを作る
        if (meta.masterKey === 'allServiceStaffs') {
          const { staffs, validStaffs } = formatAllServiceStaffs(payload)
          return update(state, {
            data: {
              staffs: { $set: staffs },
              validStaffs: { $set: validStaffs },
            },
          })
        } else {
          // マスタのデータを整形
          const isNeedFormatting = meta.format
          const eachMaster = payload
            ? isNeedFormatting
              ? formatMaster(payload)
              : payload
            : []

          return update(state, {
            data: {
              [meta.masterKey]: { $set: eachMaster },
            },
          })
        }
      })
      .case(MASTER_GET_FAILURE, () => {
        // 失敗したときは空の配列を入れる
        return update(state, {
          data: {
            [meta.masterKey]: { $set: [] },
          },
        })
      })
      // reducer for prefCode updating
      .case(CITYCODE_REQUEST, () => {
        // cityCodeのリクエスト開始時、都道府県にダミーのデータを設定して2回目以降のリクエストが動かないようにする。
        if (
          !state.cityCodes ||
          !state.cityCodes[meta.prefCode] ||
          state.cityCodes[meta.prefCode].length === 0
        ) {
          return update(state, {
            cityCodes: { [meta.prefCode]: { $set: [''] } },
          })
        }
        return state
      })
      .case(CITYCODE_FAILURE, () => {
        // エラーの時、リクエスト時に設定したダミーデータを削除し、次にリクエストが動くようにする
        return update(state, {
          cityCodes: { [meta.prefCode]: { $set: [] } },
        })
      })
      .case(CITYCODE_SUCCESS, () => {
        // APIでソートしていないため、UI側でソートする(今後修正するかも)
        payload.sort((a, b) => (a.code > b.code ? 1 : -1))
        return update(state, {
          cityCodes: { [meta.prefCode]: { $set: payload } },
        })
      })
      .default(() => state),
  )
}
