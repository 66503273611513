import React from 'react'

import Toggle from 'src/components/commons/toggle'
import QuickSearchForms from './quick-search-forms'

export const Search = props => (
  <Toggle
    slug={ 'call-screen_search' }
    openButtonTitle={ '検索を開く' }
    defaultToggleOpen={ props.search }
  >
    <QuickSearchForms { ...props } />
  </Toggle>
)

export default Search
