import { connect as _connect } from 'react-redux'
import { ACTION_TYPES as LOGIN_ACTION_TYPES } from 'src/reducers/login'
import { ACTION_TYPES as MASTER_ACTION_TYPES } from 'src/reducers/master'
import { ACTION_TYPES as NETWORK_ACTION_TYPES } from 'src/reducers/network-status'
import { SUCCESS as NETWORK_SUCCESS } from 'src/reducers/network-status'
import {
  createAsyncLoginAction,
  createActions as createLoginActions,
} from 'src/reducers/login'

// networker middleware hook
import { NETWORKER } from 'src/middlewares/network-monitor'

// action creators
import { push } from 'react-router-redux'

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
export const mapStateToProps = state => {
  return {
    version: state.meta.version,
    __env: state.env,
  }
}

/**
 * map dispatch to props
 * @param  {function} dispatch dispatcher
 * @param  {object}   ownProps own props
 * @return {object}            dispatch props
 */
export const mapDispatchToProps = dispatch => {
  return {
    setCredentials: (userid, password) => {
      dispatch(createLoginActions.setUsername(userid))
      dispatch(createLoginActions.setPassword(password))
    },

    __login: env => (userid, password) =>
      dispatch(createAsyncLoginAction(userid, password, env)),

    recoverLogin: (userid, accessToken) => {
      // ログイン状態にする
      dispatch({
        type: LOGIN_ACTION_TYPES.LOGIN_SUCCESS,
        // NOTE ここでpayloadにaccessTokenではなくtokenというキーを使っているのは、サーバーの実装に依存しているため
        payload: { token: accessToken },
        meta: { userid, cached: true },
      })
      dispatch({
        type: LOGIN_ACTION_TYPES.SET_USERNAME,
        payload: { userid },
      })
      dispatch({
        type: NETWORK_SUCCESS,
        payload: { target: 'login' },
      })
    },

    setMaster: master => {
      dispatch({
        type: MASTER_ACTION_TYPES.MASTER_UPDATE_ALL,
        payload: master,
        meta: {
          [NETWORKER]: {
            target: 'getMaster',
            networkActionType: NETWORK_ACTION_TYPES.SUCCESS,
          },
        },
      })
    },

    setCityCodes: cityCodes => {
      dispatch({
        type: MASTER_ACTION_TYPES.CITYCODES_UPDATE_ALL,
        payload: cityCodes,
        meta: {
          [NETWORKER]: {
            target: 'getMaster',
            networkActionType: NETWORK_ACTION_TYPES.SUCCESS,
          },
        },
      })
    },

    goToHome: () => dispatch(push('/')),
  }
}

/**
 * map state to props
 * @param  {object} stateProps    state props
 * @param  {object} dispatchProps dispatch props
 * @param  {object} ownProps      own props
 * @return {object}               props
 */
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    // merge
    login: dispatchProps.__login(stateProps.__env),
    // make private
    __env: void 0,
    __login: void 0,
  }
}

export const connect = Component =>
  _connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component)

export default connect
