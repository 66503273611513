import React from 'react'
import PropTypes from 'prop-types'
import createClassNames from 'classnames'
import Modal from 'react-modal'
import { hlMasterFromFeeId, isPaymentMaster, salesTax } from './util'
import config from 'src/config'

export class CalcButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      inputValue: null,
      calculation: null,
      modalIsOpen: false,
    }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.inputType !== nextProps.inputType ||
      this.props.params !== nextProps.params ||
      this.state.inputtValue !== nextState.inputValue ||
      this.state.calculation !== nextState.calculation
    )
  }

  /**
   * 電卓ダイアログを表示する。
   */
  openModal() {
    // 初期値を計算する
    const { initialValue } = this.initialValue()
    this.setState({ modalIsOpen: true, calculation: initialValue })
  }

  /**
   * 電卓ダイアログを閉じる
   */
  closeModal() {
    this.setState({ modalIsOpen: false })
  }

  // ACボタンを押したとき
  onAllClear = () => {
    this.setState({ calculation: 0 })
  }

  calcValue = val => {
    this.setState({ inputValue: val })
    this.setState({ calculation: val })
  }

  // 基本作業料金の計算
  calcBasicFee = (fee, e) => {
    this.setState({ inputValue: e.target.value })
    this.setState({ calculation: String(fee * e.target.value) })
  }

  // クライアント紹介料と現場紹介料の計算
  calcIntroduceFee = (ratio, e) => {
    this.setState({ inputValue: e.target.value })
    this.setState({
      calculation: String(((e.target.value * ratio) / 100) * -1),
    })
  }

  // 出張費の計算
  calcTripExp = e => {
    this.setState({ inputValue: e.target.value })
    this.setState({
      calculation:
        e.target.value > 15 ? String((e.target.value - 15) * 110) : '0',
    })
  }

  // 遠方費の計算。30kmより遠いなら1,100円
  calcTransportationExp = e => {
    this.setState({ inputValue: e.target.value })
    this.setState({
      calculation: e.target.value > 30 ? '1100' : '0',
    })
  }

  // OKボタンで表に計算結果を流し込む
  clickOkButton = () => {
    this.closeModal()
    this.props.onDecide(this.state.calculation)
  }

  /**
   * ダイアログの初期値を計算する
   */
  initialValue = () => {
    const { sticky, inputType, params, contractAmount } = this.props
    const stickyProps = sticky.json() || {}
    const workPlaces = stickyProps.houseLaboWorks
      ? stickyProps.houseLaboWorks.length
      : 0
    // クライアント紹介料と現場紹介料の元になる額
    const houselabo = stickyProps.houseLabo || {}
    const houselaboFees = stickyProps.houseLaboFees || [] // ①と②の部分
    const extraFees = stickyProps.houseLaboExtraFees || [] // ③の部分
    const houseLaboClientMaster = stickyProps.houseLaboClientMaster || {}
    const masterHouseLaboFees = houseLaboClientMaster.houseLaboFees || []

    // 距離
    const totalHouseLaboDistance = houselaboFees => {
      let total = 0
      houselaboFees.forEach(fee => {
        const master = hlMasterFromFeeId(masterHouseLaboFees, fee.feeId)
        if (master && master.inputType) {
          if (master.inputType === config.houseLaboFeeInputType.distance) {
            total += parseInt(fee.fee || 0, 10)
          }
        }
      })
      return total
    }

    /*
     ** 夜間料金かを判定
     */
    const checkNightFee = () => {
      // 作業開始時刻
      const startTime = houselabo.workStartTime || ''
      // 作業終了時刻
      const endTime = houselabo.workEndTime || ''
      // 出動後キャンセル料の判定
      const nightHours = params.nightHours
      const midnightHours = params.midnightHours
      let isNight = false
      let isMidnight = false
      if (params.rangeType === 1) {
        // 深夜料金のチェック
        midnightHours.forEach(midnightHour => {
          if (
            midnightHour.startHour <= startTime &&
            startTime < midnightHour.endHour
          ) {
            // 開始時刻が深夜時間内
            isMidnight = true
          } else if (
            midnightHour.startHour <= endTime &&
            endTime < midnightHour.endHour
          ) {
            isMidnight = true
            // 終了時刻が深夜時間内
          }
        })
        // 夜間料金のチェック
        nightHours.forEach(hour => {
          if (hour.startHour <= startTime && startTime < hour.endHour) {
            // 開始時刻が夜間時間内
            isNight = true
          } else if (hour.startHour <= endTime && endTime < hour.endHour) {
            // 終了時刻が夜間時間内
            isNight = true
          }
        })
      } else if (params.rangeType === 2) {
        // 深夜料金のチェック
        midnightHours.forEach(hour => {
          if (
            hour.startHour <= startTime &&
            startTime < hour.endHour &&
            hour.startHour <= endTime &&
            endTime < hour.endHour
          ) {
            isMidnight = true
          }
        })
        nightHours.forEach(hour => {
          // 開始時刻と終了時刻が夜間時間内
          if (
            hour.startHour <= startTime &&
            startTime < hour.endHour &&
            hour.startHour <= endTime &&
            endTime < hour.endHour
          ) {
            isNight = true
          }
        })
      }
      return { isNight, isMidnight }
    }

    /*
     *紹介料のパターン別計算
     * 1: クライアント請求額 ①②③の出力するものの合計
     * 2: 現場請求額=契約金額総額
     * 3: クライアント紹介料にクライアント請求額、現場紹介料に現場請求額
     * 4: 基本料 チェック関係なし
     */
    // ハウスラボ①②の計算
    const totalHouseLaboFees = houselaboFees => {
      let total = 0
      houselaboFees.forEach(fee => {
        const master = hlMasterFromFeeId(masterHouseLaboFees, fee.feeId)
        if (isPaymentMaster(master)) {
          if (fee.isExport) {
            total += parseInt(fee.fee || '0', 10)
          }
        }
      })
      return total
    }

    // ③商品名・型式・作業欄の計算
    const totalHouseLaboExtraFees = extraFees => {
      let total = 0
      extraFees.forEach(fee => {
        if (fee.isExport) {
          total += fee.unitFee * fee.number
        }
      })
      total += salesTax(total)
      return total
    }

    // ③の最初の金額
    // const firstHouseLaboExtraFees = houseLaboExtraFees => {
    //   let total = 0
    //   if (houseLaboExtraFees.lengh >= 1) {
    //     const exfee = houseLaboExtraFees[0]
    //     total += exfee.unitFee * exfee.number
    //   }
    //   return total
    // }

    // 金額の計算に必要な値を取得
    const distance = totalHouseLaboDistance(houselaboFees)
    const isNight = checkNightFee()
    const totalHouselaboFee = totalHouseLaboFees(houselaboFees)
    const totalHouselaboExtraFee = totalHouseLaboExtraFees(extraFees)

    /*
     * クライアント紹介料と現場紹介料を計算する
     * パターンの数字とtargetの数字が異なる
     */
    // パターン1と3(target = 3)クライアント請求額
    const clientIntroduce3 = totalHouselaboFee + totalHouselaboExtraFee
    // パターン2と3 現場請求額=契約金総額(target = 2) 現場紹介料で使う
    const clientIntroduce2 = contractAmount
      ? contractAmount.total.totalContract
      : 0
    // パターン4 基本料(target = 1) 現在 ID162のフューチャー・コネクトのみ
    const clientIntroduce1 = masterHouseLaboFees[0][0].params.price

    let initialValue = '0'
    if (inputType === 5) {
      initialValue = String(params.price * workPlaces) // 基本料
    } else if (inputType === 4) {
      // 出動料
      if (
        !params.nightHours.length ||
        (!isNight.isNight && !isNight.isMidnight)
      ) {
        initialValue = String(params.dayPrice)
      } else if (isNight.isMidnight) {
        initialValue = String(params.midnightPrice)
      } else if (isNight.isNight) {
        initialValue = String(params.nightPrice)
      }
    } else if (inputType === 8) {
      // クライアント紹介料
      if (params.target === 1) {
        initialValue = String(
          Math.floor((clientIntroduce1 * (params.ratio || 100)) / 100) * -1,
        )
      } else if (params.target === 3) {
        initialValue = String(
          Math.floor((clientIntroduce3 * (params.ratio || 100)) / 100) * -1,
        )
      }
    } else if (inputType === 9) {
      // 現場紹介料
      params.target === 2
        ? (initialValue = String(
          Math.floor((clientIntroduce2 * (params.ratio || 100)) / 100) * -1,
        ))
        : '0'
    } else if (inputType === 10) {
      initialValue = String(params.afterCancel) // NOTE: 実際には使用されない可能性が高いが、一旦afterCancelの値を表示
    } else if (inputType === 6) {
      initialValue =
        distance > 15 ? String((distance - 15) * params.price) : '0'
    } else if (inputType === 7) {
      initialValue = distance > 30 ? String(params.price) : '0'
    } else if (inputType === 12) {
      initialValue = String(params.price) // 基本料1件分
    } else if (inputType === 13) {
      initialValue =
        workPlaces > 1 ? String(params.price * (workPlaces - 1)) : '0' // 基本料2件目以降
    } else {
      initialValue = this.state.calculation
    }
    return {
      initialValue,
      workPlaces,
      distance,
      isNight,
      clientIntroduce1,
      clientIntroduce2,
      clientIntroduce3,
    }
  }

  render() {
    const { inputType, params } = this.props
    const { inputValue, calculation } = this.state

    const buttonClassNames = createClassNames({
      button: true,
      'button-open': true,
      'label-inputs-wrap-button': true,
      'button-disabled': false,
    })

    // 電卓のモーダル
    const modalStyle = {
      overlay: {
        backgroundColor: 'rgba(255, 255, 255, .1)',
      },
      content: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '208px',
        height: '294px',
        padding: '1.5rem',
        background: 'rgba(33, 33, 33, .9)',
        backdropFilter: 'blur(4px)',
        border: '1px solid #212121',
        borderRadius: '10px',
        boxShadow: 'rgba(0, 0, 0, 0.4) 0px 10px 10px',
      },
    }

    // 計算結果の表示部分
    const calculationStyle = {
      alignSelf: 'center',
      width: 'calc(208px - 0.75rem)',
      backgroundImage: 'linear-gradient(180deg, #fafafa, #eeeeee)',
      fontSize: '2.125rem',
      fontFamily: 'Orbitron, sans-serif',
      textAlign: 'right',
    }

    // 式のタイトル部分
    const formTitleStyle = {
      display: 'flex',
      justifyContent: 'flex-start',
      marginBottom: '0.75rem',
      marginRight: '0.75rem',
      color: '#ffffff',
      fontSize: '1rem',
    }
    // 式の数値部分
    const formulaStyle = {
      display: 'flex',
      justifyContent: 'flex-start',
      marginBottom: '0.75rem',
      marginRight: '0.75rem',
      fontSize: '1.5rem',
      fontFamily: 'Orbitron, sans-serif',
      color: '#ffffff',
      whiteSpace: 'nowrap',
    }
    // 入力欄
    const inputSectionStyle = {
      fontSize: '1.5rem',
      fontFamily: 'Orbitron, sans-serif',
      backgroundImage: 'linear-gradient(-180deg, #fafafa, #eeeeee)',
      textAlign: 'right',
    }
    const bottomButtons = {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '1.5rem',
    }
    // 電卓のボタン
    const calculatrButtonStyle = {
      width: '9rem',
      padding: '0.75rem',
      borderRadius: '0.75rem',
      fontSize: '1rem',
      fontFamily: 'Orbitron, sans-serif',
    }
    // 対象外メッセージ
    const excludedMessage = {
      fontSize: '1rem',
      color: '#ffffff',
    }
    /*
     ** 以下は計算に使う項目。
     */
    const {
      initialValue,
      workPlaces,
      distance,
      isNight,
      clientIntroduce1,
      clientIntroduce2,
      clientIntroduce3,
    } = this.initialValue()

    // 電卓を最初に表示したとき表示する計算結果
    const firstCalculation = initialValue || ''

    /**
     * 料金タイプごとの電卓の中身
     */
    const contents = () => {
      if (inputType === 5) {
        // 基本料金
        return (
          <>
            <div style={ { display: 'flex', alignItems: 'flex-end' } }>
              <div style={ { display: 'flex', flexDirection: 'column' } }>
                <span style={ formTitleStyle }>{'基本作業料金'}</span>
                <span
                  style={ formulaStyle }
                >{`${params.price.toLocaleString()} ×`}</span>
              </div>
              <div style={ { display: 'flex', flexDirection: 'column' } }>
                <span style={ formTitleStyle }>{'作業箇所数'}</span>
                <input
                  style={ { ...inputSectionStyle, width: '5rem' } }
                  value={ inputValue || workPlaces }
                  type={ 'text' }
                  placeholder={ 0 }
                  onChange={ e => this.calcBasicFee(params.price, e) }
                />
              </div>
              <span style={ formulaStyle }>{')'}</span>
            </div>
          </>
        )
      } else if (inputType === 12) {
        // グラン 基本
        return (
          <>
            <div style={ { display: 'flex', alignItems: 'flex-end' } }>
              <div style={ { display: 'flex', flexDirection: 'column' } }>
                <span style={ formTitleStyle }>{'基本'}</span>
                <span style={ formulaStyle }>
                  {params.price.toLocaleString()}
                </span>
              </div>
            </div>
          </>
        )
      } else if (inputType === 13) {
        // グラン 複数箇所加算
        return (
          <>
            <div style={ { display: 'flex', alignItems: 'flex-end' } }>
              <div style={ { display: 'flex', flexDirection: 'column' } }>
                <span style={ formTitleStyle }>{'複数箇所加算'}</span>
                <span
                  style={ formulaStyle }
                >{`( ${params.price.toLocaleString()} ×`}</span>
              </div>
              <div style={ { display: 'flex', flexDirection: 'column' } }>
                <span style={ formTitleStyle }>{'作業箇所数'}</span>
                <input
                  style={ { ...inputSectionStyle, width: '5rem' } }
                  value={ inputValue || (workPlaces > 1 ? workPlaces - 1 : '0') }
                  type={ 'text' }
                  placeholder={ 0 }
                  onChange={ e => this.calcBasicFee(params.price, e) }
                />
              </div>
              <span style={ formulaStyle }>{')'}</span>
            </div>
          </>
        )
      } else if (inputType === 4) {
        // 出動料金
        return (
          <>
            <div style={ { display: 'flex', flexDirection: 'column' } }>
              {!params.nightHours.length ||
              (!isNight.isNight && !isNight.isMidnight) ? (
                <>
                    <span style={ formTitleStyle }>{'出動料金（昼間）'}</span>
                    <span
                    style={ formulaStyle }
                  >{`${params.dayPrice.toLocaleString()}`}</span>
                  </>
                ) : isNight.isMidnight ? (
                  <>
                    <span style={ formTitleStyle }>{'出動料金（深夜）'}</span>
                    <span
                      style={ formulaStyle }
                    >{`${params.midnightPrice.toLocaleString()}`}</span>
                  </>
                ) : (
                  <>
                    <span style={ formTitleStyle }>{'出動料金（夜間）'}</span>
                    <span
                      style={ formulaStyle }
                    >{`${params.nightPrice.toLocaleString()}`}</span>
                  </>
                )}
            </div>
          </>
        )
      } else if (inputType === 8) {
        // クライアント紹介料
        // 計算の元となる項目名と値
        const targetType = target => {
          if (target === 1) {
            return {
              title: '基本料',
              value: clientIntroduce1,
            }
          } else if (target === 3) {
            return {
              title: '総額',
              value: clientIntroduce3,
            }
          } else {
            return {
              title: '紹介料対象外',
              value: 0,
            }
          }
        }

        const { title, value } = targetType(params.target)
        return (
          <>
            <div style={ { display: 'flex', alignItems: 'flex-end' } }>
              <div style={ { display: 'flex', flexDirection: 'column' } }>
                <span style={ formTitleStyle }>{title}</span>
                <input
                  style={ { ...inputSectionStyle, width: '9rem' } }
                  value={ value }
                  type={ 'text' }
                  placeholder={ '0' }
                  onChange={ e => this.calcIntroduceFee(params.ratio, e) }
                />
              </div>
              <span style={ formulaStyle }>{` × ${params.ratio} %`}</span>
            </div>
          </>
        )
      } else if (inputType === 9) {
        // 現場紹介料
        return (
          <>
            <div style={ { display: 'flex', alignItems: 'flex-end' } }>
              <div style={ { display: 'flex', flexDirection: 'column' } }>
                <span style={ formTitleStyle }>
                  {params.target === 2 ? '直収' : '紹介料対象外'}
                </span>
                <input
                  style={ { ...inputSectionStyle, width: '9rem' } }
                  value={ params.target === 2 ? clientIntroduce2 : 0 }
                  type={ 'text' }
                  placeholder={ '0' }
                  onChange={ e => this.calcIntroduceFee(params.ratio, e) }
                />
              </div>
              <span style={ formulaStyle }>{` × ${params.ratio} %`}</span>
            </div>
          </>
        )
      } else if (inputType === 10) {
        // 出動後キャンセル料
        return (
          <>
            <div style={ { display: 'flex', flexDirection: 'column' } }>
              <span style={ formTitleStyle }>{'出動後キャンセル料'}</span>
              {/* <span style={ formulaStyle }>{`${params.beforeCancel}`}</span> */}
              <span
                style={ formulaStyle }
              >{`${params.afterCancel.toLocaleString()}`}</span>
            </div>
          </>
        )
      } else if (inputType === 6) {
        // 出張費
        return (
          <div style={ { display: 'flex', alignItems: 'flex-end' } }>
            <span style={ formulaStyle }>{'( '}</span>
            <div style={ { display: 'flex', flexDirection: 'column' } }>
              <span style={ formTitleStyle }>{'距離'}</span>
              <input
                style={ { ...inputSectionStyle, width: '5rem' } }
                value={ inputValue || distance }
                type={ 'text' }
                placeholder={ '0' }
                onChange={ this.calcTripExp }
              />
            </div>
            <span style={ formulaStyle }>{` - 15 ) × ${params.price}`}</span>
          </div>
        )
      } else if (inputType === 7) {
        // 遠方費
        return (
          <div style={ { display: 'flex', alignItems: 'flex-end' } }>
            <div style={ { display: 'flex', flexDirection: 'column' } }>
              <span style={ formTitleStyle }>{'距離'}</span>
              <input
                style={ { ...inputSectionStyle, width: '5rem' } }
                value={ inputValue || distance }
                type={ 'text' }
                placeholder={ '0' }
                onChange={ this.calcTransportationExp }
              />
            </div>
            {distance > 30 ? (
              <span style={ { ...excludedMessage, marginLeft: '0.75rem' } }>
                {'km: 遠方費適用'}
              </span>
            ) : (
              <span style={ { ...excludedMessage, marginLeft: '0.75rem' } }>
                {'km: 遠方費適用外'}
              </span>
            )}
          </div>
        )
      } else {
        return (
          <div style={ { display: 'flex', alignItems: 'flex-end' } }>
            <span style={ excludedMessage }>{'自動計算できません'}</span>
          </div>
        )
      }
    }

    return (
      <div
        style={ {
          display: 'inline',
          height: '20px',
          width: '20px',
        } }
      >
        <button
          className={ buttonClassNames }
          style={ { border: 0, fontSize: '16px' } }
          onClick={ this.openModal }
          disabled={ this.props.disabled }
        >
          <i className={ 'fa fa-calculator' } />
        </button>
        <Modal
          isOpen={ this.state.modalIsOpen }
          style={ modalStyle }
          ariaHideApp={ false }
        >
          <div
            style={ {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
            } }
          >
            <div>
              <input
                style={ calculationStyle }
                value={ calculation || firstCalculation.toLocaleString() }
                readOnly
              />
              <button
                style={ {
                  ...calculatrButtonStyle,
                  marginTop: '1.5rem',
                  background: 'linear-gradient(-180deg, #ff4081, #c51162)',
                } }
                onClick={ this.onAllClear }
              >
                {'AC'}
              </button>
            </div>
            {contents()}
            <div style={ bottomButtons }>
              <button onClick={ this.closeModal } style={ calculatrButtonStyle }>
                {'cancel'}
              </button>
              <button
                onClick={ this.clickOkButton }
                style={ {
                  ...calculatrButtonStyle,
                  background: 'linear-gradient(-180deg, #69f0ae, #00c853)',
                } }
              >
                {'OK'}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

/**
 * propTypes
 */
CalcButton.propTypes = {
  sticky: PropTypes.object.isRequired,
  inputType: PropTypes.number.isRequired, // 項目の種類→ダイアログを切り替えるのに使用
  params: PropTypes.object.isRequired, // マスタから渡す計算に使用するための値
  contractAmount: PropTypes.object,
  onDecide: PropTypes.func.isRequired, // ダイアログのボタンが押された時のハンドラ
  disabled: PropTypes.bool,
}
/**
 * defaultProps
 */
CalcButton.defaultProps = {
  contractAmount: null,
  disabled: false,
}
export default CalcButton
