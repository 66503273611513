import switz from 'switz'
import update from 'immutability-helper'
import Sticky from 'src/lib/class-sticky'

const UPDATE_STICKY = 'MARUC.UPDATE_STICKY'
const UPDATE_COMPLAINT = 'MARUC.UPDATE_COMPLAINT'
const RESET = 'MARUC.RESET'

export const initialState = {
  sticky: new Sticky({}),
  complaint: {},
}

export const ACTION_TYPES = {
  UPDATE_STICKY,
  UPDATE_COMPLAINT,
  RESET,
}

export const actionCreators = {
  updateSticky: sticky => ({
    type: UPDATE_STICKY,
    payload: { sticky },
  }),
  updateComplaint: complaint => ({
    type: UPDATE_COMPLAINT,
    payload: { complaint },
  }),

  reset: () => ({
    type: RESET,
    payload: {},
  }),
}

export const partialReducer = (state = initialState, action) => {
  const { type, payload } = action
  return switz(type, s =>
    s
      .case(UPDATE_STICKY, () =>
        update(state, { sticky: { $set: payload.sticky } }),
      )
      .case(UPDATE_COMPLAINT, () =>
        update(state, { complaint: { $set: payload.complaint } }),
      )
      .case(RESET, () => initialState)
      .default(() => state),
  )
}

export default partialReducer
