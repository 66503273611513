import utils from 'src/lib/api-utils'
import { CALL_API } from 'redux-api-middleware'
import { NETWORKER } from 'src/middlewares/network-monitor'
import Sticky from 'src/lib/class-sticky'

import switz from 'switz'
import { ACTION_TYPES as NETWORK_ACTION_TYPES } from 'src/reducers/network-status'
import update from 'immutability-helper'
import noop from 'src/lib/noop'
import config from 'src/config'

export const STICKY_POST_REQUEST = 'STICKY_POST_REQUEST'
export const STICKY_POST_SUCCESS = 'STICKY_POST_SUCCESS'
export const STICKY_POST_FAILURE = 'STICKY_POST_FAILURE'

/**
 * action type name bulk export
 * @type {object}
 */
export const ACTION_TYPES = {
  STICKY_POST_REQUEST,
  STICKY_POST_SUCCESS,
  STICKY_POST_FAILURE,
}

/**
 * createAsyncPostStickyAction 関数として、asyncPostStickyActionを作成する
 * @param  {Sticky}  sticky      シリアライズする付箋クラスインスタンス
 * @param  {string}  accessToken アクセストークン
 * @param  {object}  options     オプション
 * @return {object}                redux-api-middleware に渡す非同期処理情報を内包したアクション
 */
export const creator = (
  sticky,
  accessToken,
  {
    locationSrc = false, // メタ情報として渡す、付箋のlocation。{ regionIndex, listIndex, stickyIndex } が得られれば、POSTレスポンスの付箋オブジェクトをwara Stateのその位置に入れる
    locationDest = false,
    type = config.postStickyRequestTypes.CALL, // 付箋のPOSTの場合分け
    isAfterCreation,

    onSuccess = noop,
    onFailure = noop,
  } = {},
  env,
) => {
  const endpoint = `${utils.createEndpoint(env, 'sticky')}`

  const body = sticky
    .transform({
      pattern: Sticky.TRANSFORM_PATTERN.BEFORE_POST,
      type,
      isAfterCreation,
    })
    .toJSONString()

  return {
    [CALL_API]: {
      endpoint,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body,
      types: [
        {
          type: STICKY_POST_REQUEST,
          meta: {
            [NETWORKER]: {
              target: 'postSticky',
              networkActionType: NETWORK_ACTION_TYPES.REQUEST,
            },
          },
        },
        {
          type: STICKY_POST_SUCCESS,
          payload: (action, state, res) => res.json().then(payload => payload),
          meta: {
            [NETWORKER]: {
              target: 'postSticky',
              networkActionType: NETWORK_ACTION_TYPES.SUCCESS,
            },
            locationSrc, // これは、POST成功時に使う
            locationDest,
            type,
            sticky,
            onSuccess, // これも、 Post成功時に使う
          },
        },
        {
          type: STICKY_POST_FAILURE,
          meta: {
            [NETWORKER]: {
              target: 'postSticky',
              networkActionType: NETWORK_ACTION_TYPES.FAILURE,
            },
            locationSrc,
            locationDest,
            sticky,
            onFailure,
          },
        },
      ],
    },
  }
}

/**
 * [AsyncWaraReducers description]
 * @param  {object} state   state
 * @param  {object} action action
 * @return {object}         reducer
 */
export const partialReducer = (state, action) => {
  const { type } = action

  return switz(type, s =>
    s
      // これらのリクエストの成否はネットワーク状態を更新するだけ。ネットワークの状態更新はmiddlewareで行う
      .case(STICKY_POST_REQUEST, () => state)
      .case(STICKY_POST_SUCCESS, () => {
        const updatedSticky = action.payload
        const { locationDest } = action.meta

        if (updatedSticky && locationDest) {
          const sticky = new Sticky(updatedSticky)
          const { regionIndex, listIndex, stickyIndex } = locationDest
          if (regionIndex === -1 || listIndex === -1) {
            return update(state, {
              data: {
                dock: {
                  stickies: {
                    [stickyIndex]: { $set: sticky },
                  },
                },
              },
            })
          } else {
            return update(state, {
              data: {
                regions: {
                  [regionIndex]: {
                    lists: {
                      [listIndex]: {
                        stickies: {
                          [stickyIndex]: { $set: sticky },
                        },
                      },
                    },
                  },
                },
              },
            })
          }
        } else {
          return state
        }
      })
      .case(STICKY_POST_FAILURE, () => state)
      .default(() => state),
  )
}
