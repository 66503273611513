import Sticky from './'

// container
import DragSource from './drag-source'
import DropTarget from './drop-target'
import connect from './connect'

const ConnectedComponent = connect(Sticky)
const DropTargetComponent = DropTarget(ConnectedComponent)
const DragSourceComponent = DragSource(DropTargetComponent)

export default DragSourceComponent
