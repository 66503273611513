const dic = {
  '０': '0',
  '１': '1',
  '２': '2',
  '３': '3',
  '４': '4',
  '５': '5',
  '６': '6',
  '７': '7',
  '８': '8',
  '９': '9',
  '：': ':',
  ー: '-',
  '−': '-',
}

export const toHalf = text =>
  text
    .split('')
    .map(char => (dic[char] ? dic[char] : char))
    .join('')
