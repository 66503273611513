import utils from 'src/lib/api-utils'
import Sticky from 'src/lib/class-sticky'

/**
 * PUT stickyのアクションクリエーター
 * sticky.staffId === -1 でDockに戻せる
 * @param  {Sticky} sticky      [description]
 * @param  {string} accessToken [description]
 * @param  {object} env         env
 * @return {Promise}
 */
export const requestPostSticky = (sticky, accessToken, env) => {
  const endpoint = `${utils.createEndpoint(env, 'sticky')}`

  const postingSticky = sticky.transform({
    pattern: Sticky.TRANSFORM_PATTERN.BEFORE_POST,
    sticky,
  })

  const body = postingSticky.toJSONString()

  return fetch(endpoint, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body,
  }).then(res => {
    if (res.ok) {
      return res.json()
    } else {
      throw 'PUTエラー'
    }
  })
}

export default requestPostSticky
