// react
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'

// component
import Loading from 'src/components/commons/loading-screen/partials/initial-loading'
import ValidationErrorMessage from 'src/components/commons/validation-error-message'
import button, { buttonFill } from 'src/styled/button'
// import ExifView from './exif-view'

// libs
import EXIF from 'exif-js'
import { baseColor, darkBaseColor, red, darkRed } from 'src/colors'
import getImage from 'src/lib/image-api/get'
import expoGetImage from 'src/lib/expo-image-api/get'
import {
  getQuotation,
  getContract,
  getWorkComplete,
  getReceipt,
  getShipInventory,
  getHLWorkComplete,
  getECWorkComplete,
  getESignAgreement,
} from 'src/lib/inventory-api/get.js'
import deleteImage from 'src/lib/image-api/get'
import expoDeleteImage from 'src/lib/expo-image-api/delete'
import { deleteShipInventoryPDF } from 'src/lib/inventory-api/shipInventory'
import { makeCancelable } from 'src/lib/cancelable-promise'
import { paperTypeName } from '../../../lib/format'
import moment from 'moment'
import { isInventory } from '../../../lib/validate'

const Flex = styled.div`
  display: flex;
  flex-direction: column;
`
const degreeMap = {
  1: 0,
  2: 0,
  3: 180,
  4: 180,
  5: 90,
  6: 90,
  7: 270,
  8: 270,
}
const ReflectMap = {
  2: 180,
  4: 180,
  5: 180,
  7: 180,
}
const orientationToDegree = props =>
  (degreeMap[props.orientation] || 0) + props.rotateCount * 90
const orientationToReflect = props => ReflectMap[props.orientation] || 0

// TODO: exif.Orientation の値に乗っ取って transform rotate を設定する
const Image = styled.img`
  max-height: 92vh;
  max-width: 800px;
  transform: rotateY(${orientationToReflect}deg);
  transform: rotateZ(${orientationToDegree}deg);
  transition-duration: 0.2s;
`

const Label = styled.h2`
  font-size: 1.5em;
  text-align: center;
  color: white;
`

const DisplayBox = styled.div`
  display: ${props => (props.displayed ? 'block' : 'none')};
`

const RotateButton = styled.button`
  position: absolute;
  right: 100px;
  bottom: 50px;
  width: 50px;
  height: 50px;
  background-color: ${baseColor};
  border: none;
  border-radius: 25px;
  color: white;
  font-size: 2em;

  &:hover {
    background-color: ${darkBaseColor};
  }
`

const DeleteButton = styled.button`
  position: absolute;
  right: 160px;
  bottom: 50px;
  width: 50px;
  height: 50px;
  background-color: ${red};
  border: none;
  border-radius: 25px;
  color: white;
  font-size: 2em;

  &:hover {
    background-color: ${darkRed};
  }
`

const Button = styled.button`
  ${button};
  ${buttonFill(baseColor)};
  padding: 5px 10px;
`

export class LazyImage extends React.PureComponent {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    imageId: PropTypes.number.isRequired,
    display: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    deleteMe: PropTypes.func.isRequired,
    apitype: PropTypes.string.isRequired,
    handleCheckboxChange: PropTypes.func.isRequired,
    isChecked: PropTypes.bool.isRequired,
    addImages: PropTypes.func.isRequired, // イメージが読み込まれた時のコールバック
    originalTime: PropTypes.string.isRequired,
    documentStatus: PropTypes.string,
    canDelete: PropTypes.bool,
    // stateProps
    env: PropTypes.object.isRequired,
    accessToken: PropTypes.string.isRequired,
    paperTypes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }
  static defaultProps = {
    documentStatus: '',
    canDelete: false,
  }

  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    /**
     * 旧APIによるイメージ取得
     */
    const getImageOrg = () => {
      const { accessToken, env, imageId } = this.props

      const { promise, cancel } = makeCancelable(
        getImage(imageId, accessToken, env),
      )
      this.state = {
        ...this.state,
        onUnmount: [cancel],
      }

      promise
        .then(res => {
          if (res.ok) {
            return res.json()
          } else {
            throw new Error(res)
          }
        })
        .then(({ data }) => {
          const binaryString = atob(data.image)
          const bytes = new Uint8Array(binaryString.length)
          for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i)
          }

          this.setState({
            base64Image: data.image,
            exif: EXIF.readFromBinaryFile(bytes.buffer),
            category: data.category,
            s3Filename: data.s3Filename,
          })
        })
        .catch(() => this.setState({ error: true }))
    }

    /**
     * 新APIによるイメージ取得
     */
    const getImageExpo = () => {
      const {
        accessToken,
        env,
        imageId,
        paperTypes,
        index,
        addImages,
      } = this.props

      const { promise, cancel } = makeCancelable(
        expoGetImage(imageId, accessToken, env),
      )
      this.state = {
        ...this.state,
        onUnmount: [cancel],
      }
      promise
        .then(res => {
          if (res.ok) {
            return res.json()
          } else {
            throw new Error(res)
          }
        })
        .then(data => {
          const binaryString = atob(data.data)
          const bytes = new Uint8Array(binaryString.length)
          for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i)
          }

          // 読み込んだイメージを親コンポーネントに渡す。getImageOrgは使われないのでこの変更は反映しない。
          addImages(index, {
            imageId,
            paperTypes,
            base64Image: data.data,
            s3Filename: data.image.s3Filename,
            category: data.image.category,
          })
          // 同じ情報を自分のステートに設定する
          this.setState({
            base64Image: data.data,
            exif: EXIF.readFromBinaryFile(bytes.buffer),
            category: data.image.category,
            s3Filename: data.image.s3Filename,
          })
        })
        .catch(() => this.setState({ error: true }))
    }

    /**
     * 見積書・請求書・工事完了確認書PDFデータ覧取得
     * @param {*} nextProps
     */
    const getImageInventory = apitype => {
      const {
        accessToken,
        env,
        imageId,
        paperTypes,
        index,
        addImages,
      } = this.props

      const isQuotation = apitype === 'QPDF'
      const isQuotationKeiri = apitype === 'QKPDF'
      const isContract = apitype === 'CPDF'
      const isContractKeiri = apitype === 'CKPDF'
      const isWorkComp = apitype === 'WPDF'
      const isReceipt = apitype === 'RPDF'
      const isShipInventory = apitype === 'SPDF'
      const isHLWorkComplete = apitype === 'HLWorkCompletePDF'
      const isECWorkComplete = apitype === 'ECWorkCompletePDF'
      const isESignAgreement = apitype === 'ESignAgreement'

      let promise = null
      let cancel = null
      if (isQuotation || isQuotationKeiri) {
        ({ promise, cancel } = makeCancelable(
          getQuotation(imageId, accessToken, env),
        ))
      } else if (isContract || isContractKeiri) {
        ({ promise, cancel } = makeCancelable(
          getContract(imageId, accessToken, env),
        ))
      } else if (isWorkComp) {
        ({ promise, cancel } = makeCancelable(
          getWorkComplete(imageId, accessToken, env),
        ))
      } else if (isReceipt) {
        ({ promise, cancel } = makeCancelable(
          getReceipt(imageId, accessToken, env),
        ))
      } else if (isShipInventory) {
        ({ promise, cancel } = makeCancelable(
          getShipInventory(imageId, accessToken, env),
        ))
      } else if (isHLWorkComplete) {
        ({ promise, cancel } = makeCancelable(
          getHLWorkComplete(imageId, accessToken, env),
        ))
      } else if (isECWorkComplete) {
        ({ promise, cancel } = makeCancelable(
          getECWorkComplete(imageId, accessToken, env),
        ))
      } else if (isESignAgreement) {
        ({ promise, cancel } = makeCancelable(
          getESignAgreement(imageId, accessToken, env),
        ))
      } else {
        return
      }
      this.state = {
        ...this.state,
        onUnmount: [cancel],
      }
      promise
        .then(res => {
          if (res.ok) {
            return res.json()
          } else {
            throw new Error(res)
          }
        })
        .then(data => {
          const apiTypeMap = {
            QPDF: data.data.getQuotation,
            QKPDF: data.data.getQuotation,
            CPDF: data.data.getContract,
            CKPDF: data.data.getContract,
            WPDF: data.data.getWorkComplete,
            RPDF: data.data.getReceipt,
            SPDF: data.data.getShipInventory,
            HLWorkCompletePDF: data.data.getHLWorkComplete,
            ECWorkCompletePDF: data.data.getECWorkComplete,
            ESignAgreement: data.data.getESignAgreement,
          }
          const s3FileMap = {
            QPDF: 'quotation',
            QKPDF: 'quotation',
            CPDF: 'contract',
            CKPDF: 'contract',
            WPDF: 'workComplete',
            RPDF: 'receipt',
            SPDF: 'shipInventory',
            HLWorkCompletePDF: 'hlWorkComplete',
            ECWorkCompletePDF: 'ecWorkComplete',
            ESignAgreement: 'eSignAgreement',
          }
          const result = apiTypeMap[apitype]
          const s3Filename = result[s3FileMap[apitype]].s3Filename

          const categoryMap = {
            QPDF: 'estimate',
            QKPDF: 'estimate',
            CPDF: 'contract',
            CKPDF: 'contract',
            WPDF: 'workComplete',
            RPDF: 'receipt',
            SPDF: 'shipInventory',
            HLWorkCompletePDF: 'hlWorkComplete',
            ECWorkCompletePDF: 'ecWorkComplete',
            ESignAgreement: 'eSignAgreement',
          }
          const category = categoryMap[apitype]
          const base64Image =
            isQuotationKeiri || isContractKeiri
              ? result.stampedData || result.data
              : result.data
          // 読み込んだイメージを親コンポーネントに渡す。
          addImages(index, {
            imageId,
            paperTypes,
            base64Image,
            s3Filename,
            category,
          })
          // 同じ情報を自分のステートに設定する
          this.setState({
            base64Image,
            exif: {},
            category,
            s3Filename,
            downloadTimes: result.downloadTimes,
            printTimes: result?.printTimes || [],
          })
        })
        .catch(() => this.setState({ error: true }))
    }
    //-------------
    super(props)
    this.state = {
      base64Image: false,
      error: false,
      deleteError: false,
      exif: {},
      rotateCount: 0,
      onUnmount: [],
      timerId: false,
      s3Filename: '',
      downloadTimes: null, // 見積書等のダウンロード日時
      printTimes: null,
      showDownloadTimeModal: false, // ダウンロード日時モーダルの表示フラグ
      showPrintTimeModal: false, // 印刷日時モーダルの表示フラグ
    }
    // 新旧のAPIを切り替えて画像データを取得
    if (props.apitype === 'EXPO') {
      getImageExpo()
    } else if (isInventory(props.apitype)) {
      getImageInventory(props.apitype)
    } else {
      getImageOrg()
    }
  }

  /**
   * 画像・PDFを再描画させる。
   * ブラウザによって←→ボタンで表示切替するとPDFが再表示されないことがあるのでそれに対する対処
   * @param {*} nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.display !== nextProps.display && nextProps.display) {
      // イメージを再表示するとき
      // ① イメージを保存
      const base64Image = this.state.base64Image
      // ② 表示をクリア
      this.setState({
        base64Image: null,
        exif: this.state.exif,
        category: this.state.category,
        s3Filename: this.state.s3Filename,
      })
      // ③ 再度イメージを設定
      setTimeout(() => {
        this.setState({
          base64Image,
          exif: this.state.exif,
          category: this.state.category,
          s3Filename: this.state.s3Filename,
        })
      }, 1000)
    }
  }

  /**
   * componentWillUnmount
   * @return {void}
   */
  componentWillUnmount() {
    clearTimeout(this.state.timerId)
    this.state.onUnmount.map(cb => typeof cb === 'function' && cb())
  }

  onRotateClick = e => {
    e.stopPropagation()
    this.setState({ rotateCount: this.state.rotateCount + 1 })
  }

  onDeleteClick = e => {
    e.stopPropagation()

    if (
      window.confirm('この画像を削除しようとしています。よろしいですか？') &&
      window.confirm(
        '再度確認します。この画像を削除しようとしています。よろしいですか？',
      )
    ) {
      const { imageId, accessToken, env, deleteMe, apitype } = this.props
      const { promise, cancel } = makeCancelable(
        apitype === 'EXPO'
          ? expoDeleteImage(imageId, accessToken, env)
          : deleteImage(imageId, accessToken, env),
      )

      this.setState({ onUnmount: [...this.state.onUnmount, cancel] })

      promise
        .then(() => deleteMe())
        .catch(() =>
          this.setState({
            deleteError: true,
            timerId: setTimeout(
              () => this.setState({ deleteError: false }),
              3000,
            ),
          }),
        )
    }
  }

  onDeletePDF = e => {
    e.stopPropagation()

    if (window.confirm('アップロードしたPDFを削除します。よろしいですか？')) {
      const { imageId, accessToken, env, deleteMe } = this.props
      const { promise, cancel } = makeCancelable(
        deleteShipInventoryPDF(imageId, accessToken, env),
      )

      this.setState({ onUnmount: [...this.state.onUnmount, cancel] })

      promise
        .then(res => {
          if (res.ok) {
            return res.json()
          } else {
            throw new Error('削除失敗')
          }
        })
        .then(({ errors }) => {
          if (!errors) {
            deleteMe()
          }
        })
        .catch(() =>
          this.setState({
            deleteError: true,
            timerId: setTimeout(
              () => this.setState({ deleteError: false }),
              3000,
            ),
          }),
        )
    }
  }

  toggleCheckboxChange = () => {
    const { handleCheckboxChange, paperTypes, imageId } = this.props
    const imageInfo = Object.assign(
      {},
      { imageId: imageId },
      { paperTypes: paperTypes },
      { base64Image: this.state.base64Image },
      { s3Filename: this.state.s3Filename },
      { category: this.state.category },
    )
    handleCheckboxChange(imageInfo)
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const {
      base64Image,
      category,
      error,
      deleteError,
      exif,
      rotateCount,
      s3Filename,
      downloadTimes,
      printTimes,
      showDownloadTimeModal,
      showPrintTimeModal,
    } = this.state
    const {
      display,
      index,
      totalCount,
      paperTypes,
      isChecked,
      imageId,
      originalTime,
      apitype,
      documentStatus,
      canDelete,
    } = this.props

    const indexText = ` (${index + 1}/${totalCount})`

    // s3のファイル名を見てPDFかそれ以外のイメージかを判定する。
    const isPdf = s3Filename.toLowerCase().endsWith('pdf')

    const categoryName = ((category, indexText) => {
      const name = paperTypeName(paperTypes, category)
      return `${name}${indexText}`
    })(category, indexText)

    // originalTimeはDate.toISOString()=>'2021-06-15T02:58:04.874Z'を設定する。
    const pDate = moment(originalTime)
    const dispDate = pDate.isValid()
      ? pDate.format('YYYY年MM月DD日 HH時mm分')
      : '-'
    const dispDateStyle = {
      marginTop: '0.5rem',
      color: 'white',
      textAlign: 'left',
      fontSize: '1.25rem',
    }

    // 見積書、契約書、確認書のときのみの判定
    const isInventoryType = isInventory(apitype)

    // ダウンロード時刻の表示判定
    const showDownLoadTimes =
      isInventoryType && (downloadTimes || []).length > 0
    // 印刷時刻の表示判定
    const showPrintTimes = isInventoryType && (printTimes || []).length > 0

    // ダウンロード時刻の一覧表示
    const operationDateModal = () => {
      const modalDialog = {
        position: 'fixed',
        bottom: 56,
        right: 400,
        width: '25rem',
        height: '150px',
        padding: '8px',
        background: 'rgba(250, 250, 250, 0.8)',
        borderRadius: '0.5rem',
        zIndex: 20,
        boxShadow: 'rgba(0, 0, 0, 0.4) 0px 10px 10px',
        backdropFilter: 'blur(20px) saturate(180%)',
        visibility:
          showDownloadTimeModal || showPrintTimeModal ? 'visible' : 'hidden',
        opacity: showDownloadTimeModal || showPrintTimeModal ? 1 : 0,
      }
      const downloadHeader = {
        padding: '0.75rem',
        borderBottom: '1px solid #9e9e9e',
      }
      const downloadClose = {
        position: 'absolute',
        top: '5px',
        right: '10px',
        fontSize: '2em',
      }
      const timesView = {
        marginTop: '0.5rem',
        marginLeft: '1rem',
        height: '110px',
        overflow: 'scroll',
      }
      const dltimeView = {
        marginBottom: '0.3rem',
      }
      // downloadTimesは['2021-10-14T11:31:16.000+09:00', ]形式の配列
      // originalTimeとは形式が違う。

      return (
        <div style={ modalDialog }>
          <div style={ downloadHeader }>
            <span>
              {showDownloadTimeModal
                ? 'ダウンロードされた日時'
                : '印刷された日時'}
            </span>
            <a
              style={ downloadClose }
              onClick={ () =>
                this.setState({
                  showDownloadTimeModal: false,
                  showPrintTimeModal: false,
                })
              }
            >
              {'×'}
            </a>
          </div>
          <div style={ timesView }>
            {showDownloadTimeModal
              ? downloadTimes.map((t, i) => {
                const dltime = moment(t)
                const dispDate = dltime.isValid()
                  ? dltime.format('YYYY年MM月DD日 HH時mm分')
                  : '-'
                return (
                  <p key={ `dltime${i}` } style={ dltimeView }>
                    {dispDate}
                  </p>
                )
              })
              : printTimes.map((t, i) => {
                const printTime = moment(t)
                const displayDate = printTime.isValid()
                  ? printTime.format('YYYY年MM月DD日 HH時mm分')
                  : '-'
                return (
                  <p key={ `printTime${i}` } style={ dltimeView }>
                    {displayDate}
                  </p>
                )
              })}
          </div>
        </div>
      )
    }

    if (deleteError) {
      return (
        <DisplayBox displayed={ !!display }>
          <Flex>
            <Label>{categoryName}</Label>
            <ValidationErrorMessage message={ '画像の削除に失敗しました。' } />
          </Flex>
        </DisplayBox>
      )
    }

    // requesting
    if (!error && !base64Image) {
      return (
        <DisplayBox displayed={ !!display }>
          <Flex>
            <Loading />
          </Flex>
        </DisplayBox>
      )
      // エラー発生時
    } else if (error) {
      return (
        <DisplayBox displayed={ !!display }>
          <Flex>
            <Label>{categoryName}</Label>
            <ValidationErrorMessage message={ '画像が存在しません' } />
          </Flex>
        </DisplayBox>
      )
    } else {
      // 通常時
      // 時刻とダウンロード時刻を囲むdiv
      const bottomView = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'baseline',
        padding: '0 10px',
      }

      return (
        <DisplayBox displayed={ !!display }>
          <Flex>
            <Label>{categoryName}</Label>
            <div>
              <label className="checkbox-container">
                <input
                  type="checkbox"
                  style={ { position: 'absolute', zIndex: '1', right: '150px' } }
                  value={ imageId }
                  checked={ isChecked }
                  onChange={ this.toggleCheckboxChange }
                />
                <span className="checkmark" />
              </label>
              {isPdf ? (
                <object
                  style={ {
                    width: '800px',
                    height: '92vh',
                    filter:
                      documentStatus === 'Disposed' &&
                      'grayscale(100%) invert(24%)',
                  } }
                  data={ `data:application/pdf;base64,${base64Image}` }
                  type="application/pdf"
                />
              ) : (
                <Image
                  src={ `data:image/jpg;base64,${base64Image}` }
                  orientation={ exif.Orientation }
                  rotateCount={ rotateCount }
                />
              )}
              <div style={ bottomView }>
                <div style={ dispDateStyle }>{dispDate}</div>
                {showDownLoadTimes && (
                  <div style={ { position: 'relative', marginLeft: '2rem' } }>
                    {operationDateModal()}
                    <Button
                      onClick={ () =>
                        this.setState({ showDownloadTimeModal: true })
                      }
                    >
                      {'ダウンロード日時'}
                    </Button>
                  </div>
                )}
                {showPrintTimes && (
                  <div style={ { position: 'relative', marginLeft: '2rem' } }>
                    {operationDateModal()}
                    <Button
                      onClick={ () =>
                        this.setState({ showPrintTimeModal: true })
                      }
                    >
                      {'印刷日時'}
                    </Button>
                  </div>
                )}
              </div>
              {!isPdf && (
                <RotateButton onClick={ this.onRotateClick }>
                  <i className={ 'fa fa-rotate-right' } />
                </RotateButton>
              )}
              {apitype === 'EXPO' && (
                <DeleteButton onClick={ this.onDeleteClick }>
                  <i className={ 'fa fa-times' } />
                </DeleteButton>
              )}
              {apitype === 'SPDF' && canDelete && (
                <DeleteButton onClick={ this.onDeletePDF }>
                  <i className={ 'fa fa-times' } />
                </DeleteButton>
              )}
            </div>
          </Flex>
        </DisplayBox>
      )
    }
  }
}

export const mapStateToProps = state => ({
  env: state.env,
  accessToken: state.login.authentication.accessToken,
  paperTypes: state.master.data.paperType || [],
})

export default connect(mapStateToProps)(LazyImage)
