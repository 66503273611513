/**
 * わらのコンポーネント
 * @file
 */

/**
 * ライブラリ
 */
import { connect } from 'react-redux'

import StickyModalContent from 'src/components/commons/sticky-modal-content'

/**
 * React以外のライブラリ
 */
import switz from 'switz'

/**
 * reducer
 */
import { NETWORK_STATUSES } from 'src/reducers/network-status'
import {
  createAsyncGetStaffsAction,
  createAsyncGetDockStickiesAction,
} from 'src/reducers/wara/async'
import { UPDATE as UPDATE_DISPLAY_DATE } from 'src/reducers/display-date'
import {
  RESET,
  ACTION_TYPES as NETWORK_ACTION_TYPES,
} from 'src/reducers/network-status'
import { actionCreators as $ } from 'src/reducers/wara'
import { ACTION_TYPES } from 'src/reducers/user-preset'

import config from 'src/config'

/**
 map state to props
 * @param  {object} state Mapping state
 * @return {object}       mapped state as props
 */
export const mapStateToProps = state => {
  const masterRegions = state.master.data.regions
    ? state.master.data.regions
    : []
  return {
    regions: state.wara.data.regions,
    modal: state.wara.modal,
    accessToken: state.login.authentication.accessToken,
    userid: state.login.authentication.userid,
    isLoggedIn: state.networkStatus.login === NETWORK_STATUSES.SUCCESS,
    fixedStickiesLoaded:
      state.networkStatus.getDockSticky === NETWORK_STATUSES.SUCCESS,
    dockStickiesLoaded:
      state.networkStatus.getSticky === NETWORK_STATUSES.SUCCESS,
    wara: state.wara.data,
    master: state.master.data,
    // 表示するものかつマスタに含まれる有効なリージョンのみを取り出す
    regionIds: ((state.userPreset.data || {}).regions || [])
      .filter(x => x.display && masterRegions.map(x => x.id).includes(x.id))
      .map(x => x.id),
    env: state.env,
  }
}

/**
 * map dispatch to props
 * @param  {function} dispatch dispatcher
 * @param  {object}    props    props
 * @return {object}             Mapped dispatchers as props
 */
export const mapDispatchToProps = dispatch => {
  return {
    /**
     * 未配置付箋を全て取得する
     * @param  {number} year        year
     * @param  {number} month       month
     * @param  {number} day         day
     * @param  {string} accessToken access token
     * @return {void}
     */
    __getDockStickies: env => (year, month, day, accessToken) =>
      dispatch(
        createAsyncGetDockStickiesAction(year, month, day, accessToken, env),
      ),

    /**
     * わらのデータをリフレッシュする
     * @param  {string} datestring  YYYY-MM-DD
     * @param  {string} accessToken access token
     * @return {void}
     */
    __refresh: env => (datestring, accessToken) => {
      // ss取得
      dispatch(createAsyncGetStaffsAction(accessToken, datestring, env))
      // 未配置付箋の取得
      dispatch(
        createAsyncGetDockStickiesAction(
          ...datestring.split('-'),
          accessToken,
          env,
        ),
      )
      // displayDateの格納
      dispatch({
        type: UPDATE_DISPLAY_DATE,
        payload: { displayDate: datestring },
      })
    },

    setUserPreset: userPreset =>
      dispatch({
        type: ACTION_TYPES.USERPRESET_GET_SUCCESS,
        payload: userPreset,
      }),

    /**
     * ネットワーク状態をリセットする処理
     * @return {void}
     */
    resetPostStatus: () => dispatch({ type: RESET, target: 'postSticky' }),

    /**
     * 入力内容を消す処理
     * @return {void}
     */
    removeEntries: () => {
      dispatch($.updateStage(false))
      dispatch({
        type: NETWORK_ACTION_TYPES.RESET,
        payload: { target: 'getStickySearch' },
      })
    },

    // モーダルを開き、付箋の編集を開始する
    editSticky: (sticky, location) => {
      const type = switz(sticky.json().stickyTypeId, s =>
        s
          .case(config.stickyType.normal, () => 'normal')
          .case(config.stickyType.claimer, () => 'claimer')
          .case(config.stickyType.memo, () => 'memo')
          .case(config.stickyType.private, () => 'private')
          .case(config.stickyType.payment, () => 'payment')
          .default(() => 'normal'),
      )
      dispatch(
        $.openModal(StickyModalContent, { type, location, sticky }, sticky),
      )
    },
  }
}

/**
 * map state to props
 * @param  {object} stateProps    state props
 * @param  {object} dispatchProps dispatch props
 * @param  {object} ownProps      own props
 * @return {object}               props
 */
export const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    // merge
    getDockStickies: dispatchProps.__getDockStickies(stateProps.env),
    refresh: dispatchProps.__refresh(stateProps.env),
    // make private
    __getDockStickies: void 0,
    __refresh: void 0,
  }
}

/**
 * export
 */
export default Component => {
  /**
   * Reduxと繋がったわら
   * @type {ReactComponent}
   */
  const ConnectedWara = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  )(Component)

  return ConnectedWara
}
