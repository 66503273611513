import utils from 'src/lib/api-utils'
import convertAfterGet from './lib/convert-after-get'

export const get = (stickyContactIds, accessToken, env) => {
  if (stickyContactIds.length === 0) {
    return new Promise(resolve => resolve([]))
  }
  const base = utils.createEndpoint(env, 'payments')
  const endpoint = `${base}/${stickyContactIds.join()}`

  return fetch(endpoint, {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  })
    .then(res => res.json())
    .then(data => convertAfterGet(data))
}

export default get
