/**
 * メール送信reducer
 */

import { CALL_API } from 'redux-api-middleware'
import { NETWORKER } from 'src/middlewares/network-monitor'
import utils from 'src/lib/api-utils'
import switz from 'switz'
import update from 'immutability-helper'
import config from 'src/config'
import { ACTION_TYPES as NETWORK_ACTION_TYPES } from 'src/reducers/network-status'

/**
 * initial state
 * @type {object}
 */
export const initialSendMailState = {
  draft: {
    staffId: -1,
    message: '',
  },
}

export const SENDMAIL_REQUEST = 'SENDMAIL.SENDMAIL_REQUEST'
export const SENDMAIL_SUCCESS = 'SENDMAIL.SENDMAIL_SUCCESS'
export const SENDMAIL_FAILURE = 'SENDMAIL.SENDMAIL_FAILURE'
export const UPDATE_DRAFT = 'SENDMAIL.UPDATE_DRAFT'

/**
 * action bulk exports
 * @type {object}
 */
export const ACTION_TYPES = {
  SENDMAIL_REQUEST,
  SENDMAIL_SUCCESS,
  SENDMAIL_FAILURE,
  UPDATE_DRAFT,
}

/**
 * AsyncSendMailActionを作成するアクションクリエーター
 * @param  {{toAddress: string, }} params メールパラメータ
 * @param  {string} accessToken アクセストークン
 * @param  {{ isDebugMode: boolean }} オプション
 * @return {object} redux-api-middleware に渡す非同期処理情報を内包したアクション
 */
export const createAsyncSendMailAction = (
  params,
  accessToken,
  { isDebugMode },
  env,
) => {
  const isTestAddress = /^info.*@ai-ot\.com$/.test(params.toAddress)

  const body = JSON.stringify(
    isDebugMode
      ? {
        ...params,
        toAddress: isTestAddress
          ? config.fallbackTestEmail
          : params.toAddress,
      }
      : params,
  )

  const endpoint = `${utils.createEndpoint(env, 'sendMail')}`

  /* eslint-disable no-console */
  !__TEST__ && console.log(endpoint, body)
  /* eslint-enable no-console */

  return {
    [CALL_API]: {
      endpoint,
      method: 'POST',
      headers: { Authorization: `Bearer ${accessToken}` },
      body,
      types: [
        {
          type: SENDMAIL_REQUEST,
          meta: {
            [NETWORKER]: {
              target: 'sendMail',
              networkActionType: NETWORK_ACTION_TYPES.REQUEST,
            },
          },
        },
        {
          type: SENDMAIL_SUCCESS,
          meta: {
            [NETWORKER]: {
              target: 'sendMail',
              networkActionType: NETWORK_ACTION_TYPES.SUCCESS,
            },
          },
        },
        {
          type: SENDMAIL_FAILURE,
          meta: {
            [NETWORKER]: {
              target: 'sendMail',
              networkActionType: NETWORK_ACTION_TYPES.FAILURE,
            },
          },
        },
      ],
    },
  }
}

/**
 * sendMail reducer
 * @param  {object} [state=initialSendMailState] previous state
 * @param  {object} action                    dispatched action
 * @return {object}                            next state
 */
export default (state = initialSendMailState, action) => {
  const { type, payload } = action
  return switz(type, s =>
    s
      // reducer for sendMail updating
      .case(SENDMAIL_REQUEST, () => state)
      .case(SENDMAIL_SUCCESS, () => {
        /* eslint-disable no-console */
        !__TEST__ && console.log(payload)
        /* eslint-enable no-console */
        return state
      })
      .case(SENDMAIL_FAILURE, () => state)
      .case(UPDATE_DRAFT, () => {
        const { params } = payload
        if (params) {
          return update(state, {
            draft: { $set: { ...state.draft, ...params } },
          })
        } else {
          // falsyを渡すとリセット
          return update(state, { $set: initialSendMailState })
        }
      })
      .default(() => state),
  )
}
