import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { create as createEnvAction } from 'src/reducers/env'
import Checkbox from 'src/styled/form/checkbox'

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
const mapStateTopProps = state => {
  return {
    HTTPSEnabled: state.env.API_HTTPS,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    enableHTTPS: value => dispatch(createEnvAction.update('API_HTTPS', value)),
  }
}

export class DebugToggleButton extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // stateProps
    HTTPSEnabled: PropTypes.bool.isRequired,
    // dispatchProps
    enableHTTPS: PropTypes.func.isRequired,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate() {
    return true
  }

  onToggleClick = () => this.props.enableHTTPS(!this.props.HTTPSEnabled)

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { HTTPSEnabled } = this.props

    return (
      <Checkbox
        value={ HTTPSEnabled }
        checked={ HTTPSEnabled }
        onChange={ this.onToggleClick }
        labelText={ 'APIとの通信にHTTPを使用する' }
      />
    )
  }
}

export default connect(mapStateTopProps, mapDispatchToProps)(DebugToggleButton)
