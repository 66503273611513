/**
 * @file Reactレンダリングのエントリーポイントのファイル。
 */

// react
import React from 'react'
import { render } from 'react-dom'

// component
import AppContainer from 'src/components/app-container'

// styles
import 'font-awesome/css/font-awesome.min.css'
import 'src/styles/main.scss'

// デフォルトのAPIの挙動を変更する
import 'src/custom/fetch'

if (__ENV__.DEBUG) {
  const { setConfig } = require('react-hot-loader')
  setConfig({ logLevel: 'no-errors-please' })
}

/**
 * レンダリングのエントリポイントとなるDOMエレメントです。
 * @type {DOMElement}
 */
const MOUNT_NODE = document.getElementById('app')

// Go!
render(<AppContainer />, MOUNT_NODE)
