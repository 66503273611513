import React from 'react'
import PropTypes from 'prop-types'

// action
import { NETWORK_STATUSES } from 'src/reducers/network-status'

// library
import classNames from 'classnames'
import switz from 'switz'
import Sticky from 'src/lib/class-sticky'
import requestPostSticky from 'src/lib/sticky-api/post'

// config
import config from 'src/config'

/**
 * 付箋のドロップのターゲットを提供します。
 * @type {ReactComponent}
 */
export default class StickyEmpty extends React.Component {
  /**
   * Validation
   * @type {object}
   */
  static propTypes = {
    // ownProps
    location: PropTypes.shape({
      regionIndex: PropTypes.number.isRequired,
      listIndex: PropTypes.number.isRequired,
    }).isRequired,
    isAssignable: PropTypes.bool,

    // stateProps
    wara: PropTypes.shape({
      regions: PropTypes.arrayOf(
        PropTypes.shape({
          lists: PropTypes.arrayOf(
            PropTypes.shape({
              stickies: PropTypes.arrayOf(PropTypes.instanceOf(Sticky)),
            }),
          ),
        }),
      ).isRequired,
    }).isRequired,
    env: PropTypes.object.isRequired,
    accessToken: PropTypes.string.isRequired,
    networkStatuses: PropTypes.object.isRequired,
    splitSourceLocation: PropTypes.object,
    displayDate: PropTypes.string.isRequired,

    // dispatchProps
    updateSticky: PropTypes.func.isRequired,
    addSplitSticky: PropTypes.func.isRequired,
    addToSplitList: PropTypes.func.isRequired,

    // DnD
    canDrop: PropTypes.bool.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    isOver: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    isAssignable: false,
    splitSourceLocation: {},
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return this.props !== nextProps
  }

  /**
   * 分裂モードになっているかどうか
   * @return {boolean}
   */
  isSplitMode = () => Object.keys(this.props.splitSourceLocation).length > 0

  /**
   * 分裂クリックが押された
   * @return {void}
   */
  onClickToSplit = async () => {
    if (this.isSplitMode()) {
      const {
        // ownProps
        location: locationDest,
        // stateProps
        wara,
        splitSourceLocation: locationSrc,
        displayDate,
        accessToken,
        env,
        // dispatchProps
        updateSticky,
        addSplitSticky,
        addToSplitList,
      } = this.props

      // NOTE splitterは必ずDOCKから渡される
      const sticky = wara.dock.stickies[locationSrc.stickyIndex]

      const listDest =
        wara.regions[locationDest.regionIndex].lists[locationDest.listIndex]

      const {
        regionId,
        staffId,
        stickies: { length },
      } = listDest

      const newLocationDest = {
        ...locationDest,
        stickyIndex: length,
      }

      // 1. 仮予約付箋を作成してPOST
      // 2. 仮予約付箋をわらに追加
      // 3. 親付箋に仮予約付箋のIDを保管
      const originalStickyId = sticky.id
      const client = sticky.findClient() || {}
      // 仮予約付箋：必要最小限の情報のみを設定する。
      // contactsにこれだけの情報がないとPOSTエラーになる。
      const nextSticky = new Sticky({
        id: null,
        parentId: sticky.id,
        regionId,
        staffId,
        stickyTypeId: config.stickyType.normal,
        stickyStatusId: config.stickyStatus.split,
        receivedAt: sticky._props.receivedAt,
        displayDate,
        wishDateTimes: [{ ...sticky._props.wishDateTimes[0] }],
        orderTypeId: config.orderType.new,
        companyId: sticky._props.companyId,
        contacts: [
          [
            {
              displayOrder: 1,
              isClient: true,
              name: client.name,
              phoneNumber: client.phoneNumber,
              prefCode: client.prefCode,
              cityCode: client.cityCode,
            },
          ],
          [],
          [],
          [],
        ],
        determinedDateTime: { date: displayDate },
        displayOrder: length,
      })
        .setUserValue('splitFrom', originalStickyId)
        .setUserValue('splitOf', [])

      let result
      try {
        result = await requestPostSticky(nextSticky, accessToken, env)
      } catch (e) {
        alert('ネットワークエラー')
        return
      }
      // 仮アサインした付箋をわらに追加する
      addSplitSticky(newLocationDest, new Sticky(result, { normalize: false }))
      // 分裂した個数はstoreに入れておく
      addToSplitList(locationDest)

      // 親付箋のUserValueに分割した付箋のIDを保存する。DB保存はしない。
      // 配置後ドックに戻すとstaffIdが設定されているのでクリアする。
      const ids = sticky.getUserValue('splitOf') || []
      const nextOriginalstickyDiff = new Sticky(
        { id: sticky.id, staffId: -1 },
        { normalize: false },
      ).setUserValue('splitOf', [...ids, result.id])
      updateSticky(nextOriginalstickyDiff, locationSrc)
    }
  }

  /**
   * render
   * @return {ReactDOM} rendered result
   */
  render() {
    const {
      // ownProps
      location, // { regionIndex, listIndex }
      isAssignable,
      // stateProps
      networkStatuses,
    } = this.props

    const { regionIndex, listIndex } = location

    // React-DnD module
    const { connectDropTarget } = this.props
    // React-DnD result
    const { canDrop, isOver } = this.props

    const isOnDock = regionIndex === -1 || listIndex === -1

    const classes = classNames({
      sticky: true,
      'drop-target': true,
      'sticky-empty': true,
      droppable: canDrop,
      hover: isOver,
    })

    const isSplitMode = this.isSplitMode()

    let assignedNetworkStatus = false
    if (!isOnDock) {
      if (
        networkStatuses.getStaffs === NETWORK_STATUSES.SUCCESS &&
        networkStatuses.getSticky === NETWORK_STATUSES.SUCCESS
      ) {
        assignedNetworkStatus = NETWORK_STATUSES.SUCCESS
      } else if (
        networkStatuses.getStaffs === NETWORK_STATUSES.FAILURE ||
        networkStatuses.getSticky === NETWORK_STATUSES.FAILURE
      ) {
        assignedNetworkStatus = NETWORK_STATUSES.FAILURE
      } else {
        assignedNetworkStatus = NETWORK_STATUSES.TRYING
      }
    }

    const { className, message } = isOnDock
      ? switz(networkStatuses.getDockSticky, s =>
        s
          .case(NETWORK_STATUSES.NOT_YET, () => ({
            className: 'network-not-yet',
            message: <i className={ 'fa fa-refresh fa-spin fa-3x fa-fw' } />,
          }))
          .case(NETWORK_STATUSES.TRYING, () => ({
            className: 'network-trying',
            message: <i className={ 'fa fa-refresh fa-spin fa-3x fa-fw' } />,
          }))
          .case(NETWORK_STATUSES.SUCCESS, () =>
            isSplitMode
              ? {
                className: 'network-success clikking-disabled',
                message: null,
              }
              : {
                className: 'network-success',
                message: '付箋をここにドロップして配置',
              },
          )
          .case(NETWORK_STATUSES.FALIURE, () => ({
            className: 'network-failure',
            message: 'サーバー通信エラー',
          }))
          .default(() => ({
            className: 'network-failure',
            message: 'サーバー通信エラー',
          })),
      )
      : switz(assignedNetworkStatus, s =>
        s
          .case(NETWORK_STATUSES.TRYING, () => ({
            className: 'network-trying',
            message: <i className={ 'fa fa-refresh fa-spin fa-3x fa-fw' } />,
          }))
          .case(NETWORK_STATUSES.SUCCESS, () =>
            isSplitMode && isAssignable
              ? {
                className: 'network-success clikking-enabled',
                message: 'ここをクリックして仮予約',
              }
              : {
                className: isAssignable
                  ? 'network-success sticky-droppable'
                  : 'network-success',
                message: isAssignable ? '付箋をここにドロップして配置' : '',
              },
          )
          .case(NETWORK_STATUSES.FALIURE, () => ({
            className: 'network-failure',
            message: 'サーバー通信エラー',
          }))
          .default(() => ({
            className: 'network-failure',
            message: 'サーバー通信エラー',
          })),
      )

    const isDroppable =
      (isOnDock || (!isOnDock && isAssignable)) &&
      !isSplitMode &&
      ((isOnDock &&
        networkStatuses.getDockSticky === NETWORK_STATUSES.SUCCESS) ||
        (!isOnDock && assignedNetworkStatus === NETWORK_STATUSES.SUCCESS))

    const DOM = (
      <div className={ classes } onClick={ this.onClickToSplit }>
        <span
          className={ 'empty-sticky-title ' + className }
          style={ {
            color: isSplitMode && isAssignable ? 'red' : void 0,
          } }
        >
          {message}
        </span>
      </div>
    )

    // ドロップできる条件:
    //  - サーバーとの通信が成功
    //  - preventDrop属性が付与されていない
    return isDroppable ? connectDropTarget(DOM) : DOM
  }
}
