import React from 'react'
import PropTypes from 'prop-types'
import connect from './connect'

// components
import SearchResults from 'src/components/commons/search-results'
import SearchPagenation from 'src/components/commons/search-pagenation'

// lib
import config from 'src/config'
import { search as requestSearch } from 'src/lib/search-api'
import Sticky from 'src/lib/class-sticky'

export class Search extends React.Component {
  /**
   * initialQueryState
   * @type {object}
   */
  static initialQueryState = {
    basicSearch: true,
    freewordSearch: null,
    detailedSearch: null,
    limit: config.initialSearchLimit,
    offset: 0,
  }

  static initialResultState = {
    stickies: [],
    esFullCount: 0,
    fullCount: 0,
    hlFullCount: 0,
  }

  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    route: PropTypes.oneOf(['assign', 'call', 'history']).isRequired,
    disabled: PropTypes.bool.isRequired,
    phoneNo: PropTypes.string.isRequired,
    tabOrder: PropTypes.number,
    // stateProps
    accessToken: PropTypes.string.isRequired,
    env: PropTypes.object.isRequired,
    update: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    tabOrder: 0,
  }

  state = {
    query: { ...Search.initialQueryState },
    status: 'not_yet',
    result: { ...Search.initialResultState },
    page: 0,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps, nextState) {
    // TODO: 最適化の余地あり
    return (
      this.state !== nextState ||
      this.props.disabled !== nextProps.disabled ||
      this.props.phoneNo !== nextProps.phoneNo
    )
  }

  /**
   * 検索ボタンを押した時のclickハンドラ
   * @return {void}
   */
  doSearch = (query = {}, callback) => {
    const { accessToken, env, phoneNo } = this.props

    this.setState({ status: 'request' })
    // this.props.clearStage()

    requestSearch({ ...this.state.query, ...query, phoneNo }, accessToken, env)
      .then(result => {
        this.setState({
          result: {
            ...result,
            stickies: (result.stickies || []).map(sticky => new Sticky(sticky)),
            esFullCount: result.esFullCount || 0,
            fullCount: result.fullCount || 0,
            hlFullCount: result.hlFullCount || 0,
          },
          status: 'success',
        })
        typeof callback === 'function' && callback()
      })
      .catch(err => {
        console.error(err)
        this.setState({
          result: { stickies: [] },
          status: 'failure',
        })
      })
  }

  /**
   * 検索結果を除去
   * @return {void} [description]
   */
  clearResult = () =>
    this.setState({
      status: 'not_yet',
      result: { ...Search.initialResultState },
    })

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { route, disabled, update, reset, tabOrder } = this.props
    const { query, status, result, page } = this.state

    return (
      <div className={ 'contact-search-wrap' }>
        <button
          className={ 'button button-open label-inputs-wrap-button' }
          onClick={
            // 引数をクエリにするので、onClickのイベントオブジェクトを渡さない
            () => this.doSearch(void 0)
          }
          disabled={ disabled }
          tabIndex={ tabOrder }
        >
          <i className={ 'fa fa-search' } />
        </button>
        {status !== 'not_yet' && (
          <div>
            <SearchResults
              route={ route }
              result={ result }
              query={ query }
              status={ status }
              closeMe={ this.clearResult }
              callback={ this.clearResult }
              update={ update }
              reset={ reset }
              renderPagenation={ () => (
                <SearchPagenation
                  page={ page }
                  fullCount={ result.fullCount || 0 }
                  limit={ query.limit }
                  onPageMove={ nextPage => {
                    const offset = nextPage * query.limit
                    this.doSearch({ offset }, () =>
                      this.setState({ page: nextPage }),
                    )
                  } }
                />
              ) }
            />
          </div>
        )}
      </div>
    )
  }
}

export default connect(Search)
