import update from 'immutability-helper'
import Sticky from 'src/lib/class-sticky'

/**
 * タイプ定義
 * @type {string}
 */
const type = 'WARA.CLOSE_MODAL'

/**
 * exportation
 */
export default {
  /**
   * action type
   * @type {string}
   */
  type,

  /**
   * action creator
   * @param  {function} callback [description]
   * @return {object}         [description]
   */
  creator: callback => {
    return { type, payload: { callback } }
  },

  /**
   * action handler
   * @param  {object} state   Immutableなstateです。このオブジェクトを変更すべきではありません。
   * @param  {object} action 関数に与える引数です。
   * @return {object}         適切にUpdateされた新しいStateを返却します。
   */
  handler: (state, action) => {
    const callback = action
      ? action.payload
        ? action.payload.callback
        : false
      : false

    typeof callback === 'function' && callback()

    // TODO: ここ、影響範囲が大きすぎるので、別のactionを組み合わせて目的を達成できるようにするべきだろう
    return update(state, {
      modal: {
        isOpen: { $set: false },
        content: { $set: void 0 },
        props: { $set: void 0 },
        stage: {
          $set: state.modal.resetOnClose
            ? new Sticky({})
            : state.modal.stage
              ? state.modal.stage.clone()
              : new Sticky({}),
        },
        resetOnClose: { $set: true },
      },
    })
  },
}
