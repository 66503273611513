import utils from 'src/lib/api-utils'

export const requestPutStaffInfo = (staffInfo, accessToken, env) => {
  const endpoint = `${utils.createEndpoint(env, 'staffInfo')}`
  const body = JSON.stringify(staffInfo)

  return fetch(endpoint, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body,
  }).then(res => {
    if (res.ok) {
      return res.json()
    } else {
      throw 'PUTエラー'
    }
  })
}

export default requestPutStaffInfo
