import update from 'immutability-helper'

/**
 * マルシー返金
 */

const SET_MARUC_REFUND = 'MARUC_REFUND.SET_MARUC_REFUND' // 返金情報の設定/更新
//
const ADD_MARUC_REFUND_PAYMENT = 'MARUC_REFUND.ADD_MARUC_REFUND_PAYMENT' // 現金残金情報の追加
const DELETE_MARUC_REFUND_PAYMENT = 'MARUC_REFUND.DELETE_MARUC_REFUND_PAYMENT' // 現金残金情報の削除
const UPDATE_MARUC_REFUND_PAYMENT = 'MARUC_REFUND.UPDATE_MARUC_REFUND_PAYMENT' // 現金残金情報の更新
//
const ADD_MARUC_REFUND_ACCOUNT = 'MARUC_REFUND.ADD_MARUC_REFUND_ACCOUNT' // 売掛金残金情報の追加
const DELETE_MARUC_REFUND_ACCOUNT = 'MARUC_REFUND.DELETE_MARUC_REFUND_ACCOUNT' // 売掛金残金情報の削除
const UPDATE_MARUC_REFUND_ACCOUNT = 'MARUC_REFUND.UPDATE_MARUC_REFUND_ACCOUNT' // 売掛金残金情報の更新
const UPDATE_MARUC_REFUND_ACCOUNT_REMAIN =
  'MARUC_REFUND.UPDATE_MARUC_REFUND_ACCOUNT_REMAIN' // 売掛金残金情報の更新

export const initialState = {}

// アクションタイプ
export const ACTION_TYPES = {
  SET_MARUC_REFUND,
  UPDATE_MARUC_REFUND_PAYMENT,
  ADD_MARUC_REFUND_PAYMENT,
  DELETE_MARUC_REFUND_PAYMENT,
  ADD_MARUC_REFUND_ACCOUNT,
  DELETE_MARUC_REFUND_ACCOUNT,
  UPDATE_MARUC_REFUND_ACCOUNT,
  UPDATE_MARUC_REFUND_ACCOUNT_REMAIN,
}

// アクション
export const actionCreators = {
  setMarucRefund: refund => ({
    type: SET_MARUC_REFUND,
    payload: { refund },
  }),
  addMarucRefundPayment: (stickyId, paymentIndex, refund) => ({
    type: ADD_MARUC_REFUND_PAYMENT,
    payload: { stickyId, paymentIndex, refund },
  }),
  deleteMarucRefundPayment: (stickyId, paymentIndex, refundIndex) => ({
    type: DELETE_MARUC_REFUND_PAYMENT,
    payload: { stickyId, paymentIndex, refundIndex },
  }),
  updateMarucRefundPayment: (
    stickyId,
    paymentIndex,
    refundIndex,
    diffProps,
  ) => ({
    type: UPDATE_MARUC_REFUND_PAYMENT,
    payload: { stickyId, paymentIndex, refundIndex, diffProps },
  }),
  addMarucRefundAccount: (stickyId, accountIndex, historyIndex, refund) => ({
    type: ADD_MARUC_REFUND_ACCOUNT,
    payload: { stickyId, accountIndex, historyIndex, refund },
  }),
  deleteMarucRefundAccount: (
    stickyId,
    accountIndex,
    historyIndex,
    refundIndex,
  ) => ({
    type: DELETE_MARUC_REFUND_ACCOUNT,
    payload: { stickyId, accountIndex, historyIndex, refundIndex },
  }),
  updateMarucRefundAccount: (
    stickyId,
    accountIndex,
    historyIndex,
    refundIndex,
    diffProps,
  ) => ({
    type: UPDATE_MARUC_REFUND_ACCOUNT,
    payload: {
      stickyId,
      accountIndex,
      historyIndex,
      refundIndex,
      diffProps,
    },
  }),
  updateMarucRefundAccountRemain: (stickyId, accountIndex, diffProps) => ({
    type: UPDATE_MARUC_REFUND_ACCOUNT_REMAIN,
    payload: { stickyId, accountIndex, diffProps },
  }),
}

// リデューサ
export const partialReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_MARUC_REFUND: {
      const { refund } = payload
      return update(state, {
        [refund.stickyId]: {
          $set: {
            stickyId: refund.stickyId,
            payments: refund.payments,
            accounts: refund.accounts,
            modified: false,
          },
        },
      })
    }
    case ADD_MARUC_REFUND_PAYMENT: {
      const { stickyId, paymentIndex, refund } = payload
      return update(state, {
        [stickyId]: {
          payments: {
            [paymentIndex]: {
              refunds: { $push: [refund] },
            },
          },
          modified: { $set: true },
        },
      })
    }
    case DELETE_MARUC_REFUND_PAYMENT: {
      const { stickyId, paymentIndex, refundIndex } = payload
      return update(state, {
        [stickyId]: {
          payments: {
            [paymentIndex]: {
              refunds: { $splice: [[refundIndex, 1]] },
            },
          },
          modified: { $set: true },
        },
      })
    }
    case UPDATE_MARUC_REFUND_PAYMENT: {
      const { stickyId, paymentIndex, refundIndex, diffProps } = payload
      const orgRefund =
        state[stickyId].payments[paymentIndex].refunds[refundIndex]
      return update(state, {
        [stickyId]: {
          payments: {
            [paymentIndex]: {
              refunds: {
                [refundIndex]: {
                  $set: { ...orgRefund, ...diffProps },
                },
              },
            },
          },
          modified: { $set: true },
        },
      })
    }
    case ADD_MARUC_REFUND_ACCOUNT: {
      const { stickyId, accountIndex, historyIndex, refund } = payload
      return update(state, {
        [stickyId]: {
          accounts: {
            [accountIndex]: {
              histories: {
                [historyIndex]: {
                  refunds: { $push: [refund] },
                },
              },
            },
          },
          modified: { $set: true },
        },
      })
    }
    case DELETE_MARUC_REFUND_ACCOUNT: {
      const { stickyId, accountIndex, historyIndex, refundIndex } = payload
      return update(state, {
        [stickyId]: {
          accounts: {
            [accountIndex]: {
              histories: {
                [historyIndex]: {
                  refunds: { $splice: [[refundIndex, 1]] },
                },
              },
            },
          },
          modified: { $set: true },
        },
      })
    }
    case UPDATE_MARUC_REFUND_ACCOUNT: {
      const {
        stickyId,
        accountIndex,
        historyIndex,
        refundIndex,
        diffProps,
      } = payload
      const orgRefund =
        state[stickyId].accounts[accountIndex].histories[historyIndex].refunds[
          refundIndex
        ]
      return update(state, {
        [stickyId]: {
          accounts: {
            [accountIndex]: {
              histories: {
                [historyIndex]: {
                  refunds: {
                    [refundIndex]: {
                      $set: { ...orgRefund, ...diffProps },
                    },
                  },
                },
              },
            },
          },
          modified: { $set: true },
        },
      })
    }
    case UPDATE_MARUC_REFUND_ACCOUNT_REMAIN: {
      const { stickyId, accountIndex, diffProps } = payload
      const orgRemain = state[stickyId].accounts[accountIndex].remain
      return update(state, {
        [stickyId]: {
          accounts: {
            [accountIndex]: {
              $merge: { remain: { ...orgRemain, ...diffProps } },
            },
          },
          modified: { $set: true },
        },
      })
    }
    default:
      return state
  }
}

export default partialReducer
