import React from 'react'
import PropTypes from 'prop-types'

// lib
import Sticky from 'src/lib/class-sticky'

export class PayereSelect extends React.PureComponent {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    label: PropTypes.string.isRequired,
    paymentIndex: PropTypes.number.isRequired, // 更新する現金・売掛金のインデックス
    payment: PropTypes.object.isRequired, // 現金・売掛金の情報
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
    disabled: PropTypes.bool,
    onChangeMeta: PropTypes.func.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    disabled: false,
  }

  /**
   * 支払い者の選択が変更された
   * @param {*} e
   */
  onChange = e => {
    const { paymentIndex, onChangeMeta } = this.props
    const contactId = parseInt(e.target.value, 10)
    onChangeMeta('id', contactId, paymentIndex)
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { label, sticky, route, payment } = this.props
    const isConfirmed = payment.data.isConfirmed || false
    const disabled = this.props.disabled || isConfirmed || route !== 'assign'
    const contacts = sticky.json().contacts || []

    const payers = contacts
      .reduce(
        (prev, tabContacts, tabIndex) => [
          ...prev,
          ...(tabContacts.map((contact, contactIndex) => ({
            ...contact,
            tabIndex,
            contactIndex,
          })) || []),
        ],
        [],
      )
      .filter(x => !!x) // remove null
      .map(x => ({
        id: x.id,
        name: x.name,
        tabIndex: x.tabIndex,
        contactIndex: x.contactIndex,
      }))

    return (
      <dl className={ 'label-inputs-wrap ' }>
        <dt>
          <label htmlFor={ `payer-select-name-${route}` }>{label}</label>
        </dt>
        <dd className={ 'input-wrap' }>
          <select
            className={ 'short' }
            value={ payment.meta.id } // contactIdは必ず設定されているのでnullチェック不要
            id={ `payer-select-name-${route}` }
            name={ `payer-select-name-${route}` }
            disabled={ disabled || payers.length === 0 }
            onChange={ this.onChange }
          >
            {payers.map(payer => (
              <option key={ payer.id } value={ payer.id }>
                {payer.name || '(名前未入力)'}
              </option>
            ))}
          </select>
        </dd>
      </dl>
    )
  }
}

export default PayereSelect
