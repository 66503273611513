import { connect } from 'react-redux'
import { actionCreators as $ } from 'src/reducers/wara'

/**
 map state to props
 * @param  {object} state state
 * @return {object}      mapped state
 */
const mapStateToProps = state => {
  const prefCodes = state.master.data.prefCodes || []
  const masterRegions = state.master.data.regions || []

  const prefCodesClassified = prefCodes.reduce((prev, pref) => {
    if (prev[pref.regionId]) {
      prev[pref.regionId].push(pref)
    } else {
      prev[pref.regionId] = [pref]
    }
    return prev
  }, {})

  return {
    masterRegions,
    prefCodes,
    prefCodesClassified,
    regionsFilter: state.wara.dissociatedRegionsFilter,
    prefsFilter: state.wara.dissociatedPrefsFilter,
    marucFilter: state.wara.dissociatedMarucFilter,
  }
}

/**
 * map dispatch to props
 * @param  {function} dispatch dispatcher
 * @return {object}    mapped dispatcher as props
 */
const mapDispatchToProps = dispatch => ({
  /**
   * 未配置付箋の表示地域フィルターを更新
   * @param  {array} newRegionsFilter [description]
   * @return {void}
   */
  updateDissociatesRegionsFilter: newRegionsFilter =>
    dispatch($.updateDissociatesRegionsFilter(newRegionsFilter)),

  updateDissociatesPrefsFilter: newPrefsFilter =>
    dispatch($.updateDissociatesPrefsFilter(newPrefsFilter)),

  toggleDissociatesMarucFilter: () =>
    dispatch($.toggleDissociatesMarucFilter()),
})

export default SelectRegions =>
  connect(mapStateToProps, mapDispatchToProps)(SelectRegions)
