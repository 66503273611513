import React from 'react'
import PropTypes from 'prop-types'
import Authorize from 'src/components/commons/authorize'
import CommonHeader from 'src/components/layouts/common-header'
import CommonFooter from 'src/components/layouts/common-footer'
import App from './app'
import config from 'src/config'
import { qsparse } from 'src/lib/format'
import { today, lastWeek } from 'src/lib/moment'

export class RewardView extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    location: PropTypes.shape({ search: PropTypes.string.isRequired })
      .isRequired,
    history: PropTypes.shape({ replace: PropTypes.func.isRequired }).isRequired,
  }

  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    // クエリをパースして画面に渡す
    const initialQuery = {
      ...RewardView.initialQueryState,
      ...qsparse(props.location.search),
    }
    this.state = { query: initialQuery }
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps, nextState) {
    return this.state.query !== nextState.query
  }

  /**
   * @type {Object}
   */
  static initialQueryState = {
    startDate: lastWeek(today(), config.startDayOfWeek),
    regionId: null,
  }

  onChange = nextQuery =>
    this.setState({ query: { ...this.state.query, ...nextQuery } })

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { query } = this.state
    const {
      history: { replace },
    } = this.props

    return (
      <div className={ 'callview__wrapper' }>
        <CommonHeader route={ 'accounting' } subRoute={ 'reward' } />

        <Authorize roles={ config.roles.accounting }>
          <App query={ query } replace={ replace } onChange={ this.onChange } />
        </Authorize>

        <CommonFooter />
      </div>
    )
  }
}

export default RewardView
