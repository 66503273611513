export const MESSAGES = {
  INVALID_CASH_OR_ACCOUNT:
    '「現金」または「売掛金」のどちらかが入力されている必要があります',
  INVALID_CASH: '「現金」に関する入力が正しくありません',
  INVALID_ACCOUNT: '「売掛金」に関する入力が正しくありません',
  INVALID_CASH_OR_ACCOUNT_AFTER_REFORM_COMPLETED:
    'リフォーム完了の時は、現金金額や売掛け金金額が入力されている必要があります',
  HAS_CANCEL_FILLED: '「キャンセル」の設定が正しくありません',
  INVALID_STICKY_TYPE: '[system error] Invalid Sticky Type',
}

/**
 * [isValid description]
 * @param  {object}  stickyProps [description]
 * @param  {object}  payments    [description]
 * @return {result:boolean, messages:string[]}        [description]
 */
export const isValidSticky = () => {
  // 呼ばれても何もしない。POSTで全てのチェックを行う。
  const result = true
  const messages = []
  return { result, messages }
}
