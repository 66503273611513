import React from 'react'
import PropTypes from 'prop-types'
import linkHistoryHOC from './hocs/link-history'
import styled from 'styled-components'
import { baseColor } from 'src/colors'

const Button = styled.button`
  font-size: 16px;
  color: ${props => (props.disabled ? 'black' : baseColor)};
  border: none;
  background: none;

  &:hover {
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  }
`

export class Page extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    value: PropTypes.string.isRequired,
    query: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    maxPage: PropTypes.number.isRequired,
    splitPageChunkLimit: PropTypes.number,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    query: {},
    disabled: false,
    splitPageChunkLimit: 10,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return (
      this.props.value !== nextProps.value ||
      this.props.query !== nextProps.query ||
      this.props.maxPage !== nextProps.maxPage ||
      this.props.disabled !== nextProps.disabled
    )
  }

  handleMove = num => () =>
    this.props.onChange({ target: { value: num.toString() } })

  /**
   * pagenation page number
   * @return {array}  [1,2,3,4,5,6,7,8] or [1,2,3,4,5,6,7,8,9,10,...]
   */
  get pageChunk() {
    const { maxPage, value, splitPageChunkLimit } = this.props
    const page = parseInt(value, 10)
    if (isNaN(page)) {
      return []
    }

    const min = Math.max(1, page - splitPageChunkLimit)
    const max = Math.min(maxPage, page + splitPageChunkLimit)
    if (max - min + 1 < 0) {
      return []
    }

    const chunk = Array(max - min + 1)
      .fill(0)
      .map((_0, i) => i + min)

    if (min !== 1 && max !== maxPage) {
      return ['...', ...chunk, '...']
    } else if (min !== 1) {
      return ['...', ...chunk]
    } else if (max !== maxPage) {
      return [...chunk, '...']
    } else {
      return chunk
    }
  }

  renderInitial = () => {
    const { value, maxPage, disabled } = this.props
    return (
      maxPage > 0 && (
        <Button
          disabled={ parseInt(value, 10) < 2 || disabled }
          onClick={ this.handleMove(1) }
        >
          {'最初'}
        </Button>
      )
    )
  }

  renderPrev = () => {
    const { value, maxPage, disabled } = this.props
    return (
      maxPage > 0 && (
        <Button
          disabled={ parseInt(value, 10) < 2 || disabled }
          onClick={ this.handleMove(parseInt(value, 10) - 1) }
        >
          {'前'}
        </Button>
      )
    )
  }

  renderPage = page => {
    const { value, disabled } = this.props
    return typeof page === 'number' ? (
      <Button
        key={ page }
        onClick={ this.handleMove(page) }
        disabled={ page.toString() === value || disabled }
      >
        {page}
      </Button>
    ) : typeof page === 'string' ? (
      <span key={ page }>{page}</span>
    ) : null
  }

  renderNext = () => {
    const { value, maxPage, disabled } = this.props
    return (
      maxPage > 0 && (
        <Button
          disabled={ parseInt(value, 10) > maxPage - 1 || disabled }
          onClick={ this.handleMove(parseInt(value, 10) + 1) }
        >
          {'次'}
        </Button>
      )
    )
  }
  renderTerminal = () => {
    const { value, maxPage, disabled } = this.props
    return (
      maxPage > 0 && (
        <Button
          disabled={ parseInt(value, 10) > maxPage - 1 || disabled }
          onClick={ this.handleMove(maxPage) }
        >
          {'最後'}
        </Button>
      )
    )
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { value, disabled } = this.props

    return (
      <div>
        {this.renderInitial()}
        {this.renderPrev()}
        {this.pageChunk.map(page => this.renderPage(page))}
        {this.renderNext()}
        {this.renderTerminal()}
        <input type={ 'hidden' } value={ value } disabled={ disabled } />
      </div>
    )
  }
}

export default linkHistoryHOC(Page, 'page')
