import styled from 'styled-components'
import { baseColor, borderDarkGray, red, white, bgPink } from 'src/colors'
import button from 'src/styled/button'

export const FormLine = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
`

export const Label = styled.label`
  width: 75px;
  font-weight: bold;
`

export const Input = styled.input`
  width: 340px;
  height: 40px;
  margin-left: 30px;
  padding: 0 5px;
  border: 1px solid ${borderDarkGray};
  border-radius: 3px;
  font-size: 16px;

  &:focus {
    border: 1px solid ${baseColor};
    outline: 0;
    box-shadow: 0 0 7px ${baseColor};
  }
`

export const WarningLine = styled.p`
  display: ${props => (props.isVisible ? 'block' : 'none')};
  position: absolute;
  margin-top: 32px;
  padding-left: 108px;
  color: ${red};

  &::before {
    margin-right: 5px;
    font-family: FontAwesome;
    content: '\f06a';
  }
`

export const ErrorLine = styled.p`
  display: block;
  height: 30px;
  margin-bottom: 20px;
  padding: 0 20px;
  border: 1px solid ${red};
  border-radius: 5px;
  background: ${bgPink};
  color: ${red};
  line-height: 30px;
`

export const IconWrap = styled.span`
  padding-left: 10px;
`

export const LoginButton = styled.button`
  ${button};
  display: block;
  width: 230px;
  height: 40px;
  margin: 40px auto 0;
  transition: 0.2s;
  background: ${baseColor};
  color: ${white};
  font-size: 1.4rem;
  font-weight: bold;
`

export const LogoutButton = styled.button`
  ${button};
`
