import addSticky from './actions/add-sticky'
import assignSticky from './actions/assign-sticky'
import closeModal from './actions/close-modal'
import commitSticky from './actions/commit-sticky'
import deleteSticky from './actions/delete-sticky'
import deleteStickyById from './actions/delete-sticky-by-id'
import insertList from './actions/insert-list'
import insertSticky from './actions/insert-sticky'
import openModal from './actions/open-modal'
import stageSticky from './actions/stage-sticky'
import stageStickyRaw from './actions/stage-sticky-raw'
import updateDailyInfo from './actions/update-daily-info'
import updateStaffFreeDescribe from './actions/update-staff-free-describe'
import updateDissociatesRegionsFilter from './actions/update-dissociates-regions-filter'
import updateDissociatesPrefsFilter from './actions/update-dissociates-prefs-filter'
import toggleDissociatesMarucFilter from './actions/toggle-dissociates-maruc-filter'
import updateSticky from './actions/update-sticky'
import updateStickyById from './actions/update-sticky-by-id'
import updateStage from './actions/update-stage'
import toggleAssignButtonForceDisabled from './actions/toggle-assign-button-force-disabled'
import asyncWaraReducer from './async'
import Sticky from 'src/lib/class-sticky'
/**
 * initial state
 * @type {object}
 */
export const initialWaraState = {
  call: {},
  data: {
    regions: [],
    staffs: {}, // [regionId]: staff
    dock: {
      stickies: [],
    },
  },
  modal: {
    commitTarget: false,
    isOpen: false,
    stage: new Sticky({
      /* ...stikies */
    }), // これは、モーダルの中身を一旦保存するためのプロパテイ
    resetOnClose: true,
    deletingContactIds: [], // これは、次回PUTリクエストの際に{ deleted: true }にして連絡先を削除するためのプロパティ
    assignButtonForceDisabled: false, // アサイン課のわら編集モーダルでボタンを強制的にクリック不可にするフラグ
    // NOTE このフラグは、キーバリューにして、全てのvalueのandでボタン使用可能に、とかするといいだろう
  },
  // わらで、未配置付箋のフィルタをここに格納
  dissociatedRegionsFilter: [],
  dissociatedPrefsFilter: [],
  dissociatedMarucFilter: false,
}

/**
 * 全てのアクションを分割ファイルから読み込み
 * @type {object}
 */
const RAW_ACTIONS = {
  addSticky,
  assignSticky,
  closeModal,
  commitSticky,
  deleteSticky,
  deleteStickyById,
  insertList,
  insertSticky,
  openModal,
  stageSticky,
  stageStickyRaw,
  updateDailyInfo,
  updateDissociatesRegionsFilter,
  updateDissociatesPrefsFilter,
  toggleDissociatesMarucFilter,
  updateStaffFreeDescribe,
  updateSticky,
  updateStickyById,
  updateStage,
  toggleAssignButtonForceDisabled,
}

/**
 * テスト用にエクスポート
 * @type {object}
 */
export const __RAW_ACTIONS = __TEST__ ? RAW_ACTIONS : {}

/**
 * result[0]: リデューサーで用いられるアクションハンドラをマップする。
 * result[1]: store.disoatchされるためのアクションクリエーターをマップする。
 *    コンポーネント内などから呼び出され、mapDispatchToPropsで必要なものをマップして利用する。
 */
const [ACTION_HANDLERS, ACTION_CREATORS] = Object.keys(RAW_ACTIONS).reduce(
  (result, key) => {
    result[0][RAW_ACTIONS[key].type] = RAW_ACTIONS[key].handler
    result[1][key] = RAW_ACTIONS[key].creator
    return result
  },
  [{}, {}],
)

export const actionCreators = ACTION_CREATORS

/**
 * ------------------------------------
 * waraReducer
 * ------------------------------------
 * @param  {object} [state=initialWaraState] given state
 * @param  {object} action                    reducing action
 * @return {reducer}                          reducer function
 */
export default function waraReducer(state = initialWaraState, action) {
  const handler = ACTION_HANDLERS[action.type]
  if (handler) {
    return handler(state, action)
  } else {
    return asyncWaraReducer(state, action)
  }
}
