import update from 'immutability-helper'
import Sticky from 'src/lib/class-sticky'

/**
 * タイプ定義
 * @type {string}
 */
export const type = 'WARA.UPDATE_STAGE'

/**
 * exportation
 */
export default {
  /**
   * action type
   * @type {string}
   */
  type,

  /**
   * action creator
   * @param  {Sticky|false}  sticky 更新する付箋インスタンス
   * @return {object}  ステージを置き換えるアクション
   */
  creator: (sticky, noMerge) => {
    if (sticky instanceof Sticky || sticky === false) {
      return {
        type,
        payload: { sticky, noMerge },
      }
    } else {
      throw new Error(
        'update-stageアクションにStickyクラスのインスタンスまたはfalse以外が渡されました。',
      )
    }
  },

  /**
   * [handler description]
   * @param  {object} state   [description]
   * @param  {object} action  [description]
   * @return {object}         [description]
   */
  handler: (state, { payload: { sticky, noMerge } }) => {
    if (noMerge) {
      return update(state, { modal: { stage: { $set: sticky } } })
    }

    // falseを渡したときに初期化される
    if (sticky) {
      const nextSticky = state.modal.stage.merge(sticky)
      return update(state, { modal: { stage: { $set: nextSticky } } })
    } else {
      return update(state, { modal: { stage: { $set: new Sticky({}) } } })
    }
  },
}
