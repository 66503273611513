import { connect } from 'react-redux'

/**
 map state to props
 * @param  {object} state stored state
 * @return {object}       props
 */
const mapStateToProps = state => {
  return {
    networkStatus: state.networkStatus.putStaffDaily,
    displayDate: state.displayDate.displayDate,
    accessToken: state.login.authentication.accessToken,
    env: state.env,
    nodeenv: state.env.NODE_ENV,
  }
}

export default Component => connect(mapStateToProps)(Component)
