import React from 'react'
import PropTypes from 'prop-types'
import { findListFromWara } from 'src/lib/search'

/**
 * 非表示状態（縮小タグ状態）のSSの名前コンポーネント
 * @type {Object}
 */
export class ListIcon extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    location: PropTypes.shape({
      regionIndex: PropTypes.number.isRequired,
      listIndex: PropTypes.number.isRequired,
    }).isRequired,
    display: PropTypes.bool.isRequired,
    // stateProps
    wara: PropTypes.shape({
      regions: PropTypes.array.isRequired,
    }).isRequired,
    // dispatchProps
    showSS: PropTypes.func.isRequired,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return this.props.display !== nextProps.display
  }

  /**
   * アイコンをクリックして表示させる。そのハンドラ
   * @return {void}
   */
  onSSIconClick = () => {
    const { wara, location } = this.props
    const list = findListFromWara(wara, location) || {}
    const { regionId, staffId } = list
    regionId && staffId && this.props.showSS(regionId, staffId)
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { wara, location, display } = this.props

    const list = findListFromWara(wara, location) || {}

    return (
      <span
        className={
          'list-icon button button-icon-add ' + (display ? 'show' : 'hide')
        }
        onClick={ this.onSSIconClick }
      >
        {list.staffName}
      </span>
    )
  }
}

export default ListIcon
