import utils from 'src/lib/api-utils'

/**
 * 稼働開始のエンドポイントを叩く
 * @param  {number} staffId    [description]
 * @param  {string} accessToken [description]
 * @param  {object} env         [description]
 * @return {Promise}            [description]
 */

export const NofifyType = { start: 1, end: 2, cancel: 3 }

/**
 * Expoの通知APIで稼働開始・終了の通知を送信する
 * @param {*} notifyType
 * @param {*} staffId
 * @param {*} workDate
 * @param {*} accessToken
 * @param {*} env
 */
export const expoNotify = (notifyType, staffId, workDate, accessToken, env) => {
  const endPoint = utils.createExpoEndpoint(env, 'notify')

  const body = JSON.stringify({
    notifyType,
    staffId,
    workDate,
  })

  return fetch(endPoint, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body,
  })
}

export default expoNotify
