import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Sticky from 'src/lib/class-sticky'
import Checkbox from 'src/styled/form/checkbox'
import { isValidPhoneNumber } from '../payment/partials/validation'

/**
 * 差分データのキー名
 * 現金、売掛金どちらも請求書(invoice)、領収書(receipt)のキー名は同じ。
 * paymentKeyにこのどちらかを指定する。
 */
const paymentKeys = {
  invoice: {
    need: 'needInvoice', // チェックボックスの状態
    name: 'invoiceName', // 宛名
    phoneNumber: 'invoicePhoneNumber', // 電話番号
    address: 'invoiceAddress', // 住所
  },
  receipt: {
    need: 'needReceipt',
    name: 'receiptName',
    phoneNumber: 'receiptPhoneNumber',
    address: 'receiptAddress',
  },
}

/**
 * 請求書・領収書要送付のコンポーネント
 */
export class SendRequest extends React.PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired, // チェックボックスに表示する名称
    sticky: PropTypes.instanceOf(Sticky).isRequired, // 付箋
    data: PropTypes.object.isRequired, // 現金・売掛金の情報
    paymentKey: PropTypes.string.isRequired, // 設定する差分の判定。paymentKeysのキー
    paymentIndex: PropTypes.number.isRequired, // 現金・売掛金のインデックス
    disabled: PropTypes.bool.isRequired, // 無効にする時true
    onChange: PropTypes.func.isRequired, // プロパティを設定する処理(差分、paymentIndex)=>{}
    route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired, // 画面の識別用（使わない予定）
    // StateProps
    master: PropTypes.shape({
      prefCodes: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
        }),
      ),
    }).isRequired,
    cityCodes: PropTypes.object.isRequired,
  }

  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    this.state = {
      destination: -1, // 選択された宛先
      needed: !!props.data[this.keyName('need')], // チェックボックスの状態
      name: props.data[this.keyName('name')] || '', // 宛名
      phoneNumber: props.data[this.keyName('phoneNumber')] || '', // 電話番号
      address: props.data[this.keyName('address')] || '', // 住所
    }
  }

  /**
   * 支払い者の一覧を取得
   */
  payers = () => {
    const { sticky } = this.props
    return sticky
      .json()
      .contacts.reduce(
        (prev, tabContacts, tabIndex) => [
          ...prev,
          ...(tabContacts.map((contact, contactIndex) => ({
            ...contact,
            tabIndex,
            contactIndex,
          })) || []),
        ],
        [],
      ) // flatten
      .filter(x => !!x) // remove null
      .map(x => ({
        id: x.id,
        name:
          (x.name ? x.name + (x.isPayer ? '(支払者)' : '') : x.name) ||
          '(名前未入力)',
        tabIndex: x.tabIndex,
        contactIndex: x.contactIndex,
        phoneNumber: x.phoneNumber,
        prefCode: x.prefCode,
        cityCode: x.cityCode,
        address: x.address,
      }))
  }

  /**
   * 更新する差分のキー名を返す
   * paymentKeyで指定された区分のうち、nameで指定された項目のキー名
   */
  keyName = name => {
    const { paymentKey } = this.props
    return paymentKeys[paymentKey][name]
  }

  /**
   * チェックボックスのイベントハンドラ
   */
  onChangeRequire = e => {
    const { checked: needed } = e.target
    const { paymentIndex } = this.props
    this.props.onChange({ [this.keyName('need')]: needed }, paymentIndex)
    this.setState({ needed: needed })
  }

  /**
   * 送付先ドロップダウンのイベントハンドラ
   */
  onChangePayer = e => {
    const destination = parseInt(e.target.value)
    const {
      paymentIndex,
      master: { prefCodes },
      cityCodes,
    } = this.props
    if (!isNaN(destination)) {
      const payer =
        (this.payers() || []).find(payer => payer.id === destination) || {}
      const name = payer.name || ''
      const phoneNumber = payer.phoneNumber || ''
      const prefCode = payer.prefCode || ''
      const prefName =
        (prefCodes.find(pref => pref.code === prefCode) || {}).name || ''
      const cityCode = payer.cityCode || ''
      const cityName =
        ((cityCodes[prefCode] || []).find(city => city.code === cityCode) || {})
          .cityName || ''
      const address = `${prefName} ${cityName} ${payer.address || ''}`
      // 差分を登録する
      this.props.onChange(
        {
          [this.keyName('name')]: name,
          [this.keyName('phoneNumber')]: phoneNumber,
          [this.keyName('address')]: address,
        },
        paymentIndex,
      )
      // ステートを更新
      this.setState({ destination, name, phoneNumber, address })
    }
  }

  /**
   * 宛名を変更した時のイベントハンドラ
   */
  onChangeName = e => {
    const value = e.target.value
    const { paymentIndex } = this.props
    this.props.onChange({ [this.keyName('name')]: value }, paymentIndex)
    this.setState({ name: value })
  }

  /**
   * 電話番号を変更した時のイベントハンドラ
   */
  onChangePhoneNumber = e => {
    const value = e.target.value
    const { paymentIndex } = this.props
    this.props.onChange({ [this.keyName('phoneNumber')]: value }, paymentIndex)
    this.setState({ phoneNumber: value })
  }

  /**
   * 住所を変更した時のイベントハンドラ
   */
  onChangeAddr = e => {
    const value = e.target.value
    const { paymentIndex } = this.props
    this.props.onChange({ [this.keyName('address')]: value }, paymentIndex)
    this.setState({ address: value })
  }

  /**
   * render()
   */
  render() {
    const { label, paymentIndex, route } = this.props
    const { needed, destination, name, phoneNumber, address } = this.state
    const disabled = this.props.disabled || !needed
    const payers = this.payers()
    const displayPayer = [{ id: -1, name: '(上記以外)' }, ...payers]

    return (
      <div style={ { display: 'flex', flexWrap: 'wrap', marginLeft: '10px' } }>
        <dl className={ 'label-inputs-wrap inline' }>
          <dt style={ { minWidth: '10px', marginLeft: '-30px' } }>&nbsp;</dt>
          <dd className={ 'input-wrap' }>
            <Checkbox
              checked={ needed }
              onChange={ this.onChangeRequire }
              labelText={ label }
            />
          </dd>
        </dl>
        {/* チェックボックス */}
        <dl className={ 'label-inputs-wrap inline' }>
          <dt style={ { minWidth: '10px' } }>
            <label htmlFor={ `payer-select-${route}` }>{'送付先'}</label>
          </dt>
          <dd className={ 'input-wrap' }>
            <select
              value={ destination }
              id={ `payer-select-${route}` }
              name={ `payer-select-${route}` }
              onChange={ this.onChangePayer }
              disabled={ disabled }
            >
              {displayPayer.map(option => (
                <option key={ option.id } value={ option.id }>
                  {option.name}
                </option>
              ))}
            </select>
          </dd>
        </dl>
        {/* 送付先 */}
        <dl className={ 'label-inputs-wrap inline' }>
          <dt style={ { minWidth: '10px' } }>
            <label htmlFor={ `name-${paymentIndex}` }>{'宛名'}</label>
          </dt>
          <dd className={ 'input-wrap' }>
            <input
              value={ name }
              disabled={ disabled }
              onChange={ this.onChangeName }
              name={ `payment-name-${route}` }
            />
          </dd>
        </dl>
        {/* 宛名 */}
        <dl className={ 'label-inputs-wrap inline' }>
          <dt style={ { minWidth: '10px' } }>
            <label htmlFor={ `phone-${paymentIndex}` }>{'電話番号'}</label>
          </dt>
          <dd className={ 'input-wrap' }>
            <input
              className={
                isValidPhoneNumber(phoneNumber || '')
                  ? 'input-valid'
                  : 'input-invalid'
              }
              value={ phoneNumber }
              disabled={ disabled }
              onChange={ this.onChangePhoneNumber }
              name={ `payment-phone-number-${route}` }
            />
          </dd>
        </dl>
        {/* 電話番号 */}
        <dl className={ 'label-inputs-wrap inline' }>
          <dt style={ { minWidth: '10px' } }>
            <label htmlFor={ `addr-${paymentIndex}` }>{'住所'}</label>
          </dt>
          <dd className={ 'input-wrap' }>
            <input
              style={ { width: '200px' } }
              value={ address }
              disabled={ disabled }
              onChange={ this.onChangeAddr }
              name={ `payment-address-${route}` }
            />
          </dd>
        </dl>
        {/* 住所 */}
      </div>
    )
  }
}

export const mapStateToProps = state => {
  return {
    master: state.master.data,
    cityCodes: state.master.cityCodes || {},
  }
}

export default connect(mapStateToProps)(SendRequest)
