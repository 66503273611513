import React from 'react'
import PropTypes from 'prop-types'
import { toJPYenText } from 'src/lib/format'
import { Dl, Dt, Dd } from 'src/styled/list'
import { PluralTable } from '../../styled'

// {
//   "accountId" : 1,
//   "stickyContactId": 123,
//   "stickyId": 1,
//   "orderId": "W01-00000001",
//   "name": "顧客名",
//   "phoneNumber": "09011111111",
//   "regionId": 1,
//   "latestMemo": "集金",
//   "completedAt": "2018-05-20T00:00:00.0000000Z",
//   "remainValue": 12000,
//   "nextCollectionMethodId", 2,
//   "nextCollectionPlanDate": "2018-06-30",
//   "staffId": 1,
//   "collectedValue": 3000,
//   "accountValue": 15000,
//   "complete": false
// }

const tableSpec = {
  headers: {
    displayDate: '計上日',
    name: 'お客様名',
    accountValue: '計上額',
    remainValue: '回収残',
    phoneNumber: '電話番号',
    latestMemo: '備考',
  },
  keyOrders: [
    'displayDate',
    'name',
    'accountValue',
    'remainValue',
    'phoneNumber',
    'latestMemo',
  ],
  transform: {
    accountValue: toJPYenText,
    remainValue: toJPYenText,
  },
}

export const IncompleteAccounts = props => {
  const { items } = props
  const sum = items.reduce((prev, item) => prev + (item.remainValue || 0), 0)

  return [
    <Dl key={ 0 }>
      <Dt>{'未回収売掛金'}</Dt>
      <Dd>{toJPYenText(sum)}</Dd>
    </Dl>,
    <Dl key={ 1 }>
      <Dt />

      <Dd strech>
        {items.length > 0 && (
          <PluralTable
            items={ items }
            tableSpec={ tableSpec }
            keyName={ 'accountHistoryId' }
          />
        )}
      </Dd>
    </Dl>,
  ]
}

IncompleteAccounts.propTypes = {
  items: PropTypes.array.isRequired,
}

export default IncompleteAccounts
