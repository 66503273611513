import React from 'react'
import styled from 'styled-components'

import { Link } from 'react-router-dom'
import Authorize from 'src/components/commons/authorize'

import config from 'src/config'
import { baseColor, darkBaseColor } from 'src/colors'

export const NavLink = styled(Link)`
  text-decoration: none;
  color: ${baseColor};
  font-size: 1.25rem;
  border: 1px solid ${baseColor};
  padding: 10px 30px;
  background-color: white;
  border-radius: 4px;

  &:hover {
    color: ${darkBaseColor};
    border: 1px solid ${darkBaseColor};
  }
`

export const OrderListLink = () => (
  <span style={ { paddingLeft: '20px' } }>
    <Authorize roles={ config.roles.orderList }>
      <NavLink to={ '/order-list' }>{'注文一覧'}</NavLink>
    </Authorize>
  </span>
)

export default OrderListLink
