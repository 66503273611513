import utils from 'src/lib/api-utils'
import Sticky from 'src/lib/class-sticky'

/**
 * GET stickyのアクションクリエーター
 * sticky.staffId === -1 でDockに戻せる
 * @param  {number} stickyId    [description]
 * @param  {string} accessToken [description]
 * @param  {object} env         env
 * @return {Promise}
 */
export const requestGetSticky = (stickyId, accessToken, env) => {
  const endpoint = `${utils.createEndpoint(env, 'stickies')}/${stickyId}`

  return fetch(endpoint, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      if (res.ok) {
        return res.json()
      } else {
        throw 'GETエラー'
      }
    })
    .then(result => new Sticky(result, { normalize: false }))
}

export default requestGetSticky
