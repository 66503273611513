import styled from 'styled-components'
import { baseColor } from 'src/colors'
import { Link } from 'react-router-dom'
import button, { buttonLine } from 'src/styled/button'

export const Table = styled.table`
  width: 100%;
  margin: 2em auto 1em;
  font-size: 1em;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
`

export const Tr = styled.tr`
  border-bottom: ${props => (props.underline ? '1px' : 0)} solid black;
`

export const Th = styled.th`
  padding: 5px;
  text-align: left;
  color: ${baseColor};
  font-weight: bold;

  &:first-child {
    padding-left: 100px;
  }
`

export const Td = styled.td`
  background-color: white;
  padding: 5px;
  text-align: left;

  &:first-child {
    padding-left: 100px;
  }
`

export const ButtonLink = styled(Link)`
  ${button};
  ${buttonLine(baseColor)};
  padding: 4px 4px 2px;
  margin: 0 10px;
  text-decoration: none;
`
