import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// types
import { NETWORK_STATUSES } from 'src/reducers/network-status'
import { createAsyncLoginAction } from 'src/reducers/login'
import { ACTION_TYPES as LOGIN_ACTION_TYPES } from 'src/reducers/login'

// libs
import noop from 'src/lib/noop'
import { Box, Button } from '@mui/material'
import theme from 'src/theme.js'
import { ThemeProvider } from '@mui/material/styles'

/**
 map state to props
 * @param  {object} state state
 * @return {object}      props
 */
export const mapStateToProps = state => {
  return {
    userid: state.login.authentication.userid,
    password: state.login.authentication.password,
    loginStatus: state.networkStatus.login,
    __env: state.env,
  }
}

/**
 * map dispatch to props
 * @param  {function} dispatch dispatcher
 * @return {object}           props
 */
export const mapDispatchToProps = dispatch => {
  return {
    /**
     * ログインリクエストを発出し、成功すればアクセストークンをstoreに格納
     * @param  {string} userid   ユーザーID
     * @param  {string} password パスワード
     * @return {void}
     */
    __login: env => (userid, password) =>
      dispatch(createAsyncLoginAction(userid, password, env)),

    /**
     * ログイン試行のネットワーク状態をリセットする
     * @return {void}
     */
    resetLoginStatus: () =>
      dispatch({
        type: LOGIN_ACTION_TYPES.SET_LOGIN_STATUS,
        payload: { status: NETWORK_STATUSES.NOT_YET },
      }),
  }
}

/**
 * map state to props
 * @param  {object} stateProps    state props
 * @param  {object} dispatchProps dispatch props
 * @param  {object} ownProps      own props
 * @return {object}               props
 */
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    // merge
    login: dispatchProps.__login(stateProps.__env),
    // make private
    __env: void 0,
    __login: void 0,
  }
}

/**
 * ログインボタンを表示するコンポーネント
 * @param {object} props props
 * @return {ReactComponent}
 */
export class Login extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // stateProps
    userid: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    loginStatus: PropTypes.oneOf(Object.values(NETWORK_STATUSES)),
    // dispatchProps
    login: PropTypes.func.isRequired,
    resetLoginStatus: PropTypes.func.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    loginStatus: NETWORK_STATUSES.NOT_YET,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return (
      this.props.userid !== nextProps.userid ||
      this.props.password !== nextProps.password ||
      this.props.loginStatus !== nextProps.loginStatus
    )
  }

  /**
   * applied login
   * @return {void}
   */
  onClickToLogin = () =>
    this.props.login(this.props.userid, this.props.password)

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const {
      // stateProps
      loginStatus,
      userid,
      password,
      // dispatchProps
      resetLoginStatus,
    } = this.props
    const disabled =
      userid === '' ||
      password === '' ||
      loginStatus === NETWORK_STATUSES.TRYING
    const resetLoginStatusOnFailed =
      loginStatus !== NETWORK_STATUSES.SUCCESS ? noop : resetLoginStatus

    return (
      <ThemeProvider theme={ theme }>
        <Box sx={ { display: 'grid', placeItems: 'center' } }>
          <Button
            color="primary"
            type={ 'button' }
            onBlur={ resetLoginStatusOnFailed }
            onClick={ this.onClickToLogin }
            disabled={ disabled }
            size="large"
            variant="contained"
            sx={ { color: '#fff', width: '12.5rem' } }
          >
            {'ログイン'}
          </Button>
        </Box>
      </ThemeProvider>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Login)
