import React from 'react'
import PropTypes from 'prop-types'
import connect from '../../connect'

import ValidationWarningMessage from 'src/components/commons/validation-warning-message'

export class SelectTemplate extends React.PureComponent {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
    hasMarucRepeat: PropTypes.bool,
    master: PropTypes.shape({
      marucHandOver: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          displayIndex: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        }),
      ),
    }).isRequired,
    consultation: PropTypes.bool,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    disabled: false,
    consultation: false,
    hasMarucRepeat: false,
  }

  get groupName() {
    return `negotiation-records-hand-over-status-${this.props.route}`
  }

  renderItem = (item, isHighlighted) => (
    <div
      key={ item.value }
      style={ { background: isHighlighted ? 'lightgray' : 'white' } }
    >
      {item.text}
    </div>
  )

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const {
      route,
      hasMarucRepeat,
      master: { marucHandOver },
      consultation,
    } = this.props

    const masterHandOver = []
    marucHandOver.forEach(option => {
      const row = option.displayIndex.toString().length - 1
      if (!masterHandOver[row]) {
        masterHandOver[row] = []
      }
      masterHandOver[row].push(option)
    })
    // 相談室の時は1列目を表示しない
    if (consultation) {
      masterHandOver.shift()
    }

    return (
      <>
        {hasMarucRepeat && (
          <div>
            <ValidationWarningMessage
              message={ 'マルシーリピがあるため交渉履歴は入力できません' }
            />
          </div>
        )}
        <dl
          className={ 'label-inputs-wrap' }
          id={ `input-negotiation-record-${route}` }
        >
          <dt>
            <label>{'交渉履歴入力'}</label>
          </dt>

          <dd className={ 'input-wrap' }>&nbsp;</dd>
        </dl>
      </>
    )
  }
}

export default connect(SelectTemplate)
