import React from 'react'
import PropTypes from 'prop-types'

const Memo = props => {
  const { workContent } = props

  return (
    <div className={ 'sticky-memo-content' }>
      <div className={ 'sticky-element sticky-message' }>
        <span>{workContent || '(未記入)'}</span>
      </div>
    </div>
  )
}

Memo.propTypes = {
  workContent: PropTypes.string,
}

Memo.defaultProps = {
  workContent: '',
}

export default Memo
