import React from 'react'
import PropTypes from 'prop-types'
import connect from './props'

// components
import ChangeLogs from '../change-logs'
import ValidationErrorMessage from 'src/components/commons/validation-error-message'
import SearchButton from 'src/components/commons/search-button'

// handlers
import onEditHistoryClickHandler from './handlers/on-edit-history-click'
import parseProps from './handlers/parse-props'
import curryRequests from './handlers/curry-requests'

// library
import { findStickyFromWara } from 'src/lib/search'
import Sticky from 'src/lib/class-sticky'
import config from 'src/config'
import requestPostSticky from 'src/lib/sticky-api/post'
import { setStickyDisplay, setFinishStatus } from 'src/lib/format'
import { getStaffList } from 'src/lib/search'
import { isValidRefund } from 'src/lib/validate-maruc-refund.js'

/**
 * 付箋編集画面のボタンを包むやつ
 * @type {fuction}
 */
export class StickyModalContentButtons extends React.PureComponent {
  /**
   * Validation
   * @type {object}
   */
  static propTypes = {
    // ownProps
    sticky: PropTypes.object.isRequired,
    location: PropTypes.shape({
      regionIndex: PropTypes.number.isRequired,
      listIndex: PropTypes.number.isRequired,
      stickyIndex: PropTypes.number.isRequired,
    }).isRequired,
    newSticky: PropTypes.bool.isRequired,
    setNewSticky: PropTypes.func.isRequired,
    noHistory: PropTypes.bool, // trueの時変更履歴ボタンを表示しない
    noMessage: PropTypes.bool, // trueの時エラーメッセージを表示しない
    setPutStickyStatus: PropTypes.func.isRequired,
    resetPutStickyStatus: PropTypes.func.isRequired,
    // stateProps
    wara: PropTypes.shape({
      regions: PropTypes.arrayOf(
        PropTypes.shape({
          lists: PropTypes.arrayOf(
            PropTypes.shape({
              stickies: PropTypes.arrayOf(PropTypes.instanceOf(Sticky)),
            }),
          ),
        }),
      ).isRequired,
    }).isRequired,
    staged: PropTypes.instanceOf(Sticky).isRequired,
    accessToken: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    env: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
    displayDate: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    isDebugMode: PropTypes.bool,
    master: PropTypes.object.isRequired,
    marucRefund: PropTypes.object.isRequired,
    // dispatchprops
    commitSticky: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    deleteSticky: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    deleteStickyById: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    updateEditSticky: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    addSticky: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    closeModal: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    resetComplaint: PropTypes.func.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    isDebugMode: false,
    noHistory: false,
    noMessage: false,
  }

  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)

    this.state = {
      disableButtons: false,
    }
  }

  /**
   * Update button click handler
   * @return {void}
   */
  onEditHistoryClick = () => {
    this.setState({ disableButtons: true })

    onEditHistoryClickHandler(
      this.props,
      parseProps(this.props),
      curryRequests(this.props),
      () => {
        this.props.resetComplaint()
        this.setState({ disableButtons: false })
      },
    )
  }

  /**
   * 作成した付箋はわらに割り当てる必要があるかをチェック
   * return: 付箋を配置する location / null
   */
  isAssignNeeded = sticky => {
    // 再依頼
    if (sticky.getUserValue('reRequestOf')) {
      // 同じSSの最後
      const { location, wara } = this.props
      const stickyIndex =
        wara.regions[location.regionIndex].lists[location.listIndex].stickies
          .length + 1
      return { ...location, stickyIndex }
    }
    // SSが指定されており、確定作業日時が指定されている
    if (
      sticky._props.staffId &&
      sticky._props.determinedDateTime &&
      sticky._props.determinedDateTime.date
    ) {
      const { wara } = this.props
      const sslocation = getStaffList(wara, sticky._props.staffId)
      // SSのリストが見つからない時は配置しない
      if (sslocation.regionIndex === -1) {
        return null
      }
      return { ...sslocation, stickyIndex: sslocation.stickyIndex + 1 }
    }
    // それ以外はわらに配置しない
    return null
  }

  /**
   * 付箋を保存し、編集対象に設定する。
   */
  onPostButtonClick = () => {
    const {
      sticky,
      setNewSticky,
      // stateProps
      staged,
      accessToken,
      env,
      updateEditSticky,
      setPutStickyStatus,
      resetPutStickyStatus,
      master: { validStaffs },
    } = this.props
    this.setState({ disableButtons: true })
    const mergedSticky = sticky.merge(staged)
    // 付箋の表示状態を設定する
    setStickyDisplay(mergedSticky, validStaffs)
    // 終了状態を修正する
    setFinishStatus(mergedSticky)

    requestPostSticky(mergedSticky, accessToken, env)
      .then(res => {
        updateEditSticky(new Sticky(res), new Sticky({}))
        this.props.resetComplaint()
        this.setState({ disableButtons: false })
        setNewSticky(false)
        setPutStickyStatus('success')
        resetPutStickyStatus()
        // 作成した付箋をわらに配置する
        const sticky = new Sticky(res)
        const newLocation = this.isAssignNeeded(sticky)
        if (newLocation !== null) {
          this.props.addSticky(newLocation, sticky)
        }
      })
      .catch(err => {
        console.log(err)
        this.props.resetComplaint()
        this.setState({ disableButtons: false })
        setNewSticky(false)
        setPutStickyStatus('failure')
        resetPutStickyStatus()
      })
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { disableButtons } = this.state

    const {
      // ownProps
      sticky,
      newSticky,
      noHistory,
      noMessage,
      closeModal,
      // stateProps
      staged,
      isDebugMode,
      marucRefund,
    } = this.props

    const mergedSticky = sticky.merge(staged)

    const { id, orderId, stickyTypeId, contacts } = mergedSticky.json()

    const isNormalSticky = stickyTypeId === config.stickyType.normal

    const validation = !isNormalSticky
      ? { result: true, messages: [] }
      : mergedSticky.isValid(Sticky.VALIDATION_PATTERN.BEFORE_POST)
    const hasResident =
      contacts[0].length > 0 && contacts[0].some(contact => contact.prefCode) // 種別「居住者」で都道府県の入力があるかをチェック
    const isValid = !isNormalSticky
      ? validation.result
      : validation.result && hasResident
    const messages = validation.messages
    const companyId = mergedSticky._props.companyId || config.company.ES
    const { validRefund: isMarucRefundValid } = isValidRefund(marucRefund, id)
    const client = mergedSticky.findClient() || {}
    const phoneNumber = client.phoneNumber || ''

    return (
      <div className={ 'modal-buttons-below' }>
        {isNormalSticky && !noHistory ? (
          <ChangeLogs
            stickyId={ id }
            orderId={ orderId }
            route={ 'assign' }
            companyId={ companyId }
          />
        ) : null}
        {!noMessage &&
          messages.map(message => (
            <ValidationErrorMessage key={ message } message={ message } />
          ))}
        {newSticky ? (
          <button
            className={ 'button button-close' }
            onClick={ this.onPostButtonClick }
            disabled={ !isValid || disableButtons }
          >
            {'保存する'}
          </button>
        ) : (
          <button
            className={ 'button button-close' }
            onClick={ this.onEditHistoryClick }
            disabled={ !isValid || disableButtons || !isMarucRefundValid }
          >
            {'更新する'}
          </button>
        )}
        <SearchButton
          linkTo={ `/call/search?phoneNumber=${phoneNumber}` }
          onClick={ closeModal }
        />
        {isDebugMode && (
          <button
            /* eslint-disable react/jsx-no-bind */
            onClick={ () => {
              const { wara, staged, location } = this.props
              const sticky = findStickyFromWara(wara, location)
              /* eslint-disable no-console */
              console.log('sticky', sticky, JSON.stringify(sticky))
              console.log('staged', staged, JSON.stringify(staged))
              /* eslint-enable no-console */
            } }
            /* eslint-enable react/jsx-no-bind */
            className={ 'button' }
          >
            {'dump'}
          </button>
        )}
      </div>
    )
  }
}

export default connect(StickyModalContentButtons)
