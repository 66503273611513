import React from 'react'
import PropTypes from 'prop-types'
import config from 'src/config'
import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material'
import theme from 'src/theme.js'
import { ThemeProvider } from '@mui/material/styles'

/**
 * 支払い回数
 * @type {ReactComponent}
 */
export class PaymentTimes extends React.PureComponent {
  /**
   * Validation
   * @type {object}
   */
  static propTypes = {
    // ownProps
    value: PropTypes.number.isRequired,
    route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
    disabled: PropTypes.bool.isRequired,
    update: PropTypes.func.isRequired,
  }

  /**
   * 支払い回数の変更のハンドラ
   * @param  {Event} e event
   * @return {void}
   */
  onChange = e => {
    const val = parseInt(e.target.value, 10)
    const paymentTimes = isNaN(val) ? -1 : val
    this.props.update(paymentTimes)
  }

  /**
   * render
   * @return {ReactDOM} rendered result
   */
  render() {
    const { value, route, disabled: forceDisabled } = this.props

    const disabled = forceDisabled || route !== 'assign'

    const identifier = `cash-select-payment-payment-times-${route}`

    return (
      <ThemeProvider theme={ theme }>
        <Box
          sx={ {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, max-content)',
            columnGap: '1.5rem',
            alignItems: 'center',
          } }
        >
          <Typography
            htmlFor={ identifier }
            variant="subtitle1"
            sx={ { fontWeight: 'bold' } }
          >
            {'分割回数'}
          </Typography>
          <FormControl
            color="primary"
            fullWidth
            disabled={ disabled }
            size="small"
            sx={ {
              background: '#fff',
              '.MuiOutlinedInput-root': {
                height: '28px',
                alignItems: 'center',
                '.MuiSelect-select': {
                  padding: '0px 14px',
                },
              },
            } }
          >
            <Select
              value={ value }
              id={ identifier }
              MenuProps={ { style: { zIndex: 10000 } } } // TODO: 付箋詳細や詳細検索のモーダルで表示するにはこの数値が必要
              name={ identifier }
              onChange={ this.onChange }
            >
              {[-1, ...config.paymentTimesMaster].map(number => (
                <MenuItem key={ number } value={ number }>
                  {number < 0 ? '(未指定)' : number.toString()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </ThemeProvider>
    )
  }
}

export default PaymentTimes
