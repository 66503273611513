// react related
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// components
import PayerSelect from '../common/payer-select'
import SendRequest from '../common/send-request'
import CashValue from './partials/cash-value'
import PaymentType from './partials/payment-type'
import PaymentMethod from './partials/payment-method'
import PaymentTimes from './partials/payment-times'
import CollectedStaffId from './partials/collected-staffid'
import ValidationErrorMessage from 'src/components/commons/validation-error-message'

// lib
import Sticky from 'src/lib/class-sticky'
import { isValidPayment } from 'src/lib/validate'
import config from 'src/config'
import { Box } from '@mui/material'

const mapStateToProps = state => ({
  creditCards: state.master.data.creditCards,
})

/**
 * Payment と言いつつこれは現金を扱うやつ
 * @type {Object}
 */
const Payment = props => {
  const {
    paymentIndex,
    payment,
    route,
    sticky,
    disabled,
    isConfirmed,
    onChange,
    onChangeMeta,
    creditCards,
  } = props

  const createUpdateHandler = propName => value => {
    onChange({ [propName]: value }, paymentIndex)
  }

  // 支払方法の変更によって支払回数を設定する
  const updateCreditCardId = creditCardId => {
    const paymentMethodId = checkIsOneTimeOnly(creditCardId) ? 1 : -1
    const payment = { creditCardId, paymentMethodId }
    onChange(payment, paymentIndex)
  }
  const { messages } = isValidPayment(payment)

  const checkIsOneTimeOnly = id => {
    const result =
      creditCards && creditCards.find(creditCard => creditCard.id === id)
    return result ? result.isOneTime : null
  }

  // 支払い方法が未選択、現金、小切手、ホームサーブの時は支払い回数・分割回数を無効にする。
  // 支払回数1回で固定するタイプのカードの時も支払回数・分割回数を無効にする。
  const creditCardNotSelected =
    !payment.data.creditCardId ||
    payment.data.creditCardId === config.paymentMethod.cash ||
    payment.data.creditCardId === config.paymentMethod.check ||
    payment.data.creditCardId === config.paymentMethod.homeServe ||
    checkIsOneTimeOnly(payment.data.creditCardId)

  // 支払い方法が有効で、支払回数が分割以外の時のみ分割回数を無効にする。
  const creditCardTimesDisabled =
    !creditCardNotSelected &&
    payment.data.paymentMethodId !== config.paymentMethodId.installments

  return (
    <div className="single-payment-wrap">
      <div
        style={ {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'baseline',
        } }
      >
        <PayerSelect
          label={ '支払者' }
          paymentIndex={ paymentIndex }
          payment={ payment }
          sticky={ sticky }
          route={ route }
          disabled={ disabled }
          onChangeMeta={ onChangeMeta }
        />
        <span
          style={ {
            marginLeft: '1.5rem',
            marginRight: '0.75rem',
            fontWeight: 'bold',
          } }
        >
          {'契約書No. '}
        </span>
        <span>{payment.data.contractNumber || '----'}</span>
      </div>
      <SendRequest
        label={ '請求書要送付' }
        sticky={ sticky }
        data={ payment.data }
        paymentKey={ 'invoice' }
        paymentIndex={ paymentIndex }
        disabled={ disabled }
        onChange={ onChange }
        route={ route }
      />
      <SendRequest
        label={ '領収書要送付' }
        sticky={ sticky }
        data={ payment.data }
        paymentKey={ 'receipt' }
        paymentIndex={ paymentIndex }
        disabled={ disabled }
        onChange={ onChange }
        route={ route }
      />
      <Box
        sx={ {
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
          columnGap: '1.5rem',
          rowGap: '0.25rem',
        } }
      >
        <CashValue
          value={ payment.data.cashValue }
          sticky={ sticky }
          route={ route }
          disabled={ disabled || isConfirmed }
          update={ createUpdateHandler('cashValue') }
        />
        {/* 現金（内金） */}
        <PaymentType
          value={ payment.data.creditCardId || -1 }
          sticky={ sticky }
          route={ route }
          disabled={ disabled }
          update={ updateCreditCardId }
        />
        {/* 支払方法 */}
        <PaymentMethod
          value={ payment.data.paymentMethodId || -1 }
          sticky={ sticky }
          route={ route }
          disabled={ disabled || creditCardNotSelected }
          update={ createUpdateHandler('paymentMethodId') }
          paymentTypeIsCash={
            payment.data.creditCardId === config.creditCard.cash
          }
        />
        {/* 支払回数 */}
        <PaymentTimes
          value={ payment.data.paymentTimes || -1 }
          sticky={ sticky }
          route={ route }
          disabled={
            disabled || creditCardNotSelected || creditCardTimesDisabled
          }
          update={ createUpdateHandler('paymentTimes') }
        />
        {/* 分割回数 */}
        <CollectedStaffId
          value={ payment.data.collectedStaffId || -1 }
          sticky={ sticky }
          route={ route }
          disabled={ disabled }
          update={ createUpdateHandler('collectedStaffId') }
        />
        {/* 代理回収者 */}
      </Box>

      {messages.map(message => (
        <ValidationErrorMessage key={ message } message={ message } />
      ))}
    </div>
  )
}

Payment.propTypes = {
  // ownProps
  paymentIndex: PropTypes.number.isRequired,
  payment: PropTypes.object.isRequired,
  route: PropTypes.string.isRequired,
  sticky: PropTypes.instanceOf(Sticky).isRequired,
  disabled: PropTypes.bool,
  isConfirmed: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onChangeMeta: PropTypes.func.isRequired,
  creditCards: PropTypes.array.isRequired,
}

Payment.defaultProps = {
  disabled: false,
  isConfirmed: false,
}

export default connect(mapStateToProps)(React.memo(Payment))
