import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { maruCStatusIdToStyleProps } from 'src/config'
import {
  FlexContainer,
  BubbleContainer,
  Bubble as StyledBubble,
  SmallBubbleContainer,
  SmallBubble,
} from './styled'

const UnconnectedBubble = props => {
  const { marucStatusId, marucStatus, label, style } = props
  const name =
    (marucStatus.find(x => x.id === marucStatusId) || {}).name ||
    '(不明 不正なステータス)'
  const styles = maruCStatusIdToStyleProps[marucStatusId] || {
    color: 'none',
    borderColor: 'gray',
  }
  return styles.skip ? null : (
    <BubbleContainer key={ marucStatusId } style={ style }>
      <StyledBubble color={ styles.color } borderColor={ styles.borderColor } />
      {label && <span>{name}</span>}
    </BubbleContainer>
  )
}

UnconnectedBubble.propTypes = {
  label: PropTypes.bool,
  style: PropTypes.object,
  marucStatusId: PropTypes.number.isRequired,
  marucStatus: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.isRequired,
    }),
  ).isRequired,
}

UnconnectedBubble.defaultProps = {
  label: true,
  style: {},
}

const SmallUnconnectedBubble = props => {
  const { marucStatusId, marucStatus, label, style } = props
  const name =
    (marucStatus.find(x => x.id === marucStatusId) || {}).name ||
    '(不明 不正なステータス)'
  const styles = maruCStatusIdToStyleProps[marucStatusId] || {
    color: 'none',
    borderColor: 'gray',
  }
  return styles.skip ? null : (
    <SmallBubbleContainer key={ marucStatusId } style={ style }>
      <SmallBubble color={ styles.color } borderColor={ styles.borderColor } />
      {label && <span>{name}</span>}
    </SmallBubbleContainer>
  )
}

SmallUnconnectedBubble.propTypes = {
  label: PropTypes.bool,
  style: PropTypes.object,
  marucStatusId: PropTypes.number.isRequired,
  marucStatus: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.isRequired,
    }),
  ).isRequired,
}

SmallUnconnectedBubble.defaultProps = {
  label: true,
  style: {},
}

export const Bubbles = props => {
  const { marucStatus } = props
  return (
    <FlexContainer>
      {marucStatus.map(({ id }) => (
        <SmallUnconnectedBubble
          key={ id }
          marucStatusId={ id }
          marucStatus={ marucStatus }
        />
      ))}
    </FlexContainer>
  )
}

Bubbles.propTypes = {
  marucStatus: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.isRequired,
    }),
  ).isRequired,
}

export const mapStateToProps = state => ({
  // marucStatus は検索用の値とステータス表示用の値で共有されているので、このフラグで制御している
  marucStatus: (state.master.data.marucStatus || []).filter(x => x.display),
})

export const ConnectedBubble = connect(mapStateToProps)(UnconnectedBubble)
export default connect(mapStateToProps)(Bubbles)
