import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const H = styled.h2`
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
  margin-right: 30px;
`

export const Header = ({ title }) => <H>{title}</H>

/**
 * propTypes
 * @type {object}
 */
Header.propTypes = {
  title: PropTypes.string.isRequired,
}

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin: 50px auto 0;
  max-width: 1200px;
`

const flexAlign = props =>
  props.alignLeft
    ? 'flex-start'
    : props.alignRight
      ? 'flex-end'
      : props.center
        ? 'center'
        : 'space-around'

export const FormsWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${flexAlign};
  align-items: center;
  width: 100%;
  margin: 20px auto;
`

export const ContentWrap = styled.div`
  font-size: 16px;
`
