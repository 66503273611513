import moment from 'moment'
import config from 'src/config'

/**
 * 市町村コードが正当かどうか検証します。
 * @param  {string} code 文字列で与えられる市町村コード
 * @return {boolean}     結果
 */
export const citycode = code => {
  if (isNaN(+code) || !+code) {
    return false
  }

  const codestr = code.toString()
  const length = codestr.length
  if (length < 5 || 6 < length) {
    return false
  }

  // チェックディジット検証
  if (length === 6) {
    let sum = 0
    for (let i = 0; i < length - 1; i++) {
      sum += +codestr[i] * (6 - i)
    }
    return (11 - (sum % 11)) % 10 === +codestr[length - 1]
  } else {
    return true
  }
}

/**
 * ボードにアクセスした時のパラメータ（Location: { region, year, month, day }）を正規化します
 * @param  {Location} params  location object
 * @param  {Array<string>} regions 地域マスタ
 * @return {Location}         fixed location
 */
export const sanitizeWaraLocation = (params, regions) => {
  // 日付の正規化
  // create new Moment and avoid undefined
  const date = moment([0]) // 0/1/1
    .add(params.year ? parseInt(params.year, 10) : 0, 'year')
    .add(params.month ? parseInt(params.month, 10) : 0, 'month')
    .add(-1, 'month')
    .add(params.day ? parseInt(params.day, 10) : 0, 'day')
    .add(-1, 'day')

  let year = date.year()
  let month = date.month() + 1
  let day = date.date()

  // 地名の正規化
  let region = params.region
  const regionInValid = !Object.keys(regions).includes(region)

  // 地域を1番目にする
  if (regionInValid) {
    region = Object.keys(regions)[0]
  }

  // どうしても正規化できなかった場合
  const dateInValid = isNaN(year) || isNaN(month) || isNaN(day)
  // 今日の日付にロールバック
  if (dateInValid) {
    const now = moment()
    year = now.year()
    month = now.month() + 1
    day = now.date()
  }

  // 書き換えが必要な場合
  const fixed =
    regionInValid ||
    dateInValid ||
    +params.year !== year ||
    +params.month !== month ||
    +params.day !== day

  return { region, year, month, day, fixed }
}

export const PAYMENT_MESSAGES = {
  INVALID_VALUE: '正しい「現金」の値を入力して下さい。',
  INVALID_PAYMENT_METHOD: '正しい「支払回数」を選択してください。',
  INVALID_PAYMENT_TIMES: '正しい「分割回数」を選択してください。',
}

export const isValidPayment = ({ data: payment = {} }) => {
  const messages = []

  // 正しい金額を入力している
  const isValidCashValue =
    !isNaN(parseInt(payment.cashValue, 10)) && payment.cashValue >= 0
  // メッセージ追加
  !isValidCashValue && messages.push(PAYMENT_MESSAGES.INVALID_VALUE)

  // 正しい支払方法を入力している
  const isValidPaymentMethod =
    !payment.creditCardId ||
    payment.creditCardId === config.paymentMethod.cash ||
    payment.creditCardId === config.paymentMethod.check ||
    payment.creditCardId === config.paymentMethod.homeServe
      ? true // 現金の時はOK
      : !isNaN(parseInt(payment.paymentMethodId, 10)) &&
        payment.paymentMethodId > 0
  // メッセージ追加
  !isValidPaymentMethod &&
    messages.push(PAYMENT_MESSAGES.INVALID_PAYMENT_METHOD)

  // 正しい支払回数を入力している
  const isValidPaymentTimes =
    payment.paymentMethodId === config.paymentMethod.split
      ? !isNaN(parseInt(payment.paymentTimes, 10)) && payment.paymentTimes > 0
      : true

  // メッセージ追加
  !isValidPaymentTimes && messages.push(PAYMENT_MESSAGES.INVALID_PAYMENT_TIMES)

  const result = isValidCashValue && isValidPaymentMethod && isValidPaymentTimes

  return { result, messages }
}

export const ACCOUNT_MESSAGES = {
  INVALID_VALUE: '正しい「売掛金」の値を入力して下さい。',
  INVALID_COLLECTED_VALUE: '正しい「回収売掛金」の値を入力してください。',
  INVALID_PAYMENT_METHOD: '正しい「支払回数」を選択してください。',
  INVALID_PAYMENT_TIMES: '正しい「分割回数」を選択してください。',
  INVALID_PAYMENT_DATE: '「集金日」を入力して下さい。',
}

export const isValidAccount = ({ data: account }, isCollectSticky = false) => {
  const messages = []

  let isValidAccountCollectedValue = true
  let isValidAccountValue = true
  // 集金付箋の時は回収売掛金、それ以外は金額をチェックする
  if (isCollectSticky) {
    // 正しい回収売掛金を入力している
    isValidAccountCollectedValue =
      !isNaN(parseInt(account.accountCollectedValue, 10)) &&
      account.accountCollectedValue >= 0
    !isValidAccountCollectedValue &&
      messages.push(ACCOUNT_MESSAGES.INVALID_COLLECTED_VALUE)
  } else {
    // 正しい金額を入力している
    isValidAccountValue =
      !isNaN(parseInt(account.accountValue, 10)) && account.accountValue >= 0
    // メッセージ追加
    !isValidAccountValue && messages.push(ACCOUNT_MESSAGES.INVALID_VALUE)
  }

  // 正しい支払方法を入力している
  const isValidAccountPaymentMethod =
    !account.accountCreditCardId ||
    account.accountCreditCardId === config.paymentMethod.cash ||
    account.accountCreditCardId === config.paymentMethod.check
      ? true // 現金の時はOK
      : !isNaN(parseInt(account.accountPaymentMethodId, 10)) &&
        account.accountPaymentMethodId > 0

  // メッセージ追加
  !isValidAccountPaymentMethod &&
    messages.push(ACCOUNT_MESSAGES.INVALID_PAYMENT_METHOD)

  // 正しい支払回数を入力している
  const isValidAccountPaymentTimes =
    account.accountPaymentMethodId === config.paymentMethod.split
      ? !isNaN(parseInt(account.accountPaymentTimes, 10)) &&
        account.accountPaymentTimes > 0
      : true

  // メッセージ追加
  !isValidAccountPaymentTimes &&
    messages.push(ACCOUNT_MESSAGES.INVALID_PAYMENT_TIMES)

  // 集金日は未入力でもOKにする。

  const result =
    isValidAccountValue &&
    isValidAccountCollectedValue &&
    isValidAccountPaymentMethod &&
    isValidAccountPaymentTimes

  return { result, messages }
}

/**
 * 表示対象外のマルシー付箋を削除するための判定処理。
 * マルシー以外の付箋は全て表示対象とする。
 *
 * @param  {stickyProps}  付箋のプロパティ
 * @return {boolean}      表示対象の時true,そうでない時false
 */
export const validMaruc = stickyProps => {
  // マルシー付箋でない時は必ず表示
  if (stickyProps.orderTypeId !== config.orderType.claimer) {
    return true
  }
  // 確定作業日時が未指定の時は表示しない
  const determinedDate = stickyProps.determinedDateTime
    ? stickyProps.determinedDateTime.date || ''
    : ''
  // measuresが未指定の時は表示しない。指定されており、5:用事 が含まれるマルシー付箋のみ表示
  const measures = stickyProps.marucComplaint
    ? stickyProps.marucComplaint.measures || []
    : []
  return determinedDate !== '' && measures.includes(5)
}

/**
 * 売上調整承認の画面とメニューを表示するための条件
 * <Autorize> のconditionに渡して使う。
 * @param {*} userRoles ユーザの権限Set
 */
export const salesAdjustment = userRoles => {
  // const hasSalesAdjustment = config.roles.salesadjustment.reduce(
  //   (prev, current) => prev && userRoles.has(current),
  //   true,
  // )
  // 2020/04/15 Req1021: 経理権限者、相談室権限者（上長チェックなし）で表示する。
  const hasSalesAdjustment = userRoles.has(config.roles.consultation)
  const hasAccounting = userRoles.has(config.roles.accounting)
  return hasSalesAdjustment || hasAccounting
}

/**
 * 日付が当日の時trueを返す。
 * @param {*} date 'YYYY-MM-DD'
 */
export const isToday = date => {
  const today = moment().format('YYYY-MM-DD')
  return date === today
}

/**
 * 日付が当日以降の時trueを返す。
 * @param {*} date 'YYYY-MM-DD'
 */
export const isAfterToday = date => {
  const today = moment(moment().format('YYYY-MM-DD'))
  return today <= moment(date)
}

/**
 * 日付が過去の時trueを返す。
 * @param {*} date 'YYYY-MM-DD'
 */
export const isBeforeToday = date => {
  const today = moment(moment().format('YYYY-MM-DD'))
  return moment(date) < today
}

/**
 * イメージ表示処理のタイプがPDF表示の時trueを返す。
 * @param {*} apitype イメージ表示の種別キー
 * @returns
 */
export const isInventory = apitype =>
  apitype === 'QPDF' ||
  'CPDF' ||
  'WPDF' ||
  'QKPDF' ||
  'CKPDF' ||
  'RPDF' ||
  'SPDF'
