import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

/**
 * Components
 */
import { Link } from 'react-router-dom'
import Authorize from 'src/components/commons/authorize'
import Logout from 'src/components/commons/login-form/partials/logout'
import Welcome from 'src/components/commons/login-form/partials/welcome'

// config
import config from 'src/config'

/**
 * 共通ヘッダーのコンポーネント
 * @param {object} props props
 * @return {ReactComponent}
 */
const CommonHeader = props => {
  const {
    route,
    subRoute,
    env: { ES_STEP, API_HOST, COMMODITY_KEIRI_URL, GENERAL_AFFAIRS_URL },
  } = props

  // わら画面へのリンク
  const date = new Date()
  const toWara = `/assign/wara/${date.getFullYear()}/${date.getMonth() +
    1}/${date.getDate()}`

  const navigationColor = API_HOST.includes('staging-wara')
    ? 'rgba(211, 47, 47, 0.8)'
    : 'rgba(27, 149, 191, 0.8)'
  const navigationActiveColor = API_HOST.includes('staging-wara')
    ? 'rgba(211, 47, 47, 1)'
    : 'rgba(27, 149, 191, 1)'

  const commodityKeiriMasterUrl = `${COMMODITY_KEIRI_URL}`
  const hlClientMasterUrl = `${GENERAL_AFFAIRS_URL}#/hl_client_masters`

  const ToLink = styled.span`
    &:hover {
      font-weight: bold;
    }
  `
  const Header = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: ${navigationColor};
    backdrop-filter: saturate(180%) blur(10px);
    z-index: 100;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

    @media print {
      display: none;
    }
  `

  const Nav = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
  `

  const Ul = styled.ul`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    color: #ffffff;
  `

  const Li = styled.li`
    position: relative;

    &:hover {
      > ul {
        display: block;
      }
    }
  `
  const NavText = styled.span`
    @media (max-width: 800px) {
      display: none;
    }
  `

  const ActivityBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem 0.5rem;
    /* stylelint-disable declaration-colon-newline-after */
    background-color: ${props =>
    props.active ? navigationActiveColor : 'inherit'};
    /* stylelint-enable declaration-colon-newline-after */

    &:hover {
      background-color: ${navigationActiveColor};
    }
  `

  const SubUl = styled.ul`
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 200px;
    background-color: #ffffff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  `

  const SubLi = styled.li`
    display: block;
  `

  const SubActivityBlock = styled.div`
    display: block;
    padding: 0.75rem 0.75rem;
    background-color: ${navigationColor};

    &:hover {
      background-color: ${navigationActiveColor};
    }
  `

  const linkStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: '0.5rem',
    color: '#ffffff',
    fontSize: '0.875rem',
    textDecoration: 'none',
  }

  const subLinkStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: '0.5rem',
    color: '#ffffff',
    fontSize: '0.875rem',
    textDecoration: 'none',
  }

  // window.open()で開くように変更
  const openMaster = masterUrl => {
    if (typeof masterUrl !== 'string') {
      return
    }
    window.open(masterUrl, '_blank')
  }

  return (
    <Header>
      <Nav>
        <Ul>
          <Li>
            <ActivityBlock active={ route === 'home' }>
              <Link to={ '/' } style={ linkStyle }>
                <i className={ 'fa fa-home' } />
                <NavText>{'ホーム'}</NavText>
              </Link>
            </ActivityBlock>
          </Li>
          {ES_STEP > 2 && (
            <Li>
              <Authorize roles={ config.roles.all }>
                <ActivityBlock active={ route === 'order-list' }>
                  <Link to={ '/order-list' } style={ linkStyle }>
                    <i className={ 'fa fa-list' } />
                    <NavText>{'注文一覧'}</NavText>
                  </Link>
                </ActivityBlock>
              </Authorize>
            </Li>
          )}
          <Li>
            <Authorize roles={ config.roles.call }>
              <ActivityBlock active={ route === 'call' }>
                <Link to={ '/call' } style={ linkStyle }>
                  <i className={ 'fa fa-volume-control-phone' } />
                  <NavText>{'案件登録'}</NavText>
                </Link>
              </ActivityBlock>
            </Authorize>
          </Li>
          <Li>
            <Authorize roles={ config.roles.assign }>
              <ActivityBlock active={ route === 'assign' }>
                <Link to={ toWara } style={ linkStyle }>
                  <i className={ 'fa fa-user-plus' } />
                  <NavText>{'案件ダッシュボード'}</NavText>
                </Link>
              </ActivityBlock>
            </Authorize>
          </Li>
          {ES_STEP > 2 && (
            <Li>
              <Authorize roles={ config.roles.accounting }>
                <ActivityBlock active={ route === 'accounting' }>
                  <Link to={ '/accounting' } style={ linkStyle }>
                    <i className={ 'fa fa-yen' } />
                    <NavText>{'経理課'}</NavText>
                  </Link>
                </ActivityBlock>
              </Authorize>
              <SubUl>
                <SubLi>
                  {/* <SubActivityBlock active={ subRoute === 'check' }>
                    <Link to={ '/accounting/check' } style={ subLinkStyle }>
                      {'金額確認'}
                    </Link>
                  </SubActivityBlock>
                </SubLi>
                <SubLi>
                  <SubActivityBlock active={ subRoute === 'receivables' }>
                    <Link to={ '/accounting/receivables' } style={ subLinkStyle }>
                      {'売掛金管理'}
                    </Link>
                  </SubActivityBlock>
                </SubLi>
                <SubLi>
                  <SubActivityBlock active={ subRoute === 'invoice' }>
                    <Link to={ '/accounting/invoice' } style={ subLinkStyle }>
                      {'書類発行'}
                    </Link>
                  </SubActivityBlock>
                </SubLi>
                <SubLi> */}
                  <SubActivityBlock active={ null }>
                    <a
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={ () => openMaster(commodityKeiriMasterUrl) }
                      style={ subLinkStyle }
                    >
                      <ToLink style={ subLinkStyle }>{'経理確認処理'}</ToLink>
                    </a>
                  </SubActivityBlock>
                </SubLi>
                {/* <SubLi>
                  <SubActivityBlock active={ subRoute === 'ss-self-payment' }>
                    <Link
                      to={ '/accounting/ss-self-payment' }
                      style={ subLinkStyle }
                    >
                      {'SS自己負担登録'}
                    </Link>
                  </SubActivityBlock>
                </SubLi> */}
                <SubLi>
                  <SubActivityBlock active={ subRoute === 'settlement' }>
                    <Link to={ '/accounting/settlement' } style={ subLinkStyle }>
                      {'精算処理'}
                    </Link>
                  </SubActivityBlock>
                </SubLi>
                {/* <SubLi>
                  <SubActivityBlock active={ subRoute === 'card' }>
                    <Link to={ '/accounting/card' } style={ subLinkStyle }>
                      {'カード管理表'}
                    </Link>
                  </SubActivityBlock>
                </SubLi>
                <SubLi>
                  <SubActivityBlock active={ subRoute === 'ranking' }>
                    <Link to={ '/accounting/ranking' } style={ subLinkStyle }>
                      {'SSリアルタイム売上ランキング'}
                    </Link>
                  </SubActivityBlock>
                </SubLi>
                <SubLi>
                  <SubActivityBlock active={ subRoute === 'payment-csv' }>
                    <Link to={ '/accounting/payment-csv' } style={ subLinkStyle }>
                      {'入金表'}
                    </Link>
                  </SubActivityBlock>
                </SubLi> */}
                <SubLi>
                  <SubActivityBlock active={ subRoute === 'reward' }>
                    <Link to={ '/accounting/reward' } style={ subLinkStyle }>
                      {'報酬明細'}
                    </Link>
                  </SubActivityBlock>
                </SubLi>
                {/* <SubLi>
                  <SubActivityBlock active={ subRoute === 'attendance' }>
                    <Link to={ '/accounting/attendance' } style={ subLinkStyle }>
                      {'勤怠表示'}
                    </Link>
                  </SubActivityBlock>
                </SubLi> */}
              </SubUl>
            </Li>
          )}

          {ES_STEP > 3 && (
            <Li>
              <Authorize roles={ config.roles.commodity }>
                <ActivityBlock active={ route === 'commodity' }>
                  <Link to={ '/commodity' } style={ linkStyle }>
                    <i className={ 'fa fa-truck' } />
                    <NavText>{'商材管理課'}</NavText>
                  </Link>
                </ActivityBlock>
              </Authorize>
            </Li>
          )}
          <Li>
            <Authorize roles={ config.roles.call }>
              <ActivityBlock>
                <Link to={ '/call/search' } style={ linkStyle }>
                  <i className={ 'fa fa-search' } />
                  <NavText>{'検索'}</NavText>
                </Link>
              </ActivityBlock>
            </Authorize>
          </Li>
        </Ul>
        <Authorize>
          <Welcome />
        </Authorize>
        <Authorize>
          <ActivityBlock>
            <Logout />
          </ActivityBlock>
        </Authorize>
      </Nav>
    </Header>
  )
}

CommonHeader.propTypes = {
  // ownProps
  route: PropTypes.string.isRequired,
  subRoute: PropTypes.string,
  // stateProps
  env: PropTypes.object.isRequired,
}

CommonHeader.defaultProps = {
  subRoute: '',
}

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
const mapStateToProps = state => {
  return {
    env: state.env,
  }
}

export default connect(mapStateToProps)(CommonHeader)
