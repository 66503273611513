import { CALL_API } from 'redux-api-middleware'
import { NETWORKER } from 'src/middlewares/network-monitor'

// Using redux-api-middleware
// https://www.npmjs.com/package/redux-api-middleware

// type RSAA: Redux Standard API-calling Actions
// https://www.npmjs.com/package/redux-api-middleware#redux-standard-api-calling-actions

import { ACTION_TYPES as NETWORK_ACTION_TYPES } from 'src/reducers/network-status'

import utils from 'src/lib/api-utils'
import switz from 'switz'
import update from 'immutability-helper'

/**
 * initial user preset state
 * @type {object}
 */
export const initialUserPresetState = {
  data: {
    user_id: '',
    regions: [],
  },
}

/**
 * Action Types
 * @type {string}
 */
export const USERPRESET_GET_REQUEST = 'USERPRESET.USERPRESET_GET_REQUEST'
export const USERPRESET_GET_SUCCESS = 'USERPRESET.USERPRESET_GET_SUCCESS'
export const USERPRESET_GET_FAILURE = 'USERPRESET.USERPRESET_GET_FAILURE'
export const USERPRESET_POST_REQUEST = 'USERPRESET.USERPRESET_POST_REQUEST'
export const USERPRESET_POST_SUCCESS = 'USERPRESET.USERPRESET_POST_SUCCESS'
export const USERPRESET_POST_FAILURE = 'USERPRESET.USERPRESET_POST_FAILURE'
export const SINGULATE_USERPRESET_SELECTED_REGION =
  'USERPRESET.SINGULATE_USERPRESET_SELECTED_REGION'
export const UPDATE_USERPRESET_REGION_DISPLAY =
  'USERPRESET.UPDATE_USERPRESET_REGION_DISPLAY'
export const UPDATE_USERPRESET_SS_ORDER =
  'USERPRESET.UPDATE_USERPRESET_SS_ORDER'
export const SHOW_SIMPLE_REGION = 'USERPRESET.SHOW_SIMPLE_REGION'
export const TOGGLE_STAFF_DISPLAY = 'USERPRESET.TOGGLE_STAFF_DISPLAY'
export const RESET = 'USERPRESET.RESET'

/**
 * action bulk exports
 * @type {object}
 */
export const ACTION_TYPES = {
  USERPRESET_GET_REQUEST,
  USERPRESET_GET_SUCCESS,
  USERPRESET_GET_FAILURE,
  USERPRESET_POST_REQUEST,
  USERPRESET_POST_SUCCESS,
  USERPRESET_POST_FAILURE,
  SINGULATE_USERPRESET_SELECTED_REGION,
  UPDATE_USERPRESET_REGION_DISPLAY,
  UPDATE_USERPRESET_SS_ORDER,
  SHOW_SIMPLE_REGION,
  TOGGLE_STAFF_DISPLAY,
  RESET,
}

/**
 * userpreset get action creator
 * @param  {string} username    username
 * @param  {string} accessToken access token
 * @return {object} redux-api-middleware に渡す非同期処理情報を内包したアクション
 */
export const createAsyncGetUserPresetAction = (username, accessToken, env) => {
  const base = `${utils.createEndpoint(env, 'userPreset')}`
  const endpoint = `${base}/${username}`

  return {
    [CALL_API]: {
      endpoint,
      method: 'GET',
      headers: { Authorization: `Bearer ${accessToken}` },
      types: [
        {
          type: USERPRESET_GET_REQUEST,
          meta: {
            [NETWORKER]: {
              target: 'getUserPreset',
              networkActionType: NETWORK_ACTION_TYPES.REQUEST,
            },
          },
        },
        {
          type: USERPRESET_GET_SUCCESS,
          meta: {
            [NETWORKER]: {
              target: 'getUserPreset',
              networkActionType: NETWORK_ACTION_TYPES.SUCCESS,
            },
          },
          payload: (action, state, res) => res.json().then(payload => payload),
        },
        {
          type: USERPRESET_GET_FAILURE,
          meta: {
            [NETWORKER]: {
              target: 'getUserPreset',
              networkActionType: NETWORK_ACTION_TYPES.FAILURE,
            },
          },
        },
      ],
    },
  }
}

/**
 * userpreset get action creator
 * @param  {string} username    username
 * @param  {object} body        preset
 * @param  {string} accessToken access token
 * @return {object} redux-api-middleware に渡す非同期処理情報を内包したアクション
 */
export const createAsyncPostUserPresetAction = (
  username,
  body,
  accessToken,
  env,
) => {
  const base = `${utils.createEndpoint(env, 'userPreset')}`
  const endpoint = `${base}/${username}`

  return {
    [CALL_API]: {
      endpoint,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
      types: [
        {
          type: USERPRESET_POST_REQUEST,
          meta: {
            [NETWORKER]: {
              target: 'postUserPreset',
              networkActionType: NETWORK_ACTION_TYPES.REQUEST,
            },
          },
        },
        {
          type: USERPRESET_POST_SUCCESS,
          meta: {
            [NETWORKER]: {
              target: 'postUserPreset',
              networkActionType: NETWORK_ACTION_TYPES.SUCCESS,
            },
          },
        },
        {
          type: USERPRESET_POST_FAILURE,
          meta: {
            [NETWORKER]: {
              target: 'postUserPreset',
              networkActionType: NETWORK_ACTION_TYPES.FAILURE,
            },
          },
        },
      ],
    },
  }
}

/**
 * userPreset reducer
 * @param  {object} [state=initialUserPresetState] previous state
 * @param  {object} action                        dispatched action
 * @return {object}                                next state
 */
export default (state = initialUserPresetState, action) => {
  const { type, payload } = action
  return switz(type, s =>
    s
      // reducer for userPreset updating
      .case(USERPRESET_GET_REQUEST, () => state)
      .case(USERPRESET_GET_SUCCESS, () => {
        const regions = Array.isArray(payload.regions) ? payload.regions : []
        return update(state, {
          data: {
            regions: { $set: regions },
          },
        })
      })
      .case(USERPRESET_GET_FAILURE, () => state)
      .case(USERPRESET_POST_REQUEST, () => state)
      .case(USERPRESET_POST_SUCCESS, () => state)
      .case(USERPRESET_POST_FAILURE, () => state)
      .case(SINGULATE_USERPRESET_SELECTED_REGION, () => {
        const { regionId: selectedRegionId, masterRegions } = payload

        const updatingRegions = masterRegions.map(x => {
          const regionId = x.id
          const userPresetRegion =
            state.data.regions.filter(region => region.id === regionId)[0] || {}
          userPresetRegion.display = selectedRegionId === regionId
          userPresetRegion.id = regionId
          return userPresetRegion
        })
        return update(state, {
          data: {
            regions: { $set: updatingRegions },
          },
        })
      })
      .case(UPDATE_USERPRESET_REGION_DISPLAY, () => {
        // チェックされたエリアの付箋リストを表示・非表示にする
        // payload: {regionId: エリアID, display: true=表示、false=非表示}
        // ミドルウェアとの関連が複雑なので、ここで全選択・全解除を実装する。本来はresetと同じ方法を使いたいところ。
        if (Array.isArray(payload.regionId)) {
          // regionId にエリアIDの配列を設定し、特定の種類のエリアを一括操作する
          const result = state.data.regions.map(region => {
            return payload.regionId.includes(region.id)
              ? {
                ...region,
                display: payload.display,
              }
              : region
          })
          return update(state, {
            data: {
              regions: { $set: result },
            },
          })
        }
        if (payload.regionId === 'ALL') {
          // エリアID='ALL'の時は全選択・全解除とする。displayで区別する。
          const result = state.data.regions.map(region => ({
            ...region,
            display: payload.display,
          }))
          return update(state, {
            data: {
              regions: { $set: result },
            },
          })
        }
        // 単独のエリアの表示・非表示制御（今までの処理）
        const knownRegionIds = state.data.regions.map(sec => sec.id)
        const isKnown = knownRegionIds.includes(payload.regionId)

        const regions = isKnown
          ? state.data.regions.map(region =>
            region.id === payload.regionId
              ? { ...region, display: payload.display }
              : region,
          )
          : state.data.regions.concat([
            { id: payload.regionId, display: payload.display, ssOrder: null },
          ])

        return update(state, {
          data: {
            regions: { $set: regions },
          },
        })
      })
      .case(TOGGLE_STAFF_DISPLAY, () => {
        const { regionId, ssId, display } = payload

        const regionIndex = (state.data.regions || [])
          .map(region => region.id)
          .indexOf(regionId)

        const theStaffList =
          regionIndex === -1
            ? []
            : state.data.regions[regionIndex].listUserDisplayRegionSS || []

        const staffIndex = theStaffList.map(staff => staff.ssId).indexOf(ssId)

        return update(state, {
          data: {
            regions: {
              $apply: x =>
                update(
                  x || [],
                  regionIndex === -1
                    ? {
                      $push: [
                        {
                          id: regionId,
                          listUserDisplayRegionSS: [{ ssId, display }],
                        },
                      ],
                    }
                    : {
                      [regionIndex]: {
                        listUserDisplayRegionSS: {
                          $apply: x =>
                            update(
                              x || [],
                              staffIndex === -1
                                ? {
                                  $push: [{ ssId, display }],
                                }
                                : {
                                  [staffIndex]: {
                                    display: {
                                      $set: display,
                                    },
                                  },
                                },
                            ),
                        },
                      },
                    },
                ),
            },
          },
        })
      })
      .case(SHOW_SIMPLE_REGION, () => {
        const { regionId, masterRegions } = payload
        const regions = masterRegions.map(region => ({
          id: region.id,
          display: region.id === regionId,
        }))
        return update(state, {
          data: {
            regions: {
              $set: regions,
            },
          },
        })
      })
      .case(UPDATE_USERPRESET_SS_ORDER, () => {
        // regionごとの、SSの並び順を更新
        // このアクションは、wara.insertList アクションの後で自動でdispatchされる
        // この後にシリアライズ（POST）に続く
        // これらはposthooks で定義されている。
        // NOTE: このアクションを手動で使ってはいけない
        const { regionId, ssOrder } = payload
        const regionIndex = state.data.regions
          .map(region => region.id)
          .indexOf(regionId)

        if (regionIndex === -1) {
          // 見つからない、ということはあるのか？
          return state
        } else {
          return update(state, {
            data: {
              regions: {
                [regionIndex]: {
                  ssOrder: { $set: ssOrder },
                },
              },
            },
          })
        }
      })
      .case(RESET, () => {
        return update(state, {
          data: {
            regions: { $set: [] },
          },
        })
      })
      .default(() => state),
  )
}
