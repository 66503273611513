import utils from 'src/lib/api-utils'

export const get = (startDate, regionId, accessToken, env) => {
  const base = utils.createEndpoint(env, 'downloadReward')
  const url = `${base}/${startDate}${regionId ? '/' + regionId : ''}`

  const request = fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  })

  return request
}

/**
 * 複数エリアの報酬明細をまとめてダウンロード
 * @param {*} startDate
 * @param {*} regionIds
 * @param {*} accessToken
 * @param {*} env
 */
export const getMulti = (startDate, regionIds, accessToken, env) => {
  const url = utils.createEndpoint(env, 'downloadReward')
  const body = JSON.stringify({
    startDate,
    regionIds,
  })

  const request = fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body,
  })

  return request
}

export default get
