import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { stickyYellow, red } from 'src/colors'

const P = styled.p`
  display: block;
  height: 30px;
  margin-bottom: 2px;
  padding: 0 20px;
  border: 1px solid $red;
  border-radius: 5px;
  background: ${stickyYellow};
  color: ${red};
  line-height: 30px;

  &::before {
    font-family: FontAwesome;
    content: '\f071';
  }
`

export const ValidationWarningMessage = props => <P>{props.message}</P>

ValidationWarningMessage.propTypes = {
  message: PropTypes.string.isRequired,
}

export default ValidationWarningMessage
