import utils from 'src/lib/api-utils'

/**
 * SSの稼働状態を取得する
 * @param {*} date
 * @param {*} accessToken
 * @param {*} env
 */
export const expoGetWorkList = (date, accessToken, env) => {
  const endPoint = utils.createExpoEndpoint(env, 'workList')
  const uri = `${endPoint}/${date}`

  return fetch(uri, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  })
}

export default expoGetWorkList
