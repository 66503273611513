import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// component
import UnFinishedStickyContent from './partials/unfinished'
import StickyContentButtons from '../buttons'
import {
  MarucBadge,
  ReRequestBadge,
  PhoneCallBadge,
  ShowRepeatBadge,
} from 'src/components/commons/badges'

// libs
// import { findStickyFromWara } from 'src/lib/search'
import ClassSticky from 'src/lib/class-sticky'
import styled from 'styled-components'
import config from 'src/config'

export const Split = styled.div`
  display: block;
  position: absolute;
  top: 50px;
  left: 10px;
  padding: 40px 40px;
  border-radius: 4px;
  background-color: rgba(128, 128, 128, 0.5);
  color: white;
  font-size: large;
  font-weight: bold;
  text-align: center;
  z-index: 20;
`
// background-color: ${props => props.color};

/**
 * map state to props
 * @param  {object} state mapping state
 * @return {object}       mapped props
 */
const mapStateToProps = state => {
  return {
    wara: state.wara.data,
  }
}

export class NormalStickySurface extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    className: PropTypes.string.isRequired,
    location: PropTypes.shape({
      regionIndex: PropTypes.number.isRequired,
      listIndex: PropTypes.number.isRequired,
      stickyIndex: PropTypes.number.isRequired,
    }).isRequired,
    sticky: PropTypes.instanceOf(ClassSticky).isRequired,
    needButtons: PropTypes.bool,
    getSSworkList: PropTypes.func.isRequired,
    workState: PropTypes.number.isRequired,
    // stateProps
    wara: PropTypes.shape({
      regions: PropTypes.array,
      dock: PropTypes.object,
    }).isRequired,
    isSplitSource: PropTypes.bool.isRequired,
    setWaiting: PropTypes.func.isRequired,
    isMaruc: PropTypes.bool.isRequired,
    isReRequest: PropTypes.bool.isRequired,
    isPhoneCall: PropTypes.bool.isRequired,
    isShowSchedule: PropTypes.bool.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    needButtons: true,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate() {
    // console.log(this.props, nextProps)
    return true
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const {
      // ownProps
      className,
      location,
      sticky,
      needButtons,
      isSplitSource,
      getSSworkList,
      workState,
      // stateProps
      // wara,
      setWaiting,
      isMaruc,
      isReRequest,
      isPhoneCall,
      isShowSchedule,
    } = this.props

    // const sticky = findStickyFromWara(wara, location) || {}

    const isDocked = location.regionIndex === -1 || location.listIndex === -1
    const isSplit = sticky.json().stickyStatusId === config.stickyStatus.split

    return (
      <div className={ className }>
        <UnFinishedStickyContent
          sticky={ sticky }
          isSplitSource={ isSplitSource }
          isDocked={ isDocked }
        />
        {needButtons ? (
          <StickyContentButtons
            location={ location }
            sticky={ sticky }
            setWaiting={ setWaiting }
            getSSworkList={ getSSworkList }
            workState={ workState }
          />
        ) : null}
        {isSplit && <Split>{'仮予約'}</Split>}
        {isPhoneCall && <PhoneCallBadge offsetX={ -4 } offsetY={ 7 } />}
        {isMaruc && <MarucBadge offsetX={ -4 } offsetY={ 7 } />}
        {isReRequest && <ReRequestBadge offsetX={ -4 } offsetY={ 7 } />}
        {isShowSchedule && <ShowRepeatBadge offsetX={ -4 } offsetY={ 7 } />}
      </div>
    )
  }
}

export default connect(mapStateToProps)(NormalStickySurface)
