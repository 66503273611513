import Region from './'
import { connect } from 'react-redux'

/**
 map state to props
 * @param  {object} state    [description]
 * @param  {object} ownProps [description]
 * @return {object}          [description]
 */
const mapStateToProps = (state, ownProps) => {
  const region = state.wara.data.regions[ownProps.location.regionIndex] || {}
  const { id } = region

  return {
    // 親から渡されたregionIndexを元に、regionを取得
    id,
    lists: region.lists,
    masterRegions: state.master.data.regions,
    userPresetRegions: state.userPreset.data.regions,
    isDebugMode: state.env.DEBUG,
  }
}

export default connect(mapStateToProps)(Region)
