import { connect } from 'react-redux'
import { ACTION_TYPES as USERPRESET_ACTION_TYPES } from 'src/reducers/user-preset'

/**
 * map state to props
 * @param  {object} state mapping state
 * @return {object}       mapped props
 */
const mapStateToProps = state => {
  return {
    masterRegions: state.master.data.regions,
    displayingRegions: ((state.userPreset.data || {}).regions || [])
      .filter(region => region.display)
      .map(region => region.id),
    isDebugMode: state.env.DEBUG,
  }
}

/**
 * map dispatch to props
 * @param  {function} dispatch dispatcher
 * @return {object}    mapped dispatcher as props
 */
const mapDispatchToProps = dispatch => ({
  /**
   * 地域に表示・非表示の変更
   * @param  {number} regionId 地域ID
   * @param  {boolean} display  表示・非表示の別
   * @return {void}
   */
  makeRegionVisible: (regionId, display) =>
    dispatch({
      type: USERPRESET_ACTION_TYPES.UPDATE_USERPRESET_REGION_DISPLAY,
      payload: { regionId, display },
    }),

  /**
   * ユーザープリセットをリセットする
   * @return {void}
   */
  resetUserPreset: () =>
    dispatch({ type: USERPRESET_ACTION_TYPES.RESET, payload: {} }),
})

export default SelectRegions =>
  connect(mapStateToProps, mapDispatchToProps)(SelectRegions)
