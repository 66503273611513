// lib
import { createExpoEndpoint } from 'src/lib/api-utils'

export const getList = (stickyId, accessToken, env) => {
  const endpoint = createExpoEndpoint(env, 'list')

  const request = fetch(endpoint, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: `stickyId=${stickyId}`,
  })

  return request
}

export default getList
