import PropTypes from 'prop-types'
import React from 'react'
import Sticky from 'src/lib/class-sticky'
import connect from '../connect'
import SingularContact from './singular-contact'

import AddButton from './add-button'

/**
 * 連絡先の表示
 * @type {ReactComponent}
 */
export class Contacts extends React.Component {
  /**
   * Validation
   * @type {object}
   */
  static propTypes = {
    update: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
    disabled: PropTypes.bool.isRequired,
    searchDisabled: PropTypes.bool,
    isClaimer: PropTypes.bool, // マルシー付箋かどうか
    closeModal: PropTypes.func,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    update: false,
    isClaimer: false,
    searchDisabled: false,
    closeModal: () => {},
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate() {
    return true
  }

  /**
   * contactsを1次元配列に変換する。
   * 削除済、別の種類に移動されたcontact(moved=true)を削除
   */
  getComplexedContacts = contacts => {
    const complexedContacts = []
    contacts.forEach((tabContacts, i) => {
      (tabContacts || []).forEach((contact, j) => {
        if (!contact.deleted && !contact.moved) {
          complexedContacts.push({ contact, tabIndex: i, contactsIndex: j })
        }
      })
    })
    /**
     * contactsが一つもない時は空の連絡先を追加する。
     */
    if (complexedContacts.length === 0) {
      complexedContacts.push({
        contact: {},
        tabIndex: 0,
        contactsIndex: 0,
        isGhost: true,
      })
    }
    return complexedContacts
  }

  warning = {
    color: '#ff4f3d',
    fontSize: '1rem',
  }

  /**
   * render
   * @return {ReactDOM} rendered result
   */
  render() {
    const {
      // ownProps
      update,
      sticky,
      isClaimer,
      route,
      disabled,
      searchDisabled,
      draggable,
      closeModal,
    } = this.props

    const contacts = sticky.json().contacts || []
    let complexedContacts = this.getComplexedContacts(contacts)

    /**
     * マルシー付箋の時、最初の連絡先のみを表示する。
     */
    if (isClaimer) {
      complexedContacts = [complexedContacts[0]]
    }

    // 連絡先の追加可否判定
    const canAddContact = !isClaimer

    // 新規追加するときの種別
    const addTabIndex =
      complexedContacts[complexedContacts.length - 1].tabIndex || 0

    // コール課以外から呼ばれた時は項目を編集不可にする。
    const readOnly = route !== 'call'

    // 項目ごとのタブオーダーの開始値を設定する
    let tabOrderStart = -1

    // 少なくとも電話番号が1つは必須なのは、受付会社がイースマイル、キレイエ、交換の達人の時
    const isPhoneNumberRequired = [1, 3, 5].includes(sticky._props.companyId)
    const hasAtLeastOnePhoneNumber =
      !isPhoneNumberRequired ||
      (sticky._props.contacts || [])
        .flat()
        .some(contact => !!contact?.phoneNumber)

    return (
      <div>
        {/* {!hasAtLeastOnePhoneNumber && (
          <span style={ this.warning }>{'* 電話番号を最低1つは入力'}</span>
        )} */}
        {complexedContacts.map(
          ({ contact, tabIndex, contactsIndex, isGhost }) => {
            // 1項目にある項目数に応じて追加する値は調整する。
            tabOrderStart = tabOrderStart === -1 ? 1 : tabOrderStart + 20
            return (contact || {}).deleted ? null : (
              <SingularContact
                // TODO: パフォーマンスに劣る
                key={ `${tabIndex}-${contactsIndex}` }
                { ...{
                  sticky,
                  contacts, // 大元を渡しておく NOTE: なんで？
                  contact: isGhost ? {} : contact,
                  tabIndex,
                  contactsIndex,
                  isGhost,
                  update,
                  searchDisabled,
                  readOnly,
                } }
                isClaimer={ isClaimer }
                route={ route }
                disabled={ disabled }
                draggable={ draggable }
                closeModal={ closeModal }
                tabOrderStart={ tabOrderStart }
              />
            )
          },
        )}
        {// 追加先の初期値は最後の種別とする。
          canAddContact && (
            <AddButton
              { ...{
                sticky,
                route,
                tabIndex: addTabIndex,
                disabled,
                update,
              } }
            />
          )}
      </div>
    )
  }
}

export default connect(Contacts)
