import config from 'src/config'
/**
 * googleマップで開く
 * @param  {string} address [description]
 * @return {Window} window object
 */

export default address => () => {
  const url = 'https://www.google.co.jp/maps?q=' + address
  const width = config.googleMapWindow.width
  const height = config.googleMapWindow.height
  return window.open(url, '_blank', `width=${width},height=${height}`)
}
