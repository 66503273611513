import React from 'react'
import PropTypes from 'prop-types'
import linkHistoryHOC from './hocs/link-history'

export class Limit extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    value: PropTypes.string.isRequired,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return this.props.value !== nextProps.value
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { value } = this.props
    return <input type={ 'hidden' } value={ value } />
  }
}

export default linkHistoryHOC(Limit, 'limit')
