import { createInventoryEndpoint } from 'src/lib/api-utils'

const query = (body, accessToken, env) => {
  const url = createInventoryEndpoint(env, 'query')
  const request = fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body,
  })
  return request
}

export const workCompleteContract = (
  stickyId,
  contractNumber,
  accessToken,
  env,
) => {
  const body = {
    query: `mutation workCompleteContract($stickyId: Int!, $contractNumber: String!) {
      workCompleteContract(stickyId: $stickyId, contractNumber: $contractNumber)
    }
  `,
    variables: {
      stickyId,
      contractNumber,
    },
  }
  return query(JSON.stringify(body), accessToken, env)
}

export const listOriginalContract = (orderId, accessToken, env) => {
  const body = {
    query: `query listOriginalContract($orderId: String!) {
      listOriginalContract(orderId: $orderId) {
        id
        stickyId
        orderId
        originalQuotationNumber
        contractNumber
        quotationIssuedDate
        contractIssuedDate
        customerName
        customerAddress
        workType
        regionId
        staffId
        officeName
        staffName
        workStart
        workEnd
        workTermText
        contents {
          id
          commodityId
          displayMaker
          displayModel
          displayContent
          price
          amount
          decimalNumber
          originalWorkType
          templateType
          code
          name
          model
          color
          size
          commodityType
          commodityMakerType
          pipeType
          pipeMakerType
          open
          marketPrice
          total
          readonly
        }
        taxIncludeTotal
        payments {
          paymentType
          paymentValue
          cashDate
          creditCardType
          creditPaymentMethod
          creditPaymentTimes
          collectionDate
          collectionTime
          transferDate
          transferName
          memo
          name
          phoneNumber
          phoneNumberType
          phoneNumberDesc
        }
        memo
        confirmCheck
        signature
        documentStatus
        workComplete
      }
    }
    `,
    variables: {
      orderId,
    },
  }
  return query(JSON.stringify(body), accessToken, env)
}
