import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { baseColor } from 'src/colors'
import button from 'src/styled/button'

export const RegionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`

export const PrefsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
`

const Button = styled.button`
  ${button};
  margin: 0;
  padding: 0 10px;
  background: none;
  border: none;
  outline: none;
  text-decoration: underline;
  color: ${baseColor};
`

export const ToggleButton = props => (
  <Button { ...props }>
    <i className={ props.isOpen ? 'fa fa-caret-down' : 'fa fa-caret-right' } />
  </Button>
)

ToggleButton.propTypes = {
  // ownProps
  isOpen: PropTypes.bool.isRequired,
}

export const ToggleBody = styled.div`
  display: block;
  height: ${props => (props.isOpen ? 'auto' : 0)};
  opacity: ${props => (props.isOpen ? 1 : 0)};
  transition-duration: 0.2s;
  overflow: hidden;
`
