import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'

// action creators
import { actionCreators as $1 } from 'src/reducers/wara'
import { actionCreators as $2 } from 'src/reducers/stages'
import { actionCreators as paymentsActionCreators } from 'src/reducers/payments'

// utils
import Sticky from 'src/lib/class-sticky'
import getPayments from 'src/lib/payments-api/get'
import getAccountHistory from 'src/lib/account-history-api/get'
import update from 'immutability-helper'
import getcontractAmount from 'src/lib/payments-api/contractamount.js'
import config from 'src/config'

// components
import ContractTotalPayment from './contract-total-payment'
import Payment from './payment'
import Account from './account'
import SelectPayer from './select-payer'
import ErrorMessage from 'src/components/commons/validation-error-message'
import ObjectDumper from 'src/components/debuggers/object-dumper'
import HouseLabo from 'src/components/commons/custom-controls/assign/house-labo'
import HomeServe from '../home-serve'
import ECBusiness from '../ec-business'

const defineBackGroundColor = ({ isCash, isAccount }) => {
  if (isCash) {
    return '#ffe'
  } else if (isAccount) {
    return '#eff'
  } else {
    return '#eee'
  }
}

export const PaymentsArea = styled.div`
  padding: 1em;
  background-color: ${defineBackGroundColor};
  border: 1px solid #aaa;
  border-radius: 4px;
  margin: 1em;
`

export const Hr = styled.hr`
  margin-top: 0.5em;
  margin-bottom: 1em;
`

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
export const mapStateToProps = state => {
  return {
    accessToken: state.login.authentication.accessToken,
    env: state.env,
    allPayments: state.payments.data,
    storedPayments: state.payments.store,
    isDebugMode: state.env.DEBUG,
  }
}

export const mapDispatchToProps = (dispatch, ownProps) => {
  const $ = ownProps.route === 'history' ? $2 : $1

  return {
    storePayments: (stickyId, allPayments) =>
      dispatch(paymentsActionCreators.store(stickyId, allPayments)),
    clearPayments: () => dispatch(paymentsActionCreators.clear()),
    updateStage: sticky => dispatch($.updateStage(sticky)),
  }
}

// eslint-disable-next-line react/require-optimization
export class PaymentsWrap extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
    disabled: PropTypes.bool.isRequired,
    newContactExists: PropTypes.bool.isRequired,
    // これは、集金付箋で使うプロパティ
    isCollectSticky: PropTypes.bool,
    collectStickyProps: PropTypes.shape({
      id: PropTypes.number,
    }),
    // ここで使うupdateが他のコンポーネントと違うため、フォームからupdateを渡す。
    propUpdate: PropTypes.func.isRequired, // 付箋の更新に使用する。
    // stateProps
    accessToken: PropTypes.string.isRequired,
    env: PropTypes.object.isRequired,
    // allPayments: PropTypes.array.isRequired,
    storedPayments: PropTypes.object.isRequired,
    isDebugMode: PropTypes.bool.isRequired,
    // dispatchProps
    storePayments: PropTypes.func.isRequired,
    clearPayments: PropTypes.func.isRequired,
    updateStage: PropTypes.func.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    isCollectSticky: false,
    collectStickyProps: {},
  }

  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    this.state = {
      allPayments: [],
      contractAmount: null,
      status: 'request',
    }
    // 支払情報を初期化しておく。支払情報は、マウント後にリクエストして取ってくるため。
    this.props.clearPayments()
    this.getStickyPayments()
  }

  /**
   * 付箋がクリアされた時に持っている支払い情報をクリアする。
   * 他のケースでも対応が必要かもしれない。
   * @param {*} nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.sticky === null || nextProps.sticky.id === null) {
      this.setState({
        allPayments: [],
        contractAmount: null,
      })
    }
    // 付箋が更新された時、金額情報を更新する
    const thisSticky = this.props.sticky ? this.props.sticky.json() : {}
    const nextSticky = nextProps.sticky ? nextProps.sticky.json() : {}
    if (thisSticky.updateAt !== nextSticky.updateAt) {
      this.getStickyPayments()
    }
    // 支払い情報が変更された時は何もせず戻る。
    if (this.state.allPayments !== nextState.allPayments) {
      return
    }
  }

  getStickyPayments = () => {
    const { sticky, accessToken, env } = this.props
    const stickyContactIds = (sticky.stickyContactIds || []).filter(
      id => id !== null && id !== -1,
    )
    getPayments(stickyContactIds, accessToken, env)
      .then(payments =>
        Promise.all(
          payments.map(payment => {
            return getAccountHistory(payment.data.id, accessToken, env)
              .then(res => {
                if (res.ok) {
                  return res.json()
                } else {
                  throw 'GETエラー'
                }
              })
              .then(accountHistories => {
                if ((accountHistories.accountHistories || []).length > 0) {
                  const last =
                    accountHistories.accountHistories[
                      accountHistories.accountHistories.length - 1
                    ]
                  payment.data.accountCollectedValue =
                    payment.data.accountValue - last.remainValue
                }
                return payment
              })
          }),
        ),
      )
      .then(allPayments => this.setState({ allPayments, status: 'success' }))
      .catch(err => {
        console.error(err)
        this.setState({ status: 'failure' })
      })

    // 契約金額の総額を取得する
    if (sticky && sticky.id) {
      getcontractAmount(sticky.id, accessToken, env)
        .then(amount => {
          console.log(amount)
          this.setState({ contractAmount: amount })
        })
        .catch(err => {
          console.error(err)
          this.setState({ status: 'failure' })
        })
    }
  }

  addPayment = (payer, stickyId) =>
    this.setState(
      update(this.state, {
        allPayments: {
          $push: [
            {
              data: { stickyId, creditCardId: -1 }, // 支払い方法の初期値に「現金」を指定する
              meta: { id: payer.id },
              paymentType: 'payments',
            },
          ],
        },
      }),
    )

  addAccount = (payer, stickyId) =>
    this.setState(
      update(this.state, {
        allPayments: {
          $push: [
            {
              data: { stickyId },
              meta: { id: payer.id },
              paymentType: 'accounts',
            },
          ],
        },
      }),
    )

  /**
   * 支払い情報を更新する
   * @param {*} payment 更新するデータ
   * @param {*} index 支払い情報のインデックス
   * 　indexは現金と売掛全てでユニークな番号が割り振られる。
   * @returns
   */
  onChange = (payment, index) => {
    const nextState = update(this.state, {
      allPayments: {
        [index]: { data: { $merge: payment } },
      },
    })
    // 変更内容をチェックし、エラーの時は反映しない。
    const { result, message } = this.hasErrorPayments(nextState.allPayments)
    if (result) {
      alert(message)
      return
    }

    this.setState(nextState)

    // ストアには変更のあった支払いだけを格納する。
    // meta, paymentTypeは最初はallPaymentsからコピーし、その後はストアの値を使う。
    // dataは更新された値のみを設定。ただしid, updateAtはmetaなどと同様にする。
    const allPayments = nextState.allPayments
    const storedPayments = [
      ...(this.props.storedPayments[this.props.sticky.id] || []),
    ]
    const sPayment = storedPayments[index] || { meta: {}, data: {} }
    if (this.props.isCollectSticky) {
      // 集金付箋の時は全情報をコピーしないと更新ボタンの有効性判定ができなくなる。
      storedPayments[index] = { ...allPayments[index] }
    } else {
      // 通常付箋の場合は変更のあったデータのみを設定する。
      storedPayments[index] = {
        meta: { ...sPayment.meta, ...allPayments[index].meta },
        paymentType: sPayment.paymentType || allPayments[index].paymentType,
        data: {
          ...sPayment.data,
          ...payment,
          id: sPayment.data.id || allPayments[index].data.id || null,
          updateAt:
            sPayment.data.updateAt || allPayments[index].data.updateAt || null,
        },
      }
    }
    this.props.storePayments(this.props.sticky.id, storedPayments)
  }

  /**
   * 支払い情報のmetaを更新する
   * @param {*} key キー
   * @param {*} value 値
   * @param {*} index allPaymentsのインデックス
   */
  onChangeMeta = (key, value, index) => {
    const nextState = update(this.state, {
      allPayments: {
        [index]: { meta: { $merge: { [key]: value } } },
      },
    })
    // 変更内容をチェックし、エラーの時は反映しない。
    const { result, message } = this.hasErrorPayments(nextState.allPayments)
    if (result) {
      alert(message)
      return
    }
    // console.log(nextState)
    this.setState(nextState)
    // ストアの支払い情報を更新する。やり方はonChangeと同様。
    const allPayments = nextState.allPayments
    const storedPayments = [
      ...(this.props.storedPayments[this.props.sticky.id] || []),
    ]
    const sPayment = storedPayments[index] || { meta: {}, data: {} }
    storedPayments[index] = {
      meta: { ...sPayment.meta, ...allPayments[index].meta, [key]: value },
      paymentType: sPayment.paymentType || allPayments[index].paymentType,
      data: {
        ...sPayment.data,
        ...allPayments[index].data,
        id: sPayment.data.id || allPayments[index].data.id || null,
        updateAt:
          sPayment.data.updateAt || allPayments[index].data.updateAt || null,
      },
    }
    // storedPayments[index] = { ...allPayments[index] } // ↑の変更結果をコピー
    this.props.storePayments(this.props.sticky.id, storedPayments)
  }

  /**
   * 支払い情報にエラーがないかチェックする。
   * @returns: { result: true/false, message: エラーメッセージ (result = trueの時のみ)}
   */
  hasErrorPayments = allPayments => {
    let result = false
    let message = ''
    // 同じ支払い者の集金があればエラー
    const payers = new Set()
    for (let i = 0; i < allPayments.length; i++) {
      const payment = allPayments[i]
      if (
        payment.paymentType === 'accounts' &&
        payment.data.accountCollectionMethodId === 2
      ) {
        if (payers.has(payment.meta.id)) {
          result = true
          message = '同一支払い者の集金は2つ以上作成できません。'
          break
        }
        payers.add(payment.meta.id)
      }
    }
    return { result, message }
  }

  makePayer = (tabIndex, contactsIndex) => {
    const diffProps = {
      contacts: {
        [tabIndex]: {
          [contactsIndex]: { isPayer: true },
        },
      },
    }
    this.props.updateStage(new Sticky(diffProps))
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const {
      sticky,
      route,
      newContactExists,
      isDebugMode,
      disabled,
      isCollectSticky,
      propUpdate,
      // collectStickyProps,
      storedPayments,
    } = this.props

    const { allPayments, contractAmount, status } = this.state

    // 支払情報の振り分け
    const payments = []
    const accounts = []

    allPayments.forEach((payment, paymentIndex) => {
      if (payment.paymentType === 'payments') {
        payments.push({ ...payment, paymentIndex })
      } else if (payment.paymentType === 'accounts') {
        accounts.push({ ...payment, paymentIndex })
      }
    })

    const forceDisabled =
      disabled ||
      route === 'history' ||
      (route === 'assign' && newContactExists) ||
      status !== 'success'

    const isHouseLabo = sticky._props.companyId === config.company.HL
    const isHomeServe = sticky._props.companyId === config.company.SV
    const isEC = sticky._props.companyId === config.company.EC

    return (
      <div>
        {status === 'failure' ? (
          <ErrorMessage
            message={
              'ネットワークエラーが発生しました。支払情報を編集できません。'
            }
          />
        ) : null}

        {/* メモ： ほとんどのコンポーネントは NormalForm から付箋を更新するための
         update を渡されているが、PaymentWrap は渡されていないうえ、immutability-helperの
         updateが使われている（金額の差分は付箋とは別にあるため？）。HouseLaboは付箋の更新に
         他と同じupdateを使うようにしているため、propUpdateという名前でupdateを受け渡しする
         ようにするというわかりづらい方法をとっている。画面によりupdateが異なり、updateStageが
         隠れたそのupdateを使うという訳のわからない作りになっているのがそもそもの問題。 */}
        {isHouseLabo && (
          <HouseLabo
            sticky={ sticky }
            route={ route }
            disabled={ disabled }
            update={ propUpdate }
            contractAmount={ contractAmount }
          />
        )}
        {isHomeServe && (
          <HomeServe
            sticky={ sticky }
            route={ route }
            disabled={ disabled }
            update={ propUpdate }
            contractAmount={ contractAmount }
          />
        )}
        {isEC && <ECBusiness sticky={ sticky } update={ propUpdate } />}
        {/* ホームサーブの時は契約金を通常の表示にする？今後の変更でどうなるか不明。 */}
        <ContractTotalPayment
          contractAmount={ contractAmount }
          sticky={ sticky._props }
          getStickyPayments={ this.getStickyPayments }
        />
        {route === 'history' ? (
          <ErrorMessage message={ '過去履歴の支払情報は変更できません。' } />
        ) : null}
        {newContactExists && route === 'assign' ? (
          <ErrorMessage
            message={
              '連絡先が変更されています。支払情報を入力するためには、一度保存してください。'
            }
          />
        ) : null}
        {!isCollectSticky && (
          <PaymentsArea isCash>
            <h3>{'現金'}</h3>
            {payments.map(payment => {
              const isConfirmed = !!payment.data.isConfirmed
              return (
                // TODO: パフォーマンスに劣る
                <div key={ payment.paymentIndex }>
                  <Payment
                    paymentIndex={ payment.paymentIndex }
                    sticky={ sticky }
                    payment={ payment }
                    route={ route }
                    onChange={ this.onChange }
                    onChangeMeta={ this.onChangeMeta }
                    disabled={ forceDisabled || isCollectSticky }
                    isConfirmed={ isConfirmed }
                  />
                  <Hr />
                </div>
              )
            })}

            {!isCollectSticky && (
              <SelectPayer
                label={ '支払者(現金)' }
                sticky={ sticky }
                route={ route }
                onSelect={ this.addPayment }
                disabled={ isCollectSticky || forceDisabled }
              />
            )}
          </PaymentsArea>
        )}

        <PaymentsArea isAccount>
          <h3>{'売掛金'}</h3>
          {accounts.map((account, index) => {
            let highlight = true
            const isConfirmed = !!account.data.isConfirmed
            return (
              // TODO: パフォーマンスに劣る
              <div key={ index }>
                <Account
                  paymentIndex={ account.paymentIndex }
                  sticky={ sticky }
                  account={ account }
                  route={ route }
                  onChange={ this.onChange }
                  onChangeMeta={ this.onChangeMeta }
                  disabled={
                    isCollectSticky
                      ? forceDisabled || !highlight
                      : forceDisabled
                  }
                  isConfirmed={ isConfirmed }
                  isCollectSticky={ isCollectSticky }
                  highlight={ highlight }
                />
                <Hr />
              </div>
            )
          })}

          {!isCollectSticky && (
            <SelectPayer
              label={ '支払者(売掛金)' }
              sticky={ sticky }
              route={ route }
              onSelect={ this.addAccount }
              disabled={ forceDisabled }
            />
          )}
        </PaymentsArea>
        {isDebugMode && (
          <ObjectDumper
            prop={ { storedPayments, allPayments, payments, accounts } }
            side={ 'left' }
            handleOffsetY={ 50 }
            title={ '支払金額store' }
            defaultOpen={ false }
          />
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsWrap)
