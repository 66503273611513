// react libs
import React from 'react'
import PropTypes from 'prop-types'
import connect from './connect'

// components
import Background from './partials/background'
import InitialLoading from './partials/initial-loading'

// libs
import decodeJWT from 'jsonwebtoken/decode'
import { decode } from 'src/lib/obfuscate'

// constants
import config from 'src/config'
import { USERID_LS_KEY, PASSWORD_LS_KEY } from 'src/reducers/login'

const { VERSION_INFO_KEY, USERID_KEY, ACCESS_TOKEN_KEY } = config.constants.keys

export class LoadingScreen extends React.PureComponent {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    children: PropTypes.any,
    // stateProps
    version: PropTypes.string.isRequired,
    // dispatchProps
    login: PropTypes.func.isRequired,
    setCredentials: PropTypes.func.isRequired,
    recoverLogin: PropTypes.func.isRequired,
    setMaster: PropTypes.func.isRequired,
    setCityCodes: PropTypes.func.isRequired,
    goToHome: PropTypes.func.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    children: null,
  }

  state = { isReady: false, timerId: false }

  /**
   * componentDidMount
   * @return {void}
   */
  componentDidMount() {
    // getReadyを使って、いい感じの秒数を待っている
    Promise.all([
      this.localStorageDeserialize(),
      this.getReady(1000),
    ]).then(() => this.setState({ isReady: true }))
  }

  /**
   * componentWillUnmount
   * @return {void}
   */
  componentWillUnmount() {
    clearTimeout(this.state.timerId)
  }

  localStorageDeserialize() {
    return new Promise(resolve => {
      const {
        // stateProps
        version,
        // dispatchProps
        setCredentials,
        login,
        recoverLogin,
        setMaster,
        setCityCodes,
        goToHome,
      } = this.props

      const previousVersionInfo = localStorage.getItem(VERSION_INFO_KEY)

      // バージョンが違った時の強制ログアウト処理
      if (version !== previousVersionInfo) {
        localStorage.clear()
      }
      localStorage.setItem(VERSION_INFO_KEY, version)

      // クレデンシャルの復元
      const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY)
      const userid = localStorage.getItem(USERID_KEY)
      const decoded = decodeJWT(accessToken)
      const isAlive =
        decoded && decodeJWT(accessToken).exp * 1000 - Date.now() > 0
      // NOTE: アクセストークンのprofileの構造が変わってしまうっていると、のちにエラーになる。
      // APIバージョン情報をチェックできるように回収されることは好ましい。

      // マスタの復元
      const master = config.constants.master.keys.reduce((prev, key) => {
        const LS_MASTER_KEY = `${config.constants.keys.LS_MASTER_PREFIX}-${key}`
        const storedMasterData = JSON.parse(localStorage.getItem(LS_MASTER_KEY))
        prev[key] = storedMasterData ? storedMasterData : []
        return prev
      }, {})

      // cityCode復元
      // cityCodesマスタをローカルストレージから復元
      const cityCodes = master.prefCodes
        .map(pref => pref.code)
        .reduce((prev, code) => {
          const LS_CITY_CODES_KEY = `${config.constants.keys.LS_CITYCODES_PREFIX}-${code}`
          const storedCityCodesData = JSON.parse(
            localStorage.getItem(LS_CITY_CODES_KEY),
          )
          prev[code] = storedCityCodesData ? storedCityCodesData : []
          return prev
        }, {})

      if (accessToken && userid && isAlive) {
        recoverLogin(userid, accessToken)
        setMaster(master)
        setCityCodes(cityCodes)
        return resolve()
      }

      const preservedUserid = decode(localStorage.getItem(USERID_LS_KEY)) || ''
      const preservedPassword =
        decode(localStorage.getItem(PASSWORD_LS_KEY)) || ''

      if (preservedUserid && preservedPassword) {
        setCredentials(preservedUserid, preservedPassword)
        login(preservedUserid, preservedPassword)
        goToHome()
        return resolve()
      }

      // 期限切れか、おかしいトークンが与えられた
      localStorage.removeItem(ACCESS_TOKEN_KEY)
      localStorage.removeItem(USERID_KEY)
      // TODO: このgoToがちゃんと動いているかどうかわからない。
      // routerがマウントされる前に呼ばれているため。
      // routeを変更して検証する。
      goToHome()
      return resolve()
    })
  }

  getReady = milisec =>
    new Promise(resolve =>
      this.setState({
        timerId: setTimeout(
          /* eslint-disable no-console */
          () => console.log('ready!') || resolve(),
          /* eslint-enable no-console */
          milisec,
        ),
      }),
    )

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { isReady } = this.state

    return isReady ? (
      this.props.children
    ) : (
      <Background>
        <InitialLoading />
      </Background>
    )
  }
}

export default connect(LoadingScreen)
