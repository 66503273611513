import React from 'react'
import PropTypes from 'prop-types'
import { toJPYenText } from 'src/lib/format'
import { Dl, Dt, Dd } from 'src/styled/list'
import { PluralTable } from '../../styled'

// {
//   "id": 1,
//   "stockNumber": 54321,
//   "partsName": "配管",
//   "useDate": "2018-10-01",
//   "deliveryDestination": "在庫",
//   "amount": 40000,
//   "adjustment": 200,
//   "paymentDate": "2018-10-01",
//   "accountStaffName": "経理担当名",
//   "updateAt": "2018-10-01T12:00:00.00000Z",
//   "updateUserId": "ope1",
//   "isConfirmed": false,
//   "isSettled": false
// }

const tableSpec = {
  headers: {
    completeDate: '完工日',
    customerName: 'お客様名',
    workSummary: '適用名',
    amount: '金額',
  },
  keyOrders: ['completeDate', 'customerName', 'workSummary', 'amount'],
  transform: {
    amount: toJPYenText,
  },
}

export const LiquidationData = props => {
  const { items } = props
  let sum = 0
  items.forEach(liquidation => {
    liquidation.completeDate = liquidation.liquidation.completeDate
    liquidation.customerName = liquidation.liquidation.customerName
    liquidation.workSummary = liquidation.liquidation.workSummary
    liquidation.amount = liquidation.liquidation.totalCost
    sum += liquidation.liquidation.totalCost
  })

  return [
    <Dl key={ 0 }>
      <Dt>{'外注費'}</Dt>
      <Dd>{toJPYenText(sum)}</Dd>
    </Dl>,
    <Dl key={ 1 }>
      <Dt />

      <Dd strech>
        {items.length > 0 && (
          <PluralTable items={ items } tableSpec={ tableSpec } />
        )}
      </Dd>
    </Dl>,
  ]
}

LiquidationData.propTypes = {
  items: PropTypes.array.isRequired,
}

export default LiquidationData
