/**
 * アイコンを表示するコンポーネント
 * @file {Component}
 */

/**
 * ライブラリ
 */
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

/**
 * 普遍なIDとクラスをマッピングしている
 * @type {object}
 */
const classMaps = {
  buildingTypes: [
    { givenId: 1, className: 'building-type-icon-house' },
    { givenId: 2, className: 'building-type-icon-apartment' },
    { givenId: 3, className: 'building-type-icon-shop' },
    { givenId: 4, className: 'building-type-icon-enterprise' },
    { givenId: 99, className: 'building-type-icon-other' },
  ],
  customerTypes: [
    { givenId: 4, className: 'customer-type-icon-own' },
    { givenId: 5, className: 'customer-type-icon-rental' },
    { givenId: 3, className: 'customer-type-icon-enterprise' },
    { givenId: 10, className: 'customer-type-icon-other' },
  ],
  workPoints: [
    { givenId: 1, className: 'work-place-icon-lavatory' },
    { givenId: 2, className: 'work-place-icon-kitchen' },
    { givenId: 3, className: 'work-place-icon-bathroom' },
    { givenId: 4, className: 'work-place-icon-washroom' },
    { givenId: 6, className: 'work-place-icon-laundry' },
    { givenId: 7, className: 'work-place-icon-exterier' },
    { givenId: 8, className: 'work-place-icon-handrails' },
    { givenId: 5, className: 'work-place-icon-other' },
  ],
  workTypes: [
    { givenId: 1, className: 'work-type-icon-stuck' },
    { givenId: 2, className: 'work-type-icon-nozzleLeaking' },
    { givenId: 3, className: 'work-type-icon-leaking' },
    { givenId: 4, className: 'work-type-icon-other' },
  ],
}

/**
 * StickyIconsなものを表示するコンポーネント
 * @type {ReactComponent}
 */
export default class StickyIcons extends React.Component {
  static propTypes = {
    buildingTypeId: PropTypes.number,
    customerTypeId: PropTypes.number,
    workPointIds: PropTypes.arrayOf(PropTypes.number),
    workTypeIds: PropTypes.arrayOf(PropTypes.number),
  }

  static defaultProps = {
    buildingTypeId: -1,
    customerTypeId: -1,
    workPointIds: [],
    workTypeIds: [],
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return (
      this.props.buildingTypeId !== nextProps.buildingTypeId ||
      this.props.customerTypeId !== nextProps.customerTypeId ||
      this.props.workPointIds !== nextProps.workPointIds ||
      this.props.workTypeIds !== nextProps.workTypeIds
    )
  }

  /**
   * render
   * @return {ReactDOM} rendered result
   */
  render() {
    const {
      buildingTypeId,
      customerTypeId,
      workTypeIds,
      workPointIds,
    } = this.props

    const buildingTypeClassName = classMaps.buildingTypes
      .filter(map => map.givenId === buildingTypeId)
      .map(x => classnames({ [x.className]: true }))[0]

    const customerTypeClassName = classMaps.customerTypes
      .filter(map => map.givenId === customerTypeId)
      .map(x => classnames({ [x.className]: true }))[0]

    const workPointClassNames = classMaps.workPoints
      .filter(map => workPointIds.includes(map.givenId))
      .map(x => classnames({ [x.className]: true }))

    const workTypeClassNames = classMaps.workTypes
      .filter(map => workTypeIds.includes(map.givenId))
      .map(x => classnames({ [x.className]: true }))

    return (
      <ul className={ 'sticky-icons sticky-element sticky-situations' }>
        {workPointClassNames.map(singlularClassName => (
          <li
            key={ singlularClassName }
            className={ `work-point ${singlularClassName}` }
          />
        ))}
        {workTypeClassNames.map(singlularClassName => (
          <li
            key={ singlularClassName }
            className={ `work-type ${singlularClassName}` }
          />
        ))}
        <li className={ buildingTypeClassName + ' ' + customerTypeClassName } />
      </ul>
    )
  }
}
