import styled from 'styled-components'

/**
 * スペーサー付箋の表面の記述内容のコンポーネント
 * @param {object} props props
 */
export const Noop = styled.div`
  color: gray;
`

export default Noop
