import React from 'react'
import styled from 'styled-components'
import { baseColor, white, gray } from 'src/colors'

export const Screen = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 1;
`

export const ContentWrap = styled.div`
  position: relative;
  width: 700px;
  height: 450px;
  background-color: ${white};
  padding: 30px;
  overflow-y: scroll;
`

export const Title = styled.h3`
  font-size: 1.2em;
  text-align: center;
  display: block;
  font-weight: bold;
`

const dropZoneDefaultStyle = {
  width: '600px',
  height: '200px',
  margin: '2em auto',
  border: `5px dashed ${baseColor}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1.2em',
  transitionDuration: '.2s',
  textAlign: 'center',
}

export const dropZoneStyles = {
  default: dropZoneDefaultStyle,
  active: {
    ...dropZoneDefaultStyle,
    backgroundColor: gray,
    color: white,
  },
}

const CloseButtonStyle = styled.button`
  background: transparent;
  border: none;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 30px;
  transition-duration: 0.2s;
  color: ${gray};

  &:hover {
    font-size: 35px;
    cursor: pointer;
  }
`

export const CloseButton = props => (
  <CloseButtonStyle { ...props }>
    <i className={ 'fa fa-times' } />
  </CloseButtonStyle>
)

export const FileListItem = styled.li`
  display: flex;
  flex-direction: row;
`

export const AllFileStatus = styled.p`
  margin: 10px;
  font-weight: bold;
`
