// react
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// libs
import createClassNames from 'classnames'
import Sticky from 'src/lib/class-sticky'

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
const mapStateToProps = state => {
  return {
    isDebugMode: state.env.DEBUG,
  }
}

export const PostModeButtons = props => {
  const {
    // ownProps
    sticky,
    postDisabled,
    deleteDisabled,
    onPostClick,
    onDeleteClick,
    divClassName,
    // stateProps
    isDebugMode,
  } = props

  const postClassNames = createClassNames({
    button: true,
    'button-create': true,
    'button-icon-add': true,
    'button-create-sticky': true,
    'create-sticky-form-wrap_button': true,
    'button-disabled': postDisabled,
  })
  const deleteClassNames = createClassNames({
    button: true,
    'button-icon-delete': true,
    'create-sticky-form-wrap_button': true,
    'button-disabled': deleteDisabled,
  })

  return (
    <div className={ divClassName }>
      <button
        className={ postClassNames }
        disabled={ postDisabled }
        onClick={ onPostClick }
      >
        {'案件を登録'}
      </button>
      <button
        className={ deleteClassNames }
        disabled={ deleteDisabled }
        onClick={ onDeleteClick }
      >
        {'入力内容を削除'}
      </button>
      {isDebugMode && (
        <button
          className={ 'button button-debug' }
          /* eslint-disable react/jsx-no-bind */
          onClick={
            /* eslint-disable no-console */
            () => console.log(sticky.json(), sticky.toJSONString())

            /* eslint-enable no-console */
          }
          /* eslint-enable react/jsx-no-bind */
        >
          {'dump'}
        </button>
      )}
    </div>
  )
}

/**
 * propTypes
 * @type {object}
 */
PostModeButtons.propTypes = {
  // ownProps
  sticky: PropTypes.instanceOf(Sticky).isRequired,
  postDisabled: PropTypes.bool.isRequired,
  deleteDisabled: PropTypes.bool.isRequired,
  onPostClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  divClassName: PropTypes.string.isRequired,
  // stateProps
  isDebugMode: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps)(PostModeButtons)
