import utils from 'src/lib/api-utils'

/**
 * 稼働開始のエンドポイントを叩く
 * @param  {number} staffId    [description]
 * @param  {string} accessToken [description]
 * @param  {object} env         [description]
 * @return {Promise}            [description]
 */

export const WorkType = {
  workStart: 101,
  workEnd: 102,
  workCancel: 103,
}

/**
 * Expoの通知APIで稼働開始・終了の通知を送信する
 * @param {*} workType
 * @param {*} staffId
 * @param {*} stickyId
 * @param {*} stickyContactId
 * @param {*} workDate
 * @param {*} accessToken
 * @param {*} env
 */
export const expoWork = (
  notifyType,
  staffId,
  stickyId,
  stickyContactId,
  workDate,
  accessToken,
  env,
) => {
  const endPoint = utils.createExpoEndpoint(env, 'notify')

  const body = JSON.stringify({
    notifyType,
    staffId,
    stickyId,
    stickyContactId,
    workDate,
  })

  return fetch(endPoint, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body,
  })
}

export default expoWork
