import ListIcon from './'
import { connect } from 'react-redux'
import { ACTION_TYPES as USEPRESET_ACTION_TYPES } from 'src/reducers/user-preset'

/**
 map state to props
 * @param  {object} state    state
 * @param  {object} ownProps own props
 * @return {object}          mapped state props
 */
const mapStateToProps = state => {
  return {
    wara: state.wara.data,
  }
}

/**
 * map dispatch to props
 * @param  {function} dispatch dispatcher
 * @return {object}             props
 */
const mapDispatchToProps = dispatch => {
  return {
    /**
     * SSを表示させる
     * @param  {number} regionId そのregionId
     * @param  {number} ssId     そのssId = staffId
     * @return {void}
     */
    showSS: (regionId, ssId) =>
      dispatch({
        type: USEPRESET_ACTION_TYPES.TOGGLE_STAFF_DISPLAY,
        payload: { regionId, ssId, display: true },
      }),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ListIcon)
