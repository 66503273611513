// action types
import { ACTION_TYPES as STICKY_POST_ACTION_TYPES } from 'src/reducers/wara/async/post-sticky'
import { ACTION_TYPES as NETWORK_ACTION_TYPES } from 'src/reducers/network-status'

// config
import config from 'src/config'

export default {
  /**
   * 付箋作成リクエスト成功時の処理
   */
  [STICKY_POST_ACTION_TYPES.STICKY_POST_SUCCESS]: ({ dispatch }, action) => {
    // NOTE: このdispatch は、コール課画面に重複がある
    // 向こうを削除し、こちらに一本化するのも良いかもしれない
    setTimeout(
      () =>
        dispatch({
          type: NETWORK_ACTION_TYPES.RESET,
          payload: { target: 'postSticky' },
        }),
      config.messageResultDelay,
    )

    if (
      action.meta.type !== config.postStickyRequestTypes.CALL &&
      !Object.values(config.postStickyRequestTypes).includes(action.meta.type)
    ) {
      /* eslint-disable no-console */
      console.warn(`不明なタイプのPOST -> ${action.meta.type.toString()}`)
      /* eslint-enable no-console */
    }
    // コールバックが定義されていたら実行
    if (action.meta.onSuccess && typeof action.meta.onSuccess === 'function') {
      // APIの戻り値（新規に作成した付箋パラメータ）を引数にコールバックを実行
      action.meta.onSuccess(action.payload)
    }
  },

  /**
   * 付箋作成リクエスト失敗時の処理
   */
  [STICKY_POST_ACTION_TYPES.STICKY_POST_FAILURE]: ({ dispatch }) => {
    setTimeout(
      () =>
        dispatch({
          type: NETWORK_ACTION_TYPES.RESET,
          payload: { target: 'postSticky' },
        }),
      config.messageResultDelay,
    )
  },
}
