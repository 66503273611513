import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { FormsWrap, ContentWrap, Header } from '../../../styled'
import { ButtonLink } from '../styled'
import { Dl, Dt, Dd } from 'src/styled/list'
import Description from './partials/description'
import ValidationErrorMessage from 'src/components/commons/validation-error-message'
import Settle from './partials/settle'

// libs
import moment from 'moment'
import getSettlement from 'src/lib/settlement-api/get-settlement'
import { getDocument } from 'src/lib/inventory-api/documentList'
import { makeCancelable, noop } from 'src/lib/cancelable-promise'
import { fluffyPromise as fluffy } from 'src/lib/api-utils'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin: 50px auto 0;
  width: 95%;
`

/**
 * 経理課精算アプリ-詳細表示
 * @type {Object}
 */
export class SettlementDetailApp extends React.PureComponent {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    staffId: PropTypes.number.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    goBack: PropTypes.func.isRequired,
    // stateProps
    staffs: PropTypes.arrayOf(
      PropTypes.shape({
        staffId: PropTypes.number.isRequired,
        staffName: PropTypes.string.isRequired,
      }),
    ).isRequired,
    accessToken: PropTypes.string.isRequired,
    env: PropTypes.object.isRequired,
    refundMethods: PropTypes.array.isRequired,
  }

  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    this.state = {
      status: 'not_yet',
      settlement: void 0,
      documents: void 0,
      onUnmount: noop,
      onUnmount2: noop,
    }
  }

  /**
   * componentDidMount
   * @return {void}
   */
  componentDidMount() {
    this.request()
  }

  /**
   * componentWillUnmount
   * @return {void}
   */
  componentWillUnmount() {
    this.state.onUnmount()
    this.state.onUnmount2()
  }

  request = () => {
    const { staffId, from, to, accessToken, env } = this.props
    const { promise, cancel } = makeCancelable(
      getSettlement({ from, to }, staffId, accessToken, env),
    )
    this.setState({ status: 'requesting', onUnmount: cancel })

    fluffy(promise)
      .then(res => {
        if (res.ok) {
          return res.json()
        } else {
          throw new Error(res)
        }
      })
      .then(settlement => {
        this.setState({ settlement, status: 'success' })
        this.requestDocuments()
      })
      .catch(error => {
        if (!error.isCanceled) {
          console.error(error)
          this.setState({ status: 'failure', settlement: void 0 })
        }
      })
  }

  /**
   * iPadでの提出・未提出書類の一覧を取得する。
   */
  requestDocuments = () => {
    const { staffId, from, to, accessToken, env } = this.props
    const { promise, cancel } = makeCancelable(
      getDocument(from, to, staffId, accessToken, env),
    )
    this.setState({
      status: 'requesting',
      onUnmount2: cancel,
    })
    fluffy(promise)
      .then(res => {
        if (res.ok) {
          return res.json()
        } else {
          throw new Error(res)
        }
      })
      .then(data => {
        const documents = data.data.getDocument
        this.setState({ documents, status: 'success' })
      })
      .catch(error => {
        if (!error.isCanceled) {
          console.error(error)
          this.setState({ status: 'failure', settlement: void 0 })
        }
      })
  }

  /**
   * 「確定」の状態をStateに反映する。
   */
  setCheckedStatus = value => {
    const { settlement } = this.state
    this.setState({
      settlement: { ...settlement, isSettled: value },
    })
  }
  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { from, to, staffId, staffs, goBack, refundMethods } = this.props
    const { settlement, documents, status } = this.state

    const duration =
      moment(from).format('YYYY-MM-DD') +
      ' - ' +
      moment(to)
        .add(-1, 'day')
        .format('YYYY-MM-DD')

    const staff = staffs.find(x => x.staffId === staffId) || {}

    return (
      <Container>
        <Header title={ '精算処理 |  詳細' } />

        <FormsWrap alignLeft>
          <ButtonLink onClick={ goBack }>{'精算処理一覧'}</ButtonLink>
        </FormsWrap>

        <ContentWrap>
          <Dl>
            <Dt>{'精算期間'}</Dt>
            <Dd>{duration}</Dd>
          </Dl>
          <Dl>
            <Dt>{'担当者名'}</Dt>
            <Dd>{staff.staffVerboseName}</Dd>
            <Dd>
              {settlement && (
                <Settle
                  settlement={ settlement }
                  query={ { from, to, staffId } }
                  setCheckedStatus={ this.setCheckedStatus }
                />
              )}
            </Dd>
          </Dl>
          {status === 'failure' ? (
            <ValidationErrorMessage message={ 'サーバーエラー' } />
          ) : status === 'success' ? (
            <Description
              settlement={ settlement }
              uploadDocuments={ documents || [] }
              query={ { from, to, staffId } }
              isSettled={ settlement.isSettled }
              accessToken={ this.props.accessToken }
              env={ this.props.env }
              refundMethods={ refundMethods }
            />
          ) : null}
        </ContentWrap>
      </Container>
    )
  }
}

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
const mapStateToProps = state => {
  return {
    staffs: state.master.data.staffs,
    accessToken: state.login.authentication.accessToken,
    env: state.env,
    refundMethods: state.master.data.refundMethods,
  }
}

export default connect(mapStateToProps)(SettlementDetailApp)
