// react related
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material'
import theme from 'src/theme.js'
import { ThemeProvider } from '@mui/material/styles'

// libs
// import config from 'src/config'

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
const mapStateToProps = state => ({
  creditCards: state.master.data.creditCards,
})

export const PaymentType = ({
  value,
  route,
  update,
  disabled,
  creditCards,
}) => {
  /**
   * クレジットカードIDを変更した時にそのIDをステージング値に反映する
   * @param  {Event} e イベントハンドラです
   * @return {void}
   */
  const onChange = e => {
    const val = parseInt(e.target.value, 10)
    const creditCardId = isNaN(val) ? -1 : val
    update(creditCardId)
  }

  const identifier = useMemo(() => `payment-select-how-to-retrieve-${route}`, [
    route,
  ])

  const displayCreditCards = [{ id: -1, name: '現金' }, ...creditCards]

  return (
    <ThemeProvider theme={ theme }>
      <Box
        sx={ {
          display: 'grid',
          gridTemplateColumns: 'repeat(2, max-content)',
          columnGap: '1.5rem',
          alignItems: 'center',
        } }
      >
        <Typography
          htmlFor={ `payment-select-credit-card-id-${route}` }
          variant="subtitle1"
          sx={ { fontWeight: 'bold' } }
        >
          {'支払方法'}
        </Typography>
        <FormControl
          color="primary"
          fullWidth
          disabled={ disabled || route !== 'assign' }
          size="small"
          sx={ {
            background: '#fff',
            '.MuiOutlinedInput-root': {
              height: '28px',
              alignItems: 'center',
              '.MuiSelect-select': {
                padding: '0px 14px',
              },
            },
          } }
        >
          <Select
            displayEmpty
            value={ value }
            id={ `payment-select-credit-card-id-${route}` }
            MenuProps={ {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              PaperProps: { style: { maxHeight: '50vh' } },
              style: { zIndex: 10000 }, // TODO: 付箋詳細や詳細検索のモーダルで表示するにはこの数値が必要
            } }
            name={ `payment-select-credit-card-id-${route}` }
            onChange={ onChange }
          >
            {displayCreditCards.map(option => (
              <MenuItem key={ option.id } value={ option.id }>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </ThemeProvider>
  )
}

/**
 * propTypes
 * @type {object}
 */
PaymentType.propTypes = {
  // ownProps
  value: PropTypes.number.isRequired,
  route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
  update: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  // stateProps
  creditCards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      isOneTime: PropTypes.bool.isRequired,
    }),
  ).isRequired,
}

export default connect(mapStateToProps)(React.memo(PaymentType))
