import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { baseColor } from 'src/colors'
import { darken } from 'polished'

const LinkTo = styled(Link)`
  background: ${baseColor};
  color: #fff;
  padding: 10px 15px;
  margin: 10px;
  border: 0;
  border-radius: 3px;
  line-height: 2em;
  text-decoration: none;

  &:hover {
    background: ${darken(0.1, baseColor)};
  }
`

/**
 * 検索画面に移動するためのボタン
 * @param {*} props
 */
export const SearchButton = props => {
  const { linkTo, onClick } = props

  return (
    <LinkTo to={ linkTo } onClick={ onClick }>
      <i className={ 'fa fa-search' } />
      {'検索画面'}
    </LinkTo>
  )
}

/**
 * propTypes
 * @type {object}
 */
SearchButton.propTypes = {
  linkTo: PropTypes.string.isRequired, // 検索画面のURL
  onClick: PropTypes.func.isRequired, // クリックしたときの処理（モーダルを閉じるなど）
}

export default SearchButton
