import React from 'react'
import PropTypes from 'prop-types'
import { toJPYenText } from 'src/lib/format'
import { Dl, Dt, Dd } from 'src/styled/list'
import { PluralTable } from '../../styled'

// {
//   "stickyId": 1,
//   "orderId": "W01-00000001",
//   "regionId": 1,
//   "stickyContactId": 123,
//   "name": "顧客名",
//   "phoneNumber": "09011111111",
//   "address": "住所",
//   "accountHistoryId": 2,
//   "cancelAccountHistoryId": null,
//   "reportDate": "2018-10-01",
//   "recordDate": "2018-10-31",
//   "collectionValue": 3000,
//   "remainValue": 15000,
//   "collectedMethodId": 1,
//   "NextCollectionMethodId": 2,
//   "NextCollectionPlanDate": "2018-10-15"
//   "memo": "集金",
//   "complete": false,
//   "isSettled": false
// }

const tableSpec = {
  headers: {
    recordDate: '計上日',
    reportDate: '回収日',
    name: 'お客様名',
    phoneNumber: '電話番号',
    collectionValue: '回収金額',
    remainValue: '回収残',
    memo: 'メモ',
  },
  keyOrders: [
    'recordDate',
    'reportDate',
    'name',
    'phoneNumber',
    'collectionValue',
    'remainValue',
    'memo',
  ],
  transform: {
    collectionValue: toJPYenText,
    remainValue: toJPYenText,
  },
}

export const AccountHistories = props => {
  const { items } = props
  const sum = items.reduce(
    (prev, item) => prev + (item.collectionValue || 0),
    0,
  )

  return [
    <Dl key={ 0 }>
      <Dt>{'売掛金履歴'}</Dt>
      <Dd>{toJPYenText(sum)}</Dd>
    </Dl>,
    <Dl key={ 1 }>
      <Dt />

      <Dd strech>
        {items.length > 0 && (
          <PluralTable
            items={ items }
            tableSpec={ tableSpec }
            keyName={ 'paymentId' }
          />
        )}
      </Dd>
    </Dl>,
  ]
}

AccountHistories.propTypes = {
  items: PropTypes.array.isRequired,
}

export default AccountHistories
