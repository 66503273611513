import update from 'immutability-helper'

/**
 * タイプ定義
 * @type {string}
 */
const type = 'WARA.UPDATE_STAFF_FREE_DESCRIBE'

/**
 * exportation
 */
export default {
  /**
   * action type
   * @type {string}
   */
  type,

  /**
   * action creator
   * @param {{ regionIndex: number, listIndex: numvber }} location 更新するssが所属するregionとlistのIndexです
   * @param  {string}  freeDescribe     更新するサービススタッフのパラメータです
   * @return {object}              action
   */
  creator: (location, freeDescribe) => {
    return {
      type,
      payload: { location, freeDescribe },
    }
  },

  /**
   * [handler description]
   * @param  {object}  state   [description]
   * @param  {{payload:{location:object, ssProps:object}}} action  [description]
   * @return {object}          [description]
   */
  handler: (state, { payload: { location, freeDescribe } }) => {
    const { regionIndex, listIndex } = location

    return update(state, {
      data: {
        regions: {
          [regionIndex]: {
            lists: {
              [listIndex]: {
                freeDescribe: { $set: freeDescribe },
              },
            },
          },
        },
      },
    })
  },
}
