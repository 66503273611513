import update from 'immutability-helper'

/**
 * タイプ定義
 * @type {string}
 */
const type = 'WARA.INSERT_LIST'

/**
 * exportation
 */
export default {
  /**
   * action type
   * @type {string}
   */
  type,

  /**
   * action creator
   * @param  {number} regionIndex index of regions
   * @param  {number} listIndexSrc 挿入しようとしているリストのインデックス
   * @param  {number} listIndexDest 挿入されようとしているリストのインデックス
   * @return {object} action
   */
  creator: (regionIndex, listIndexSrc, listIndexDest) => {
    return {
      type,
      payload: {
        regionIndex,
        listIndexSrc,
        listIndexDest,
      },
    }
  },

  /**
   * 関数本体
   * @param  {object} state  Immutableなstateです。このオブジェクトを変更すべきではありません。
   * @param  {object} action 関数に与える引数です。
   * @return {object}        適切にUpdateされた新しいStateを返却します。
   */
  handler: (
    state,
    { payload: { regionIndex, listIndexSrc, listIndexDest } },
  ) => {
    if (
      regionIndex === -1 ||
      listIndexSrc === -1 ||
      listIndexDest === -1 ||
      listIndexSrc === listIndexDest
    ) {
      // 交換不要なケース
      return state
    }
    const lists = state.data.regions[regionIndex].lists

    // spliceするやつ
    const updator =
      listIndexSrc < listIndexDest
        ? [
          [listIndexDest, 1, lists[listIndexDest], lists[listIndexSrc]],
          [listIndexSrc, 1],
        ]
        : [
          [listIndexDest, 1, lists[listIndexSrc], lists[listIndexDest]],
          [listIndexSrc + 1, 1],
        ]

    return update(state, {
      data: {
        regions: {
          [regionIndex]: {
            lists: {
              $splice: updator,
            },
          },
        },
      },
    })
  },
}
