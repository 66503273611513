import utils from 'src/lib/api-utils'

export const post = (
  { staffId, documentType, actualStartNumber, startDate },
  accessToken,
  env,
) => {
  const endpoint = `${utils.createEndpoint(env, 'documentBundle')}`

  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    /* body: qsjoin({
      stickyId,
      category: slug,
      image: `${base64Image}`,
    }), */
    body: JSON.stringify({
      staffId,
      documentType,
      actualStartNumber,
      startDate,
    }),
  }

  const request = fetch(endpoint, options)

  return request
}

export default post
