import React from 'react'
import PropTypes from 'prop-types'

// components
import ValidationErrorMessage from 'src/components/commons/validation-error-message'

export const InputRecord = ({
  value,
  onChange,
  onClick,
  route,
  whoRecord,
  disabled,
}) => {
  const groupName = () => {
    return `input-negotiation-record-input-free-describe-${route}`
  }
  const buttonDisabled = disabled || !value

  const identifier = 'id-' + groupName

  return (
    <div>
      <dl
        className={ 'label-inputs-wrap input-negotiation-record' }
        id={ `input-negotiation-record-new-${route}` }
      >
        <p
          htmlFor={ identifier }
          className={ 'input-negotiation-record-name' }
        >{`記録者: ${whoRecord}`}</p>
        <textarea
          value={ value }
          id={ identifier }
          name={ groupName }
          onChange={ onChange }
          className={ 'long' }
          disabled={ disabled }
        />
        <button
          className={
            'button button-icon-add button-register-negotiation-record'
          }
          onClick={ onClick }
          disabled={ buttonDisabled }
        >
          {'登録'}
        </button>
      </dl>
      {!!value && (
        <ValidationErrorMessage
          message={ '交渉履歴が登録されていません。登録ボタンを押して下さい。' }
        />
      )}
    </div>
  )
}

/**
 * propTypes
 * @type {object}
 */
InputRecord.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
  whoRecord: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

/**
 * defaultProps
 * @type {object}
 */
InputRecord.defaultProps = {
  disabled: false,
}

export default React.memo(InputRecord)
