import UPDATE_SS_ACTION from 'src/reducers/wara/actions/update-daily-info'
import { createAsyncPutStaffAction } from 'src/reducers/wara/async'
import { findListFromWara } from 'src/lib/search'

/**
 * SSパラメーター更新 -> シリアライズのチェーン
 * @param  {object} store  redux store
 * @param  {object} action redux action
 * @return {void}
 */
export const preServiceStffHook = (store, action) => {
  // serialize = falseが与えられた時はシリアライズしない
  const { serialize } = action.meta || { serialize: true }
  if (!serialize) {
    return
  }

  const state = store.getState()
  const { location, ssProps } = action.payload

  const list = findListFromWara(state.wara.data, location)

  const { staffId, dailyInfo } = list
  const dailyInfoId = (dailyInfo || {}).id

  const { accessToken } = state.login.authentication
  const { env } = state

  store.dispatch(
    createAsyncPutStaffAction(
      ssProps,
      dailyInfoId,
      staffId,
      accessToken,
      location,
      env,
    ),
  )
}

export default {
  [UPDATE_SS_ACTION.type]: preServiceStffHook,
}
