export const convertAfterGet = allPayments =>
  allPayments
    .map(payment => {
      const {
        stickyContactId: id,
        payments: payments = [],
        accounts: accounts = [],
      } = payment

      return [
        ...payments.map(data => ({
          meta: { id },
          paymentType: 'payments',
          data,
        })),
        ...accounts.map(data => ({
          meta: { id },
          paymentType: 'accounts',
          data,
        })),
      ]
    })
    .reduce((prev, paymentsPair) => [...prev, ...paymentsPair], [])

export default convertAfterGet
