import React from 'react'
import PropTypes from 'prop-types'
import connect from '../../../connect'
import Sticky from 'src/lib/class-sticky'
import { changeContactArea } from 'src/lib/format.js'
import ContactOrder from './contact-order'

/**
 * 連絡先の種類
 */
const contactTypes = [
  {
    id: 0,
    name: '居住者',
  },
  {
    id: 1,
    name: 'オーナー',
  },
  {
    id: 2,
    name: '第三者',
  },
  {
    id: 3,
    name: '管理会社',
  },
  {
    id: 4,
    name: '親族',
  },
]

/**
 * 連絡先の種別を選択
 * @type {ReactComponent}
 */
export class ContactType extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    tabIndex: PropTypes.number.isRequired,
    contactsIndex: PropTypes.number.isRequired,
    disabled: PropTypes.bool.isRequired,
    // stateProps
    master: PropTypes.object.isRequired,
    // dispatchProps
    updateStage: PropTypes.func.isRequired,
  }

  /**
   * onChange handler
   * @param  {Event} e event
   * @return {void}
   */
  onChange = e => {
    // contacts[tabIndex][contactsIndex]を contacts[e.value]の最後に追加する。
    // contacts[tabIndex][contactsIndex]は
    //   id = nullの時：-1 →　差分から削除
    //   id != nullの時: {id, moved: true} →　差分には「移動済」として更新する。
    // contacts[e.value][]には元の連絡先をそのまま移動する。
    // 付箋の表示：Contacts で1次元配列に変換する際、deletedとmovedの連絡先は表示しない。
    // 付箋の更新：移動した連絡先は移動された{id, 残り}で更新される。movedのデータは送らない。
    const contactType = parseInt(e.target.value)
    const { sticky, tabIndex, contactsIndex, updateStage, master } = this.props
    const contacts = sticky.json().contacts || []
    const tabContacts = contacts[tabIndex] || []
    const newContacts = contacts[contactType] || []
    // 移動する連絡先：元の連絡先のid === nullの時は-1, !== null の時 {id: 元のID, moved: true}にする。
    const target = tabContacts[contactsIndex]
    const deleted = target.id === null ? -1 : { id: target.id, moved: true }
    const contact = tabContacts.splice(contactsIndex, 1, deleted)[0]
    // 新しい分類に追加
    newContacts.push(contact)
    const diffProps = {
      contacts: {
        [tabIndex]: tabContacts,
        [contactType]: newContacts,
      },
      contactNoMerge: { fr: tabIndex, to: contactType },
    }
    changeContactArea(sticky, master, diffProps)
    updateStage(new Sticky(diffProps))
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const {
      // ownProps
      sticky,
      tabIndex,
      contactsIndex,
      disabled,
      update,
    } = this.props

    const identifier = `contact-type-${tabIndex}-${contactsIndex}`
    const contactType = tabIndex

    return (
      <dl className={ 'label-inputs-wrap' }>
        <dt>{'種別'}</dt>
        <dd className={ 'input-wrap' }>
          <div style={ { display: 'flex' } }>
            <ContactOrder
              { ...{
                sticky,
                tabIndex,
                contactsIndex,
                disabled,
                update,
              } }
            />
            {/* 順番変更 */}

            <select
              value={ contactType }
              id={ identifier }
              name={ identifier }
              onChange={ this.onChange }
              disabled={ disabled }
              style={ { maxWidth: '11.25rem' } }
            >
              {contactTypes.map(contact => (
                <option key={ contact.id } value={ contact.id }>
                  {contact.name}
                </option>
              ))}
            </select>
          </div>
        </dd>
      </dl>
    )
  }
}

export default connect(ContactType)
