import React from 'react'
import PropTypes from 'prop-types'
import SettlementTable from './settlement-table'

export const Index = props => {
  if (props.staffIds.length > 0) {
    return (
      <SettlementTable
        staffIds={ props.staffIds }
        from={ props.from }
        to={ props.to }
        disabled={ props.disabled }
        onConfirm={ props.onConfirm }
        setAllSettlement={ props.setAllSettlement }
        resetAllSettlement={ props.resetAllSettlement }
      />
    )
  } else if (!props.disabled) {
    return <span>{'精算対象のスタッフは見つかりませんでした。'}</span>
  } else {
    return null
  }
}

Index.propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  staffIds: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  setAllSettlement: PropTypes.func.isRequired,
  resetAllSettlement: PropTypes.func.isRequired,
}

export default Index
