import { maxFileSize } from 'src/config'

export const readFile = (readerType, encode) => {
  return file => {
    if (file.size > maxFileSize.size) {
      return {
        name: file.name,
        errorMessage: `ファイルサイズが大きすぎます。${maxFileSize.name} 以下にしてください。`,
      }
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        resolve({ data: reader.result, name: file.name }),
      )
      reader.addEventListener('error', reject)

      if (readerType === 'text') {
        reader.readAsText(file, encode)
      } else if (readerType === 'base64') {
        reader.readAsDataURL(file)
      } else if (readerType === 'binary') {
        reader.readAsBinaryString(file)
      } else {
        reject({
          errorMessage: '無効なreaderType',
          name: readerType,
        })
      }
    }).catch(() => ({
      errorMessage: 'ファイル読み込みエラー',
      name: file.name,
    }))
  }
}

export const calcHash = async text => {
  const [, filePart] = text.split(',')

  const uint8 = new TextEncoder().encode(filePart)
  const digest = await crypto.subtle.digest('SHA-256', uint8)
  return Array.from(new Uint8Array(digest))
    .map(v => v.toString(16).padStart(2, '0'))
    .join('')
}
