// アクセストークンや環境情報をカリー化しておくことで、複雑なリクエストの連鎖のコードを少しでもみやすくする
import requestPutSticky from 'src/lib/sticky-api/put'
import requestPostSticky from 'src/lib/sticky-api/post'
import requestPutPayment from 'src/lib/payments-api/put'
import postOrPutCollectSticky from 'src/lib/payments-api/collect-sticky/post-or-put'
import requestPutComplaint from 'src/lib/maruc-api/put'

export default ({ accessToken, env }) => ({
  putSticky: (...arg) => requestPutSticky(...arg, accessToken, env),
  postSticky: (...arg) => requestPostSticky(...arg, accessToken, env),
  putPayment: (...arg) => requestPutPayment(...arg, accessToken, env),
  postOrPutCollectSticky: (...arg) =>
    postOrPutCollectSticky(...arg, accessToken, env),
  putComplaint: (...arg) => requestPutComplaint(...arg, accessToken, env),
})
