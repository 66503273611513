import update from 'immutability-helper'

/**
 * タイプ定義
 * @type {string}
 */
const type = 'WARA.DELETE_STICKY_BY_ID'

/**
 * exportation
 */
export default {
  /**
   * action type
   * @type {string}
   */
  type,

  /**
   * action creator
   * @param  {number} stickyId stickyId
   * @return {object} Action
   */
  creator: stickyId => {
    return {
      type,
      payload: { stickyId },
    }
  },

  /**
   * action handler
   * @param  {object}  state   Immutableなstateです。このオブジェクトを変更すべきではありません。
   * @param  {object} action   関数に与える引数です。
   * @return {object}          適切にUpdateされた新しいStateを返却します。
   */
  handler: (state, action) => {
    const payload = action ? action.payload : {}
    const deletingStickyId = payload ? payload.stickyId : -1
    const dockStickies = (state.data.dock && state.data.dock.stickies) || []

    for (const stickyIndex in dockStickies) {
      const sticky = state.data.dock.stickies[stickyIndex]
      if (sticky.id === deletingStickyId) {
        return update(state, {
          data: {
            dock: {
              stickies: { $splice: [[stickyIndex, 1]] },
            },
          },
        })
      }
    }

    for (const regionIndex in state.data.regions) {
      const region = state.data.regions[regionIndex]
      for (const listIndex in region.lists) {
        const list = region.lists[listIndex]
        for (const stickyIndex in list.stickies) {
          const sticky = list.stickies[stickyIndex]
          if (sticky.id === deletingStickyId) {
            return update(state, {
              data: {
                regions: {
                  [regionIndex]: {
                    lists: {
                      [listIndex]: {
                        stickies: { $splice: [[stickyIndex, 1]] },
                      },
                    },
                  },
                },
              },
            })
          }
        }
      }
    }
    return state
  },
}
