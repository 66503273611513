import React from 'react'
import PropTypes from 'prop-types'
import { toJPYenText } from 'src/lib/format'
import { Dl, Dt, Dd } from 'src/styled/list'
import { PluralTable } from '../../styled'

// const items = [
//   {
//     id: 1,
//     stockNumber: 54321,
//     partsName: '配管',
//     useDate: '2018-10-01',
//     deliveryDestination: '在庫',
//     amount: 40000,
//     adjustment: 200,
//     paymentDate: '2018-10-01',
//     accountStaffName: '経理担当名',
//     updateAt: '2018-10-01T12:00:00.00000Z',
//     updateUserId: 'ope1',
//     isConfirmed: false,
//     isSettled: false,
//   },
// ]

const tableSpec = {
  headers: {
    useDate: '使用日',
    deliveryDestination: '使用先',
    amount: '金額',
    partsName: '材料名',
    isConfirmed: '確定済み',
    isSettled: '精算済み',
  },
  keyOrders: [
    'useDate',
    'deliveryDestination',
    'amount',
    'partsName',
    'isConfirmed',
    'isSettled',
  ],
  transform: {
    amount: toJPYenText,
    adjustment: toJPYenText,
    isConfirmed: isConfirmed => (isConfirmed ? '✅' : '-'),
    isSettled: isSettled => (isSettled ? '✅' : '-'),
  },
}

export const SmileData = props => {
  const { items } = props
  const sum = items.reduce((prev, item) => prev + (item.amount || 0), 0)

  return [
    <Dl key={ 0 }>
      <Dt>{'経理データ'}</Dt>
      <Dd>{toJPYenText(sum)}</Dd>
    </Dl>,
    <Dl key={ 1 }>
      <Dt />

      <Dd strech>
        {items.length > 0 && (
          <PluralTable items={ items } tableSpec={ tableSpec } />
        )}
      </Dd>
    </Dl>,
  ]
}

SmileData.propTypes = {
  items: PropTypes.array.isRequired,
}

export default SmileData
