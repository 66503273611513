import config from 'src/config'

/**
 * validなIDかどうかを確かめる関数
 * @param  {any}  id idと思しきもの
 * @return {boolean} is valid
 */
export const isValidId = id => {
  return !isNaN(id, 10) && id !== null && id !== -1
}

/**
 * 付箋ポスト時の前処理
 * @param  {Sticky}  sticky                投稿しようとしているStickyクラスのインスタンス
 * @param  {symbol}  postStickyRequestType 投稿する付箋のタイプ
 * @param  {boolean} isAfterCreation       アフター作成時のpostリクエストかどうか
 * @return {Sticky}                        投稿する付箋クラスインスタンス
 */
export const transformBeforePost = (
  sticky,
  postStickyRequestType,
  isAfterCreation, // NOTE: アフタ周りは、buttons.jsx にロジックが分散している
) => {
  // POST時、SSに-1が指定されている時は削除する。
  if (sticky._props.staffId === -1) {
    delete sticky._props.staffId
  }
  return sticky
    .update({
      // 新規付箋作成で、初番はサーバー側で行うので、IDは送らない
      id: void 0,

      // 必ず付箋タイプを埋め込む
      stickyTypeId: x => (isValidId(x) ? x : config.stickyType.normal),

      // 必ず注文タイプを埋め込む
      orderTypeId: x => (isValidId(x) ? x : config.orderType.new),

      // 必ず受電媒体Idを埋め込む
      mediumId: x => (isValidId(x) ? x : config.media.other),

      isAfter: isAfterCreation,

      determinedDateTime: x =>
        isAfterCreation
          ? {
            ...x,
            answerTimeId: void 0,
          }
          : x,

      wishDateTimes: (x = []) =>
        x.map(wishDateTime => ({
          ...wishDateTime,
          id: void 0,
          stickyId: void 0,
        })),

      // idがない交渉履歴は、検索によって得られたもの。
      // これは、新しい案件には引き継がない
      negotiationRecords: x =>
        postStickyRequestType === config.postStickyRequestTypes.CALL &&
        Array.isArray(x)
          ? x.filter(y => !!y.id)
          : [],

      // DeterminedDateTimeをシリアライズするときは、displayDate に反映する
      displayDate: (x, self) =>
        self.determinedDateTime &&
        self.determinedDateTime.date &&
        !isAfterCreation
          ? self.determinedDateTime.date
          : x,

      thankYouCall: (x = {}) => ({
        ...x,
        done: x.thankYouCall ? !!x.thankYouCall.done : false,
      }),
    })
    .update(
      {
        // 電話番号登録情報を綺麗にする
        contacts: (allContacts = []) =>
          allContacts.map(tabContacts =>
            (tabContacts || [])
              .map(contact => {
                if (contact.deleted || contact.moved) {
                  return false
                } else {
                  return {
                    ...contact,
                    id: void 0,
                    stickyId: void 0,
                    // https://ai-ot.slack.com/archives/G3QQLKELR/p1504485761000050
                    phoneNumber: contact.phoneNumber || '',
                    name: contact.name || '',
                  }
                }
              })
              .filter(x => !!x),
          ),
      },
      { shallow: true },
    )

  // TODO: これなんだっけ？実装されてる？
  // if (
  //   sendingSticky.stickyTypeId !== config.stickyType.normal &&
  //   !findClient(sendingSticky.contacts)
  // ) {
  //   sendingSticky.contacts = [
  //     [{ isClient: true, name: '', phoneNumber: '', canonicalPhoneNumber: '' }],
  //   ]
  // }
  //
  // if (sendingSticky.stickyTypeId !== config.stickyType.normal) {
  //   sendingSticky.wishDateTimes = [{ date: '1900-01-01', displayOrder: 0 }]
  // }
}

export default transformBeforePost
