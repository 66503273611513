import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import StickyContent from './sticky-content'

import Sticky from 'src/lib/class-sticky'

export const StickyBoxWrap = styled.div`
  border-radius: 4px;
  border: 1px solid #9e9e9e;
  margin: 1em;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`

export const StickiesWrap = styled.div`
  display: block;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
`

export const StickyBoxs = props => (
  <StickiesWrap disabled={ props.disabled }>
    {props.data.map(({ sticky, conditionIds }) => (
      <StickyBoxWrap key={ sticky.id }>
        <StickyContent
          sticky={ sticky }
          conditionIds={ conditionIds }
          openModal={ props.openModal }
        />
      </StickyBoxWrap>
    ))}
  </StickiesWrap>
)

StickyBoxs.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      sticky: PropTypes.instanceOf(Sticky).isRequired,
      conditionId: PropTypes.number.isRequired,
      conditionIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    }),
  ).isRequired,
  disabled: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
}

export default StickyBoxs
