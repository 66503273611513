import React from 'react'
import PropTypes from 'prop-types'

/**
 * 値を表示するコンポーネント
 * @type {ReactComponent}
 */
const ItemValue = props => {
  const ddStyle = {
    flexGrow: 0,
    marginRight: 0,
  }

  return (
    <div style={ { display: 'flex', flexDirection: 'row', marginBottom: 4 } }>
      <div style={ { width: '11rem' } }>
        <label style={ { fontWeight: 'bold' } }>{props.label}</label>
      </div>
      <dd className={ 'input-wrap' } style={ ddStyle }>
        {props.value}
      </dd>
    </div>
  )
}
/**
 * propTypes
 * @type {object}
 */
ItemValue.propTypes = {
  label: PropTypes.string.isRequired, // 見出し
  value: PropTypes.string.isRequired, // 表示する値
}

export default ItemValue
