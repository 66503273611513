import styled from 'styled-components'
import PropTypes from 'prop-types'

export const FlexContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-left: 10px;
  width: 100%;
`

export const BubbleContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-right: 30px;
  margin-top: 15px;
`
export const Bubble = styled.span`
  &::before {
    content: '';
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 15px;
    background-color: ${props => props.color};
    border: 1px solid ${props => props.borderColor};
  }
`

Bubble.propTypes = {
  color: PropTypes.string.isRequired,
  borderColor: PropTypes.string,
}

Bubble.defaultProps = {
  borderColor: 'transparent',
}

export const BubblesWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: -10px;
  margin-bottom: 10px;
  font-size: 0.9em;
`

export const SmallBubbleContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-right: 10px;
  margin-top: 0;
`

export const SmallBubble = styled.span`
  &::before {
    content: '';
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 5px;
    border-radius: 15px;
    background-color: ${props => props.color};
    border: 1px solid ${props => props.borderColor};
  }
`
