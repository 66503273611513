import React from 'react'
import styled, { keyframes } from 'styled-components'
import { baseColor } from 'src/colors'

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const cubeMove = keyframes`
  25% { transform: translateX(42px) rotate(-90deg) scale(0.5); }
  50% { transform: translateX(42px) translateY(42px) rotate(-179deg); }
  50.1% { transform: translateX(42px) translateY(42px) rotate(-180deg); }
  75% { transform: translateX(0) translateY(42px) rotate(-270deg) scale(0.5); }
  100% { transform: rotate(-360deg); }
`

const Spinner = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  margin: 100px auto;
  animation: ${fadeIn} 1s ease 0s 1 normal;
`

const Cube = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  border-radius: 2px;
  background-color: ${baseColor};
  animation: ${cubeMove} 1.8s infinite ease-in-out;
  animation-delay: ${props => (props.delay ? '-0.9s' : 0)};
`

export const InitialLoading = () => (
  <Spinner>
    <Cube />
    <Cube delay />
  </Spinner>
)

export default InitialLoading
