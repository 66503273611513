import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material'
import theme from 'src/theme.js'
import { ThemeProvider } from '@mui/material/styles'

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
const mapStateToProps = state => ({
  receivableCollection: state.master.data.receivableCollection,
})

export class AccountCollectionMethod extends React.PureComponent {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    value: PropTypes.number.isRequired,
    route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
    update: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    // stateProps
    receivableCollection: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }

  /**
   * 回収方法を変更した時にそのIDをステージング値に反映する
   * @param  {Event} e イベントハンドラです
   * @return {void}
   */
  onChange = e => {
    const val = parseInt(e.target.value, 10)
    const howToRetrieveId = isNaN(val) ? -1 : val
    this.props.update(howToRetrieveId)
  }

  get identifier() {
    return `account-select-how-to-retrieve-${this.props.route}`
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { value, route, receivableCollection, label } = this.props

    const disabled = this.props.disabled || route !== 'assign'
    // マスタ
    const displayRecievableCollection = [
      { id: -1, name: '未定' },
      ...(receivableCollection || []),
    ]

    return (
      <ThemeProvider theme={ theme }>
        <Box
          sx={ {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, max-content)',
            alignItems: 'center',
            columnGap: '0.5rem',
          } }
        >
          <Typography
            htmlFor={ this.identifier }
            variant="subtitle1"
            sx={ { fontWeight: 'bold' } }
          >
            {label}
          </Typography>
          <FormControl
            color="primary"
            fullWidth
            size="small"
            sx={ {
              background: '#fff',
              '.MuiOutlinedInput-root': {
                height: '28px',
                alignItems: 'center',
                '.MuiSelect-select': {
                  padding: '0px 14px',
                },
              },
            } }
          >
            <Select
              value={ value }
              id={ this.identifier }
              name={ this.identifier }
              onChange={ this.onChange }
              disabled={ disabled }
              MenuProps={ { style: { zIndex: 10000 } } } // TODO: 付箋詳細や詳細検索のモーダルで表示するにはこの数値が必要
            >
              {displayRecievableCollection.map(option => (
                <MenuItem key={ option.id } value={ option.id }>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </ThemeProvider>
    )
  }
}

export default connect(mapStateToProps)(AccountCollectionMethod)
