import { today } from 'src/lib/moment'
import config from 'src/config'

export default {
  orderTypeId: config.orderType.new,
  companyId: config.company.ES,
  personalTypeCode: config.personalTypeCode.M1,
  mediumId: config.media.other,
  contacts: [
    [
      {
        isClient: true,
        name: 'AIoT',
        phoneNumber: '000-0000',
        canonicalPhoneNumber: '0000000',
        zip: '100-0001',
        prefCode: '130001 ',
        cityCode: '131016 ',
        address: 'テスト',
      },
    ],
  ],
  buildingType: {
    ids: [config.buildingType.other],
    freeDescribe: 'テストデータ',
  },
  customerTypeId: config.customerType.own,
  workPoint: {
    ids: [config.workPoint.other],
  },
  workType: {
    ids: [config.workType.other],
  },
  wishDateTimes: [{ date: today(), displayOrder: 0 }],
  regionId: 1,
}
