import React from 'react'
import PropTypes from 'prop-types'
import connect from './connect'
import update from 'immutability-helper'
import { NETWORK_STATUSES } from 'src/reducers/network-status'
import { isToday } from 'src/lib/validate'

export class Notice extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    updateTheDailyInfo: PropTypes.func.isRequired,
    notice: PropTypes.string,
    displayDate: PropTypes.string.isRequired,
    // StateProps
    networkStatus: PropTypes.symbol.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    notice: '',
  }

  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    this.state = { isNetworkTrying: false, editingValue: false }
  }

  /**
   * UNSAFE_componentWillReceiveProps
   * @param  {object} nextProps React props.
   * @return {void}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    // リクエスト中はdisableにするための処理
    if (
      this.props.networkStatus === NETWORK_STATUSES.TRYING &&
      nextProps.networkStatus !== NETWORK_STATUSES.TRYING &&
      this.state.isNetworkTrying
    ) {
      this.setState(update(this.state, { isNetworkTrying: { $set: false } }))
    }
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps, nextState) {
    // if (
    //   nextProps.location.regionIndex === 0 &&
    //   nextProps.location.listIndex === 3
    // ) {
    //   console.log(this.props.notice, nextProps.notice)
    // }
    return (
      this.props.notice !== nextProps.notice ||
      this.state.isNetworkTrying !== nextState.isNetworkTrying ||
      this.state.editingValue !== nextState.editingValue ||
      this.props.displayDate !== nextProps.displayDate
    )
  }

  /**
   * 特記事項の値変更時のハンドラ
   * @param  {Event} e event
   * @return {void}
   */
  onTextAreaChange = e => {
    this.setState(
      update(this.state, { editingValue: { $set: e.target.value } }),
    )
  }

  /**
   * 特記事項の編集を終了した時のハンドラ
   * @param  {Event} e event
   * @return {void}
   */
  onTextAreaBlur = e => {
    this.setState(
      update(this.state, {
        isNetworkTrying: { $set: true },
        editingValue: { $set: false },
      }),
    )
    this.props.updateTheDailyInfo(
      { notice: e.target.value },
      { notice: e.target.value },
    )
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { isNetworkTrying, editingValue } = this.state

    const { notice } = this.props

    const displayNotice = editingValue === false ? notice : editingValue

    const disabled = !isToday(this.props.displayDate)

    return (
      <div className={ 'notice list-notice' }>
        <textarea
          rows="5"
          className={ 'content' }
          placeholder={ '(特記事項なし)' }
          value={ displayNotice || '' }
          onChange={ this.onTextAreaChange }
          onBlur={ this.onTextAreaBlur }
          disabled={ isNetworkTrying || disabled }
          style={ disabled ? { background: 'lightgrey' } : {} }
        />
      </div>
    )
  }
}

export default connect(Notice)
