import config from 'src/config'

/**
 * @param  {Sticky} sticky 付箋インスタンス
 * @param  {object} option オプション
 * @return {Sticky}        変形後の付箋インスタンス
 */
const transformBeforeGeneratePrivate = sticky => {
  return sticky.update({
    // 新規付箋作成で、初番はサーバー側で行うので、IDは送らない
    id: void 0,
    // 必ず付箋タイプを埋め込む
    stickyTypeId: config.stickyType.private,
    // 必ず注文タイプを埋め込む
    orderTypeId: config.orderType.new,
    // 必ず受電媒体Idを埋め込む
    mediumId: config.media.other,
    displayDate: sticky._props.displayDate,
  })
}

export default transformBeforeGeneratePrivate
