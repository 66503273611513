import React from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'
import moment from 'moment'
import connect from '../connect'
import Sticky from 'src/lib/class-sticky'

// components
import SelectTemplate from './partials/select-template'
import InputRecord from './partials/input-record'
import Histories from './partials/histories'
import config from 'src/config'

/**
 * 交渉履歴変更
 * @type {ReactComponent}
 */
export class NegotiationRecords extends React.PureComponent {
  /**
   * Validation
   * @type {object}
   */
  static propTypes = {
    // ownProps
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
    disabled: PropTypes.bool.isRequired,
    hasMarucRepeat: PropTypes.bool, // リピート付箋がある時true
    consultation: PropTypes.bool,
    // stateProps
    master: PropTypes.shape({
      handOver: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        }),
      ),
    }).isRequired,
    login: PropTypes.shape({
      userid: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
    }).isRequired,
    // dispatchProps
    updateStage: PropTypes.func.isRequired,
  }

  /**
   * Defaults
   * @type {object}
   */
  static defaultProps = {
    negotiationRecords: [],
    contacts: [],
    consultation: false,
    hasMarucRepeat: false,
  }

  state = {
    selected: -1,
    editingText: '',
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // 付箋が入れ替わるとき入力済みのテキストをクリアする。
    if (this.props.sticky.id !== nextProps.sticky.id) {
      this.setState({ selectd: -1, editingText: '' })
    }
  }

  /**
   * その他で交渉相手を選択した時のテキストを作成する。
   * stageAdditionalNegotiationRecordWith
   * @param {string} who who negotiate with
   * @return {string} record
   */
  createRecordWith = who => {
    const { selected } = this.state
    const {
      master: { handOver },
    } = this.props
    const handover = handOver.find(ho => ho.id === selected)
    const result = handover ? handover.name : ''
    return (who ? [who].concat(result) : [result]).join('\n')
  }

  /**
   * Autocomplete on Select handler
   * @param  {string} item 相手方のラベル
   * @return {void}
   */
  onContactSelect = item => {
    this.setState(
      update(this.state, {
        editingText: {
          $set: this.createRecordWith(item) + '\n' + this.state.editingText,
        },
      }),
    )
  }

  /**
   * on check handler
   * @param  {Event} e event
   * @return {void}
   */
  onSelectTemplates = e => {
    const val = parseInt(e.target.value, 10)
    const id = isNaN(val) ? -1 : val
    this.setState(
      update(this.state, {
        selected: { $set: id },
      }),
    )
  }

  /**
   * 交渉履歴テキストエリア変更時のハンドラ
   * @param  {Event} e event
   * @return {void}
   */
  onTextareaChange = e =>
    this.setState(
      update(this.state, {
        editingText: { $set: e.target.value },
      }),
    )

  /**
   * 最新の交渉履歴を修正する
   * describe: 修正した交渉履歴の本文
   * index: 付箋の中での交渉履歴の位置を表すインデックス
   * id: 交渉履歴のインデックス。新規追加の場合はnull。交渉履歴[index].id と同じになる。
   */
  onLastRecordChange = (describe, index, id) => {
    const {
      login: { displayName },
    } = this.props
    const negotiationRecords = this.props.sticky.json().negotiationRecords || []
    // idがある時は対応するレコード、ない時は追加された最後の交渉履歴を更新する。
    const idx = id ? index : negotiationRecords.length - 1
    // 作成時刻がある時は変更しない。
    const createAt =
      negotiationRecords[idx].createAt ||
      moment().format('YYYY-MM-DD HH:mm:ss.SSS')
    // タブ文字を空白に変換する
    const freeDescribe = describe.replace(/\t/g, ' ')
    //
    const diffProps = {
      negotiationRecords: {
        [idx]: {
          id,
          freeDescribe,
          createAt,
          displayName,
        },
      },
    }
    this.props.updateStage(new Sticky(diffProps))
  }

  /**
   * register new record
   * @return {void}
   */
  registerNewRecord = () => {
    const {
      sticky,
      updateStage,
      login: { displayName },
    } = this.props
    const { selected, editingText } = this.state
    const freeDescribe = editingText.replace(/\t/g, ' ')

    const handOverStatuses = selected
    const nextIndex = (sticky.json().negotiationRecords || []).length
    const diffProps = {
      negotiationRecords: {
        [nextIndex]: {
          id: null,
          freeDescribe,
          handOverStatuses,
          createAt: moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
          displayName,
        },
      },
    }
    updateStage(new Sticky(diffProps))

    // 更新成功時に入力している値を消す
    this.setState(
      update(this.state, {
        selected: { $set: -1 },
        editingText: { $set: '' },
      }),
    )
  }

  /**
   * render
   * @return {ReactDOM} dom
   */
  render() {
    const { editingText, selected } = this.state
    const {
      sticky,
      login,
      route,
      disabled,
      hasMarucRepeat,
      consultation,
    } = this.props

    const { negotiationRecords, contacts } = sticky.json()

    const flattenContacts = Object.values(contacts || [])
      .reduce((prev, x) => [...prev, ...x], [])
      .filter(x => !!x)
      .map(x => {
        const phoneNumber = x.phoneNumber ? x.phoneNumber : '(電話番号未入力)'
        const value = phoneNumber + ': ' + x.name + '様'
        return { value: value, text: value }
      })
      .concat({ value: '', text: '自由記述' })

    return (
      <div>
        <SelectTemplate
          items={ flattenContacts }
          selected={ selected }
          route={ route }
          disabled={ disabled }
          hasMarucRepeat={ hasMarucRepeat }
          onCheckChange={ this.onSelectTemplates }
          onContactSelect={ this.onContactSelect }
          consultation={ consultation }
        />

        <InputRecord
          value={ editingText }
          onChange={ this.onTextareaChange }
          onClick={ this.registerNewRecord }
          route={ route }
          whoRecord={ login.displayName }
          disabled={ disabled }
        />

        <Histories
          stickyId={ sticky.id || -1 }
          stickyOrderTypeId={ sticky.json().orderTypeId || config.orderType.new }
          onLastRecordChange={ this.onLastRecordChange }
          negotiationRecords={ negotiationRecords || [] }
          route={ route }
        />
      </div>
    )
  }
}

export default connect(NegotiationRecords)
