import React from 'react'
import PropTypes from 'prop-types'
import config from 'src/config'
import styled from 'styled-components'

const { stickyConditions } = config

export const Label = styled.label`
  &::before {
    content: '';
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: ${props => props.color};
    filter: drop-shadow(0 0 2px #616161);
  }
`

Label.propTypes = {
  color: PropTypes.string.isRequired,
  borderColor: PropTypes.string,
}

Label.defaultProps = {
  borderColor: 'transpaerent',
}

export const LabelsWrap = styled.div`
  display: flex;
  align-items: center;
  ${props => (props.center ? 'justify-content: center;' : '')};

  &:not(:first-child) {
    margin-left: 1em;
  }
`

export const LabelText = styled.span`
  margin-left: 0.5em;
`

export const Labels = () => (
  <LabelsWrap center style={ { fontSize: '0.9em' } }>
    {stickyConditions.map(condition =>
      condition.display ? (
        <LabelsWrap key={ condition.id }>
          <Label color={ condition.color } borderColor={ condition.borderColor } />
          <LabelText>{condition.dispName || condition.name}</LabelText>
        </LabelsWrap>
      ) : null,
    )}
  </LabelsWrap>
)

export default Labels
