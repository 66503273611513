// react
import React from 'react'
import PropTypes from 'prop-types'
import connect from './props'

// lib
// import { findStickyFromWara, findListFromWara } from 'src/lib/search'
import config from 'src/config'
import ClassSticky from 'src/lib/class-sticky'
import expoWork, { WorkType } from 'src/lib/expo-push-api/work'
import requestPutSticky from 'src/lib/sticky-api/put'
import requestPostSticky from 'src/lib/sticky-api/post'
import requestGetSticky from 'src/lib/sticky-api/get'
import requestDeleteSticky from 'src/lib/sticky-api/delete'
import { conditionIds } from 'src/components/divisions/order-list/partials/conditions'
import Sticky from 'src/lib/class-sticky'
import { useCancelStatus } from 'src/reducers/wara/actions/useCancelStats'

const StickyContentButtons = props => {
  const {
    location,
    sticky,
    getSSworkList,
    workState,
    wara,
    splitterLocation,
    isDebugMode,
    editSticky,
    accessToken,
    env,
    displayDate,
    updateSticky,
    deleteSticky,
    deleteStickyById,
    openLightbox,
    setWaiting,
    addSticky,
  } = props

  /**
   * 仮予約 -> 本予約の直接変更
   * @return {void}
   */
  const onReserveClick = async () => {
    const splitFrom = sticky.getUserValue('splitFrom')
    let orgSticky
    try {
      orgSticky = await requestGetSticky(splitFrom, accessToken, env)
    } catch (e) {
      console.error('分裂元付箋が取得できませんでした')
      return
    }
    const splitOf = orgSticky.getUserValue('splitOf') || []
    // 親付箋をこの付箋が割り当てられているSSに割り当て、本予約とする。
    // dockから元の付箋を削除
    deleteStickyById(splitFrom)
    // staffIdと確定作業日時を設定する。確定作業日時は元付箋になければ現在日付とする。
    const assignDate =
      (sticky._props.determinedDateTime || {}).date || displayDate
    const diffSticky = new ClassSticky({
      id: orgSticky.id,
      updateAt: orgSticky._props.updateAt,
      staffId: sticky._props.staffId,
      determinedDateTime: {
        ...sticky._props.determinedDateTime,
        date: assignDate,
      },
    })
    const newSticky = await requestPutSticky(diffSticky, accessToken, env)
    // わらで仮付箋のあった場所に貼り付ける
    addSticky(location, new ClassSticky(newSticky))
    // 分割付箋全てを削除する。
    splitOf.forEach(stickyId => {
      // 読み込み済みの付箋を削除
      deleteStickyById(stickyId)
      // バックエンドに付箋の削除を要求
      requestDeleteSticky(stickyId, accessToken, env)
        .then(() => {})
        .catch(err => {
          console.log(err)
        })
    })
  }

  const onCopyClick = async () => {
    const copySticky = sticky.transform({
      pattern:
        sticky._props.stickyTypeId === config.stickyType.private
          ? Sticky.TRANSFORM_PATTERN.BEFORE_GENERATE_PRIVATE
          : Sticky.TRANSFORM_PATTERN.BEFORE_GENERATE_MEMO,
      determinedDateTime: {
        ...sticky._props.determinedDateTime,
        date: displayDate,
      },
      stickyTypeId: sticky._props.stickyTypeId,
    })
    const newSticky = await requestPostSticky(copySticky, accessToken, env)
    // わらで伝言/私用付箋のあった場所に貼り付ける
    addSticky(location, new Sticky(newSticky))
  }

  /**
   * 着手状態の通知
   * @param {*} staffId
   * @param {*} displayDate
   * @param {*} sticky
   * @param {*} nextStatusId
   * @param {*} workType
   * @param {*} type
   */
  const expoWorkNotify = (
    staffId,
    displayDate,
    sticky,
    nextStatusId,
    workType,
    type,
  ) => {
    const { accessToken, env, getSSworkList } = props
    const stickyId = sticky.id
    const client = sticky.findClient() || {}
    const stickyContactId = client.id || -1

    expoWork(
      workType,
      staffId,
      stickyId,
      stickyContactId,
      displayDate,
      accessToken,
      env,
    )
      .then(res => {
        if (res.ok) {
          return { ok: true }
        } else {
          return res.json()
        }
      })
      .then(json => {
        if (json.ok) {
          console.log(nextStatusId)
          // updateAndRequestSticky(
          //   new ClassSticky({ id: stickyId, stickyStatusId: nextStatusId }),
          // )
          getSSworkList(displayDate)
        } else {
          console.error(json)
          const message = `${type}の通知に失敗しました。${json.message || ''}`
          console.error(message)
          window.alert(message)
        }
      })
      .catch(() => {
        const message = `${type}の通知に失敗しました。`
        console.log(message)
        window.alert(message)
      })
  }

  /**
   * 着手開始
   * @param {*} staffId
   * @param {*} displayDate
   * @param {*} sticky
   * @param {*} nextStatusId
   */
  const expoWorkNotifyStart = (staffId, displayDate, sticky, nextStatusId) =>
    expoWorkNotify(
      staffId,
      displayDate,
      sticky,
      nextStatusId,
      WorkType.workStart,
      '着手指示',
    )

  /**
   * 着手取消
   * @param {*} staffId
   * @param {*} displayDate
   * @param {*} sticky
   * @param {*} nextStatusId
   */
  const expoWorkNotifyCancel = (staffId, displayDate, sticky, nextStatusId) =>
    expoWorkNotify(
      staffId,
      displayDate,
      sticky,
      nextStatusId,
      WorkType.workCancel,
      '着手取消',
    )

  /**
   * 着手完了
   * @param {*} staffId
   * @param {*} displayDate
   * @param {*} sticky
   * @param {*} nextStatusId
   */
  const expoWorkNotifyEnd = (staffId, displayDate, sticky, nextStatusId) =>
    expoWorkNotify(
      staffId,
      displayDate,
      sticky,
      nextStatusId,
      WorkType.workEnd,
      '着手完了',
    )

  /**
   * 直接変更のハンドラ。色々応用効くよ
   * 着手済み <-> 未着手のパラメータを変更するなどに使われる
   * @param finish: 完了の時true, キャンセルの時false。着手の時は false で呼ばれる。
   * @return {void}
   */
  const onUpdateClick = finish => {
    const { sticky, displayDate } = props
    // const sticky = findStickyFromWara(wara, location)
    // const list = findListFromWara(wara, location) || {}
    // const staffId = list.staffId
    const staffId = sticky._props.staffId

    if (sticky) {
      // 変更実行
      // 未着手/完了： => 着手済み( stickyStatusId === 10)
      // 着手済み：
      // 　finish = true => 完了(stickyStatusId === 11)
      // 　finish = false => 未着手(stickyStatusId === 0)
      // それ以外なら、実行しない
      const { id: stickyId, stickyStatusId, userField } = sticky.json()
      let nextStatusId = false
      if (
        stickyStatusId === config.stickyStatus.notWorking ||
        stickyStatusId === config.stickyStatus.finishWorking
      ) {
        nextStatusId = config.stickyStatus.working
      } else if (stickyStatusId === config.stickyStatus.working) {
        nextStatusId = finish
          ? config.stickyStatus.finishWorking
          : config.stickyStatus.notWorking
      }

      if (nextStatusId !== false) {
        // プッシュ通知
        if (
          nextStatusId === config.stickyStatus.working &&
          window.confirm('着手指示をSSに通知しますか？')
        ) {
          updateAndRequestSticky(
            new ClassSticky({
              id: stickyId,
              stickyStatusId: nextStatusId,
              showSchedule: true,
            }),
          )
          expoWorkNotifyStart(staffId, displayDate, sticky, nextStatusId)
        } else if (
          nextStatusId === config.stickyStatus.notWorking &&
          window.confirm(
            '再度着手するまで訪問件数に勘定されません。\n着手を取り消して、よろしいですか？',
          )
        ) {
          // アフター付箋と保留復活・キャンセル復活ならshowScheduleはfalseにしない
          const isAfterChildren = userField && userField.includes('afterOf')
          const isResume = userField && userField.includes('resume')
          updateAndRequestSticky(
            new ClassSticky({
              id: stickyId,
              stickyStatusId: nextStatusId,
              showSchedule: isAfterChildren || isResume ? true : false,
            }),
          )
          expoWorkNotifyCancel(staffId, displayDate, sticky, nextStatusId)
        } else if (
          nextStatusId === config.stickyStatus.finishWorking &&
          window.confirm('SSの着手を完了させますか？')
        ) {
          updateAndRequestSticky(
            new ClassSticky({ id: stickyId, stickyStatusId: nextStatusId }),
          )
          expoWorkNotifyEnd(staffId, displayDate, sticky, nextStatusId)
        }
      }
    }
  }

  /**
   * 削除ボタンクリックのハンドラ。
   * @return {void}
   */
  const onDeleteClick = async () => {
    const { location } = props
    // TODO: サイドエフェクトがある。これは解除したい
    deleteSticky(location)
  }

  /**
   * 削除ボタンクリックのハンドラ。
   * @return {void}
   */
  const onSplitDeleteClick = async () => {
    const isSplit = sticky.json().stickyStatusId === config.stickyStatus.split
    if (isSplit) {
      const splitFrom = sticky.getUserValue('splitFrom') // NOTE: ここは、undefined になるはずはないが、一応避けておく

      let result
      try {
        result = await requestGetSticky(splitFrom, accessToken, env)
      } catch (e) {
        console.error('分裂元付箋が取得できませんでした')
        return
      }
      const splitOf = result.getUserValue('splitOf') || []
      if (splitOf.length < 2) {
        alert('削除できません')
        return
      }

      splitOf.splice(splitOf.indexOf(sticky.id), 1)
      result = result.setUserValue('splitOf', splitOf)

      try {
        result = await requestPutSticky(result, accessToken, env)
      } catch (e) {
        console.error(e)
        if (e === config.conflictError) {
          alert(config.conflictMessage)
        } else {
          alert('分裂元付箋が更新できませんでした')
        }
        return
      }
      // TODO サイドエフェクトがある。これは解除したい
      deleteSticky(location)
    }
  }

  /**
   * 編集ボタンクリックのハンドラ
   * @return {void}
   */
  const onEditClick = () => {
    setWaiting(true)
    requestGetSticky(sticky.id, accessToken, env)
      .then(rsticky => {
        setWaiting(false)
        editSticky(rsticky)
        // const splitMode = rsticky.getUserValue('SplitMode') || 'false'
        // if (splitMode === 'false') {
        // } else {
        //   alert('他で仮予約中です。')
        // }
      })
      .catch(err => {
        setWaiting(false)
        console.error(err)
      })
  }

  // 写真の表示
  const onPhotoClick = sticky => {
    const { id, orderId } = sticky.json()
    openLightbox(id, orderId, 'EXPO')
  }

  /**
   * 精算済みにするボタン
   * @return {void} [description]
   */
  const onLiquidateClick = () =>
    updateAndRequestSticky(
      new ClassSticky({
        finishStateId: config.finishState.complete,
        stickyStatusId: config.stickyStatus.liquidated,
      }),
    )

  /**
   * 指定された差分を付箋に反映する。
   */
  const updateAndRequestSticky = stickyDiff => {
    const id = sticky.id

    requestGetSticky(id, accessToken, env).then(result => {
      stickyDiff._props.updateAt = result._props.updateAt
      requestPutSticky(stickyDiff.update({ id }), accessToken, env)
        .then(result => {
          const sticky = new ClassSticky(result)
          // リクエスト結果を入れ込む
          updateSticky(sticky, location)
        })
        .catch(error => {
          // TODO: ここで元に戻す
          console.error(error)
        })
    })
  }

  /**
   * 仮アサインモード（複製モード）判定
   * @return {boolean} 仮アサインモード（複製モード）かどうか
   */
  const isSplitMode = Object.keys(splitterLocation).length > 0

  // 付箋分裂モードの時は、ボタンを表示しない
  if (isSplitMode) return null

  const isNormalSticky = sticky.json().stickyTypeId === config.stickyType.normal
  const isMemoSticky = sticky.json().stickyTypeId === config.stickyType.memo
  const isPrivateSticky =
    sticky.json().stickyTypeId === config.stickyType.private
  const isWorking = sticky.json().stickyStatusId === config.stickyStatus.working
  const isSSWorking = workState === config.ssworkState.workStart

  const isSplit = sticky.json().stickyStatusId === config.stickyStatus.split
  const isDocked = location.regionIndex === -1 || location.listIndex === -1

  const isNoop = sticky.json().stickyTypeId === config.stickyType.noop
  const isPayment = sticky.json().stickyTypeId === config.stickyType.payment
  // アサイン実行中・担当者未連絡以外のときのみ「着手完了」が押せる。
  const cids = conditionIds(sticky.json())
  const enableFinish = !(cids[0] === 2 || cids[0] === 1)

  // 編集できる付箋
  const isEditable = !isNoop && !isSplit
  // 本予約にできる付箋
  const isReservable = isSplit && !isNoop
  const isAssignable = (isNormalSticky || isPayment) && !isDocked && !isNoop
  // NOTE: UIとしては複製付箋、伝言付箋、私用付箋、スペーサー付箋以外の削除を許容しない
  const isDeletable = isSplit || isMemoSticky || isPrivateSticky || isNoop
  // 伝言付箋と私用付箋は「写真」の代わりに「複製」
  const isCopyable = isMemoSticky || isPrivateSticky

  return (
    <div className={ 'sticky-button-wrap' }>
      {isEditable && (
        <button className={ 'button button-icon-edit' } onClick={ onEditClick }>
          {'編集'}
        </button>
      )}

      {isReservable && (
        // 分裂付箋の時は、仮予約 -> 本予約のボタンをここで提供
        <button className={ 'button button-icon-check' } onClick={ onReserveClick }>
          {'本予約'}
        </button>
      )}

      {isAssignable &&
        (isWorking ? (
          <button
            className={ 'button button-icon-cancel' }
            onClick={ () => onUpdateClick(false) }
          >
            {'着手取消'}
          </button>
        ) : (
          <button
            className={ 'button button-icon-hand' }
            onClick={ () => onUpdateClick(false) }
            disabled={ !isSSWorking }
          >
            {isSSWorking ? '着手' : ' '}
          </button>
        ))

      // 通常付箋の時は、着手ボタンをここで提供
      }

      {/* 中断・アフター・完了の時のみ「通知完了」を表示。 */}
      {isWorking && !isDocked && !isNoop && enableFinish ? (
        <button
          className={ 'button button-icon-check' }
          onClick={ () => onUpdateClick(true) }
        >
          {' 着手完了'}
        </button>
      ) : null}

      {isDeletable ? (
        <button
          className={ 'button button-icon-delete' }
          onClick={ isSplit ? onSplitDeleteClick : onDeleteClick }
        >
          {'削除'}
        </button>
      ) : null}

      {// NOTE: デバッグ時は削除を許容する
        isDebugMode && !isSplit ? (
          <button
            className={ 'button button-icon-delete button-debug' }
            onClick={ onDeleteClick }
          >
            {'デバッグ削除'}
          </button>
        ) : null}

      {!isCopyable && (
        <button
          className={ 'button button-icon-camera' }
          onClick={ () => onPhotoClick(sticky) }
        >
          {'写真'}
        </button>
      )}

      {isCopyable && (
        <button
          className={ 'button button-icon-copy' }
          onClick={ () => onCopyClick() }
        >
          {'複製'}
        </button>
      )}

      {// プロパティをダンプする開発用の機能
        isDebugMode && (
          <button
            className={ 'button button-sticky-dump button-debug' }
            onClick={ sticky.log }
          >
            {'dump'}
          </button>
        )}
    </div>
  )
}

/**
 * propTypes
 * @type {object}
 */
StickyContentButtons.propTypes = {
  // ownProps
  location: PropTypes.shape({
    regionIndex: PropTypes.number.isRequired,
    listIndex: PropTypes.number.isRequired,
    stickyIndex: PropTypes.number.isRequired,
  }).isRequired,
  sticky: PropTypes.instanceOf(ClassSticky).isRequired,
  getSSworkList: PropTypes.func.isRequired,
  workState: PropTypes.number.isRequired,
  // stateProps
  wara: PropTypes.shape({
    regions: PropTypes.arrayOf(
      PropTypes.shape({
        lists: PropTypes.arrayOf(
          PropTypes.shape({
            stickies: PropTypes.arrayOf(PropTypes.instanceOf(ClassSticky)),
          }),
        ),
      }),
    ).isRequired,
  }).isRequired,
  // isSplitMode     : PropTypes.bool.isRequired,
  splitterLocation: PropTypes.object.isRequired,
  isDebugMode: PropTypes.bool,
  editSticky: PropTypes.func.isRequired,
  accessToken: PropTypes.string.isRequired,
  env: PropTypes.object.isRequired,
  displayDate: PropTypes.string.isRequired,
  // dispatchProps
  updateSticky: PropTypes.func.isRequired,
  deleteSticky: PropTypes.func.isRequired,
  deleteStickyById: PropTypes.func.isRequired,
  openLightbox: PropTypes.func.isRequired,
  setWaiting: PropTypes.func.isRequired,
  addSticky: PropTypes.func.isRequired,
}

/**
 * defaultProps
 * @type {object}
 */
StickyContentButtons.defaultProps = {
  isDebugMode: false,
}

export default connect(StickyContentButtons)
