import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import { textColorB } from 'src/colors'

export const Paragraph = styled.p`
  line-height: 1rem;
  word-wrap: break-word;
`

const Wrap = styled.p`
  margin-bottom: 10px;
  color: ${textColorB};
  font-size: 1rem;
`

export const Time = props => {
  const { value } = props
  const [createdDate, createdTime] = value
    ? ['YYYY-MM-DD', 'HH:mm:ss'].map(format => moment(value).format(format))
    : ['', '']

  const displayValue = (createdDate || '') + ' ' + (createdTime || '')

  return <Wrap>{displayValue}</Wrap>
}

Time.propTypes = {
  value: PropTypes.string.isRequired,
}
