import config from 'src/config'

/**
 * コール課での流し込み実行前に付箋を変形する
 * @param  {Sticky} sticky 付箋インスタンス
 * @param  {object} option オプション
 * @return {Sticky}        変形後の付箋インスタンス
 */
const transformBeforeGenerateMaruc = sticky => {
  const isHouseLabo = sticky._props.companyId === config.company.HL
  const isHomeServe = sticky._props.companyId === config.company.SV
  // 現在の担当者をマルシーの元担当者として設定する
  if (!sticky._props.marucComplaint) {
    sticky._props.marucComplaint = {}
  }
  sticky._props.marucComplaint.parentStaffId = sticky._props.staffId
  // マルシー付箋の支払情報をクリアする。
  sticky._props.contacts &&
    sticky._props.contacts.forEach(idcontact => {
      idcontact.forEach(contact => {
        contact.id = null
        contact.stickyId = null
        contact.paymentInfo = null
      })
    })
  // ハウスラボの付箋の場合、金額情報その他を削除する。
  if (isHouseLabo) {
    sticky._props.houseLabo = {}
    sticky._props.houseLaboWorks = []
    sticky._props.houseLaboFees = []
    sticky._props.houseLaboExtraFees = []
  }
  // ES提携（ホームサーブ）の場合、金額情報その他を削除する。
  if (isHomeServe) {
    sticky._props.es = {}
    sticky._props.esFees = []
    sticky._props.esPartsFees = []
    sticky._props.esWorks = []
    sticky._props.esWorkTimes = []
  }
  // 受注不可、受注注意のチェックボックスの状態を残す
  const handOverIds = [...((sticky._props.handOver || {}).ids || [])]
  sticky._props.handOver = { ids: [...handOverIds] }

  return sticky
    .remove({
      orderId: false,
      // 流し込みに当たって、いらないデータを消しておく
      wishDateTimes: true,
      determinedDateTime: true,
      userField: true,
      // アサイン課の領分
      account: true,
      afterDateTime: true,
      cancelReasonId: true,
      cash: true,
      childId: true,
      completedAt: true,
      createAt: true,
      createUserId: true,
      creditCardId: true,
      creditPaymentMethodId: true,
      displayOrder: true,
      displayDate: true,
      estimateNumber: true,
      estimatePayment: true,
      finishState: true,
      finishStateId: true,
      receivedAt: true,
      reRequest: true,
      reRequestTime: true,
      reRequestUser: true,
      staffId: true,
      stickyStatusId: true, // これは消しても次のステップで0になる
      suspendReason: true,
      thankYouCall: true,
      updateAt: true,
      updateUserId: true,
      workContent: true,
      beforeWorkReport: true,
      makerMaintenance: true,
      twoPersonParentId: true, // 二人工付箋からマルシーを作るとき削除する
    })
    .update({
      // 全ての場合でparentIdを設定、orderTypeIdにマルシーを設定
      parentId: sticky.id,
      orderTypeId: config.orderType.claimer,
    })
}

export default transformBeforeGenerateMaruc
