import { connect } from 'react-redux'

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
export const mapStateToProps = state => {
  return {
    master: state.master.data,
    staffs: state.master.data.staffs,
    cityCodes: state.master.cityCodes,
    accessToken: state.login.authentication.accessToken,
    env: state.env,
  }
}

export default Component => connect(mapStateToProps)(Component)
