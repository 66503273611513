import React from 'react'
import PropTypes from 'prop-types'
import connect from './props'
import Checkbox from 'src/styled/form/checkbox'
import {
  RegionContainer,
  PrefsContainer,
  ToggleButton,
  ToggleBody,
} from './styled'
import { gray } from 'src/colors'
import { Button } from '../styled'
import config from 'src/config'

/**
 * 未配置付箋の選択フィルタ
 * @type {function} コンポーネント
 */
export class SelectRegionsForDissociates extends React.PureComponent {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // stateProps
    masterRegions: PropTypes.array.isRequired,
    prefCodesClassified: PropTypes.object.isRequired,
    prefCodes: PropTypes.array.isRequired,
    regionsFilter: PropTypes.array.isRequired,
    prefsFilter: PropTypes.array.isRequired,
    marucFilter: PropTypes.bool.isRequired,
    // dispatchProps
    updateDissociatesRegionsFilter: PropTypes.func.isRequired,
    updateDissociatesPrefsFilter: PropTypes.func.isRequired,
    toggleDissociatesMarucFilter: PropTypes.func.isRequired,
  }

  static LOCAL_STORAGE_KEY =
    'PRESERVE__select-regions-for-dissociates-region-list'

  /**
   * getDerivedStateFromProps
   * @param  {object} nextProps next props
   * @param  {object} prevState prev state
   * @return {object} new state
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    const isPropsOK = nextProps.masterRegions.length > 0

    if (!prevState.isRecovered && isPropsOK) {
      const preservedToggleState = SelectRegionsForDissociates.recover(
        nextProps,
      )
      return { ...prevState, ...preservedToggleState, isRecovered: true }
    } else {
      return prevState
    }
  }

  /**
   * LocalStorage から状態を再現する
   */
  static recover = nextProps => {
    let preservedRegionIds
    let preservedPrefCodes
    let preservedToggleState
    const {
      masterRegions,
      prefCodes,
      updateDissociatesRegionsFilter,
      updateDissociatesPrefsFilter,
    } = nextProps

    try {
      preservedRegionIds =
        JSON.parse(
          localStorage.getItem(
            SelectRegionsForDissociates.LOCAL_STORAGE_KEY + '__regions',
          ),
        ) || masterRegions.map(region => region.id)
      if (!Array.isArray(preservedRegionIds)) {
        throw new Error('type error')
      }
    } catch (e) {
      preservedRegionIds = masterRegions.map(region => region.id)
    }

    try {
      preservedPrefCodes =
        JSON.parse(
          localStorage.getItem(
            SelectRegionsForDissociates.LOCAL_STORAGE_KEY + '__prefs',
          ),
        ) || prefCodes.map(pref => pref.code)
      if (!Array.isArray(preservedPrefCodes)) {
        throw new Error('type error')
      }
    } catch (e) {
      preservedPrefCodes = prefCodes.map(pref => pref.code)
    }

    try {
      preservedToggleState =
        JSON.parse(
          localStorage.getItem(
            SelectRegionsForDissociates.LOCAL_STORAGE_KEY + '__prefs-toggle',
          ),
        ) || {}
    } catch (e) {
      preservedToggleState = {}
    }

    updateDissociatesRegionsFilter(preservedRegionIds)
    updateDissociatesPrefsFilter(preservedPrefCodes)
    return preservedToggleState
  }

  /**
   * constructor
   * @param  {object} props React props.
   * @return {void}
   */
  constructor(props) {
    super(props)
    this.state = { isRecovered: false }
  }

  save = (regionIds, prefCodes) => {
    const {
      updateDissociatesRegionsFilter,
      updateDissociatesPrefsFilter,
    } = this.props

    updateDissociatesRegionsFilter(regionIds)
    updateDissociatesPrefsFilter(prefCodes)

    // localStorage に再度突っ込む
    localStorage.setItem(
      SelectRegionsForDissociates.LOCAL_STORAGE_KEY + '__regions',
      JSON.stringify(regionIds),
    )
    localStorage.setItem(
      SelectRegionsForDissociates.LOCAL_STORAGE_KEY + '__prefs',
      JSON.stringify(prefCodes),
    )
  }

  /**
   * フィルタ選択時のコールバック
   * @param  {object} e Event
   * @return {void}
   */
  onRegionFilterSelect = e => {
    const { regionsFilter, prefsFilter, prefCodesClassified } = this.props
    const val = parseInt(e.target.value, 10)

    if (!isNaN(val)) {
      const id = val
      let regionIds = []

      if (e.target.checked) {
        // + checked
        regionIds = regionsFilter
          .concat(id)
          .filter((x, i, self) => self.indexOf(x) === i) // make unique
      } else {
        // - checked
        regionIds = regionsFilter.filter(x => x !== id)
      }

      // 当該 region に属する全ての都道府県
      const codes = prefCodesClassified[id].map(x => x.code)
      // 次の prefCodesフィルタ
      let prefCodes = prefsFilter

      if (e.target.checked) {
        // + checked
        prefCodes = prefCodes
          .concat(codes)
          .filter((x, i, self) => self.indexOf(x) === i) // make unique
      } else {
        // - checked
        prefCodes = prefCodes.filter(code => !codes.includes(code))
      }

      this.save(regionIds, prefCodes)
    }
  }

  /**
   * エリア選択に表示、「全選択」で対象とするエリアを選ぶための関数
   *
   * @param {*} region
   */
  filterRegion = region => {
    return region.isValid && region.companyId === config.company.ES
  }

  /**
   * 未配置付箋のエリアを全て選択する
   */
  selectAll = () => {
    const { masterRegions, prefCodesClassified } = this.props
    const regionIds = masterRegions
      .filter(this.filterRegion)
      .map(region => region.id)
    // 関連する全ての都道府県をチェックする
    let prefCodes = []
    regionIds.forEach(id => {
      if (prefCodesClassified[id]) {
        const codes = prefCodesClassified[id].map(x => x.code)
        prefCodes = prefCodes.concat(codes)
      }
    })
    this.save(regionIds, prefCodes)
  }

  /**
   * 未配置付箋のエリアを全てクリアする
   */
  deselectAll = () => {
    this.save([], [])
  }

  /**
   * フィルタ選択時のコールバック
   * @param  {object} e Event
   * @return {void}
   */
  onPrefFilterSelect = e => {
    const {
      prefCodes,
      prefCodesClassified,
      regionsFilter,
      prefsFilter,
    } = this.props
    const code = e.target.value

    const { regionId } = prefCodes.find(pref => pref.code === code) || {}

    if (!regionId) {
      // 異常なprefCode
      return
    }

    if (code) {
      let regionIds = []
      let prefCodes = []

      if (e.target.checked) {
        // + checked
        prefCodes = prefsFilter
          .concat(code)
          .filter((x, i, self) => self.indexOf(x) === i) // make unique
        regionIds = regionsFilter
          .concat(regionId)
          .filter((x, i, self) => self.indexOf(x) === i) // make unique
      } else {
        // - checked
        prefCodes = prefsFilter.filter(x => x !== code)
        // 当該regionの都道府県で、表示するようになっているもの
        const displayRegionPrefs = prefCodes.filter(prefCode =>
          prefCodesClassified[regionId]
            .map(pref => pref.code)
            .includes(prefCode),
        )

        regionIds =
          displayRegionPrefs.length === 0
            ? regionsFilter.filter(x => x !== regionId)
            : regionsFilter
      }

      this.save(regionIds, prefCodes)
    }
  }

  createToggleRegionHandler = id => () =>
    this.setState({ [id]: !this.state[id] })

  toggleMarucFilter = () => {
    const nextFilter = !this.props.marucFilter
    this.props.toggleDissociatesMarucFilter(nextFilter)
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const {
      masterRegions,
      prefCodesClassified,
      regionsFilter,
      prefsFilter,
      marucFilter,
    } = this.props

    const regions = masterRegions.filter(this.filterRegion)
    if (!regions || regions.length === 0) {
      return null
    }

    return (
      <div>
        <Button onClick={ this.selectAll }>{'全選択'}</Button>
        <Button onClick={ this.deselectAll }>{'全解除'}</Button>
        <RegionContainer>
          {regions.map(({ id, name }) => {
            const isOpen = this.state[id]
            const prefs = prefCodesClassified[id] || []
            const pluralPrefs = prefs.length > 1
            const prefChecked = prefsFilter.filter(code =>
              prefs.map(pref => pref.code).includes(code),
            )

            const notice = ` (${prefChecked.length}/${prefs.length})`

            return (
              <div key={ id }>
                <Checkbox
                  value={ id }
                  name={ 'select-region-disociated' }
                  checked={ regionsFilter.includes(id) }
                  onChange={ this.onRegionFilterSelect }
                  labelText={
                    name + (pluralPrefs && prefChecked.length > 0 ? notice : '')
                  }
                  checkColor={
                    prefChecked.length !== prefs.length ? gray : void 0
                  }
                />
                {pluralPrefs && (
                  <ToggleButton
                    onClick={ this.createToggleRegionHandler(id) }
                    isOpen={ isOpen }
                  />
                )}
                {pluralPrefs && (
                  <ToggleBody isOpen={ isOpen }>
                    <PrefsContainer>
                      {prefs.map(({ code, name }) => (
                        <Checkbox
                          key={ code }
                          value={ code }
                          name={ 'select-region-disociated_' + code }
                          checked={ prefsFilter.includes(code) }
                          onChange={ this.onPrefFilterSelect }
                          labelText={ name }
                        />
                      ))}
                    </PrefsContainer>
                  </ToggleBody>
                )}
              </div>
            )
          })}
        </RegionContainer>
        <hr />
        <Checkbox
          value={ marucFilter }
          name={ 'select-maruc-disociated' }
          checked={ marucFilter }
          onChange={ this.toggleMarucFilter }
          labelText={ 'マルシーのみを表示' }
        />
      </div>
    )
  }
}

export default connect(SelectRegionsForDissociates)
