import styled from 'styled-components'
import button, { buttonFill } from 'src/styled/button'
import { baseColor } from 'src/colors'

export const Button = styled.button`
  ${button};
  ${buttonFill(baseColor)};
  display: block;
  width: 150px;
  height: 30px;
  margin: 20px auto 0;
`

export const ClearButton = styled.button`
  ${button};
  ${buttonFill('hotpink')};
  border: 1px solid 'hotpink';
  display: block;
  width: 150px;
  height: 30px;
  margin: 20px auto 0;
`

// labelInputsWrap 相当
export const CheckWrap = styled.div`
  width: 120px;
  display: flex;
  align-items: center;
  height: auto;
  margin: 5px 0;
`

export const Flex = styled.div`
  display: flex;
`
