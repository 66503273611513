import styled from 'styled-components'
import image from 'src/styles/assets/logo-es.png'

export const Logo = styled.div`
  width: 328px;
  height: 46px;
  margin: 0 auto 30px;
  background-image: url(${image});
  background-size: cover;
`

export const FormWrap = styled.div`
  width: 460px;
  margin: 0 auto;
  padding: 40px;
  border: 1px solid $border-dark-gray;
  border-top: 5px solid #3ca14c;
  background: #fff;
`
