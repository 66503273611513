import Sticky from 'src/lib/class-sticky'
import { today } from 'src/lib/moment'
import config from 'src/config'
import requestGetSticky from 'src/lib/sticky-api/get'
import requestDeleteSticky from 'src/lib/sticky-api/delete'
import requestCanUpdateSticky from 'src/lib/sticky-api/canupdate'
import requestPutMarucRefund from 'src/lib/maruc-api/put-refund'
import { getStaffList } from 'src/lib/search'
import { setStickyDisplay } from 'src/lib/format'
import findStickyById from '../../../../../../lib/find-sticky-by-id'
// それぞれのリクエストモジュールの関数型
// {
//    putSticky: (Sticky, accessToken, env) => ?
//    postSticky: (Sticky, accessToken, env) => ?
//    putPayment: (Payment[], accessToken, env) => ?
//    postOrPutCollectSticky: (Payment, StickyProps, accessToken, env) => ?
//    putComplaint: ({sticky: Sticky, complaint: Complaint}, accessToken, env) => ?
// }

export default async (props, parsedProps, requests, callback) => {
  const {
    // ownProps
    // location,
    setPutStickyStatus, // 付箋の更新状態を設定→ポップアップを表示
    resetPutStickyStatus, // 付箋の更新状態をリセット
    // stateProps
    storedPayments,
    displayDate,
    master: { validStaffs },
    marucRefund,
    // dispatchProps (callbacks)
    addSticky,
    commitSticky,
    deleteSticky,
    deleteStickyById,
    updateEditSticky,
    // updateSticky,
    updateStickyById,
    assignSticky,
    setMarucRefund,
    storePayments,
    // closeModal,
    accessToken,
    env,
    wara,
  } = props

  const {
    // 判定条件
    isDateMoved,
    isAfterCreation,
    isMarucRepeatCreation,
    isAfterOnOtherDay,
    isMarucRepeatOnOtherDay,

    // リクエストに必要なSticky等の値
    originalSticky, // 元の付箋に画面入力をマージした結果の付箋
    stagedSticky, // 差分に元の付箋IDをセットしたもの
    afterSticky, // アフタ付箋
    marucRepeatSticky, // マルシー付箋
    // newContacts,
    isFinished, // 「完了」された

    // その他の雑多な値
    stickyCount, // そのオリジナルの付箋がアサインされているリストにいる付箋の数
    staffId,
    regionId,
    // p2s, // payment id(支払情報ID) -> sticky id(回収付箋ID)
    // s2p, // sticky id(回収付箋ID) -> payment id(支払情報ID)
  } = parsedProps
  // 変更前の状態を退避する
  // const previousSticky = originalSticky.clone()

  try {
    await requestCanUpdateSticky(
      originalSticky.id,
      originalSticky._props.updateAt,
      accessToken,
      env,
    )
  } catch (e) {
    console.error(e)
    if (e === config.conflictError) {
      alert(config.conflictMessage)
    } else {
      alert(e)
    }
    callback()
    return
  }

  const { location } = findStickyById(wara, originalSticky.id) // find target sticky's location
  // わらの上での変更を実行。commitSticky 関数の内部で、 staged sticky がわらに載せられる
  commitSticky(location) // synchronous

  // 0. アフタとマルシーリピートを作るべきならば、それをする
  // 1. 支払情報の変更をPUTする
  // 2. 付箋の変更をPUTする
  // 3.
  //  (1)集金付箋を作っていない時（1からそのIDが得られない時）
  //    集金付箋をPOSTする
  //    付箋のパラメータとしてそのIDをPUTする
  //  (2)集金付箋を作っている時（1からそのIDが得られる時）
  //    集金付箋をPUTする

  let afterStickyId
  let marucRepeatStickyId
  let followingStickyCount = 0

  if (isAfterCreation) {
    try {
      const nextAfterStickyProps = await requests.postSticky(afterSticky)

      afterStickyId = nextAfterStickyProps.id
      if (!isAfterOnOtherDay) {
        // 当日アフタならば、一つ増やす
        followingStickyCount++
        addSticky(
          { ...location, stickyIndex: stickyCount },
          new Sticky(nextAfterStickyProps, { normalize: false }),
        )
      }
    } catch (e) {
      console.error(e)
      alert('アフタ付箋の作成に失敗しました')
      callback()
      return
    }
  }

  if (isMarucRepeatCreation) {
    try {
      const nextMarucRepeatStickyProps = await requests.postSticky(
        marucRepeatSticky,
      )

      marucRepeatStickyId = nextMarucRepeatStickyProps.id
      if (!isMarucRepeatOnOtherDay) {
        // 当日アフタならば、一つ増やす
        followingStickyCount++
        addSticky(
          { ...location, stickyIndex: stickyCount },
          new Sticky(nextMarucRepeatStickyProps, { normalize: false }),
        )
      }
    } catch (e) {
      console.error(e)
      alert('マルシー付箋の作成に失敗しました')
      callback()
      return
    }
  }

  //------------------------------------------------------------------
  // 全ての支払情報を取得
  const allPayments = (storedPayments[originalSticky.id] || [])
    .filter(p => p)
    // stickyIdをくっつける
    .map(payment => ({
      ...payment,
      data: { ...payment.data, stickyId: originalSticky.id },
    }))

  //------------------------------------------------------------------
  // 付箋の支払情報を更新する
  let putPayments = []
  if (allPayments.length > 0) {
    try {
      putPayments = await requests.putPayment(allPayments)
    } catch (e) {
      console.error(e)
      if (e === config.conflictError) {
        alert(config.conflictMessage)
      } else {
        alert('支払情報の更新に失敗しました')
      }
      callback()
      return
    }
  }

  //------------------------------------------------------------------
  // マルシー付箋の時は返金情報を更新する
  if (originalSticky.isClaimerOrder && marucRefund) {
    const refund = marucRefund[originalSticky.id] || {}
    // 返金データがあり、更新されている時のみAPIを呼び出す。
    if (refund && refund.modified) {
      let result = {}
      try {
        delete refund.modified
        const res = await requestPutMarucRefund(refund, accessToken, env)
        if (res.ok) {
          result = await res.json()
          setMarucRefund(result)
          // TODO: 更新結果でmarucRefundを置き換える。
          console.log(result)
        } else {
          alert('マルシー返金の更新に失敗しました。')
        }
      } catch (e) {
        console.error(e)
        alert('マルシー返金の更新に失敗しました。')
      }
    }
  }

  // 親付箋に以下を設定
  // - afterStickyId
  // - marucRepeatStickyId
  afterStickyId || (afterStickyId = originalSticky.getUserValue('hasAfter'))
  marucRepeatStickyId ||
    (marucRepeatStickyId = originalSticky.getUserValue('hasMarucRepeat'))
  // 送られてきた付箋情報で今の付箋を更新する
  const nextOriginalSticky = stagedSticky
    .update(
      { childId: afterStickyId, updateAt: originalSticky._props.updateAt },
      { normalize: false },
    )
    .setUserValue('hasAfter', afterStickyId)
    .setUserValue('hasMarucRepeat', marucRepeatStickyId)
  // 付箋の表示状態を設定する
  setStickyDisplay(nextOriginalSticky, validStaffs, originalSticky)
  // putPaymentsで更新した支払情報を更新しないようにする。
  const orgContacts = nextOriginalSticky._props.contacts
  orgContacts.forEach(contact => contact.forEach(c => (c.paymentInfo = null)))

  // userFieldが更新されない時は削除する。
  if (nextOriginalSticky._props.userField === '{}') {
    delete nextOriginalSticky._props.userField
  }
  // マルシーリピートを作成するとき、付箋を完了させる
  if (isMarucRepeatCreation) {
    nextOriginalSticky._props.finishStateId = config.finishState.complete
  }

  let nextStickyProps = null
  try {
    nextStickyProps = await requests.putSticky(nextOriginalSticky)
    const nextSticky = new Sticky(nextStickyProps, { normalize: false })
    updateStickyById(nextSticky)
  } catch (e) {
    // TODO: ここで元に戻す
    console.error(e)
    if (e === config.conflictError) {
      alert(config.conflictMessage)
    } else {
      alert('付箋の更新に失敗しました')
    }
    callback()
    return
  }

  //------------------------------------------------------------------
  // マルシーリピート完了時、親付箋も完了させる
  //------------------------------------------------------------------
  // 親のマルシー付箋のID
  const marucid = originalSticky.getUserValue('marucRepeatOf')
  if (isFinished && marucid) {
    // marucid で参照する付箋から更新日付を取得し、diffStickyにセットする。
    const parentSticky = await requestGetSticky(marucid, accessToken, env)

    // リピート付箋を完了した時、親のマルシー付箋も完了する。
    const diffSticky = new Sticky({
      id: marucid,
      finishStateId: 1,
      updateAt: parentSticky._props.updateAt,
    })
    requests
      .putSticky(diffSticky)
      .then(result => {
        // マルシー付箋を更新する。
        const marucSticky = new Sticky(result)
        updateStickyById(marucSticky)
      })
      .catch(err => {
        console.log(err)
        if (err === config.conflictError) {
          alert(config.conflictMessage)
        } else {
          alert('付箋の更新に失敗しました')
        }
        callback()
        return
      })
  }

  //------------------------------------------------------------------
  // 集金付箋の作成
  //------------------------------------------------------------------
  // 集金付箋と関係を持つべき支払情報
  //   ・売掛け金
  //   ・回収方法 = 集金
  //   ・回収予定売掛け金 - 回収売掛け金 > 0 （残金あり）
  //   ・集金確認済み　2024-01-24追加
  const collectPayments = putPayments.filter(
    payment =>
      payment.paymentType === 'accounts' &&
      payment.data.accountCollectionMethodId === config.howToRetrieve.collect &&
      !payment.data.childCollected &&
      payment.data.accountValue - (payment.data.accountCollectedValue || 0) >=
        0,
  )

  // 更新後の付箋の情報を取得する。集金付箋のcontactsにコピーするために使用。
  let orgsticky
  try {
    orgsticky = await requestGetSticky(originalSticky.id, accessToken, env)
  } catch (e) {
    console.error(e)
    return
  }

  let collectStickiesResults
  const removingCollectStickyIds = []
  // 新規に作成した集金付箋の一覧
  const payments = []
  // 元の付箋のcontactsをJSON文字列の形で保管し、後で復元できるようにする。
  const orgStickyProps = orgsticky.json()
  const orgContactsJson = JSON.stringify(orgStickyProps.contacts)
  let paymentDisplay = (orgsticky._props.displayOrder || 0) + 1

  // 集金付箋の対象となる支払情報を元に集金付箋を作成する。
  try {
    collectStickiesResults = await Promise.all(
      collectPayments.map(payment => {
        // 元の付箋のcontactsを集金付箋用に加工する。
        // contactsは以下の処理で変更されるため、上で保存したJSON文字列から変更前のものを復元する。
        //   ・登録するpaymentのidと一致するaccountだけを残す
        //   ・支払情報が関連づく付箋情報をnullにする
        //   ・現金がある場合は削除する
        //   ・売掛け金の回収方法は「集金」に設定
        // 元の付箋のcontactsから、登録しようとする支払情報のみを残す
        const orgContacts = JSON.parse(orgContactsJson)
        const paymentContact = [...orgContacts]
        for (let i = 0; i < paymentContact.length; i++) {
          const contactn = paymentContact[i]
          for (let j = 0; j < contactn.length; j++) {
            const contact = contactn[j]
            contact.paymentInfo.accounts = contact.paymentInfo.accounts.filter(
              account => account.id === payment.data.id,
            )
            // 実際は一つになるはずだが、念のためループしておく
            contact.paymentInfo.accounts.forEach(account => {
              account.accountCollectionMethodId = config.howToRetrieve.collect
            })
            contact.paymentInfo.stickyContactId = null
            contact.paymentInfo.stickyId = null
            contact.paymentInfo.payments = []
          }
        }
        const collectProps = {
          parentId: originalSticky.id,
          parentAccountId: payment.data.id,
          userField: JSON.stringify({
            collectStickyOf: originalSticky.id,
            collectPaymentOf: payment.data.id,
          }),
          stickyTypeId: config.stickyType.payment, // 集金付箋
          isAfter: 'false',
          stickyStatusId: config.stickyStatus.notworking, // 未アサイン
          regionId,
          staffId,
          displayDate: payment.data.accountDate || displayDate,
          displayOrder: paymentDisplay++, // orderは最後にする
          orderId: originalSticky.orderId,
          contacts: paymentContact, // 加工した元付箋のcontacts
          companyId: orgsticky._props.companyId, // 親の会社名を引き継ぐ
          // 集金付箋の重要申し送りにいつの集金か記載する
          handOver: {
            freeDescribe:
              displayDate.substring(5, 7) +
              '月' +
              displayDate.substring(8, 10) +
              '日分集金',
          },
        }
        return requests.postSticky(
          new Sticky(collectProps, { normalize: false }),
        )
      }),
    )
    // リクエスト後にビューに集金付箋を配置
    collectStickiesResults.forEach((sticky, i) => {
      const stickObj = new Sticky(sticky, { normalize: false })
      payments.push(stickObj.id)
      if (
        // 現在の日付に表示する場合
        stickObj.json().displayDate === displayDate
      ) {
        addSticky(
          {
            ...location,
            stickyIndex: location.stickyIndex + i + followingStickyCount + 1,
          },
          stickObj,
        )
      } else if (
        displayDate === today() && // TODO これおかしい気がする。today は関連しないと思う
        stickObj.json().displayDate !== displayDate
      ) {
        // 日付を当日から変更
        removingCollectStickyIds.push(stickObj.id)
      }
    })
  } catch (e) {
    console.error(e)
    alert('集金付箋の作成に失敗しました')
    callback()
    return
  }

  // 上で更新した親付箋で編集中の付箋を置き換える。同時に差分をクリアする。
  const diffUpdateDateProps = {
    id: orgsticky.id,
  }
  updateEditSticky(
    new Sticky(nextStickyProps === null ? orgsticky.json() : nextStickyProps),
    new Sticky(diffUpdateDateProps),
  )
  // 金額の差分をクリアする
  storePayments(originalSticky.id, [])

  // 画面上の処理。リクエストした結果付箋が消えることがあるのでそれを処理する

  // isDateMoved の時はlocationの位置にいる付箋は要らないのでわらから取り除く
  isDateMoved && deleteSticky(location)

  // 前回作成された集金付箋を削除する。
  putPayments.forEach(payment => {
    if (
      payment.paymentType === 'accounts' &&
      (payment.data.childStickyId || -1) !== -1 &&
      !payment.data.childCollected
    ) {
      const paymentStickyId = payment.data.childStickyId
      deleteStickyById(paymentStickyId)
      requestDeleteSticky(paymentStickyId, accessToken, env)
        .then(() => {})
        .catch(err => {
          console.log(err)
        })
    }
  })

  removingCollectStickyIds.forEach(removingCollectStickyId =>
    deleteStickyById(removingCollectStickyId),
  )

  // SSまたは確定作業日時が変更された時は付箋を移動する
  if (
    location.regionIndex !== -1 &&
    (stagedSticky._props.staffId === -1 ||
      (stagedSticky._props.determinedDateTime &&
        stagedSticky._props.determinedDateTime.date === ''))
  ) {
    // get current location of target sticky
    const locationSrc = findStickyById(wara, stagedSticky.id)
    const locationDest = {
      regionIndex: -1,
      listIndex: -1,
      stickyIndex: wara.dock.stickies.length,
    }
    assignSticky(locationSrc.location, locationDest, displayDate)
  } else if (
    stagedSticky._props.staffId ||
    (stagedSticky._props.determinedDateTime &&
      stagedSticky._props.determinedDateTime.date)
  ) {
    // SSまたは確定作業日時が設定されたとき、付箋をわらに移動する
    const orgStickyProp =
      nextStickyProps === null ? orgsticky.json() : nextStickyProps
    // 差分または元付箋の確定作業日時
    const moveToDate =
      (
        stagedSticky._props.determinedDateTime ||
        nextStickyProps.determinedDateTime ||
        {}
      ).date || ''
    // SSが変更されていない時は元付箋のSSを使う。
    const staffId = parseInt(
      stagedSticky._props.staffId || orgStickyProp.staffId,
    )
    if (moveToDate === displayDate) {
      // 移動先の担当者が所属するリストを検索する。
      const staffLocation = getStaffList(wara, staffId)
      if (staffLocation.regionIndex !== -1 && staffLocation.listIndex !== -1) {
        deleteStickyById(stagedSticky.id)
        const nextSticky = new Sticky(
          nextStickyProps === null ? orgsticky.json() : nextStickyProps,
        )
        addSticky(
          {
            ...staffLocation,
            stickyIndex: staffLocation.stickyIndex + 1,
          },
          nextSticky,
        )
      }
    }
  }

  callback()
  // 更新成功のメッセージ
  setPutStickyStatus('success')
  resetPutStickyStatus()
  // モーダルを閉じないようにする。
  // closeModal()
}
