import { darken } from 'polished'

export const black = '#2c302e'
export const white = '#fff'
export const bgGray = '#f6f6f7'
export const bgPink = '#ffdfe2'
export const borderGray = '#e8e9ec'
export const borderDarkGray = darken(0.15, bgGray)
export const gray = '#747a77'
export const green = '#37c5ab' // rgb(55, 197, 171)
export const darkGreen = darken(0.15, green)
export const blue = '#3699C4'
export const lightGreen = '#d5ead8'
export const lightBlue = '#d3edfb'
export const red = '#ff4f3d' // rgb(255, 79, 61)
export const darkRed = darken(0.15, red)
export const deletedInput = 'lightgrey'
// 付箋の色
export const stickyRed = '#ffc7cc'
export const stickyYellow = '#f9f2b3'
export const stickyGray = gray
export const stickyScarred = '#ff5755'
export const stickyPhoneCall = '#faeaec'
// フォントカラー
export const textColorA = black
export const textColorB = '#a0a7a5'
export const linkColorA = borderDarkGray
// 優先度の色
export const priorityStatusColor1 = 'red'
export const priorityStatusColor2 = 'hotpink'
export const priorityStatusColor3 = 'gold'
export const priorityStatusColor4 = 'lightblue'
// 付箋編集画面の背景色
export const stickyEsmile = '#edfaf1'
export const stickyHouseLabo = '#e3eeff'
export const stickyQireie = '#ffffff'
export const stickyServe = stickyEsmile // ES提携: ESと同じ
// ベースカラー
export const baseColor = '#1b95bf' // #1b95bf / RGB(27,149,191) / コーポレートカラー
export const darkBaseColor = '#198bb2' // HSV に変換して明度を 5% 落として RGB に戻した値
