import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { create as createEnvAction } from 'src/reducers/env'

export const Button = styled.button`
  background-color: red;
  color: white;
  font-weight: bold;
`
export const Wrap = styled.div`
  margin: 30px;
  text-align: center;
`

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
const mapStateTopProps = state => {
  return {
    isDebugMode: state.env.DEBUG,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    toggleDebugMode: value => dispatch(createEnvAction.update('DEBUG', value)),
  }
}

export class DebugToggleButton extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // stateProps
    isDebugMode: PropTypes.bool.isRequired,
    // dispatchProps
    toggleDebugMode: PropTypes.func.isRequired,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate() {
    return true
  }

  onToggleClick = () => this.props.toggleDebugMode(!this.props.isDebugMode)

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { isDebugMode } = this.props
    const message = isDebugMode
      ? 'デバッグモードを解除'
      : 'デバッグモードにする'
    return (
      <Wrap>
        <Button onClick={ this.onToggleClick }>{message}</Button>
      </Wrap>
    )
  }
}

export default connect(mapStateTopProps, mapDispatchToProps)(DebugToggleButton)
