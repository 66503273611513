import utils from 'src/lib/api-utils'

export const putSettlement = (
  { from, to, advanceValue, staffId, settled },
  accessToken,
  env,
) => {
  const endpoint = `${utils.createEndpoint(env, 'settlement')}`

  const request = fetch(endpoint, {
    method: 'PUT',
    headers: { Authorization: `Bearer ${accessToken}` },
    body: JSON.stringify({
      billingStart: from,
      billingEnd: to,
      staffId,
      advanceValue,
      settled,
    }),
  })

  return request
}

export default putSettlement
