import update from 'immutability-helper'

/**
 * タイプ定義
 * @type {string}
 */
const type = 'WARA.UPDATE_DISSOCIATED_PREFS_FILTER'

/**
 * exportation
 */
export default {
  /**
   * action type
   * @type {string}
   */
  type,

  /**
   * action creator
   * @param  {Array<number>}  prefsFilter 新しいフィルタ配列
   * @return {object} redux action
   */
  creator: prefsFilter => {
    return {
      type,
      payload: { prefsFilter },
    }
  },

  /**
   * reducer handler
   * @param  {object}  state  wara state
   * @param  {object} action  action
   * @return {object}         new state
   */
  handler: (state, action) => {
    const { prefsFilter } = action.payload || {}

    return update(state, {
      dissociatedPrefsFilter: { $set: prefsFilter },
    })
  },
}
