import utils from 'src/lib/api-utils'
import { CALL_API } from 'redux-api-middleware'
import { NETWORKER } from 'src/middlewares/network-monitor'

import switz from 'switz'
import { ACTION_TYPES as NETWORK_ACTION_TYPES } from 'src/reducers/network-status'

export const STICKY_DELETE_REQUEST = 'STICKY_DELETE_REQUEST'
export const STICKY_DELETE_SUCCESS = 'STICKY_DELETE_SUCCESS'
export const STICKY_DELETE_FAILURE = 'STICKY_DELETE_FAILURE'

/**
 * action type name bulk export
 * @type {object}
 */
export const ACTION_TYPES = {
  STICKY_DELETE_REQUEST,
  STICKY_DELETE_SUCCESS,
  STICKY_DELETE_FAILURE,
}

/**
 * [creator description]
 * @param  {number}  id           [description]
 * @param  {string}  accessToken  [description]
 * @return {object} redux-api-middleware に渡す非同期処理情報を内包したアクション
 */
export const creator = (id, accessToken, env) => {
  const endpoint = `${utils.createEndpoint(env, 'sticky')}/${id}`

  // eslint-disable-next-line no-console
  !__TEST__ && console.log('deleteSticky', endpoint)

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      types: [
        {
          type: STICKY_DELETE_REQUEST,
          meta: {
            [NETWORKER]: {
              target: 'deleteSticky',
              networkActionType: NETWORK_ACTION_TYPES.REQUEST,
            },
          },
        },
        {
          type: STICKY_DELETE_SUCCESS,
          meta: {
            [NETWORKER]: {
              target: 'deleteSticky',
              networkActionType: NETWORK_ACTION_TYPES.SUCCESS,
            },
          },
        },
        {
          type: STICKY_DELETE_FAILURE,
          meta: {
            [NETWORKER]: {
              target: 'deleteSticky',
              networkActionType: NETWORK_ACTION_TYPES.FAILURE,
            },
          },
        },
      ],
    },
  }
}

/**
 * [AsyncWaraReducers description]
 * @param  {object} state   state
 * @param  {object} action action
 * @return {object}         reducer
 */
export const partialReducer = (state, action) => {
  const { type } = action

  return switz(type, s =>
    s
      // これらのリクエストの成否はネットワーク状態を更新するだけ。ネットワークの状態更新はmiddlewareで行う
      .case(STICKY_DELETE_REQUEST, () => state)
      .case(STICKY_DELETE_SUCCESS, () => state)
      .case(STICKY_DELETE_FAILURE, () => state)
      .default(() => state),
  )
}
