/**
 * サービススタッフに対応して付箋のアサインなどを行うUIを提供します。
 * @file
 */

/**
 * ライブラリ
 */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

/**
 * React以外のライブラリ
 */
import classNames from 'classnames'
import { findListFromWara } from 'src/lib/search'
import { today } from 'src/lib/moment'
import config from 'src/config'
import moment from 'moment'

/**
 * コンポーネント
 */
import Assignable from './partials/assignable'
import Money from './partials/money'
import AllCases from './partials/all-cases'
import PaymentDelay from './partials/payment-delay'
import Hide from './partials/hide'
import Notice from './partials/notice'
import SSName from './partials/ss-name'
import TimesAndWorkStart from './partials/time-and-work-start'
import Stickies from './partials/stickies'
import StickyEmpty from '../sticky-empty/container'
import AddNoopButton from './partials/add-noop-button'

import BadgeLateShift from 'src/styles/assets/badge-late-shift.png'
import BadgeDayOff from 'src/styles/assets/badge-day-off.png'
import BadgePaidLeave from 'src/styles/assets/badge-paid-leave.png'
import BadgeWorkingHoliday from 'src/styles/assets/badge-holiday-work.png'
import BadgeAbsence from 'src/styles/assets/badge-absent.png'
import BadgeHalfDayOff from 'src/styles/assets/badge-half-day.png'
import BadgeSpecialLeave from 'src/styles/assets/badge-special-holiday.png'

const List = props => {
  const {
    location,
    showAllStickies,
    ssWeeklySales, // SSの週次売上
    ssWorkStatus, // SSの稼働状況
    getSSworkList, // SSの稼働状況更新
    staffInfo, // SSの状態
    putStaffInfo, // SSの情報更新
    wara,
    isDebugMode,
    updateTheDailyInfo,
  } = props

  const [workState, setWorkState] = useState(
    ssWorkStatus.workState || config.ssworkState.workCancel,
  )

  useEffect(() => {
    if (ssWorkStatus.workState !== workState) {
      setWorkState(ssWorkStatus.workState)
    }
  }, [ssWorkStatus.workState])

  /**
   * SSの情報を更新する
   * @param {*} ssProps // 旧APIのSS情報差分
   * @param {*} ssInfo // staffInfo APIで使うSS情報の差分
   */
  const updateStaffInfo = (ssProps, ssInfo = null) => {
    // staffIdを取得
    const list = findListFromWara(wara, location) || {}
    const staffId = list.staffId || -1

    if (ssProps) {
      updateTheDailyInfo(ssProps)
    }
    if (ssInfo) {
      putStaffInfo(staffInfo, { ...ssInfo, staffId })
    }
  }

  const list = findListFromWara(wara, location) || {}

  const {
    dailyInfo,
    staffName,
    regionId,
    staffId,
    stickies,
    // freeDescribe, // NOTE: このプロパティもAPIから使えるが、使っていない
  } = list

  const {
    id: dailyInfoId, // dailyInfoのID
    date,
    lastFinishedTime, // TimeString?
    startTime, // TimeString?,
    workStarted: _workStarted, // この日の業務を開始しているか
    workFinished: _workFinished, // この日の業務を終了したか
    isAssignable, // ノルマ達成状況
    isPaymentDelay, // 支払い遅れ
    workKindId, // 遅番とか
  } = dailyInfo || {}

  const {
    cityName, // 市町村名のテキストボックス
    notice, // 特記事項
    money, // 33,001円
    allCases, // 全件
  } = staffInfo
  const { regionIndex, listIndex } = location

  const workStarted = !!_workStarted
  const workFinished = !!_workFinished

  /**
   * ラッパーエレメントのクラス属性定義
   * @type {string}
   */
  const classes = classNames({
    list: true,
    clearfix: true,
    // 休日種別
    'ss-day-off':
      (workKindId === config.workKind.dayOff ||
        workKindId === config.workKind.paidLeave ||
        workKindId === config.workKind.absence ||
        workKindId === config.workKind.halfDayOff ||
        workKindId === config.workKind.specialLeave) &&
      !isAssignable,
    'ss-late-shift': workKindId === config.workKind.lateShift,
    'ss-badged': workKindId !== config.workKind.dayShift,
    // 'ss-unachieved'  : isAssignable !== true,
    // 'ss-achieved'    : isAssignable === true,
    'list-assignable': isAssignable,
    'list-work-finished': workFinished,
    'list-paymentdelay': isPaymentDelay,
  })

  const badgeImageMap = {
    [config.workKind.lateShift]: BadgeLateShift,
    [config.workKind.dayOff]: BadgeDayOff,
    [config.workKind.paidLeave]: BadgePaidLeave,
    [config.workKind.workingHoliday]: BadgeWorkingHoliday,
    [config.workKind.absence]: BadgeAbsence,
    [config.workKind.halfDayOff]: BadgeHalfDayOff,
    [config.workKind.specialLeave]: BadgeSpecialLeave,
  }
  const badgeImage = badgeImageMap[workKindId]

  const style = {}

  /**
   * 完了予定時刻を表示
   */
  const finishTime = () => {
    /**
     * 最新の完了予定時刻を取得
     */
    let completeTime = null
    stickies.forEach(sticky => {
      const completeEstimateTime = sticky._props.completeEstimateTime || null
      if (completeEstimateTime !== null && completeEstimateTime !== '') {
        const ctime = moment(completeEstimateTime)
        if (ctime.isValid) {
          if (completeTime === null || ctime > completeTime) {
            completeTime = ctime
          }
        }
      }
    })
    const finishStyle = {
      fontSize: '1rem',
      display: 'flex',
      justifyContent: 'center',
    }
    const redText = {
      color: 'red',
    }
    return completeTime ? (
      <div style={ finishStyle }>
        <span>{'完了予定: '}</span>
        <span style={ redText }>{completeTime.format('HH:mm')}</span>
      </div>
    ) : null
  }

  return (
    <li className={ classes } style={ style }>
      <div className={ 'list-propaties' }>
        <div
          className={ 'list-check' }
          style={ { backgroundImage: `url(${badgeImage})` } }
        >
          <Money hasMoney={ money } updateTheDailyInfo={ updateStaffInfo } />
          <AllCases
            hasAllCases={ allCases }
            updateTheDailyInfo={ updateStaffInfo }
          />
          <Assignable
            isAssignable={ isAssignable }
            updateTheDailyInfo={ updateTheDailyInfo }
          />

          <PaymentDelay
            isPaymentDelay={ isPaymentDelay }
            workStarted={ workStarted }
            updateTheDailyInfo={ updateTheDailyInfo }
          />

          <Hide regionId={ regionId } staffId={ staffId } />
        </div>

        <SSName
          className={ 'name list-name ss-name' }
          name={ staffName }
          location={ location }
          staffId={ staffId }
          freeDescribe={ cityName || '' }
          updateTheDailyInfo={ updateStaffInfo }
          regionIndex={ regionIndex }
          listIndex={ listIndex }
          ssWeeklySales={ ssWeeklySales }
          ssWorkStatus={ ssWorkStatus }
          date={ date || today() }
          lastFinishedTime={ lastFinishedTime || '' }
          startTime={ startTime || '' }
        />
        <div
          style={ {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            borderRight: '1px dotted #ceced3',
          } }
        >
          <Notice
            notice={ notice }
            updateTheDailyInfo={ updateStaffInfo }
            location={ location }
          />

          <TimesAndWorkStart
            staffId={ staffId }
            date={ date || today() }
            updateTheDailyInfo={ updateTheDailyInfo }
            lastFinishedTime={ lastFinishedTime || '' }
            startTime={ startTime || '' }
            dailyInfoId={ dailyInfoId || -1 }
            ssWorkStatus={ ssWorkStatus }
            getSSworkList={ getSSworkList }
            setWorkState={ setWorkState }
          />
          {finishTime()}
        </div>
      </div>

      <Stickies
        location={ location }
        showAllStickies={ showAllStickies }
        getSSworkList={ getSSworkList }
        workState={ workState }
      />
      <div className={ 'list-stickies' }>
        <StickyEmpty location={ location } isAssignable={ isAssignable } />
      </div>

      <AddNoopButton
        locationDest={ { ...location, stickyIndex: stickies.length } }
        regionId={ regionId }
        staffId={ staffId }
      />

      {isDebugMode && [
        <button
          key={ 0 }
          /* eslint-disable react/jsx-no-bind */
          onClick={ () =>
            // eslint-disable-next-line no-console
            console.log(
              { regionIndex, listIndex, list },
              JSON.stringify({ regionIndex, listIndex, list }),
            )
          }
          /* eslint-enable react/jsx-no-bind */
        >
          {'dump'}
        </button>,
        <span key={ 1 }>{`index: ${listIndex}`}</span>,
      ]}
    </li>
  )
}

/**
 * Validation
 * @type {object}
 */
List.propTypes = {
  // ownProps
  location: PropTypes.shape({
    regionIndex: PropTypes.number.isRequired,
    listIndex: PropTypes.number.isRequired,
  }).isRequired,
  showAllStickies: PropTypes.bool.isRequired,
  ssWeeklySales: PropTypes.object.isRequired, // SSの週次売上
  ssWorkStatus: PropTypes.object.isRequired, // SSの稼働状況
  getSSworkList: PropTypes.func.isRequired, // SSの稼働状況更新
  staffInfo: PropTypes.object.isRequired, // SSの状態
  putStaffInfo: PropTypes.func.isRequired, // SSの情報更新
  // stateProps
  wara: PropTypes.shape({ regions: PropTypes.array }).isRequired,
  isDebugMode: PropTypes.bool,
  // dispatchProps
  updateTheDailyInfo: PropTypes.func.isRequired,
}

/**
 * defaultProps
 * @type {object}
 */
List.defaultProps = {
  display: true,
  isDebugMode: false,
}

export default React.memo(List)
