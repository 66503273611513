import update from 'immutability-helper'
import switz from 'switz'

export const initialState = { store: {}, data: [], network: 'NOT_YET' }

const UPDATE = 'PAYMENTS.UPDATE'
const UPDATE_ALL = 'PAYMENTS.UPDATE_ALL'
const ADD = 'PAYMENTS.ADD'
const DELETE = 'PAYMENTS.DELETE'
const STORE = 'PAYMENTS.STORE'
const CLEAR = 'PAYMENTS.CLEAR'
const UPDATE_NETWORK = 'PAYMENTS.UPDATE_NETWORK'

/**
 * action type name bulk export
 * @type {object}
 */
export const ACTION_TYPES = { UPDATE, ADD, DELETE, STORE, CLEAR }

export const actionCreators = {
  addPayment: (data, meta) => ({
    type: ADD,
    payload: { data, meta, paymentType: 'payments' },
  }),
  addAccount: (data, meta) => ({
    type: ADD,
    payload: { data, meta, paymentType: 'accounts' },
  }),
  updatePayment: (data, index) => ({
    type: UPDATE,
    payload: { data, paymentType: 'payments', index },
  }),
  updatePayments: payments => ({
    type: UPDATE_ALL,
    payload: { payments },
  }),
  updateAccount: (data, index) => ({
    type: UPDATE,
    payload: { data, paymentType: 'accounts', index },
  }),
  deletePayment: index => ({
    type: DELETE,
    payload: { index, paymentType: 'payments' },
  }),
  deleteAccount: index => ({
    type: DELETE,
    payload: { index, paymentType: 'accounts' },
  }),
  store: (stickyId, allPayments) => ({
    type: STORE,
    payload: { stickyId, allPayments },
  }),
  clear: () => ({ type: CLEAR, payload: {} }),
  updateNetwork: slug => ({ type: UPDATE_NETWORK, payload: { slug } }),
}

/**
 * [AsyncWaraReducers description]
 * @param  {object} state   state
 * @param  {object} action action
 * @return {object}         reducer
 */
export const partialReducer = (state = initialState, action) => {
  const { type } = action
  return switz(type, s =>
    s
      .case(ADD, () => {
        const {
          payload: { data, paymentType, meta },
        } = action
        return update(state, { data: { $push: [{ data, paymentType, meta }] } })
      })
      .case(UPDATE, () => {
        const {
          payload: { data, index },
        } = action
        const oldData = state.data[index].data
        return update(state, {
          data: { [index]: { data: { $set: { ...oldData, ...data } } } },
        })
      })
      .case(UPDATE_ALL, () => {
        const { payments } = action.payload
        return update(state, {
          data: { $set: payments },
        })
      })
      .case(DELETE, () => {
        const {
          payload: { index },
        } = action
        return update(state, {
          data: { [index]: { deleted: { $set: true } } },
        })
      })
      .case(STORE, () => {
        const { stickyId, allPayments } = action.payload
        return update(state, { store: { [stickyId]: { $set: allPayments } } })
      })
      .case(CLEAR, () =>
        update(state, { data: { $set: [] }, store: { $set: {} } }),
      )
      .case(UPDATE_NETWORK, () =>
        update(state, { network: { $set: action.payload.slug } }),
      )
      .default(() => state),
  )
}

export default partialReducer
