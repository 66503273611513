import utils from 'src/lib/api-utils'

export const get = (stickyId, accessToken, env) => {
  const base = utils.createEndpoint(env, 'contractAmount')
  const endpoint = `${base}/${stickyId}`

  return fetch(endpoint, {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then(res => res.json())
}

export default get
