import { DropTarget } from 'react-dnd'
import { drop, canDrop } from './dnd-helpers/sticky'

// プロパティが変わったことを検出する。
const arePropsEqual = (props, otherProps) => {
  return (
    props.location.regionIndex === otherProps.regionIndex &&
    props.location.listIndex === otherProps.listIndex &&
    props.location.stickyIndex === otherProps.location.stickyIndex &&
    props.isAssignable === otherProps.isAssignable &&
    props.workState === otherProps.workState
  )
}

/**
 * DropTarget in React-DnD
 * @type {Decorator}
 */
export default Component =>
  DropTarget(
    // type
    'sticky',
    // spec
    { drop, canDrop },
    // collect
    (connect, monitor) => ({
      connectDropTarget: connect.dropTarget(),
      isOver: monitor.isOver({ shallow: true }),
    }),
    // options: プロパティの変化検出をカスタマイズ
    { arePropsEqual },
  )(Component)
