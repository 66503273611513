import utils from 'src/lib/api-utils'
import { qsjoin } from 'src/lib/format'

/**
 * telephone number search action creator
 * @param  {object} query       search with
 * @param  {string} accessToken access token
 * @return {Promise}            promised fetch
 */
export const search = (query, accessToken, env) => {
  const canonicalQuery = {
    ...query,
    phoneNo:
      query.phoneNo && typeof query.phoneNo === 'string'
        ? query.phoneNo.split('-').join('')
        : void 0,
  }
  const base = `${utils.createEndpoint(env, 'search')}`
  const endpoint = `${base}?${qsjoin(canonicalQuery)}`

  return fetch(endpoint, {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then(res => {
    if (res.ok) {
      return res.json()
    } else {
      throw res
    }
  })
}
