/**
 * ログイン時に子要素隠蔽するためのコンポーネントです
 * @file {Component}
 */

/**
 * ライブラリ
 */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NETWORK_STATUSES } from 'src/reducers/network-status'

/**
 * map state to props
 * @param  {object} state state
 * @return {object}       mapped state as props
 */
const mapStateToProps = state => {
  const isLoggedIn = state.networkStatus.login === NETWORK_STATUSES.SUCCESS
  return {
    invisible: !isLoggedIn,
  }
}

/**
 * Authorizeなものを表示するコンポーネント
 * @type {ReactComponent}
 */
export class Unauthorize extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    invisible: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    children: null,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate() {
    return true
  }

  /**
   * render
   * @return {ReactDOM} rendered result
   */
  render() {
    return !this.props.invisible ? (
      <span className={ 'is-not-visible' } />
    ) : (
      <span className={ 'is-visible' }>{this.props.children}</span>
    )
  }
}

export default connect(mapStateToProps)(Unauthorize)
