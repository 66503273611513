import React from 'react'
import PropTypes from 'prop-types'
import connect from '../../../connect'
import update from 'immutability-helper'
import Sticky from 'src/lib/class-sticky'
import findContact from '../../lib/find-contact'
import { contactInputStyle } from 'src/lib/format'

/**
 * ふりがなを入力します
 * @type {ReactComponent}
 */
export class Building extends React.Component {
  /**
   * 値のバリデーションを実行
   * @param  {any}  value 判定する値
   * @return {boolean}    判定結果
   */
  static isValid() {
    return true
  }

  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    tabIndex: PropTypes.number.isRequired,
    contactsIndex: PropTypes.number.isRequired,
    route: PropTypes.oneOf(['call', 'assign', 'history']).isRequired,
    disabled: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
    tabOrder: PropTypes.number,
    isDelete: PropTypes.bool,
    // dispatchProps
    updateStage: PropTypes.func.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    tabOrder: 0,
    isDelete: false,
  }

  state = { onEditing: false }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.onEditing !== this.state.onEditing ||
      nextProps.sticky.json().contacts !== this.props.sticky.json().contacts ||
      this.props.disabled !== nextProps.disabled ||
      this.props.readOnly !== nextProps.readOnly
    )
  }

  /**
   * フォーカスした時に編集開始。（初期値をコピーしてBlurまでstateで管理する）
   * フォームにフォーカスした際にエラー表示を消す
   * @return {void}
   */
  onFocus = () => {
    const { sticky, tabIndex, contactsIndex } = this.props
    const stickyProps = sticky.json()

    const contact =
      ((stickyProps.contacts || [])[tabIndex] || [])[contactsIndex] || {}

    this.setState(
      update(this.state, {
        onEditing: { $set: contact.buildingName || '' },
      }),
    )
  }

  /**
   * 建物名を変更した時にstateの値を変更してフォームに反映する
   * @param  {Event} e イベントハンドラです
   * @return {void}
   */
  onChange = e => {
    const value = e.target.value
    this.setState(
      update(this.state, {
        onEditing: { $set: value },
      }),
    )
  }

  /**
   * フォームを離れた時にバリデーションをかける
   * @return {void}
   */
  onBlur = () => {
    // バリデーション。成功したときだけ、ステージング値をアップデートする
    const buildingName = this.state.onEditing
    if (Building.isValid(buildingName)) {
      const { sticky, tabIndex, contactsIndex, updateStage } = this.props
      const id = sticky.getContactId(tabIndex, contactsIndex)
      const existsClient = sticky.existsClient()

      const diffProps = {
        contacts: {
          [tabIndex]: {
            [contactsIndex]: existsClient
              ? { id, buildingName }
              : { id, buildingName, isClient: true },
          },
        },
      }
      updateStage(new Sticky(diffProps))
    }

    // 編集中の値を消す
    this.setState(
      update(this.state, {
        onEditing: { $set: false },
      }),
    )
  }

  /**
   * 値の正規化を実施
   * @param  {string} value ひらがなを含む
   * @return {string}       カタカナのみ
   */
  normalize = value =>
    value.replace(/[\u3040-\u3096F]/g, match =>
      String.fromCharCode(match.charCodeAt(0) + 0x60),
    )

  get groupName() {
    return `contact-building-name-text-${this.props.tabIndex}-${this.props.contactsIndex}-${this.props.route}`
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { onEditing } = this.state

    const {
      sticky,
      tabIndex,
      contactsIndex,
      disabled,
      readOnly,
      tabOrder,
      isDelete,
    } = this.props
    const contact = findContact(sticky, tabIndex, contactsIndex) || {}
    const buildingName = contact.buildingName || ''

    const dispayBuilding = onEditing === false ? buildingName : onEditing
    const identifier = `id-${this.groupName}`

    return (
      <dl className={ 'label-inputs-wrap' }>
        <dt>
          <label htmlFor={ identifier }>{'建物名'}</label>
        </dt>

        <dd>
          <textarea
            rows="3"
            value={ dispayBuilding }
            id={ identifier }
            name={ this.groupName }
            type={ 'text' }
            onFocus={ this.onFocus }
            onChange={ this.onChange }
            onBlur={ this.onBlur }
            disabled={ disabled }
            readOnly={ readOnly }
            style={ contactInputStyle({ disabled, readOnly, isDelete }) }
            tabIndex={ tabOrder }
          />
        </dd>
      </dl>
    )
  }
}

export default connect(Building)
