import { DragSource } from 'react-dnd'
import { beginDrag, canDrag } from './dnd-helpers/sticky'

// プロパティが変わったことを検出する。
const arePropsEqual = (props, otherProps) => {
  return (
    props.location.regionIndex === otherProps.location.regionIndex &&
    props.location.listIndex === otherProps.location.listIndex &&
    props.location.stickyIndex === otherProps.location.stickyIndex &&
    props.isAssignable === otherProps.isAssignable &&
    props.stickyStatusId === otherProps.stickyStatusId &&
    props.finishStateId === otherProps.finishStateId &&
    props.workState === otherProps.workState &&
    props.dockStickiesLoaded === otherProps.dockStickiesLoaded &&
    props.sticky === otherProps.sticky
  )
}

/**
 * DragSource in React-DnD
 * @type {Decorator}
 */
export default Component =>
  DragSource(
    // type
    'sticky',
    // spec
    { beginDrag, canDrag },
    /**
     * collect
     * @param  {connect} connect [description]
     * @param  {monitor} monitor [description]
     * @return {object}         [description]
     */
    (connect, monitor) => ({
      // Call this function inside render()
      // to let React DnD handle the drag events:
      connectDragSource: connect.dragSource(),
      // You can ask the monitor about the current drag state:
      isDragging: monitor.isDragging(),
      dragIndexes: monitor.getItem(),
    }),
    // options: プロパティの変化検出をカスタマイズ
    { arePropsEqual },
  )(Component)
