// react
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// lib
import Sticky from 'src/lib/class-sticky'
import { baseColor } from 'src/colors'
import styled from 'styled-components'
import config from 'src/config'
import { hlWorkTypeLabel } from 'src/lib/format'

/**
 * map state to props
 * @param  {object} state mapping state
 * @return {object}       mapped props
 */
const mapStateToProps = state => {
  return {
    accessToken: state.login.authentication.accessToken,
    env: state.env,
  }
}

const HlTukanStatsWrap = styled.span`
  color: white;
  font-weight: bold;
  background-color: ${baseColor};
  border-radius: 4px;
  font-size: 14px;
  padding: 5px 8px;
  margin-left: 10px;
`

/**
 * 付箋詳細ヘッダにハウスラボの通貫状態を表示する。
 * @type {Object}
 */
export class HlTukanStats extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    sticky: PropTypes.instanceOf(Sticky).isRequired,
  }

  constructor(props) {
    super(props)
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    const thisStickyProps = this.props.sticky.json() || {}
    const thisHouseLabo = thisStickyProps.houseLabo || {}
    const nextStickyProps = nextProps.sticky.json() || {}
    const nextHouseLabo = nextStickyProps.houseLabo || {}
    return (
      thisHouseLabo.twoPersonWorkTypeId !== nextHouseLabo.twoPersonWorkTypeId
    )
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { sticky } = this.props
    const stickyProps = sticky.json() || {}
    const houseLabo = stickyProps.houseLabo || {}
    // ハウスラボ二人工付箋の種類
    const twoPersonWorkTypeId =
      houseLabo.twoPersonWorkTypeId || config.houseLaboTwoWorkType.normal
    const hlWorkType = hlWorkTypeLabel(twoPersonWorkTypeId)

    return (
      hlWorkType.display && (
        <HlTukanStatsWrap>{hlWorkType.label}</HlTukanStatsWrap>
      )
    )
  }
}

export default connect(mapStateToProps)(HlTukanStats)
