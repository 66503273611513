import createClassName from 'classnames'
import config from 'src/config'

/**
 * 付箋表面の見た目を決めるクラスを吐き出す
 * @param  {Sticky} sticky           付箋オブジェクト
 * @param  {object} additional       追加のクラス
 * @param  {object} orderRanksMaster ランク付け表
 * @return {string}                  クラス名
 */
export default (sticky, additional, orderRanksMaster) => {
  const stickyProps = sticky.json()
  const priorityLabelId = stickyProps.priorityLabelId

  const workTypeIds = stickyProps.workType ? stickyProps.workType.ids || [] : []
  const workPointIds = stickyProps.workPoint
    ? stickyProps.workPoint.ids || []
    : []
  const buildingTypeId = stickyProps.buildingType
    ? (stickyProps.buildingType.ids || [])[0]
    : void 0
  const customerTypeId = stickyProps.customerTypeId

  // NOTE: priority.jsxとロジックの重複がある
  const decidedPriorityLabelIds = []
  if (priorityLabelId === null || priorityLabelId === -1) {
    [-1].concat(workTypeIds).forEach(workTypeId => {
      [-1].concat(workPointIds).forEach(workPointId => {
        // 簡便にtry-catch
        try {
          decidedPriorityLabelIds.push(
            orderRanksMaster[buildingTypeId][customerTypeId][workTypeId][
              workPointId
            ] || 99,
          )
        } catch (e) {
          decidedPriorityLabelIds.push(99)
        }
      })
    })
  } else {
    decidedPriorityLabelIds.push(priorityLabelId)
  }
  const decidedPriorityLabelId = Math.min(...decidedPriorityLabelIds)

  /**
   * ラッパーエレメントのクラス属性定義
   * @type {string}
   */
  const classes = createClassName({
    sticky: true,
    finished: stickyProps.finishStateId === config.finishState.complete, // is-completed
    // clicker
    'is-split': stickyProps.stickyStatusId === config.stickyStatus.split,
    'sticky-private': stickyProps.stickyTypeId === config.stickyType.private,
    'sticky-memo': stickyProps.stickyTypeId === config.stickyType.memo,
    [`has-priority-status-${decidedPriorityLabelId}`]:
      decidedPriorityLabelId > 0,
    ...additional,
  })
  return classes
}
