import moment from 'moment'
import { bgPink, deletedInput, lightGreen } from 'src/colors.js'
import config from 'src/config'

/**
 * 数字を日本円表記に変換します
 * @param {number} yen 入力値
 * @return {string} フォーマットされた日本円
 */
export const toJPYenText = (yen, yenMark = true) => {
  if (yen === null || yen === void 0) {
    return ''
  }
  const formattedYenText = yenMark
    ? '¥' + yen.toLocaleString('ja-JP-u-ca-japanese')
    : yen.toLocaleString('ja-JP-u-ca-japanese')
  return formattedYenText
}

/**
 * 日本円表記の文字列を数値に変換する
 * @param {*} value
 */
export const yenToValue = value => {
  // 数値が渡された時はそのまま返す
  if (typeof value === 'number') {
    return value
  }
  // ￥とカンマを除去し、数値変換する
  return parseInt(value.replace(/[¥,]/g, ''), 10)
}

/**
 * 日付を%YYYY-%MM-%DDT%HH:%mm:%ssZ09:00にフォーマットします
 * @param  {string} date YYYY-MM-DD
 * @param  {string} time HH:mm
 * @return {string}      %YYYY-%MM-%DDT%HH:%mm:%ssZ09:00
 */
export const toJTC = (date, time) => {
  if (date || date === '') {
    date = moment().format('YYYY-MM-DD')
  }
  const formattedDate = zerofillDate(date)
  return `${formattedDate}T${time}:00+09:00`
}

/**
 * 郵便番号が「-」なしのものの場合
 * 郵便番号を「-」付きのものに変換します
 * 入力が、「-」ありのものの場合そのままにします
 * @param {string} zip [description]
 * @return {string} フォーマットされた郵便番号
 */
export const formatZip = zip => (/^(\d{7})$/.test(zip) ? `${zip.slice(0, 3)}-${zip.slice(-4)}` : zip)

export const normalizeZip = zip => zip.replace('-', '')

/**
 * isomorphic btoa
 * @param  {string} str decoding base64 formatted text
 * @return {string}     decoded text
 */
export const isomorphicAtoB = str => {
  if (window) {
    // browser
    return window.atob(str)
  } else if (Buffer) {
    // Node
    return Buffer.from(str, 'base64').toString('binary')
  }
}

/**
 * join hash query into querystring
 * @param  {object} query query
 * @return {string}       querystring
 */
export const qsjoin = query => {
  return Object.keys(query)
    .filter(key => !!query[key] && query[key] !== '')
    .map(key => [key, query[key]].map(encodeURIComponent).join('='))
    .join('&')
}

export const qsparse = qs => {
  if (qs[0] !== '?') {
    return {}
  } else {
    return qs
      .substr(1, qs.length)
      .split('&')
      .reduce((prev, kv) => {
        const [key, value] = kv.split('=')
        prev[key] = decodeURIComponent(value)
        return prev
      }, {})
  }
}

/**
 * [formatMaster description]
 * @param  {Array<{id:number,name:string,displayIndex:number}>} payload input
 * @return {Array<{id:number,name:string}>}                             output
 */
export const formatMaster = payload => {
  const formatting = payload ? [].concat(payload) : []
  formatting.sort((a = {}, b = {}) => {
    const display1 = a.displayIndex !== void 0 ? a.displayIndex : a.DisplayIndex
    const display2 = b.displayIndex !== void 0 ? b.displayIndex : b.DisplayIndex
    return display1 - display2
  })

  return formatting.map(x => {
    const result = {
      id: x.id !== void 0 ? x.id : x.Id,
      name: x.name !== void 0 ? x.name : x.Name,
      code: x.code !== void 0 ? x.code : x.Code,
    }

    const acceptablePropNames = [
      'trailingBreakline',
      'nickname',
      'regionId',
      'regionIds',
      'search',
      'display',
      'label',
      'displayIndex',
      'prefCode',
      'prefName',
      'isValid',
      'isSticky',
      'companyId',
      'isOneTime',
      'deleted',
      'emphasis',
      'roles',
    ]

    // それぞれのマスタ固有のプロパティがあれば、それを入れる
    acceptablePropNames.forEach(propName => {
      if (x[propName] !== void 0 || x[propName] !== null) {
        result[propName] = x[propName]
      }
    })

    return result
  })
}

/**
 * [zerofillDate description]
 * @param  {string} datestring YYYY-M-D
 * @return {string}            YYYY-MM-DD
 */
export const zerofillDate = datestring => {
  if (!datestring) {
    return ''
  }
  const [year, month, day] = datestring.split('-')
  return `${year}-${month.length === 1 ? '0' + month : month}-${
    day.length === 1 ? '0' + day : day
  }`
}

/**
 * [zerofillTime description]
 * @param  {string} timestring h:m
 * @return {string}            HH:mm
 */
export const zerofillTime = timestring => {
  if (!timestring) {
    return ''
  }
  const [hour, minute] = timestring.split(':')
  return `${hour.length === 1 ? '0' + hour : hour}:${
    minute.length === 1 ? '0' + minute : minute
  }`
}

/**
 * 名前(漢字・かな等)をフォーマットします
 * 前後のスペースを取り除いた後、全角スペースを半角スペースに置換し
 * 連続している半角スペースは1つにまとめます
 * @param {string|undefined} namastring
 * @return {string}
 */
export const formatName = namestring => {
  if (!namestring) {
    return ''
  }
  // NOTE: なぜか skipRegExps オプションが効かないので、一旦別変数に代入している
  // ビルド時に最適化されるのでこのまま放っておいても良い
  const ideographicSpaceRegexp = /　/g
  return namestring
    .trim()
    .replace(ideographicSpaceRegexp, ' ')
    .replace(/ +/g, ' ')
}

/**
 * [idToIndex description]
 * @param  {object} wara        wara
 * @param  {number} regionId  region Id
 * @param  {number} staffId staff Id
 * @return {{regionIndex, listIndex}}  values
 */
export const idToIndex = (wara, regionId, staffId) => {
  const regionIndex = wara.data.regions.map(x => x.id).indexOf(regionId)

  const listIndex = wara.data.regions[regionIndex].lists
    .map(x => x.staffId)
    .indexOf(staffId)

  return { regionIndex, listIndex }
}

/**
 * [divide description]
 * @param  {array} arr [description]
 * @param  {number} num [description]
 * @return {array<array>}     [description]
 */
export const divide = (arr, num) => {
  const result = []
  arr.forEach((elem, i) => {
    if (i % num === 0) {
      result[result.length] = [elem]
    } else {
      result[result.length - 1].push(elem)
    }
  })
  return result
}

/**
 * allServiceStaffs APIの結果からスタッフの一覧を作成する。
 * staffs: IDが重複したスタッフを削除
 * validStaffs: 有効なスタッフのみの一覧
 * @param {*} payload
 */
export const formatAllServiceStaffs = payload => {
  // 地域ごとに別々の配列になっているのを結合する
  const staffs = Object.values(payload).reduce(
    (prev, staffs) => [...prev, ...staffs],
    [],
  )
  // APIでは退職者を含む全スタッフを取得し、IDの重複を除去して有効なスタッフをフィルタする。
  const noDupStaffs = staffs.reduce(
    (prev, current) =>
      prev.staffId !== current.staffId
        ? {
          staffId: current.staffId,
          staffs: [...prev.staffs, current],
        }
        : prev,
    { staffId: null, staffs: [] },
  )
  const validStaffs = noDupStaffs.staffs.filter(staff => !staff.stop)
  return { staffs: noDupStaffs.staffs, validStaffs }
}

/**
 * 付箋作成・更新時、わらまたは未配置付箋へ配置されるための設定を行う。
 *
 * @param {*} sticky 付箋
 * @param {*} validStaffs 有効なSSの一覧（マスターデータを渡す）
 * @param {*} orgSticky 更新の時、元の付箋の情報を渡す
 */
export const setStickyDisplay = (
  sticky,
  validStaffs,
  orgSticky = { _props: {} },
) => {
  const determinedDateTime =
    sticky._props.determinedDateTime ||
    orgSticky._props.determinedDateTime ||
    {}
  const staffId = sticky._props.staffId || orgSticky._props.staffId
  if (sticky.isClaimerOrder || orgSticky.isClaimerOrder) {
    // マルシー付箋の表示方法を設定する。
    // 代行担当者が選択されている時は staffId が指定されている。
    // 代行担当者が未指定の場合、現担当者(parentStaffId)が有効な時はstaffIdに設定する。
    // parentStaffIdが無効のときはわらに表示されなくなるため、未配置付箋に表示させる。
    if (!staffId) {
      const marucComplaint =
        sticky._props.marucComplaint || orgSticky._props.marucComplaint || {}
      const parentStaffId = marucComplaint.parentStaffId || -1
      const staff = validStaffs.find(st => st.staffId === parentStaffId) || {}
      if (staff && staff.isValid) {
        sticky._props.staffId = parentStaffId
      }
    }

    // 割当先がある時は確定作業日時に表示する。未指定の時は未配置付箋に表示する（ただし見えない）。
    if (staffId || sticky._props.staffId) {
      if (determinedDateTime && determinedDateTime.date) {
        sticky._props.displayDate = determinedDateTime.date
      }
    }
    // マルシー付箋の対応希望日時は無効にするので、空にする。
    sticky._props.wishDateTimes = []
  } else {
    // SS指定があるとき
    if (staffId) {
      // 確定作業日時が指定されている時はわらに割り当てる。
      if (determinedDateTime && determinedDateTime.date) {
        sticky._props.displayDate = determinedDateTime.date
      }
    }
  }
}

/**
 * マルシー付箋の作成時、マルシー担当者を設定する
 * @param {*} sticky
 * @param {*} validStaffs
 */
export const setMarucStaff = (sticky, validStaffs) => {
  // 元担当者が未指定・無効な時はマルシー担当者はなし、それ以外は元担当者をマルシー担当者に設定する
  const parentStaffId = (sticky._props.marucComplaint || {}).parentStaffId || -1
  const staff = validStaffs.find(st => st.staffId === parentStaffId) || {}
  sticky._props.staffId = staff && staff.isValid ? parentStaffId : null
}

/**
 * イメージのSHA-256ハッシュを取得する
 * @param {*} base64Image
 */
export const base64sha256 = async base64Image => {
  const bytes = new Uint8Array(base64Image.length)
  for (let i = 0; i < base64Image.length; i++) {
    bytes[i] = base64Image.charCodeAt(i)
  }
  const buff = bytes.buffer
  const digest = await crypto.subtle.digest('SHA-256', buff)
  return [].map
    .call(new Uint8Array(digest), x => ('00' + x.toString(16)).slice(-2))
    .join('')
}

/**
 * 付箋の終了状態、キャンセル状態が -1 の時 null にする
 * 新規作成時のみに必要
 * @param {*} sticky
 */
export const setFinishStatus = sticky => {
  if (sticky._props.finishStateId === -1) {
    sticky._props.finishStateId = null
  }
  if (sticky._props.cancelReasonId === -1) {
    sticky._props.cancelReasonId = null
  }
}

/**
 * 連絡先の種別が更新されていた時、更新された連絡先は上書き更新する。
 * それ以外の連絡先はこの後差分とマージする。
 * @param {*} diffSticky 付箋の差分
 * @param {*} sticky オリジナルの付箋
 */
export const contactNoMerge = (diffSticky, sticky) => {
  if (diffSticky._props.contactNoMerge) {
    // fr, to の間で連絡先が移動した。
    const { fr, to } = diffSticky._props.contactNoMerge
    sticky._props.contacts[fr] = [...diffSticky._props.contacts[fr]]
    sticky._props.contacts[to] = [...diffSticky._props.contacts[to]]
    delete diffSticky._props.contactNoMerge
  }
}

/**
 * contactの順番が変わった時、エリアを自動設定する。
 * @param {*} sticky
 * @param {*} master
 * @param {*} diffProps
 */
export const changeContactArea = (sticky, master, diffProps) => {
  const stickyProps = sticky.json()
  const contacts = stickyProps.contacts || []
  //　差分・元付箋の順にcontactを探す
  const contact =
    (diffProps.contacts[0] || [])[0] || (contacts[0] || [])[0] || {}
  // contactのエリアコードを取得
  const prefCode = contact.prefCode || -1
  if (prefCode !== -1) {
    const prefInfo = (master.prefCodes || []).filter(x => x.code === prefCode)
    const nextRegionId = (prefInfo[0] || {}).regionId || null
    // エリアコードが変わる時は差分に追加する。
    if (nextRegionId && stickyProps.regionId !== nextRegionId) {
      diffProps.regionId = nextRegionId
    }
  }
}

/**
 * contactsの入力項目の表示色を設定する
 * @param {*} params 表示を制御するためのパラメータ
 */
export const contactInputStyle = params => {
  // paramsの内容：今後も変わることがあるので随時対応
  // disabled: 項目が無効
  // isDelete: 削除対象
  // readOnly: 読み出しのみ
  const { disabled, readOnly, isDelete } = params
  const style = {}
  if (!disabled) {
    if (isDelete) {
      style.background = deletedInput
    } else if (readOnly) {
      style.background = lightGreen
    }
  }
  return style
}

/**
 * contactInputStyle 都道府県、市町村の専用版
 * @param {*} params
 */
export const contactPrefInputStyle = params => {
  // paramsの内容：今後も変わることがあるので随時対応
  // disabled: 項目が無効
  // isDelete: 削除対象
  // readOnly: 読み出しのみ
  // invalid: 値が不正（今は使っていないかもしれない。'true', 'false'の文字列
  const { disabled, readOnly, isDelete, invalid } = params
  const style = {}
  if (!disabled) {
    if (isDelete) {
      style.background = deletedInput
    } else if (readOnly) {
      style.background = lightGreen
    } else if (invalid === 'true') {
      style.background = bgPink
    }
  }
  return style
}

/**
 * 都道府県コードに対応するエリアコードを返す。
 * @param {*} prefCode 都道府県コード
 * @param {*} prefCodes 都道府県マスタ
 */
export const getRegionId = (prefCode, prefCodes) => {
  const pref = prefCodes.find(p => p.code === prefCode) || {}
  return pref.regionId || -1
}

/**
 * ハウスラボ　二人工付箋の種別判定
 * @param {*} twoPersonWorkTypeId
 * @returns {label: 表示するテキスト, display: 表示フラグ }
 */
export const hlWorkTypeLabel = twoPersonWorkTypeId => {
  if (twoPersonWorkTypeId === config.houseLaboTwoWorkType.tukan) {
    return { label: '通貫', display: true }
  } else if (twoPersonWorkTypeId === config.houseLaboTwoWorkType.camera) {
    return { label: 'カメラ', display: true }
  } else if (twoPersonWorkTypeId === config.houseLaboTwoWorkType.other) {
    return { label: 'その他', display: true }
  } else {
    return { label: '', display: false }
  }
}

/**
 * 画像表示・ダウンロード時の名称
 * @param {*} paperTypes マスタの種別リスト
 * @param {*} category 画像に付けられた種別コード
 * @returns
 */
export const paperTypeName = (paperTypes, category) => {
  const categoryMap = {
    workComplete: '工事完了確認書',
    shipInventory: '車載出庫',
    hlWorkComplete: '作業完了確認書',
    ecWorkComplete: '工事完了確認書（交換の達人）',
    constructionCaseApproval: '許諾書（施工事例記入用紙）',
    eSignAgreement: '電子交付の承諾書',
  }
  // 画像の種別を表す名前。アップロード画像のcategoryは 'estimate-0' のように番号がついているので、
  // paperTypes.labelの前方一致で検索。
  // 見積書などは番号なしの名前をつけている。マスタに存在しないカテゴリはここで処理する。
  return categoryMap[category] || paperTypes.find(p => category.startsWith(p.label))?.name || '(不明な画像)'
}
