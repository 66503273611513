import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { checkbox, checkboxLabel } from './styled'
import { baseColor } from 'src/colors'
import { v4 } from 'src/lib/uuid-util'

const Input = styled.input`
  ${checkbox};
`

const Label = styled.label`
  ${props => checkboxLabel(props.checkColor)};
  font-weight: ${props => (props.labelWeight ? props.labelWeight : 'inherit')};
  color: ${props => (props.labelColor ? props.labelColor : 'inherit')};
`

export class Checkbox extends React.PureComponent {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    labelWeight: PropTypes.string,
    labelColor: PropTypes.string,
    checkColor: PropTypes.string,
    labelText: PropTypes.any.isRequired,
  }

  /**
   * defaultProps
   * @type {object}
   */
  static defaultProps = {
    labelWeight: 'inherit',
    labelColor: 'inherit',
    checkColor: baseColor,
  }

  id = v4()

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    return (
      <span>
        <Input { ...this.props } id={ this.id } type={ 'checkbox' } />
        <Label
          htmlFor={ this.id }
          labelWeight={ this.props.labelWeight }
          labelColor={ this.props.labelColor }
          checkColor={ this.props.checkColor }
        >
          {this.props.labelText}
        </Label>
      </span>
    )
  }
}

export default Checkbox
