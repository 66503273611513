import React from 'react'
import PropTypes from 'prop-types'
import connect from '../../../connect'
import findContact from '../../lib/find-contact'
import Sticky from 'src/lib/class-sticky'
import Checkbox from 'src/styled/form/checkbox'

/**
 * DM禁止かどうかを選択します
 * @type {ReactComponent}
 */
export class DmForbidden extends React.Component {
  /**
   * propTypes
   * @type {object}
   */
  static propTypes = {
    // ownProps
    sticky: PropTypes.instanceOf(Sticky).isRequired,
    tabIndex: PropTypes.number.isRequired,
    contactsIndex: PropTypes.number.isRequired,
    disabled: PropTypes.bool.isRequired,
    // dispatchProps
    updateStage: PropTypes.func.isRequired,
  }

  /**
   * shouldComponentUpdate
   * @param  {object} nextProps next props
   * @param  {object} nextState next state
   * @return {boolean}          should component update
   */
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.sticky.json().contacts !== this.props.sticky.json().contacts ||
      this.props.disabled !== nextProps.disabled
    )
  }

  /**
   * onChange handler
   * @param  {Event} e event
   * @return {void}
   */
  onChange = e => {
    const dmForbidden = e.target.checked
    const { sticky, tabIndex, contactsIndex, updateStage } = this.props
    const id = sticky.getContactId(tabIndex, contactsIndex)
    const diffProps = {
      contacts: {
        [tabIndex]: {
          [contactsIndex]: { id, dmForbidden },
        },
      },
    }
    updateStage(new Sticky(diffProps))
  }

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const {
      // ownProps
      sticky,
      tabIndex,
      contactsIndex,
      disabled,
    } = this.props

    const contact = findContact(sticky, tabIndex, contactsIndex) || {}
    const dmForbidden = !!contact.dmForbidden

    return (
      <div>
        <Checkbox
          labelText={ 'DM禁止' }
          checked={ dmForbidden }
          onChange={ this.onChange }
          disabled={ disabled }
        />
      </div>
    )
  }
}

export default connect(DmForbidden)
