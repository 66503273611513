/**
 * ライブラリ
 */
import React from 'react'
import PropTypes from 'prop-types'
import { DropTarget } from 'react-dnd'
import { connect } from 'react-redux'
import { actionCreators as $ } from 'src/reducers/wara'
import Sticky from 'src/lib/class-sticky'
import { bgPink, lightGreen } from 'src/colors'

/**
 * contactsのボタン
 * @type {ReactComponent}
 */
class TabButtonInner extends React.Component {
  /**
   * Validation
   * @type {object}
   */
  static propTypes = {
    dataIndex: PropTypes.number.isRequired, // タブのインデックス
    tabHeader: PropTypes.string.isRequired, // タブ名
    tabCount: PropTypes.number.isRequired, // タブ内の顧客数
    activityClassName: PropTypes.string.isRequired, // クラス名
    onClick: PropTypes.func.isRequired, // クリックされた時の処理
    onKeyDown: PropTypes.func.isRequired, // キーを押された時の処理
    // dndProps
    connectDropTarget: PropTypes.func.isRequired,
    isOver: PropTypes.bool.isRequired,
    canDrop: PropTypes.bool.isRequired,
  }

  /**
   * @param {*} nextProps
   */
  shouldComponentUpdate(nextProps) {
    // ドラッグ＆ドロップの状態が変わった時、contactsの件数が変わった時に再描画する
    return (
      this.props.isOver !== nextProps.isOver ||
      this.props.canDrop !== nextProps.canDrop ||
      this.props.tabCount !== nextProps.tabCount ||
      this.props.activityClassName !== nextProps.activityClassName
    )
  }

  render() {
    const {
      dataIndex,
      tabHeader,
      tabCount,
      activityClassName,
      onClick,
      onKeyDown,
      connectDropTarget,
      isOver,
      canDrop,
    } = this.props
    const buttonStyle = {}
    // ドロップの可否によって色を変える
    if (isOver) {
      buttonStyle.background = canDrop ? lightGreen : bgPink
    }

    const DOM = (
      <button
        id={ `tab-header-${dataIndex}` }
        className={ `tab-head-button tab-head-button-${dataIndex} ${activityClassName}` }
        onClick={ onClick(dataIndex) }
        onKeyDown={ onKeyDown }
        data-index={ dataIndex }
        style={ buttonStyle }
      >
        {tabHeader}
        {tabCount > 0 ? (
          <strong className={ 'tab-count' }>{`${tabCount} 件`}</strong>
        ) : null}
      </button>
    )
    return connectDropTarget(DOM)
  }
}

/**
 * contactがdropされた
 * @param {*} props
 * @param {*} monitor
 * @param {*} component
 */
const drop = (props, monitor, component) => {
  // dragSourceの情報取得
  const srcProp = monitor.getItem()
  // console.log(srcProp)
  const {
    props: {
      tabIndex: fromTab, // 移動元のタブ
      contactsIndex: fromIndex, // 移動元のcontact
      contacts, // 元のcontacts
      update, // 付箋の更新
    },
  } = srcProp
  // dropTargetの情報取得
  const {
    dataIndex: toTab, // 移動先のタブ
  } = props
  // console.log(props)
  // contactを移動
  const newContacts = [...contacts]
  // 移動先がないときは作成
  if (!newContacts[toTab]) {
    newContacts[toTab] = []
  }
  newContacts[toTab].push(newContacts[fromTab][fromIndex])
  newContacts[fromTab].splice(fromIndex, 1)
  // 移動元と移動先の ContactDivisionId = tabIndex+1, displayorder: 連番にする
  for (let i = 0; i < newContacts[fromTab].length; i++) {
    newContacts[fromTab][i].ContactDivisionId = fromTab + 1
    newContacts[fromTab][i].displayorder = i + 1
  }
  for (let i = 0; i < newContacts[toTab].length; i++) {
    newContacts[toTab][i].ContactDivisionId = toTab + 1
    newContacts[toTab][i].displayorder = i + 1
  }
  // console.log(newContacts)
  // ドラッグ＆ドロップした時はcontacts全体を差分とし、画面のupdateを呼び出す
  // ドラッグ＆ドロップであることがわかるように差分にdragdropフラグを追加する。
  if (update) {
    const diffsticky = {
      contacts: newContacts,
    }
    diffsticky.dragdrop = true
    update(diffsticky)
  }

  // dispatcherの取得
  const { dispatch } = component.context.store
  // diffstickyを作り、updateStageに渡す。ここもdragdropフラグを追加する。
  const diffsticky = { contacts: newContacts, dragdrop: true }
  dispatch($.updateStage(new Sticky(diffsticky), true))
}

/**
 * ドロップの可否を判定。
 * @param {*} props
 * @param {*} monitor
 */
const canDrop = (props, monitor) => {
  // console.log('canDrop')
  const srcProp = monitor.getItem()
  const {
    props: {
      tabIndex: fromTab, // 移動元のタブ
    },
  } = srcProp
  const {
    dataIndex: toTab, // 移動先のタブ
  } = props
  // 移動元と移動先が同じ時はドロップ不可とする。
  return fromTab !== toTab
}

/**
 * ドロップターゲットとして登録
 */
export default DropTarget(
  'contact', // types
  { drop, canDrop }, // spec
  // collect
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    canDrop: monitor.canDrop(),
    isOver: monitor.isOver({ shallow: true }),
  }),
)(connect()(TabButtonInner))
