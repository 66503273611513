/**
 * validなIDかどうかを確かめる関数
 * @param  {any}  id idと思しきもの
 * @return {boolean} is valid
 */
export const isValidId = id => {
  return !isNaN(id, 10) && id !== null && id !== -1
}

/**
 * 付箋PUT時の前処理
 * @param  {Sticky}  sticky 投稿しようとしているStickyクラスのインスタンス
 * @return {Sticky}         投稿する付箋クラスインスタンス
 */
export const transformBeforePut = sticky => {
  const { wishDateTimes, negotiationRecords } = sticky.json()

  const nextSticky = sticky
    .update(
      {
        // wishDateTimesにはdisplayOrderを降っておく。
        // サーバー側の処理がよくわからないので、クライアントで固めておく
        wishDateTimes: (wishDateTimes || []).map((wishDateTime, index) => ({
          ...wishDateTime,
          displayOrder: index < 3 ? index : 99,
        })),
        // 一応不正な交渉履歴を除く
        negotiationRecords: (negotiationRecords || []).filter(
          x =>
            typeof x === 'object' &&
            // id だけの奴を除く
            !(Object.keys(x).length === 1 && Object.keys(x)[0] === 'id'),
        ),
      },
      { shallow: true },
    )
    .update(
      {
        // 種別変更したときの元連絡先を削除。削除した連絡先はそのまま送らなければならない。
        contacts: (allContacts = []) =>
          allContacts.map(tabContacts =>
            tabContacts.reduce(
              (filtered, contact) =>
                !contact.moved ? [...filtered, contact] : filtered,
              [],
            ),
          ),
      },
      { shallow: true },
    )
    .remove({
      // 更新させないので必ず消すようにする
      stickyTypeId: true,
    })
  return nextSticky
}

export default transformBeforePut
