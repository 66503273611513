/**
 * 未配置の付箋置き場コンポーンント
 * @file
 */

/**
 * ライブラリ
 */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import StickyView from './sticky/container'
import Sticky from 'src/lib/class-sticky'
import { NETWORK_STATUSES } from 'src/reducers/network-status'

/**
 * comparetor
 * @param  {object} a [description]
 * @param  {object} b [description]
 * @return {number}   [description]
 */
export const withWishDateTime = (a, b) => {
  const wishDateTimesA = a.sticky.json().wishDateTimes[0] || {}
  const wishDateTimesB = b.sticky.json().wishDateTimes[0] || {}

  const aDate = wishDateTimesA.date || '0000-00-00'
  const bDate = wishDateTimesB.date || '0000-00-00'
  const aStartAt = wishDateTimesA.startAt || '00:00'
  const bStartAt = wishDateTimesB.startAt || '00:00'
  const aMoment = moment(`${aDate} ${aStartAt}`)
  const bMoment = moment(`${bDate} ${bStartAt}`)
  return aMoment.unix() - bMoment.unix()
}

/**
 * comparetor
 * @param  {object} a [description]
 * @param  {object} b [description]
 * @return {number}   [description]
 */
export const withDeterminedDateTime = (a, b) => {
  const determinedDateTimeA = a.sticky.json().determinedDateTime || {}
  const determinedDateTimeB = b.sticky.json().determinedDateTime || {}

  const aDate = determinedDateTimeA.date || '0000-00-00'
  const bDate = determinedDateTimeB.date || '0000-00-00'
  const aStartAt = determinedDateTimeA.startAt || '00:00'
  const bStartAt = determinedDateTimeB.startAt || '00:00'
  const aMoment = moment(`${aDate} ${aStartAt}`)
  const bMoment = moment(`${bDate} ${bStartAt}`)
  return aMoment.unix() - bMoment.unix()
}

/**
 * map state to props
 * @param  {object} state stored state
 * @return {object}       merging props
 */
const mapStateToProps = state => {
  return {
    networkStatus: state.networkStatus,
  }
}

/**
 * Dockのコンポーネント
 * @type {ReactComponent}
 */
const Dock = ({
  justRegistered,
  wished,
  adjusting,
  messaged,
  networkStatus,
}) => {
  const [sortedMessaged] = [[...messaged]]
  // 調整中でない希望承り済みと調整中の希望承り済みを希望日時でソート後にまとめてる
  const [sortedWished] = [
    [...wished.sort(withWishDateTime), ...adjusting.sort(withWishDateTime)],
  ]
  sortedMessaged.sort(withDeterminedDateTime)
  const dockStickiesLoaded =
    networkStatus.getDockSticky === NETWORK_STATUSES.SUCCESS

  // 未配置付箋を3種類に分割
  const columns = [
    {
      stickies: justRegistered,
      slug: 'just-registered',
      label: '予定未調整',
    },
    { stickies: sortedWished, slug: 'wished', label: '希望承り済み' },
    { stickies: sortedMessaged, slug: 'messaged', label: '目安お伝え済み' },
  ]

  return (
    <div className={ 'dock clearfix' }>
      <ul className={ 'docks-ul' }>
        {columns.map(({ stickies, slug, label }) =>
          stickies.length > 0 ? (
            <li key={ slug }>
              <h3>{label}</h3>
              <ul className={ `dock-ul dock-${slug}` }>
                {stickies.map(({ sticky, stickyIndex }) => {
                  const location = {
                    regionIndex: -1,
                    listIndex: -1,
                    stickyIndex,
                  }
                  return (
                    <StickyView
                      key={ sticky.id }
                      location={ location }
                      sticky={ sticky }
                      staffId={ -1 }
                      getSSworkList={ () => {} }
                      workState={ -1 }
                      dockStickiesLoaded={ dockStickiesLoaded }
                    />
                  )
                })}
              </ul>
            </li>
          ) : null,
        )}
      </ul>
    </div>
  )
}

/**
 * Validation
 * @type {object}
 */
Dock.propTypes = {
  // ownProps
  justRegistered: PropTypes.arrayOf(
    PropTypes.shape({
      sticky: PropTypes.instanceOf(Sticky).isRequired,
      stickyIndex: PropTypes.number.isRequired,
    }),
  ).isRequired,
  wished: PropTypes.arrayOf(
    PropTypes.shape({
      sticky: PropTypes.instanceOf(Sticky).isRequired,
      stickyIndex: PropTypes.number.isRequired,
    }),
  ).isRequired,
  adjusting: PropTypes.arrayOf(
    PropTypes.shape({
      sticky: PropTypes.instanceOf(Sticky).isRequired,
      stickyIndex: PropTypes.number.isRequired,
    }),
  ).isRequired,
  messaged: PropTypes.arrayOf(
    PropTypes.shape({
      sticky: PropTypes.instanceOf(Sticky).isRequired,
      stickyIndex: PropTypes.number.isRequired,
    }),
  ).isRequired,
  // state props
  networkStatus: PropTypes.object.isRequired,
}

export default connect(mapStateToProps)(React.memo(Dock))
