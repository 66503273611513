import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { baseColor } from 'src/colors'

const CircleButton = styled.button`
  opacity: 0.6;
  position: fixed;
  z-index: 99999999999;
  top: ${props => props.top + 'px'};
  left: ${props => props.left + 'px'};
  height: 100px;
  width: 100px;
  border-radius: 50px;
  background-color: ${baseColor};
`

/**
 * map state to props
 * @param  {object} state    state tree
 * @param  {object} ownProps own props
 * @return {object}          state props
 */
export const mapStateToProps = state => ({ state })

export class StateInspector extends React.PureComponent {
  state = { top: 100, left: 100, pageX: 0, pageY: 0 }

  dragStart = ({ pageX, pageY }) => this.setState({ pageX, pageY })
  dragEnd = ({ pageX, pageY }) =>
    this.setState({
      top: this.state.top + pageY - this.state.pageY,
      left: this.state.left + pageX - this.state.pageX,
      pageX: 0,
      pageY: 0,
    })

  // eslint-disable-next-line no-console
  dump = () => console.log(this.props.state)

  /**
   * render
   * @return {ReactElement|null|false} render a React element.
   */
  render() {
    const { top, left } = this.state
    const { state } = this.props
    return (
      state.env.DEBUG && (
        <CircleButton
          key={ 'state-inspector-draggable' }
          top={ top }
          left={ left }
          onClick={ this.dump }
          onDragStart={ this.dragStart }
          onDragEnd={ this.dragEnd }
          draggable
        >
          {'State'}
        </CircleButton>
      )
    )
  }
}

StateInspector.propTypes = {
  state: PropTypes.object.isRequired,
}

export default connect(mapStateToProps)(StateInspector)
