import meta from 'src/version.json'

/**
 * initialState
 * @type {object}
 */
const initialMetaState = { ...meta }

/**
 * [description]
 * @param  {object} [state=initialMetaState] [description]
 * @param  {object} action                   [description]
 * @return {object}                          [description]
 */
export const reducer = (state = initialMetaState, action) => {
  const { type } = action

  switch (type) {
    default:
      return state
  }
}

export default reducer
